import { FC, Fragment, useCallback, useContext, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import PaginationButtons from "../../../../components/PaginationButtons";
import {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../../layout/SubHeader/SubHeader";
import useFilters from "../../../../hooks/useFilters";
import useFetch from "../../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/table";
import Spinner from "../../../../components/bootstrap/Spinner";
import { FeeService } from "../../../../services/fees/feeService";
import { Fee, Fees, FeesApiResponse } from "../../../../type/fee-type";
import moment from "moment";
import FeesFilters from "./fees-options/FeesFilters";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FeeModal from "./FeeModal";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../../components/table/CustomTable";
import StatusDropdown from "../../../../components/StatusDropdown";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";

interface ProductListProps {
  businessId: string;
}
export interface IFeeFilters {
  name?: string;
  description?: string;
  active?: number;
  company?: any;
  business?: string;
}

const FeeList: FC<ProductListProps> = ({ businessId }) => {
  const {userCan} = useContext(PrivilegeContext)
  const [feeId, setFeeId] = useState('');
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);
  
  const feeFilters: IFeeFilters = {
    active: 1,
    business: businessId,
  };
  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(feeFilters, [], 50);

  async function deleteFee(id: string){
    let response = (await new FeeService().deleteFee(id)).getResponseData();
    if(response.success) {
      toast.success('Tarifa eliminada exitosamente');
    } else {
      toast.error('Error al eliminar la tarifa');
    }
    refetch();
  }

  const fetchFees = useCallback(async () => {
    const response = await new FeeService().getFees(filters);
    return response.getResponseData() as FeesApiResponse;
  }, [filters, businessId, modal]);

  const [data, loading, error, refetch] = useFetch(fetchFees);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleFeeStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new FeeService().toggleFeeStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refetch();
  };
  
  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setFeeId(item.id);
    setModal(true);
  }
  
  return (
    <Fragment>
      <CardHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Tarifas</CardTitle>
            {userCan('create','fees')?
              <>
              <SubheaderSeparator />
              <Button
                color="light"
                icon="Add"
                className={`${userCan('delete','fees')?'':'d-none'}`}
                isLight
                onClick={() => {
                  openModal('', false);
                }}
              >

                Añadir Tarifa
              </Button> </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <FeesFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
        </SubHeaderRight>
      </CardHeader>
      <CardBody>
        <Fragment>{
          data ? (
              <CustomTable
              data={data?.fees ? data.fees : null}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: data as FeesApiResponse ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
              }}
              className={"table table-hover"}
                columns={[
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {
                          setReadMode(true);
                          setFeeId(element.id); 
                          setModal(true);}}>
                          {element.name}
                        </div>
                      )
                    },
                  },
                  {
                    name: "Descripción",
                    keyValue: "description",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                  },
                  {
                    name: "Comericalizadora",
                    keyValue: "business",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {                  
                      if(element.business) {
                        return (
                          <div key={element.business.id} className="d-flex justify-content-center">
                            {element.business.name}
                          </div>
                        )
                      }else return (<></>);
                    }
                  },
                  {
                    name: "Estado",
                    keyValue: "active",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          <StatusDropdown
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            status={element.active}
                            change={toggleFeeStatus}
                            key={element.id}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    name: "Fecha de creación",
                    keyValue: "createdAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                        </div>
                      )
                    },
                  },
                  {
                    name: "Última modificación",
                    keyValue: "updatedAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (item: any) => {
                      return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                    }
                  },
                  {
                    name: "Nº Productos",
                    keyValue: "products",
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          {element.products.length}
                        </div>
                      );
                    },
                  },
                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={
                  [
                    {
                      title: "Ver",
                      buttonType: 'icon',
                      iconColor: 'text-primary',
                      iconPath: '/media/icons/duotune/general/gen021.svg',
                      additionalClasses: `text-primary ${userCan('edit','fees')?'':'d-none'}`,
                      description: "Ver tarifa",
                      callback: (item: any) => {
                        if(userCan('edit','fees')) openModal(item, true);
                      },
                    },
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-info',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${userCan('edit','fees')?'':'d-none'}`,
                      description: "Editar tarifa",
                      callback: (item: any) => {
                        if(userCan('edit','fees')) openModal(item, false);
                      },
                    },
        
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${userCan('delete','fees')?'':'d-none'}`,
                      description: "Eliminar tarifa",
                      callback: (item: any) => {
                        if(userCan('delete','fees')){
                          handleConfirmationAlert({
                            title: "Eliminar tarifa",
                            text: "¿Está seguro que desea eliminar el tarifa?",
                            icon: "warning",
                            onConfirm: () => {
                              deleteFee(item.id);
                            }
                          })
                        }
                      },
                    },
                  ]}
              />
            ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
          }</Fragment>
      </CardBody>
      {modal && (<FeeModal isOpen={modal} setIsOpen={setModal} businessId={businessId} feeId={feeId} readMode={readMode}/>)}
    </Fragment>
  );
};

export default FeeList;
