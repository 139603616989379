import { useFormik } from "formik";
import { FC, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NewCampaign } from "../../../type/campaign-type";
import { CampaignService } from "../../../services/campaigns/campaignService";
import { useCompanyList, useLoggedUserCompany } from "../../../hooks/useListData";
import { useSelector } from "react-redux";
import { AuthState } from "../../../redux/authSlice";
import Select from "../../../components/bootstrap/forms/Select";
import { useNavigate } from "react-router";


interface CampaingModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
}

let options: any = [
    { value: 'ALL', label: 'Todos' },
    { value: 'PARTICULAR', label: 'Particular' },
    { value: 'EMPRESA', label: 'Empresa' }
];

const CampaignModal: FC<CampaingModalProps> = ({ isOpen, setIsOpen }) => {

    const company = useLoggedUserCompany();
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const [loadign, setLoading] = useState(false);

    const rol = useSelector((state: { auth: AuthState }) => {
        const userRoles = state.auth.user?.roles;
        return userRoles && (userRoles.includes('Superadministrador') ? 'Superadministrador' : userRoles?.[0]);
    });

    const companyList = useCompanyList();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: null,
            clientType: 'ALL',
            company: '',
        },
        onSubmit: (values) => {
            if (rol !== "Superadministrador") {
                values.company = company;
            }
            createCampaign(values as NewCampaign);
        },
    });


    const createCampaign = async (values: NewCampaign) => {
        let response = null;
        try {
            setLoading(true);
            response = await (await (new CampaignService()).createCampaign(values)).getResponseData();
            console.log(response)
            if (response.success) {
                toast.success('Departamento creado con éxito');
            } else {
                toast.error('Error al crear el Departamneto');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al crear el Departamneto');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
            navigate((`edit/${response.data.id}`));
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true} size={!modal ? 'xl' : 'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>Nuevo Departamento</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className={rol === "Superadministrador" ? 'col-md-4' : 'col-md-6'}>
                                <Input value={formik.values.name || ''} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='description' label='Breve descripción' className={rol === "Superadministrador" ? 'col-md-4' : 'col-md-6'}>
                                <Input value={formik.values.description || ''} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup requiredInputLabel label='Tipo de Cliente' className={rol === "Superadministrador" ? 'col-md-12 mt-2' : 'col-md-12 mt-2'}>
                                <Select
                                    onChange={(e: any) => { formik.setFieldValue('clientType', e.target.value) } }
                                    value={formik.values.clientType}
                                    id='active-select' ariaLabel='Default select example' placeholder='Tipo de cliente...'
                                    list={options}
                                />
                            </FormGroup>
                            <FormGroup id='company' label='Compañia' requiredInputLabel className={rol === "Superadministrador" ? 'col-md-4' : 'd-none'}>
                                <Select id='SelectCompany' required={rol === "Superadministrador" ? true : false} ariaLabel='Select Company' placeholder='Elegir Compañia...' onChange={formik.handleChange} value={formik.values.company} list={companyList} />
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter className='px-4 pb-4'>
                        <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                            {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default CampaignModal;