import React, { FC } from 'react';
import Badge from '../../../../components/bootstrap/Badge';
import Icon from '../../../../components/icon/Icon';

interface PostalCodeComponentProps {
    postalCodes: string | null
}

const PostalCodeComponent:FC<PostalCodeComponentProps> = ({ postalCodes }) => {

  const chunkArray = (array:any , chunkSize: any) => {

    const chunks = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;

  };

  // Dividir los códigos postales por coma y mapear a objetos
  const postalCodesArray = postalCodes !== null ? postalCodes.split(',').map((code) => {
    const [postalCode, status] = code.split('-');
    return { postalCode, status };
  }) : [];

  // Separar en incluidos y excluidos
  const includedPostalCodes = postalCodesArray
    .filter(({ status }) => status !== 'exclude')
    .map(({ postalCode }) => postalCode);

  const excludedPostalCodes = postalCodesArray
    .filter(({ status }) => status === 'exclude')
    .map(({ postalCode }) => postalCode);

  const includedChunks = chunkArray(includedPostalCodes, 3);
  const excludedChunks = chunkArray(excludedPostalCodes, 3);

  return (
    <>
    { postalCodesArray.length > 0 ? (
        <>
            <div className="d-flex justify-content-around align-items-center flex-column">
                <Icon icon="Add" className="ms-2 me-2 text-primary" size={'2x'}/>
            </div>
            <div className="d-flex justify-content-around align-items-center flex-column">
                <span className="fw-bold">Codigos Postales</span>
                <div className="mt-2">
                    <Badge className="mt-2" color="success">
                    {includedChunks.length > 0 ? (
                        includedChunks.map((chunk, index) => (
                        <div key={index} className={`${index < includedChunks.length - 1 ? 'mb-2' : ''}`}>
                            {chunk.join(', ')}
                            {index < includedChunks.length - 1 && <br />}
                        </div>
                        ))
                    ) : (
                        'Ninguno'
                    )}
                    </Badge>
                </div>
                <div className="mt-2">
                        <Badge className="mt-2" color="danger">
                        {excludedChunks.length > 0 ? (
                            excludedChunks.map((chunk, index) => (
                            <div key={index} className={`${index < excludedChunks.length - 1 ? 'mb-2' : ''}`}>
                                {chunk.join(', ')}
                                {index < excludedChunks.length - 1 && <br />}
                            </div>
                            ))
                        ) : (
                            'Ninguno'
                        )}
                        </Badge>
                </div>
            </div> 
        </>
    ) : (
        <></>
    )}
  </>
);
};

export default PostalCodeComponent;