import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardActions,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import BusinessForm from "../BusinessForm";
import { BusinessApiResponse } from "../../../type/business-type";
import StatusSwitch from "../../../components/StatusSwitch";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BusinessService } from "../../../services/business/businessService";

const CompanyEdit: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const fetchBusiness = useCallback(async () => {
    const businessService = new BusinessService();
    const response = await businessService.getBusinessById(id as string);
    return response.getResponseData() as BusinessApiResponse;
  }, [id]);

  const [data] = useFetch(fetchBusiness);
  const [error, setError]= useState<boolean>(false);
  const [loading, setLoading]= useState<boolean>(false);  const [changingStatus, setChangingStatus] = useState<boolean>(false);

  const handleUpdate = async (values: any) => {
    setLoading(true);

		const formData = new FormData();
    
		for(let key in values) {
      if(values[key]){
        formData.append(key, values[key]);
			}else{
        formData.append(key, '')
      }
		}
    formData.append('business', data['id']);

		try {
			setLoading(true)
			let response = await (await (new BusinessService()).editBusiness(formData, values.logo instanceof File)).getResponseData();
			if(response.success) {
        toast.success(response.message);
        navigate(-1);
			} else {
        toast.error(response.message);
				setError(true);
			}
		} catch (error: any) {
      toast.error('Error al editar la Comercializadora');      
		}finally{
			setLoading(false);
		}
    
  };

  const toggleBusinessStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus(true);
      let response = await (
        await new BusinessService().toggleBusinessStatus(id, status)
      ).getResponseData();
      if (response.success) {
        toggleStatus(status);
      }
    } catch (error: any) {
      toast.error('Error al editar la Comercializadora');      
    }finally{
      setChangingStatus(false);
    }
  };

  const getContent = () => {
    if (loading) return <Spinner />;

    if (error) return <div> Error </div>;

    if (data) {

      const businessData = {
        id : data?.id,
        name: data?.name,
        company: '',
        address: data?.address,
        cif: data?.cif,
        clawback_days: data?.clawbackDays !== null ? data.clawbackDays : "",
        renewal_days_particular: data?.renewalDaysParticular !== null ? data.renewalDaysParticular : "",
        renewal_days_business: data?.renewalDaysBusiness !== null ? data.renewalDaysBusiness : "",
        reference_id: data?.referenceId,
        shop_code: data?.shopCode,
      };

      return (
        <Fragment>
        <CardHeader borderSize={1}>
          <CardLabel icon="BorderColor" iconColor="info">
            <CardTitle>Editar Comercializadora</CardTitle>
          </CardLabel>
          <CardActions>
            <StatusSwitch itemId={data.id} status={data.active} change={toggleBusinessStatus}/>
          </CardActions>
        </CardHeader>
        <BusinessForm isLoading={false} submit={handleUpdate} businessData={businessData}
        />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          ></Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <></>
          {getContent()}
        </Card>
      </Page>
    </Fragment>
  );
};

export default CompanyEdit;
