import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { OperationService } from "../../../services/operations/operationService";
import { OperationApiResponse } from "../../../type/operation-type";
import Button from "../../../components/bootstrap/Button";
import OperationFilters from "./operation-options/OperationsFilters";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import OperationTableComponent from "../../../components/table/Operation/OperationTableComponent";
import { ActionStatus } from "../../../type/actionStatus-type";
import { StatusService } from "../../../services/status/statusService";
import EditOperationModal from "../../../components/operation/EditOperationModal";
import { toast } from "react-toastify";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { useLoader } from "../../../components/loader/LoaderProvider";
import useHandleErrors from "../../../hooks/useHandleErrors";

export interface IOperationFilters {
  name?: string;
  agent?: string | undefined;
  operation_type?: string;
  current_action_status?: Array<string>;
  current_contract_status?: Array<string>;
  current_commission_status?: Array<string>;
  lead?: string;
  office?: string;
  from?: string;
  to?: string;
}

const OperationsList = () => {

  let defaultColumns = ["company", "agent", "operationType", "actionStatus", "status", "operationIdentifier", "cups", "clientName", "fav", "clientDni", "createdAt", "product"];

  const { userCan } = useContext(PrivilegeContext);
  
  const [actionStatuses, setActionStatuses] = useState<ActionStatus[]>([]);
  const [showEditOperationModal, setShowEditOperationModal] = useState<boolean>(false);
  const [operationSelected, setOperationSelected] = useState<string | null>();
  const [contractStatuses, setContractStatuses] = useState<ActionStatus[]>([]);
  const [commissionStatuses, setCommissionStatuses] = useState<ActionStatus[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns);
  const [exportData, setExportData] = useState<any>();
  const button = useRef<HTMLButtonElement>(null);
  const { showLoading, hideLoading } = useLoader();
  const { handleErrors } = useHandleErrors();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    if (button.current !== null && exportData != undefined){ button.current.click();}
  }, [ exportData, setExportData]);

  useEffect(() => {
    fetchStatuses();
    checkIfUrlHasFilters();
  },[])

  // if user can show contract status then add column
  if (userCan('show_contract_status', 'operations')) {
    defaultColumns.push('contract_status');
  }
  // if user can show commission status then add column
  if (userCan('show_commission_status', 'operations')) {
    defaultColumns.push('commission_status');
  }

  if (userCan('show_points', 'operations')) {
    defaultColumns.push('points');
  }

  if (userCan('show_commissions', 'operations')) {
    defaultColumns.push('commission');
  }

  
  const fetchOperations = useCallback(async () => {
    const operationService = new OperationService();
    const response = await operationService.getOperations(filters);
    return response.getResponseData() as OperationApiResponse;
  }, [filters]);
  
  const [data, loading, error, refetch] = useFetch(fetchOperations);
  
  const getActionStatusList = async (entity: string): Promise<ActionStatus[]|undefined> => {
    const response = await (await (new StatusService()).getChangeActionStatuses({filter_filters:{entity: entity, active: 1}, all: true})).getResponseData();
    if (response.success) {
      return response.data.actionStatuses;
    } else {
      return [];
    }
  }
  
  const fetchStatuses = async () => {
    let actionStatusList = await getActionStatusList("operation");
    setActionStatuses(actionStatusList !== undefined ? actionStatusList : []);

    let contractStatusList = await getActionStatusList("contract");
    setContractStatuses(contractStatusList !== undefined ? contractStatusList : []);

    let commissionStatusList = await getActionStatusList("commission");
    setCommissionStatuses(commissionStatusList !== undefined ? commissionStatusList : []);
  }

  const _handleOnOperationEdit = (operation: any) => {
    toast.success("Contrato editado correctamente");
    refetch();
  }

  const _handleOnEditModalVisilble = (visibility: boolean) => {
    if (visibility === false) {
      setOperationSelected(null);
    }
    setShowEditOperationModal(visibility);
  }

  const _handleExportContract = () => async () => {

    showLoading("Exportando contrato", <>Espere mientras se genera el contrato</>);

    try {
      const response = await (await (new OperationService()).exportOperations(filters));
      const responseData = response.getResponseData();
      // generate blob and download
      const blob = new Blob([response?.getResponse()?.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Contratos.xlsx`;
      link.click();
      if (responseData?.success) {
        toast.success("Contratos exportados correctamente");
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error("Error al exportar contratos" + error.message);
    } finally {
      hideLoading();
    }
  }


  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardLabel icon="Inventory2" iconColor="info">
              <CardTitle>Listado de Contratos</CardTitle>
            </CardLabel>
            <SubheaderSeparator />
            <Button
              color="light"
              icon="Autorenew"
              isLight
              onClick={() => {
                refetch()
              }}
            >
            </Button>
            {data ?
              <>
                <SubheaderSeparator />
                <Button color="light" isLight  icon="FileDownload" onClick={_handleExportContract()}>Exportar</Button>
              </>
              : <></>
            }
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <OperationFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={false}>
          <OperationTableComponent
            data={data}
            defaultLimit={filters.limit || 50}
            defaultOrder={filters.filter_order || undefined}
            error={error}
            filters={filters}
            updateFilters={updateFilters}
            updateFilterOrder={updateFilterOrder}
            updatePage={updatePage}
            updatePageSize={updatePageSize}
            onClickEdit={(operation: any) => {
              setOperationSelected(operation.id);
              setShowEditOperationModal(true);
            }}
            actionStatuses={actionStatuses}
            contractStatuses={contractStatuses}
            commissionStatuses={commissionStatuses}
            classNameProps="table table-hover p-3 fs-8 min-h-500"
            visibleActions={["show", "edit", "delete", "addFavorite", "reapply"]}
            refetch={() => {
              refetch();
            }}
            visibleColums={visibleColumns}
          />
          <EditOperationModal visible={showEditOperationModal} setVisible={_handleOnEditModalVisilble} operationId={operationSelected || ""} onOperationEdit={_handleOnOperationEdit} />
        </Card>
      </Page>
    </Fragment>
  );
};

export default OperationsList;
