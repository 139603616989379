import { Fragment } from "react"
import { Outlet } from "react-router-dom"

const DashboardWrapper = () => {

  return (
    <Fragment>
      <Outlet/>
    </Fragment>
  )
}

export default DashboardWrapper