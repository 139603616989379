import { NewCompany } from '../../type/company-type';
import { RestServiceConnection } from '../restServiceConnection';

const COMPANIES_ENDPOINT = '/companies';

export class CompanyService extends RestServiceConnection {
    
    createCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => {if(value == ''){companyObj[key] = null;}else (companyObj[key] = value)});
        companyObj['logo'] = null;
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/create',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            },
        }, true);
        return this;
    }

    editCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => {if(value == ''){companyObj[key] = null;}else (companyObj[key] = value)});
        companyObj['logo'] = null;
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/edit',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    updateCompany = async (company: NewCompany) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/edit',
            data: company,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getCompanies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/list',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getCompanyById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/get',
            data: {
                company: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    toggleCompantStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/toggle',
            data: {
                company: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteCompany = async (companyId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/delete',
            data: {
                company: companyId
            }
        }, true);

        return this
    }
}