import { FC, useEffect, useState } from "react";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import { OperationService } from "../../../../services/operations/operationService";
import AsyncImg from "../../../../components/AsyncImg";
import { Link } from "react-router-dom";
import { priceFormatEuro } from "../../../../helpers/helpers";
import Icon from "../../../../components/icon/Icon";
import { usePrivilege } from "../../../../components/priviledge/PriviledgeProvider";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import { truncateString } from "../../../../utils/strings/truncateText";

interface IOperationAgentRankingByRankingProps {
  date: any;
  users?: any;
  showCommission?: boolean;
  rankingId?: string;
  rankingName?: string;
  rankingDescription?: string;
}

const OperationAgentRankingByRanking: FC<IOperationAgentRankingByRankingProps> = ({ date , showCommission , rankingId , rankingName , rankingDescription, users }) => {
  const [data, setData] = useState<any>();
  const { userCan } = usePrivilege();
  const [loading, setLoading] = useState<boolean>(false);

  const getRanking = async () => {
    if (date.start === "" || date.end === "" || rankingId === "") return;
    const operationService = new OperationService();
    const response = (
      await operationService.getRankingByRanking(date.start, date.end , rankingId)
    ).getResponseData();
    if (response.success) {
      
      let resultData = response.data !== null ? response.data : [];

      const nonMatchingUsers = users && users.filter((user: any) => 
        !resultData.some((item: any) => item.agentId === user.id)
      );

      nonMatchingUsers && nonMatchingUsers.length > 0 ?
        setData(resultData.concat(nonMatchingUsers.map((user: any) => ({
          agentId: user.id,
          agentName: user.name,
          agentImage: user?.image,
          grossCommission: 0,
          netPoints: 0,
          grossPoints: 0,
        })))) :
        setData(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (date != null) {
      getRanking();
    }
  }, [date]);

  return (
    <Card>
      <CardHeader className="mb-1">
        <CardLabel icon="ContactSupport" iconColor="secondary">
          <CardTitle tag="h4" className="h5">
            Ranking de Agentes { rankingName ? `- ${rankingName}` : '' }
            {rankingDescription ? (
              <small className="text-muted d-block fs-6">
                {rankingDescription}
              </small>
            ) : null}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className="overflow-auto" style={{ maxHeight: "250px" }}>
        {data != null && data.errors == null ? (
          data.map((item: any, key: number) => {
            return (
              <div className="col-12 mb-4" key={key}>
                <div className="row">
                  <div className="col-md-4 col-12 row d-flex">
                    <div className=" col-md-3 d-flex align-items-center">
                      <span className={`fs-4 fw-bold mb-0 text-center ${key + 1 == 1 ? "text-warning" : key + 1 == 2 ? "text-muted" : key + 1 == 3 ? "text-danger" : ""}`}>
                        {key + 1}
                      </span>
                    </div>
                    <div className="col-md-3 flex-shrink-0 d-flex justify-content-center">
                      <div
                        className="user-avatar"
                        title={item.agentName}
                        id={item.agentId}
                      >
                        <AsyncImg id={item.agentImage || null} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <Tooltips title={item.agentName}>
                          <Link
                            to={`/users/${item.agentId}/profile`}
                            className={"fw-bold fs-6 mb-0"}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {truncateString(item.agentName, 8)}
                          </Link>
                        </Tooltips>
                      </div>
                    </div>
                  </div>
                    <div className=" col-sm-8 row col-12 d-flex justify-content-around align-items-center">
                      {userCan("show_commissions", "operations") && (
                        <span className="fs-6 fw-bold mb-0 text-end col-4">
                          { showCommission ? priceFormatEuro(item.grossCommission || 0) : '-'}
                        </span>
                      )}

                      <span className="fs-6 fw-bold mb-0 text-end col-4">
                        {(item.netPoints || 0)  + " ptos"}
                      </span>

                      <span className="fs-6 fw-bold mb-0 text-end col-4">
                        {(item.grossPoints || 0) + " ptos"}
                      </span>
                    </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center">No hay datos</div>
        )}
      </CardBody>
    </Card>
  );
};

export default OperationAgentRankingByRanking;
