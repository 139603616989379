import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { OfficeService } from "../../../services/ofiice/officeService";
import { OfficesApiResponse } from "../../../type/office-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { CustomTable } from "../../../components/table/CustomTable";
import StatusDropdown from "../../../components/StatusDropdown";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import OfficeModal from "../offices-create/OfficeModal";
import OfficeFilter from "./OfficeFilter";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const OfficesList = () => {
  const {userCan} = useContext(PrivilegeContext)

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const [office, setOffice] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);


  const fetchOffices = useCallback(async () => {
    const officeService = new OfficeService();
    const response = await officeService.getOffices(filters);
    return response.getResponseData() as OfficesApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refech] = useFetch(fetchOffices);


  const deleteOffice = async (id: string) => {
    let response = (await new OfficeService().deleteOffice(id)).getResponseData();
    if (response.success) {
      toast.success('Oficina eliminada correctamente');
      refech();
    } else {
      toast.error('Error al eliminar la oficina');
    }
  }


  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setOffice(item);
    setModal(true);
  }

  
  const handleToggleStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new OfficeService().toggleStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success('Oficina cambiada de estado correctamente');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refech();
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Oficinas</CardTitle>
              {userCan('create','offices')?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('create','offices')?'':'d-none'}`}
                  isLight
                  onClick={() => {
                    openModal(null, false);
                  }}
                >
                  Añadir Oficina
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <OfficeFilter updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {
            data ? (
                <CustomTable
                  data={data?.offices ? data.offices : null}
                  pagination={true}
                  defaultLimit={filters.limit || 50}
                  defaultOrder={filters.filter_order || undefined}
                  paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: data as OfficesApiResponse ? data.lastPage : 1,
                    totalCount: data.totalRegisters,
                    handlePagination: updatePage,
                    handlePerPage: updatePageSize,
                  }}
                  className={"table table-hover"}
                  columns={
                    [
                      {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {openModal(element, true);}}>
                              {element.name}
                            </div>
                          )
                        },
                      },
                      {
                        name: "Descripción",
                        keyValue: "description",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Organización",
                        keyValue: "company",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: userCan('list','offices',true) ? "text-center" : "d-none",
                        isVisible: userCan('list','offices',true),
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.company.name}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Estado",
                        keyValue: "active",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className={'text-center'}>
                              <StatusDropdown
                                disabled={changingStatus.includes(element.id)}
                                itemId={element.id}
                                key={element.id}
                                status={element.active}
                                change={handleToggleStatus}
                              />
                            </div>
                          );
                        },
                      },
                      {
                        name: "Fecha de Creación",
                        keyValue: "createdAt",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return <div className="text-center">{moment(element.createdAt.date).format('DD/MM/YYYY HH:mm')}</div>;
                        },
                      },
                      { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]
                  }
                  actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-info',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${userCan('edit','offices')?'':'d-none'}`,
                      description: "Editar la oficina",
                      callback: (item: any) => {
                        if(userCan('edit','offices')) openModal(item, false);
                      },
                    },
        
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${userCan('delete','offices')?'':'d-none'}`,
                      description: "Eliminar Oficina",
                      callback: (item: any) => {
                        if(userCan('delete','offices')){
                          handleConfirmationAlert({
                            title: "Eliminar Oficina",
                            text: "¿Está seguro que desea eliminar la Oficina? Todos los usuarios que hereden o contengan esta Oficina quedarán huérfanos.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteOffice(item.id);
                            }
                          })
                        }
                      },
                    },
                  ]
                  }
                  />
              ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
          }
        </Card>
      </Page>
      {modal && (<OfficeModal isOpen={modal} setIsOpen={setModal} readMode={readMode} setReadMode={setReadMode} office={office}  />)}
    </Fragment>
  );
};

export default OfficesList;