import { useFormik } from "formik";
import { FC, Fragment, useState } from "react";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as Yup from "yup";
import AsyncImg from "../../components/AsyncImg";
import { validateOnlyCIF } from "../../utils/forms/ValidateDNICIFField";

interface BusinessFormProps {
    isLoading: boolean;
    submit: Function;
    businessData?: any;
}

export interface IBusinessForm {
    id?: string,
    name?: string,
    address?: string,
    cif?: string,
    company?: string,
    clawback_days: number,
    renewal_days_particular: number,
    renewal_days_business: number,
    reference_id?: string,
    shop_code?: string,
}

Yup.addMethod(Yup.string, 'isValidCIF', validateOnlyCIF);

const BusinessEditSchema = Yup.object({
    name: Yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    // @ts-ignore
    cif: Yup.string().required('El NIF del cliente es obligatorio').isValidCIF('El CIF no es válido'),
    address: Yup.string().notRequired().nullable(),
    clawback_days: Yup.number().typeError('Debe ser un número').transform((_, value) => {
        return Number(value);
    }).required('Campo Obligatorio').min(0, 'Debe ser un número positivo'),
    renewal_days_particular: Yup.number().typeError('Debe ser un número').transform((_, value) => {
        return Number(value);
    }).required('Campo Obligatorio').min(0, 'Debe ser un número positivo'),
    renewal_days_business: Yup.number().typeError('Debe ser un número').transform((_, value) => {
        return Number(value);
    }).required('Campo Obligatorio').min(0, 'Debe ser un número positivo'),
    reference_id: Yup.string().notRequired().nullable(),
    shop_code: Yup.string().notRequired().nullable(),
});

const BusinessForm: FC<BusinessFormProps> = ({ businessData, submit, isLoading }) => {

    const mode = businessData ? 'Editar' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<string | null>(businessData?.logo?.id || null);
    const [uploadImage, setuploadImage] = useState<any>(null);


    const businessInitialValues: IBusinessForm = {
        name: businessData?.name,
        company: '',
        address: businessData?.address,
        cif: businessData?.cif,
        clawback_days: businessData?.clawback_days,
        renewal_days_particular: businessData?.renewal_days_particular,
        renewal_days_business: businessData?.renewal_days_business,
        reference_id: businessData?.reference_id,
        shop_code: businessData?.shop_code,
    }

    const formik = useFormik({
        initialValues: businessInitialValues,
        validationSchema: BusinessEditSchema,
        onSubmit: values => {
            values.clawback_days = Number(values.clawback_days)
            if (businessData && businessData.clawback_days === null) {
                businessData.clawback_days = 0;
            }
            values.renewal_days_particular = Number(values.renewal_days_particular)
            if (businessData && businessData.renewal_days_particular === null) {
                businessData.renewal_days_particular = 0;
            }  
            values.renewal_days_business = Number(values.renewal_days_business)
            if (businessData && businessData.renewal_days_business === null) {
                businessData.renewal_days_business = 0;
            }         
            submit(values)
        },
    });


    const handleImageUpload = (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setuploadImage(reader.result);
        };
        reader.readAsDataURL(file);
        formik.setFieldValue('logo', event.currentTarget.files[0])
    };

    const clearImage = () => {
        setSelectedImage(null);
        setuploadImage(null);
        formik.setFieldValue('logo', null);
    }


    const verifyClass = (inputFieldID: keyof IBusinessForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof IBusinessForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
        }
        return <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <CardBody isScrollable={false}>
                    <div className="row">
                        <div className='col-md-6'>
                            <CardBody>
                                <div className="row align-items-center">
                                    <div className='col-lg-2'>
                                        {(uploadImage) ?
                                            <img width={70} height={70} src={uploadImage} alt="uploaded image" /> :
                                            (
                                                selectedImage ?
                                                    <AsyncImg height={"70px"} width={"70px"} id={selectedImage} />
                                                    : <PlaceholderImage width={70} height={70} className='mx-auto d-block img-fluid mb-3 rounded' />
                                            )}

                                    </div>
                                    <div className='col-lg-10'>
                                        <div className='row g-4'>
                                            <div className='col-8'>
                                                <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                                    autoComplete='photo' />
                                            </div>
                                            <div className='col-4'>
                                                <Button color='dark' isLight icon='Delete' onClick={clearImage} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Assignment' iconColor='info'>
                                    <CardTitle>Datos básicos</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <FormGroup requiredInputLabel label='Nombre de Comercializadora' className='col-md-6'>
                                        <Input required id='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='CIF' className='col-md-6'>
                                        <Input required id='cif' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.cif} className={verifyClass('cif')} />
                                        {showErrors('cif')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup label='Dirección' className='col-md-3'>
                                        <Input id='address' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Días de clawback' className='col-md-3'>
                                        <Input id='clawback_days' required onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.clawback_days} className={verifyClass('clawback_days')} />
                                        {showErrors('clawback_days')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Días de renovación (Particulares)' className='col-md-3'>
                                        <Input id='renewal_days_particular' required onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.renewal_days_particular} className={verifyClass('renewal_days_particular')} />
                                        {showErrors('renewal_days_particular')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Días de renovación (Empresas)' className='col-md-3'>
                                        <Input id='renewal_days_business' required onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.renewal_days_business} className={verifyClass('renewal_days_business')} />
                                        {showErrors('renewal_days_business')}
                                    </FormGroup>
                                    </div>
                                <div className="row mt-2">
                                    <FormGroup label='ID Referencia' className='col-md-6'>
                                        <Input id='reference_id' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.reference_id} className={verifyClass('reference_id')} />
                                        {showErrors('reference_id')}
                                    </FormGroup>
                                    <FormGroup label='Código Tienda' className='col-md-6'>
                                        <Input id='shop_code' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shop_code} className={verifyClass('shop_code')} />
                                        {showErrors('shop_code')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <CardFooterRight>
                        <Button type="submit" size='lg' color='primary' icon='Save'>
                            {isLoading ? <Spinner isSmall /> : `${mode} Comercializadora`}
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default BusinessForm;