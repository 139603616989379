import { FC, useCallback, useContext, useEffect, useState } from "react";
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import Spinner from "../../../components/bootstrap/Spinner";
import useFilters from "../../../hooks/useFilters";
import { toast } from "react-toastify";
import moment from "moment";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import ClientRelatedModal from "./ClientRelatedModal";
import Button from "../../../components/bootstrap/Button";
import { Link } from "react-router-dom";

interface ClientRelatedCardProps {
    id: string;
}

const ClientsRelatedCard: FC<ClientRelatedCardProps> = ({ id }) => {

    // STATES 

    const clientFilters = {client: id};

    const { userCan } = useContext(PrivilegeContext);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("");
    const [relatedClient, setRelatedClient] = useState<any>(null);

    // FILTERS

    const {
      filters,
      updateFilters,
      resetFilters,
      updateFilterOrder,
      updatePage,
      updatePageSize,
    } = useFilters(clientFilters, [], 50);

    // FUNCTIONS

    /**
     * 
     * @EN This function is responsible for closing the modal and updating the filters
     * @ES Esta función se encarga de cerrar el modal y actualizar los filtros  
     * 
     * @param message
     * @returns Close the modal and update the filters
     */

    const _onClose = (message?: string) => {
      if (message) {
        toast.success(message);
      }
      setIsOpen(false);
      setRelatedClient(null);
      updateFilters({ client: id });
    };

    /**
     * 
     * @EN This function is responsible for deleting a related client
     * @ES Esta función se encarga de eliminar un cliente relacionado
     * 
     * @param idrelation
     * @returns Delete a related client
     */

    const deleteRelation = async (relationId: string, relatedClientId: string) => {
      let response = (
        await new ClientService().deleteRelatedClient(relationId, id, relatedClientId)
      ).getResponseData();
      if (response.success) {
        toast.success("Cliente relacionado eliminado correctamente");
        refech();
      } else {
        toast.error("Error al eliminar el cliente relacionado");
      }
    };

    /**
     * 
     * @EN This function is responsible for obtaining the clients related to a client
     * @ES Esta función se encarga de obtener los clientes relacionados de un cliente
     * 
     * @returns Clients Related of a client
     */

    const fetchClientRelated = useCallback(async () => {
      const clientService = new ClientService();
      const response = await (
        await clientService.getRelatedClients(filters)
      ).getResponseData();
      if (response.success) {
        return response;
      }
    }, [filters]);

    // VARIABLES
  
    const [data, loading, error, refech] = useFetch(fetchClientRelated);

    // USE EFFECTS

    useEffect(() => {
      updateFilters({ client: id });
    }, [id]);

    // RENDER
      
    return <div>{
      data ? (
        <>
          <div className="row">
            { userCan("assign_related_clients", "clients") && (
            <div className="col-md-12 d-flex justify-content-end">

                <Button
                    icon="add"
                    color="primary"
                    size={'sm'}
                    isLight
                    onClick={() => {
                        setMode("create");
                        setIsOpen(true);
                    }}
                >
                    Nueva Relación
                </Button>
            </div> ) }
          </div>
          <CustomTable
              data={data.relatedClients}
              className={"table table-hover fs-7"}
              columns={[
                { 
                  name : "Tipo de Relación",
                  keyValue : "relationType",
                  className : "text-center",
                  render : (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        {element.relationType || "-"}
                      </div>
                    );
                  }
                },
                {
                  name: "Cliente Relacionado",
                  keyValue: "clientRelated",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <Link to={`/clients/${element.relatedClient.id}/show`} color="primary">
                          {element.relatedClient ? element.relatedClient.name : "-"}
                        </Link>
                      </div>
                    );
                  },
                },
                {
                  name: "Comentario",
                  keyValue: "comment",
                  className: "text-center",
                  cellClassName: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        {element?.comment}
                      </div>
                    );
                  },
                },
                {
                  name: "Fecha de Creación",
                  keyValue: "created_at",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  cellClassName: "text-center",
                  render: (element: any) => {
                    return (
                      <>
                        <div className="d-flex justify-content-center">
                          {element.createdAt ? moment(element.createdAt?.date).format("DD/MM/YYYY - HH:mm") : "-"}
                        </div>
                      </>
                    );
                  },
                },
                {
                  name: "Ult. Actualización",
                  keyValue: "updated_at",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  cellClassName: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        {element.updatedAt ? moment(element.updatedAt?.date).format("DD/MM/YYYY - HH:mm") : "-"}
                      </div>
                    );
                  },
                },
                {
                  name: "Acciones",
                  className: "min-w-100px text-end",
                  isActionCell: true,
                }
              ]}
              actions={[
                {
                  title: "Editar",
                  buttonType: "icon",
                  iconColor: "text-info",
                  iconPath: "/media/icons/duotune/general/gen055.svg",
                  hide:(item: any) => !userCan("assign_related_clients", "clients"),
                  description: "Editar el Comentario",
                  callback: (item: any) => {
                    if (userCan("assign_related_clients", "clients")) {
                      setRelatedClient(item);
                      setMode("edit");
                      setIsOpen(true);
                    }
                  },
                },
                {
                  title: "Eliminar",
                  buttonType: "icon",
                  iconColor: "text-danger",
                  iconPath: "/media/icons/duotune/general/gen027.svg",
                  hide:(item: any) => !userCan("remove_related_clients", "clients"),
                  description: "Eliminar el Comentario",
                  callback: (item: any) => {
                    if (userCan("remove_related_clients", "clients")) {
                      handleConfirmationAlert({
                        title: "Eliminar Relación",
                        text: "¿Está seguro que desea eliminar la relación con el cliente?",
                        icon: "warning",
                        onConfirm: () => {
                          deleteRelation(item.id , item.relatedClient.id);
                        },
                      });
                    }
                  },
                }
              ]}
            />
            <ClientRelatedModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              client={id}
              relation={relatedClient}
              onClose={_onClose}
              mode={mode}
            />
          </>
        ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
      }</div>;
  };
  
  export default ClientsRelatedCard;
  