import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { FC, useEffect, useState } from "react";
import { CUSTOMTABS, customTabType } from "../OperationStatistic";
import { ExpenseService } from "../../../../services/expenses/expenseService";
import { DashboardService } from "../../../../services/dashboard/DashboardService";
ChartJS.register(ArcElement, Tooltip, Legend);

interface IOperacionPieProps {
  activeTab: customTabType;
  date: any;
  dateDiff: number;
  showCommission?: boolean;
}

const OperacionPie: FC<IOperacionPieProps> = ({
  date,
  activeTab,
  dateDiff,
  showCommission,
}) => {
  const [totalExpenses, setTotalExpenses] = useState<number | null>(null);

  const [totalCommission, setTotalCommission] = useState<number | null>(null);

  const getTotalExpenses = async () => {
    const expenseService = new ExpenseService();
    if (date.start === "" || date.end === "") return;
    const response = await expenseService.getSumBetweenDates(
      date.start,
      date.end
    );
    if (response.getResponseData()?.success) {
      setTotalExpenses(response.getResponseData().data.sum);
    } else {
      setTotalExpenses(0);
    }
  };

  const getType = () => {
    if (activeTab === CUSTOMTABS.YEARLY) {
      return "year";
    }
    if (activeTab === CUSTOMTABS.MONTHLY) {
      return "month";
    }
    if (activeTab === CUSTOMTABS.WEEKLY) {
      return "week";
    }
    if (activeTab === CUSTOMTABS.TODAY) {
      return "today";
    }
    return "custom";
  };

  const getCommissionedContracts = async () => {
    const dashboardService = new DashboardService();
    if (date.start === "" || date.end === "") return;
    const response = await (
      await dashboardService.getTotalCommissionedOperationsAndPercentage({
        filter_filters: {
          dateFrom: date.start,
          dateTo: date.end,
          periodType: getType(),
          dateDifference: dateDiff,
        },
      })
    ).getResponseData();
    if (response.success) {
      setTotalCommission(response.data.actualTotal);
    }
  };

  useEffect(() => {
    if (date.start !== "" && date.end !== "") {
      getCommissionedContracts()
      getTotalExpenses();
    }
  }, [activeTab, dateDiff, date, showCommission]);

  const pieData = {
    labels:
      totalExpenses != null && totalCommission != null
        ? ["Commissiones", "Gastos"]
        : ["No hay datos"],
    datasets: [
      {
        label: "Total",
        data:
          totalExpenses != null  && totalCommission != null
            ? [totalCommission, totalExpenses]
            : [1],
        backgroundColor:
          totalExpenses != null && totalCommission != null
            ? ["#FFBAD0", "#D7E1CE"]
            : ["#B2BABB"],
        borderColor:
          totalExpenses != null && totalCommission != null
            ? ["#FFBAD0", "#D7E1CE"]
            : ["#B2BABB"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
        <Pie
          data={pieData}
          options={{
            maintainAspectRatio: false,
            plugins: { legend: { position: "bottom" } , tooltip: { enabled: showCommission } },
          }}
          style={{
            width: "200px",
            height: "200px",
            maxWidth: "450px",
            maxHeight: "400px",
          }}
        />
    </>
  );
};

export default OperacionPie;
