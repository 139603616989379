import { useFormik } from "formik";
import { FC, useCallback } from "react";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import { ServiceService } from "../../../../services/services/servicesService";
import { NewService, Service, UpdateService } from "../../../../type/service-type";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useFetch from "../../../../hooks/useFetch";


interface ServiceModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    serviceId: string;
    businessId: string;
}

const ServiceModal: FC<ServiceModalProps> = ({ isOpen, setIsOpen, readMode, serviceId, businessId }) => {
    const fetchService = useCallback(async () => {
        if (serviceId) {
            const response = await (new ServiceService()).getServiceById(serviceId);
            return response.getResponseData();
        }
    }, [serviceId]);

    const [data, loading, error] = useFetch(fetchService);

    const createService = async (values: NewService) => {
        try {
            let response = await (await (new ServiceService()).createService(values)).getResponseData();
            if (response.success) {
                toast.success('Servicio creado con éxito');
            } else {
                toast.error('Error al crear Servicio');
            }
        } catch (e) {
            toast.error('Error al crear Servicio');
            console.log('error', e);
        } finally {
            setIsOpen(false);
        }

    }

    const updateService = async (values: UpdateService) => {
        try {
            values.service = serviceId;
            let response = await (await (new ServiceService()).editService(values)).getResponseData();
            if (response.success) {
                toast.success('Servicio editado con éxito');
            } else {
                toast.error('Error al editar Servicio');
            }
        } catch (e) {
            toast.error('Error al editar Servicio');
            console.log('error', e);
        } finally {
            setIsOpen(false);
        }

    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data ? data.name : '',
            description: data ? data.description : '',
            service: serviceId ? serviceId : '',
            business: businessId ? businessId : '',
        },
        onSubmit: (values) => {
            if (serviceId == undefined || serviceId === '') {
                createService(values);
            }else{
                updateService(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode?(serviceId ? 'Editar':'Nuevo'):'Ver'}  Servicio</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6'>
                            <Input disabled={readMode} value={formik.values.name} required onChange={formik.handleChange} />
                        </FormGroup>
                        <FormGroup id='description' label='Breve descripción' className='col-md-6'>
                            <Input disabled={readMode} value={formik.values.description} onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                </ModalBody>
                {(!readMode)?
                    <ModalFooter className='px-4 pb-4'>
                        <Button icon="Save" color='primary' type="submit">
                            Guardar Servicio
                        </Button>
                    </ModalFooter>
                : <></>}
            </form>
        </Modal>
    )
}

export default ServiceModal;