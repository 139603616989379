import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import dashboardReducer from './dashboardSlice';
import activeTabReducer from './activeTabSlice';
import {loadState, saveState} from './browser-storage';
import {debounce} from "../helpers/helpers";

const store = configureStore({
  reducer: {
    auth: authReducer,
    activeTab: activeTabReducer,
    dashboard: dashboardReducer,
  },
  preloadedState: loadState(),
});

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
