import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ProductTypeService } from "../../../services/products/productTypeService";
import { ProductTypesApiResponse } from "../../../type/product-type";
import ProductTypeFilters from "./product-type-options/ProductTypeFilters";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CustomTable } from "../../../components/table/CustomTable";
import StatusDropdown from "../../../components/StatusDropdown";
import moment from "moment";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import ProductTypeModal from "../product-create/products-components/ProductTypeModal";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const ProductTypeList = () => {
  const {userCan} = useContext(PrivilegeContext);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const [productType, setproductType] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);

  const fetchProductTypes = useCallback(async () => {
    const productTypeService = new ProductTypeService();
    const response = await productTypeService.getProductTypes(filters);
    return response.getResponseData() as ProductTypesApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refetch] = useFetch(fetchProductTypes);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const deleteProductType = async (id: string) => {
    let response = (await (new ProductTypeService()).deleteProductType(id)).getResponseData();
    if (response.success) {
      toast.success('Tipo de producto eliminado correctamente');
      updateFilters({...filters});
    }
  }

  const toggleProductTypeStatus = async (
		id: string,
		status: boolean,
		toggleStatus: Function
	) => {
		try {
			setChangingStatus([...changingStatus, id]);
			let response = await (
				await new ProductTypeService().toggleProductTypeStatus(id, status)
			).getResponseData();
			if (response.success) {
				setChangingStatus(changingStatus.filter((item) => item !== id));
				toggleStatus(status);
			} else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
			}
		} catch (error: any) {
			setChangingStatus(changingStatus.filter((item) => item !== id));
		}
    refetch();
	};

  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setproductType(item);
    setModal(true);
}

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Tipos de Productos</CardTitle>
            {userCan('manage_types','products')?
            <>
              <SubheaderSeparator />
              <Button
                color="light"
                icon="Add"
                isLight
                onClick={() => {
                  openModal(null, false);
                }}
              >

                Añadir Tipo de Producto
              </Button> 
            </> :<></> }
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <ProductTypeFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={false}>
          {
            data ? (
              <CustomTable
                data={data?.productTypes ? data.productTypes : null}
                pagination={true}
                defaultLimit={data?.limit ? data.limit : 50}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: data as ProductTypesApiResponse ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                className={"table table-hover"}
                columns={[
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {openModal(element, true)}}>
                            {element.name ? element.name : '' }
                          </div>
                        )
                      },
                    },
                    {
                      name: "Descripción",
                      keyValue: "description",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Organización",
                      keyValue: "company",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: userCan('manage_types','products',true) ? "text-center" : "d-none",
                      isVisible: userCan('manage_types','products',true),
                      render: (element: any) => {
                        if (element.company) {
                          return (
                            <div key={element.company.id} className="d-flex justify-content-center">
                            {element.company.name ?? ''}
                          </div>
                        )
                        }else return (<></>);
                      }
                    },
                    {
                      name: "Nº Productos",
                      keyValue: "products",
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            {element.products.length}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Estado",
                      keyValue: "active",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <StatusDropdown
                              disabled={changingStatus.includes(element.id)}
                              itemId={element.id}
                              key={element.id}
                              status={element.active}
                              change={toggleProductTypeStatus}
                            />
                          </div>
                        
                        );
                      },
                    },
                    {
                      name: "Fecha de creación",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                          </div>
                        )
                      },
                    },
                    {
                      name: "Fecha de actualización",
                      keyValue: "updatedAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                          </div>
                        )
                      },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={[
                  {
                    title: "Ver",
                    buttonType: 'icon',
                    iconColor: 'text-primary',
                    iconPath: '/media/icons/duotune/general/gen021.svg',
                    additionalClasses: 'text-primary',
                    description: "Ver Tipo de Producto",
                    callback: (item: any) => {
                      if(userCan('manage_types','products'))openModal(item, true);
                    },
                    
                  },
                  {
                    title: "Editar",
                    buttonType: 'icon',
                    iconColor: 'text-info',
                    iconPath: '/media/icons/duotune/general/gen055.svg',
                    additionalClasses: 'text-primary',
                    description: "Editar Tipo de Producto",
                    callback: (item: any) => {
                      if(userCan('manage_types','products')) openModal(item, false);
                    },
                  },
                  {
                    title: "Eliminar",
                    buttonType: 'icon',
                    iconColor: 'text-danger',
                    iconPath: '/media/icons/duotune/general/gen027.svg',
                    additionalClasses: 'text-danger',
                    description: "Eliminar Tipo de Producto",
                    callback: (item: any) => {
                      if(userCan('manage_types','products')){
                        handleConfirmationAlert({
                          title: "Eliminar tipo de producto",
                          text: "¿Está seguro que desea eliminar el tipo de Producto?",
                          icon: "warning",
                          onConfirm: () => {
                            deleteProductType(item.id);
                          }
                        })
                      }
                    },
                  },
                ]}
              />
            ) : (
              <div className="text-center d-flex justify-content-center"><Spinner/></div>
            )
          }
        </Card>
      </Page>
      {modal && (<ProductTypeModal isOpen={modal} setIsOpen={setModal} setReadMode={() => setReadMode(false)} readMode={readMode} productType={productType} />)}
    </Fragment>
  );
};

export default ProductTypeList;
