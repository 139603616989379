import { useFormik } from "formik";
import { FC, useCallback } from "react";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import { FeeService } from "../../../../services/fees/feeService";
import { NewFee, UpdateFee } from "../../../../type/fee-type";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useFetch from "../../../../hooks/useFetch";


interface FeeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    businessId: string;
    feeId: string;
}

const FeeModal: FC<FeeModalProps> = ({ isOpen, setIsOpen, readMode, businessId, feeId}) => {
    const fetchFee = useCallback(async () => {
        if (feeId) {
            const response = await (new FeeService()).getFeeById(feeId);
            return response.getResponseData();
        }
    }, [feeId]);

    const [data, loading, error] = useFetch(fetchFee);

    const createFee = async (values: NewFee) => {
        try {
            let response = await (await (new FeeService()).createFee(values)).getResponseData();
            if (response.success) {
                toast.success('Tarifa creada con éxito');
            } else {
                toast.error('Error al crear Tarifa');
            }
        } catch (e) {
            toast.error('Error al crear Tarifa');      
            console.log('error', e);
        } finally {
            setIsOpen(false);
        }

    }

    const updateFee = async (values: UpdateFee) => {
        try {
            values.fee = feeId;            
            let response = await (await (new FeeService()).editService(values)).getResponseData();
            if (response.success) {
                toast.success('Tarifa editada con éxito');
            } else {
                toast.error('Error al editar Tarifa');
            }
        } catch (e) {
            toast.error('Error al editar Tarifa');      
            console.log('error', e);
        } finally {
            setIsOpen(false);
        }

    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data ? data.name : '',
            description: data ? data.description : '',
            business: businessId ? businessId : '',
        },
        onSubmit: (values) => {
            if(feeId === undefined || feeId === ''){
                createFee(values as NewFee);
            }else{
                updateFee(values as UpdateFee);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode?(feeId ? 'Editar':'Nuevo'):'Ver'}  Tarifa</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6'>
                            <Input disabled={readMode} value={formik.values.name} required onChange={formik.handleChange} />
                        </FormGroup>
                        <FormGroup id='description' label='Breve descripción' className='col-md-6'>
                            <Input disabled={readMode} value={formik.values.description} onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                </ModalBody>
                {(!readMode)?
                    <ModalFooter className='px-4 pb-4'>
                        <Button icon="Save" color='primary' type="submit">
                            Guardar Tarifa
                        </Button>
                    </ModalFooter>
                : <></>}
            </form>
        </Modal>
    )
}

export default FeeModal;