import { useFormik } from "formik";
import { FC, useState } from "react";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Button from "../../../../components/bootstrap/Button";
import { ProductTypeService } from "../../../../services/products/productTypeService";
import {ProductType } from "../../../../type/product-type";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../../../components/bootstrap/Spinner";


interface ProductTypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    setReadMode(...args: unknown[]): unknown;
    productType: ProductType | null;
}

const ProductTypeModal: FC<ProductTypeModalProps> = ({ isOpen, setIsOpen, readMode, setReadMode, productType }) => {
    const [loadign, setLoading] = useState(false);

    const createProductType = async (values: any) => {
        try {
            setLoading(true);
            let response = (await (new ProductTypeService()).createProductType(values)).getResponseData();
            if (response.success) {
                toast.success(response.message || 'Producto creado con éxito');
            } else {
                toast.error(response.message || 'Error al crear Producto');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al crear Producto');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const updateProductType = async (values: any) => {
        try {
            setLoading(true);
            if(productType) values.product_type = productType.id;
            let response = await (await (new ProductTypeService()).editProductType(values)).getResponseData();
            if (response.success) {
                toast.success(response.message || 'Producto editado con éxito');
            } else {
                toast.error(response.message || 'Error al editar Producto');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al editar Producto');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: productType ? productType.name : '',
            description: productType ? productType.description : '',
            company: '',

        },
        onSubmit: (values) => {
            if (productType === null) {
                createProductType(values);
            }else{
                updateProductType(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode?(productType ? 'Editar':'Nuevo'):'Ver'}  Tipo de Producto</ModalTitle>
            </ModalHeader>
                <form onSubmit={formik.handleSubmit}>

                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel label='Nombre' className='col-md-6'>
                                <Input disabled={readMode} id="name" value={formik.values.name || ''} required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup label='Breve descripción' className='col-md-6'>
                                <Input disabled={readMode} id="description" value={formik.values.description || ''} onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter className='px-4 pb-4'>
                        {!readMode ? (
                        <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                            {loadign  ? <Spinner className="text-center"/> : "Guardar Tipo de Producto"}
                        </Button> ): (<></>)}
                    </ModalFooter>
                </form>

        </Modal>
    )
}

export default ProductTypeModal;