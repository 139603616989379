import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import Spinner from '../../../../components/bootstrap/Spinner'
import { useSelector } from 'react-redux'
import { AuthState } from '../../../../redux/authSlice'
import StatusSelector from '../../../../components/selectors/status_selector/StatusSelector'
import { ActionStatus } from '../../../../type/actionStatus-type'
import { usePrivilege } from '../../../../components/priviledge/PriviledgeProvider'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import { useActionStatusCommissionList, useActionStatusContractList, useActionStatusOperationList, useBusinessList, useLeadList, useOfficeList, useOperationAgentsList, useOperationAgentsListSimple, useOperationTypeList, useOriginList, useUserList } from '../../../../hooks/useListData'
import CustomSelect from '../../../../components/selectors/general/CustomSelect'
import { MultiValue } from 'react-select'
import moment from "moment";

interface IOperationsFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number , hardReset : boolean) => void
  filters: any
}

const OperationsFilters: FC<IOperationsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false);

  const rol = useSelector((state: { auth: AuthState }) => {
    const userRoles = state.auth.user?.roles;
    return userRoles && (userRoles.includes('Superadministrador') || userRoles.includes('Administrador')) ? 'admin' : userRoles?.[0];
  });

  const { userCan } = usePrivilege();

  const userlist = useOperationAgentsList({filter_filters: { active : 1 } , all: true});

  const statusOperationList = useActionStatusOperationList();

  const statusContractList = useActionStatusContractList();

  const statusCommissionList = useActionStatusCommissionList();

  const businessList = useBusinessList({filter_filters: { active : 1 } , all: true});

  const operationsTypeList = useOperationTypeList({filter_filters: { active : 1 } , all: true});

  const originList = useOriginList({filter_filters: { active : 1 } , all: true});
  
  const officeList = useOfficeList({filter_filters: { active : 1 }, all: true});

  const leadList = useLeadList({filter_filters: { active : 1 }, all: true});

  const handleTextChange = (search: string) => {
    updateFilters({ search_text: search });
  };

  const onChangeAgents = (newValue: MultiValue<unknown>) => {
    let agents = newValue.map((value: any) => value.value);
    updateFilters({ agents: agents });
  }

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters.search_text || ''}/>
      
      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Contratos </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            <div className='col-12'>
              <FormGroup id='current_action_status'>
                <StatusSelector isMulti entity='operation' name="Estados" defaultSelected={filters.filter_filters.current_action_status ? filters.filter_filters.current_action_status : []} onChange={(value: ActionStatus[]) => {
                  updateFilters({ current_action_status: value })
                }} data={statusOperationList}></StatusSelector>
              </FormGroup>
            </div>
            {
              userCan('show_contract_status', 'operations') &&
              (
                <div className='col-12'>
                  <FormGroup id='current_contract_status'>
                    <StatusSelector isMulti entity='contract' name="Estados de contrato" defaultSelected={filters.filter_filters.current_contract_status ? filters.filter_filters.current_contract_status : []} onChange={(value: ActionStatus[]) => {
                      updateFilters({ current_contract_status: value })
                    }} data={statusContractList}></StatusSelector>
                  </FormGroup>
                </div>
              )
            }
            {
              userCan('show_commission_status', 'operations') &&
              (
                <div className='col-12'>
                  <FormGroup id='current_commission_status'>
                    <StatusSelector isMulti entity='commission' name="Estados de comisión" defaultSelected={filters.filter_filters.current_commission_status ? filters.filter_filters.current_commission_status : []} onChange={(value: ActionStatus[]) => {
                      updateFilters({ current_commission_status: value })
                    }} data={statusCommissionList}></StatusSelector>
                  </FormGroup>
                </div>
              )
            }

            <div className='col-12'>
              <FormGroup id='filter1' label='Nombre'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })}
                  value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter2' label='Localidad'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ locality: e.target.value })}
                  value={filters.filter_filters.locality} id='locality' placeholder='Localidad...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter3' label='Provincia'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ province: e.target.value })}
                  value={filters.filter_filters.province} id='province' placeholder='Provincia...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter4' label='Tipo de Contrato' key={filters.filter_filters.operation_type}>
                {(operationsTypeList) ? (
                  <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ operation_type: e.target.value })} value={filters.filter_filters.operation_type} ariaLabel='Default select example' placeholder='Tipo de Contrato...' list={operationsTypeList}
                  />
                ) : (<Spinner isSmall />)}
              </FormGroup>
            </div>
            {
              userCan('list_all', 'operations') && (
                <div className={'d-block col-12'}>
                  <FormGroup id='filter5' label='Agente' key={filters.filter_filters.agents}>
                    {(userlist.length > 0) ? (
                      <CustomSelect options={userlist} is_multi={true} 
                      defaultValue={filters.filter_filters.agents ? userlist.filter((user: any) => filters.filter_filters.agents.includes(user.value)): []} 
                      placeholder='Elegir Usuario...' onChange={onChangeAgents} />
                    ) : (<Spinner isSmall />)}
                  </FormGroup>
                </div>
              )
            }
            {
              userCan('list', 'offices') && (
                <div className='col-12'>
                  <FormGroup id='filter6' label='Oficina' key={filters.filter_filters.office}>
                    {(officeList) ? (
                      <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ office: e.target.value })} value={filters.filter_filters.office} ariaLabel='Default select example' placeholder='Oficina...' list={officeList}
                      />
                    ) : (<Spinner isSmall />)}
                  </FormGroup>
                </div>
              )
            }

            <div className='col-12'>
              <FormGroup id='filter7' label='Tipo de Trámite' key={filters.filter_filters.lead}>
                {(leadList) ? (
                  <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ lead: e.target.value })} value={filters.filter_filters.lead} ariaLabel='Default select example' placeholder='Tipo de Trámite...' list={leadList}
                  />
                ) : (<Spinner isSmall />)}
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup id='filter8' label='Origen Lead' key={filters.filter_filters.origin}>
                {(leadList) ? (
                  <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ origin: e.target.value })} value={filters.filter_filters.origin} ariaLabel='Default select example' placeholder='Origen Lead...' list={originList}
                  />
                ) : (<Spinner isSmall />)}
              </FormGroup>
            </div>

            <div className='row g-4 mt-1'>
              <FormGroup label='Fecha Inicio' className='col-md-6'>
                <Input type='date'
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                         if(!e.target.value) {
                           updateFilters({ from: null })
                           return
                         }
                         updateFilters({ from: moment(e.target.value).set({hour: 0, minute: 0, second: 0}).format('YYYY-MM-DDTHH:mm') })
                       }}
                       value={filters.filter_filters.from ? moment(filters.filter_filters.from).format('YYYY-MM-DD') : ''}
                       ariaLabel='Default select example'
                       placeholder='Fecha Inicial...' />
              </FormGroup>

              <FormGroup label='Fecha Final' className='col-md-6'>
                <Input type='date'
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                         if(!e.target.value) {
                           updateFilters({ to: null })
                           return
                         }
                         updateFilters({ to: moment(e.target.value).set({hour: 23, minute: 59, second: 59}).format('YYYY-MM-DDTHH:mm') })
                       }}
                       value={filters.filter_filters.to ? moment(filters.filter_filters.to).format('YYYY-MM-DD') : ''}
                       ariaLabel='Default select example'
                       placeholder='Fecha Final...' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter11' label='Clientes' key={filters.filter_filters.favourite}>
                <Select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ favourite: parseInt(e.target.value) })}
                  value={filters.filter_filters.favourite}
                  id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                  list={[{ value: 2, label: 'Todos los Clientes', text: 'Todos los Clientes' }, { value: 1, label: 'Clientes favoritos', text: 'Clientes favoritos' }, { value: 0, label: 'Clientes no favoritos', text: 'Clientes no favoritos' }]}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter12' label='CUPS'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ cups: e.target.value })}
                  value={filters.filter_filters.cups} id='cups' placeholder='CUPS...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter13' label='Comercializadora' key={filters.filter_filters.business}>
                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ business: e.target.value })} value={filters.filter_filters.business} ariaLabel='Default select example' placeholder='Elegir Comercializadora...' list={businessList}/>
              </FormGroup>
            </div>
            {userCan("view_differents_phone_iban", "operations") && (
            <>
              <div className='col-12'>
                <FormGroup id='filter14' label='Iban Diferente' key={filters.filter_filters.has_duplicate_iban}>
                  <Select
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ has_duplicate_iban: parseInt(e.target.value) })}
                    value={filters.filter_filters.has_duplicate_iban}
                    id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                    list={[{ value: 2, label: 'Todos los Contratos', text: 'Todos los Contratos' }, { value: 1, label: 'Iban Diferentes', text: 'Iban Diferentes' }, { value: 3, label: 'Iban Iguales', text: 'Iban Iguales' }]}
                  />
                </FormGroup>
              </div>
              <div className='col-12'>
                <FormGroup id='filter15' label='Telefono Diferente' key={filters.filter_filters.has_duplicate_phone}>
                  <Select
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ has_duplicate_phone: parseInt(e.target.value) })}
                    value={filters.filter_filters.has_duplicate_phone}
                    id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                    list={[{ value: 2, label: 'Todos los Contratos', text: 'Todos los Contratos' }, { value: 1, label: 'Telefonos Diferentes', text: 'Telefonos Diferentes' }, { value: 3, label: 'Telefonos Iguales', text: 'Telefonos Iguales' }]}
                  />
                </FormGroup>
              </div>
            </>
            )}
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => {
                resetFilters(50, true)
              }}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    </Fragment>
  )
}

export default OperationsFilters