import { FC, Fragment, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ClientForm from "../ClientForm";
import { ClientService } from "../../../services/clients/clientService";


const ClientCreate: FC = () => {

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
        try {
			setLoading(true)
			let response = await (await (new ClientService()).createClient(values)).getResponseData();
			if(response.success) {
				
				toast.success(response.message);

				// Redirect to view client after create if query param is true
				if(searchParams.get('redirectToView') === 'true') {
					navigate(`/clients/${response.data.id}/show`, {replace: true});
					return;
				}

				navigate('/clients', {replace: true});
			} else {

				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear el Cliente');
		}finally{
			setLoading(false);
		}
	}

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/clients', { replace: true })}>
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
					<ClientForm submit={handleCreation} isLoading={loading}/>
			</Page>
		</Fragment>
	)
}

export default ClientCreate;