import { FiltersProvider } from "../../components/providers/FiltersProvider";
import OfficesList from "./offices-list/OfficesList";

const OfficesListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <OfficesList />
        </FiltersProvider>
      </>
    );
  };

export default OfficesListWrapper;