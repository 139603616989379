import {
	dashboardPagesMenu,
	superAdminMenu,
	companiesMenu,
	productMenu,
	expensesMenu,
	operationsMenu,
	usersMenu,
	permissionsPage,
	officesMenu,
	campaignsMenu,
	clientsMenu,
	operationAdminMenu,
	documentsMenu,
	pendingTasksMenu,
	workMenu,
	walletMenu,
	callMenu,
	rankingMenu,
} from '../menu';
import BusinessWrapper from '../pages/business';
import CreateBusiness from '../pages/business/business-create';
import BusinessEdit from '../pages/business/business-edit/BusinessEdit';
import BusinessView from '../pages/business/business-view/BusinessView';
import CompanyWrapper from '../pages/companies';
import CompanyCreate from '../pages/companies/company-create';
import CompanyEdit from '../pages/companies/company-edit/OrganizationEdit';
import CompanyProfile from '../pages/companies/company-profile/OrganizationProfile';
import RoleWrapper from '../pages/roles';
import UsersWrapper from '../pages/users';
import CreateUser from '../pages/users/user-create';
import UserEdit from '../pages/users/user-edit/UserEdit';
import UserProfile from '../pages/users/user-profile/UserProfile';
import UsersList from '../pages/users/users-list/UsersList';
import CreateProduct from '../pages/products/product-create/CreateProduct';
import EditProduct from '../pages/products/product-create/EditProduct';
import ProductsWrapper from '../pages/products';
import ProductTypesWrapper from '../pages/products/ProductTypesWrapper';
import RoleEditPermissions from '../pages/roles/role-edit/RoleEditPermissions';
import ExpenseWrapper from '../pages/expenses';
import ExpenseTypesWrapper from '../pages/expenses/ExpenseTypesWrapper';
import OperationsWrapper from '../pages/operations';
import OperationTypesWrapper from '../pages/operations/OperationTypesWrapper';
import DashboardWrapper from "../pages/dashboard/DashboardWrapper";
import DashboardPage from "../pages/dashboard/main-dashboard/DashboardPage";
import OperationCreate from '../pages/operations/operations-create/OperationCreate';
import ActionStatusWrapper from '../pages/actionStatus';
import PagePermissions from '../pages/auth/PagePermissions';
import OperationListWrapper from '../pages/operations/OperationListWrapper';
import LeadsWrapper from '../pages/leads/LeadsWrapper';
import OfficesWrapper from '../pages/offices/OfficesWrapper';
import { ProductImport } from '../pages/products/product-import/ProductImport';
import OperationView from '../pages/operations/operation-view/OperationView';
import CampaignsWrapper from '../pages/campaigns';
import CampaignEdit from '../pages/campaigns/campaign-edit/CampaignEdit';
import ProductView from '../pages/products/product-view/ProductView';
import ClientsWrapper from '../pages/clients';
import ClientCreate from '../pages/clients/client-create';
import ClientEdit from '../pages/clients/client-edit';
import ClientView from '../pages/clients/clients-view/ClientsView';
import ClientListWrapper from '../pages/clients/ClientListIndex';
import OperationStatistic from '../pages/dashboard/agent-dashboard/OperationStatistic';
import DocumentsWrapper from '../pages/documents';
import PendingTaskWrapper from '../pages/pendingTask';
import OriginsWrapper from '../pages/origins/OriginWrapper';
import WorkWrapper from '../pages/work/WorkWrapper';
import NewWork from '../pages/work/NewWork';
import CampaginListWrapper from '../pages/campaigns/CampaignListIndex';
import ActionStatusListWrapper from '../pages/actionStatus/ActionStatusListIndex';
import DocumentTypeListWrapper from '../pages/documents/DocumentTypeListIndex';
import ExpenseTypeListWrapper from '../pages/expenses/ExpenseTypeListIndex';
import ExpenseListWrapper from '../pages/expenses/ExpenseListIndex';
import LeadListWrapper from '../pages/leads/LeadListIndex';
import OperationTypeListWrapper from '../pages/operations/OperationTypesListIndex';
import BusinessListWrapper from '../pages/business/BusinessListIndex';
import OfficesListWrapper from '../pages/offices/OfficeListIndex';
import OriginListWrapper from '../pages/origins/OriginListIndex';
import PendingTaskListWrapper from '../pages/pendingTask/PendingTaskListIndex';
import ProductTypeListWrapper from '../pages/products/ProductTypeListIndex';
import ProductListWrapper from '../pages/products/ProductListIndex';
import UserListWrapper from '../pages/users/UserListIndex';
import RoleListWrapper from '../pages/roles/RoleListIndex';
import OrganizationListWrapper from '../pages/companies/CompaniesListIndex';
import WalletListWrapper from '../pages/wallet/WalletListWrapper';
import WalletConfigPage from '../pages/config/wallet-config/WalletConfigPage';
import CallListWrapper from '../pages/call/CallListIndex';
import RankingWrapper from '../pages/ranking/RankingWrapper';
import RankingListWrapper from '../pages/ranking/RankingListIndex';
import WalletView from '../pages/wallet/wallet-view/WalletView';
import WalletWrapper from '../pages/wallet';
import WalletConfigChangeStatus from '../pages/config/wallet-config/pages/WalletConfigChangeStatus';
import WalletConfigAditionalInfo from '../pages/config/wallet-config/pages/WalletConfigAditionaInfo';

const protectedRoutes = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DashboardWrapper/>,
		sub: [
			{
				element: <DashboardPage/>,
				index: true
			},
		]
	},
	{
		path: dashboardPagesMenu.operationStats.path,
		element: <DashboardWrapper/>,
		sub: [
			{
				element: <OperationStatistic/>,
				index: true
			},
		]
	},
	{
		path: superAdminMenu.organizations.path,
		element: <CompanyWrapper/>,
		sub: [
			{
				element: <OrganizationListWrapper/>,
				access: {
					group: 'companies',
					action: 'list'
				},
				index: true
			},
			{	
				path: 'create',
				access: {
					group: 'companies',
					action: 'create'
				},
				element: <CompanyCreate/>,
			},
			{	
				path: ':id/edit',
				access: {
					group: 'companies',
					action: 'edit'
				},
				element: <CompanyEdit/>,
			},
			{	
				path: ':id/profile',
				access: {
					group: 'companies',
					action: 'show'
				},
				element: <CompanyProfile/>,
			}
		]
	},
	{
		path: usersMenu.users.path,
		element: <UsersWrapper/>,
		sub: [
			{
				element: <UserListWrapper/>,
				access: {
					group: 'users',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/profile',
				access: {
					group: 'users',
					action: 'show'
				},
				element: <UserProfile/>,
			},
			{
				path: ':id/edit',
				access: {
					group: 'users',
					action: 'edit'
				},
				element: <UserEdit/>,
			},
			{
				path: 'create',
				access: {
					group: 'users',
					action: 'create'
				},
				element: <CreateUser/>,
			}	
		]
	},
	/**Duplicated in order to fast solve / problem, may check how to multipathing
	 * in react router v6 before having a dashboard
	 */
	{
		path: '/',
		element: <UsersWrapper/>,
		sub: [
			{
				element: <UserListWrapper/>,
				access: {
					group: 'users',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/profile',
				access: {
					group: 'users',
					action: 'show'
				},
				element: <UserProfile/>,
			},
			{
				path: ':id/edit',
				access: {
					group: 'users',
					action: 'edit'
				},
				element: <UserEdit/>,
			},
			{
				path: 'create',
				access: {
					group: 'users',
					action: 'create'
				},
				element: <CreateUser/>,
			},	
		]
	},
	{
		path: superAdminMenu.roles.path,
		element: <RoleWrapper/>,
		isProtected: true,
		sub: [
			{
				element: <RoleListWrapper/>,
				access: {
					group: 'roles',
					action: 'list'
				},
				index: true
			},
			{
				path: ':id/edit',
				access: {
					group: 'roles',
					action: 'edit'
				},
				element: <RoleEditPermissions />,
			}
		]
	},
	{
		path: clientsMenu.clients.path,
		element: <ClientsWrapper/>,
		sub: [
			{
				element: <ClientListWrapper/>,
				index: true,
			},		
			{
				path: 'edit/:id',
				access: {
					group: 'clients',
					action: 'edit'
				},
				element: <ClientEdit/>,
			},
			{
				path: 'create',
				access: {
					group: 'clients',
					action: 'create'
				},
				element: <ClientCreate/>,
			},
			{
				path: ':id/show/:tabURL',
				access: {
					group: 'clients',
					action: 'show'
				},
				element: <ClientView/>,
			},
			{
				path: ':id/show',
				access: {
					group: 'clients',
					action: 'show'
				},
				element: <ClientView/>,
			},
		]
	},
	{
		path: companiesMenu.companies.path,
		element: <BusinessWrapper/>,
		sub: [
			{
				element: <BusinessListWrapper/>,
				access: {
					group: 'businesses',
					action: 'list'
				},
				index: true
			},
			{
				path: ':id/profile',
				access: {
					group: 'businesses',
					action: 'show'
				},
				element: <BusinessView/>,
			},
			{
				path: ':id/profile/products/create',
				access: {
					group: 'products',
					action: 'edit'
				},
				element: <CreateProduct/>,
			},
			{
				path: ':idcm/profile/products/:id/edit',
				access: {
					group: 'products',
					action: 'edit'
				},
				element: <EditProduct/>,
			},
			{
				path: ':idcm/profile/products/:id/show',
				access: {
					group: 'products',
					action: 'edit'
				},
				element: <ProductView/>,
			},
			{
				path: ':id/edit',
				access: {
					group: 'businesses',
					action: 'edit'
				},
				element: <BusinessEdit/>,
			},
			{
				path: 'create',
				access: {
					group: 'businesses',
					action: 'create'
				},
				element: <CreateBusiness/>,
			}	
		]
	},
	{
		element: <OfficesWrapper/>,
		path: officesMenu.offices.subMenu.officeList.path,
		sub: [
			{
				element: <OfficesListWrapper/>,
				access: {
					group: 'offices',
					action: 'list'
				},
				index: true,
			}
		]
	},
	{
		element: <RankingWrapper/>,
		path: rankingMenu.ranking.subMenu.rankingList.path,
		sub: [
			{
				element: <RankingListWrapper/>,
				access: {
					group: 'ranking',
					action: 'list'
				},
				index: true,
			}/*,
			{
				path: ':id/show',
				access: {
					group: 'ranking',
					action: 'show'
				},
				element: <RankingView/>,
			},*/
		]
	},
	{
		element: <CampaignsWrapper/>,
		path: campaignsMenu.campaigns.subMenu.campaignList.path,
		sub: [
			{
				element: <CampaginListWrapper/>,
				access: {
					group: 'campaigns',
					action: 'list'
				},
				index: true,
			},
			{	
				path: 'edit/:id',
				access: {
					group: 'campaigns',
					action: 'edit'
				},
				element: <CampaignEdit/>,
			},
		]
	},
	{
		element: <DocumentsWrapper/>,
		path: documentsMenu.documents.subMenu.documentTypes.path,
		sub: [
			{
				element: <DocumentTypeListWrapper/>,
				access: {
					group: 'products',
					action: 'list'
				},
				index: true,
			}
		]
	},
	{
		element: <ProductsWrapper/>,
		sub: [
			{
				element: <ProductListWrapper/>,
				path: productMenu.products.subMenu.productList.path,
				access: {
					group: 'products',
					action: 'list'
				},
				index: true,
			},{
				element: <CreateProduct/>,
				path: productMenu.products.subMenu.productCreate.path,
				access: {
					group: 'products',
					action: 'create'
				},
			},{
				element: <EditProduct/>,
				path: 'products/:id/edit',
				access: {
					group: 'products',
					action: 'edit'
				},
			},
			{
				element: <ProductView/>,
				path: 'products/:id/show',
				access: {
					group: 'products',
					action: 'edit'
				},
			},
			{
				path: 'products/import',
				element: <ProductImport/>,
				access: {
					group: 'products',
					action: 'import'
				},
			},
			
		]
	},
	{
		element: <ProductTypesWrapper/>,
		path: productMenu.products.subMenu.productTypes.path,
		sub: [
			{
				element: <ProductTypeListWrapper/>,
				access: {
					group: 'products',
					action: 'manage_types'
				},
				index: true,
			}
		]
	},
	{
		element: <LeadsWrapper/>,
		path:	operationAdminMenu.operations.subMenu.leads.path,
		sub: [
			{
				element: <LeadListWrapper/>,
				access: {
					group: 'leads',
					action: 'list'
				},
				index: true,
			}
		]
	},
	{
		element: <OriginsWrapper/>,
		path: operationAdminMenu.operations.subMenu.originLead.path,
		sub: [
			{
				element: <OriginListWrapper/>,
				access: {
					group: 'origins',
					action: 'list'
				},
				index: true,
			}
		]
	},

	{
		element: <WalletConfigPage />,
		path: operationAdminMenu.operations.subMenu.configWallet.pathOrigin,
		sub: [
			{
				path: 'status',
				access: {
					group: 'config',
					action: 'config_wallet'
				},
				index: true,
				element: <WalletConfigChangeStatus/>,
			},
			{
				path: 'aditional-config',
				access: {
					group: 'config',
					action: 'config_wallet'
				},
				element: <WalletConfigAditionalInfo/>,
			}
		],
	},

	{
		element: <BusinessView/>,
		access: {
			group: 'businesses',
			action: 'show'
		},
		path: productMenu.products.subMenu.productTypes.path,
	},
	{
		element: <ExpenseWrapper/>,
		path: expensesMenu.expenses.subMenu.expenseList.path,
		access: {
			group: 'expenses',
			action: 'list'
		},
		sub: [
			{
				element: <ExpenseListWrapper/>,
				access: {
					group: 'expenses',
					action: 'list'
				},
				index: true,
			},
		]
	},
	{
		element: <ExpenseTypesWrapper/>,
		path: expensesMenu.expenses.subMenu.expenseTypes.path,
		sub: [
			{
				element: <ExpenseTypeListWrapper/>,
				access: {
					group: 'expenses',
					action: 'manage_types'
				},	
				index: true,
			}
		]
	},
	{
		element: <OperationsWrapper/>,
		path: operationsMenu.operations.subMenu.operationList.path,
		sub: [
			{
				element: <OperationListWrapper />,
				access: {
					group: 'operations',
					action: 'list'
				},
				index: true,
				
			},
			{
				path: 'create',
				access: {
					group: 'operations',
					action: 'create'
				},
				element: <OperationCreate/>,
			},
			{
				path: 'edit/:id',
				access:{
					group: 'operations',
					action: 'edit'
				},
				element: <OperationCreate/>,
			},
			{
				path: 'edit/:id/:tab',
				access: {
					group: 'operations',
					action: 'edit'
				},
				element: <OperationCreate/>,
			},
			{
				path: ':id/show',
				access: {
					group: 'operations',
					action: 'show'
				},
				element: <OperationView/>,
			},
		]
	},
	{
		element: <WalletWrapper/>,
		path: walletMenu.wallet.path,
		sub: [
			{
				element: <WalletListWrapper/>,
				access: {
					group: 'wallet',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/show',
				access: {
					group: 'wallet',
					action: 'show'
				},
				element: <WalletView/>,
			}
		]
	},
	{
		element: <WorkWrapper/>,
		path: workMenu.work.path,
		sub: [
			{
				element: <NewWork/>,
				path: workMenu.work.subMenu.newWork.path,
				access: {
					group: 'clients',
					action: 'find_client_data'
				},
				index: true,
			},
		]
	},
	{
		element: <CallListWrapper/>,
		path: callMenu.calls.path,
	},
	{
		element: <PendingTaskWrapper/>,
		path: pendingTasksMenu.pendingTasks.path,
		sub: [
			{
				element: <PendingTaskListWrapper/>,
				access: {
					group: 'operations',
					action: 'list'
				},
				index: true,
			},
		]
	},
	{
		element: <OperationTypesWrapper/>,
		path: operationAdminMenu.operations.subMenu.operationTypes.path,
		sub: [
			{
				element: <OperationTypeListWrapper/>,
				access: {
					group: 'operations',
					action: 'manage_types'
				},
				index: true,
			}
		]
	},

	{
		element: <ActionStatusWrapper />,
		path: operationAdminMenu.operations.subMenu.operationsStatus.path,
		sub: [
			{
				element: <ActionStatusListWrapper />,
				access: {
					group: 'action_statuses',
					action: 'list'
				},
				index: true,
			},
		]
	},


	/** ************************************************** */

	{
		path: dashboardPagesMenu.dashboard.path,
		element: <UsersList />,
	},
	{
		path: permissionsPage.permissions.path,
		element: <PagePermissions />,
	},

];
const contents = [...protectedRoutes];

export default contents;
