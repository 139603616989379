import { NewFee, UpdateFee } from "../../type/fee-type";
import { RestServiceConnection } from "../restServiceConnection";

const FEE_ENDPOINT = '/fees';

export class FeeService extends RestServiceConnection {
    
    createFee = async (fee: NewFee) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FEE_ENDPOINT + '/create',
            data: fee,
        }, true);
        return this;
    }

    editService = async (values: UpdateFee) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FEE_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getFees = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FEE_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    getFeeById = async (feeId: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: FEE_ENDPOINT + `/get/${feeId}`,
        }, true);
        return this;
    }

    toggleFeeStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FEE_ENDPOINT + '/toggle',
            data: {
                service: id,
                active: status
            }
        }, true);
        return this;
    }

    deleteFee = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FEE_ENDPOINT + '/delete',
            data: {
                fee: id
            }
        }, true);
        return this;
    }
}