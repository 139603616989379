import { FiltersProvider } from "../../components/providers/FiltersProvider";
import CallList from "./calls-list/CallList";


const CallListWrapper = () => {

    return (
      <>
        <FiltersProvider>
          <CallList/>
        </FiltersProvider>
      </>
    );
};

export default CallListWrapper;
