import { ActionStatus } from "./actionStatus-type";
import { ApiResponse , CreatedAt, Filters } from "./apiResponse-type";
import { Client } from "./client-type";
import { Company } from "./company-type";
import { Lead } from "./lead-type";
import { Office } from "./office-type";
import { Origin } from "./origin-type";
import { Product } from "./product-type";
import { User } from "./user-type";

export type Operations = Operation[] | null;

export type OperationTypes = OperationType[] | null;

export type OperationError = Error | null;

export interface OperationsApiResponse extends ApiResponse {
    totalRegisters: number;
    operations: Operations;
    lastPage: number;
    filters: Filters;
    length: number;
}

export interface OperationTypeApiResponse extends ApiResponse {
    totalRegisters: number;
    operationTypes: OperationTypes;
    lastPage: number;
    filters: Filters;
    length: number;
}

export interface OperationApiResponse extends ApiResponse {
    data: Operation | null;
}

export interface OperationTypeApiResponse extends ApiResponse {
    data: OperationType | null;
}

export interface OperationHasProducts {
    id: string,
    address: string,
    town: string,
    province: string,
    postalcode: number,
    cups: string | null,
    cups_gas: string | null,
    product: Product,
    operation: Operation,
    consumption: number | null,
    powerP1: number | null,
    powerP2: number | null,
    powerP3: number | null,
    powerP4: number | null,
    powerP5: number | null,
    powerP6: number | null
}
export interface Operation {
    id: string ,
    operationIdentifier: string ,
    cups: string | null ,
    clientName: string ,
    clientDni: string ,
    locality: string ,
    province: string ,
    clientPhone: string ,
    postalCode: string ,
    iban: string,
    company: Company ,
    user : User,
    agent: User,
    operationType: OperationType,
    lead: Lead,
    origin: Origin,
    client: Client,
    office: Office,
    operationFor: string,
    operationComment: string,
    currentActionStatus: ActionStatus | null,
    statuses: string,
    actionStatuses: ActionStatus[],
    products: OperationHasProducts[] | any,
    clientPostalCode: number | null,
    clientAddress: string | null,
    clientPhone2: string | null,
    clientEmail: string | null,
    activationDate: string | null,
    cnae: string | null,
    oiLuz: string | null,
    oiGas: string | null,
    oiMaintenanceLuz: string | null,
    oiMaintenanceGas: string | null,
}

export interface OperationType {
    id: string,
    name: string,
    description: string | null,
    active: boolean,
    company: Company
    products: Product[],
    operations: Operation[]
}

export interface NewOperation {
   business: string,
   user: string,
   operation_type: string,
   lead: string,
   office: string,
   product: string,
   operation_identifier: string,
   cups: string | null,
   client_name: string,
   client_dni: string,
   client_phone: string,
   locality: string,
   province: string
}

export interface NewOperationType {
    company: string,
    name: string,
    description: string | null
}

export interface EditOperationType {
    operation_type: string,
    company: string,
    name: string,
    desciption: string | null
}

export interface EditOperation {
    operation: string,
    business: string,
    user: string,
    operation_type: string,
    lead: string,
    office: string,
    product: string,
    operation_identifier: string,
    cups: string | null,
    client_name: string,
    client_dni: string,
    client_phone: string,
    locality: string,
    province: string
}



export interface OperationRecord {
    id:               string;
    name:             string;
    callIdentifier:   string;
    status:           number;
    createdAt:        AtedAt;
    updatedAt:        AtedAt;
    operationProduct: null;
    operation:        Operation;
}

export interface AtedAt {
    date:          Date;
    timezone_type: number;
    timezone:      string;
}

export interface OperationRecordApiResponse extends ApiResponse {
    data: OperationRecord | null;
}


export const operationForValues = [
    {
        value: 'PARTICULAR',
        label: 'Particular'
    },
    {
        value: 'EMPRESA',
        label: 'Empresa'
    },
]

export type OperationRequestSMS = {
    id?:           string;
    idReport?:     null;
    certicate?:    null;
    idMessage?:    null;
    message?:      string;
    date?:         null;
    sendDate?:     null;
    result?:       null;
    telephone?:    string;
    codeDocument?: null;
    type?:         string;
    createdAt?:    CreatedAt;
    updatedAt?:    null;
    user?:         User;
}