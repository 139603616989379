import { useEffect, useRef, useState } from "react";
import { CompactPicker } from "react-color";
import Card from "./bootstrap/Card";

function ColorPicker(props: any) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(props.item.color || "#000000");
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => setShowColorPicker(!showColorPicker);

  const handleChange = (colorPick: any) => {
    setColor(colorPick.hex)
    if (colorPick.hex === color) {
      handleSave()
    }
  };

  const handleSave = () => {
    props.handleSave({ action_status: props.item?.id ?? '', description: props.item?.description ?? null, company: props.item?.company.id ?? '', color: color, entity: "operation", name: props.item.name });
    setShowColorPicker(false);
  };

  const noAction = () => {

  };

  useEffect(() => {
    /*function handleDocumentClick(event: MouseEvent) {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    }

    if (showColorPicker) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };*/
  }, [showColorPicker]);

  return (
    <div className="d-flex align-items-center" ref={colorPickerRef}>
      <div
        className="rounded-circle mr-3 p-3"
        style={{
          backgroundColor: color,
          border: "1px solid rgba(0, 0, 0, 0.1)"
        }}
        onClick={props.disabled ? noAction : handleClick}
      >
        <div
          className="rounded-circle"
          style={{ backgroundColor: color, width: "5px", height: "5px" }}
        ></div>
      </div>
      {showColorPicker && (
        <div className="position-absolute mt-3">
          <CompactPicker  color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
}

export default ColorPicker;