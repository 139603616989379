import React, { ChangeEvent, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { CustomTable } from '../../../../components/table/CustomTable';
import { toast } from 'react-toastify';
import { CampaignService } from '../../../../services/campaigns/campaignService';
import CampaignPostalCodeSelectorModal from '../../../../components/selectors/PostalCodeSelectorModal.tsx/CampaignPostalCodeSelectorModal';

interface PostalCodeRestrictionsTableWizardProps {
    formik: any; // Añade el tipo correcto para formik
    reloadCampaign: Function;
}

const PostalCodeRestrictionsTableWizard: React.FC<PostalCodeRestrictionsTableWizardProps> = ({ formik, reloadCampaign }) => {

    const [modalUs, setModalUs] = useState(false);
    const [selectedRowsRest, setSelectedRowsRest] = useState<any[]>([]);
    const [selectAllRest, setSelectAllRest] = useState(false);

    const handleActionSelected = async (array: any, selectArr: any[], setSelectArr: any) => {
        try {
            for (const rowId of selectArr) {
                const response = (await new CampaignService().removePostalCodeRestrictionFromCampaign(formik.values.id, rowId.id)).getResponseData();
            }
            toast.success('Restricción de código postal borrada con éxito');
            reloadCampaign();
            setSelectArr([]);
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleCheckboxChangeUsr = (
        id: string,
        event: ChangeEvent<HTMLInputElement>,
        element: any,
    ) => {
        const { checked } = event.target;
        let selectedItemsCopy = [...selectedRowsRest];
        if (checked) {
            selectedItemsCopy.push(element);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item.id === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedRowsRest(selectedItemsCopy);
    };

    const handleSelectAllRest = (checked: boolean) => {
        setSelectAllRest(!selectAllRest);
        if (!selectAllRest) {
            setSelectedRowsRest(formik.values.users ? formik.values.users : []);
        } else {
            setSelectedRowsRest([]);
        }
    };

    const _oncloseModalOf = async (postalCode : string , restrictionType : string) => {
        const response = (await new CampaignService().addPostalCodeRestrictionToCampaign(formik.values.id, postalCode, restrictionType)).getResponseData()
        if (response.success == true) {
            toast.success(response.message);
            reloadCampaign();
        } else {
            response.data.errors.forEach((error: any) => {
                toast.error(error.message);
            });
            return;
        }
    }

    return (
        <>
            <Card>
                <CardHeader className='rounded-1 mb-0' borderSize={1}>
                    <CardLabel icon='Groups' iconColor='info'>
                        <CardTitle>Restricciones de Código Postal</CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button color="primary" icon="Add" onClick={() => setModalUs(true)}>Añadir Restricción</Button>
                        <Button color="danger" icon="DeleteForever" onClick={() => handleActionSelected(formik.values.offices, selectedRowsRest, setSelectedRowsRest)}>Borrar seleccionados</Button>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <CustomTable
                        data={formik.values.postalCodeRestrictions && formik.values.postalCodeRestrictions.length > 0 ? formik.values.postalCodeRestrictions : []}
                        className={"table table-hover"}
                        selectableItems={true}
                        onSelectAllItems={handleSelectAllRest}
                        onChangeCheckedItems={handleCheckboxChangeUsr}
                        columns={[
                            {
                                name: "Código Postal",
                                keyValue: "postalCode",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.postalCode}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Tipo de Restricción",
                                keyValue: "description",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.restrictionType === 'include' ? 'Permitido' : 'No Permitido'}
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            {modalUs && <CampaignPostalCodeSelectorModal isOpen={modalUs} setIsOpen={setModalUs} onClose={_oncloseModalOf} isRequired />}
        </>
    );
};

export default PostalCodeRestrictionsTableWizard;
