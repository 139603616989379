import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import StatusDropdown from "../../../components/StatusDropdown";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import OriginModal from "../origin-create/OriginModal";
import OriginsFilters from "./origin-options/OriginFilters";
import { OriginService } from "../../../services/origin/originService";
import { OriginsApiResponse } from "../../../type/origin-type";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const OriginList = () => {
  const {userCan} = useContext(PrivilegeContext)

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const [origin, setOrigin] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);


  const fetchOrigins = useCallback(async () => {
    const originService = new OriginService();
    const response = await originService.getOrigins(filters);
    return response.getResponseData() as OriginsApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refech] = useFetch(fetchOrigins);


  const deleteOrigin = async (id: string) => {
    let response = (await new OriginService().deleteOrigin(id)).getResponseData();
    if (response.success) {
      toast.success('Origen Lead eliminado correctamente');
      refech();
    } else {
      toast.error('Error al eliminar el origen lead');
    }
  }


  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setOrigin(item);
    setModal(true);
  }

  
  const handleToggleStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new OriginService().toggleStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success('Origen Lead cambiado de estado correctamente');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refech();
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Origenes Lead</CardTitle>
              {userCan('create','origins')?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('create','origins') ? '' : 'd-none' }`}
                  isLight
                  onClick={() => {
                    openModal(null, false);
                  }}
                >
                  Añadir Origen Lead
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <OriginsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {
          data ? (
            <CustomTable
              data={data?.origins ? data.origins : null}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: data as OriginsApiResponse ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
              }}
              defaultLimit={filters.limit || 50}
              defaultOrder={filters.filter_order || undefined}
              className={"table table-hover"}
              columns={
                [
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {openModal(element, true);}}>
                          {element.name}
                        </div>
                      )
                    },
                  },
                  {
                    name: "Descripción",
                    keyValue: "description",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                  },
                  {
                    name: "Creacion",
                    keyValue: "createdAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (item: any) => {
                        return <div className="fs-7 text-center">{moment(item.createdAt.date).format("DD/MM/YYYY H:mm")}</div>;
                    }
                },
                {
                    name: "Ult. Actualizacion",
                    keyValue: "updatedAt",
                    className: "text-center",
                    render: (item: any) => {
                        return <div className="fs-7 text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                    }
                },
                  {
                    name: "Organización",
                    keyValue: "company",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: userCan('list','companies',true) ? "text-center" : "d-none",
                    isVisible: userCan('list','companies',true),
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center">
                          {element.company.name}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Estado",
                    keyValue: "active",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          <StatusDropdown
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            key={element.id}
                            status={element.active}
                            change={handleToggleStatus}
                          />
                        </div>
                      );
                    },
                  },

                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]
              }
              actions={
              [
                {
                  title: "Editar",
                  buttonType: 'icon',
                  iconColor: 'text-info',
                  iconPath: '/media/icons/duotune/general/gen055.svg',
                  additionalClasses: `text-primary ${userCan('edit','origins')?'':'d-none'}`,
                  description: "Editar el origen lead",
                  callback: (item: any) => {
                    if(userCan('edit','origins')) openModal(item, false);
                  },
                },

                {
                  title: "Eliminar",
                  buttonType: 'icon',
                  iconColor: 'text-danger',
                  iconPath: '/media/icons/duotune/general/gen027.svg',
                  additionalClasses: `text-danger ${userCan('delete','origins')?'':'d-none'}`,
                  description: "Eliminar Origen Lead",
                  callback: (item: any) => {
                    if(userCan('delete','origins')){
                      handleConfirmationAlert({
                        title: "Eliminar Origen Lead",
                        text: "¿Estás seguro de eliminar este origen lead?",
                        icon: "warning",
                        onConfirm: () => {
                          deleteOrigin(item.id);
                        }
                      })
                    }
                  },
                },
              ]
              }
          />
          ) : (
            <div className="text-center d-flex justify-content-center"><Spinner/></div>
          )
          }
        </Card>
      </Page>
      {modal && (<OriginModal isOpen={modal} setIsOpen={setModal} readMode={readMode} setReadMode={setReadMode} origin={origin}  />)}
    </Fragment>
  );
};

export default OriginList;
