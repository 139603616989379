import React, { FC, Fragment, useCallback, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Select from '../../../../components/bootstrap/forms/Select'
import Icon from '../../../../components/icon/Icon'
import useFetch from '../../../../hooks/useFetch'
import { ExpenseTypeService } from '../../../../services/expenses/expenseTypeService'
import { ExpenseType, ExpenseTypeApiResponse } from '../../../../type/expense-type'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'

interface IExpensesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any
}

const ExpenseFilters: FC<IExpensesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false)

    const fetchExpensesTypes = useCallback(async () => {
        const response = await new ExpenseTypeService().getExpenseTypes();
        return response.getResponseData() as ExpenseTypeApiResponse;
    }, [filters]);

    const [expenseTypes, fetchingExpenseTypes, expenseTypeError] = useFetch(fetchExpensesTypes);

    const getExpenseTypeList = () => {

        if (expenseTypes as ExpenseType) {
            return expenseTypes.expenseTypes.map((item: { id: any; expenseName: any; }) => {
                return {
                    value: item.id,
                    label: item.expenseName
                }
            })
        }
        return [];
    }

    const handleTextChange = (search: string) => {
        updateFilters({ search_text: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>
            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Tipos de Gastos </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter2' label='Nombre:'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup label='Tipo de gasto:'>
                                <Select id='expenseType' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ expenseType: e.target.value })} value={filters.filter_filters.expenseType || ''} ariaLabel='Default select example' placeholder='Elegir tipo...' list={getExpenseTypeList()}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup label='Importes:' className='row text-center'>
                            <FormGroup label='Desde:' className=' col-md-6'>
                                <Input type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ amount_from: e.target.value })} value={filters.filter_filters.amount_from || ''} placeholder='Cantidad mínima'/>
                            </FormGroup>
                            <FormGroup label='Hasta:' className=' col-md-6'>
                                <Input type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ amount_to: e.target.value })} value={filters.filter_filters.amount_to || ''} placeholder='Cantidad máxima'/>
                            </FormGroup>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Button
                            className='mt-4'
                            color="storybook"
                            isLight
                            onClick={() => resetFilters(50, true)}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default ExpenseFilters;