import { AxiosResponse } from "axios";
import { NewProductType, UpdateProductType } from "../../type/product-type";
import { RestServiceConnection } from "../restServiceConnection";

const PRODUCT_TYPE_ENDPOINT = '/product-types';

export class ProductTypeService extends RestServiceConnection {
    
    createProductType = async (product: NewProductType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/create',
            data: product
        }, true);
        return this;
    }

    editProductType = async (values: UpdateProductType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getProductTypes = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/list',
            data: {
                ...filters
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true) as AxiosResponse;
        return this;
    }
    
    getProductTypeById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/get',
            data: {
                product_type: id
            }
        }, true);
        return this;
    }

    toggleProductTypeStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/toggle',
            data: {
                product_type: id,
                active: status
            },
        }, true);
        return this;
    }

    deleteProductType = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_TYPE_ENDPOINT + '/delete',
            data: {
                product_type: id
            }
        }, true);
        return this;
    }
}