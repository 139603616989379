import { FC, PropsWithChildren } from "react";
import Button from "../bootstrap/Button";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import { useWindowScroll } from "react-use";
import { CallService } from "../../services/calls/servicesService";
import { toast } from "react-toastify";
import useHandleErrors from "../../hooks/useHandleErrors";


export interface ClickToCallProps {
    telephone?: string
}

export const ClickToCall: FC<PropsWithChildren<ClickToCallProps>> = ({children, telephone}) => {

    const {handleErrors} = useHandleErrors();

    const _executeCall = async () => {
        
        if(!telephone) return;

        const callService = new CallService();

        let response = await callService.launchCall(telephone);
        let responseData = response.getResponseData();

        if(responseData.success) {
            toast.success("Llamada lanzada");
        } else {
            handleErrors(responseData);
        }

    }

    const _makeCall = () => {
        handleConfirmationAlert({
            title: "Llamar",
            icon: "info",
            text: "¿Desea llamar a " + telephone + "?",
            onConfirm: () => {
                _executeCall();
            }
        });
    }

    return (
        <>
            {telephone === null || telephone === undefined || telephone === '' && (<span>-</span>) }
            {telephone !== null && telephone !== undefined && telephone !== '' && (<Button isLink color="primary" className="underlined text-decoration-underline d-inline-block" style={{fontSize: '10px'}} onClick={_makeCall}>{telephone}</Button>)}
        </>
    )
}

