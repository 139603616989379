import Card, { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import React, { useEffect, useState } from "react";
import { StatusService } from "../../../../services/status/statusService";
import ConfigurationsListTime from "../components/ConfigurationsListTime";
import { CampaignService } from "../../../../services/campaigns/campaignService";
;

const WalletConfigAditionalInfo = () => {

    // STATES

    const [ allWalletStatuses, setAllWalletStatuses ] = useState<any[]>([]);
    const [ walletStatuses, setWalletStatuses ] = useState<any[]>([]);
    const [ walletActionStatuses, setWalletActionStatuses ] = useState<any[]>([]);
    const [ campaigns, setCampaigns ] = useState<any[]>([]);

    // OPTIONS FOR SELECT

    useEffect(() => {
        Promise.all([
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "wallet", active : 1 }, limit: 1000}),
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "action_wallet", active : 1 }, limit: 1000}),
            (new CampaignService()).getCampaignsSelect({filter_filters: { active: 1 }, limit: 1000}),
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "all_wallets", active : 1 }, limit: 1000})
        ]).then((responses) => {
            setWalletStatuses(responses[0].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name })));
            setWalletActionStatuses(responses[1].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name })));
            if (responses[2].getResponseData()) {
                let campaignsToSelect = responses[2].getResponseData()?.data.campaigns.map((status: any) => ({ value: status.id, label: status.name }));
                // Add Empty select to the beginning
                campaignsToSelect.unshift({value: null, label: "SIN DEPARTAMENTO"});
                setCampaigns(campaignsToSelect);
            }
            setAllWalletStatuses(responses[3].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name })));
        });
    }, []);

    return (
        <React.Fragment>
            <div className="container mt-5">
                <Card>
                    <CardHeader>
                        <CardTitle>
                            <Icon icon="AccessTime" className="me-2 text-black" size={'2x'} />
                            Configuraciones de Tiempo en Cartera
                        </CardTitle>
                        <div className="d-flex flex-row gap-3">
                            <div className="d-flex flex-row align-items-end">
                                <div className="color-info-danger me-2"></div>
                                <span className="text-center">C.P. Restringidos</span>
                            </div>
                            <div className="d-flex flex-row align-items-end">
                                <div className="color-info-success me-2"></div>
                                <span className="text-center">C.P. Permitidos</span>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="w-100">
                            <ConfigurationsListTime walletStatuses={walletStatuses} allWalletStatuses={allWalletStatuses}
                            walletActionStatuses={walletActionStatuses} campaigns={campaigns}/>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default WalletConfigAditionalInfo;