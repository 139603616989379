import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { OfficeService } from "../../../services/ofiice/officeService";
import { toast } from "react-toastify";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { SingleValue } from "react-select";
import CustomSelect from "../../../components/selectors/general/CustomSelect";
import { CampaignService } from "../../../services/campaigns/campaignService";
import { WalletService } from "../../../services/wallet/walletService";


interface WalletAssignDepartmentsModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    selectedItems: any;
    refecthData(): void;
}

const WalletAssignDepartmentsModal: FC<WalletAssignDepartmentsModalProps> = ({ isOpen, setIsOpen, selectedItems, refecthData }) => {

    const [modal, setModal] = useState(false);
    const [loadign, setLoading] = useState(false);

    const [campaigns, setCampaings] = useState<any>([]);

    const getDepartments = async () => {
        const response = await (await (new CampaignService()).getCampaignsSelect()).getResponseData();
        if (response.success) {
            setCampaings( response.data.campaigns.map((campaign: any) => {
                return {
                    value: campaign.id,
                    label: campaign.name,
                }
            }));
        } else {
            setCampaings([]);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            campaingId : '',
            wallets : selectedItems,    
        },
        onSubmit: (values) => {
            if (values.campaingId !== '' || values.campaingId !== null) {
                assignDepartment(values);
            }
        },
    });


    const assignDepartment = async (values : any) => {
        try {
            setLoading(true);
            let response = await (await (new WalletService()).assingCampaign(values.wallets , values.campaingId)).getResponseData();
            if (response.success) {
                toast.success('Departamentos asignados con éxito');
            } else {
                toast.error('Error al asignar los departamentos');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al asignar los departamentos');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
            refecthData();
        }
    }

    useEffect(() => {
        getDepartments();
    }, []);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true}  size={'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>Asignar Departamentos</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-12'>
                            { campaigns && campaigns.length > 0 && (
                                <CustomSelect onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('campaingId', value.value)}
                                placeholder='Departamentos...' options={campaigns} is_multi={false} />
                            )}
                            </FormGroup>
                        </div>
                    </ModalBody>
                    
                    <ModalFooter className='px-4 pb-4'>
                        <Button color='primary' type="submit" icon={loadign ? "" : "Save"} isDisable={loadign}>
                            {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                        </Button>
                    </ModalFooter>
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default WalletAssignDepartmentsModal;