import { FiltersProvider } from "../../components/providers/FiltersProvider";
import LeadList from "./leads-list/LeadList";

const LeadListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <LeadList />
        </FiltersProvider>
      </>
    );
};

export default LeadListWrapper;
