import { validateIBAN } from "../ibanValidator";

export const ValidateIBANField = function (errorMessage: string) {
    // @ts-ignore
    return this.test(`test-iban-field`, errorMessage, function (value: any) {
        // @ts-ignore
        const { path, createError } = this;
        
        if(!value) {
            return true;
        }

        if (validateIBAN(value)) {
            return true;
        }

        return (
            createError({ path, message: errorMessage })
        );

    });
}
