import { ChangeEvent, FC } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { OperationHasProducts } from "../../../type/operation-type";
import * as yup from 'yup';
import { valida_cups } from "../../../utils/cupsValidator";

interface OperationTypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    productSelected: OperationHasProducts;
    onClose?: (updateProducts: OperationHasProducts, message?: string) => void;
}

const DataValidationSchema = yup.object().shape({
    address: yup.string().required("Debe introducir una dirección"),
    postalCode: yup.string().required("Debe introducir un código postal"),
    town: yup.string().required("Debe introducir una población"),
    province: yup.string().required("Debe introducir una provincia"),
    cups: yup.string().test({
        name: 'cups',
        test: function (value) {
            const cupsGas = this.parent.cups_gas;
            const isCupsGasEmpty = !cupsGas || cupsGas.trim() === '';
            const isCupsEmpty = !value || value.trim() === '';
            const isValidCups = valida_cups(value ?? '');

            if (!isCupsEmpty && isValidCups) {
                if (isCupsGasEmpty || (valida_cups(cupsGas ?? '') && !isCupsGasEmpty)) {
                    return true;
                }
            }

            if (!isCupsGasEmpty && !valida_cups(cupsGas ?? '')) {
                return true;
            }

            if (isCupsEmpty && !isValidCups) {
                return true;
            }

            return false;
        },
        message: 'Debe introducir un CUPS valido o dejarlo en blanco',
    }).nullable(),

    cups_gas: yup.string().test({
        name: 'cups_gas',
        test: function (value) {
            const cups = this.parent.cups;
            const isCupsEmpty = !cups || cups.trim() === '';
            const isCupsGasEmpty = !value || value.trim() === '';
            const isValidCupsGas = valida_cups(value ?? '');

            if (!isCupsGasEmpty && isValidCupsGas) {
                if (isCupsEmpty || (valida_cups(cups ?? '') && !isCupsEmpty)) {
                    return true;
                }
            }

            if (!isCupsEmpty && !valida_cups(cups ?? '')) {
                return true;
            }

            if (isCupsGasEmpty && !isValidCupsGas) {
                return true;
            }

            return false;
        },
        message: 'Debe introducir un CUPS GAS valido o dejarlo en blanco',
    }).nullable(),
    comsupmtion: yup.number().nullable().positive('Solo Número positivos'),
    powerP1: yup.number().nullable().min(0, 'Solo Número positivos'),
    powerP2: yup.number().nullable().positive('Solo Número positivos'),
    powerP3: yup.number().nullable().positive('Solo Número positivos'),
    powerP4: yup.number().nullable().positive('Solo Número positivos'),
    powerP5: yup.number().nullable().positive('Solo Número positivos'),
    powerP6: yup.number().nullable().positive('Solo Número positivos'),
});

export interface IDataForm {
    address: string;
    postalCode: string;
    town: string;
    province: string;
    cups?: string | null;
    cups_gas?: string | null;
    consumption?: number | null;
    powerP1?: number | null;
    powerP2?: number | null;
    powerP3?: number | null;
    powerP4?: number | null;
    powerP5?: number | null;
    powerP6?: number | null;
}

const adicionalData: IDataForm = {
    address: "",
    postalCode: "",
    town: "",
    province: "",
    cups: null,
    cups_gas: null,
    consumption: null,
    powerP1: null,
    powerP2: null,
    powerP3: null,
    powerP4: null,
    powerP5: null,
    powerP6: null,
}

const OperationAdicionalDataEditModal: FC<OperationTypeModalProps> = ({ isOpen, setIsOpen, productSelected, onClose }) => {

    const updateArray = async (values: any) => {

        productSelected.address = values.address;
        productSelected.postalcode = values.postalCode;
        productSelected.town = values.town;
        productSelected.province = values.province;
        productSelected.cups = values.cups;
        productSelected.cups_gas = values.cups_gas;
        productSelected.consumption = values.consumption == "" ? null : values.consumption;
        productSelected.powerP1 = values.powerP1 == "" ? null : values.powerP1;
        productSelected.powerP2 = values.powerP2 == "" ? null : values.powerP2;
        productSelected.powerP3 = values.powerP3 == "" ? null : values.powerP3;
        productSelected.powerP4 = values.powerP4 == "" ? null : values.powerP4;
        productSelected.powerP5 = values.powerP5 == "" ? null : values.powerP5;
        productSelected.powerP6 = values.powerP6 == "" ? null : values.powerP6;

        onClose && onClose(productSelected, "Datos actualizados correctamente");
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: productSelected ? {
            address: productSelected.address,
            postalCode: productSelected.postalcode,
            town: productSelected.town,
            province: productSelected.province,
            cups: productSelected.cups,
            cups_gas: productSelected.cups_gas,
            consumption: productSelected.consumption,
            powerP1: productSelected.powerP1,
            powerP2: productSelected.powerP2,
            powerP3: productSelected.powerP3,
            powerP4: productSelected.powerP4,
            powerP5: productSelected.powerP5,
            powerP6: productSelected.powerP6,
        }
        : adicionalData,
        validationSchema: DataValidationSchema,
        onSubmit: (values) => {
            updateArray(values as any);
        },
    });

    const verifyClass = (inputFieldID: keyof IDataForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IDataForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as string}</div> : <></>;
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Datos Adicionales' data-backdrop="static" >
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='edit_operationType'>Editar Datos Adicionales</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup id='address' label='Dirección:'>
                                <Input id='address' required onChange={formik.handleChange} value={formik.values.address} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                {showErrors('address')}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='town' label='Ciudad:'>
                                <Input id='town' required onChange={formik.handleChange} value={formik.values.town} onBlur={formik.handleBlur} className={verifyClass('town')} />
                                {showErrors('town')}

                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='province' label='Provincia:'>
                                <Input id='province' required onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                {showErrors('province')}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='postalCode' label='Código Postal:'>
                                <Input id='postalCode' required onChange={formik.handleChange} value={formik.values.postalCode} onBlur={formik.handleBlur} className={verifyClass('postalCode')} />
                                {showErrors('postalCode')}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='cups' label='Cups:'>
                                <Input id='cups' onChange={formik.handleChange} value={formik.values.cups || ''} onBlur={formik.handleBlur} className={verifyClass('cups')} />
                                {showErrors('cups')}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup id='cups_gas' label='Cups Gas:'>
                                <Input id='cups_gas' onChange={formik.handleChange} value={formik.values.cups_gas || ''} onBlur={formik.handleBlur} className={verifyClass('cups_gas')} />
                                {showErrors('cups_gas')}
                            </FormGroup>
                        </div>
                        <div className="col-md-12">
                                <FormGroup className="mt-2" id='consumption' label='Consumo (en Kw):'>
                                    <Input id='consumption' type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("consumption" , e.target.value)}} value={formik.values.consumption || ''} onBlur={formik.handleBlur} className={verifyClass('consumption')} />
                                    {showErrors('consumption')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 1)'>
                                    <Input id='powerP1' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP1" , e.target.value)}} value={formik.values.powerP1 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP1')} />
                                    {showErrors('powerP1')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP2' label='Potencia (Periodo 2)'>
                                    <Input id='powerP2' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP2" , e.target.value)}} value={formik.values.powerP2 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP2')} />
                                    {showErrors('powerP2')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP3' label='Potencia (Periodo 3)'>
                                    <Input id='powerP3' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP3" , e.target.value == "" ?? null )}} value={formik.values.powerP3 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP3')} />
                                    {showErrors('powerP3')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="mt-2" id='powerP4' label='Potencia (Periodo 4)'>
                                    <Input id='powerP4' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP4" , e.target.value)}} value={formik.values.powerP4 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP4')} />
                                    {showErrors('powerP4')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP5' label='Potencia (Periodo 5)'>
                                    <Input id='powerP5' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP5" , e.target.value)}} value={formik.values.powerP5 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP5')} />
                                    {showErrors('powerP5')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP6' label='Potencia (Periodo 6)'>
                                    <Input id='powerP6' min={0} step={0.01} type="number" onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("powerP6" , e.target.value)}} value={formik.values.powerP6 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP6')} />
                                    {showErrors('powerP6')}
                                </FormGroup>
                            </div>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon="Save" color='primary' type="submit">
                        {'Editar Datos'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default OperationAdicionalDataEditModal;