import React, { FC, KeyboardEventHandler, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const isValidPostalCode = (postalCode: string) => {
  const postalCodeRegex = /^[0-9]{5}$/;
  return postalCodeRegex.test(postalCode);
};

interface CreateOptionsSelectProps {
    className: string;
    defaultValue?: string[];
    updateFilters?: any;
    filters?: any;
}

const CreateOptionPostalCodeIncluded: FC <CreateOptionsSelectProps>  = ({className, defaultValue, updateFilters, filters}) => {
  const [error, setError] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly Option[]>([]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        if (isValidPostalCode(inputValue)) {
          setValue((prev) => [...prev, createOption(inputValue)]);
          let prevFilters = filters.filter_filters.postalCodesIncludedList !== undefined ? [...filters.filter_filters.postalCodesIncludedList] : [];
          updateFilters({postalCodesIncludedList: [...prevFilters, inputValue]});
          setInputValue('');
        } else {
          break;
        }
        event.preventDefault();
        break;
      case 'Tab':
        if (isValidPostalCode(inputValue)) {
          setValue((prev) => [...prev, createOption(inputValue)]);
          let prevFilters = filters.filter_filters.postalCodesIncludedList !== undefined ? [...filters.filter_filters.postalCodesIncludedList] : [];
          updateFilters({postalCodesIncludedList: [...prevFilters, inputValue]});
        } else {
          break;
        }
        event.preventDefault();
        break;
    }
  };

  const createDefaultOptions = (defaultValue: string[]) => {
    const options = defaultValue?.map((option) => createOption(option));
    return options;
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(createDefaultOptions(defaultValue));
    }
  }, [defaultValue]);

  return (
    <>
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      className={`${className}`}
      menuIsOpen={false}
      onChange={(newValue) => {
        setValue(newValue)
        if (newValue) {
          updateFilters({postalCodesIncludedList: newValue.map((option: Option) => option.value)});
        }
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Escribe un código postal y presiona enter"
      value={value}
    />
    </>
  );
};


export default CreateOptionPostalCodeIncluded;
