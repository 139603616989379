import { AxiosResponse } from "axios";
import { EditOperationType, NewOperationType } from "../../type/operation-type";
import { RestServiceConnection } from "../restServiceConnection";

const OPERATION_TYPE_ENDPOINT = '/operation-types';

export class OperationTypeService extends RestServiceConnection {

    createOperationType = async (operation: NewOperationType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/create',
            data: operation
        }, true);
        return this;
    }

    editOperationType = async (operation: EditOperationType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/edit',
            data: operation
        }, true);
        return this;
    }

    getOperationTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/list',
            data: filters ? filters :{
                "limit": 25,
                "page": 1,
                "filter_filters": {
                    "name": ""
                },
                "filter_order": [
                    {
                        "field": "id",
                        "order": "DESC"
                    }
                ]
            }
            }, true) as AxiosResponse;
        return this;
    }

    getOperationTypeById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/get',
            data: {
                operation_type: id
            },
        }, true);
        return this;
    }

    deleteOperationType = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/delete',
            data: {
                operation_type: id
            },
        }, true);
        return this;
    }

    toggleOperationTypeStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: OPERATION_TYPE_ENDPOINT + '/toggle',
            data: {
                operation_type: id,
                active: status
            },
        }, true);
        return this;
    }
}