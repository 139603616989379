import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from './bootstrap/Card';
import moment from 'moment';
import 'moment/locale/es';
import { ExpenseService } from '../services/expenses/expenseService';
import { priceFormatEuro } from '../helpers/helpers';
import { CUSTOMTABS, customTabType } from '../pages/dashboard/agent-dashboard/OperationStatistic';

interface IUserContactProps {
    className?: string;
    name: string;
    date: any;
    activeTab: customTabType;
}
const ToTalMonthExpenses: FC<IUserContactProps> = ({
    name,
    activeTab,
    date,
    ...props
}) => {
    const [activeOption, setActiveOption] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    const calculateDateRange = () => {
        if (activeTab === CUSTOMTABS.YEARLY) {
            const start = moment().subtract(activeOption + 1, 'years').set('month', 11).endOf('month').format('YYYY-MM-DD');
            const end = moment().subtract(activeOption, 'years').set('month', 11).endOf('month').format('YYYY-MM-DD');
            return { start, end };
        }
        if (activeTab === CUSTOMTABS.MONTHLY) {
            const start = moment().subtract(activeOption + 1, 'months').endOf('month').format('YYYY-MM-DD');
            const end = moment().subtract(activeOption, 'months').endOf('month').format('YYYY-MM-DD');
            return { start, end };
        }
        if (activeTab === CUSTOMTABS.WEEKLY) {
            const start = moment().subtract(activeOption + 1, 'weeks').endOf('week').format('YYYY-MM-DD');
            const end = moment().subtract(activeOption, 'weeks').endOf('week').format('YYYY-MM-DD');
            return { start, end };
        }
        if (activeTab === CUSTOMTABS.TODAY) {
            const start = moment().subtract(activeOption + 1, 'days').format('YYYY-MM-DD');
            const end = moment().subtract(activeOption, 'days').format('YYYY-MM-DD');
            return { start, end };
        }
        return { start: date.start, end: date.end };
    };

    const getTotalExpenses = async () => {
        const { start, end } = calculateDateRange();
        const expenseService = new ExpenseService();
        if (start == '' && end == '') return;
        const response = await expenseService.getSumBetweenDates(start, end);
        if (response.getResponseData()?.success) {
            setTotalExpenses(response.getResponseData().data.sum);
        } else {
            setTotalExpenses(0);
        }
    }

    useEffect(() => {
        if (date.start !== "" && date.end !== "") {
            getTotalExpenses();
        }
    }, [activeTab, date])

    return (
        <Card {...props} className={classNames(props.className) + 'text-dark bg-l25-primary transition-base rounded-2 mb-4'}>
            <CardHeader className='text-dark bg-l25-primary'>
                <CardLabel icon='Paid' iconColor='success' className='mt-2'>
                    <CardTitle tag='h4' className='ms-2 h5'>
                        {name}
                    </CardTitle>
                    <CardSubTitle className='text-muted h6 mt-2 ms-2'>{activeTab}</CardSubTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='d-flex align-items-center'>
                <div className='flex-grow-1 ms-3'>
                    <div className='fw-bold fs-3 mb-0 text-center'>
                        {priceFormatEuro(totalExpenses)}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
ToTalMonthExpenses.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
};
ToTalMonthExpenses.defaultProps = {
    className: undefined,
};

export default ToTalMonthExpenses;