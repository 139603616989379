import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { OperationApiResponse } from "../../../type/operation-type";
import { CustomTable } from "../../../components/table/CustomTable";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import { StatusService } from "../../../services/status/statusService";
import { ActionStatusApiResponse } from "../../../type/actionStatus-type";
import StatusDropdown from "../../../components/StatusDropdown";
import moment from "moment";
import ActionCreateModal from "../actionStatus-create/ActionModal";
import ColorPicker from "../../../components/ColoPicker";
import { ActionStatus } from "../../../type/actionStatus-type";
import ActionStatusFilters from "./actionStatus-options/ActionStatusFilters";
import { CSVLink } from "react-csv";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import Icon from "../../../components/icon/Icon";
import Tooltips from "../../../components/bootstrap/Tooltips";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const ActionStatusList = () => {

    // HOOKS
    const { userCan } = useContext(PrivilegeContext)
    const { handleErrors } = useHandleErrors();
    const [statuses, setStatuses] = useState<ActionStatus[]>([]);

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [actionToEdit, setactionToEdit] = useState();

    const deleteActionStatus = async (id: string) => {
        const response = await (await (new StatusService()).deleteActionStatus(id)).getResponseData();
        if (response.success) {
            toast.success('Esado de accion eliminado correctamente');
            updateFilters({ ...filters });
        } else {
            toast.error('Error al eliminar el estado de accion');
        }
    }

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters,checkIfUrlHasFilters } = useFiltersPR();

    useEffect(() => {
        checkIfUrlHasFilters();
    },[])

    const fetchActionStatuses = useCallback(async () => {
        const actionService = new StatusService();
        const response = await actionService.getActionStatuses(filters);
        return response.getResponseData() as ActionStatusApiResponse;
    }, [filters]);

    const [data, loading, error, refetch] = useFetch(fetchActionStatuses);


    const toggleActionStatus = async (
        id: string,
        status: boolean,
        toggleStatus: Function
    ) => {
        try {
            setChangingStatus([...changingStatus, id]);
            let response = await (
                await new StatusService().toggleActionStatusActive(id, status)
            ).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                toast.success(`Estado de accion ${status ? "activado" : "desactivado"} correctamente`); 
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
        }
    };

    useEffect(() => {
        if (data !== null && data.actionStatuses) {
            setStatuses(data.actionStatuses);
        }
    }, [data, loading]);

    const _onCloseModal = (message?: string) => {
        toast.success(message ? message : "Accion Realizada correctamente");
        setStatuses([])
        refetch();
    }

    // Set initial status (defaultStatus property) to the action status received.
    const _setDefaultInitialStatus = async (id: string) => {

        const response = await (await (new StatusService()).setDefaultInitialStatus(id)).getResponseData();

        if (response.success) {
            toast.success('Estado de accion establecido como inicial');
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
        }
    }

    const _setApplyCommission = async (id: string, disable : boolean = false) => {

        const response = await (await (new StatusService()).setApplyCommission(id , disable)).getResponseData();

        if (response.success) {
            toast.success('Estado de accion establecido como que aplica la comisión');
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
        }
    }

    const _setApplyPoints = async (id: string, disable : boolean = false) => {

        const response = await (await (new StatusService()).setApplyPoints(id , disable)).getResponseData();

        if (response.success) {
            toast.success('Estado de accion establecido como que aplica los puntos');
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
        }
    }

    const _setResetCommission = async (id: string, disable : boolean = false) => {

        const response = await (await (new StatusService()).setResetCommission(id , disable)).getResponseData();

        if (response.success) {
            toast.success('Estado de accion establecido como que resetea la comisión');
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
        }
    }

    const _setResetPoints = async (id: string, disable : boolean = false) => {
        
        const response = await (await (new StatusService()).setResetPoints(id , disable)).getResponseData();

        if (response.success) {
            toast.success('Estado de accion establecido como que resetea los puntos');
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
        }
    }

    function convertList(input: any[]): any[] {
        try {
            return input.map((item) => ({
                Nombre: item.name ?? '',
                Entidad: item.entity ?? '',
                Descripción: item.description ?? '',
                Color: item.color ?? '',
                Activo: item.active ?? '',
                Fecha_Creación: item.createdAt?.date ?? '',
                Fecha_Actualización: item.updatedAt?.date ?? '',
                Nº_Operaciones: item.operations.length ?? '',
                Organización: item.company.name ?? '',
            }));
        } catch (error) {
            return [];
        }
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardLabel icon="BubbleChart" iconColor="info">
                            <CardTitle>Listado de Estados de Acción</CardTitle>
                        </CardLabel>
                        {
                            userCan('create', 'action_statuses') &&
                            (
                                <>
                                    <SubheaderSeparator />
                                    <Button
                                        color="light"
                                        icon="PersonAdd"
                                        isLight
                                        onClick={() => {
                                            setOpenCreateModal(true);
                                        }}
                                    >
                                        Añadir Estado
                                    </Button>
                                </>
                            )
                        }

                    </Fragment>
                    {data ?
                        <>
                            <SubheaderSeparator />
                            <CSVLink data={convertList(data.actionStatuses)} filename="ActionStatus">
                                <Button
                                    color="light"
                                    icon="FileDownload"
                                    isLight >
                                    Exportar
                                </Button>
                            </CSVLink>
                        </>
                        : <></>
                    }
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ActionStatusFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={false}>
                    {
                        data ?
                        (
                            <CustomTable
                                data={data?.actionStatuses ? data.actionStatuses : null}
                                className={"table table-hover p-3 fs-7"}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: data as OperationApiResponse ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="text-center">
                                                    <span>{item.name}</span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: userCan('list', 'action_statuses', true) ? "text-center" : "d-none",
                                        isVisible: userCan('list', 'action_statuses', true),
                                        render: (item: any) => {
                                            return (
                                                <div key={item.id} className="d-flex justify-content-center">
                                                    {item.company.name}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        name: "Estado",
                                        keyValue: "active",
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="d-flex justify-content-center">
                                                    <StatusDropdown
                                                        disabled={changingStatus.includes(item.id)}
                                                        itemId={item.id}
                                                        status={item.active}
                                                        change={toggleActionStatus}
                                                        key={item.id}
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Inicial",
                                        keyValue: "defaultStatus",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="d-flex justify-content-center" >
                                                    <Tooltips title={item.defaultStatus ? "Es un estado inicial" : "Establecer este estado como inicial"}>
                                                        <Button type={"button"} color={'primary'} isLink onClick={() => {
                                                            if (item.defaultStatus) return
                                                            _setDefaultInitialStatus(item.id)
                                                        }}>
                                                            {
                                                                item.defaultStatus ?
                                                                    <Icon icon={'star'} color={"primary"} size={'2x'} />
                                                                    : <Icon icon={'starOutline'} color={"light"} size={'2x'} />
                                                            }
                                                        </Button>
                                                    </Tooltips>

                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Aplica Comisión",
                                        keyValue: "applyCommission",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                    <Tooltips title={
                                                        item.statusEntity.name == 'wallet' ? "No se puede aplicar a este estado" :
                                                        item.applyCommission ? "Aplica la comisión" : "Establecer este estado como que aplica la comisión"}>
                                                    <div className="d-flex justify-content-center" >
                                                        <Button type={"button"} color={'primary'}  isLink onClick={() => {
                                                            if(item.applyCommission) {
                                                                _setApplyCommission(item.id, true)
                                                            } else {
                                                                _setApplyCommission(item.id)
                                                            }
                                                        }} isDisable={item.statusEntity.name == 'wallet'}>
                                                            {
                                                                item.applyCommission ?
                                                                <Icon icon={'AttachMoney'} color={"primary"} size={'2x'}/>
                                                                : <Icon icon={'AttachMoney'} color={"light"} size={'2x'}/>
                                                            }
                                                        </Button>
                                                    </div>
                                                    </Tooltips>
                                            );
                                        }
                                    },
                                    {
                                        name: "Aplica Puntos",
                                        keyValue: "applyPoints",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                    <Tooltips title={
                                                        item.statusEntity.name == 'wallet' ? "No se puede aplicar a este estado" :
                                                        item.applyPoints ? "Aplica los puntos" : "Establecer este estado como que aplica los puntos"}>
                                                        <div className="d-flex justify-content-center" >
                                                            <Button type={"button"} color={'primary'}  isLink onClick={() => {
                                                                if(item.applyPoints) {
                                                                    _setApplyPoints(item.id, true)
                                                                } else {
                                                                    _setApplyPoints(item.id)
                                                                }
                                                            }} isDisable={item.statusEntity.name == 'wallet'}>
                                                                {
                                                                    item.applyPoints ?
                                                                    <Icon icon={'AddTask'} color={"primary"} size={'2x'}/>
                                                                    : <Icon icon={'AddTask'} color={"light"} size={'2x'}/>
                                                                }
                                                            </Button>
                                                        </div>
                                                    </Tooltips>
                                            );
                                        }
                                    },
                                    {
                                        name: "Resetea Comisión",
                                        keyValue: "resetCommission",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                    <Tooltips title={
                                                        item.statusEntity.name == 'wallet' ? "No se puede aplicar a este estado" :
                                                        item.resetCommission ? "Resetea la comisión" : "Establecer este estado como que resetea la comisión"}>
                                                         <div className="d-flex justify-content-center" >
                                                            <Button type={"button"} color={'primary'}  isLink onClick={() => {
                                                                if(item.resetCommission) {
                                                                    _setResetCommission(item.id, true)
                                                                } else {
                                                                    _setResetCommission(item.id)
                                                                }
                                                            }} isDisable={item.statusEntity.name == 'wallet'}>
                                                                {
                                                                    item.resetCommission ?
                                                                    <Icon icon={'MoneyOffCsred'} color={"danger"} size={'2x'}/>
                                                                    : <Icon icon={'MoneyOffCsred'} color={"light"} size={'2x'}/>
                                                                }
                                                            </Button>
                                                        </div>
                                                    </Tooltips>
                                            );
                                        }
                                    },
                                    {
                                        name: "Resetea Puntos",
                                        keyValue: "resetPoints",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <Tooltips title={
                                                    item.statusEntity.name == 'wallet' ? "No se puede aplicar a este estado" :
                                                    item.resetPoints ? "Resetea los Puntos " : "Establecer este estado como que resetea los Puntos"}>
                                                    <div className="d-flex justify-content-center" >
                                                            <Button type={"button"} color={'primary'}  isLink onClick={() => {
                                                                if(item.resetPoints) {
                                                                    _setResetPoints(item.id, true)
                                                                } else {
                                                                    _setResetPoints(item.id)
                                                                }
                                                            }}isDisable={item.statusEntity.name == 'wallet'}>
                                                                {
                                                                    item.resetPoints ?
                                                                    <Icon icon={'SettingsBackupRestore'} color={"danger"} size={'2x'}/>
                                                                    : <Icon icon={'SettingsBackupRestore'} color={"light"} size={'2x'}/>
                                                                }
                                                            </Button>
                                                    </div>
                                                    </Tooltips>
                                            );
                                        }
                                    },
                                    {
                                        name: "Color",
                                        keyValue: "color",
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="d-flex justify-content-center" key={item.name + item.color}>
                                                    <ColorPicker item={item} handleSave={() => { }} disabled={true} filters={filters} />
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Tipo",
                                        keyValue: "statusEntity",
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="d-flex justify-content-center">
                                                    <span>{item.statusEntity.label}</span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Creacion",
                                        keyValue: "createdAt",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return <div className="fs-7 text-center">{moment(item.createdAt.date).format("DD/MM/YYYY H:mm")}</div>;
                                        }
                                    },
                                    {
                                        name: "Ult. Actualizacion",
                                        keyValue: "updatedAt",
                                        className: "text-center",
                                        render: (item: any) => {
                                            return <div className="fs-7 text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                                        }
                                    },
                                    {
                                        name: "Descripcion",
                                        keyValue: "description",
                                        className: "text-center",
                                        render: (item: any) => {
                                            return (
                                                <div className="d-flex justify-content-center">
                                                    <span>{item.description != null ? item.description : "Sin descripcion"}</span>
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                                ]}
                                actions={[
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        iconColor: "text-info",
                                        additionalClasses: `text-primary ${userCan('edit', 'action_statuses') ? '' : 'd-none'}`,
                                        description: "Editar Estado",
                                        callback: (item: any) => {
                                            setactionToEdit(item);
                                            setOpenEditModal(true);
                                        },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: `text-danger ${userCan('delete', 'action_statuses') ? '' : 'd-none'}`,
                                        iconColor: "text-danger",
                                        description: "Eliminar Estado",
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar estado",
                                                text: "¿Está seguro que desea eliminar el estado? Todos los contratos que se encuentren con este estado serán eliminadas.",
                                                icon: "warning",
                                                onConfirm: () => {
                                                    deleteActionStatus(item.id);
                                                }
                                            });
                                        },
                                    },
                                ]}
                            />
                        ) : (
                            <div className="text-center d-flex justify-content-center"><Spinner/></div>
                        )
                    }
                </Card>
            </Page>
            {openCreateModal && (<ActionCreateModal isOpen={openCreateModal} setIsOpen={setOpenCreateModal} onClose={_onCloseModal} mode={"create"} />)}
            {openEditModal && (<ActionCreateModal isOpen={openEditModal} setIsOpen={setOpenEditModal} onClose={_onCloseModal} mode={"edit"} actionStatus={actionToEdit} />)}
        </Fragment>
    );
};

export default ActionStatusList;