import { FC, Fragment, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import { ProductService } from "../../../services/products/productService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import ProductForm from "../ProductForm";

const CreateProduct: FC = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreation = async (values: any) => {
        setLoading(true);
        normalizeData(values);
        try {
            setLoading(true)
            let response = await (await (new ProductService()).createProduct(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                if (id) {
                    navigate(-1)
                } else {
                    navigate(-1)
                }
            } else {
                toast.error(response.message);
            }
        } catch (error: any) {
            toast.error('Error al crear Producto');
        } finally {
            setLoading(false);
        }
    }

    const normalizeData = (data: any) => {
        if (data.points == '') {
            data.points = null;
        }
        if (data.base_commission == '') {
            data.base_commission = null;
        }
        if (data.powerFrom == '') {
            data.powerFrom = null;
        }
        if (data.powerTo == '') {
            data.powerTo = null;
        }
        if (data.consumptionFrom == '') {
            data.consumptionFrom = null;
        }
        if (data.consumptionTo == '') {
            data.consumptionTo = null;
        }
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => {
                        if (id) {
                            navigate(-1)
                        } else {
                            navigate(-1)
                        }
                    }}>
                    </Button>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <ProductForm submit={handleCreation} productData={undefined} businessId={id} />
            </Page>
        </Fragment>

    )
}

export default CreateProduct;