import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useHandleErrors from "../../../hooks/useHandleErrors";

const OrganizationCreate: FC = () => {

	const navigate = useNavigate();

	const { handleErrors } = useHandleErrors();

	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		const formData = new FormData();
		
		for(let value in values) {
			if(values[value]){
				formData.append(value, values[value]);
			}else{
				formData.append(value, '');
			}
		}
		
		let hasImg = formData.get("logo") ? true : false;

		try {
			setLoading(true)
			let response = await (await (new CompanyService()).createCompany(formData, hasImg)).getResponseData();
			if(response.success) {
				toast.success(response.message);
				navigate(-1);
			} else {
				handleErrors(response);
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear Organización');
		}finally{
			setLoading(false);
		}
		
	}

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/organizations', { replace: true })}>
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={true}>
					<CardHeader borderSize={1}>
						<CardLabel icon='CorporateFare' iconColor='info'>
							<CardTitle>Nueva Organización</CardTitle>
						</CardLabel>
					</CardHeader>
					<CompanyForm submit={handleCreation} isLoading={loading}/>
				</Card>
			</Page>
		</Fragment>
	)
}

export default OrganizationCreate;