import { RestServiceConnection } from "../restServiceConnection";

export class LoginService extends RestServiceConnection {
  authUserCredentials = async (username: string, password: string) => {
    this.response = await this.makeRequest({
      method: "POST",
      url: "/login_check",
      data: {
        username: username,
        password: password,
      },
    });
    return this;
  };
}
