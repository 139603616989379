import { Fragment, useCallback, useContext, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { CustomTable } from "../../../components/table/CustomTable";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import CallsFilter from "../../call/calls-list/call-options/CallsFilter";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { CallService } from "../../../services/calls/servicesService";
import { Link } from "react-router-dom";
import { formatDateFromSeconds } from "../../../utils/date/DateUtils";

interface ICLientCallList {
}

const ClientCallList: React.FC<ICLientCallList> = ({ }) => {

  // USER CAN

  const {userCan} = useContext(PrivilegeContext)

  // FILTERS

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  // FETCH DATA

  /**
   * @ES : Funcion que se encarga de hacer la peticion al servicio de llamadas
   * @EN : Function that makes the request to the call service
   * 
   * @returns LeadsApiResponse
   * @param filters
   */

  const fetchCalls = useCallback(async () => {
    const callService = new CallService();
    const response = await callService.getCalls(filters);
    return response.getResponseData() as any;
  }, [filters]);

  const [data, loading, error, refech] = useFetch(fetchCalls);

  return (
    <Fragment>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle>Listado de LLamadas</CardTitle>
        <div className="d-flex flex-row align-items-center gap-3">
          <CallsFilter updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </div>
      </CardHeader>
      <CardBody>
          {
            data ? (
              <CustomTable
                data={data?.calls ? data.calls : null}
                pagination={true}
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: data as any ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                className={"table table-hover"}
                columns={
                  [
                    {
                      name: "Usuario",
                      keyValue: "user",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center text-center">
                            {element.user?.name || '-'}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Cliente",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element?.client?.id ? 
                              <Link to={`/clients/${element?.client?.id}/show`} color="primary">
                                {element.name || '-'}
                              </Link> : 
                              element.name || '-'
                            }
                          </div>
                        );
                      }
                    },
                    {
                      name: "DNI",
                      keyValue: "nif",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.nif || '-'}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Telefono",
                      keyValue: "phone",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.phone || '-'}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Estado",
                      keyValue: "status",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.status || '-'}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Comentario",
                      keyValue: "comment",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.comment || '-'}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Fecha de Llamada",
                      keyValue: "call_date",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return <div className="fs-7 text-center">{moment(element.callDate.date).format("DD/MM/YYYY H:mm")}</div>;
                      }
                    },
                    {
                      name: "Duracion",
                      keyValue: "call_duration",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {formatDateFromSeconds(element.callDuration) || '-'}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Creacion",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (item: any) => {
                          return <div className="fs-7 text-center">{moment(item.createdAt.date).format("DD/MM/YYYY H:mm")}</div>;
                      }
                    },
                    {
                        name: "Ult. Actualizacion",
                        keyValue: "updatedAt",
                        className: "text-center",
                        render: (item: any) => {
                            return <div className="fs-7 text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                        }
                    },
                    {
                      name: "Organización",
                      keyValue: "company",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: userCan('list','companies',true) ? "text-center" : "d-none",
                      isVisible: userCan('list','companies',true),
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.company.name}
                          </div>
                        );
                      },
                    },
                  ]
                }
              />
            ) : (
              <div className="text-center d-flex justify-content-center"><Spinner/></div>
            )
          }
        </CardBody>
    </Fragment>
  );
};

export default ClientCallList;
