import { Fragment } from "react"
import OperationsList from "./operation-list/OperationsList";
import { FiltersProvider } from "../../components/providers/FiltersProvider";

const OperationListWrapper = () => {


    return (
        <Fragment>
            <FiltersProvider>
                <OperationsList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default OperationListWrapper