import { FC, Fragment, useEffect, useRef, useState } from "react";
import Button from "./bootstrap/Button"
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "./bootstrap/Dropdown"
import Spinner from "./bootstrap/Spinner";
import { ActionStatus, Status } from "../type/actionStatus-type";
import { handleConfirmationAlert } from "../utils/ConfirmationAlert";
import FormGroup from "./bootstrap/forms/FormGroup";
import './StatusOperationDropdown.css';
import useHandleErrors from "../hooks/useHandleErrors";
import { WalletService } from "../services/wallet/walletService";

interface StatusOperationDropdownProps {
    itemId: string;
    status: any;
    additionalInfo?: string;
    onChange?: () => void;
    disabled?: boolean;
    statuses?: ActionStatus[];
    actionStatus ?: boolean;
}

const StatusWalletDropdown: FC<StatusOperationDropdownProps> = ({ itemId, status, onChange, disabled = false, additionalInfo, statuses, actionStatus = false }) => {

    const [currentStatus, setCurrentStatus] = useState<Status>(status);
    const [nameStatus, setNameStatus] = useState<string>(status.name);
    const [color, setColor] = useState<string>(status.color);
    const commentRef = useRef<HTMLTextAreaElement | null>(null);
    const [actionStatusList, setActionStatusList] = useState<Status[]>(statuses ? statuses : []);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleErrors } = useHandleErrors();

    useEffect(() => {
        setActionStatusList(statuses ? statuses : []);
    }, [statuses]);


    const _toggleState = (newStatus: string) => {
        handleConfirmationAlert({
            title: '¿Estás segur@ de que deseas cambiar de estado?',
            html: (
                <>
                    <p>Se cambiará el estado de contrato</p>
                    <FormGroup label="Comentario:" requiredInputLabel>
                        <textarea ref={commentRef} className="form-control" rows={5}></textarea>
                    </FormGroup>
                </>
            ),
            icon: 'warning',
            onConfirm: () => {
                let commentValue = commentRef.current?.value || null;
                
                setIsLoading(true);

                setTimeout(() => {
                    _toggleStatus(newStatus, commentValue);
                }, 200);

            }
        });
    }

    const _toggleStatus = async (
        status: string,
        commentValue: string | null
    ) => {

        let response;

        if (actionStatus) {
            response = await (
                await new WalletService().changeActionWalletStatus(
                    itemId,
                    status,
                    commentValue
                )
            ).getResponseData();
        } else {
            response = await (
                await new WalletService().changeActionStatus(
                    itemId,
                    status,
                    commentValue
                )
            ).getResponseData();
        }

        if (response.success) {
            if (actionStatus) {
                setCurrentStatus(response.data.currentActionStatusWallet);
            }
            else {
                setCurrentStatus(response.data.currentWalletStatus);
            }
            onChange && onChange();
        } else {
            handleErrors(response);
        }
        setIsLoading(false);
    };


    useEffect(() => {
        
        if (currentStatus) {
            setColor(currentStatus?.color ? currentStatus.color : 'whitesmoke');
            setNameStatus(currentStatus.name);
        }
    }, [currentStatus]);


    return (
        <Dropdown className={'status-wallet-dropdown'} key={itemId + "-" + "-dropdownItem"}>
            <DropdownToggle hasIcon={false}>
                <Button isLink isDisable={disabled} className='text-nowrap fs-8  p-2' style={{ backgroundColor: color }}>
                    {isLoading ? <Spinner isSmall /> : <Fragment><span className='text-white fw-bold fs-8 ' style={{ color: '#3e3e3e' }}> {nameStatus}  </span></Fragment>}
                    {additionalInfo ? <Fragment> <br /> <small className='text-white fw-bold fs-8 ' style={{ color: '#3e3e3e' }}> {additionalInfo} </small> </Fragment> : null}
                </Button>

            </DropdownToggle>
            <DropdownMenu className="max-h-250 overflow-auto">
                {actionStatusList?.filter((actionStatus: Status) => actionStatus.id !== currentStatus?.id)?.map((actionStatus: Status) => {
                    return (
                        <DropdownItem key={itemId + "-" + actionStatus.id + "-dropdownItem"} className={'mt-2'} >
                            <Button
                                className="text-nowrap fs-8  fw-semibold text-center"
                                style={{ backgroundColor: actionStatus.color ? actionStatus.color : 'white', color: "white" }}
                                isLink
                                onClick={() => _toggleState(actionStatus.id)}>
                                <span className="text-center w-100 fs-8 " style={{ color: 'white' }}>
                                    {actionStatus.name}
                                </span>
                            </Button>
                        </DropdownItem>
                    )
                }
                )}
            </DropdownMenu>
        </Dropdown>
    )
}

export default StatusWalletDropdown;