import { useFormik } from "formik";
import { ChangeEvent, FC, useCallback, useEffect } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Button from "../../../../components/bootstrap/Button";
import Input from "../../../../components/bootstrap/forms/Input";
import { ServiceService } from "../../../../services/services/servicesService";
import useFetch from "../../../../hooks/useFetch";
import { Product } from "../../../../type/product-type";
import { ProductService } from "../../../../services/products/productService";
import { toast } from "react-toastify";
import { Service, Services } from "../../../../type/service-type";
import Select from "../../../../components/bootstrap/forms/Select";

interface ProductServiceModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    newService(...args: unknown[]): unknown;
    productObj: Product | any;
    businessId: string;
    serviceEdit?: any;
}

const ProductServiceModal: FC<ProductServiceModalProps> = ({ isOpen, setIsOpen, productObj, newService, businessId , serviceEdit}) => {

        const mode = serviceEdit ? 'edit' : 'create';

        const formik = useFormik({
            enableReinitialize: true,
            initialValues: {
                product: productObj?.id,
                service: serviceEdit?.service.id || null,
                points: serviceEdit?.points,
                commission: serviceEdit?.commission,
                warning: serviceEdit?.warning || ''
            },
            onSubmit: (values) => {
                if (values.product != null || values.product != undefined) {
                    if (serviceEdit) {
                        editProductService(values);
                    }
                    else {
                        createProductService(values);
                    }
                } else {
                    setSelectedServices(values);
                }
            },
        });

    const fetchService = useCallback(async () => {
        const response = await (new ServiceService()).getServices({ filter_filters: { business: businessId} });
        return response.getResponseData();
    }, [productObj, businessId]);

    const [services, loading, error] = useFetch(fetchService);

    const getServiceList = () => {
        if (services as Services) {
            return services.services.map((s: { id: any; name: any; }) => {
                return {
                    value: s.id,
                    label: s.name
                }
            })
        }
        return [];
    }

    const setSelectedServices = async (values: any) => {
        try {
            const selectedService = services.services.find((service: Service) => service.id === values.service);
            const service = { ...values, service: { ...selectedService } };
            newService(service);
        } catch (e) {
            toast.error('Error al añadir Servicios');
            console.log('error', e);
        } finally {
            setIsOpen(false);
            formik.resetForm();
        }
    }

    const createProductService = async (values: any) => {
        try {
            let response = await (await (new ProductService()).addService(values)).getResponseData();
            if (response.success) {
                toast.success('Servicio añadido exitosamente');
                const service = response.data.services.find((service: any) => service.service.id === values.service);   
                newService(service);
            } else {
                toast.error(response.message || 'Error al añadir Servicios');
            }
        } catch (e) {
            toast.error('Error al añadir Servicios');
            console.log('error', e);
        } finally {
            setIsOpen(false);
            formik.resetForm();
        }
    }

    const editProductService = async (values: any) => {
        try {
            let response = await (await (new ProductService()).editService(values)).getResponseData();
            if (response.success) {
                toast.success('Servicio editado exitosamente');
                const service = response.data.services.find((service: any) => service.service.id === values.service);
                newService(service);
            } else {
                toast.error(response.message || 'Error al editar Servicios');
            }
        } catch (e) {
            toast.error('Error al editar Servicios');
            console.log('error', e);
        } finally {
            setIsOpen(false);
            formik.resetForm();
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Servicio'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{mode == "create" ? 'Añadir Servicio' : 'Editar Servicio'}</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel label='Servicios:' className='col-md-6'>
                            <Select required id='service' value={formik.values.service || ''} ariaLabel='Default select example' onChange={formik.handleChange} placeholder='Elegir servicios...'
                                list={getServiceList()} />
                        </FormGroup>
                        <FormGroup requiredInputLabel id='commission' label='Comisión:' className='col-md-6'>
                            <Input type="number" min={0} step={0.01} required value={formik.values.commission} onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("commission" , e.target.value)}} />
                        </FormGroup>
                    </div>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='points' label='Puntos:' className='col-md-6'>
                            <Input type="number" min={0} step={0.01} value={formik.values.points} required onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("points" , e.target.value)}} />
                        </FormGroup>
                        <FormGroup id='warning' label='Advertencia:' className='col-md-6'>
                            <Input value={formik.values.warning || ''} onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon="Save" color='primary' type="submit">
                        {mode == "create" ? 'Añadir' : 'Editar'} Servicio
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default ProductServiceModal;