import { ChangeEvent, FC, useState } from "react";
import { CustomTable } from "../../../components/table/CustomTable";
import { Service } from "../../../type/service-type";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Icon from "../../../components/icon/Icon";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

interface serviceTableProps {
    services: any;
    formik: any;
}

const ServiceTableWizard:FC<serviceTableProps> = ({services, formik}) => {

    const [selectedItems, setSelectedItems] = useState<Service[]>([]);

    const {userCan} = usePrivilege();

    const handleCheckboxChange = (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
        service: any
    ) => {
        const { checked } = event.target;
        let newSelectedItems;

        if (checked) {
            if (service.warning != null && service.warning != undefined && service.warning != "") {
                toast.warning(service.warning);
            }
            newSelectedItems = [...selectedItems, service];
        } else {
            newSelectedItems = selectedItems.filter(item => item.id !== id);
        }

        formik.setFieldValue('servicesSelected', newSelectedItems);
        setSelectedItems(newSelectedItems);
    };

    return (
            <CustomTable
                data={services}
                className={"table table-hover p-3 fs-7"}
                columns={[
                    {
                        name: "",
                        keyValue: "selected",
                        className: "text-center",
                        render: (item: any) => {
                            return <div className="d-flex justify-content-center align-items-center">
                            <div>
                                <div key={item.id} className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`checkbox-${item.id}`}
                                        checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                        onChange={(event) => handleCheckboxChange(event, item.id, item)}
                                    />
                                </div>
                            </div>
                        </div>
                        },
                    },
                    {
                        name: "Nombre",
                        keyValue: "name",
                        className: "text-center",
                        render: (item: any) => {
                            return <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    {
                                        item.warning &&
                                        <Tooltips title={item.warning}>
                                            <Icon icon="Info" color="info" size={'lg'} className="me-2" />
                                        </Tooltips>
                                    }
                                    {item.service.name}
                                </div>
                            </div >
                        }
                    },
                    {
                        name: "Comision",
                        keyValue: "commission",
                        className: "text-center",
                        isVisible: userCan('show_commissions', 'operations'),
                        render: (item: any) => {
                            return <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    {item.commission}
                                </div>
                            </div >
                        }
                    },
                    {
                        name: "Puntos",
                        keyValue: "points",
                        className: "text-center",
                        isVisible: userCan('show_points', 'operations'),
                        render: (item: any) => {
                            return <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    {item.points}
                                </div>
                            </div >
                        }
                    },
                    {
                        name: "Advertencia",
                        keyValue: "warning",
                        className: "text-center",
                        render: (item: any) => {
                            return <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    {item.warning}
                                </div>
                            </div >
                        }
                    }
                ]}
            />
        );
    }

export default ServiceTableWizard;