import { useCallback, useEffect, useState } from "react";
import { ActionStatus } from "../../../type/actionStatus-type";
import { CampaignStatus } from "../../../type/campaign-type";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import Button from "../../bootstrap/Button";
import useFetch from "../../../hooks/useFetch";
import { CampaignService } from "../../../services/campaigns/campaignService";
import useFilters from "../../../hooks/useFilters";
import StatusSelector from "./StatusSelector";
import { StatusService } from "../../../services/status/statusService";

type CampaignStatusSelectorModalProps = {
    campaignId: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onClose: Function;
}

const CampaignStatusSelectorModal: React.FC<CampaignStatusSelectorModalProps> = ({ campaignId, isOpen, setIsOpen, onClose }) => {
    
    const [selectedStatuses, setSelectedStatuses] = useState<ActionStatus[]>([]);
    const [statusesToSave, setStatusesToSave] = useState<ActionStatus[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ campaign: campaignId }, [], 99999);

    const [data] = useFetch(
        useCallback(async () => {
            const campaignService = new CampaignService();
            const response = await campaignService.listCampaignStatuses(filters);
            return response.getResponseData();
        }, [])
    )
    
    // Loop through data and set selected statuses
    useEffect(() => {
        if(data){
            let statuses: ActionStatus[] = [];
            for (let status of data) {
                statuses.push(status.status);
            }
            setSelectedStatuses(statuses);
            
        }
    }, [data]);

    const _handleChange = async (values: any[]) => {
        const actionStatusService = new StatusService();
        const response = (await actionStatusService.getActionStatusById(values[0])).getResponseData();
        if (response.success) {
            setStatusesToSave([response.data]);
        } else {
            setStatusesToSave([]);
        }
    }

    const _notifyChange = () => {
        onClose(statusesToSave);
    }

    return (
        <>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={_notifyChange} size={'lg'}>
                <ModalHeader setIsOpen={setIsOpen}>
                    <ModalTitle id="modal-allowed-statuses">Estados permitidos</ModalTitle>
                </ModalHeader>
                <ModalBody>
                        <StatusSelector entity="all" defaultSelected={[]} onChange={_handleChange} isMulti={false} discardSelected/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={_notifyChange}>Guardar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};


export default CampaignStatusSelectorModal;