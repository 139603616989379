import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {  Navigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { PrivilegeContext } from '../components/priviledge/PriviledgeProvider';
import ErrorPageComponent from '../pages/extra/ErrorPageComponent';

interface ProtectedRouteProps {
  component: React.ComponentType;
  access: any|undefined;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, access }) => {

  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const {userCan} = useContext(PrivilegeContext)

  const errorAccessPage = <ErrorPageComponent error={"No tienes acceso a este recurso"}/>

  const determineAccess = () => {

    if(access !== undefined) {

      if (access.actions) {

        let allowed = false;

        access.actions.forEach((action: string) => {
          if (userCan(action, access.group)) {
            allowed = true;
          }
        });

        if (!allowed) {
          return errorAccessPage;
        }
      } else if (!userCan(access.action, access.group)) {
        return errorAccessPage;
      }
    }

    return (component);
  }

  return (
    <>
      {
        (user && isAuthenticated) ? determineAccess() : null
      }
      {
        (user && !isAuthenticated) ? <Navigate to="/" /> : null
      }
      {
        (!user && !isAuthenticated) ? <Navigate to="/login" /> : null
      }
    </>
  )

};

export default ProtectedRoute;
