import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import { Client } from "../../type/client-type";
import { Link } from "react-router-dom";
import CompleteOperationForm from "./CompleteOperationForm";
import { OperationService } from "../../services/operations/operationService";
import { Operation } from "../../type/operation-type";

type EditOperationModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    operationId: string
    onOperationEdit?: (operation: any) => void;
}

const EditOperationModal: React.FC<EditOperationModalProps> = ({ visible, setVisible, operationId, onOperationEdit }) => {

    // SERVICES
    const operationService = new OperationService()

    // STATE
    const [client, setClient] = useState<Client | null>(null)
    const [operation, setOperation] = useState<Operation | null>(null)

    // ACTIONS
    const _getOperation = async () => {
        if (operationId === null) return
        let response = (await operationService.getOperationById(operationId)).getResponseData()
        if (response.success) {
            setOperation(response.data)
        }
    }

    useEffect(() => {
        if(visible) {
            _getOperation()
        } else {
            setOperation(null)
            setClient(null)
        }
    }, [visible])

    useEffect(() => {
        if (operation) {
            setClient(operation.client)
        }
    }, [operation])

    return (
        <>
            <Modal id="create-operation-modal" setIsOpen={setVisible} size={'xl'} isOpen={visible} isStaticBackdrop>
                <ModalHeader setIsOpen={setVisible}>
                    <ModalTitle id={'cnae-modal-selector'} tag="h3" className="fw-bold">
                        Editar contrato de <Link to={`/clients/${client?.id}/show`} target="_blank" title="Ver cliente en una pestaña nueva">{client?.name}</Link>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="p-4">
                    {
                        (operation && client) ? <CompleteOperationForm clientId={client?.id} operationId={operation.id} onOperationEdit={onOperationEdit}></CompleteOperationForm> : <></>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}


export default EditOperationModal