import { FC, useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal"
import Button from "../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import Icon from "../../../../components/icon/Icon";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import CustomSelect from "../../../../components/selectors/general/CustomSelect";
import { SingleValue } from "react-select";
import { ConfiguraciónService } from "../../../../services/configuration/configurationService";
import { toast } from "react-toastify";
import Spinner from "../../../../components/bootstrap/Spinner";
import Input from "../../../../components/bootstrap/forms/Input";
import * as Yup from 'yup'; 
import CreateOptionPostalCodeExcludedFormik from "../../../../components/bootstrap/forms/CreateOptionPostalCodeExcludedFormik";
import CreateOptionPostalCodeIncludedFormik from "../../../../components/bootstrap/forms/CreateOptionPostalCodeIncludedFormik";
import {showErrors, verifyClass} from "../../../../utils/forms/VerifyForms";

interface TypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    refetch?: any;
    walletStatuses: any;
    allWalletStatuses: any;
    walletActionStatuses: any;
    campaigns: any;
}

const validationSchema = Yup.object().shape({
    triggerStatus: Yup.string().required('Debes indicar al menos un estado disparador'),
    statusResult: Yup.string().required('Debes indicar al menos un estado resultado de la cartera'),
    expirationTime: Yup.number().required('El tiempo de expiración es requerido').integer('El valor debe ser un número entero').min(0, 'El valor debe ser mayor o igual a 0'),
    campaign: Yup.string().nullable()
});

const clientTypes = [
    { value: 'particular', label: 'Particular' },
    { value: 'empresa', label: 'Empresa' },
    { value: 'todos', label: 'Todos' }
];

const AddConfigurationTimeModal:FC<TypeModalProps> = ({ isOpen, setIsOpen, refetch, walletStatuses, allWalletStatuses, walletActionStatuses, campaigns }) => {

    const [loading, setLoading] = useState(false)

    const handleSubmit  = async (values: any) => {
        setLoading(true);
        const configurationService = new ConfiguraciónService();
        const response = await ( await configurationService.createDetailedConfigurationWallet(values) ).getResponseData();
        if (response.success) {
            toast.success('Configuración de tiempo en Cartera guardada correctamente');
            setLoading(false);
            setIsOpen(false);
            formik.resetForm();
            refetch && refetch();
        } else {
            toast.error(response.message);
            setLoading(false);
            setIsOpen(false);
            formik.resetForm();
            refetch && refetch();
        }
    }

    const formik = useFormik({
        initialValues: {
            triggerStatus: '',
            clientType: null,
            statusResult: null,
            postalCodeExclude : [], 
            postalCodeInclude : [],
            statusResultAction  : null,
            expirationTime: '',
            campaign: null
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            let valuesToSend = values;

            if (!valuesToSend.statusResult && !valuesToSend.statusResultAction) {
                toast.warning('Debes completar al menos un cambio de estado');
            }


            if (!valuesToSend.campaign || valuesToSend.campaign === "") {
                valuesToSend.campaign = null
            }

            if (valuesToSend.clientType === 'todos' || valuesToSend.clientType === null) {
                valuesToSend.clientType = null;
            }

            handleSubmit(valuesToSend);
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="lg" titleId="Nueva Configuración de Cartera">
            <ModalHeader setIsOpen={setIsOpen} className="p-4">
                <ModalTitle id="new_configuration" className="fw-bold fs-4 text-primary w-100 h-100">
                    <Icon icon="Settings" className="me-2 text-primary" size={'2x'} />
                    Nueva Configuración de Tiempo en Cartera
                </ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className="row">
                        <div className="col-5">
                            <FormGroup requiredInputLabel id="triggerStatus" label="Estado" className="col-12">
                                <CustomSelect placeholder="Elegir Estado..." is_multi={false} customClass={verifyClass(formik, 'triggerStatus')}  options={allWalletStatuses}
                                onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('triggerStatus', value.value)}
                                />
                                {showErrors(formik,'triggerStatus')}
                            </FormGroup>
                            <FormGroup label='Tiempo de Expiración' className='col-md-12 mt-3' requiredInputLabel={true}>
                                <Input id='expirationTime' onChange={formik.handleChange} className={`form-control ${verifyClass(formik, 'expirationTime')}`} value={formik.values.expirationTime || ''} onBlur={formik.handleBlur} />
                                {/* {formik.errors.expirationTime ? <div className="text-danger mt-1">{formik.errors.expirationTime}</div> : <></>} */}
                                {showErrors(formik,'expirationTime')}
                            </FormGroup>
                        </div>
                        <div className="col-2 d-flex justify-content-around align-items-center flex-column">
                            <Icon icon="ArrowForward" className="ms-2 me-2 text-primary col-12 mb-3 mt-3" size={'3x'}/>
                            <Icon icon="SubdirectoryArrowRight" className="ms-2 me-2 text-primary" size={'3x'} />
                        </div>
                        <div className="col-5">
                            <FormGroup id="statusResult" label="Estado de Cartera" className="col-12" requiredInputLabel={true}>
                                <CustomSelect placeholder="Elegir Estado..." customClass={verifyClass(formik, 'statusResult')} is_multi={false} options={walletStatuses}
                                onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('statusResult', value.value)} />
                                {showErrors(formik,'statusResult')}
                            </FormGroup>
                            <FormGroup id="statusResultAction" label="Estado Acción de Cartera:" className="col-12 mt-3">
                                <CustomSelect placeholder="Elegir Estado de acción..." is_multi={false} options={walletActionStatuses}
                                onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('statusResultAction', value.value)} />
                                {showErrors(formik,'statusResultAction')}
                            </FormGroup>
                        </div>
                        <FormGroup id="clientType" label="Tipo de Cliente" className="col-md-6 mt-5" >
                            <CustomSelect placeholder="Elegir tipo..." is_multi={false} options={clientTypes}
                            onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('clientType', value.value)} />
                            {showErrors(formik,'clientType')}
                        </FormGroup>
                        <FormGroup id="campaign" label="Departamento de destino" className="col-md-6 mt-5" >
                            <CustomSelect placeholder="Elegir departamento..." is_multi={false} options={campaigns} required={false}
                                          defaultValue={formik.values.campaign}
                            onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('campaign', value.value)} />
                            {showErrors(formik,'campaign')}
                        </FormGroup>
                        <FormGroup id="clientType" label="Códigos Postales (incluidos)" className="col-md-6 mt-3" >
                            <CreateOptionPostalCodeIncludedFormik className="mt-1" formik={formik} />
                        </FormGroup>
                        <FormGroup id="campaign" label="Códigos Postales (excluidos)" className="col-md-6 mt-3" >
                            <CreateOptionPostalCodeExcludedFormik className="mt-1" formik={formik} />
                        </FormGroup>
                </ModalBody>
                <ModalFooter className="px-4 pb-4">
                    <Button icon={loading ? '' : 'Save'} color="primary" type="submit" isDisable={loading}>
                        {loading && <Spinner color={'light'}/>}
                        {loading ? '' : 'Guardar Configuración'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddConfigurationTimeModal;