import { Fragment, useCallback, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { useNavigate } from "react-router-dom";
import { CompanyService } from "../../../services/companies/companyService";
import { CompaniesApiResponse } from "../../../type/company-type";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import StatusDropdown from "../../../components/StatusDropdown";
import CompanyFilters from "./companies-options/CompanyFilters";
import AsyncImg from "../../../components/AsyncImg";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import useHandleErrors from "../../../hooks/useHandleErrors";

const CompanyList = () => {

	const navigate = useNavigate();

	const { handleErrors } = useHandleErrors();

	const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

	useEffect(() => {
		checkIfUrlHasFilters();
	}, []);

	const fetchCompanies = useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getCompanies(filters);
		return response.getResponseData() as CompaniesApiResponse;
	}, [filters]);

	const [data, loading, error] = useFetch(fetchCompanies);
	const [changingStatus, setChangingStatus] = useState<string[]>([]);

	const toggleCompanyStatus = async (
		id: string,
		status: boolean,
		toggleStatus: Function
	) => {
		try {
			setChangingStatus([...changingStatus, id]);
			let response = await (
				await new CompanyService().toggleCompantStatus(id, status)
			).getResponseData();
			if (response.success) {
				setChangingStatus(changingStatus.filter((item) => item !== id));
				toggleStatus(status);
			} else {
				setChangingStatus(changingStatus.filter((item) => item !== id));
			}
		} catch (error: any) {
			setChangingStatus(changingStatus.filter((item) => item !== id));
		}
	};

	const deleteCompany = async (id: string) => {
		let response = (await new CompanyService().deleteCompany(id)).getResponseData();
		if (response.success) {
			toast.success('La compañía se ha eliminado correctamente');
			updateFilters({ ...filters });
		} else {
			handleErrors(response);
		}
	}

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Fragment>
						<CardTitle>Listado de Organizaciones</CardTitle>
						<SubheaderSeparator />
						<Button
							color="light"
							icon="Add"
							isLight
							onClick={() => {
								navigate("create");
							}}
						>

							Añadir Organización
						</Button>
					</Fragment>
				</SubHeaderLeft>
				<SubHeaderRight>
					<CompanyFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
				</SubHeaderRight>
			</SubHeader>
			<Page container="fluid">
				<Card stretch={false}>
					{
						data ? (
								<CustomTable
									data={data?.companies ? data.companies : null}
									pagination={true}
									defaultLimit={filters.limit || 50}
									defaultOrder={filters.filter_order || undefined}
									paginationData={{
										pageSize: filters.limit,
										currentPage: filters.page,
										pageCount: data as CompaniesApiResponse ? data.lastPage : 1,
										totalCount: data.totalRegisters,
										handlePagination: updatePage,
										handlePerPage: updatePageSize,
									}}
									className={"table table-hover"}
									columns={
										[
											{
												name: "",
												keyValue: "name",
												render: (element: any) => {
													return (
														<div className="d-flex justify-content-center">
															<div className='user-avatar'>
																<AsyncImg id={element.logo?.id ? element.logo?.id : null} />
															</div>
														</div>
													);
												},
											},
											{
												name: "Nombre",
												keyValue: "name",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												render: (element: any) => {
													return (
														<div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => { navigate(`${element.id}/profile`) }}>
															{element.name}
														</div>
													)
												},
											},
											{
												name: "Descripción",
												keyValue: "description",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												cellClassName: "text-center",
											},
											{
												name: "CIF",
												keyValue: "cif",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												cellClassName: "text-center",
											},
											{
												name: "Dirección",
												keyValue: "address",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												cellClassName: "text-center",
											},
											{
												name: "Email",
												keyValue: "email",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												cellClassName: "text-center",
											},
											{
												name: "Teléfono",
												keyValue: "telephone",
												className: "text-center",
												cellClassName: "text-center",
											},
											{
												name: "Estado",
												keyValue: "active",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												render: (element: any) => {
													return (
														<div className={'text-center'}>
															<StatusDropdown
																disabled={changingStatus.includes(element.id)}
																itemId={element.id}
																status={element.active}
																change={toggleCompanyStatus}
																key={element.id}
															/>
														</div>
													);
												},
											},
											{
												name: "Fecha de creación",
												keyValue: "createdAt",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												render: (element: any) => {
													return (
														<div className={'text-center'}>
															<span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
														</div>
													)
												},
											},
											{
												name: "Última Modificación",
												keyValue: "updatedAt",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												render: (item: any) => {
													return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
												}
											},
											{
												name: "Nº Usuarios",
												keyValue: "users",
												sortable: true,
												sortColumn: updateFilterOrder,
												className: "text-center",
												render: (element: any) => {
													return (
														<div className={'text-center'}>
															{element.users.length}
														</div>
													);
												},
											},
				
											{ name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
										]
									}
									actions={
										[
											{
												title: "Ver",
												buttonType: 'icon',
												iconColor: 'text-primary',
												iconPath: '/media/icons/duotune/general/gen021.svg',
												additionalClasses: 'text-primary',
												description: "Ver perfil de organización",
												callback: (item: any) => {
													navigate(`${item.id}/profile`)
												},
											},
											{
												title: "Editar",
												buttonType: 'icon',
												iconColor: 'text-info',
												iconPath: '/media/icons/duotune/general/gen055.svg',
												additionalClasses: 'text-primary',
												description: "Editar perfil del organización",
												callback: (item: any) => {
													navigate(`${item.id}/edit`);
												},
											},
				
											{
												title: "Eliminar",
												buttonType: 'icon',
												iconColor: 'text-danger',
												iconPath: '/media/icons/duotune/general/gen027.svg',
												additionalClasses: 'text-danger',
												description: "Eliminar organización",
												callback: (item: any) => {
													handleConfirmationAlert({
														title: "Eliminar organización",
														text: "¿Está seguro que desea eliminar la organización? \n \n TODOS LOS DATOS relacionados con la ORGANIZACIÓN serán eliminados. Si no deseas eliminar el HISTÓRICO de datos de la organización puedes DESACTIVARLA en lugar de eliminarla.",
														icon: "warning",
														onConfirm: () => {
															deleteCompany(item.id);
														}
													})
												},
											},
										]
									}
								/>
							) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
					}
				</Card>
			</Page>
		</Fragment>
	)
}

export default CompanyList;

