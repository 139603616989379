import { FC, useState } from "react";
import { NewOffice, Office } from "../../../type/office-type";
import { useFormik } from "formik";
import { OfficeService } from "../../../services/ofiice/officeService";
import { toast } from "react-toastify";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { useOperationAgentsList } from "../../../hooks/useListData";
import CustomSelect from "../../../components/selectors/general/CustomSelect";
import { MultiValue } from "react-select";
import { RankingService } from "../../../services/ranking/rankingService";
import * as yup from 'yup';

interface RankingModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    setReadMode(...args: unknown[]): unknown;
    ranking: any;
}

const RankingModalSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    description: yup.string().required('La descripción es requerida'),
    users: yup.array().min(1, 'Debe seleccionar al menos un agente')
});

const RankingModal: FC<RankingModalProps> = ({ isOpen, setIsOpen, readMode, setReadMode, ranking }) => {

    const [modal, setModal] = useState(false);
    const [loadign, setLoading] = useState(false);

    const agents = useOperationAgentsList({filters_filters: {active: true} , all: true});

    const onChangeAgents = (newValue: MultiValue<unknown>) => {
        let agents = newValue.map((value: any) => value.value);
        formik.setFieldValue('users', agents);
      }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: RankingModalSchema,
        initialValues: {
            name: ranking?.name,
            description: ranking?.description || null,
            users : ranking?.users.map((user: any) => user?.user.id) || []
        },
        onSubmit: (values) => {
            if (ranking === null) {
                createRanking(values);
            } else {
                updateRanking(values)
            }
        },
    });


    const createRanking = async (values: any) => {
        try {
            setLoading(true);
            let response = await (await (new RankingService()).createRanking(values)).getResponseData();
            if (response.success) {
                toast.success('Ranking creado con éxito');
            } else {
                toast.error('Error al crear el Ranking');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al crear el Ranking');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const updateRanking = async (values: any) => {
        try {
            setLoading(true);
            values.rankingId = ranking.id;
            let response = await (await (new RankingService()).editRanking(values)).getResponseData();
            if (response.success) {
                toast.success('Ranking creado con éxito');
            } else {
                toast.error('Error al editar el Ranking');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al editar el Ranking');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true}  size={!modal? 'xl':'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode ? (ranking ? 'Editar' : 'Nueva') : 'Ver'} Ranking</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.name || ''} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='description' requiredInputLabel label='Breve descripción' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.description || ''} autoComplete="off" onChange={formik.handleChange} required/>
                            </FormGroup>
                            <FormGroup id='users' label='Agentes' requiredInputLabel className='col-md-12 mt-3'>
                                <CustomSelect options={agents} is_multi={true} required
                                defaultValue={formik.values.users ? agents.filter((user: any) => formik.values.users.includes(user.value)): []} 
                                placeholder='Elegir Agentes...' onChange={onChangeAgents} />
                                {formik.errors.users ? <div className="text-danger">{formik.errors.users as string}</div> : <></>}
                            </FormGroup>
                        </div>
                    </ModalBody>
                    
                    {(!readMode) ?
                        <ModalFooter className='px-4 pb-4'>
                            <Button color='primary' type="submit" icon={loadign ? "" : "Save"} isDisable={loadign}>
                                {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                            </Button>
                        </ModalFooter>
                        : <></>}
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default RankingModal;