import { useFormik } from "formik";
import { FC, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Checks, { ChecksGroup } from "../../../components/bootstrap/forms/Checks";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Lead, NewLead } from "../../../type/lead-type";
import { LeadService } from "../../../services/lead/leadService";


interface ProductModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    setReadMode(...args: unknown[]): unknown;
    lead: Lead | any;
}

const LeadModal: FC<ProductModalProps> = ({ isOpen, setIsOpen, readMode, setReadMode, lead }) => {
    const [modal, setModal] = useState(false);

    const [loadign, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: lead?.name,
            description: lead?.description || null,
            active: lead?.active || true
        },
        onSubmit: (values, selectedServices) => {
            if (lead === null) {
                createLead(values as NewLead);
            } else {
                updateLead(values as Lead)
            }
        },
    });


    const createLead = async (values: NewLead) => {
        try {
            setLoading(true);
            let response = await (await (new LeadService()).createLead(values)).getResponseData();
            if (response.success) {
                toast.success('Tipo de Trámite creado con éxito');
            } else {
                toast.error('Error al crear el tipo de trámite');
            }
        } catch (e) {
            toast.error('Error al crear el tipo de trámite');
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);            
        }
    }

    const updateLead = async (values: any) => {
        try {
            setLoading(true);
            values.lead = lead.id;
            let response = await (await (new LeadService()).editLead(values)).getResponseData();
            if (response.success) {
                toast.success('Tipo de Trámite editado con éxito');
            } else {
                toast.error('Error al editar el tipo de trámite');
            }
        } catch (e) {
            toast.error('Error al editar el tipo de trámite');
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true}  size={!modal? 'xl':'sm'} titleId='Nuevo Tipo de Trámite'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode ? (lead ? 'Editar' : 'Nuevo') : 'Ver'} Tipo de Trámite</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.name || ''} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='description' label='Breve descripción' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.description || ''} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>
                    
                    {(!readMode) ?
                        <ModalFooter className='px-4 pb-4'>
                            <Button color='primary' type="submit" icon={loadign ? "" : "Save"} isDisable={loadign}>
                                {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                            </Button>
                        </ModalFooter>
                        : <></>}
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default LeadModal;