import { FC, useCallback } from "react";
import { WalletService } from "../../../../services/wallet/walletService";
import Spinner from "../../../../components/bootstrap/Spinner";
import useFetch from "../../../../hooks/useFetch";
import { ClientService } from "../../../../services/clients/clientService";
import { CustomTable } from "../../../../components/table/CustomTable";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import { Link } from "react-router-dom";
import Icon from "../../../../components/icon/Icon";
import { toast } from "react-toastify";
import {ClickToCall} from "../../../../components/calls/ClickToCall";

interface WalletCupsCardProps {
  cups: string;
}

const WalletCupsClientCard: FC<WalletCupsCardProps> = ({ cups }) => {

  // FETCH FUNCTION

  /**
   * 
   * @EN This function is responsible for fetching the client's cups
   * @ES Esta función se encarga de obtener los cups del cliente
   * 
   * @returns Fetch the client's cups
   */

  const fetchClientCups = useCallback(async () => {
    const clientService = new ClientService();
    const response = await (
      await clientService.getClientByCups(cups)
    ).getResponseData();
    if (response.success) {
      return response;
    }
  }, [cups]);

  const [data, loading, error, refech] = useFetch(fetchClientCups);

  const getMostRecentDate = (comment: any) => {
    const createdAtDate = new Date(comment.createdAt.date);
    const updatedAtDate = comment.updatedAt
      ? new Date(comment.updatedAt.date)
      : null;

    return updatedAtDate && updatedAtDate > createdAtDate
      ? updatedAtDate
      : createdAtDate;
  };

  // FUNCTIONS

  /**
   * 
   * @EN Function to toggle the client's favourite status
   * @ES Función para cambiar el estado de favorito del cliente
   * 
   * @param id 
   * @param favourite 
   */

  const _toggleFavourite = async (id: string, favourite: boolean) => {
    try {
      let response = await (
        await new ClientService().toggleClientFavourite(id, !favourite)
      ).getResponseData();
      if (response.success) {
        toast.success("Cliente actualizado correctamente");
        refech();
      } else {
        toast.error("Error al actualizar el Cliente");
      }
    } catch (error: any) {
      toast.error("Error al actualizar el Cliente");
    }
  };

  // RENDER

  return <div>{
    data ? (
      <CustomTable
          data={data}
          className={"table table-hover fs-7"}
          columns={[
            {
              name: "Nombre",
              keyValue: "name",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <Link to={`/clients/${row.id}/show`} color="primary" className="d-flex justify-content-center align-items-center">
                    {row.name || "-"}
                  </Link>
                );
              },
            },
            {
              name: "DNI",
              keyValue: "nif",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.nif || "-"}
                  </div>
                );
              },
            },
            {
              name: "Email",
              keyValue: "email",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.email || "-"}
                  </div>
                );
              },
            },
            {
              name: "Telefono 1",
              keyValue: "phone1",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.phone1 ? <ClickToCall telephone={row.phone1} /> : "-"}
                  </div>
                );
              },
            },
            {
              name: "Telefono 2",
              keyValue: "phone2",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.phone2 ? <ClickToCall telephone={row.phone2} /> : "-"}
                  </div>
                );
              },
            },
            {
              name: "Dirección",
              keyValue: "address",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.address || "-"}
                  </div>
                );
              },
            },
            {
              name: "Localidad",
              keyValue: "locality",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.locality || "-"}
                  </div>
                );
              },
            },
            {
              name: "Província",
              keyValue: "province",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.province || "-"}
                  </div>
                );
              },
            },
            {
                name: "Código Postal",
                keyValue: "postalCode",
                className: "text-center",
                cellClassName: "text-center",
                render: (row) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      {row.postalCode || "-"}
                    </div>
                  );
                },
            },
            {
              name: "Iban",
              keyValue: "iban",
              className: "text-center",
              cellClassName: "text-center",
            },
            {
              name: "Favorito",
              keyValue: "favourite",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center" onClick={() => _toggleFavourite(element.id, element.favourite)}>
                    <Tooltips title={element.favourite ? "Quitar de favoritos" : "Añadir a favoritos"}>
                      {element.favourite ? (
                        <Icon icon="Star" color="info" size={"2x"} />
                      ) : (
                        <Icon icon="Star" color="gray" size={"2x"} />
                      )}
                    </Tooltips>
                  </div>
                );
              }
            },
          ]}
        />
      ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
    }</div>;
};

export default WalletCupsClientCard;
