import { Fragment, useCallback } from "react";
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Select from "../../../components/bootstrap/forms/Select";
import Input from "../../../components/bootstrap/forms/Input";
import ServicesProductCard from "../product-create/products-components/ServiceProductCard";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import { ProductService } from "../../../services/products/productService";
import { Product } from "../../../type/product-type";
import useFetch from "../../../hooks/useFetch";
import { CheckCircleOutline, HighlightOff } from "../../../components/icon/material-icons";
import Spinner from "../../../components/bootstrap/Spinner";
import Badge from "../../../components/bootstrap/Badge";

const ProductView = () => {

    const navigate = useNavigate();

    const { idcm, id } = useParams<{ idcm: string, id: string }>();

    const fetchProduct = useCallback(async () => {
        const productService = new ProductService();
        const response = await productService.getProductById(id || '');
        return response.getResponseData() as Product;
    }, [id]);

    const [product, loading, error, refech] = useFetch(fetchProduct);

    return (
        <>
            {product ? (
                <Fragment>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='primary' isLink icon='ArrowBack' onClick={() => {
                                if (idcm) {
                                    navigate(`/companies/${idcm}/profile`, { replace: true })
                                } else {
                                    navigate(-1)
                                }
                            }}>
                            </Button>
                            <CardLabel icon='Search' iconColor='info'>
                                <CardTitle>Ver Producto</CardTitle>
                            </CardLabel>
                        </SubHeaderLeft>
                        <SubHeaderRight>
                            <Fragment>
                                <Button
                                color="primary"
                                icon="Edit"
                                isLight
                                onClick={() => {
                                    navigate(`/products/${product.id}/edit`);
                                }}
                                >
                                Editar Producto
                                </Button>
                            </Fragment>
                        </SubHeaderRight>
                    </SubHeader>
                    <Page container='fluid'>
                        <Card>
                            <CardBody>
                                <div className='row g-4'>
                                    <FormGroup label='Nombre:' className='col-md-4'>
                                        <Input id="name" required value={product?.name} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Comercializadora:' className='col-md-4'>
                                        <Input id='business' value={product?.business.name} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Tipo de producto:' className='col-md-4'>
                                        <Input id='productType' value={product?.productType.name} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Breve descripción:' className='col-md-12'>
                                        <Input id='description' value={product?.description} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='priority' label='Prioridad:' className='col-md-3'>
                                        <Input id='priority' value={product?.priority} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='points' label='Puntos:' className='col-md-3'>
                                        <Input id='points' value={product?.points} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='base_commission' label='Comisión:' className='col-md-3'>
                                        <Input id='base_commission' value={product?.baseCommission} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='contributor_percentage' label='Porcentaje Colaborador:' className='col-md-3'>
                                        <Input id='contributor_percentage' value={product?.contributorPercentage} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Tarifa:' className='col-md-4'>
                                        <Input id='fee' value={product?.fee.name} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Lead:' className='col-md-4'>
                                    {product.leads ?  (
                                        <div key={product.productType.id} className="d-flex flex-column justify-content-center">
                                            {
                                                product.leads.map((lead: any) => {
                                                return (
                                                    <Badge color="info" isLight key={product.id + '-' +lead.id} className="mt-2">{lead.lead.name}</Badge>
                                            )
                                            })}
                                         </div>
                                    ) : (<>-</>) }
                                    </FormGroup>
                                    <FormGroup label='Tipo de Contrato:' className='col-md-4'>
                                    {product.operationTypes ?  (
                                        <div key={product.productType.id} className="d-flex flex-column justify-content-center">
                                            {
                                                product.operationTypes.map((operationType: any) => {
                                                return (
                                                    <Badge color="info" isLight key={product.id + '-' +operationType.id} className="mt-2">{operationType.operationType.name}</Badge>
                                            )
                                            })}
                                         </div>
                                    ) : (<>-</>) }
                                    </FormGroup>
                                    <FormGroup id='powerFrom' label='Potencia Desde (kw):' className='col-md-2'>
                                        <Input id='powerFrom' value={product?.powerFrom} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='powerTo' label='Potencia Hasta (kw):' className='col-md-2'>
                                        <Input id='powerTo' value={product?.powerTo} readOnly/>
                                    </FormGroup>
                                    <FormGroup label='Tipo de Cliente:' className='col-md-4 text-center'>
                                        <div className="d-flex justify-content-center mt-2">
                                            <FormGroup label='Empresa' className='col-md-6 me-3'>
                                                <br />
                                                {product?.businessEnabled ? <CheckCircleOutline className="text-success" width="40px" height="40px" /> : <HighlightOff className="text-danger" width="40px" height="40px" />}
                                            </FormGroup>
                                            <FormGroup label='Particular' className='col-md-6 ms-3'>
                                                <br />
                                                {product?.individualEnabled ? <CheckCircleOutline className="text-success" width="40px" height="40px" /> : <HighlightOff className="text-danger" width="40px" height="40px" />}
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                    <FormGroup id='consumptionFrom' label='Consumo Desde (kw):' className='col-md-2'>
                                        <Input id='consumptionFrom' value={product?.consumptionFrom} readOnly/>
                                    </FormGroup>
                                    <FormGroup id='consumptionTo' label='Consumo Hasta (kw):' className='col-md-2'>
                                        <Input id='consumptionTo' value={product?.consumptionTo} readOnly/>
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                        <ServicesProductCard selectedServices={product?.services} setSelectedServices={[]} idProduct={product?.id} show={true} business={product?.business.id} />
                    </Page>
                </Fragment>
            ) : <Spinner/>}
        </>
    )
}

export default ProductView;