import { Client } from "../../type/client-type"
import Badge from "../bootstrap/Badge"
import Card, { CardBody } from "../bootstrap/Card"

type ClientSmartCardProps = {
    client: Client,
    onClick?: (client: Client) => void
}

const ClientSmartCard: React.FC<ClientSmartCardProps> = ({client, onClick}) => {
    return (
        <Card shadow={'none'} className="cursor-pointer" borderColor={'primary'} isCompact borderSize={1} onClick={() => {
            if (onClick) {
                onClick(client);
            }
        }}>
            <CardBody className="">
                <h5 className="fw-bold">{client.name} ({client.nif})</h5>
                <p>{client.address}, {client.postalCode}, {client.locality}, {client.province}</p>
                <p>{client.phone1}{client.phone2 ? ", "+client.phone2 : ""}</p>
                <p><Badge isLight>{client.clientType}</Badge></p>
            </CardBody>
        </Card>
    )
}


export default ClientSmartCard