import { useContext } from "react";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import ClientListBySearch from "./client-list/ClientListBySearch";
import ClientsListAllWrapper from "./client-list/ClientListWrapper";

const ClientListWrapper = () => {
    const { userCan } = useContext(PrivilegeContext);
    const isListAllowed = userCan('list', 'clients');
  
    return (
      <>
          {isListAllowed ?  <ClientsListAllWrapper /> : <ClientListBySearch />}
      </>
    );
  };

export default ClientListWrapper;