import { FC, useCallback } from "react";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import { CustomTable } from "../../../../components/table/CustomTable";
import { OperationService } from "../../../../services/operations/operationService";
import Spinner from "../../../../components/bootstrap/Spinner";
import Button from "../../../../components/bootstrap/Button";
import moment from "moment";
import Icon from "../../../../components/icon/Icon";
import useFilters from "../../../../hooks/useFilters";
import useFetch from "../../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { workMenu } from "../../../../menu";

interface RemainingTasksProps {
  user: any;
}

const RemainingTasks: FC<RemainingTasksProps> = ({ user }) => {
  const {
    filters,
    updateFilters,
    resetFilters,
    updateFilterOrder,
    updatePage,
    updatePageSize,
  } = useFilters({ }, [], 50);

  const fetchAgentPendingTasks = useCallback(async () => {
    if (!user) return;
    const operationService = new OperationService();
    const response = (
      await operationService.getAgentPendingTasks(filters)
    ).getResponseData();
    return response;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchAgentPendingTasks);

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader className="mb-1">
        <CardLabel icon="AssignmentLate" iconColor="danger">
          <CardTitle tag="h4" className="h5">
            Tareas Pendientes
          </CardTitle>
        </CardLabel>
        <CardActions>
            <Button
              color="primary"
              icon="Add"
              onClick={() => {
                navigate(workMenu.work.subMenu.newWork.absolutePath)
              }}
            >
              Añadir trabajo
            </Button>
          </CardActions>
      </CardHeader>
      <CardBody>
        {
          data ? (
            <>
              <CustomTable
                data={data.pendingTasks}
                className="table table-hover p-3 fs-8 max-h-150"
                pagination={true}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: (data as any) ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                columns={[
                  {
                    name: "Estado",
                    keyValue: "currentStatus",
                    className: "text-center",
                    render: (item: any) => {
                      return (
                        <div className="d-flex justify-content-center fs-7">
                          <Button
                            isLink
                            isDisable={true}
                            className="text-nowrap fs-8 p-2"
                            style={{
                              backgroundColor:
                                item.currentActionStatus.color
                                  ? item.currentActionStatus.color
                                  : "whiteSmoke",
                              color: "black",
                            }}
                          >
                            <span
                              className="text-white fw-bold"
                              style={{ color: "#3e3e3e" }}
                            >
                              {" "}
                              {item.currentActionStatus !== null
                                ? item.currentActionStatus.name
                                : ""}{" "}
                            </span>
                            <div>
                              <small
                                className="text-white fw-bold"
                                style={{ color: "#3e3e3e" }}
                              >
                                {" "}
                                {item.currentActionStatusDate
                                  ? moment(
                                      item.currentActionStatusDate.date
                                    ).format("DD/MM/yyyy HH:mm")
                                  : undefined}{" "}
                              </small>
                            </div>
                          </Button>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Cliente",
                    keyValue: "clientName",
                    className: "text-center fs-7",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    cellClassName: "text-center",
                    render: (item: any) => {
                      return (
                        <div className="fs-7 text-center">
                          <a
                            className="tex-primary"
                            href={`/clients/${item.client.id}/show/1`}
                          >
                            {item.clientName ? item.clientName : "-"}
                          </a>
                          <div>
                            {item.client.favourite ? (
                              <Icon icon="Star" color="info" size={"2x"} />
                            ) : (
                              <Icon icon="Star" color="gray" size={"2x"} />
                            )}
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    name: "DNI",
                    keyValue: "clientDni",
                    className: "text-center fs-7",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    cellClassName: "text-center",
                  },
                  {
                    name: "Agent",
                    keyValue: "agentName",
                    className: "text-center fs-7",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    render: (item: any) => {
                      return (
                        <div className="fs-7 text-center">
                          {item.agent ? item.agent.name : "-"}
                        </div>
                      );
                    },
                  },
                ]}
              />
            </>
          ) : (
            <div className="text-center d-flex justify-content-center">
              <Spinner></Spinner>
            </div>
          )
        }
      </CardBody>
    </Card>
  );
};

export default RemainingTasks;
