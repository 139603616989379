import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useNavigate } from "react-router-dom";
import { CampaignService } from "../../../services/campaigns/campaignService";
import { CampaignApiResponse } from "../../../type/campaign-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import CampaignModal from "../campaign-create/CampaignModal";
import CampaignsFilters from "./campaign-options/CampaignsFilters";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const CampaignsList = () => {
  const { userCan } = useContext(PrivilegeContext)
  const navigate = useNavigate();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();
  
  useEffect(() => {
    checkIfUrlHasFilters();
  },[]);

  const [campaign, setCampaign] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);


  const fetchCampaigns = useCallback(async () => {
    const campaignService = new CampaignService();
    const response = await campaignService.getCampaigns(filters);
    return response.getResponseData() as CampaignApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refech] = useFetch(fetchCampaigns);


  const deleteLead = async (id: string) => {
    let response = (await new CampaignService().deleteCampaign(id)).getResponseData();
    if (response.success) {
      toast.success('Departamento eliminada correctamente');
      refech();
    } else {
      toast.error('Error al eliminar el departamento');
    }
  }


  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setCampaign(item);
    setModal(true);
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Departamentos</CardTitle>
            {userCan('create', 'products') ?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('create', 'campaigns') ? '' : 'd-none'}`}
                  isLight
                  onClick={() => {
                    openModal(null, false);
                  }}
                >
                  Añadir Departamento
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <CampaignsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {
            data ? (
              <CustomTable
                data={data?.campaigns ? data.campaigns : null}
                pagination={true}
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: data as CampaignApiResponse ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                className={"table table-hover"}
                columns={
                  [
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <a className="d-flex justify-content-center cursor-pointer" onClick={() => {navigate(`edit/${element.id}`)}}>
                            {element.name}
                          </a>
                        )
                      },
                    },
                    {
                      name: "Descripción",
                      keyValue: "description",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    
                    {
                      name: "Usuarios",
                      keyValue: "users",
                      className: "text-center",
                      render: (element: any) => {
                        return <div className="text-center">{element.users.length}</div>;
                      },
                    },
                    {
                      name: "Estados",
                      keyValue: "statuses",
                      className: "text-center",
                      render: (element: any) => {
                        return <div className="text-center">{element.statuses.length}</div>;
                      },
                    },
                    {
                      name: "Productos",
                      keyValue: "products",
                      className: "text-center",
                      render: (element: any) => {
                        return <div className="text-center">{element.products.length}</div>;
                      },
                    },
                    {
                      name: "Fecha de Creación",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return <div className="text-center">{moment(element.createdAt.date).format('DD/MM/YYYY HH:mm')}</div>;
                      },
                    },
                    {
                      name: "Organización",
                      keyValue: "company",
                      className: userCan('list', 'operations', true) ? "text-center" : "d-none",
                      isVisible: userCan('list', 'operations', true),
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.company.name}
                          </div>
                        );
                      },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                  [
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-info',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${userCan('edit', 'campaigns') ? '' : 'd-none'}`,
                      description: "Editar el Departamento",
                      callback: (item: any) => {
                        if (userCan('edit', 'campaigns')) navigate(`edit/${item.id}`);
                      },
                    },
      
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${userCan('delete', 'campaigns') ? '' : 'd-none'}`,
                      description: "Eliminar el Departamento",
                      callback: (item: any) => {
                        if (userCan('delete', 'campaigns')) {
                          handleConfirmationAlert({
                            title: "Eliminar Departamento",
                            text: "¿Está seguro que desea eliminar el Departamento? Todos los productos o usuarios que hereden o contengan este Departamento quedarán huérfanos.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteLead(item.id);
                            }
                          })
                        }
                      },
                    },
                  ]
                }
              />
            ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
          }
        </Card>
      </Page>
      {modal && (<CampaignModal isOpen={modal} setIsOpen={setModal} />)}
    </Fragment>
  );
};

export default CampaignsList;
