import { FC } from "react"
import { CustomTable } from "../table/CustomTable"
import { CheckCircleOutline, HighlightOff } from "../icon/material-icons";

interface SMSMatrixTableComponentProps {
    visibleColums: string[];
    visibleActions: string[];
    updateFilters: Function;
    filters: any;
    updatePage: Function;
    updatePageSize: Function;
    updateFilterOrder: any;
    classNameProps: string;
    data: any;
    error: any;
    refetch?: Function;
}

export const SMSMatrixTableComponent: FC<SMSMatrixTableComponentProps> = ({
    visibleColums,
    visibleActions,
    updateFilters,
    filters,
    updatePage,
    updatePageSize,
    updateFilterOrder,
    classNameProps,
    data,
    error,
    refetch,
}) => {
    return (
        <CustomTable
            data={data.matrix ? data.matrix : []}
            className={classNameProps}
            pagination={true}
            paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: data ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
            }}
            columns={[
                {
                    name: "ID",
                    keyValue: "id",
                    className: `${
                        visibleColums.includes("id") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("id"),
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center">
                                {element.id}
                            </div>
                        );
                    },
                },
                {
                    name: "CNAE",
                    keyValue: "cnae",
                    className: `${
                        visibleColums.includes("cnae") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("cnae"),
                },
                {
                    name: "Nombre",
                    keyValue: "name",
                    className: `${
                        visibleColums.includes("name") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("name"),
                },
                {
                    name: "Tipo",
                    keyValue: "type",
                    className: `${
                        visibleColums.includes("type") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("type"),
                },
                {
                    name: "Tarifa",
                    keyValue: "fee",
                    className: `${
                        visibleColums.includes("fee") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("fee"),
                },
                {
                    name: "Servicios",
                    keyValue: "services",
                    className: `${
                        visibleColums.includes("services") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("services"),
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center">
                                {element.services.map((service: any, index: number) => {
                                    return (
                                        <div key={index} className="d-flex justify-content-center me-1">
                                            <span className="badge bg-primary">{service}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    },
                },
                {
                    name: "Para particulares",
                    keyValue: "individualEnabled",
                    className: `${
                        visibleColums.includes("individualEnabled") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("individualEnabled"),
                    render: (element: any) => {
                        return (
                            <div className="fs-7 text-center">{element.individualEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                        );
                    },
                },
                {
                    name: "Para empresas",
                    keyValue: "businessEnabled",
                    className: `${
                        visibleColums.includes("businessEnabled") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("businessEnabled"),
                    render: (element: any) => {
                        return (
                            <div className="fs-7 text-center">{element.businessEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                        );
                    },
                },
                {
                    name: "Potencia desde",
                    keyValue: "powerFrom",
                    className: `${
                        visibleColums.includes("powerFrom") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("powerFrom"),
                },
                {
                    name: "Potencia hasta",
                    keyValue: "powerTo",
                    className: `${
                        visibleColums.includes("powerTo") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("powerTo"),
                },
                {
                    name: "Mensaje",
                    keyValue: "message",
                    className: `${
                        visibleColums.includes("message") ? "text-center" : "d-none"
                      }`,
                    isVisible: visibleColums.includes("message"),
                },
            ]}
        />
    )
}