import { Fragment } from "react";
import ClientList from "./ClientList";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";

const ClientsListAllWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <ClientList/>
            </FiltersProvider>
        </Fragment>
    );

}

export default ClientsListAllWrapper;