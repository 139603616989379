import { FC, Fragment, useEffect, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import { useSelector } from 'react-redux'
import { AuthState } from '../../../../redux/authSlice'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../components/bootstrap/forms/Select'
import { useCompanyList } from '../../../../hooks/useListData'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'

interface ICampaignsFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: any, hardReset: boolean) => void
  filters: any
}

const CampaignsFilters: FC<ICampaignsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false)

  const rol = useSelector((state: { auth: AuthState }) => {
    const userRoles = state.auth.user?.roles;
    return userRoles && (userRoles.includes('Superadministrador')) ? 'Superadministrador' : userRoles?.[0];
  });

  const companyList = useCompanyList();

  const handleTextChange = (search: string) => {
    updateFilters({ search_text: search });
  };

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>

      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Departamentos </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            <div className='col-12'>
              <FormGroup id='filter1' label='Nombre'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })}
                  value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter2' label='Descripción'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ description: e.target.value })}
                  value={filters.filter_filters.description} id='description' placeholder='Descripción...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className={rol === 'Superadministrador' ? 'col-12' : 'd-none'}>
              <FormGroup id='filter3' label='Compañia'>
                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })} ariaLabel='Default select example'
                  value={filters.filter_filters.company} id='company' placeholder='Compañia...' list={companyList}/>
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => resetFilters(50, true)}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    </Fragment>
  )
}

export default CampaignsFilters