import { FC, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { NewOperationType, OperationType } from "../../../type/operation-type";
import { OperationTypeService } from "../../../services/operations/operationTypeService";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { EditOperationType } from "../../../type/operation-type";
import Spinner from "../../../components/bootstrap/Spinner";

interface OperationTypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    operationType?: OperationType;
    onClose?: (message?: string) => void;
}

const OperationTypeCreateModal: FC<OperationTypeModalProps> = ({ isOpen, setIsOpen, mode, operationType, onClose }) => {

    const [loadign, setLoading] = useState(false);

    const createOperationType = async (values: NewOperationType) => {
        try {
            setLoading(true);
            let response = await (await (new OperationTypeService()).createOperationType(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                setIsOpen(false);
                onClose && onClose("Tipo de producto creado correctamente");
            } else {
                toast.error('Error al crear el tipo de producto');
                setLoading(false);
            }
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }

    }

    const updateOperationType = async (values: EditOperationType) => {
        try {
            setLoading(true);
            let response = await (await (new OperationTypeService()).editOperationType(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                setIsOpen(false);
                onClose && onClose("Tipo de producto actualizado correctamente");
            } else {
                toast.error('Error al actualizar el tipo de producto');
                setLoading(false);
            }
        } catch (error: any) {
            console.log(error);
            setLoading(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
            mode === 'create' ?
                {
                    name: '',
                    description: '',
                    company: ''
                }
                :
                {
                    name: operationType?.name ?? '',
                    description: operationType?.description ?? '',
                    company: operationType?.company.id ?? '',
                    operation_type: operationType?.id ?? ''
                }
        ,
        onSubmit: (values) => {
            if (mode === 'create') {
                createOperationType(values as NewOperationType);
            } else {
                updateOperationType(values as any);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Tipo de Contrato'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_operationType'>Nuevo Tipo de Contrato</ModalTitle>) : (<ModalTitle id='edit_operationType'>Editar Tipo de Contrato</ModalTitle>)}
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-12'>
                            <Input value={formik.values.name} required onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                    <div className='row g-4'>
                        <FormGroup id='description' label='Breve descripción' className='col-md-12'>
                            <Textarea value={formik.values.description == null ? '' : formik.values.description} onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                        {loadign  ? <Spinner className="text-center"/> : mode === 'create' ? 'Guardar Tipo' : 'Editar Tipo'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default OperationTypeCreateModal;