import { Fragment } from "react"
import { Outlet } from "react-router-dom"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import CompanyList from "./companies-list/CompanyList"

const OrganizationListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <CompanyList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default OrganizationListWrapper