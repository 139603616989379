import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { ClientsApiResponse } from "../../../type/client-type";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";
import StatusDropdown from "../../../components/StatusDropdown";
import ClientCommentModal from "../components/ClientEditCommentModal";
import Icon from "../../../components/icon/Icon";
import Input from "../../../components/bootstrap/forms/Input";
import Tooltips from "../../../components/bootstrap/Tooltips";

const ClientListBySearch = () => {
  const { userCan } = useContext(PrivilegeContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [client, setClient] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>(location.search.split("=")[1] || "");
  const [searchAPIValue, setSearchAPIValue] = useState<string>(location.search.split("=")[1] || "");

  useEffect(() => {
    if (searchAPIValue.trim() !== '') {
      const queryString = `searchValue=${encodeURIComponent(searchAPIValue)}`;
      navigate(`?${queryString}`, { replace: true });
    } else {
      navigate('', { replace: true });
    }
  }, [searchAPIValue]);

  const openModal = (id: string) => {
    setIsOpen(true);
    setMode("create");
    setClient(id);
  };

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    setIsOpen(false);
    setMode("");
  };

  const _toggleFavourite = async (id: string, favourite: boolean) => {
    try {
      let response = await (
        await new ClientService().toggleClientFavourite(id, !favourite)
      ).getResponseData();
      if (response.success) {
        toast.success("Cliente actualizado correctamente");
        refech();
      } else {
        toast.error("Error al actualizar el Cliente");
      }
    } catch (error: any) {
      toast.error("Error al actualizar el Cliente");
    }
  };

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const fetchClients = useCallback(async () => {
    const clientService = new ClientService();
    const response = await clientService.getClientByDniOrPhone(searchAPIValue);
    return response.getResponseData() as ClientsApiResponse;
  }, [searchAPIValue]);

  const [data, loading, error, refech] = useFetch(fetchClients);

  const deleteClient = async (id: string) => {
    let response = (
      await new ClientService().deleteClient(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Cliente eliminado correctamente");
      refech();
    } else {
      toast.error("Error al eliminar el Cliente");
    }
  };

  const getMostRecentDate = (comment: any) => {
    const createdAtDate = new Date(comment.createdAt.date);
    const updatedAtDate = comment.updatedAt
      ? new Date(comment.updatedAt.date)
      : null;

    return updatedAtDate && updatedAtDate > createdAtDate
      ? updatedAtDate
      : createdAtDate;
  };

  const toggleClientStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new ClientService().toggleClientStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      setSearchAPIValue("");
    }
    if (e.target.value.length > 7) {
      setSearchAPIValue(e.target.value);
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Clientes</CardTitle>
            {userCan("create", "clients") ? (
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${
                    userCan("create", "clients") ? "" : "d-none"
                  }`}
                  isLight
                  onClick={() => {
                    navigate("create");
                  }}
                >
                  Añadir Cliente
                </Button>
              </>
            ) : (
              <></>
            )}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <label
            className="border-0 bg-transparent cursor-pointer"
            htmlFor="searchInput"
          >
            <Icon icon="Search" size="2x" color="primary" />
          </label>
          <Input
            id="searchInput"
            type="search"
            className="w-auto"
            placeholder="Buscar..."
            autoComplete="off"
            onChange={onChangeSearch}
            value={searchValue}
          />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {data || data == null ? (
              <>
                <CustomTable
                  empty_message="Busca un cliente por nif o teléfono para ver los resultados"
                  data={data ? !Array.isArray(data) ? [data] : data : []}
                  className={"table table-hover fs-7"}
                  columns={[
                    {
                      name: "Nombre",
                      keyValue: "name",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Nif",
                      keyValue: "nif",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Email",
                      keyValue: "email",
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.email ? element.email : "-"}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Teléfono 1",
                      keyValue: "phone1",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Teléfono 2",
                      keyValue: "phone2",
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.phone2 ? element.phone2 : "-"}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Address",
                      keyValue: "address",
                      className: "text-center",
                      cellClassName: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.address ? element.address : "-"}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Localidad",
                      keyValue: "locality",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Provincia",
                      keyValue: "province",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Código Postal",
                      keyValue: "postalCode",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Iban",
                      keyValue: "iban",
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Comentarios",
                      keyValue: "comments",
                      className: "text-center",
                      render: (element: any) => {
                      
                        let mostRecentComment = null;
        
                        if (element.comments?.length > 0) {
                          mostRecentComment = element.comments.reduce((prevComment: any, currentComment: any) => {
                            const prevCommentDate = getMostRecentDate(prevComment);
                            const currentCommentDate = getMostRecentDate(currentComment);
                            return currentCommentDate > prevCommentDate ? currentComment : prevComment;
                          });
                        }
        
                        return (
                          <div className="d-flex justify-content-center">
                            {element.comments?.length > 0 ? (
                              <Tooltips title={mostRecentComment?.comment}>
                                <Icon icon="Message" color="primary" size={"2x"} />
                              </Tooltips>
                            ) : (
                                <Icon icon="Message" color="gray" size={"2x"} />
                            )}
                          </div>
                        );
                      }
                    },
                    {
                      name: "Favorito",
                      keyValue: "favourite",
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center" onClick={() => _toggleFavourite(element.id, element.favourite)}>
                            <Tooltips title={element.favourite ? "Quitar de favoritos" : "Añadir a favoritos"}>
                            {element.favourite ? (
                              <Icon icon="Star" color="info" size={"2x"} />
                            ) : (
                              <Icon icon="Star" color="gray" size={"2x"} />
                            )}
                            </Tooltips>
                          </div>
                        );
                      }
                    },
                    {
                      name: "Estado",
                      keyValue: "active",
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={"text-center"}>
                            <StatusDropdown
                              disabled={changingStatus.includes(element.id)}
                              itemId={element.id}
                              status={element.active}
                              change={toggleClientStatus}
                              key={element.id}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      name: "Organización",
                      keyValue: "company",
                      className: userCan("list", "operations", true)
                        ? "text-center"
                        : "d-none",
                      isVisible: userCan("list", "operations", true),
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.company.name}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Acciones",
                      className: "min-w-100px text-end",
                      isActionCell: true,
                    },
                  ]}
                  actions={[
                    {
                      title: "Ver",
                      buttonType: "icon",
                      iconColor: "text-primary",
                      iconPath: "/media/icons/duotune/general/gen021.svg",
                      additionalClasses: `text-primary ${
                        userCan("show", "clients") ? "" : "d-none"
                      }`,
                      description: "Ver el Cliente",
                      callback: (item: any) => {
                        if (userCan("show", "clients")) navigate(`${item.id}/show`);
                      },
                    },
                    {
                      title: "Editar",
                      buttonType: "icon",
                      iconColor: "text-info",
                      iconPath: "/media/icons/duotune/general/gen055.svg",
                      additionalClasses: `text-primary ${
                        userCan("edit", "clients") ? "" : "d-none"
                      }`,
                      description: "Editar el Cliente",
                      callback: (item: any) => {
                        if (userCan("edit", "clients")) navigate(`edit/${item.id}`);
                      },
                    },
                    {
                      title: "Eliminar",
                      buttonType: "icon",
                      iconColor: "text-danger",
                      iconPath: "/media/icons/duotune/general/gen027.svg",
                      additionalClasses: `text-danger ${
                        userCan("delete", "clients") ? "" : "d-none"
                      }`,
                      description: "Eliminar el Departamento",
                      callback: (item: any) => {
                        if (userCan("delete", "clients")) {
                          handleConfirmationAlert({
                            title: "Eliminar Cliente",
                            text: "¿Está seguro que desea eliminar el cliente? Todos los contratos que hereden o contengan este Cliente quedarán huérfanos.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteClient(item.id);
                            },
                          });
                        }
                      },
                    },
                    {
                      title: "Añadir comentario",
                      buttonType: "icon",
                      iconColor: "text-secondary",
                      iconPath: "/media/icons/duotune/general/gen063.svg",
                      additionalClasses: `text-danger ${
                        userCan("edit", "clients") ? "" : "d-none"
                      }`,
                      description: "Añadir comentario",
                      callback: (item: any) => {
                        if (userCan("edit", "clients")) {
                          openModal(item.id);
                        }
                      },
                    },
                  ]}
                />
                <ClientCommentModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onClose={_onClose}
                  mode={mode}
                  client={client}
                />
              </>
            ) : (
              <div className="text-center d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </Card>
      </Page>
    </Fragment>
  );
};

export default ClientListBySearch;
