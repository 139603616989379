import React, { ChangeEvent, FC, Fragment, useState } from "react"
import Icon from "../../../../../components/icon/Icon"
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../../../components/bootstrap/OffCanvas"
import Button from "../../../../../components/bootstrap/Button"
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup"
import Select from "../../../../../components/bootstrap/forms/Select"
import Input from "../../../../../components/bootstrap/forms/Input"

interface IFeesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number) => void
    filters: any
}

const FeesFilters: FC<IFeesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false)
    const [searchInput, setSearchInput] = useState<string>('');

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
        setTimeout(() => {
            updateFilters({ searchText: e.target.value });
        }, 500);
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <Input onChange={handleTextChange} value={searchInput} id='searchInput' type='search' className='w-auto' placeholder='Buscar...' autoComplete='off' />

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Tarifas </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado de cuenta'>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter2' label='Nombre'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter3' label='Descripción'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ description: e.target.value })} value={filters.filter_filters.description} id='description' placeholder='Descripción...' autoComplete='off' />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Button
                            className='mt-4'
                            color="storybook"
                            isLight
                            onClick={() => resetFilters(50)}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default FeesFilters