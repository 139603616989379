import { Fragment } from "react"
import { Outlet } from "react-router-dom"

const ActionStatusWrapper = () => {

    return (
        <Fragment>
           <Outlet/>
        </Fragment>
    )
}

export default ActionStatusWrapper