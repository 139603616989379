import { Fragment, useEffect, useState } from "react"
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import Button from "../../components/bootstrap/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Page from "../../layout/Page/Page";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { showErrors, verifyClass } from "../../utils/forms/VerifyForms";
import { ValidateSearchField } from "./components/ValidateSearchField";
import { ClientService } from "../../services/clients/clientService";
import useHandleErrors from "../../hooks/useHandleErrors";
import Spinner from "../../components/bootstrap/Spinner";
import ClientSmartCard from "../../components/cards/ClientSmallCard";
import { Client } from "../../type/client-type";



type NewWorkProps = {

}

// Add custom method for check if search is a valid DNI or a valid phone number
yup.addMethod(yup.string, 'isValidSearch', ValidateSearchField);

const NewWorkSearchSchema = yup.object({
    // @ts-ignore
    search: yup.string().required("El campo es requerido").isValidSearch("Se ha detectado un DNI, CIF o teléfono inválido"),
});

/**
 * Component to redirect user to the right client page for admin works with the client.
 */
const NewWork: React.FC<NewWorkProps> = () => {

    // Services
    const clientService = new ClientService();

    // State
    const [clients, setClients] = useState<any[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    // HOOKS
    const navigate = useNavigate();
    const [routerParams] = useSearchParams();
    const { handleErrors } = useHandleErrors();

    const formik = useFormik({
        validationSchema: NewWorkSearchSchema,
        initialValues: {
            search: '',
        },
        onSubmit: async (values) => {
            _searchClients(values.search);
        }
    });


    // Actions
    const _handleSearch = () => {
        formik.handleSubmit();
    }

    const _searchClients = async (search: string) => {

        setIsLoading(true);

        let clientsData = (await clientService.getClientByDniOrPhone(search)).getResponseData();

        setIsLoading(false);

        if (clientsData.success) {

            setClients(clientsData.data)

        } else {
            handleErrors(clientsData);
        }
    }

    const _handleClickClient = (client: Client) => {
        navigate(`/clients/${client.id}/show`);
    }

    /**
     * Function that control all form actions on start, this action is executed only one time when load the page. 
     * 
     * This function retrieve the params from url and try to fill the form and search the client
     */
    const _handleStart = () => {

        // Get query parameters form url
        const telephone = routerParams.get('telephone');
        const dni = routerParams.get('dni');

        if (dni) {
            formik.setFieldValue('search', dni);
            setTimeout(() => {
                formik.handleSubmit();
                // If not clients found, try to search by telephone
                setTimeout(() => {
                    if (clients && clients.length === 0 && telephone) {
                        formik.setFieldValue('search', telephone);
                        formik.handleSubmit();
                    }
                }, 1000)
            }, 1000)
            return;
        }

        if (telephone) {
            formik.setFieldValue('search', telephone);
            setTimeout(() => {
                formik.handleSubmit();
            }, 1000)
            return;
        }

    }

    const _handleCreateNewClient = () => {
        navigate('/clients/create?redirectToView=true');
    }

    // ON START
    useEffect(() => {

        _handleStart()

    }, []);

    // ON CLIENTS CHANGE
    useEffect(() => {

        if (clients && clients.length === 1) {
            _handleClickClient(clients[0]);
        }

    }, [clients])


    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <Button
                            color="primary"
                            isLink
                            icon="ArrowBack"
                            onClick={() => navigate(-1)}
                        />
                        <SubheaderSeparator />
                        <CardTitle>Buscar cliente</CardTitle>
                    </Fragment>
                </SubHeaderLeft>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={false} className="col-lg-6 col-md-12 col-sm-12 offset-lg-3">
                    <CardHeader>
                        <CardTitle tag={'h4'}>
                            Buscar cliente
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <FormGroup label="" size={'lg'} className="col-12 mb-3">
                                <Input name="search" onChange={(ev) => {
                                    formik.handleChange(ev);
                                    setClients(null);
                                }} onBlur={formik.handleBlur} value={formik.values.search} placeholder="DNI/CIF/Teléfono/CUPS" size={'lg'} className={verifyClass(formik, 'search')} />
                                {showErrors(formik, 'search')}
                            </FormGroup>
                            <div className="col-12">
                                {!clients && (<Button color="primary" icon={isLoading ? undefined : 'search'} className="w-100" isDisable={isLoading} onClick={_handleSearch}>
                                    {isLoading ? <Spinner /> : 'Buscar'}
                                </Button>)}
                            </div>
                        </div>
                        {clients && (
                            <>
                                <hr className="mt-4 mb-4" />
                                <div className="row">
                                    {clients.length > 0 && (
                                        <>
                                            {clients.map((client) => (
                                                <div key={client.id} className="col-md-12">
                                                    <ClientSmartCard client={client} onClick={_handleClickClient} />
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {clients.length == 0 && (
                                        <div className="col-md-12">
                                            <h4 className="text-muted text-center">No se ha encontrado el cliente en la base de datos</h4>
                                            <Button color="primary" isLight icon="add" className="w-100 mt-4" onClick={_handleCreateNewClient}>Crear cliente nuevo</Button>
                                        </div>

                                    )}
                                </div>
                            </>
                        )}


                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}


export default NewWork