import React, { useContext, useEffect } from "react";
import Brand from "../../../layout/Brand/Brand";
import Navigation, {
  NavigationLine,
} from "../../../layout/Navigation/Navigation";
import User from "../../../layout/User/User";
import {
  superAdminMenu,
  dashboardPagesMenu,
  adminMenu,
  modulesMenu,
} from "../../../menu";
import ThemeContext from "../../../contexts/themeContext";
import Aside, {
  AsideBody,
  AsideFoot,
  AsideHead,
} from "../../../layout/Aside/Aside";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

/**
 * Filter menu based on user permissions
 */
const filterMenu = (menu: any, userCan: Function) => {
  return Object.keys(menu).reduce((obj: any, key: string) => {
    const menuItem = menu[key];
    let clonedMenuItem = { ...menuItem };

    if (menuItem.permissions_required !== undefined) {
      const { action , actions, entity } = menuItem.permissions_required;
      if (actions !== undefined) {
        let can = false;
        actions.forEach((action: string) => {
          if (userCan(action, entity)) {
            can = true;
          }
        });
        if (!can) {
          return obj;
        }
      } else {
        if (!userCan(action, entity)) {
          return obj;
        }
      }
    }

    if (menuItem.subMenu !== undefined && menuItem.subMenu !== null) {
      const filteredSubMenu = filterMenu(menuItem.subMenu, userCan);
      if (Object.keys(filteredSubMenu).length === 0) {
        // Excluir el elemento principal si todos los elementos del submenú están excluidos

        return obj;
      }
      clonedMenuItem.subMenu = filteredSubMenu;
    }

    obj[key] = clonedMenuItem;
    return obj;
  }, {});
};

const DefaultAside = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const { userCan, permissions } = useContext(PrivilegeContext);

  const [localAdminMenu, setLocalAdminMenu] = React.useState<any>({});
  const [localDashboardPagesMenu, setLocalDashboardPagesMenu] = React.useState<any>({});
  const [localModulesMenu, setLocalModulesMenu] = React.useState<any>({});

  const updateMenu = (originalMenu : any, setMenuFunction : any) => {
    const filteredMenu = filterMenu(originalMenu, userCan);
    setMenuFunction(filteredMenu);
  };

  useEffect(() => {
    updateMenu(adminMenu, setLocalAdminMenu);
    updateMenu(dashboardPagesMenu, setLocalDashboardPagesMenu);
    updateMenu(modulesMenu, setLocalModulesMenu);
  }, [permissions]);

  return (
    <Aside>
      <AsideHead>
        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
      </AsideHead>
      <AsideBody>
        <Navigation menu={localDashboardPagesMenu} id="dashboard-menu" />
        <NavigationLine />
        <Navigation menu={localModulesMenu} id="modules-menu" />
        <NavigationLine />

        <Navigation menu={localAdminMenu} id="administration-menu" />
        <NavigationLine />

        {userCan("list", "companies", true) ? (
          <>
            <Navigation menu={superAdminMenu} id="superAdmin-menu" />
            <NavigationLine />
          </>
        ) : (
          <></>
        )}
      </AsideBody>
      <AsideFoot>
        <User />
      </AsideFoot>
    </Aside>
  );
};

export default DefaultAside;
