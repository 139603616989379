import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, {
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import StatusDropdown from "../../../components/StatusDropdown";
import { OperationTypeService } from "../../../services/operations/operationTypeService";
import OperationTypeCreateModal from "../operations-create/OperationTypeCreateModal";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { OperationTypeApiResponse } from "../../../type/operation-type";
import moment from "moment";
import { toast } from "react-toastify";
import OperationsTypeFilters from "./operation-options/OperationsTypeFilters";
import { CSVLink } from "react-csv";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const OperationTypeList = () => {
  const { userCan } = useContext(PrivilegeContext);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [operationTypeToEdit, setOperationTypeToEdit] = useState();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, [])

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const deleteOperationType = async (id: string) => {
    try {
      const response = await (
        await new OperationTypeService().deleteOperationType(id)
      ).getResponseData();
      if (response.success) {
        toast.success("Contrato eliminada correctamente");
        updateFilters({ ...filters });
      } else {
        toast.error("Error al eliminar el contrato");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Error al eliminar el contrato");
    }
  };

  const fetchProductTypes = useCallback(async () => {
    const operationTypeService = new OperationTypeService();
    const response = await operationTypeService.getOperationTypes(filters);
    return response.getResponseData() as any;
  }, [filters]);

  const _onCloseModal = (message?: string) => {
    updateFilters({ ...filters });
    toast.success(message ? message : "Accion Realizada correctamente");
  };

  const toggleOperationTypeStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new OperationTypeService().toggleOperationTypeStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const [data, loading, error, refetch] = useFetch(fetchProductTypes);

  function convertList(input: any[]): any[] {
    try {
      return input.map((item) => ({
        Número_id: item.idNumber ?? "",
        Nombre: item.name ?? "",
        Descripción: item.description ?? "",
        Activo: item.active ?? "",
        Fecha_Creación: item?.createdAt?.date ?? "",
        Fecha_Actualización: item?.updatedAt?.date ?? "",
        Nº_Operaciones: item.operations?.length ?? "",
        Nº_Productos: item.products?.length ?? "",
        Organización: item.company.name ?? "",
      }));
    } catch (error) {
      return [];
    }
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardLabel icon="Widgets" iconColor="info">
              <CardTitle>Listado de Tipos de Contratos</CardTitle>
            </CardLabel>
            <SubheaderSeparator />
            <Button
              color="light"
              icon="AddCircle"
              isLight
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Añadir Tipo de Contrato
            </Button>
          </Fragment>
          {data ? (
            <>
              <SubheaderSeparator />
              <CSVLink
                data={convertList(data.operationTypes)}
                filename="OperationTypes"
              >
                <Button color="light" icon="FileDownload" isLight>
                  Exportar
                </Button>
              </CSVLink>
            </>
          ) : (
            <></>
          )}
        </SubHeaderLeft>
        <SubHeaderRight>
          <OperationsTypeFilters
            updateFilters={updateFilters}
            resetFilters={resetFilters}
            filters={filters}
          />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={false}>
          {data ? (
            <CustomTable
              data={data.operationTypes ? data.operationTypes : []}
              className={"table table-hover p-3"}
              defaultLimit={filters.limit || 50}
              defaultOrder={filters.filter_order || undefined}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: (data as OperationTypeApiResponse)
                  ? data.lastPage
                  : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
              }}
              columns={[
                {
                  name: "Nombre",
                  keyValue: "name",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (item: any) => {
                    return <div className="text-center">{item.name}</div>;
                  },
                },
                {
                  name: "Descripción",
                  keyValue: "description",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (item: any) => {
                    return (
                      <div className="text-center">
                        {item.description == null
                          ? "Sin descripción"
                          : item.description}
                      </div>
                    );
                  },
                },
                {
                  name: "Organización",
                  keyValue: "company",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: userCan("manage_types", "operations", true)
                    ? "text-center"
                    : "d-none",
                  isVisible: userCan("manage_types", "operations", true),
                  render: (item: any) => {
                    return (
                      <div
                        key={item.company.id}
                        className="d-flex justify-content-center"
                      >
                        {item.company.name}
                      </div>
                    );
                  },
                },
                {
                  name: "Creacion",
                  keyValue: "createdAt",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (item: any) => {
                    return (
                      <div className="text-center">
                        {moment(item.createdAt.date).format("DD/MM/YYYY H:mm")}
                      </div>
                    );
                  },
                },
                {
                  name: "Ult. Actualizacion",
                  keyValue: "updatedAt",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (item: any) => {
                    return (
                      <div className="text-center">
                        {item.updatedAt
                          ? moment(item.updatedAt.date).format("DD/MM/YYYY")
                          : "Sin actualizar"}
                      </div>
                    );
                  },
                },
                {
                  name: "Estado",
                  keyValue: "active",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <StatusDropdown
                          disabled={changingStatus.includes(element.id)}
                          itemId={element.id}
                          status={element.active}
                          change={toggleOperationTypeStatus}
                          key={element.id}
                        />
                      </div>
                    );
                  },
                },
                {
                  name: "Acciones",
                  className: "min-w-100px text-end",
                  isActionCell: true,
                },
              ]}
              actions={[
                {
                  title: "Editar",
                  buttonType: "icon",
                  iconColor: "text-info",
                  iconPath: "/media/icons/duotune/general/gen055.svg",
                  additionalClasses: "text-primary text-center",
                  description: "Editar Tipo de Contrato",
                  callback: (item: any) => {
                    setOperationTypeToEdit(item);
                    setOpenEditModal(true);
                  },
                },
                {
                  title: "Eliminar",
                  buttonType: "icon",
                  iconColor: "text-danger",
                  iconPath: "/media/icons/duotune/general/gen027.svg",
                  additionalClasses: "text-danger",
                  description: "Eliminar Contrato",
                  callback: (item: any) => {
                    handleConfirmationAlert({
                      title: "Eliminar Contrato",
                      text: "¿Está seguro que desea eliminar el contrato?",
                      icon: "warning",
                      onConfirm: () => {
                        deleteOperationType(item.id);
                      },
                    });
                  },
                },
              ]}
            />
          ) : (
            <div className="text-center d-flex justify-content-center">
              <Spinner />
            </div>
          )}
        </Card>
      </Page>
      {openCreateModal && (
        <OperationTypeCreateModal
          isOpen={openCreateModal}
          setIsOpen={setOpenCreateModal}
          onClose={_onCloseModal}
          mode={"create"}
        />
      )}
      {openEditModal && (
        <OperationTypeCreateModal
          isOpen={openEditModal}
          setIsOpen={setOpenEditModal}
          onClose={_onCloseModal}
          mode={"edit"}
          operationType={operationTypeToEdit}
        />
      )}
    </Fragment>
  );
};

export default OperationTypeList;
