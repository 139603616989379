import { FC, Fragment, useEffect, useState } from "react";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTabItem,
  CardTitle,
} from "../../../components/bootstrap/Card";
import { DocumentTypeService } from "../../../services/documents/documentTypeService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ActiveTab, setActiveTab } from "../../../redux/activeTabSlice";
import TabDocumentItem from "./TabDocumentItem";

interface UserDocumentsProps {
  id: string;
}

const ACTIVE_TAB_SECTION = "user_document_view";

const UserDocuments: FC<UserDocumentsProps> = ({ id }) => {
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);

  const activeTab = useSelector((state: RootState) =>
    state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION)
  );

  const dispatch = useDispatch();

  const getDocumentTypes = async () => {
    let response = await (
      await new DocumentTypeService().getDocumentTypes({
        filter_filters: { active: 1 , entityType: "Usuario" },
        all: true,
      })
    ).getResponseData();
    setDocumentTypes(response.data.documentTypes);
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardLabel icon="InsertDriveFile">
            <CardTitle>Documentos</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Card
            hasTab
            // @ts-ignore
            defaultActiveTab={activeTab?.activeTab || 0}
            onActiveTab={(tab: number) => {
              dispatch(
                setActiveTab({ section: ACTIVE_TAB_SECTION, activeTab: tab })
              );
            }}
          >
            {documentTypes && documentTypes.length !== 0 ? (
              documentTypes.map((documentType: any, index: number) => (
                <CardTabItem id={documentType.name} title={documentType.name}>
                <TabDocumentItem
                  id={id}
                  documentType={documentType}
                  key={index}
               />
                </CardTabItem>
              ))
            ) : (
              <CardTabItem id="no_documents" title="No hay documentos">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="fw-bold">No hay ningun tipo de documento orientado a Usuarios</span>
                </div>
              </CardTabItem>
            )}
          </Card>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserDocuments;
