import { ChangeEvent, FC, useState } from "react";
import { Operation, OperationHasProducts } from "../../../type/operation-type";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Button from "../../../components/bootstrap/Button";
import { CustomTable } from "../../../components/table/CustomTable";
import {
  CheckCircleOutline,
  HighlightOff,
} from "../../../components/icon/material-icons";
import OperationAdicionalDataEditModal from "../operation-edit/OperationAdicionalDataEditModal";
import { toast } from "react-toastify";
import WizardProduct from "../operations-create/Wizard-Products";
import Icon from "../../../components/icon/Icon";
import { Product } from "../../../type/product-type";
import OperationServiceEditModal from "../operation-edit/OperationServicesEditModal";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

type OperationProductsComponentProps = {
  products: OperationHasProducts[];
  operation: Operation;
  handleEditSelected: (selectedItem: OperationHasProducts[]) => unknown;
  handleEditServiceSelected: (selectedItem: any[]) => unknown;
  handleDeleteSelected: (selectedItem: Product) => unknown;
  handleAddProduct: (product: OperationHasProducts) => void;
};

const OperationProductsComponent: FC<OperationProductsComponentProps> = ({
  operation,
  products,
  handleDeleteSelected,
  handleEditSelected,
  handleEditServiceSelected,
  handleAddProduct,
}) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false);

  const _handleSelectAll = (checked: boolean) => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItem(products);
    } else {
      setSelectedItem([]);
    }
  };

  const { userCan } = usePrivilege();

  const _handleCheckboxChange = (
    id: string,
    event: ChangeEvent<HTMLInputElement>,
    element: any
  ) => {
    const { checked } = event.target;
    let item;
    if (checked) {
      item = element;
    } else {
      item = null;
    }
    setSelectedItem(item);
  };

  const _handleAddProduct = () => {
    setOpenCreateModal(true);
  };

  const _handleDeleteSelected = () => {
    handleDeleteSelected(selectedItem);
  };

  const _handleEditSelected = () => {
    if (selectedItem == null) {
      toast.warning("Debes seleccionar un producto para editar");
      return;
    }
    setOpenEditModal(true);
  };

  const _handleEditSelectedServices = () => {
    if (selectedItem == null) {
      toast.warning("Debes seleccionar un producto para editar");
      return;
    }
    setOpenEditServiceModal(true);
  };

  const _onCloseEditModal = async (
    updatedProduct: OperationHasProducts,
    message?: string
  ) => {
    setOpenEditModal(false);
    handleEditSelected(selectedItem);
  };

  const _onCloseServiceEditModal = async (
    updatedServices: any,
    message?: string
  ) => {
    setOpenEditServiceModal(false);
    setSelectedItem(null);
    handleEditServiceSelected(updatedServices);
  };

  const _onCloseCreateModal = async (message?: string, product?: any) => {
    let createdProduct = product;
    setOpenCreateModal(false);
    handleAddProduct(product);
  };

  return (
    <>
      <Card>
        <CardHeader borderSize={1}>
          <CardLabel icon="Inventory2" iconColor="info">
            <CardTitle>Productos</CardTitle>
          </CardLabel>
          <CardActions>
            <Tooltips
              title={`${
                products.length > 0 ? "Cambiar producto" : "Añadir producto"
              }`}
            >
              <Button
                onClick={_handleAddProduct}
                color="primary"
                isLight
                icon={`${products.length > 0 ? "FindReplace" : "Add"}`}
              ></Button>
            </Tooltips>
            <Tooltips title="Editar producto">
              <Button
                onClick={_handleEditSelected}
                color="info"
                isLight
                icon="Edit"
              ></Button>
            </Tooltips>
            <Tooltips title="Editar Servicios">
              <Button
                onClick={_handleEditSelectedServices}
                color="secondary"
                isLight
                icon="AddToPhotos"
              ></Button>
            </Tooltips>
            <Tooltips title="Eliminar producto seleccionado">
              <Button
                onClick={_handleDeleteSelected}
                color="danger"
                icon="DeleteForever"
              ></Button>
            </Tooltips>
          </CardActions>
        </CardHeader>
        <CardBody>
          <CustomTable
            data={products ? products : null}
            pagination={true}
            className={"table table-hover fs-8"}
            selectableItems={true}
            onSelectAllItems={_handleSelectAll}
            onChangeCheckedItems={_handleCheckboxChange}
            columns={[
              {
                name: "Nombre",
                keyValue: "name",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center fw-semibold">
                      {element.product.description && (
                        <Tooltips title={element.product.description}>
                          <Icon
                            icon="Info"
                            color="info"
                            size={"lg"}
                            className="me-2"
                          />
                        </Tooltips>
                      )}
                      {element.product.name}
                    </div>
                  );
                },
              },
              {
                name: "Servicios",
                keyValue: "services",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="">
                      {element.services.map((service: any) => {
                        return (
                          <div className="row">
                            <div className="text-center">
                              {service.warning && (
                                <Tooltips title={service.warning}>
                                  <Icon
                                    icon="Info"
                                    color="info"
                                    size={"lg"}
                                    className="me-2"
                                  />
                                </Tooltips>
                              )}
                              {service.service.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                },
              },
              {
                name: "Tipo",
                keyValue: "name",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center fw-semibold">
                      {element.product.productType.name}
                    </div>
                  );
                },
              },
              {
                name: "Cups",
                keyValue: "cups",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.cups ?? "No tiene"}
                    </div>
                  );
                },
              },
              {
                name: "Puntos",
                keyValue: "points",
                className: "text-center",
                cellClassName: "text-center",
                isVisible: userCan("show_points", "operations"),
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.points ?? 0}
                    </div>
                  );
                },
              },
              {
                name: "Comisión Total",
                keyValue: "commission",
                className: "text-center",
                cellClassName: "text-center",
                isVisible: userCan("show_commissions", "operations"),
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.commission} €
                    </div>
                  );
                },
              },
              {
                name: "Consumo anual:",
                keyValue: "consumption",
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.consumption ?? 0} kW
                    </div>
                  );
                },
              },
              {
                name: "Potencia (Periodos):",
                keyValue: "powers",
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                        <ul className="list-unstyled">
                          {element?.powerP1 != null && (
                            <li>P1: {element?.powerP1} kW</li>
                          )}
                          {element?.powerP2 != null && (
                            <li>P2: {element?.powerP2} kW</li>
                          )}
                          {element?.powerP3 != null && (
                            <li>P3: {element?.powerP3} kW</li>
                          )}
                          {element?.powerP4 != null && (
                            <li>P4: {element?.powerP4} kW</li>
                          )}
                          {element?.powerP5 != null && (
                            <li>P5: {element?.powerP5} kW</li>
                          )}
                          {element?.powerP6 != null && (
                            <li>P6: {element?.powerP6} kW</li>
                          )}
                        </ul>
                    </div>
                  );
                },
              },
              {
                name: "Negocio",
                keyValue: "businessEnabled",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="fs-7 text-center">
                      {element.product.businessEnabled ? (
                        <CheckCircleOutline
                          className="text-success"
                          width="30px"
                          height="30px"
                        />
                      ) : (
                        <HighlightOff
                          className="text-danger"
                          width="30px"
                          height="30px"
                        />
                      )}
                    </div>
                  );
                },
              },
              {
                name: "Individual",
                keyValue: "individualEnabled",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="fs-7 text-center">
                      {element.product.individualEnabled ? (
                        <CheckCircleOutline
                          className="text-success"
                          width="30px"
                          height="30px"
                        />
                      ) : (
                        <HighlightOff
                          className="text-danger"
                          width="30px"
                          height="30px"
                        />
                      )}
                    </div>
                  );
                },
              },
              {
                name: "Dirección",
                keyValue: "address",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.address}
                    </div>
                  );
                },
              },
              {
                name: "Ciudad",
                keyValue: "town",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.town}
                    </div>
                  );
                },
              },
              {
                name: "Provincia",
                keyValue: "province",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.province}
                    </div>
                  );
                },
              },
              {
                name: "Código Postal",
                keyValue: "postalcode",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.postalcode}
                    </div>
                  );
                },
              },
            ]}
          />
        </CardBody>
      </Card>
      {openEditModal && (
        <OperationAdicionalDataEditModal
          isOpen={openEditModal}
          setIsOpen={setOpenEditModal}
          onClose={_onCloseEditModal}
          productSelected={selectedItem}
        />
      )}
      {openEditServiceModal && (
        <OperationServiceEditModal
          isOpen={openEditServiceModal}
          setIsOpen={setOpenEditServiceModal}
          onClose={_onCloseServiceEditModal}
          productSelected={selectedItem}
        />
      )}
      {openCreateModal && (
        <WizardProduct
          isOpen={openCreateModal}
          operationData={operation}
          setIsOpen={setOpenCreateModal}
          onClose={_onCloseCreateModal}
          mode={"create"}
        />
      )}
    </>
  );
};

export default OperationProductsComponent;
