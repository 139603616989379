import { useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import Button from "../../bootstrap/Button";
import FormGroup from "../../bootstrap/forms/FormGroup";
import { ReactSelectStyles } from '../../../utils/styles';
import Input from "../../bootstrap/forms/Input";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Select from "../../bootstrap/forms/Select";

type CampaignPostalCodeSelectorModalProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onClose: Function;
    defaultSelected?: string | null;
    isMulti?: boolean;
    discardSelected?: boolean;
    isRequired?: boolean;
}

let postalCodeSelectorSchema = Yup.object().shape({
    postalCode: Yup.string().required('El código postal es requerido').matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos'),
    restrictionType: Yup.string().required('El tipo de restricción es requerido')
});

let options: any = [
    { value: 'include', label: 'Permitido' },
    { value: 'exclude', label: 'No Permitido' }
];

const CampaignPostalCodeSelectorModal: React.FC<CampaignPostalCodeSelectorModalProps> = ({ isOpen, setIsOpen, onClose, defaultSelected, isMulti, discardSelected, isRequired}) => {

    const _notifyChange = (postalCode: string, restrictionType: string) => {
        setIsOpen(false);
        onClose(postalCode, restrictionType);
    }

    const formik = useFormik({
        initialValues: {
            postalCode: '',
            restrictionType: ''
        },
        validationSchema: postalCodeSelectorSchema,
        onSubmit: values => {
            _notifyChange( values.postalCode, values.restrictionType );
        },
    });

    return (
        <>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} onClose={_notifyChange} size={'lg'}>
                <ModalHeader setIsOpen={setIsOpen}>
                    <ModalTitle id="modal-allowed-statuses">Añadir Restricción de Código Postal</ModalTitle>
                </ModalHeader>
                <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <FormGroup label='Código Postal' requiredInputLabel={isRequired} color={'primary'} className="col-6">
                        <Input id='postalCode' required onChange={formik.handleChange} value={formik.values.postalCode || ''}
                            onBlur={formik.handleBlur} />
                        { formik.errors?.postalCode && formik.touched?.postalCode ? <div className="invalid-feedback d-block">{formik.errors?.postalCode}</div> : <></> }
                    </FormGroup>
                    <FormGroup label='Tipo de Restricción' requiredInputLabel={isRequired} color={'primary'} className="col-6">
                        <Select
                            onChange={(e: any) => { formik.setFieldValue('restrictionType', e.target.value) } }
                            value={formik.values.restrictionType}
                            id='active-select' ariaLabel='Default select example' placeholder='Tipo de restricción...'
                            list={options}
                        />
                        { formik.errors?.restrictionType && formik.touched?.restrictionType ? <div className="invalid-feedback d-block">{formik.errors?.restrictionType}</div> : <></> }
                    </FormGroup>
                </div>
                </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={formik.submitForm}>Guardar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};


export default CampaignPostalCodeSelectorModal;