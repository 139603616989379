import React, { SVGProps } from 'react';

const TurnRight = (props: SVGProps<SVGSVGElement>) => {

	return (
        <svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
            <path d="M24 24H0V0h24v24z" fill="none" opacity={0.87} />
            <path d="M19 9l-6-6-1.42 1.42L15.17 8H4v12h2V10h9.17l-3.59 3.59L13 15l6-6z" />
        </svg>
	);
};

export default TurnRight;
