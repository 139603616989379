import { FC, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import OperationsCommentHistory from "./OperationsCommentHistory";
import { toast } from "react-toastify";
import OperationCommentModal from "./OperationEditCommentModal";

interface OperationCommentCardProps {
  id: string;
  maxheight?: string;
}

const OperationCommentCard: FC<OperationCommentCardProps> = ({ id , maxheight }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [updateComments, setUpdateComments] = useState<boolean>(false);

  const [mode, setMode] = useState<string>("");

  const openModal = () => {
    setIsOpen(true);
    setMode("create");
  };

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    setIsOpen(false);
    setMode("");
    setUpdateComments(!updateComments);
  };

  return (
    <>
      <OperationsCommentHistory
        id={id}
        maxheight={maxheight ? maxheight :"max-h-400"}
        updateComments={updateComments}
      />
      <Tooltips title="Añadir Comentario">
        <div className="d-flex justify-content-center">
          <Button
            color="info"
            isLight
            className="mt-2 text-center"
            isLink
            icon="AddComment"
            onClick={() => openModal()}
          >
            Añadir Comentario
          </Button>
        </div>
      </Tooltips>
      <OperationCommentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        operation={id}
        onClose={_onClose}
        mode={mode}
      />
    </>
  );
};

export default OperationCommentCard;
