import {FC, Fragment, useCallback, useState} from "react";
import Icon from "../../../../components/icon/Icon";
import useFetch from "../../../../hooks/useFetch";
import {ConfiguraciónService} from "../../../../services/configuration/configurationService";
import Button from "../../../../components/bootstrap/Button";
import {handleConfirmationAlert} from "../../../../utils/ConfirmationAlert";
import {toast} from "react-toastify";
import Spinner from "../../../../components/bootstrap/Spinner";
import AddConfigurationTimeModal from "./AddConfigurationTimeModal";
import Badge from "../../../../components/bootstrap/Badge";
import PostalCodeComponent from "./PostalCodeComponent";

interface IConfigurationsListTimeProps {
    walletStatuses: any;
    walletActionStatuses: any;
    allWalletStatuses: any;
    campaigns: any;
}


const ConfigurationsListTime: FC<IConfigurationsListTimeProps> = ({
                                                                      walletStatuses,
                                                                      walletActionStatuses,
                                                                      allWalletStatuses,
                                                                      campaigns
                                                                  }) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const deleteConfiguration = async (id: string) => {
        const configurationService = new ConfiguraciónService();
        const response = await (await configurationService.deleteDetailedConfigurationWallet(id)).getResponseData();
        if (response.success) {
            toast.success('Configuración de tiempo en cartera eliminada correctamente');
            refetch();
        } else {
            response.data.errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    }

    const fetchConfigutations = useCallback(async () => {
        const configurationService = new ConfiguraciónService();
        const response = await (await configurationService.getWalletConfigurationsDetailed()).getResponseData();
        return response;
    }, []);

    const [data, loading, error, refetch] = useFetch(fetchConfigutations);

    return (
        <>
            <div className="d-flex justify-content-center flex-column">
                {data ?
                    data.length > 0 ?
                        data.map((item: any, index: number) => (
                            <div key={`wallet-configuration-time-${index}`} className="border border-2 bg-light-primary mb-4 rounded-1">
                                <div
                                     className="d-flex justify-content-around align-items-center mb-4 element-configuration"
                                     style={{
                                         minHeight: '100px'
                                     }}>
                                    <div className="d-flex justify-content-around align-items-center flex-column">
                                        <span className="fw-bold p-2">Estado</span>
                                        {item?.clientType && (
                                            <span className="fw-bold p-2">Tipo de Cliente</span>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-around align-items-center flex-column">
                                        <Button isLink className='text-wrap fs-8 p-2 statusButtons'
                                                style={{backgroundColor: item?.actionStatusTriggerColor}}>
                                            <Fragment><span className='text-white fw-bold fs-8 '
                                                            style={{color: '#3e3e3e'}}> {item?.actionStatusTriggerName} </span></Fragment>
                                        </Button>
                                        {item?.clientType && (
                                            <div className={`p-2 fw-bold mt-2 text-muted`}>
                                                {item?.clientType?.charAt(0).toUpperCase() + item?.clientType?.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                    <PostalCodeComponent postalCodes={item?.postalCodes}/>
                                    <div className="d-flex justify-content-around align-items-center flex-column">
                                        {/*
                                    IF IT HAS ACTION STATUS RESULT NAME SHOW ONE OF THE FOLLOWING ICONS:
                                    - TurnRight IF IT HAS ACTION STATUS RESULT ACTION NAME
                                    - ArrowForward IF IT DOESN'T HAVE ACTION STATUS RESULT ACTION NAME 
                                */}
                                        {item?.actionStatusResultName !== null ? <Icon
                                            icon={item?.actionStatusResultActionName !== null ? 'TurnRight' : "ArrowForward"}
                                            className={`ms-2 me-2 text-primary ${item?.actionStatusResultActionName !== null ? 'mb-4 mt-2' : ''}`}
                                            size={'2x'}/> : ''}
                                        {/* IF IT HAS ACTION STATUS RESULT ACTION NAME AND ACTION STATUS RESULT NAME SHOW ICON SubdirectoryArrowRight */}
                                        {item?.actionStatusResultActionName !== null && item?.actionStatusResultName !== null ?
                                            <Icon icon="SubdirectoryArrowRight" className="ms-2 me-2 text-primary mt-3"
                                                  size={'2x'}/> : ''}
                                        {/* IF IT DOESN'T HAVE ACTION STATUS RESULT NAME AND IT HAS ACTION STATUS RESULT ACTION NAME SHOW ICON ArrowForward */}
                                        {item?.actionStatusResultName == null && item?.actionStatusResultActionName !== null ?
                                            <Icon icon="ArrowForward" className="ms-2 me-2 text-primary"
                                                  size={'2x'}/> : ''}
                                    </div>
                                    <div className="d-flex justify-content-around align-items-center flex-column">
                                        <Badge className="fw-bold fs-6 mt-2">
                                            {item?.expirationTime ? item?.expirationTime + ' Días'
                                                : '0 Días'}
                                        </Badge>
                                    </div>
                                    <div
                                        className="fw-bold d-flex justify-content-center align-items-around flex-column">
                                        {item?.actionStatusResultName !== null ? <div
                                            className={` ${item?.actionStatusResultActionName !== null ? 'mb-5 mt-3 text-center' : ''}`}>Estado
                                            de la Cartera</div> : ''}
                                        {item?.actionStatusResultActionName !== null && item?.actionStatusResultName !== null ?
                                            <div className="text-center">Estado de Accion de<br/> la Cartera</div> : ''}
                                        {item?.actionStatusResultName == null && item?.actionStatusResultActionName !== null ?
                                            <div className="text-center">Estado de Accion de<br/> la Cartera</div> : ''}
                                    </div>
                                    <div className="d-flex justify-content-center align-items-around flex-column">
                                        {item?.actionStatusResultName !== null ? <Button isLink
                                                                                         className={`text-wrap fs-8 p-2 statusButtons ${item?.actionStatusResultActionName !== null ? 'mb-4 mt-3' : ''}`}
                                                                                         style={{backgroundColor: item?.actionStatusResultColor}}>
                                            <Fragment><span className='text-white fw-bold fs-8 '
                                                            style={{color: '#3e3e3e'}}> {item?.actionStatusResultName}  </span></Fragment>
                                        </Button> : ''}
                                        {item?.actionStatusResultActionName !== null && item?.actionStatusResultName !== null ?
                                            <Button isLink className='text-wrap fs-8 p-2 statusButtons mt-3'
                                                    style={{backgroundColor: item?.actionStatusResultActionColor}}>
                                                <Fragment><span className='text-white fw-bold fs-8 '
                                                                style={{color: '#3e3e3e'}}> {item?.actionStatusResultActionName}  </span></Fragment>
                                            </Button> : ''}
                                        {item?.actionStatusResultName == null && item?.actionStatusResultActionName !== null ?
                                            <Button isLink className={`text-wrap fs-8 p-2 statusButtons`}
                                                    style={{backgroundColor: item?.actionStatusResultActionColor}}>
                                                <Fragment><span className='text-white fw-bold fs-8 '
                                                                style={{color: '#3e3e3e'}}> {item?.actionStatusResultActionName}  </span></Fragment>
                                            </Button> : ''}
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="add-button border-danger maxWidth-rounded-config-button "
                                             onClick={() => {
                                                 handleConfirmationAlert({
                                                     title: "Eliminar Configuración",
                                                     text: "¿Está seguro que desea eliminar esta configuración?",
                                                     icon: "warning",
                                                     onConfirm: () => {
                                                         deleteConfiguration(item.id);
                                                     },
                                                 });
                                             }}>
                                            <Icon icon="Delete" className="m-2 text-danger" size={'2x'}/>
                                        </div>
                                    </div>
                                </div>
                                {item?.campaignName && (
                                    <span className="fw-bold fs-6 text-primary text-center d-flex justify-content-center">
                                        {item?.campaignName}
                                    </span>
                                )}
                                { !item?.campaignName && (
                                        <span className="fw-bold fs-6 text-muted text-center d-flex justify-content-center">
                                            {"SIN DEPARTAMENTO"}
                                        </span>
                                    )
                                }

                            </div>
                        )) : <div className="d-flex justify-content-center fw-bold fs-6 text-dark mb-5">
                            No hay configuraciones de tiempo en la cartera
                        </div>
                    : <Spinner color={'primary'} className="m-auto mb-5"/>}
                <div className="add-button border-primary maxWidth-rounded-config-button m-auto" onClick={handleOpen}>
                    <Icon icon="Add" className="m-2 text-primary" size={'2x'}/>
                </div>
            </div>
            <AddConfigurationTimeModal isOpen={open} setIsOpen={setOpen} refetch={refetch} campaigns={campaigns}
                                       allWalletStatuses={allWalletStatuses}
                                       walletActionStatuses={walletActionStatuses} walletStatuses={walletStatuses}/>
        </>
    );
}

export default ConfigurationsListTime;