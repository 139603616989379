import { FC, Fragment } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import { User } from "../../../type/user-type";

type UserCampaignProps = {
    user: User;
};

const UserCampaign: FC<UserCampaignProps> = ({ user }) => {

    const campaignName = user.campaign ? user.campaign.name : "Sin Departamento";
    const campaignClass = user.campaign ? "text-primary" : "text-muted";

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2>Departamento</h2>
                        <h3 className={`fw-bold ${campaignClass}`}>{campaignName}</h3>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default UserCampaign;