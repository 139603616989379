import React, { FC, Fragment, useCallback, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import useFetch from '../../../../hooks/useFetch'
import { OfficeService } from '../../../../services/ofiice/officeService'
import { Offices, OfficesApiResponse } from '../../../../type/office-type'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import ReactSelect, { MultiValue } from 'react-select'
import { ReactSelectStyles } from '../../../../utils/styles'
import { useLoggedUserCompany, useRoleList } from '../../../../hooks/useListData'
import Spinner from '../../../../components/bootstrap/Spinner'

interface IUsersFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: any , hardReset?: boolean) => void
  filters: any
}

const UsersFilters: FC<IUsersFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false)

  const handleTextChange = (search: string) => {
    updateFilters({ search_text: search });
  };

  const fetchOffices = useCallback(async () => {
    const officeService = new OfficeService();
    const response = await officeService.getOffices();
    return response.getResponseData() as OfficesApiResponse;
  }, []);

  const [offices, fetchingOffices, officeError] = useFetch(fetchOffices);

  const getOfficesList = () => {
    if (offices as Offices) {
      return offices.offices.map((office: { id: any; name: any; }) => {
        return {
          value: office.id,
          label: office.name
        }
      })
    }
    return [];
  }

  const roles = useRoleList(useLoggedUserCompany());

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}></CustomSearchInput>

      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Usuario </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            <div className='col-12'>
              <FormGroup id='filter2' label='Estado de cuenta'>
                <Select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                  value={filters.filter_filters?.active?.toString() || '3'}
                  id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                  list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter3' label='Nombre'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })}
                  value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter4' label='DNI'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ dni: e.target.value })}
                  value={filters.filter_filters.dni} id='dni' placeholder='DNI...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter5' label='Email'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ email: e.target.value })}
                  value={filters.filter_filters.email} id='email' placeholder='Email...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter6' label='Oficina'>
                <Select id='office' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ office: e.target.value })}
                  value={filters.filter_filters.office} ariaLabel='Default select example'
                  placeholder='Elegir Oficina...'
                  list={getOfficesList()}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter7' label='Roles'>
                { roles ? (
                  <ReactSelect isMulti id='leads' onChange={(options: MultiValue<any>) => { updateFilters({roles: options.map((option: any) => option.value)}) }} 
                    value={filters.filter_filters.roles ? roles.filter((item: any) => filters.filter_filters.roles.includes(item.value)) : []} 
                    styles={ReactSelectStyles} placeholder='Elegir Roles...' options={roles}/> ) :
                  <Spinner color={'primary'}/>
                }
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => resetFilters(50, true)}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    </Fragment>
  )
}

export default UsersFilters