import { FC, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { DocumentType } from "../../../type/document-type";
import { DocumentTypeService } from "../../../services/documents/documentTypeService";
import Checks from "../../../components/bootstrap/forms/Checks";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import Select from "../../../components/bootstrap/forms/Select";
import Spinner from "../../../components/bootstrap/Spinner";

export interface IDocumentTypeForm {
  documentType?: number;
  company?: string;
  name?: string;
  description?: string | null;
  requiredDocument?: boolean;
  entityType: string;
}

interface TypeModalProps {
  isOpen: boolean;
  setIsOpen(...args: unknown[]): unknown;
  readMode: boolean;
  documentType?: DocumentType | null;
}

const DocumentTypeModal: FC<TypeModalProps> = ({
  isOpen,
  setIsOpen,
  readMode,
  documentType,
}) => {
  const TypeInitialValues: IDocumentTypeForm = {
    documentType: documentType?.id,
    company: documentType?.company?.id || "",
    name: documentType?.name,
    description: documentType?.description || "",
    requiredDocument: documentType?.requiredDocument || false,
    entityType: documentType?.entityType || "",
  };

  const [entityTypes, setEntityTypes] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const getEntityTypes = async () => {
    const documentTypeService = new DocumentTypeService();
    const response = await (
      await documentTypeService.getEntityTypes()
    ).getResponseData();
    if (response.success) {
      const options: { value: string; label: string }[] = response.data.map(
        (entityType: string) => {
          return { value: entityType, label: entityType };
        }
      );
      setEntityTypes(options);
    }
  };

  useEffect(() => {
    getEntityTypes();
  }, [isOpen]);

  const createDocumentType = async (values: any) => {
    try {
      let response = await (
        await new DocumentTypeService().createDocumentType(values)
      ).getResponseData();
      if (response.success) {
        toast.success("Tipo de documento creado exitosamente");
      } else {
        toast.error("Error al crear Tipo de Documento");
      }
    } catch (e) {
      toast.error("Error al crear Tipo de Documento");
      console.log("error", e);
    } finally {
      setIsOpen(false);
      setLoading(false);
    }
  };

  const editDocumentType = async (values: any) => {
    try {
      values.id = documentType?.id;
      let response = await (
        await new DocumentTypeService().editDocumentType(values)
      ).getResponseData();
      if (response.success) {
        toast.success("Tipo de documento editado con éxito");
      } else {
        toast.error("Error al editar Tipo de Documento");
      }
    } catch (e) {
      toast.error("Error al editar Tipo de Documento");
      console.log("error", e);
    } finally {
      setIsOpen(false);
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: TypeInitialValues,
    onSubmit: (values) => {
      if (values.description == "") {
        values.description = null;
      }
      if (documentType === null) {
        setLoading(true);
        createDocumentType(values);
      } else {
        setLoading(true);
        editDocumentType(values);
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="lg"
      titleId="Nuevo Tipo de Documento"
    >
      <ModalHeader setIsOpen={setIsOpen} className="p-4">
        <ModalTitle id="new_role">
          {!readMode ? (documentType ? "Editar" : "Nuevo") : "Ver"} Tipo de
          Documento
        </ModalTitle>
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody className="px-4">
          <div className="row g-4">
            <FormGroup
              requiredInputLabel
              id="name"
              label="Nombre:"
              className="col-md-4"
            >
              <Input
                disabled={readMode}
                value={formik.values.name || ""}
                required
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup
              requiredInputLabel
              id="requiredDocument"
              className="col-md-4 d-flex justify-content-center align-items-center"
            >
              <Checks
                disabled={readMode}
                tmuted={true}
                label="Documento Requerido"
                id="requiredDocument"
                type="checkbox"
                checked={formik.values.requiredDocument || false}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup
              requiredInputLabel
              id="entityType"
              label="Orientado a:"
              className="col-md-4"
            >
              <Select
                disabled={readMode}
                ariaLabel="Default select example"
                placeholder="Elegir Objetivo..."
                value={formik.values.entityType || ""}
                required
                onChange={formik.handleChange}
                list={entityTypes}
              ></Select>
            </FormGroup>
          </div>
          <div className="row g-4 mt-3">
            <FormGroup
              id="description"
              label="Descripción:"
              className="col-md-12"
            >
              <Textarea
                disabled={readMode}
                value={formik.values.description || ""}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </div>
        </ModalBody>
        {!readMode ? (
          <ModalFooter className="px-4 pb-4">
            {loading ? (
              <Button isDisable={true} color="primary" type="submit">
                <Spinner />
              </Button>
            ) : (
              <Button icon="Save" color="primary" type="submit">
                {documentType ? "Editar" : "Crear"} Tipo de Documento
              </Button>
            )}
          </ModalFooter>
        ) : (
          <></>
        )}
      </form>
    </Modal>
  );
};

export default DocumentTypeModal;
