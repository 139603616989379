import { FC, useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal"
import Button from "../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import Icon from "../../../../components/icon/Icon";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import CustomSelect from "../../../../components/selectors/general/CustomSelect";
import { SingleValue } from "react-select";
import { ConfiguraciónService } from "../../../../services/configuration/configurationService";
import { toast } from "react-toastify";
import Spinner from "../../../../components/bootstrap/Spinner";
import useHandleErrors from "../../../../hooks/useHandleErrors";

interface TypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    refetch?: any;
    operationStatuses: any;
    walletStatuses: any;
    walletActionStatuses: any;
    campaigns: any;
}

const AddConfigurationModal:FC<TypeModalProps> = ({ isOpen, setIsOpen, refetch, operationStatuses, walletStatuses, walletActionStatuses, campaigns }) => {

    const [loading, setLoading] = useState(false)
    const {handleErrors} = useHandleErrors();

    const handleSubmit  = async (values: any) => {
        setLoading(true);
        const configurationService = new ConfiguraciónService();
        const response = await ( await configurationService.createConfigurationWallet(values) ).getResponseData();

        handleErrors(response);

        if (response.success) {
            toast.success('Configuración de Cartera guardada correctamente');
            setLoading(false);
            setIsOpen(false);
            formik.resetForm();
            refetch && refetch();
        } else {
            setLoading(false);
            setIsOpen(false);
            formik.resetForm();
            refetch && refetch();
        }
    }

    const formik = useFormik({
        initialValues: {
            triggerStatus: '',
            statusResult: null,
            statusResultWallet  : null,
            campaign: null
        },
        onSubmit: async (values) => {
            if (!values.statusResult && !values.statusResultWallet) {
                toast.warning('Debes completar al menos un cambio de estado');
                return;
            }
            handleSubmit(values);
        }
    });

    return (
        <Modal isOpen={isOpen}  setIsOpen={setIsOpen} size="xl" titleId="Nueva Configuración de Cartera">
            <ModalHeader setIsOpen={setIsOpen} className="p-4">
                <ModalTitle id="new_configuration" className="fw-bold fs-4 text-primary w-100 h-100">
                    <Icon icon="Settings" className="me-2 text-primary" size={'2x'} />
                    Nueva Configuración de Cartera
                </ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className="row">
                    <div className="col-3">
                        <FormGroup requiredInputLabel id="triggerStatus" label="Estado de Operación:"
                                   className="col-12">
                            <CustomSelect placeholder="Elegir Estado..." is_multi={false} options={operationStatuses}
                                          isRequired={true}
                                          style2={true}
                                          formatOptionLabel={(option: any) => <div className="d-flex justify-content-between align-items-center">
                                            {option.label}
                                            <div>
                                                <svg viewBox="0 0 24 24" fill={option.color} className="svg-icon--material svg-icon fs-5 me-2" data-name="Material--Lens">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8z" opacity="0.3"></path>
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                                </svg>
                                                {option.active ? 
                                                <svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon svg-icon-3x text-primary fs-5" data-name="Material--Done">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
                                                </svg>
                                                : 
                                                <svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon svg-icon-3x text-danger fs-5" data-name="Material--Clear">
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                                                </svg>
                                                }
                                            </div>
                                          </div>}
                                          onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('triggerStatus', value.value)}/>
                        </FormGroup>
                    </div>
                    <div className="col-3 d-flex justify-content-around align-items-center flex-column">
                        <div className={"fw-bold text-center"}>Crea/Actualiza la Cartera</div>
                        <Icon icon="ArrowForward" className="ms-2 me-2 text-primary col-12 mb-3 mt-3" size={'3x'}/>
                        <Icon icon="SubdirectoryArrowRight" className="ms-2 me-2 text-primary" size={'3x'}/>
                    </div>
                    <div className="col-3">
                        <FormGroup id="statusResult" label="Estado de Cartera:" className="col-12">
                            <CustomSelect placeholder="Elegir Estado de cartera..." is_multi={false} options={walletStatuses}
                                          onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('statusResult', value.value)}/>
                        </FormGroup>
                        <FormGroup id="statusResultWallet" label="Estado Acción de Cartera:" className="col-12 mt-3">
                            <CustomSelect placeholder="Elegir Estado de acción..." is_multi={false}
                                          options={walletActionStatuses}
                                          onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('statusResultWallet', value.value)}/>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup id="statusResultWallet" label="Asociar al departamento" className="col-12">
                            <CustomSelect placeholder="Elegir departamento..." is_multi={false}
                                          options={campaigns}
                                          onChangeSingle={(value: SingleValue<any>) => formik.setFieldValue('campaign', value.value)}/>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className="px-4 pb-4">
                    <Button icon={loading ? '' : 'Save'} color="primary" type="submit" isDisable={loading}>
                        {loading ? <Spinner color={'light'}/> :
                            <Icon icon="Save" className="me-2 text-primary" style={{fontSize: '1.3 rem'}}/>}
                        {loading ? '' : 'Guardar Configuración'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddConfigurationModal;