import React, { useCallback, useEffect, useState } from 'react'
import {StatusEntity} from '../../../type/actionStatus-type';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select, { ActionMeta } from 'react-select';
import { StatusService } from '../../../services/status/statusService';
import useFetch from '../../../hooks/useFetch';
import {ReactSelectStyles} from "../../../utils/styles";

type StatusSelectorProps = {
    defaultSelected?: string | null;
    name: string;
    onChange: (value: string[]|string) => void;
    isMulti?: boolean;
    discardSelected?: boolean;
    isRequired?: boolean;
}

const StatusTypeSelector: React.FC<StatusSelectorProps> = ({ defaultSelected, isMulti, discardSelected, onChange, isRequired , name}) => {

    const [selectedOption, setSelectedOption] = useState<string[]|string|null>(null);

    // listen for changes in selectedOptions and store in selectedOptions state
    useEffect(() => {
        if (defaultSelected) {
            setSelectedOption(discardSelected ? null : defaultSelected);
        }
    }, [defaultSelected])

    const [data] = useFetch(
        useCallback(async () => {
            const service = new StatusService();
            const response = await service.getEntityTypes();
            return response.getResponseData();
        }, [])
    )

    const _getOptions = (): any[] => {
        let options: any = [];
        if (data) {
            data.forEach((status: StatusEntity) => {
                // if discardSelected is true, discard selected options for not appear in the list
                if(discardSelected && defaultSelected && defaultSelected.length > 0) {
                    let found = defaultSelected == status.name;
                    if(found) {
                        return;
                    }
                }
                options.push({ value: status.name, label: status.label });
            })
        }
        return options;
    }



    const _getDefaultSelectedValues = (): any => {
        let option = null;
        if (selectedOption && data) {
            let optionData = data.find((status: StatusEntity) => status.name == selectedOption);
            option = { value: optionData?.name, label: optionData?.label };
        }
        return option;
    }

    const _handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        if (Array.isArray(newValue)) {
            // map for object selected
            let selected = newValue.map((option: any) => option.value);
            setSelectedOption(selected);
            onChange([...selected]);
        } else {
            setSelectedOption(newValue.value);
            onChange(newValue.value);
        }
    }


    // useEffect when selectedOptions changes and call onChange function


    return (
        <FormGroup requiredInputLabel={isRequired} color={'primary'} label='Tipo de estado'>
            <Select styles={ReactSelectStyles} required={isRequired}
                    name={name} isMulti={isMulti} options={_getOptions()} value={_getDefaultSelectedValues()} onChange={_handleChange} />
        </FormGroup>
    )
}

StatusTypeSelector.defaultProps = {
    isMulti: false,
    discardSelected: false,
    isRequired: false
}

export default StatusTypeSelector;
