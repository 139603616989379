import React, { useEffect, useState } from "react";
import { ActionStatus } from "../../../type/actionStatus-type";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Label from "../../bootstrap/forms/Label";
import Select, { ActionMeta } from "react-select";
import { StatusService } from "../../../services/status/statusService";
import { ReactSelectStyles } from "../../../utils/styles";
import Spinner from "../../bootstrap/Spinner";

type StatusSelectorProps = {
  defaultSelected: string[];
  entity?: string;
  name?: string;
  onChange: (value: ActionStatus[]) => void;
  data ?: any;
  isMulti?: boolean;
  discardSelected?: boolean;
};

const StatusSelector: React.FC<StatusSelectorProps> = ({
  defaultSelected,
  isMulti,
  discardSelected,
  onChange,
  entity,
  name,
  data
}) => {
  const [selectedOptions, setSelectedOptions] =
  useState<string[]>(defaultSelected);
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [empty , setEmpty] = useState<boolean>(false);

  // Fetch options and set them initially
  useEffect(() => {
    const fetchData = async () => {
      const service = new StatusService();
      const response = await service.getActionStatuses({
        filter_filters: { entity: entity },
        limit: 99999
      });
      const data = response.getResponseData();
      if (data) {
        if (data.data.actionStatuses.length === 0) {
            setEmpty(true);
        }
        const options = data.data.actionStatuses.map((status: ActionStatus) => ({
          value: status.id,
          label: `${status.name} (${status.statusEntity.label})`,
        }));
          setSelectOptions(options);
      }
    };

    if (!data) {
      fetchData();
    } else {
      setSelectOptions(data);
    }
  }, [entity]);

  const _getDefaultSelectedValues = (): any[] => {
    return selectedOptions.map((status: string) =>
      selectOptions.find((option) => option.value === status)
    );
  };

  const _handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (Array.isArray(newValue)) {
      const selected = newValue.map((option: any) => option.value);
      setSelectedOptions(selected);
      onChange(selected);
    } else {
      const selected = [newValue.value];
      setSelectedOptions(selected);
      onChange(selected);
    }
  };

  useEffect(() => {
    if (isMulti) {
    setSelectedOptions(defaultSelected);
    }
  }, [defaultSelected]);

  return (
    <>
      {selectOptions.length > 0 || empty || data ? (
        <FormGroup>
          <Label>{name ? name : "Estado" + (isMulti ? "s" : "")}</Label>
          <Select
            isMulti={isMulti}
            styles={ReactSelectStyles}
            options={selectOptions}
            value={_getDefaultSelectedValues()}
            onChange={_handleChange}
          />
        </FormGroup>
      ) : (
      <div className="text-center">
        <Spinner/>
      </div>)}
    </>
  );
};

StatusSelector.defaultProps = {
  isMulti: false,
  discardSelected: true,
  entity: "operation",
};

export default StatusSelector;
