import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { ClientsApiResponse } from "../../../type/client-type";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import { ClientService } from "../../../services/clients/clientService";
import useFetch from "../../../hooks/useFetch";
import StatusDropdown from "../../../components/StatusDropdown";
import ClientFilters from "./client-options/ClientFilters";
import ClientCommentModal from "../components/ClientEditCommentModal";
import Icon from "../../../components/icon/Icon";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { ClickToCall } from "../../../components/calls/ClickToCall";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const ClientList = () => {
  const { userCan } = useContext(PrivilegeContext);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [client, setClient] = useState<any>(null);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();
  
  useEffect(() => {
    checkIfUrlHasFilters();
  },[]);

  const openModal = (id: string) => {
    setIsOpen(true);
    setMode("create");
    setClient(id);
  };

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    setIsOpen(false);
    setMode("");
    updateFilters({...filters});
  };


  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const fetchClients = useCallback(async () => {
    const campaignService = new ClientService();
    const response = await campaignService.getClients(filters);
    return response.getResponseData() as ClientsApiResponse;
  }, [filters]);

  const [data, loading, error, refech] = useFetch(fetchClients);

  const deleteClient = async (id: string) => {
    let response = (
      await new ClientService().deleteClient(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Cliente eliminado correctamente");
      refech();
    } else {
      toast.error("Error al eliminar el Cliente");
    }
  };

  const getMostRecentDate = (comment: any) => {
    const createdAtDate = new Date(comment.createdAt.date);
    const updatedAtDate = comment.updatedAt
      ? new Date(comment.updatedAt.date)
      : null;

    return updatedAtDate && updatedAtDate > createdAtDate
      ? updatedAtDate
      : createdAtDate;
  };

  const toggleClientStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new ClientService().toggleClientStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const _toggleFavourite = async (id: string, favourite: boolean) => {
    try {
      let response = await (
        await new ClientService().toggleClientFavourite(id, !favourite)
      ).getResponseData();
      if (response.success) {
        toast.success("Cliente actualizado correctamente");
        refech();
      } else {
        toast.error("Error al actualizar el Cliente");
      }
    } catch (error: any) {
      toast.error("Error al actualizar el Cliente");
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Clientes</CardTitle>
            {userCan("create", "clients") ? (
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan("create", "clients") ? "" : "d-none"
                    }`}
                  isLight
                  onClick={() => {
                    navigate("create");
                  }}
                >
                  Añadir Cliente
                </Button>
              </>
            ) : (
              <></>
            )}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <ClientFilters
            filters={filters}
            updateFilters={updateFilters}
            resetFilters={resetFilters}
          />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {data ? (
              <>
                <CustomTable
                    data={data?.clients ? data.clients : null}
                    defaultLimit={filters.limit || 50}
                    defaultOrder={filters.filter_order || undefined}
                    pagination={true}
                    paginationData={{
                      pageSize: filters.limit,
                      currentPage: filters.page,
                      pageCount: (data as ClientsApiResponse) ? data.lastPage : 1,
                      totalCount: data.totalRegisters,
                      handlePagination: updatePage,
                      handlePerPage: updatePageSize,
                    }}
                    className={"table table-hover fs-7"}
                    columns={[
                      {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Nif",
                        keyValue: "nif",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Email",
                        keyValue: "email",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.email ? element.email : "-"}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Teléfono 1",
                        keyValue: "phone1",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.phone1 ? (<ClickToCall telephone={element.phone1}/>): "-"}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Teléfono 2",
                        keyValue: "phone2",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.phone2 ? (<ClickToCall telephone={element.phone2}/>) : "-"}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Address",
                        keyValue: "address",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.address ? element.address : "-"}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Localidad",
                        keyValue: "locality",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Provincia",
                        keyValue: "province",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Código Postal",
                        keyValue: "postalCode",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Iban",
                        keyValue: "iban",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Comentarios",
                        keyValue: "comments",
                        className: "text-center",
                        render: (element: any) => {

                          let mostRecentComment = null;

                          if (element.comments.length > 0) {
                            mostRecentComment = element.comments.reduce((prevComment: any, currentComment: any) => {
                              const prevCommentDate = getMostRecentDate(prevComment);
                              const currentCommentDate = getMostRecentDate(currentComment);
                              return currentCommentDate > prevCommentDate ? currentComment : prevComment;
                            });
                          }
                        
                          if(!mostRecentComment) {
                            if(element.textComments) {
                              mostRecentComment = {
                                comment: element.textComments,
                                createdAt: element.createdAt
                              }
                            }
                          }

                          return (
                            <div className="d-flex justify-content-center">
                              {mostRecentComment ? (
                                <Tooltips title={mostRecentComment?.comment}>
                                  <Icon icon="Message" color="primary" size={"2x"} />
                                </Tooltips>
                              ) : (
                                <Icon icon="Message" color="gray" size={"2x"} />
                              )}
                            </div>
                          );
                        }
                      },
                      {
                        name: "Favorito",
                        keyValue: "favourite",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center" onClick={() => _toggleFavourite(element.id, element.favourite)}>
                              <Tooltips title={element.favourite ? "Quitar de favoritos" : "Añadir a favoritos"}>
                                {element.favourite ? (
                                  <Icon icon="Star" color="info" size={"2x"} />
                                ) : (
                                  <Icon icon="Star" color="gray" size={"2x"} />
                                )}
                              </Tooltips>
                            </div>
                          );
                        }
                      },
                      {
                        name: "Estado",
                        keyValue: "active",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className={"text-center"}>
                              <StatusDropdown
                                disabled={changingStatus.includes(element.id)}
                                itemId={element.id}
                                status={element.active}
                                change={toggleClientStatus}
                                key={element.id}
                              />
                            </div>
                          );
                        },
                      },
                      {
                        name: "Organización",
                        keyValue: "company",
                        className: userCan("list", "operations", true)
                          ? "text-center"
                          : "d-none",
                        isVisible: userCan("list", "operations", true),
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.company.name}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Acciones",
                        className: "min-w-100px text-end",
                        isActionCell: true,
                      },
                    ]}
                    actions={[
                      {
                        title: "Ver",
                        buttonType: "icon",
                        iconColor: "text-primary",
                        iconPath: "/media/icons/duotune/general/gen021.svg",
                        additionalClasses: `text-primary ${userCan("list", "clients") ? "" : "d-none"
                          }`,
                        description: "Ver el Cliente",
                        callback: (item: any) => {
                          if (userCan("list", "clients")) navigate(`${item.id}/show`);
                        },
                      },
                      {
                        title: "Editar",
                        buttonType: "icon",
                        iconColor: "text-info",
                        iconPath: "/media/icons/duotune/general/gen055.svg",
                        additionalClasses: `text-primary ${userCan("edit", "clients") ? "" : "d-none"
                          }`,
                        description: "Editar el Cliente",
                        callback: (item: any) => {
                          if (userCan("edit", "clients")) navigate(`edit/${item.id}`);
                        },
                      },
                      {
                        title: "Eliminar",
                        buttonType: "icon",
                        iconColor: "text-danger",
                        iconPath: "/media/icons/duotune/general/gen027.svg",
                        additionalClasses: `text-danger ${userCan("delete", "clients") ? "" : "d-none"
                          }`,
                        description: "Eliminar el Departamento",
                        callback: (item: any) => {
                          if (userCan("delete", "clients")) {
                            handleConfirmationAlert({
                              title: "Eliminar Cliente",
                              text: "¿Está seguro que desea eliminar el cliente? Todos los contratos que hereden o contengan este Cliente quedarán huérfanos.",
                              icon: "warning",
                              onConfirm: () => {
                                deleteClient(item.id);
                              },
                            });
                          }
                        },
                      },
                      {
                        title: "Añadir comentario",
                        buttonType: "icon",
                        iconColor: "text-secondary",
                        iconPath: "/media/icons/duotune/general/gen063.svg",
                        additionalClasses: `text-danger ${userCan("edit", "clients") ? "" : "d-none"
                          }`,
                        description: "Añadir comentario",
                        callback: (item: any) => {
                          if (userCan("edit", "clients")) {
                            openModal(item.id);
                          }
                        }
                      }
                    ]}
                  />
                  <ClientCommentModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    onClose={_onClose}
                    mode={mode}
                    client={client}
                  />
                </>
          ) : (
            <div className="text-center">
              <Spinner></Spinner>
            </div>
          )
        }</Card>
      </Page>
    </Fragment>
  );
};

export default ClientList;
