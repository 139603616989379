import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import Icon from '../../../../components/icon/Icon'

interface ICompanyFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any
}

const CompanyFilters: FC<ICompanyFiltersProps> = ({updateFilters, filters, resetFilters}) => {

    const [filterMenu, setFilterMenu] = useState(false)

    const handleTextChange = (search: string) => {
        updateFilters({ search_text: search });
      };
    
    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
             <Icon icon='Search' size='2x' color='primary' />
            </label>
             <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}></CustomSearchInput>

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) } }>
                Filtros
            </Button>
            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Usuario </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'> 
						<div className='col-12'>
							<FormGroup id='filter1' label='Estado de Organización'>
                                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>)=> updateFilters({active: parseInt(e.target.value)})} value={filters.filter_filters?.active?.toString() || '3'}
                                    id='active-select' ariaLabel='Default select example' placeholder='Estado...' 
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' },{value: 1, label: 'Activa', text: 'Activa'}, {value: 0, label: 'Desactivada', text: 'Desactivada'}]}
                                />
							</FormGroup>
						</div>
                        <div className='col-12'>
							<FormGroup id='filter2' label='Nombre'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>)=> updateFilters({name: e.target.value})} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
							</FormGroup>
						</div>
                        <div className='col-12'>
							<FormGroup id='filter3' label='CIF'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>)=> updateFilters({cif: e.target.value})} value={filters.filter_filters.cif} id='cif' placeholder='CIF...' autoComplete='off' />
							</FormGroup>
						</div>
                        <div className='col-12'>
							<FormGroup id='filter4' label='Dirección'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>)=> updateFilters({address: e.target.value})} value={filters.filter_filters.address} id='address' placeholder='Dirección...' autoComplete='off' />
							</FormGroup>
						</div>
                    </div>
                    <div className='col-12'>
                        <Button
                            className='mt-4'
                            color="storybook"
                            isLight
                            onClick={() => resetFilters(50, true)}
                        >
                        Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default CompanyFilters