import React, { FC, KeyboardEventHandler, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const isValidPostalCode = (postalCode: string) => {
  const postalCodeRegex = /^[0-9]{5}$/;
  return postalCodeRegex.test(postalCode);
};

interface CreateOptionsSelectProps {
    className: string;
    formik?: any;
}

const CreateOptionPostalCodeIncludedFormik: FC <CreateOptionsSelectProps>  = ({className, formik}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState<readonly Option[]>([]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (isValidPostalCode(inputValue)) {
          setValue((prev) => [...prev, createOption(inputValue)]);
          formik.setFieldValue('postalCodeInclude', [...formik.values.postalCodeInclude, inputValue]);
          setInputValue('');
          setError(false);
          break;
        } else {
          setError(true);
          break;
        }
      case 'Tab':
        event.preventDefault();
        if (isValidPostalCode(inputValue)) {
          setValue((prev) => [...prev, createOption(inputValue)]);
          formik.setFieldValue('postalCodeInclude', [...formik.values.postalCodeInclude, inputValue]);
          setInputValue('');
          setError(false);
          break;
        } else {
          setError(true);
          break;
        }
    }
  };

  return (
    <>
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      className={`${className}`}
      menuIsOpen={false}
      onChange={(newValue) => {
        setValue(newValue)
        if (newValue) {
          formik.setFieldValue('postalCodeInclude', [...formik.values.postalCodeInclude, inputValue]);
          setError(false);
        }
      }}
      onInputChange={(newValue) => {
        if (newValue == '') { setError(false) }
        setInputValue(newValue)}
      } 
      onKeyDown={handleKeyDown}
      placeholder="Escribe un código postal y presiona enter"
      value={value}
    />
    { error && <div className="text-danger mt-2">Ingrese un código postal válido</div> }
    </>
  );
};


export default CreateOptionPostalCodeIncludedFormik;
