import React, { useEffect } from 'react'
import Select, { ActionMeta, GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';
import { ReactSelectStyles } from '../../../utils/styles';
import { ReactSelectStyles2 } from '../../../utils/styles-2';

type Props = {
    id ?: string,
    customClass ?: string,
    required?: boolean,
    options?: OptionsOrGroups<unknown, GroupBase<unknown>>
    is_multi?: boolean,
    onChange?: (selected: MultiValue<unknown>) => void,
    onChangeSingle?: (selected: SingleValue<unknown>) => void,
    onInputChange?: (inputValue: string) => void;
    formatOptionLabel?: (option: any) => any
    placeholder?: string
    defaultValue ?: OptionsOrGroups<any, any>|null|any
    customFontSize?: string
    minWidth?: string
    isRequired?: boolean
    style2?: boolean
}

const CustomSelect: React.FC<Props> = ({ options, placeholder = 'Select options', onChange, is_multi, onChangeSingle, customClass , defaultValue,style2 , onInputChange, customFontSize, minWidth, isRequired, formatOptionLabel}) => {

    const onSelectChange = (newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChange !== undefined) {
            onChange(newValue);
        }
    }

    const onSelectChangeSingle = (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChangeSingle !== undefined) {
            onChangeSingle(newValue);
        }
    }

    const onInputValueChange = (inputValue: string) => {
        if (onInputChange !== undefined) {
          onInputChange(inputValue);
        }
      };

    return (
        <>
            {
                is_multi === true ?
                (
                    <Select placeholder={placeholder} required={isRequired} isSearchable={true} isMulti={true} styles={style2 ? ReactSelectStyles2 : ReactSelectStyles} options={options} defaultValue={defaultValue} onChange={onSelectChange} onInputChange={onInputValueChange} key={defaultValue} className={customClass} formatOptionLabel={formatOptionLabel}></Select>
                )
                :
                (
                    <Select placeholder={placeholder} required={isRequired} isSearchable={true} isMulti={false} styles={style2 ? ReactSelectStyles2 : ReactSelectStyles} options={options} defaultValue={defaultValue} onChange={onSelectChangeSingle} onInputChange={onInputValueChange} key={defaultValue} className={customClass} formatOptionLabel={formatOptionLabel}></Select>
                )
            }
            
        </>
    )
}

export default CustomSelect;