import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardTitle,
} from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import StatusDropdown from "../../../components/StatusDropdown";
import Page from "../../../layout/Page/Page";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import { UserService } from "../../../services/users/userService";
import UsersFilters from "./users-options/UsersFilters";
import useFetch from "../../../hooks/useFetch";
import { UsersApiResponse } from "../../../type/user-type";
import AsyncImg from "../../../components/AsyncImg";
import Badge from "../../../components/bootstrap/Badge";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import moment from "moment";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { Link } from "react-router-dom";
import { RestorePermissionsComponent } from "../../../components/permissions/RestorePermissionsButton";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/authSlice";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const UsersList = () => {
  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const { handleErrors } = useHandleErrors();
  const dispatch = useDispatch();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchUsers = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUsers(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchUsers);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const deleteUser = async (id: string) => {
    let response = (await new UserService().deleteUser(id)).getResponseData();
    if (response.success) {
      toast.success("Usuario eliminado correctamente");
      updateFilters({ ...filters });
    }
  };

  const toggleUserStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new UserService().toggleUserStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const _loginLikeUser = async (id: string) => {
    handleConfirmationAlert({
      title: "Login",
      text: "¿Está seguro que desea logearse como este usuario?",
      icon: "warning",
      onConfirm: async () => {
        let response = await (
          await new UserService().loginLikeUser(id)
        ).getResponseData();

        if (response.success) {
          dispatch(
            login({
              ...response.data,
              isAuthenticated: true,
              error: null,
              loading: false,
            })
          );
          navigate("/");
        }

        handleErrors(response);
      },
    });
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Usuarios</CardTitle>
            <SubheaderSeparator />
            <Button
              color="light"
              icon="PersonAdd"
              isLight
              onClick={() => {
                navigate("create");
              }}
            >
              Añadir Usuario
            </Button>
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <UsersFilters
            updateFilters={updateFilters}
            filters={filters}
            resetFilters={resetFilters}
          />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {data ? (
            <CustomTable
              data={data?.users ? data.users : null}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: (data as UsersApiResponse) ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
              }}
              defaultLimit={filters.limit || 50}
              defaultOrder={filters.filter_order || undefined}
              className={"table table-hover"}
              columns={[
                {
                  name: "",
                  keyValue: "name",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <Link to={`/users/${element.id}/profile`}>
                          <div className="user-avatar">
                            <AsyncImg
                              id={
                                element.profileImg
                                  ? element.profileImg.id
                                  : null
                              }
                            />
                          </div>
                        </Link>
                      </div>
                    );
                  },
                },
                {
                  name: "Nombre",
                  keyValue: "name",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center text-center">
                        <Link
                          to={`/users/${element.id}/profile`}
                          className="cursor-pointer text-primary fw-bold"
                        >
                          {element.name}
                        </Link>
                      </div>
                    );
                  },
                },
                {
                  name: "Email",
                  keyValue: "email",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  cellClassName: "text-center",
                },
                {
                  name: "Teléfono",
                  keyValue: "telephone",
                  className: "text-center",
                  cellClassName: "text-center",
                },
                {
                  name: "Estado",
                  keyValue: "active",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className={"text-center"}>
                        <StatusDropdown
                          disabled={changingStatus.includes(element.id)}
                          itemId={element.id}
                          status={element.active}
                          change={toggleUserStatus}
                          key={element.id}
                        />
                      </div>
                    );
                  },
                },
                {
                  name: "Fecha de creación",
                  keyValue: "createdAt",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className={"text-center"}>
                        <span className={"text-muted"}>
                          {moment(element.createdAt.date).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: "Último acceso",
                  keyValue: "lastLogin",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className={"text-center"}>
                        <span className={"text-muted"}>
                          {element.lastLogin?.date &&
                            moment(element.lastLogin?.date).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: "Oficina",
                  keyValue: "office",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className={"text-center"}>
                        <span>{element.office ? element.office.name : ""}</span>
                      </div>
                    );
                  },
                },
                {
                  name: "Organización",
                  keyValue: "company",
                  sortable: true,
                  sortColumn: updateFilterOrder,
                  className: userCan("list", "users", true)
                    ? "text-center"
                    : "d-none",
                  isVisible: userCan("list", "users", true),
                  render: (element: any) => {
                    return (
                      <>
                        {element.userRoles.map((userRole: any) => {
                          if (userRole.company) {
                            return (
                              <div
                                key={userRole.id}
                                className="d-flex justify-content-center"
                              >
                                {userRole.company.name}
                              </div>
                            );
                          }

                          return null;
                        })}
                      </>
                    );
                  },
                },
                {
                  name: "Rol",
                  keyValue: "role",
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className="d-flex justify-content-center">
                        {element.userRoles.map((userRole: any) => (
                          <Badge
                            key={userRole.id}
                            color={"primary"}
                            isLight={true}
                            className="px-3 py-2"
                            rounded={1}
                          >
                            {userRole.role.name}
                          </Badge>
                        ))}
                      </div>
                    );
                  },
                },
                {
                  name: "Permisos",
                  keyValue: "role",
                  sortable: false,
                  sortColumn: updateFilterOrder,
                  isVisible: userCan("list", "users"),
                  className: "text-center",
                  render: (element: any) => {
                    return (
                      <div className={"text-center"}>
                        <RestorePermissionsComponent
                          userId={element.id}
                          size={"sm"}
                        ></RestorePermissionsComponent>
                      </div>
                    );
                  },
                },

                {
                  name: "Acciones",
                  className: "min-w-100px text-end",
                  isActionCell: true,
                },
              ]}
              actions={[
                {
                  title: "Login",
                  buttonType: "icon",
                  iconColor: "text-primary",
                  iconPath: "/media/icons/duotune/general/power-off-solid.svg",
                  additionalClasses: `${userCan("login_like_user", "users") ? "text-primary" : "d-none"}`,
                  hide: (item: any) =>
                    userCan("login_like_user", "users") ? false : true,
                  description: "Logearse como este usuario",
                  callback: (item: any) => {
                    _loginLikeUser(item.id);
                  },
                },
                {
                  title: "Ver",
                  buttonType: "icon",
                  iconColor: "text-primary",
                  iconPath: "/media/icons/duotune/general/gen021.svg",
                  additionalClasses: `${userCan("show", "users") ? "text-primary" : "d-none"}`,
                  description: "Ver perfil de usuario",
                  callback: (item: any) => {
                    navigate(`${item.id}/profile`);
                  },
                },
                {
                  title: "Editar",
                  buttonType: "icon",
                  iconColor: "text-info",
                  iconPath: "/media/icons/duotune/general/gen055.svg",
                  additionalClasses: `${userCan("edit", "users") ? "text-primary" : "d-none"}`,
                  description: "Editar perfil del usuario",
                  callback: (item: any) => {
                    navigate(`${item.id}/edit`);
                  },
                },
                {
                  title: "Eliminar",
                  buttonType: "icon",
                  iconColor: "text-danger",
                  iconPath: "/media/icons/duotune/general/gen027.svg",
                  additionalClasses: `${userCan("delete", "users") ? "text-danger" : "d-none"}`,
                  description: "Eliminar usuario",
                  callback: (item: any) => {
                    handleConfirmationAlert({
                      title: "Eliminar usuario",
                      text: "¿Está seguro que desea eliminar el usuario?",
                      icon: "warning",
                      onConfirm: () => {
                        deleteUser(item.id);
                      },
                    });
                  },
                },
              ]}
            />
          ) : (
            <div className="text-center d-flex justify-content-center">
              <Spinner></Spinner>
            </div>
          )}
        </Card>
      </Page>
    </Fragment>
  );
};

export default UsersList;