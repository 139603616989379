import { AxiosResponse } from "axios";
import { RestServiceConnection } from '../restServiceConnection';

const RANKINGS_ENDPOINT = '/ranking';

export class RankingService extends RestServiceConnection {
    
        createRanking = async (ranking: any) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: RANKINGS_ENDPOINT + '/create',
                data: ranking
            }, true);
            return this;
        }
    
        editRanking = async (ranking: any) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: RANKINGS_ENDPOINT + '/edit',
                data: ranking
            }, true);
            return this;
        }
    
        getRankings = async (filters?: any) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: RANKINGS_ENDPOINT + '/list',
                data: filters,
                }, true) as AxiosResponse;
            return this;
        }
    
        getRankingById = async (id: string) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: RANKINGS_ENDPOINT + '/get',
                data: {
                    rankingId: id
                },
            }, true);
            return this;
        }
    
        deleteRanking = async (id: string) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: RANKINGS_ENDPOINT + '/delete',
                data: {
                    rankingId: id
                },
            }, true);
            return this;
        }
}
