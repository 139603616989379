import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { ExpensesApiResponse, ExpenseTypeApiResponse } from "../../../type/expense-type";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ExpenseTypeService } from "../../../services/expenses/expenseTypeService";
import ExpenseTypeModal from "../expenses-create/ExpenseTypeModal";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import ExpenseTypesFilters from "./expenses-options/ExpensesTypesFilters";
import { CheckCircleOutline, HighlightOff } from "../../../components/icon/material-icons";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const ExpenseTypeList = () => {
    const {userCan} = useContext(PrivilegeContext);

    const [expenseTypeId, setExpenseTypeId] = useState(null);
    const [modal, setModal] = useState(false);
    const [readMode, setReadMode] = useState(false);

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

    useEffect(() => {
        checkIfUrlHasFilters();
    },[]);

    const fetchExpensesTypes = useCallback(async () => {
        const response = await new ExpenseTypeService().getExpenseTypes(filters);
        return response.getResponseData() as ExpensesApiResponse;
    }, [filters, modal]);

    const [data, loading, error, refetch] = useFetch(fetchExpensesTypes);

    const deleteExpenseType = async (id: string) => {
        let response = (await new ExpenseTypeService().deleteExpenseType(id)).getResponseData();
        if (response.success) {
            toast.success('Tipo de gasto eliminado correctamente');
            updateFilters({ ...filters });
        }else toast.error('Error al eliminar tipo de gasto')
    }

    const openModal = (item: any, readBool: boolean) => {
        setReadMode(readBool);
        setExpenseTypeId(item);
        setModal(true);
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Tipo de Gastos</CardTitle>
                        <SubheaderSeparator />
                        <Button
                            color="light"
                            icon="Add"
                            isLight
                            onClick={() => {
                                openModal(null, false);
                            }}
                        >

                            Añadir Tipo de Gasto
                        </Button>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ExpenseTypesFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
                </SubHeaderRight>
            </SubHeader>
            <Card>
                <CardBody>
                    <Fragment>
                    {
                        data ? (
                            <CustomTable
                                data={data?.expenseTypes ? data.expenseTypes : null}
                                pagination={true}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: data as ExpenseTypeApiResponse ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                className={"table table-hover"}
                                columns={
                                    [
                                        {
                                            name: "Nombre",
                                            keyValue: "expenseName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {
                                                        openModal(element, true);
                                                    }}>
                                                        {element.expenseName}
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            name: "Usuario",
                                            keyValue: "user",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div key={element.user?.id} className="d-flex justify-content-center">
                                                        {element.user?.name}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Organización",
                                            keyValue: "company",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: userCan('manage_types','expenses',true) ? "text-center" : "d-none",
                                            isVisible: userCan('manage_types','expenses',true),
                                            render: (element: any) => {
                                                return (
                                                    <div key={element.company?.id} className="d-flex justify-content-center">
                                                        {element.company?.name}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Importe",
                                            keyValue: "amount",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                        },
                                        {
                                            name: "Fijo",
                                            keyValue: "fixed",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="fs-7 text-center">{element.fixed ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Fecha de creación",
                                            keyValue: "createdAt",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className={'text-center'}>
                                                        <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            name: "Última modificación",
                                            keyValue: "updatedAt",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                                              }
                                        },
                                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                                    ]}
                                actions={
                                    [
                                        {
                                            title: "Ver",
                                            buttonType: 'icon',
                                            iconColor: 'text-primary',
                                            iconPath: '/media/icons/duotune/general/gen021.svg',
                                            additionalClasses: 'text-primary',
                                            description: "Ver tipo de gasto",
                                            callback: (item: any) => {
                                                openModal(item, true);
                                            },
                                        },
                                        {
                                            title: "Editar",
                                            buttonType: 'icon',
                                            iconColor: 'text-info',
                                            iconPath: '/media/icons/duotune/general/gen055.svg',
                                            additionalClasses: 'text-primary',
                                            description: "Editar tipo de gasto",
                                            callback: (item: any) => {
                                                openModal(item, false);
                                            },
                                        },
            
                                        {
                                            title: "Eliminar",
                                            buttonType: 'icon',
                                            iconColor: 'text-danger',
                                            iconPath: '/media/icons/duotune/general/gen027.svg',
                                            additionalClasses: 'text-danger',
                                            description: "Eliminar tipo de gasto",
                                            callback: (item: any) => {
                                                handleConfirmationAlert({
                                                    title: "Eliminar tipo de gasto",
                                                    text: "¿Está seguro que desea eliminar el tipo de gasto?",
                                                    icon: "warning",
                                                    onConfirm: () => {
                                                        deleteExpenseType(item.id);
                                                    }
                                                })
                                            },
                                        },
                                    ]
                                }
                            />
                        ) : (
                            <div className="text-center d-flex justify-content-center"><Spinner /></div>
                        )
                    }
                    </Fragment>
                </CardBody>
            </Card>
            {modal && (<ExpenseTypeModal isOpen={modal} setIsOpen={setModal} expenseType={expenseTypeId} readMode={readMode} />)}
        </Fragment>
    );
}

export default ExpenseTypeList;