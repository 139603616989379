

import React, { ChangeEvent } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import { ProductHasCommission } from '../../../../type/product-type';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import { showErrors, verifyClass } from '../../../../utils/forms/VerifyForms';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';


type ProductCommissionModalProps = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    product?: string,
    type?: string,
    commission?: ProductHasCommission,
    onAdd?: (commission: ProductHasCommission) => void
}

const productCommissionSchema = Yup.object().shape({
    powerFrom: Yup.number().min(0),
    powerTo: Yup.number().min(1, 'La potencia hasta debe ser superior a 1').moreThan(Yup.ref('powerFrom'), 'La potencia hasta debe ser superior a la potencia desde'),
    consumptionFrom: Yup.number().min(0).nullable(),
    consumptionTo: Yup.number().min(1, 'El consumo hasta debe ser superior a 1').nullable().moreThan(Yup.ref('consumptionFrom'), 'El consumo hasta debe ser superior al consumo desde'),
    commission: Yup.number().required('Campo requerido'),
    points: Yup.number().required('Campo requerido'),
    description: Yup.string().nullable()
});

export const ProductCommissionModal: React.FC<ProductCommissionModalProps> = ({ isOpen, setIsOpen, product, type, commission, onAdd }) => {
    const mode = commission ? 'edit' : 'create';

        const formik = useFormik({
            enableReinitialize: true,
            validationSchema: productCommissionSchema,
            initialValues: {
                product: commission?.product ? commission.product : product,
                powerFrom: commission?.powerFrom || '',
                powerTo: commission?.powerTo || '',
                consumptionFrom: commission?.consumptionFrom || null,
                consumptionTo: commission?.consumptionTo || null,
                commission: commission?.commission || '',
                points: commission?.points || '',
                description: commission?.description || ''
            },
            onSubmit: (values) => {
               if (onAdd !== undefined) {
                    onAdd(values as ProductHasCommission);
                    formik.resetForm();
               }
            },
        });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Servicio'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{mode == "create" ? 'Añadir Comisión' : 'Editar Comisión'}</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel label='Potencia desde' className={`col-md-3`}>
                            <Input id="powerFrom" type="number" min={0} step={0.01} className={verifyClass(formik, 'powerFrom')} required value={formik.values.powerFrom} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {showErrors(formik, 'powerFrom')}
                        </FormGroup>
                        <FormGroup requiredInputLabel label='Potencia hasta' className={`col-md-3`}>
                            <Input id="powerTo" type="number" min={0} step={0.01} className={verifyClass(formik, 'powerTo')} required value={formik.values.powerTo} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {showErrors(formik, 'powerTo')}
                        </FormGroup>
                        <FormGroup requiredInputLabel label='Consumo desde' className={`col-md-3`}>
                            <Input id="consumptionFrom" type="number" min={0} step={0.01} className={verifyClass(formik, 'consumptionFrom')} value={formik.values.consumptionFrom || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {showErrors(formik, 'consumptionFrom')}
                        </FormGroup>
                        <FormGroup requiredInputLabel label='Consumo hasta' className={`col-md-3`}>
                            <Input id="consumptionTo" type="number" min={0} step={0.01} className={verifyClass(formik, 'consumptionTo')} value={formik.values.consumptionTo || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {showErrors(formik, 'consumptionTo')}
                        </FormGroup>
                        <FormGroup requiredInputLabel id='commission' label='Comisión' className='col-md-6'>
                            <InputGroup>
                                <Input id="commission" type="number" min={0} step={0.01} className={verifyClass(formik, 'commission')} required value={formik.values.commission} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                <InputGroupText>
                                    €
                                </InputGroupText>    
                                {showErrors(formik, 'commission')}
                            </InputGroup>
                        </FormGroup>
                        <FormGroup requiredInputLabel id='points' label='Puntos' className='col-md-6'>
                            <Input id="points" type="number" min={0} step={0.01} className={verifyClass(formik, 'points')} required value={formik.values.points} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {showErrors(formik, 'points')}
                        </FormGroup>
                    </div>
                    <div className='row g-4'>
                        <FormGroup id='description' label='Descripción:' className='col-md-12 pt-2'>
                            <Textarea id='description' value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}></Textarea>
                            {showErrors(formik, 'description')}
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button color='primary' type="submit">
                        {mode == "create" ? 'Añadir' : 'Editar'} Comisión
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}