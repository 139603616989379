import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import { Client } from "../../type/client-type";
import { ClientService } from "../../services/clients/clientService";
import { Link } from "react-router-dom";
import useHandleErrors from "../../hooks/useHandleErrors";
import CompleteOperationForm from "./CompleteOperationForm";

type CreateOperationModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    clientId: string
    onOperationCreate?: (operation: any) => void;
}

const CreateOperationModal: React.FC<CreateOperationModalProps> = ({ visible, setVisible, clientId, onOperationCreate }) => {

    // SERVICES
    const clientService = new ClientService()

    // STATE
    const [client, setClient] = useState<Client | null>(null)
    const {handleErrors} = useHandleErrors();

    // ACTIONS
    const _getClient = async () => {
        if (clientId == null) return
        let response = (await clientService.getClientById(clientId)).getResponseData()
        if (response.success) {
            setClient(response.data)
        }
    }

    useEffect(() => {
        _getClient()
    }, [clientId])

    return (
        <>
            <Modal id="create-operation-modal" setIsOpen={setVisible} size={'xl'} isOpen={visible} isStaticBackdrop>
                <ModalHeader setIsOpen={setVisible}>
                    <ModalTitle id={'cnae-modal-selector'} tag="h3" className="fw-bold">
                        Nuevo contrato para <Link to={`/clients/${clientId}/show`} target="_blank" title="Ver cliente en una pestaña nueva">{client?.name}</Link>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="p-4">
                    <CompleteOperationForm clientId={clientId} onOperationCreate={onOperationCreate}></CompleteOperationForm>
                </ModalBody>
            </Modal>
        </>
    )
}


export default CreateOperationModal
