import { NewService, Service, UpdateService } from "../../type/service-type";
import { RestServiceConnection } from "../restServiceConnection";

const SERVICE_ENDPOINT = '/services';

export class ServiceService extends RestServiceConnection {
    
    createService = async (service: NewService) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/create',
            data: service,
        }, true);
        return this;
    }

    editService = async (values: UpdateService) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getServices = async ( filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    getServiceById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: SERVICE_ENDPOINT + `/get/${id}`,
        }, true);
        return this;
    }

    toggleServiceStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/toggle',
            data: {
                service: id,
                active: status
            }
        }, true);
        return this;
    }

    deleteService = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/delete',
            data: {
                service: id
            }
        }, true);
        return this;
    }
}