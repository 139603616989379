import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { CirclePicker, CompactPicker, SliderPicker } from 'react-color';
import { Operation, OperationRequestSMS } from "../../../../type/operation-type";
import { request } from "http";
import * as Yup from 'yup';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import Button from "../../../../components/bootstrap/Button";
import { OperationService } from "../../../../services/operations/operationService";
import ReactSelect from "react-select";
import { Input } from "react-select/animated";
import PhoneInput from "react-phone-input-2";
import { verifyClass, showErrors } from "../../../../utils/forms/VerifyForms";

export interface MForm {
    message: string;
    telephone: string;
}

interface ModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    onClose?: (message?: string) => void;
    requestSMS: OperationRequestSMS;
    availableTelephones: Array<any>;

}

const OperationSMSModal: FC<ModalProps> = ({ isOpen, setIsOpen, mode, onClose, requestSMS, availableTelephones }) => {

    const [selectedTelephone, setSelectedTelephone] = useState<any>({ value: requestSMS.telephone, label: requestSMS.telephone });

    const update = async (values: any) => {
        try {
            let response = await (await (new OperationService()).updateSMSRequest(values)).getResponseData();
            if (response) {
                setIsOpen(false);
                onClose && onClose("Cambios guardados correctamente");
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            message: Yup.string().required('El mensaje es requerido'),
            telephone: Yup.string().required('El telefono es requerido'),
            sms_request: Yup.string().required('El id de la solicitud es requerido'),
            custom_telephone: Yup.string().nullable()
        }),
        initialValues:
        {
            message: requestSMS.message,
            telephone: requestSMS.telephone,
            sms_request: requestSMS.id,
            custom_telephone: null
        }
        ,
        onSubmit: (values) => {
            
            /** Override telephone */
            if (values.custom_telephone != null) {
                values.telephone = values.custom_telephone;
            }

            update(values);
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Estado de Accion'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_status'>Editar Solucitud de SMS</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4 mt-1'>
                        <FormGroup id='telephone' label='Teléfonos relacionados' className='col-md-6' >
                            <ReactSelect value={selectedTelephone} options={availableTelephones} onChange={(newValue: any) => {
                                setSelectedTelephone(newValue);
                                formik.setFieldValue('telephone', newValue.value);
                            }} />
                        </FormGroup>

                        <FormGroup id='custom_telephone' label='Personalizado' className='col-md-6' >
                            <PhoneInput
                                country={'es'}
                                onlyCountries={['es']}
                                autoFormat={false}
                                countryCodeEditable={false}
                                onChange={(phone: string) => {
                                    formik.setFieldValue('custom_telephone', "+" + phone);
                                }}
                                value={formik.values.custom_telephone} inputClass={verifyClass(formik, 'custom_telephone')} />
                            {showErrors(formik, 'custom_telephone')}
                        </FormGroup>
                       
                    </div>
                    <div className='row g-4 mt-1'>
                        <FormGroup id='message' label='Mensaje' className='col-md-12' >
                            <Textarea rows={10} value={formik.values.message == null ? '' : formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        </FormGroup>
                    </div>
                    
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon="Save" color='primary' type="submit"> Editar </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default OperationSMSModal;