import { FiltersProvider } from "../../components/providers/FiltersProvider";
import ExpenseList from "./expense-list/ExpenseList";

const ExpenseListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <ExpenseList />
        </FiltersProvider>
      </>
    );
  };

export default ExpenseListWrapper ;