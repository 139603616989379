import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Client } from "../../../type/client-type";
import { ClientService } from "../../../services/clients/clientService";
import ClientForm from "../ClientForm";

const ClientEdit: FC = () => {

	const navigate = useNavigate();

	const { id = '' } = useParams<{ id: string }>();

	const fetchClient = useCallback(async () => {
		const companyService = new ClientService();
		const response = await companyService.getClientById(id as string);
		return response.getResponseData() as Client;
	  }, [id]);
	
	  const [data] = useFetch(fetchClient);
	  const [error, setError] = useState<string>('');
	  const [loading, setLoading] = useState<boolean>(false);
	
	
	const handleUpdate = async (values: any) => {    
			try {
				setLoading(true)			
				let response = await (await (new ClientService()).updateClient(values)).getResponseData();
				if(response.success) {
					toast.success(response.message);
					navigate(-1);
				} else {
					toast.error(response.message);
					setError(response.message);
				}
			} catch (error: any) {
				toast.error('Error al editar el Cliente');      
			}finally{
				setLoading(false);
			}
		
	  };
	
	  const getContent = () => {
		if (loading) return <Spinner />;
	
		if (error) return <div> Error </div>;
	
		if (data !== null) {
		  const clientData = {
				...data,
		  };      
		  
		  return (
			<ClientForm isLoading={false} submit={handleUpdate} clientData={clientData} />
		  );
		}

		return <div className="text-center"><Spinner /></div>;
	  };
	
	
	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				{getContent()}
			</Page>
		</Fragment>
	)
}

export default ClientEdit;