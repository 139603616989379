import { FC, useState } from "react";
import { NewOffice, Office } from "../../../type/office-type";
import { useFormik } from "formik";
import { OfficeService } from "../../../services/ofiice/officeService";
import { toast } from "react-toastify";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Checks from "../../../components/bootstrap/forms/Checks";


interface ProductModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    setReadMode(...args: unknown[]): unknown;
    office: Office | any;
}

const OfficeModal: FC<ProductModalProps> = ({ isOpen, setIsOpen, readMode, setReadMode, office }) => {
    const [modal, setModal] = useState(false);
    const [loadign, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: office?.name,
            description: office?.description || null,
            isIndividual : office?.isIndividual || false,
        },
        onSubmit: (values) => {
            if (office === null) {
                createOffice(values as NewOffice);
            } else {
                updateOffice(values as Office)
            }
        },
    });


    const createOffice = async (values: NewOffice) => {
        try {
            setLoading(true);
            let response = await (await (new OfficeService()).createOffice(values)).getResponseData();
            if (response.success) {
                toast.success('Oficina creada con éxito');
            } else {
                toast.error('Error al crear la Oficina');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al crear la Oficina');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const updateOffice = async (values: any) => {
        try {
            setLoading(true);
            values.office = office.id;
            let response = await (await (new OfficeService()).editOffice(values)).getResponseData();
            if (response.success) {
                toast.success('Oficina editada con éxito');
            } else {
                toast.error('Error al editar la Oficina');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al editar la Oficina');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true}  size={!modal? 'xl':'sm'} titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode ? (office ? 'Editar' : 'Nueva') : 'Ver'} Oficina</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-4'>
                                <Input disabled={readMode} value={formik.values.name || ''} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='description' label='Breve descripción' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.description || ''} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup className='col-md-2 d-flex justify-content-center align-items-center'>
                                <Checks disabled={readMode} checked={formik.values.isIndividual || ''} onChange={
                                    (e: any) => formik.setFieldValue('isIndividual', e.target.checked)
                                } label={'Independiente'} tmuted name={'isIndividual'} key={formik.values.isIndividual}
                                className={'fs-5'} labelClassName="fw-normal fs-5"/>
                            </FormGroup>
                        </div>
                    </ModalBody>
                    
                    {(!readMode) ?
                        <ModalFooter className='px-4 pb-4'>
                            <Button color='primary' type="submit" icon={loadign ? "" : "Save"} isDisable={loadign}>
                                {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                            </Button>
                        </ModalFooter>
                        : <></>}
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default OfficeModal;