import { Fragment, useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, {CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { OperationService } from "../../../services/operations/operationService";
import { OperationApiResponse } from "../../../type/operation-type";
import Button from "../../../components/bootstrap/Button";
import OperationTableComponent from "../../../components/table/Operation/OperationTableComponent";
import PendingTaskFilters from "./pendingTask-options/PendingTaskFilters";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const OperationsPendingTasks = () => {

  let defaultColumns = ["company", "clientName", "clientDni", "province", "locality", "clientPhone","operationCount"];

  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);
  
  const fetchOperations = useCallback(async () => {
    const operationService = new OperationService();

    const response = await operationService.getAgentPendingTasksByClient(filters);
    return response.getResponseData() as OperationApiResponse;
  }, [filters]);


  const [data, loading, error, refetch] = useFetch(fetchOperations);

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardLabel icon="Inventory2" iconColor="info">
              <CardTitle>Listado de Contratos Pendientes</CardTitle>
            </CardLabel>
            <SubheaderSeparator />
            <Button
              color="light"
              icon="Autorenew"
              isLight
              onClick={() => {
                refetch()
              }}
            >
            </Button>
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <PendingTaskFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={false}>
        {data ? (
          <OperationTableComponent 
            data={data}
            error={error}
            filters={filters}
            updateFilters={updateFilters}
            updateFilterOrder={updateFilterOrder}
            updatePage={updatePage}
            defaultLimit={filters.limit || 50}
            defaultOrder={filters.filter_order || undefined}
            updatePageSize={updatePageSize}
            classNameProps="table table-hover p-3 fs-8 min-h-500"
            visibleActions={["viewClient"]}
            refetch={() => {
                refetch();
            }}
            visibleColums={visibleColumns}
          /> ) : null}
        </Card>
      </Page>
    </Fragment>
  );
};

export default OperationsPendingTasks;
