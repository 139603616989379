import React, { ChangeEvent, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { CustomTable } from '../../../../components/table/CustomTable';
import { toast } from 'react-toastify';
import { CampaignService } from '../../../../services/campaigns/campaignService';
import moment from 'moment';
import { UserService } from '../../../../services/users/userService';
import StatusDropdown from '../../../../components/StatusDropdown';
import CampaignUserSelectorModal from '../../../../components/selectors/user_selector/CampaignUserSelectorModal';
import { OfficeService } from '../../../../services/ofiice/officeService';
import CampaignOfficeSelectorModal from '../../../../components/selectors/office_selector/CampaignOfficeSelectorModal';

interface OffciesTableWizardProps {
    formik: any; // Añade el tipo correcto para formik
    reloadCampaign: Function;

}

const OffciesTableWizard: React.FC<OffciesTableWizardProps> = ({ formik, reloadCampaign }) => {

    const [modalUs, setModalUs] = useState(false);
    const [selectedRowsUsr, setSelectedRowsUsr] = useState<any[]>([]);
    const [selectAllUsr, setSelectAllUsr] = useState(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const handleActionSelected = async (array: any, selectArr: any[], setSelectArr: any) => {
        try {
            for (const rowId of selectArr) {
                const response = (await new CampaignService().removeOfficeFromCampaign(formik.values.id, rowId.id)).getResponseData();
            }
            toast.success('Oficina borrado con éxito');
            reloadCampaign();
            setSelectArr([]);
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleCheckboxChangeUsr = (
        id: string,
        event: ChangeEvent<HTMLInputElement>,
        element: any,
    ) => {
        const { checked } = event.target;
        let selectedItemsCopy = [...selectedRowsUsr];
        if (checked) {
            selectedItemsCopy.push(element);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item.id === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedRowsUsr(selectedItemsCopy);
    };

    const handleSelectAllUsr = (checked: boolean) => {
        setSelectAllUsr(!selectAllUsr);
        if (!selectAllUsr) {
            setSelectedRowsUsr(formik.values.users ? formik.values.users : []);
        } else {
            setSelectedRowsUsr([]);
        }
    };

    const _oncloseModalOf = async (userId: string) => {
        const response = (await new CampaignService().addOfficeToCampaign(formik.values.id, userId)).getResponseData()
        if (response.success == true) {
            toast.success(response.message);
            reloadCampaign();
        } else {
            response.data.errors.forEach((error: any) => {
                toast.error(error.message);
            });
            return;
        }
    }

    return (
        <>
            <Card>
                <CardHeader className='rounded-1 mb-0' borderSize={1}>
                    <CardLabel icon='Groups' iconColor='info'>
                        <CardTitle>Oficinas</CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button color="primary" icon="Add" onClick={() => setModalUs(true)}>Añadir Oficinas</Button>
                        <Button color="danger" icon="DeleteForever" onClick={() => handleActionSelected(formik.values.offices, selectedRowsUsr, setSelectedRowsUsr)}>Borrar seleccionados</Button>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <CustomTable
                        data={formik.values.offices ? formik.values.offices : null}
                        className={"table table-hover"}
                        selectableItems={true}
                        onSelectAllItems={handleSelectAllUsr}
                        onChangeCheckedItems={handleCheckboxChangeUsr}
                        columns={[
                            {
                                name: "Nombre",
                                keyValue: "name",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.office?.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Descripción",
                                keyValue: "description",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.office?.description}
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            {modalUs && <CampaignOfficeSelectorModal isOpen={modalUs} setIsOpen={setModalUs} onClose={_oncloseModalOf} isRequired />}
        </>
    );
};

export default OffciesTableWizard;
