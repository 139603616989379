import { FC, useEffect, useState } from "react";
import { DashboardService } from "../../../../services/dashboard/DashboardService";
import { useUserProfile } from "../../../../hooks/useListData";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import Spinner from "../../../../components/bootstrap/Spinner";

interface IOperationPointProps {
  date: any;
  filterByUser?: boolean;
  agent?: string;
  office?: string;
  business?: string;
  isAgentDashboard?: boolean;
}

const DashboardOperationPoints: FC<IOperationPointProps> = ({
  date,
  filterByUser,
  agent,
  office,
  business,
  isAgentDashboard,
}) => {
  const [data, setData] = useState<any>(null);

  const user = useUserProfile();

  const getOperationPointsBetweenDates = async () => {
    const dashboardService = new DashboardService();
    try {
      if (date.start !== "" && date.end !== "") {
        const response = await (
          await dashboardService.getOperationPointsBetweenDates({
            filter_filters: {
              dateFrom: date.start,
              dateTo: date.end,
              ...(filterByUser ? { agent: user?.id || "" } : {}),
              ...(agent ? { agent: agent } : {}),
              ...(office ? { office: office } : {}),
              ...(business ? { business: business } : {}),
            },
          })
        ).getResponseData();
        if (response?.success) {
          setData(response?.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (date.start !== "" && date.end !== "") {
      getOperationPointsBetweenDates();
    }
  }, [date , agent, office, business]);

  useEffect(() => {
    if (filterByUser && user?.id !== "" && date.start !== "" && date.end !== "") {
      getOperationPointsBetweenDates();
    }
  }, [user]);

  return (
    <>
      {data ? (
          <div className={`row gap-1 d-flex justify-content-around align-items-center ${isAgentDashboard ? '' : 'h-100'}`}>
            <Card className={`col-xxl-12 bg-l25-info border border-info`} style={{minHeight: '130px'}} >
              <CardBody className="">
                  <div className="flex-grow-1 h-100">
                    <div className="fs-5 fw-bold">
                      <Icon icon="Score" size={"2x"} className="me-1" color="info"/>
                      Brutos<small className="text-muted"> (ptos)</small>
                    </div>
                    <div className="fs-3 fw-bold d-flex justify-content-center mt-3">
                      {data.grossPoints.toFixed(2)}
                    </div>
                  </div>
              </CardBody>
            </Card>
            <Card className={`col-xxl-12 bg-l25-primary border border-primary`} style={{minHeight: '130px'}}>
              <CardBody className="">
                  <div className="flex-grow-1 h-100">
                    <div className="fs-5 fw-bold">
                      <Icon icon="Verified" size={"2x"} className="me-1" color="success"/>
                      Netos<small className="text-muted"> (ptos)</small>
                    </div>
                    <div className="fs-3 fw-bold d-flex justify-content-center mt-3">
                      {data.netPoints.toFixed(2)}
                    </div>
                  </div>
              </CardBody>
            </Card>
          </div>
      ) : (
        <div className="col-12 d-flex justify-content-center">
          <Spinner className="text-center text-primary" />
        </div>
      )}
    </>
  );
};

export default DashboardOperationPoints;
