import { FC, Fragment, useCallback, useContext, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Checks from "../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../components/bootstrap/Modal";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import Spinner from "../../../components/bootstrap/Spinner";
import {
  NewRole,
  Permission,
  PermissionGroup,
  PermissionsApiResponse,
} from "../../../type/role-type";
import Accordion, {
  AccordionItem,
} from "../../../components/bootstrap/Accordion";
import { Companies, CompaniesApiResponse } from "../../../type/company-type";
import { CompanyService } from "../../../services/companies/companyService";
import Select from "../../../components/bootstrap/forms/Select";
import { useFormik } from "formik";
import { RoleService } from "../../../services/auth/roleService";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

interface RoleCreateModalProps {
  isOpen: boolean;
  setIsOpen(...args: unknown[]): unknown;
  handleSuccessCreation(): void;
}

const RoleCreateModal: FC<RoleCreateModalProps> = ({ isOpen, setIsOpen, handleSuccessCreation }) => {
  
  const {userCan} = useContext(PrivilegeContext)
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<number[]>([]);
  const [loadign, setLoading] = useState(false);

  const fetchPermissions = useCallback(async () => {
    const permissionService = new PermissionService();
    const response = await permissionService.getPermissions();
    return response.getResponseData() as PermissionsApiResponse;
  }, []);

  const [permissions, fetchingPermissions, permissionError] =
    useFetch(fetchPermissions);

  const fetchCompanies = useCallback(async () => {
    const companyService = new CompanyService();
    if (!userCan('list', 'companies')) return ;
    const response = await companyService.getCompanies();
    return response.getResponseData() as CompaniesApiResponse;
  }, []);

  const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

  const createRole = async (values: NewRole) => {
    values.permissions = selectedPermissions;

    try {
      setLoading(true);
      let response = await (
        await new RoleService().createRole(values)
      ).getResponseData();
      setLoading(false);
      handleSuccessCreation();
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      company: "",
      permissions: [],
    },
    onSubmit: (values: NewRole) => {
      createRole(values);
    },
  });

  const getCompanyList = () => {
    if (companies as Companies) {
      return companies.companies.map((company: { id: any; name: any }) => {
        return {
          value: company.id,
          label: company.name,
        };
      });
    }
    return [];
  };

  const getContent = () => {
    if (fetchingPermissions || (userCan('list', 'companies') && fetchingCompanies))
      return (
        <div className="text-center">
          {" "}
          <Spinner />{" "}
        </div>
      );

    if (permissionError || (userCan('list', 'companies') && companyError)) return <div>Error</div>;

    return (
      <Fragment>
        <div className="row g-4">
          <FormGroup
            requiredInputLabel
            id="name"
            label="Nombre"
            className="col-md-6"
          >
            <Input
              required
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <FormGroup
            id="description"
            label="Breve descripción"
            className="col-md-6"
          >
            <Input
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </FormGroup>
          { userCan('list', 'companies') ? (
            <FormGroup
              requiredInputLabel
              id="company"
              label="Organización"
              className="col-md-6"
            >
              <Select
                required
                id="company"
                onChange={formik.handleChange}
                value={formik.values.company}
                ariaLabel="Default select example"
                placeholder="Elegir organización..."
                list={getCompanyList()}
              />
            </FormGroup>
          ) : <></>}
        </div>
        <div className="row mt-5">
          {permissions?.map((group: PermissionGroup, index: number) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-6 mb-5" key={index}>
                <Accordion id={group.name} isFlush activeItemId={group.id}>
                  <AccordionItem id={group.id} title={group.label}>
                    <>
                      <Checks
                        label="Seleccionar todos"
                        value="all"
                        checked={selectAll.includes( group.id )}
                        onChange={() => {
                          const list = group.permissions.map((item:Permission) => item.id);
                          if (selectAll.includes(group.id)) {
                            setSelectAll(selectAll.filter((id: number) => id !== group.id));
                            setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                          }else{
                            setSelectAll([ ...selectAll, group.id ]); 
                            setSelectedPermissions([...selectedPermissions.concat(list) ]);
                          }
                        }}
                      />
                    </>
                    {group.permissions.map( (permission: Permission, index: number) => {
                        return (
                          <div key={index}>
                            <Checks
                              label={permission.label}
                              value={permission.id}
                              checked={selectedPermissions.includes(
                                permission.id
                              )}
                              onChange={() => {
                                selectedPermissions.includes(permission.id)
                                  ? setSelectedPermissions(
                                      selectedPermissions.filter(
                                        (id: number) => id !== permission.id
                                      )
                                    )
                                  : setSelectedPermissions([
                                      ...selectedPermissions,
                                      permission.id,
                                    ]);
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                  </AccordionItem>
                </Accordion>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xl" titleId="Nuevo Rol">
      <ModalHeader setIsOpen={setIsOpen} className="p-4">
        <ModalTitle id="new_role">Nuevo Rol</ModalTitle>
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody className="px-4">{getContent()}</ModalBody>
        <ModalFooter className="px-4 pb-4">
          <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
            {loadign  ? <Spinner className="text-center"/> : "Guardar Rol"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default RoleCreateModal;
