import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { DocumentTypeService } from "../../../services/documents/documentTypeService";
import { DocumentTypeApiResponse } from "../../../type/document-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";
import DocumentTypeModal from "../documentType-create/DocumentTypeModal";
import {
  CheckCircleOutline,
  HighlightOff,
} from "../../../components/icon/material-icons";
import DocumentTypesFilters from "./documentType-options/DocumentTypeFilters";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import StatusDropdown from "../../../components/StatusDropdown";

export interface IDocumentTypeFilters {
  search?: string;
  name?: string;
  requiredDocument?: number;
}

const DocumentTypeList = () => {
  const { userCan } = useContext(PrivilegeContext);

  const [documentType, setDocumentType] = useState(null);
  const [modal, setModal] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);
  const [readMode, setReadMode] = useState(false);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  },[]);

  const fetchDocumentTypes = useCallback(async () => {
    const response = await new DocumentTypeService().getDocumentTypes(filters);
    return response.getResponseData() as DocumentTypeApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refetch] = useFetch(fetchDocumentTypes);

  const deleteDocumentType = async (id: string) => {
    let response = (
      await new DocumentTypeService().deleteDocumentType(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Tipo de Documento eliminado correctamente");
      updateFilters({ ...filters });
    } else toast.error("Error al eliminar el Tipo de Documento");
    updateFilters({ ...filters });
  };

  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setDocumentType(item);
    setModal(true);
  };

  const toggleDocumentTypeStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new DocumentTypeService().toggleClientStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Tipos de Documentos</CardTitle>
            <SubheaderSeparator />
            <Button
              color="light"
              icon="Add"
              className={`${userCan("manage_type", "documents") ? "" : "d-none"}`}
              isLight
              onClick={() => {
                openModal(null, false);
              }}
            >
              Añadir Tipo de Documento
            </Button>
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <DocumentTypesFilters
            updateFilters={updateFilters}
            resetFilters={resetFilters}
            filters={filters}
          />
        </SubHeaderRight>
      </SubHeader>
      <Card>
        <CardBody>
          <Fragment>
            {data ? (
              <CustomTable
                data={data?.documentTypes ? data.documentTypes : null}
                pagination={true}
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: (data as DocumentTypeApiResponse)
                    ? data.lastPage
                    : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                className={"table table-hover"}
                columns={[
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div
                          className="d-flex justify-content-center cursor-pointer text-primary fw-bold"
                          onClick={() => {
                            openModal(element, true);
                          }}
                        >
                          {element.name}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Descripción",
                    keyValue: "description",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center">
                          {element.description}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Orientado a",
                    keyValue: "entityType",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center">
                          {element.entityType}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Requerido",
                    keyValue: "requiredDocument",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold">
                          <div className="fs-7 text-center">
                            {element.requiredDocument ? (
                              <CheckCircleOutline
                                className="text-success"
                                width="30px"
                                height="30px"
                              />
                            ) : (
                              <HighlightOff
                                className="text-danger"
                                width="30px"
                                height="30px"
                              />
                            )}
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    name: "Estado",
                    keyValue: "active",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={"text-center"}>
                          <StatusDropdown
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            status={element.active}
                            change={toggleDocumentTypeStatus}
                            key={element.id}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    name: "Organización",
                    keyValue: "company",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: userCan("list", "expenses", true)
                      ? "text-center"
                      : "d-none",
                    isVisible: userCan("list", "expenses", true),
                    render: (element: any) => {
                      return (
                        <div
                          key={element.company?.id}
                          className="d-flex justify-content-center"
                        >
                          {element.company?.name}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Acciones",
                    className: "min-w-100px text-end",
                    isActionCell: true,
                  },
                ]}
                actions={[
                  {
                    title: "Ver",
                    buttonType: "icon",
                    iconColor: "text-primary",
                    iconPath: "/media/icons/duotune/general/gen021.svg",
                    additionalClasses: `text-primary ${
                      userCan("manage_type", "documents") ? "" : "d-none"
                    }`,
                    description: "Ver Tipo de Documento",
                    callback: (item: any) => {
                      openModal(item, true);
                    },
                  },
                  {
                    title: "Editar",
                    buttonType: "icon",
                    iconColor: "text-info",
                    iconPath: "/media/icons/duotune/general/gen055.svg",
                    additionalClasses: `text-primary ${
                      userCan("manage_type", "documents") ? "" : "d-none"
                    }`,
                    description: "Editar Tipo de Documento",
                    callback: (item: any) => {
                      openModal(item, false);
                    },
                  },

                  {
                    title: "Eliminar",
                    buttonType: "icon",
                    iconColor: "text-danger",
                    iconPath: "/media/icons/duotune/general/gen027.svg",
                    additionalClasses: `text-danger ${
                      userCan("manage_type", "documents") ? "" : "d-none"
                    }`,
                    description: "Eliminar Tipo de Documento",
                    callback: (item: any) => {
                      handleConfirmationAlert({
                        title: "Eliminar gasto",
                        text: "¿Está seguro que desea eliminar el tipo de documento todo el contenido relacionado a este tipo de documento será eliminado?",
                        icon: "warning",
                        onConfirm: () => {
                          deleteDocumentType(item.id);
                        },
                      });
                    },
                  },
                ]}
              />
            ) : (
              <div className="text-center d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </Fragment>
        </CardBody>
      </Card>
      {modal && (
        <DocumentTypeModal
          isOpen={modal}
          setIsOpen={setModal}
          documentType={documentType}
          readMode={readMode}
        />
      )}
    </Fragment>
  );
};

export default DocumentTypeList;
