import { FC, useCallback } from "react";
import { ClientService } from "../../../services/clients/clientService";
import { CustomTable } from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Icon from "../../../components/icon/Icon";

interface ClientCupsCardProps {
  idMain: string;
}

const ClientCupsCard: FC<ClientCupsCardProps> = ({ idMain }) => {

  // FETCH FUNCTION

  /**
   * 
   * @EN This function is responsible for fetching the client's cups
   * @ES Esta función se encarga de obtener los cups del cliente
   * 
   * @returns Fetch the client's cups
   */

  const fetchClientCups = useCallback(async () => {
    const clientService = new ClientService();
    const response = await (
      await clientService.getClientCupsAll(idMain)
    ).getResponseData();
    if (response.success) {
      return response;
    }
  }, [idMain]);

  const [data, loading, error] = useFetch(fetchClientCups);

  // RENDER

  return <div>{
    data ? (
      <CustomTable
          data={data}
          className={"table table-hover fs-7"}
          columns={[
            {
              name: "Tipo de suministro",
              keyValue: "cups",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.products[0]?.product?.productType?.name || "-"}
                  </div>
                );
              },
            },
            {
              name: "CUPS (Código de Suministro)",
              keyValue: "cups",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.products[0]?.cups || "-"}
                    {row.client.id !== idMain && (
                      <Tooltips title={`El CUPS no pertenece al cliente principal pertece a ${row.clientName}`}>
                        <span>
                          <Icon icon={'EmojiPeople'} color={"warning"} size={'2x'}/>
                        </span>
                      </Tooltips>
                    )}
                  </div>
                );
              },
            },
            
            {
              name: "Dirección",
              keyValue: "clientAddress",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.products[0] && (
                      <>
                        {row.products[0]?.address}, {row.products[0]?.postalcode}, {row.products[0]?.town}, {row.products[0]?.province}
                      </>
                    )}
                    
                  </div>
                );
              },
            },
            {
                name: "Destinado a",
                keyValue: "operationFor",
                className: "text-center",
                cellClassName: "text-center",
                render: (row) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      {row.operationFor || "-"}
                    </div>
                  );
                },
              },
            {
              name: "Consumo (kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (row) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {row.products[0]?.consumption || "-"}
                  </div>
                );
              },
            },
            {
              name: "P1 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP1 || "-"} 
                  </div>
                );
              },
            },
            {
              name: "P2 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP2 || "-"} 
                  </div>
                );
              },
            },
            {
              name: "P3 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP3 || "-"} 
                  </div>
                );
              },
            },
            {
              name: "P4 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP4 || "-"} 
                  </div>
                );
              },
            },
            {
              name: "P5 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP5 || "-"} 
                  </div>
                );
              },
            },
            {
              name: "P6 (Kw)",
              keyValue: "consumption",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center align-items-center">
                    {element.products[0]?.powerP6 || "-"} 
                  </div>
                );
              },
            }
          ]}
        />
      ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
    }</div>;
};

export default ClientCupsCard;
