import { useFormik } from "formik";
import { FC, Fragment, useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../components/bootstrap/Accordion";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardFooter, CardFooterRight } from "../../components/bootstrap/Card";
import Checks from "../../components/bootstrap/forms/Checks";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import ErrorMessage from "../../components/ErrorMessage";
import useFetch from "../../hooks/useFetch";
import { PermissionService } from "../../services/auth/permissionService";
import { RoleService } from "../../services/auth/roleService";
import { NewRole, Permission, PermissionGroup, RolesApiResponse } from "../../type/role-type";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import Select from "../../components/bootstrap/forms/Select";
import { CompanyService } from "../../services/companies/companyService";
import { Companies, CompaniesApiResponse } from "../../type/company-type";

interface CreateFormProps {
    submit: (values: RoleForm) => void;
    roleData?: any;
}

/* export interface RoleForm {
    name: string;
    description: string;
    company: string;
    permissions: string[];
} */

export interface RoleForm {
    name:        string;
    description: string;
    permissions: RolePermissions;
    company:     RoleCompany;
}

export interface RoleCompany {
    id:        string;
    cif:       string;
    name:      string;
    address:   string;
    active:    boolean;
}

export interface RolePermissions {
    users:     string[];
    companies: string[];
    roles:     string[];
}

const roleInitialValues: RoleForm = {
    name: '',
    description: '',
    company: {
        id: '',
        cif: '',
        name: '',
        address: '',
        active: false,
    },
    /* active: '', */
    permissions: {
        users: [],
        companies: [],
        roles: [],
    },
}

const RoleForm: FC<CreateFormProps> = ({ submit, roleData }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState<number[]>([]);
    const {userCan} = useContext(PrivilegeContext);


    const fetchPermissions = useCallback(async () => {
        const permissionService = new PermissionService();
        const response = await permissionService.getPermissions();

        const allPermissionsData = response.getResponseData().data;

        // find permissions selected in roleData
        const defaultSelectedPermissions = allPermissionsData.reduce((acc: number[], group: PermissionGroup) => {
            let permissionIdsOfGroup: number[] = [];
            group.permissions.forEach((permission: Permission) => {
                if (roleData?.permissions[group.name]?.includes(permission.action)) {
                    permissionIdsOfGroup.push(permission.id);
                }
            });
            return [...acc, ...permissionIdsOfGroup];
        }, []);


        setSelectedPermissions(defaultSelectedPermissions);

        return response.getResponseData() as RolesApiResponse;

    }, []);


    const [permissionData, loadingPermission, errorPermission, fetchPermissionData] = useFetch(fetchPermissions);




    const showPermissions = () => {
        if (loadingPermission) return (<div className="text-center">{" "}<Spinner />{" "}</div>);

        if (errorPermission) return <ErrorMessage error={errorPermission} />;

        if (permissionData) {
            return (
                <div className="row w-75">
                        {permissionData?.map((group: PermissionGroup, index: number) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5" key={index}>
                                    <Accordion id={group.name} isFlush activeItemId={group.id}>
                                        <AccordionItem id={group.id} title={group.label}>
                                            <Fragment>
                                                <Checks
                                                    label="Seleccionar todos"
                                                    value="all"
                                                    checked={selectAll.includes(group.id)}
                                                    onChange={() => {
                                                        const list = group.permissions.map((item: Permission) => item.id);
                                                        if (selectAll.includes(group.id)) {
                                                            setSelectAll(selectAll.filter((id: number) => id !== group.id));
                                                            setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                                                        } else {
                                                            setSelectAll([...selectAll, group.id]);
                                                            setSelectedPermissions([...selectedPermissions.concat(list)]);
                                                        }
                                                    }}
                                                />
                                            </Fragment>

                                            {group.permissions.map((permission: Permission, index: number) => {

                                                return (
                                                    <div key={index}>
                                                        <Checks
                                                            label={permission.label}
                                                            value={permission.id}
                                                            name={`permissions[]`}
                                                            /* defaultValue={permission.id} */
                                                            checked={selectedPermissions.includes(
                                                                permission.id
                                                            )}
                                                            onChange={() => {
                                                                selectedPermissions.includes(permission.id)
                                                                    ? setSelectedPermissions(
                                                                        selectedPermissions.filter(
                                                                            (id: number) => id !== permission.id
                                                                        )
                                                                    )
                                                                    : setSelectedPermissions([
                                                                        ...selectedPermissions,
                                                                        permission.id,
                                                                    ]);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            },
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </div>

                            );
                        })}
                    </div>
            )
        }
    }

    const { id } = useParams<{ id: string }>();

    const handleEditRole = async (values: any) => {

        values.role = id;
        values.permissions = selectedPermissions;
        values.company = values.company;
        if (values && id) {
            try {
                setIsLoading(true)
                const response = await (await new RoleService().editRole(values)).getResponseData();

                if (response.success) {
                    setIsLoading(false)
                    navigate('/roles');
                    setTimeout(() => {
                        toast.success('Rol editado correctamente');
                    }, 500);
                } else {
                    setIsLoading(false)
                    throw new Error(response.message);
                }
            } catch (e: any) {
                console.error(e);
                setIsLoading(false)
                toast.error(e.message || 'Error al editar el rol');
            }
        }
    }

    const formik = useFormik({
        initialValues: roleData ? roleData : roleInitialValues,
        onSubmit: handleEditRole,
    });

    const fetchCompanies = useCallback(async () => {
        const companyService = new CompanyService();
        if (!userCan('list', 'companies')) return ;
        const response = await companyService.getCompanies();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

    const getCompanyList = () => {
        if (companies as Companies) {
          return companies.companies.map((company: { id: any; name: any }) => {
            return {
              value: company.id,
              label: company.name,
            };
          });
        }
        return [];
      };

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <CardBody isScrollable={false}>
                    <div className="row">
                        <div /* className='col-md-6' */>
                            <CardBody className="d-flex flex-column">
                                <div className="row d-flex justify-content-around ">
                                    <FormGroup label='Nombre' className={`${userCan('list', 'companies') ? 'col-md-4' : 'col-md-6'}`}>
                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} />
                                    </FormGroup>
                                    <FormGroup label='Breve descripción' className={`${userCan('list', 'companies') ? 'col-md-4' : 'col-md-6'}`}>
                                        <Input id='description' onChange={formik.handleChange} value={formik.values.description} />
                                    </FormGroup>
                                    { userCan('list', 'companies') ? (
                                        <FormGroup
                                        requiredInputLabel
                                        id="company"
                                        label="Organización"
                                        className="col-md-4"
                                        >
                                        <Select
                                            required
                                            id="company"
                                            onChange={formik.handleChange}
                                            value={formik.values.company}
                                            ariaLabel="Default select example"
                                            placeholder="Elegir organización..."
                                            list={getCompanyList()}
                                        />
                                        </FormGroup>
                                    ) : <></>}
                                </div>
                                <div className="row mt-5 d-flex justify-content-center">
                                    {/* <FormGroup label='Estado' className='col-md-6'>
                                        <Input id='active' onChange={formik.handleChange} value={formik.values.active} />
                                    </FormGroup> */}
                                    {showPermissions()}
                                </div>
                            </CardBody>
                        </div>
                    </div>

                </CardBody>
                <CardFooter>
                    <CardFooterRight>
                        <Button type="submit" size='lg' color='primary' isDisable={isLoading}>
                            {isLoading ? <Spinner /> : `Guardar cambios`}
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default RoleForm;