import React, { FC } from 'react';
import Alert, { AlertHeading } from '../../../../components/bootstrap/Alert';

interface ICommonDashboardAlertProps {
	name: string;
	points: number;
}

const CommonDashboardAlert: FC<ICommonDashboardAlertProps> = ({name, points}) => {
	return (
		<Alert
			icon='Verified'
			isLight
			color='primary'
			borderWidth={0}
			className='shadow-3d-primary'
			isDismissible>
			<AlertHeading tag='h2' className='h4'>
				Felicidades! 🎉
			</AlertHeading>
			<span><strong>{name}</strong> es líder en este ranking con <strong>{points} puntos</strong></span>
		</Alert>
	);
};

export default CommonDashboardAlert;
