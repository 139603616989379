import { NewService, Service, UpdateService } from "../../type/service-type";
import { RestServiceConnection } from "../restServiceConnection";

const SERVICE_ENDPOINT = '/sms-matrix';

export class SMSMatrixService extends RestServiceConnection {


    list = async ( filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true);
        return this;
    }

    import = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SERVICE_ENDPOINT + '/import',
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

}