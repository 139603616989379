import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import UserForm from "../UserForm";
import { UserService } from "../../../services/users/userService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {usersMenu} from "../../../menu";


const CreateUser: FC = () => {

    const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const handleCreation = async (values: any) => {
		setLoading(true);

		try {
			setLoading(true)
			let response = await (await (new UserService()).createUser(values)).getResponseData();
			if(response.success) {
				toast.success(response.message);
				navigate(usersMenu.users.path, {replace: true})
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al crear Usuario');      
		}finally{
			setLoading(false);
		}
		
	}

    return (
        <Fragment>
			<SubHeader>
				<SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/users', {replace: true})}>
                    </Button>
				</SubHeaderLeft>    
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={true}>
					<CardHeader borderSize={1}>
						<CardLabel icon='PersonAdd' iconColor='info'>
							<CardTitle>Nuevo Usuario</CardTitle>
						</CardLabel>
					</CardHeader>
					<UserForm submit={handleCreation} isLoading={loading}/>
				</Card>
			</Page>
		</Fragment>
    )
}

export default CreateUser;
