import React, { useState } from "react";
import Alert, { AlertHeading } from "../bootstrap/Alert";
import { toast } from "react-toastify";
import { useLoader } from "../loader/LoaderProvider";
import { SMSMatrixService } from "../../services/sms_matrix/SMSMatrixService";
import Input from "../bootstrap/forms/Input";
import Button from "../bootstrap/Button";

interface SMSMatrixImportProps {
    onSubmit: Function;
    businessId: string;
}

export const SMSMatrixImport: React.FC<SMSMatrixImportProps> = ({onSubmit, businessId}) => {

    const [importFile, setImportFile] = useState<any>(null);
    const {showLoading, hideLoading} = useLoader()

    const _handleFileUpload = (e: React.ChangeEvent<any>) => {
        const file: File|null = e.target.files[0]
        setImportFile(file)
    }

    const _handleSubmit = async () => {
        if(!importFile) {
            toast.error('Debe seleccionar un archivo para importar')
            return
        }
        const formData = new FormData()
        formData.append('file', importFile)
        formData.append('business', businessId)
        showLoading('Importando matrix de SMS', (<p>Por favor espera...</p>))
        const response = (await (new SMSMatrixService()).import(formData)).getResponseData()
        hideLoading()
        if(response.success) {
            toast.success('Productos importados correctamente')
            onSubmit();
        } else {
            toast.error('Error al importar productos')
        }
        
    }

    return (
        <>
            <Alert
                icon='Info'
                isLight
                color='info'
                borderWidth={0}
                className='shadow-3d-info'
                isDismissible>
                <AlertHeading tag='h2' className='h4'>
                    Importante!
                </AlertHeading>
                <section className="import-information d-flex flex-column justify-center">
                    <div className="import-information-text">
                        <p className="text-justify">
                            La importación de la matriz de SMS es una herramienta que te permite importar una matriz de SMS de una compañía.
                            Esta funcionalidad recogerá los valores de cada contrato para generar un SMS a corde a las características del producto seleccionado.
                        </p>
                    </div>
                    <div className="import-link text-center">
                        <a  className="d-inline-block btn btn-sm btn-primary-link " href="/excel/templates/sms_matrix.xlsx" download>Descargar plantilla</a>
                    </div>
                </section>
            </Alert>

            <div className='d-flex w-50 m-auto'>
                <Input type='file' onChange={(e: React.ChangeEvent<any>) => _handleFileUpload(e)}
                    autoComplete='photo' />
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <Button color="primary" icon="Upload" onClick={_handleSubmit}>Importar</Button>
            </div>
        </>
    );
}