import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import { Company } from "../../../type/company-type";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const OrganizationEdit: FC = () => {

	const navigate = useNavigate();

	const { id = '' } = useParams<{ id: string }>();

	const fetchUser = useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getCompanyById(id as string);
		return response.getResponseData() as Company;
	}, [id]);

	const [data] = useFetch(fetchUser);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);


	const handleUpdate = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		formData.append('company', data['id']);

		for (let key in values) {
			if (!values[key]) {
				formData.append(key, '');
			} else {
				formData.append(key, values[key])
			}
		}


		try {
			setLoading(true)

			let response = await (await (new CompanyService()).editCompany(formData, values.logo instanceof File)).getResponseData();
			if (response.success) {
				toast.success(response.message);
				navigate(-1);
			} else {
				toast.error(response.message);
				setError(response.message);
			}
		} catch (error: any) {
			toast.error('Error al editar Organización');
		} finally {
			setLoading(false);
		}

	};

	const getContent = () => {
		if (loading) return <Spinner />;

		if (error) return <div> Error </div>;

		if (data !== null) {
			const companyData = {
				...data,
			};

			return (
				<CompanyForm isLoading={false} submit={handleUpdate} companyData={companyData} />
			);
		}
	};


	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/organizations', { replace: true })}>
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={true}>
					<CardHeader borderSize={1}>
						<CardLabel icon='BorderColor' iconColor='info'>
							<CardTitle>Editar Organización</CardTitle>
						</CardLabel>
					</CardHeader>
					{
						data ? (
							<CompanyForm isLoading={false} submit={handleUpdate} companyData={data} />
						) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
					}
				</Card>
			</Page>
		</Fragment>
	)
}

export default OrganizationEdit;