import { AxiosResponse } from "axios";
import { RestServiceConnection } from '../restServiceConnection';

// ENDPOINT

const CONFIGURATION_ENDPOINT = '/configuration';

export class ConfiguraciónService extends RestServiceConnection {

    // FUNCTIONS

    /**
     * 
     * @ES Función para crear una configuración para la cartera
     * @EN Function to create a configuration for the wallet
     * 
     * @param data 
     * @returns Configuration Wallet
     */

    createConfigurationWallet = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/add-configuration-wallet',
            data: data, 
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para crear una configuración detallada para la cartera
     * @EN Function to create a detailed configuration for the wallet
     * 
     * @param data 
     * @returns Configuration Wallet
     */

    createDetailedConfigurationWallet = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/add-configuration-detailed-wallet',
            data: data, 
        }, true) as AxiosResponse;
        return this;
    }


    /**
     * 
     * @ES Función para crear una configuración de vencimiento para la cartera
     * @EN Function to create a configuration of expiration for the wallet
     * 
     * @param data 
     * @returns Configuration Wallet
     */

    createExpiredConfigurationWallet = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/add-configuration-wallet-expired',
            data: data, 
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para obtener la configuración de vencimiento de la cartera
     * @EN Function to get the expiration configuration of the wallet
     * 
     * @param type
     * @returns Configuration Wallet
     */

    getExpiredConfigurationWallet = async (type: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/get-configuration-wallet-expired',
            data: {
                expirationType: type
            },
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para obtener la configuración de la cartera
     * @EN Function to get the configuration of the wallet
     * 
     * @returns Configuration Wallet
     */

    getWalletConfigurations = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/get-wallet-configurations',
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para obtener la configuraciones detalladas de la cartera
     * @EN Function to get the detailed configurations of the wallet
     * 
     * @returns Configuration Wallet
     */

    getWalletConfigurationsDetailed = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/list-configuration-detailed-wallet',
        }, true) as AxiosResponse;
        return this;
    }

    /**
     *  
     * @ES Función para eliminar una configuración de la cartera
     * @EN Function to delete a configuration of the wallet
     * 
     * @param id
     * @returns Deleted Configuration Wallet
     */
    
    deleteConfigurationWallet = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/delete-configuration-wallet',
            data: {
                configurationId: id
            },
        }, true) as AxiosResponse;
        return this;
    }

    /**
     *  
     * @ES Función para eliminar una configuración detallada de la cartera
     * @EN Function to delete a detailed configuration of the wallet
     * 
     * @param id
     * @returns Deleted Configuration Wallet
     */
    
    deleteDetailedConfigurationWallet = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/delete-configuration-detailed-wallet',
            data: {
                configurationDetailedWalletId: id
            },
        }, true) as AxiosResponse;
        return this;
    }

     /**
     * 
     * @ES Función para crear una configuración de creación para la cartera
     * @EN Function to create a configuration of creation for the wallet
     * 
     * @param data 
     * @returns Configuration Wallet
     */

     createCreateEntryConfigurationWallet = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/add-configuration-wallet-create-entry',
            data: data, 
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para obtener la configuración de creación de la cartera
     * @EN Function to get the configuration of creation of the wallet
     * 
     * @returns Configuration Wallet
     */

    getCreateEntryConfigurationWallet = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CONFIGURATION_ENDPOINT + '/get-configuration-wallet-create-entry',
        }, true) as AxiosResponse;
        return this;
    }
}