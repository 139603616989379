import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { CardBody, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Button from "../../components/bootstrap/Button";
import Spinner from "../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import Card from "../../components/bootstrap/Card";
import { useSelector } from "react-redux";
import { AuthState } from "../../redux/authSlice";
import { Operation, OperationHasProducts, operationForValues } from "../../type/operation-type";
import * as Yup from 'yup';
import { CardActions } from "../../components/bootstrap/Card";
import { OperationService } from "../../services/operations/operationService";
import { useLoggedUserCompany, useLeadList, useOfficeList, useOperationTypeList, useUserList, useUserProfile, useOriginList } from "../../hooks/useListData";
import { validateDNI } from "../../utils/dniValidator";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { LoadFromCallButton } from "../../components/calls/LoadFromCallButton";
import { CallInfo } from "../../type/calls/callInfo-type";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import OperationProductsComponent from "./components/OperationProductsComponent";
import useHandleErrors from "../../hooks/useHandleErrors";
import OperationRecordsComponent from "./components/operation-records/OperationRecordsComponent";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import OperationSMSPanel from "./components/operation-sms/OperationSMSPanel";
import { SearchClientButton } from "../../components/search_client/SearchClientButton";
import { validateIBAN } from "../../utils/ibanValidator";
import { useSearchParams } from "react-router-dom";
import ClientAditionalData from "./components/ClientAditionalData";
import { ClientService } from "../../services/clients/clientService";
import OperationCommentCard from "./components/operation-comments/OperationCommentCard";
import Tooltips from "../../components/bootstrap/Tooltips";
import Icon from "../../components/icon/Icon";
import InputGroup from "../../components/bootstrap/forms/InputGroup";
import { CNAEModalSelector } from "../../components/selectors/cnae/CNAEModalSelector";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../layout/SubHeader/SubHeader";
import { useLoader } from "../../components/loader/LoaderProvider";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    OperationData?: any;
    idOpe: string;
}

const schema = Yup.object().shape({
    nif: Yup.string()
        .matches(/^\d{8}[a-zA-Z]$/, "Introduce un formato de DNI válido")
        .required("El DNI es obligatorio")
        .test("isValidDNI", "Introduce un DNI real", validateDNI),
    phone1: Yup.string(),
    phone2: Yup.string().nullable(),
    email: Yup.string().email("Introduce un email válido").nullable(),
    iban: Yup.string().nullable().when({
        is: (iban: string) => iban && iban.length > 0,
        then: (schena: Yup.Schema) => Yup.string().required().test("isValidIBAN", "Introduce un IBAN válido", validateIBAN),
    }),
    lead: Yup.string().required("El tipo de trámite es obligatorio"),
    origin: Yup.string().required("El origen es obligatorio"),
    operation_type: Yup.string().required("El tipo de contrato es obligatorio"),
    postalCode: Yup.number().required("El código postal es obligatorio").min(0, "El código postal no puede ser negativo")
});

export interface IOperationForm {
    client: string;
    name: string;
    nif: string;
    locality: string;
    province: string;
    address: string | null,
    postalCode: number | null,
    phone1: string;
    iban: string;
    phone2: string | null;
    email: string | null;
    operation_identifier: string | null;
    user: string;
    agent: string;
    lead: string;
    origin: string;
    products: any;
    company: string;
    operation_type: string;
    operation_for: string;
    activationDate?: any;
    cnae?: string;
}

const OperationForm: FC<CreateFormProps> = ({ isLoading, submit, OperationData, idOpe }) => {

    const mode = OperationData ? 'Editar' : 'Crear';

    const [isClientSelected, setIsClientSelected] = useState<boolean>(false);
    const [CNAESelectorVisible, setCNAESelectorVisible] = useState<boolean>(false);

    const { userCan } = usePrivilege();

    const { showLoading, hideLoading } = useLoader();

    const rol = useSelector((state: { auth: AuthState }) => {
        const userRoles = state.auth.user?.roles;
        return userRoles && (userRoles.includes('Superadministrador') || userRoles.includes('Administrador')) ? 'admin' : userRoles?.[0];
    });

    const id = useSelector((state: { auth: AuthState }) => {
        const userId = state.auth.user?.id;
        return userId ? userId : '';
    });

    // Custom query params handler. This is used to pass data from the client to the operation creation and this handler is triggered by custom protocol
    const [searchParams, setSearchParams] = useSearchParams();

    const uriParams = searchParams.get("uri_params");
    let urlParams = null;
    if (uriParams) {
        const removedProtocol = uriParams.replace("web+romboccreate://", "");
        urlParams = new URLSearchParams(removedProtocol);
    }


    const { handleErrors } = useHandleErrors();

    const leadList = useLeadList();
    const originList = useOriginList();
    const usersList = useUserList({ filter_filters: { active: 3 }, limit: 100 });
    const company = useLoggedUserCompany();
    const user = useUserProfile();
    const operationTypeList = useOperationTypeList();
    const [productsArr, setProductsArr] = useState<OperationHasProducts[]>([]);
    const [saveAndMantain, setSaveAndMantain] = useState(true);

    const formik = useFormik({
        initialValues: {
            client: OperationData ? OperationData.client.id : null,
            favourite: OperationData ? OperationData.client.favourite : false,
            name: OperationData ? OperationData.clientName : (urlParams?.get("name") || ''),
            nif: OperationData ? OperationData.clientDni : '',
            locality: OperationData ? OperationData.locality : '',
            province: OperationData ? OperationData.province : '',
            address: OperationData ? OperationData.clientAddress : '',
            postalCode: OperationData ? OperationData.clientPostalCode : '',
            iban: OperationData ? OperationData.iban : '',
            phone1: OperationData ? OperationData.clientPhone : (urlParams?.get("telephone") || ''),
            phone2: OperationData ? OperationData.clientPhone2 : null,
            email: OperationData ? OperationData.clientEmail : null,
            operation_identifier: OperationData ? OperationData.operationIdentifier : null,
            lead: OperationData ? OperationData.lead?.id : '',
            origin: OperationData ? OperationData.origin?.id : '',
            company: OperationData ? OperationData.company.id : '',
            operation_type: OperationData ? OperationData.operationType.id : '',
            user: OperationData ? OperationData.user.id : undefined,
            agent: OperationData ? OperationData.agent.id : undefined,
            activationDate: OperationData ? OperationData.activationDate?.date.split(' ')[0] : null,
            operation_for: OperationData && OperationData.operationFor ? OperationData.operationFor : "PARTICULAR",
            products: OperationData ? OperationData.products : [],
            cnae: OperationData && OperationData.cnae ? OperationData.cnae : undefined,
        },
        validationSchema: schema,
        onSubmit: values => {
            if (values.phone1 == "" || values.phone1 == "+34" || values.phone1 == null) {
                toast.error("El cliente debe tener un teléfono");
            } else {
                productsArr.map(({ product, ...rest }) => {
                    rest.id = product.id;
                    return rest;
                });
                formik.values.products = productsArr.map(({ product, ...rest }) => { rest.id = product.id; return rest; });
                if (rol != 'admin') {
                    values.user = id;
                }
                values.company = company;

                if (values.phone2 == "+34" || values.phone2 == "") {
                    values.phone2 = null;
                }

                if (values.email == "") {
                    values.email = null;
                }

                if (values.activationDate == "") {
                    values.activationDate = null;
                }

                submit({
                    ...values,
                    postal_code: values.postalCode,
                }, saveAndMantain)
            }
        },
    });


    useEffect(() => {
        if (OperationData) {
            const productsWithoutIds = OperationData.products.map((product: any) => {
                const { createdAt, updatedAt, ...productWithoutId } = product;
                return productWithoutId;
            });
            setProductsArr(productsWithoutIds);

            // if client id exists in operation data, then we get the client calling to _getClient function
            if (OperationData.client.id) {
                _getClient(OperationData.client.dni);
            }
        }
    }, [])

    useEffect(() => {
        if (user != null && formik.values.agent === undefined) {
            formik.setFieldValue('user', user.id);
            formik.setFieldValue('agent', user.id);
        }
    }, [user])

    const handleAddProduct = async (data: OperationHasProducts) => {
        let response;
        if (data) {
            const wizardItems = { ...data, product: data.product.id };
            if (productsArr.length > 0) {
                const deleteResponse = await (await (new OperationService()).removeProduct(idOpe, productsArr[0].product.id)).getResponseData();
                if (deleteResponse.success) {
                    response = await (await (new OperationService()).addProduct(idOpe, wizardItems)).getResponseData();
                    if (response.success) {
                        setProductsArr(response.data.products);
                        toast.success("Producto añadido correctamente");
                    } else {
                        handleErrors(response)
                    }
                }
            } else {
                response = await (await (new OperationService()).addProduct(idOpe, wizardItems)).getResponseData();
                if (response.success) {
                    setProductsArr(response.data.products);
                    toast.success("Producto añadido correctamente");
                } else {
                    handleErrors(response)
                }
            }
        } else {
            toast.error("Error al añadir el producto");
        }
    }

    const showErrors = (inputFieldID: keyof IOperationForm) => {
        const errors = formik.errors[inputFieldID];
        if (formik.touched[inputFieldID] && errors) {
            const errorMessages = Array.isArray(errors)
                ? errors.join(', ')
                : String(errors);
            return <div className="invalid-feedback" style={{ display: "block" }}>{errorMessages}</div>;
        }
        return <></>;
    };


    const verifyClass = (inputFieldID: keyof IOperationForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const handleDeleteSelected = async (selectedItem: any) => {
        if (selectedItem) {
            handleConfirmationAlert({
                title: "Eliminar producto",
                icon: 'warning',
                text: '¿Estás seguro de que quieres eliminar el producto?',
                onConfirm: async () => {
                    const response = await (await (new OperationService()).removeProduct(idOpe, selectedItem.product.id)).getResponseData();
                    if (response.success) {
                        toast.success("Producto eliminado correctamente");
                        setProductsArr(response.data.products);
                    } else {
                        handleErrors(response);
                    }
                }
            })
        } else {
            toast.error("Debe seleccionar un producto para eliminarlo");
        }
    }

    const handleEditSelected = async (selectedItems: OperationHasProducts[]) => {

        const response = await (await (new OperationService()).editProducts(idOpe, selectedItems)).getResponseData();

        if (response.success) {
            toast.success("Producto editado correctamente");
            setProductsArr(response.data.products);
        } else {
            toast.error("Error en la edición del Producto");
        }
    }

    const handleEditServiceSelected = async (selectedItems: any[]) => {

        const response = await (await (new OperationService()).updateService(idOpe, selectedItems)).getResponseData();

        if (response.success) {
            toast.success("Servicios editado correctamente");
            setProductsArr(response.data.products);
        } else {
            toast.error("Error en la edición del Servicio");
        }
    }

    const _getClient = async (nif: string) => {
        const response = await new ClientService().findClientData(nif);
        const responseData = response.getResponseData();

        if (responseData.data.length === 0) {

            return;
        }

        if (responseData.success === true) {
            toast.success(responseData.message);
        }

        formik.setFieldValue('client', responseData.data.id);
        setIsClientSelected(true);

    };


    const _onReceiveCallData = (status: boolean, data: CallInfo | null) => {

        // Fill received data.
        if (status === true && data !== null) {

            // Fill the name
            if (data?.data?.parameters?.name) {

                let name = data?.data?.parameters?.name;

                formik.setFieldValue('name', name);
            }

            // Fill the dni
            if (data?.data?.parameters?.dni) {
                formik.setFieldValue('nif', data?.data?.parameters?.dni);
                _getClient(data?.data?.parameters?.dni);
            }

            // Fill the address
            if (data?.data?.parameters?.address) {
                formik.setFieldValue('address', data?.data?.parameters?.address);
            }

            // Fill the postal code
            if (data?.data?.parameters?.postalCode) {
                formik.setFieldValue('postalCode', data?.data?.parameters?.postalCode);
            }
            // Fill the email
            if (data?.data?.parameters?.email) {
                formik.setFieldValue('email', data?.data?.parameters?.email);
            }

            // Fill the email
            if (data?.phoneNumber) {
                formik.setFieldValue('phone1', "34" + data?.phoneNumber);
            }

            // Fill the locality
            if (data?.data?.parameters?.city) {
                formik.setFieldValue('locality', data?.data?.parameters?.city);
            }

            // Fill the province
            if (data?.data?.parameters?.province) {
                formik.setFieldValue('province', data?.data?.parameters?.province);
            }
        }
    }

    const _handleExportContract = () => async () => {

        showLoading("Exportando contrato", <>Espere mientras se genera el contrato</>);

        try {
            const response = await (await (new OperationService()).exportContract(idOpe));
            const responseData = response.getResponseData();
            // generate blob and download
            const blob = new Blob([response?.getResponse()?.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${idOpe}_contrato_endesa.xlsx`;
            link.click();
            if (responseData?.success) {

            } else {
                handleErrors(response);
            }

        } catch (error: any) {
            toast.error("Error al exportar el contrato" + error.message);
        } finally {
            hideLoading();
        }


    }

    const _renderExportToExcelButton = () => {
        if (OperationData) {
            if (OperationData.products.length > 0) {
                let product = OperationData.products[0];
                if (product.product?.business?.name === "ENDESA") {
                    return (
                        <Button color="danger" isLight className="me-3" onClick={_handleExportContract()}>Exportar contrato ENDESA</Button>
                    )
                }
            }
        }

        return (<></>)
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-7">
                        <Card stretch={false} shadow={'sm'}>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Person' iconColor='info'>
                                    <div className="row">
                                        <CardTitle className="col-10 d-flex justify-content-center align-items-center">
                                            Datos del Cliente
                                        </CardTitle>
                                        <div className="col-2" onClick={() => formik.setFieldValue("favourite", !formik.values.favourite)}>
                                            <Tooltips title={formik.values.favourite ? "Quitar de favoritos" : "Añadir a favoritos"}>
                                                <Button className={`d-flex justify-content-center btn ${formik.values.favourite ? 'btn-outline-info' : 'btn-outline-light'}`} isLight onClick={() => formik.setFieldValue("favourite", !formik.values.favourite)}>
                                                    {formik.values.favourite ? (
                                                        <Icon icon="Star" color="info" size={"2x"} />
                                                    ) : (
                                                        <Icon icon="Star" color="gray" size={"2x"} />
                                                    )}
                                                </Button>
                                            </Tooltips>
                                        </div>
                                    </div>
                                </CardLabel>
                                <CardActions>
                                    <LoadFromCallButton onReceiveData={_onReceiveCallData} />
                                </CardActions>
                            </CardHeader>
                            <CardBody>
                                <div className="row mb-2">
                                    <SearchClientButton formik={formik} setIsClientSelected={setIsClientSelected} operationTypeList={operationTypeList} />
                                </div>
                                <div className="row">
                                    <FormGroup requiredInputLabel label='Nombre y apellidos' className='col-md-6'>
                                        <Input id='name' required onChange={formik.handleChange} autoComplete="new-name" value={formik.values.name} />
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Dni' className='col-md-6'>
                                        <Input id='nif' required onChange={formik.handleChange} value={formik.values.nif} onBlur={formik.handleBlur} className={verifyClass('nif')} />
                                        {showErrors('nif')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup requiredInputLabel label='Direccion' className='col-md-5'>
                                        <Input id='address' required autoComplete="new-address" onChange={formik.handleChange} value={formik.values.address || undefined} />
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Localidad' className='col-md-4'>
                                        <Input id='locality' required onChange={formik.handleChange} value={formik.values.locality} />
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Provincia' className='col-md-3'>
                                        <Input id='province' required onChange={formik.handleChange} value={formik.values.province} />
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup requiredInputLabel label='Codigo Postal' className='col-md-4'>
                                        <Input id='postalCode' type="number" min={0} className={verifyClass('postalCode')} required onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("postalCode", e.target.value) }} onBlur={formik.handleBlur} autoComplete="new-postal-code" value={formik.values.postalCode || undefined} />
                                        {showErrors('postalCode')}
                                    </FormGroup>
                                    <FormGroup label='IBAN' className='col-md-4'>
                                        <Input id='iban' onChange={formik.handleChange} value={formik.values.iban} autoCorrect="new-iban" onBlur={formik.handleBlur} className={verifyClass('iban')} />
                                        {showErrors('iban')}
                                    </FormGroup>
                                    <FormGroup label='Correo electrónico' className='col-md-4'>
                                        <Input
                                            type={'email'}
                                            className={verifyClass('email')}
                                            id='email'
                                            value={formik.values.email || undefined}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {showErrors('email')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup requiredInputLabel label='Teléfono' className='col-md-6'>
                                        <PhoneInput
                                            country={'es'}
                                            onlyCountries={['es']}
                                            autoFormat={false}
                                            countryCodeEditable={false}
                                            onChange={(phone: string) => {
                                                formik.setFieldValue('phone1', "+" + phone);
                                            }}
                                            value={formik.values.phone1} inputClass={verifyClass('phone1')} />
                                        {/*
                                            <Input id='client_phone' required onChange={formik.handleChange} value={formik.values.client_phone} onBlur={formik.handleBlur} className={verifyClass('client_phone')} />
                                        */}
                                        {showErrors('phone1')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono 2' className='col-md-6'>
                                        <PhoneInput
                                            country={'es'}
                                            onlyCountries={['es']}
                                            autoFormat={false}
                                            countryCodeEditable={false}
                                            onChange={(phone: string) => {
                                                if (phone.length == 0) {
                                                    formik.setFieldValue('phone2', null);
                                                    return;
                                                } else {
                                                    formik.setFieldValue('phone2', "+" + phone);
                                                }

                                            }}
                                            value={formik.values.phone2 || '+34'} inputClass={verifyClass('phone2')} />
                                        {/*
                                            <Input id='client_phone' required onChange={formik.handleChange} value={formik.values.client_phone} onBlur={formik.handleBlur} className={verifyClass('client_phone')} />
                                        */}
                                        {showErrors('phone2')}
                                    </FormGroup>
                                </div>
                                <hr />
                                <CardLabel icon='Source' iconColor='info'>
                                    <CardTitle>Datos del Contrato</CardTitle>
                                </CardLabel>
                                <div className="row mt-4">
                                    <FormGroup id='type' label='Tipo de contrato' requiredInputLabel className='col-md-3'>
                                        <Select id='SelectOperationType' required ariaLabel='Select Operation Type' placeholder='Elegir Tipo de Contrato...' onChange={(e: any) => formik.setFieldValue('operation_type', e.target.value)} value={formik.values.operation_type} list={operationTypeList} />
                                        {showErrors('operation_type')}
                                    </FormGroup>
                                    <FormGroup id='lead' label='Tipo de trámite' requiredInputLabel className='col-md-3'>
                                        <Select id='SelectLead' required ariaLabel='Select Lead' placeholder='Elegir Tipo de Trámite...' onChange={(e: any) => formik.setFieldValue('lead', e.target.value)} value={formik.values.lead} list={leadList} />
                                        {showErrors('lead')}
                                    </FormGroup>
                                    {
                                        userCan('set_activation_date', 'operations') &&
                                        (
                                            <FormGroup label='Fecha de Activación' className='col-md-3'>
                                                <Input id='activationDate' type='date' onChange={formik.handleChange} value={formik.values.activationDate || ''} className={verifyClass('activationDate')} />
                                                {showErrors('activationDate')}
                                            </FormGroup>
                                        )
                                    }

                                    <FormGroup label='Origen Lead' requiredInputLabel className='col-md-3'>
                                        <Select id='SelectOrigin' required ariaLabel='Select Origin' placeholder='Elegir Origen Lead...' onChange={(e: any) => formik.setFieldValue('origin', e.target.value)} value={formik.values.origin} list={originList} />
                                        {showErrors('lead')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-4">
                                    <FormGroup id='operation_for' label='Contrato destianada a' requiredInputLabel className='col-md-3'>
                                        <Select id='SelectOperationFor' required ariaLabel='Select Lead' placeholder='Selecciona tipo' onChange={(e: any) => formik.setFieldValue('operation_for', e.target.value)} value={formik.values.operation_for} list={operationForValues} />
                                        {showErrors('operation_for')}
                                    </FormGroup>
                                    {
                                        userCan('assign_to_agents', 'operations') || userCan('assign_to_user', 'operations') ?
                                            <>
                                                <FormGroup id='user' label='Creada por:' requiredInputLabel className={`${userCan('assign_to_user', 'operations') ? userCan('assign_to_agents', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                                    <Select id='SelectUser' required ariaLabel='Select Agent' placeholder='Elegir Usuario...' onChange={(e: any) => formik.setFieldValue('user', e.target.value)} value={formik.values.user} list={usersList} />
                                                    {showErrors('user')}
                                                </FormGroup>
                                                <FormGroup id='userAgent' label='Agente' requiredInputLabel className={`${userCan('assign_to_agents', 'operations') ? userCan('assign_to_user', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                                    <Select id='SelectAgente' required ariaLabel='Select Agent' placeholder='Elegir Agente...' onChange={(e: any) => formik.setFieldValue('agent', e.target.value)} value={formik.values.agent} list={usersList} />
                                                    {showErrors('user')}
                                                </FormGroup>
                                            </>
                                            : null
                                    }
                                    <FormGroup id='cnae' label='CNAE' requiredInputLabel className='col-md-3'>
                                        <InputGroup>
                                            <Input id='cnae' readOnly value={formik.values.cnae} />
                                            <Tooltips title="Buscar CNAE">
                                                <Button type="button" color="info" icon="Search" onClick={() => {
                                                    setCNAESelectorVisible(true);
                                                }}></Button>
                                            </Tooltips>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className={`col-md-5 `}>
                        {!isClientSelected && (<div className="d-flex justify-content-center"><h5 className="text-muted mt-5">No hay histórico del cliente</h5></div>)}
                        {isClientSelected && (<ClientAditionalData clientId={formik.values.client !== undefined ? formik.values.client : OperationData.client.id} />)}
                    </div>
                </div>


                {mode == "Editar" && (
                    <>
                        <div className="row">
                            <div className='col-md-12'>
                                <OperationProductsComponent
                                    operation={OperationData as Operation}
                                    products={productsArr}
                                    handleDeleteSelected={handleDeleteSelected}
                                    handleEditSelected={handleEditSelected}
                                    handleEditServiceSelected={handleEditServiceSelected}
                                    handleAddProduct={handleAddProduct}
                                />
                            </div>
                        </div>
                        {
                            // Only show operation records when there are products in the operation
                        }
                        <div className="row">
                            <div className='col-md-4'>
                                <OperationRecordsComponent
                                    operationId={OperationData?.id as string}
                                />
                            </div>
                            <div className='col-md-8'>
                                <OperationSMSPanel
                                    operation={OperationData}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className={`${OperationData ? 'col-md-12 d-block' : 'd-none'}`}>
                    <Card>
                        <CardHeader style={{ paddingBottom: "0px" }} className="float-end">
                            <CardLabel icon="Message" iconColor="info">
                                <CardTitle>Comentarios del Contrato</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <OperationCommentCard id={OperationData?.id} />
                        </CardBody>
                    </Card>
                </div>
                <div className="row">
                    <div className={"col-12 d-flex justify-content-end"}>
                        {mode != "Editar" ? (
                            <Button type="submit" size='lg' color='primary'>
                                {isLoading ? <Spinner isSmall /> : `Crear Contrato`}
                            </Button>
                        ) : (
                            <>
                                {_renderExportToExcelButton()}
                                <Button type="button" size='lg' color='primary' isLight className="me-2"
                                    onClick={() => {
                                        setSaveAndMantain(false);
                                        formik.handleSubmit();
                                    }}
                                >
                                    {isLoading ? <Spinner isSmall /> : `${mode == "Editar" ? "Guardar y Salir" : "Guardar y finalizar"}`}
                                </Button>
                                <Button type="submit" size='lg' color='primary' >
                                    {isLoading ? <Spinner isSmall /> : `${mode == "Editar" ? "Guardar" : "Guardar y finalizar"} Contrato`}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </form >

            {/* CNAE Selector modal */}
            <CNAEModalSelector
                visible={CNAESelectorVisible}
                setVisible={setCNAESelectorVisible}
                onSelect={(cnae: string) => {
                    formik.setFieldValue('cnae', cnae);
                    setCNAESelectorVisible(false);
                }}
            ></CNAEModalSelector>
        </Fragment >
    )
}

export default OperationForm;