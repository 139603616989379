import { FC, useEffect, useState } from "react";
import UserContact from "../../../../components/UserContact";
import { OperationService } from "../../../../services/operations/operationService";
import CommonDashboardAlert from "./CommonDashboardAlert";
import Spinner from "../../../../components/bootstrap/Spinner";

interface IHeaderRankingProps {
  date: any;
  agent?: string;
  office?: string;
  business?: string;
}

const DashboardHeaderRanking: FC<IHeaderRankingProps> = ({ date , office, business, agent }) => {
  const [data, setData] = useState<any>(null);

  const getRanking = async () => {
    const operationService = new OperationService();
    if (date.start === "" || date.end === "") return;
    const response = (
      await operationService.getRanking(date.start, date.end , agent, office, business)
    ).getResponseData();
    if (response.success) {
      setData(response.data ? response.data.splice(0, 3) : []);
    }
  };

  useEffect(() => {
    if (date.start != "" && date.end != "") {
      getRanking();
    }
  }, [date , office, business, agent]);

  return (
    <>
      {data ? (
        <>
          {data.length > 0 ? (
            <>
              <div className="row">
                <CommonDashboardAlert
                  name={data[0].agentName}
                  points={data[0].netPoints || 0}
                />
              </div>
              <div className="row">
                {data.map((agent: any, index: number) => (
                  <div className="col-xl-4" key={index}>
                    <UserContact
                      name={`${index + 1}. ${agent.agentName}`}
                      position="Departamento de Endesa"
                      phone={agent.telephone}
                      src={agent.agentImg}
                      color={agent.color}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h4 className="card-title mt-2">No hay datos</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="col-12 d-flex justify-content-center">
          <Spinner className="text-center text-primary" />
        </div>
      )}
    </>
  );
};

export default DashboardHeaderRanking;
