import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import { CNAEService } from "../../../services/cnae/cnaeService";
import ReactSelect from "react-select";
import { ReactSelectStyles } from "../../../utils/styles";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Button from "../../bootstrap/Button";
import CustomSearchInput from "../../bootstrap/forms/CustomSearchInput";
import Card, { CardBody } from "../../bootstrap/Card";

type CNAEModalSelectorProps = {
    defaultValue?: string;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onSelect: (cnae: string) => void;
}

type CNAECode = {
    code: string;
    codeGroup: string;
    description: string;
}

export const CNAEModalSelector: React.FC<CNAEModalSelectorProps> = ({
    defaultValue,
    visible,
    setVisible,
    onSelect
}) => {

    const [groups, setGroups] = useState<CNAECode[]>([]);
    const [subGroups, setSubGroups] = useState<CNAECode[]>([]);
    const [categories, setCategories] = useState<CNAECode[]>([]);
    const [activities, setActivities] = useState<CNAECode[]>([]);
    const [customSearch, setCustomSearch] = useState<string | undefined>(undefined);
    const [customCnaes, setCustomCnaes] = useState<CNAECode[]>([]);

    const [groupSelected, setGroupSelected] = useState<any | null>(null);
    const [subGroupSelected, setSubgroupSelected] = useState<any | null>(null);
    const [categorySelected, setCategorySelected] = useState<any | null>(null);
    const [activitySelected, setActivitySelected] = useState<any | null>(null);

    const cnaeService = new CNAEService();

    const _getGroups = async () => {
        let response = (await (cnaeService.getGroups())).getResponseData();
        if (response.success) {
            setGroups(response.data);
        }
    }

    const _getSubGroups = async () => {
        let response = (await (cnaeService.getSubGroups(groupSelected.value))).getResponseData();
        if (response.success) {
            setSubGroups(response.data);
        }
    }

    const _getCategories = async () => {
        let response = (await (cnaeService.getCategories(subGroupSelected.value))).getResponseData();
        if (response.success) {
            setCategories(response.data);
        }
    }

    const _getActivities = async () => {
        let response = (await (cnaeService.getActivities(subGroupSelected.value))).getResponseData();
        if (response.success) {
            setActivities(response.data);
        }
    }

    const _searchCustomCnae = async () => {
        if (customSearch === undefined) return;
        let response = (await (cnaeService.searchCNAEs(customSearch))).getResponseData();
        if (response.success) {
            setCustomCnaes(response.data);
        }
    }

    useEffect(() => {
        _getGroups();
    }, []);

    useEffect(() => {
        if (groupSelected) {
            setSubgroupSelected(null);
            _getSubGroups();
        }
    }, [groupSelected])

    useEffect(() => {
        if (subGroupSelected) {
            setCategorySelected(null);
            _getCategories();
        }
    }, [subGroupSelected])

    useEffect(() => {
        if (categorySelected) {
            setActivitySelected(null);
            _getActivities();
        }
    }, [categorySelected])

    useEffect(() => {

        _searchCustomCnae();

    }, [customSearch])



    return (
        <>
            <Modal id="cnae-modal-selector" setIsOpen={setVisible} size={'lg'} isOpen={visible} isStaticBackdrop>
                <ModalHeader setIsOpen={setVisible}>
                    <ModalTitle id={'cnae-modal-selector'}>
                        CNAE
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <h4>CNAE Interactivo: </h4>
                    <FormGroup label="Grupo">
                        <ReactSelect
                            placeholder="Seleccionar grupo"
                            styles={ReactSelectStyles}
                            value={groupSelected}
                            options={groups.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                            onChange={setGroupSelected}
                        />
                    </FormGroup>
                    {groupSelected && (
                        <FormGroup label="Subgrupo" className="mt-3">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={subGroupSelected}
                                options={subGroups.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setSubgroupSelected}
                            />
                        </FormGroup>
                    )}

                    {subGroupSelected && (
                        <FormGroup label="Categoría" className="mt-3">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={categorySelected}
                                options={categories.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setCategorySelected}
                            />
                        </FormGroup>
                    )}

                    {categorySelected && (
                        <FormGroup label="Actividad" className="mt-3">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={activitySelected}
                                options={activities.map((group: any) => { return { value: group.code, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setActivitySelected}
                            />
                        </FormGroup>
                    )}

                    {activitySelected && (
                        <div className="d-flex justify-content-center mt-3">
                            <Button color="primary" isLight onClick={() => {
                                onSelect(activitySelected.value);
                            }}>
                                Aplicar CNAE {activitySelected.value}
                            </Button>
                        </div>

                    )}

                    <hr />
                    <h4>O bien, selecciona un CNAE directamente:</h4>
                    <FormGroup label="Búsqueda de cnae (código o nombre):" className="mt-3">
                        <CustomSearchInput placeholder="Buscar CNAE..." defaultValue={customSearch} onSearch={(search: string) => {
                            if (search.length >= 3) {
                                setCustomSearch(search);
                            } else {
                                setCustomSearch(undefined);
                            }
                        }}></CustomSearchInput>
                    </FormGroup>
                    <div className="cnae-list pt-3">
                        {customCnaes.length === 0 && (<div className="d-flex justify-content-center text-muted">No se han encontrado cnaes</div>)}
                        {customCnaes.map((cnae: CNAECode) => {
                            return (
                                <div className="cnae-item d-flex justify-content-between align-items-center border mb-2 p-2" key={cnae.code}>
                                    <div className="cnae-item-content">
                                        <div className="cnae-item-code"><b>Código: </b>{cnae.code}</div>
                                        <div className="cnae-item-description">{cnae.description}</div>
                                    </div>
                                    <div className="cnae-item-actions">
                                        <Button color="primary" isLight onClick={() => {
                                            onSelect(cnae.code);
                                        }}>
                                            Seleccionar
                                        </Button>
                                    </div>
                                </div>

                            )
                        })}
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}