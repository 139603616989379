import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardActions,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import UserForm from "../UserForm";
import { UserService } from "../../../services/users/userService";
import { EditUserFieldsModel, User } from "../../../type/user-type";
import useFetch from "../../../hooks/useFetch";
import UserEditPermissions from "./UserEditPermissions";
import { RolePermission } from "../../../type/role-type";
import StatusSwitch from "../../../components/StatusSwitch";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useHandleErrors from "../../../hooks/useHandleErrors";

const UserEdit: FC = () => {
  const navigate = useNavigate();
  const {handleErrors} = useHandleErrors();
  const { id = '' } = useParams<{ id: string }>();

  const [editPermissions, setEditPermissions] = useState<boolean>(false);

  /*
    Used to save the edited permissions in case we open the modal a second time, to have updated value with no need
    to fetch again. This way the user doesn't lose the unsaved changes in the user edit form.
   */
  const [userPermissions, setUserPermissions] = useState<RolePermission[] | null>(null);

  const fetchUser = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    return response.getResponseData() as User;
  }, [id]);

  const [data] = useFetch(fetchUser);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [changingStatus, setChangingStatus] = useState<boolean>(false);


  const handleUpdate = async (values: EditUserFieldsModel) => {
    setLoading(true);

    try {
      setLoading(true)
      let response = await (await (new UserService()).editUser({ ...values, user: values.id })).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate('/users', { replace: true });
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al editar Usuario');
    } finally {
      setLoading(false);
    }

  };


  const toggleUserStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus(true);
      let response = await (
        await new UserService().toggleUserStatus(id, status)
      ).getResponseData();
      if (response.success) {
        toggleStatus(status);
      }
    } catch (error: any) {
      //
    } finally {
      setChangingStatus(false);
    }
  };

  const getContent = () => {
    if (loading) return <Spinner />;

    if (error) return <div> Error </div>;

    if (data !== null) {

      const userData = {
        ...data,
        base_salary: data.baseSalary,
        monthly_objective: data.monthlyObjective,
        available_bonuses: data.availableBonuses,
        contract_start_date: data.contractStartDate && data.contractStartDate.date.split(' ')[0],
        contract_end_date: data.contractEndDate && data.contractEndDate.date.split(' ')[0],
        role: data.userRoles && data.userRoles[0] && data.userRoles[0].role.id,
        password: "",
        re_password: "",
        data_protection: data.dataProtection,
        bank_account: data.bankAccount,
        company: data.companies && data.companies[0] && data.companies[0].id,
        profile_img: data.profileImg && data.profileImg,
      };
      
      return (
        <Fragment>
          <CardHeader borderSize={1}>
            <CardLabel icon="BorderColor" iconColor="info">
              <CardTitle>Editar Usuario</CardTitle>
            </CardLabel>
            <CardActions>
              <StatusSwitch itemId={data.id} status={data.active} change={toggleUserStatus}/>
            </CardActions>
          </CardHeader>
          <UserForm isLoading={false} submit={handleUpdate} userData={userData} />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          ></Button>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button
            color="brand-two"
            icon="Security"
            onClick={() => { setEditPermissions(true) }}>
            Editar Permisos
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <></>
          {getContent()}
        </Card>
      </Page>
      {editPermissions && data &&
        <UserEditPermissions
          isOpen={editPermissions}
          setIsOpen={setEditPermissions}
          userPermissions={data.userPermissions}
          userId={id}
          newPermissions={userPermissions}
          setNewPermissions={setUserPermissions}
        />
      }
    </Fragment>
  );
};

export default UserEdit;
