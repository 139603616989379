import { FiltersProvider } from "../../components/providers/FiltersProvider";
import DocumentTypeList from "./documentType-list/DocumentTypeList";

const DocumentTypeListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <DocumentTypeList />
        </FiltersProvider>
      </>
    );
  };

export default DocumentTypeListWrapper;