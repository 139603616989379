import { FC, Fragment, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import ProductWrapper from "../entities/products/ProductWrapper";
import FeeWrapper from "../entities/fees/FeeWrapper";
import ServiceWrapper from "../entities/services/ServiceWrapper";
import AsyncImg from "../../../components/AsyncImg";
import { BusinessService } from "../../../services/business/businessService";
import { BusinessApiResponse } from "../../../type/business-type";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import PagePermissions from "../../auth/PagePermissions";
import SMSMatrixWrapper from "../entities/sms_matrix/SMSMatrixWrapper";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ActiveTab, setActiveTab } from "../../../redux/activeTabSlice";

const ACTIVE_TAB_SECTION = 'company_view';

const BusinessView: FC = () => {
    const { userCan } = useContext(PrivilegeContext)
    const { id = '' } = useParams<{ id: string }>();

    const fetchBusiness = useCallback(async () => {
        const businessService = new BusinessService();
        const response = await businessService.getBusinessById(id as string);
        return response.getResponseData() as BusinessApiResponse;
    }, [id]);

    const activeTab = useSelector((state: RootState) => state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION));

    const dispatch = useDispatch();

    const [data, loading, error] = useFetch(fetchBusiness);

    const navigate = useNavigate();

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                    </Button>
                    {data ? (
                            <CardTitle className="me-2">{data ? data.name : ""}</CardTitle>) : <Spinner />
                    }
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <div className='row'>
                    <div className='col-xxl-12 col-xl-12'>
                        <Card 
                        // @ts-ignore
                        defaultActiveTab={activeTab?.activeTab || 0}
                        onActiveTab={(tab: number) => {
                            dispatch(setActiveTab({ section: ACTIVE_TAB_SECTION, activeTab: tab }));
                        }}
                        hasTab>
                            <CardTabItem id='company' title='Comercializadora' icon='Store'>
                                {
                                    data ? (
                                        <Card
                                        className='rounded-2'
                                        tag='form'>
                                        <CardBody>
                    
                                            <div className='row g-4'>
                                                <div className="row mb-4">
                                                    <div className="col-xl-12 mb-4">
                                                        <AsyncImg id={data.logo ? data.logo.id : null} width="100" />
                                                    </div>
                                                    <FormGroup label='Nombre de la Comercializadora' className='col-md-3'>
                                                        <div className=''>{data.name || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='CIF' className='col-md-3'>
                                                        <div className=''>{data.cif || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='Dirección' className='col-md-3'>
                                                        <div className=''>{data.address || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='Código Tienda' className={`col-md-3`}>
                                                        <div className=''>{data.shopCode || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                </div>
                                                <div className="row mb-4">
                                                    { userCan('list', 'companies') &&
                                                        <FormGroup label='Organización' className='col-md-3'>
                                                            <div className=''>{data.company.name || 'No proporcionado'} </div>
                                                        </FormGroup>
                                                    }
                                                    <FormGroup label='Días de clawback' className={`${userCan('list', 'companies') ? 'col-md-2' : 'col-md-3'}`}>
                                                        <div className=''>{data.clawbackDays || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='Días de Renovación (Particulares)' className={`${userCan('list', 'companies') ? 'col-md-2' : 'col-md-3'}`}>
                                                        <div className=''>{data.renewalDaysParticular || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='Días de Renovación (Empresas)' className={`${userCan('list', 'companies') ? 'col-md-2' : 'col-md-3'}`}>
                                                        <div className=''>{data.renewalDaysBusiness || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    <FormGroup label='ID Referencia' className={`${userCan('list', 'companies') ? 'col-md-2' : 'col-md-3'}`}>
                                                        <div className=''>{data.referenceId || 'No proporcionado'} </div>
                                                    </FormGroup>
                                                    
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card> 
                                    ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
                                }
                            </CardTabItem>
                            <CardTabItem id='products' title='Productos' icon='Inventory'>
                                {userCan('list', 'products') ? <ProductWrapper businessId={id} /> : <PagePermissions />}
                            </CardTabItem>
                            <CardTabItem id='fees' title='Tarifas' icon='LocalOffer'>
                                {userCan('list', 'fees') ? <FeeWrapper businessId={id} /> : <PagePermissions />}
                            </CardTabItem>
                            <CardTabItem id='services' title='Servicios' icon='SupportAgent'>
                                {userCan('list', 'services') ? <ServiceWrapper businessId={id} /> : <PagePermissions />}
                            </CardTabItem>
                            <CardTabItem id='sms_matrix' title='Matriz SMS' icon='Message'>
                                <SMSMatrixWrapper businessId={id}></SMSMatrixWrapper>
                            </CardTabItem>
                        </Card>
                    </div>
                </div>
            </Page>
        </Fragment>
    )
}

export default BusinessView;