import { FC, useCallback, useContext, useEffect, useState } from "react";
import { ClientService } from "../../../services/clients/clientService";
import { toast } from "react-toastify";
import { CustomTable } from "../../../components/table/CustomTable";
import Spinner from "../../../components/bootstrap/Spinner";
import useFilters from "../../../hooks/useFilters";
import AsyncImg from "../../../components/AsyncImg";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import Tooltips from "../../../components/bootstrap/Tooltips";
import ClientCommentModal from "./ClientEditCommentModal";
import moment from "moment";
import Button from "../../../components/bootstrap/Button";

interface ClientsCommentHistoryProps {
  id: string;
  minheight?: string;
}

const ClientsCommentHistory: FC<ClientsCommentHistoryProps> = ({
  id,
  minheight,
}) => {
  const { userCan } = useContext(PrivilegeContext);

  const commentFilters = {
    client: id,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [comment, setComment] = useState<any>(null);

  const {
    filters,
    updateFilters,
    resetFilters,
    updateFilterOrder,
    updatePage,
    updatePageSize,
  } = useFilters(commentFilters, [], 50);

  useEffect(() => {
    updateFilters({ client: id });
  }, [id]);

  const fetchCommments = useCallback(async () => {
    const response = await (
      await new ClientService().getComments(filters)
    ).getResponseData();
    return response;
  }, [filters]);

  const [data, loading, error, refech] = useFetch(fetchCommments);

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message + " correctamente");
    }
    setIsOpen(false);
    setComment(null);
    refech();
  };

  const deleteComment = async (idcomment: string) => {
    let response = (
      await new ClientService().deleteComment(idcomment, id)
    ).getResponseData();
    if (response.success) {
      toast.success("Comentario eliminado correctamente");
      refech();
    } else {
      toast.error("Error al eliminar el comentario");
    }
  };

  const formatElapsedTime = (created_at: string) => {
    const createdAt = new Date(created_at);
    const now = new Date();
    const timeDifference = now.getTime() - createdAt.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const formatUnit = (value: number, unit: string): string => {
      if (value === 1) {
        return `${value} ${unit}`;
      } else if (value > 1) {
        return `${value} ${unit}s`;
      }
      return "";
    };

    if (years > 0) {
      return formatUnit(years, "año");
    } else if (months > 0) {
      return formatUnit(months, "mes");
    } else if (days > 0) {
      return formatUnit(days, "día");
    } else if (hours > 0) {
      return formatUnit(hours, "hora");
    } else if (minutes > 0) {
      return formatUnit(minutes, "minuto");
    } else if (seconds > 0) {
      return formatUnit(seconds, "segundo");
    } else {
      return "Ahora";
    }
  };

  return (
    <>
      {data ? (
        <>
          <CustomTable
            data={data.comments || null}
            className={`table table-hover fs-7 ${
              minheight ? minheight : "max-h-250"
            }`}
            columns={[
              {
                name: "Usuario",
                keyValue: "name",
                className: "text-center",
                render: (element: any) => {
                  return (
                    <Tooltips title={element.user.name}>
                      <div className="d-flex justify-content-center">
                        <div className="user-avatar">
                          <AsyncImg
                            id={
                              element.user.profileImg
                                ? element.user.profileImg.id
                                : null
                            }
                          />
                        </div>
                      </div>
                    </Tooltips>
                  );
                },
              },
              {
                name: "Comentario",
                keyValue: "comment",
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.comment}
                    </div>
                  );
                },
              },
              {
                name: "Ult. Actualización",
                keyValue: "created_at",
                sortable: true,
                sortColumn: updateFilterOrder,
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center">
                      {element.updatedAt != null
                        ? formatElapsedTime(element.updatedAt.date)
                        : formatElapsedTime(element.createdAt.date)}
                    </div>
                  );
                },
              },
              {
                name: "Fecha de Creación",
                keyValue: "created_at",
                sortable: true,
                sortColumn: updateFilterOrder,
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <>
                      <div className="d-flex justify-content-center">
                        {moment(element.createdAt.date).format(
                          "DD/MM/YYYY - HH:mm"
                        )}
                      </div>
                    </>
                  );
                },
              },
              {
                name: "Acciones",
                className: "min-w-100px text-end",
                isActionCell: true,
              },
            ]}
            actions={[
              {
                title: "Editar",
                buttonType: "icon",
                iconColor: "text-info",
                iconPath: "/media/icons/duotune/general/gen055.svg",
                hide: (item: any) => !userCan("edit_comments", "clients"),
                description: "Editar el Comentario",
                callback: (item: any) => {
                  if (userCan("edit_comments", "clients")) {
                    setComment(item);
                    setMode("edit");
                    setIsOpen(true);
                  }
                },
              },
              {
                title: "Eliminar",
                buttonType: "icon",
                iconColor: "text-danger",
                iconPath: "/media/icons/duotune/general/gen027.svg",
                hide: (item: any) => !userCan("remove_comments", "clients"),
                description: "Eliminar el Comentario",
                callback: (item: any) => {
                  if (userCan("remove_comments", "clients")) {
                    handleConfirmationAlert({
                      title: "Eliminar Comentario",
                      text: "¿Está seguro que desea eliminar el comentario?",
                      icon: "warning",
                      onConfirm: () => {
                        deleteComment(item.id);
                      },
                    });
                  }
                },
              },
            ]}
          />
          <Tooltips title="Añadir Comentario">
            <div className="d-flex justify-content-center">
              <Button
                color="info"
                isLight
                className="mt-2 text-center"
                isLink
                icon="AddComment"
                onClick={() => {
                  setMode("create");
                  setIsOpen(true);
                }}
              >
                Añadir Comentario
              </Button>
            </div>
          </Tooltips>
          <ClientCommentModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            client={id}
            comment={comment}
            onClose={_onClose}
            mode={mode}
          />
        </>
      ) : (
        <div className="text-center d-flex justify-content-center">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ClientsCommentHistory;
