import { createSlice, createAsyncThunk, Action, PayloadAction } from '@reduxjs/toolkit';

export interface Dashboard {
  commissions: boolean;
}

const initialState: Dashboard = {
        commissions: false
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setShowCommissions: (state: Dashboard, action: PayloadAction<Dashboard>) => {
        state = {
            ...action.payload
        }
        return state;
    }
  }
});

export const { setShowCommissions } = dashboardSlice.actions;

export default dashboardSlice.reducer;
