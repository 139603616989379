import Card, { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import ConfigurationsList from "../components/ConfigurationsList";
import React, { useEffect, useState } from "react";
import Alert, {AlertHeading} from "../../../../components/bootstrap/Alert";
import {useWalletConfigEntities} from "../../../../hooks/useWalletConfigEntities";

const WalletConfigChangeStatus = () => {

    // STATES
    const {walletStatuses, walletActionStatuses, campaigns, operationStatuses} = useWalletConfigEntities()


    return (
        <React.Fragment>
            <div className="container mt-5">
                <Card>
                    <CardHeader>
                        <CardTitle>
                            <Icon icon="SyncAlt" className="me-2 text-black" size={'2x'} />
                            Configuraciones de Cambios de Estado
                        </CardTitle>
                    </CardHeader>
                    <CardBody className="w-100">
                        {/*
                            <div className="row w-100" style={{minHeight: '50px'}}>
                               <ClawbackExpiration walletStatuses={walletStatuses} walletActionStatuses={walletActionStatuses} />
                                <RenewalExpiration walletStatuses={walletStatuses} walletActionStatuses={walletActionStatuses} />
                                <CreateEntry operationStatuses={operationStatuses} />
                            </div>
                        */}
                        <Alert
                            icon='Info'
                            isLight
                            color='primary'
                            borderWidth={0}
                            className='shadow-3d-primary'
                            isDismissible>
                            <AlertHeading tag='h2' className='h4'>
                                Información
                            </AlertHeading>
                            <span>Cada vez que la última <strong>OPERACIÓN</strong> de un CUPS cambie al estado seleccionado, se <strong>SINCRONIZARÁ</strong> con la <strong>CARTERA</strong>, creando el nuevo CUPS en cartera si no existe o actualizándolo con los datos de la operación en caso de existir ya en cartera.</span>
                        </Alert>
                        <ConfigurationsList walletStatuses={walletStatuses} walletActionStatuses={walletActionStatuses} operationStatuses={operationStatuses} campaigns={campaigns} />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default WalletConfigChangeStatus;