import { useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import { TABS } from "./common/helper";
import Button, { ButtonGroup } from "../../../components/bootstrap/Button";
import CommonDashboardIncome from "./common/CommonDashboardIncome";
import DashboardDataByCompany from "./common/DashboardDataByCompany";
import OperationAgentRanking from "../agent-dashboard/components/OperationAgentRanking";
import moment from "moment";
import { CUSTOMTABS } from "../agent-dashboard/OperationStatistic";
import { useFormik } from "formik";
import DashboardHeaderRanking from "./common/DashboardHeaderRanking";
import DashboardOperationPoints from "./common/DashboardOperationPoints";
import { useBusinessList, useOfficeList, useOperationAgentsList } from "../../../hooks/useListData";
import CustomSelect from "../../../components/selectors/general/CustomSelect";
import { SingleValue } from "react-select";
import Icon from "../../../components/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Dashboard , setShowCommissions } from "../../../redux/dashboardSlice";

const DashboardPage = () => {

  const [date, setDate] = useState<any>({ start: "", end: "" });
  const [activeTab, setActiveTab] = useState(CUSTOMTABS.YEARLY);
  const [office, setOffice] = useState("");
  const [business, setBusiness] = useState("");
  const [agent, setAgent] = useState("");
  
  const { commissions } = useSelector((state: RootState) => state.dashboard);
  
  const dispatch = useDispatch();

  const [showCommission, setShowCommission] = useState(commissions || false);

  const officesOptions = useOfficeList({filter_filters: {active: true} , all: true});

  const bussinessOptions = useBusinessList({filter_filters: {active: true} , all: true});

  const agentsOptions = useOperationAgentsList({filter_filters: {active: true} , all: true});

  const getDateRange = () => {
    if (activeTab === TABS.YEARLY) {
      return {
        start: moment()
          .set("month", 0)
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().set("month", 11).endOf("month").format("YYYY-MM-DD"),
      };
    }
    if (activeTab === TABS.MONTHLY) {
      return {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      };
    }
    if (activeTab === TABS.WEEKLY) {
      return {
        start: moment()
        .startOf("week")
        .format("YYYY-MM-DD"),
        end: moment().endOf("week").format("YYYY-MM-DD"),
      };
    }
    return {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };
  };

  useEffect(() => {
    if (activeTab !== CUSTOMTABS.CUSTOM) {
      setDate(getDateRange());
    }
  }, [activeTab]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start: "",
      end: "",
    },
    onSubmit: async (values) => {
      setDate(values);
    },
  });

  return (
    <PageWrapper title={"Dashboard"}>
      <SubHeader>
        <SubHeaderLeft className="d-flex align-items-center flex-wrap">
          <span className="h4 mb-0 fw-bold">Panel de control</span>
          <SubheaderSeparator />
          <Button color="light" className="" onClick={() => {
            setShowCommission(!showCommission)
            dispatch(setShowCommissions({commissions: !showCommission}))
          }}>
            Ver Comisiones
          </Button>
          <SubheaderSeparator />
          <ButtonGroup>
            {Object.keys(CUSTOMTABS).map((key) => (
              <Button
                key={key}
                color={activeTab === CUSTOMTABS[key] ? "primary" : "light"}
                onClick={() => {
                  setActiveTab(CUSTOMTABS[key]);
                }}
              >
                {CUSTOMTABS[key]}
              </Button>
            ))}
          </ButtonGroup>
          {activeTab === CUSTOMTABS.CUSTOM && (
            <form
              className="d-flex align-items-center ms-4"
              onSubmit={formik.handleSubmit}
            >
              <input
                type="date"
                id="start"
                value={formik.values.start}
                onChange={formik.handleChange}
                max={formik.values.end}
                className="form-control"
                placeholder="Fecha inicio"
              />
              <span className="mx-2">-</span>
              <input
                type="date"
                id="end"
                value={formik.values.end}
                onChange={formik.handleChange}
                min={formik.values.start}
                className="form-control"
                placeholder="Fecha fin"
              />
              <Button color="primary" className="ms-2" type="submit">
                Buscar
              </Button>
            </form>
          )}
          <CustomSelect onChangeSingle={(value: SingleValue<any>) => setOffice(value.value)} defaultValue={
            officesOptions.find((status : any) => status.value === office)
          } placeholder='Oficina...' options={officesOptions} is_multi={false}/>
          { office !== '' && 
                <Button className="btn-outline-danger d-flex align-items-center p-1 fw-bold" onClick={() => setOffice('')}>
                    <Icon icon="Clear" className="text-danger" style={{fontSize: '2.5 rem'}} />
                </Button>
          }
          <CustomSelect onChangeSingle={(value: SingleValue<any>) => setBusiness(value.value)} defaultValue={
            bussinessOptions.find((status : any) => status.value === business)
          } placeholder='Comercializadora...' options={bussinessOptions} is_multi={false}/>
          { business !== '' && 
                <Button className="btn-outline-danger d-flex align-items-center p-1 fw-bold" onClick={() => setBusiness('')}>
                    <Icon icon="Clear" className="text-danger" style={{fontSize: '2.5 rem'}} />
                </Button>
          }
          <CustomSelect onChangeSingle={(value: SingleValue<any>) => setAgent(value.value)} defaultValue={
            agentsOptions.find((status : any) => status.value === agent)
          } placeholder='Agente...' options={agentsOptions} is_multi={false}/>
          { agent !== '' && 
                <Button className="btn-outline-danger d-flex align-items-center p-1 fw-bold" onClick={() => setAgent('')}>
                    <Icon icon="Clear" className="text-danger" style={{fontSize: '2.5 rem'}} />
                </Button>
          }
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <div className="row">
          <div className="row">
            <DashboardHeaderRanking date={date} agent={agent} business={business} office={office}/>
          </div>
          <div className="col-xxl-9">
            <CommonDashboardIncome activeTab={activeTab} date={date} showCommission={showCommission} agent={agent} business={business} office={office}/>
          </div>
          <div className="col-xxl-3">
            <DashboardOperationPoints date={date} agent={agent} business={business} office={office}/>
          </div>
          <div className="col-xxl-7">
            <DashboardDataByCompany date={date} agent={agent} business={business} office={office}/>
          </div>
          <div className="col-xxl-5">
            <OperationAgentRanking date={date} showCommission={showCommission} agent={agent} business={business} office={office}/>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default DashboardPage;