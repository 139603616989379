import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import { toast } from "react-toastify";
import StatusDropdown from "../../../components/StatusDropdown";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import LeadsFilter from "./lead-options/LeadsFilter";
import { LeadService } from "../../../services/lead/leadService";
import { LeadsApiResponse } from "../../../type/lead-type";
import LeadModal from "../leads-create/LeadModal";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const LeadList = () => {
  const {userCan} = useContext(PrivilegeContext)

  const [lead, setLead] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  },[]);

  const fetchLeads = useCallback(async () => {
    const productService = new LeadService();
    const response = await productService.getLeads(filters);
    return response.getResponseData() as LeadsApiResponse;
  }, [filters, modal]);

  const [data, loading, error, refech] = useFetch(fetchLeads);


  const deleteLead = async (id: string) => {
    let response = (await new LeadService().deleteLead(id)).getResponseData();
    if (response.success) {
      toast.success('Tipo de Trámite eliminado correctamente');
      refech();
    } else {
      toast.error('Error al eliminar el tipo de trámite');
    }
  }


  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setLead(item);
    setModal(true);
  }

  
  const handleToggleStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new LeadService().toggleStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        toast.success('Tipo de Trámite cambiado de estado correctamente');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refech();
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Tipos de Trámites</CardTitle>
              {userCan('create','leads')?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('create','leads') ? '' : 'd-none' }`}
                  isLight
                  onClick={() => {
                    openModal(null, false);
                  }}
                >
                  Añadir Tipo de Trámite
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <LeadsFilter updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {
            data ? (
              <CustomTable
                data={data?.leads ? data.leads : null}
                pagination={true}
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: data as LeadsApiResponse ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                className={"table table-hover"}
                columns={
                  [
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {openModal(element, true);}}>
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Descripción",
                      keyValue: "description",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      cellClassName: "text-center",
                    },
                    {
                      name: "Creacion",
                      keyValue: "createdAt",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (item: any) => {
                          return <div className="fs-7 text-center">{moment(item.createdAt.date).format("DD/MM/YYYY H:mm")}</div>;
                      }
                    },
                    {
                        name: "Ult. Actualizacion",
                        keyValue: "updatedAt",
                        className: "text-center",
                        render: (item: any) => {
                            return <div className="fs-7 text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                        }
                    },
                    {
                      name: "Organización",
                      keyValue: "company",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: userCan('list','companies',true) ? "text-center" : "d-none",
                      isVisible: userCan('list','companies',true),
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            {element.company.name}
                          </div>
                        );
                      },
                    },
                    {
                      name: "Estado",
                      keyValue: "active",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      className: "text-center",
                      render: (element: any) => {
                        return (
                          <div className={'text-center'}>
                            <StatusDropdown
                              disabled={changingStatus.includes(element.id)}
                              itemId={element.id}
                              key={element.id}
                              status={element.active}
                              change={handleToggleStatus}
                            />
                          </div>
                        );
                      },
                    },

                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]
                }
                actions={
                [
                  {
                    title: "Editar",
                    buttonType: 'icon',
                    iconColor: 'text-info',
                    iconPath: '/media/icons/duotune/general/gen055.svg',
                    additionalClasses: `text-primary ${userCan('edit','leads')?'':'d-none'}`,
                    description: "Editar el producto",
                    callback: (item: any) => {
                      if(userCan('edit','leads')) openModal(item, false);
                    },
                  },

                  {
                    title: "Eliminar",
                    buttonType: 'icon',
                    iconColor: 'text-danger',
                    iconPath: '/media/icons/duotune/general/gen027.svg',
                    additionalClasses: `text-danger ${userCan('delete','leads')?'':'d-none'}`,
                    description: "Eliminar Producto",
                    callback: (item: any) => {
                      if(userCan('delete','leads')){
                        handleConfirmationAlert({
                          title: "Eliminar Lead",
                          text: "¿Está seguro que desea eliminar el lead? Todos los productos que hereden o contengan este Lead quedarán huérfanos.",
                          icon: "warning",
                          onConfirm: () => {
                            deleteLead(item.id);
                          }
                        })
                      }
                    },
                  },
                ]
                }
              />
            ) : (
              <div className="text-center d-flex justify-content-center"><Spinner/></div>
            )
          }
        </Card>
      </Page>
      {modal && (<LeadModal isOpen={modal} setIsOpen={setModal} readMode={readMode} setReadMode={setReadMode} lead={lead}  />)}
    </Fragment>
  );
};

export default LeadList;
