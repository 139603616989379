import { NewProduct, Product, UpdateProduct } from "../../type/product-type";
import { RestServiceConnection } from "../restServiceConnection";

const PRODUCT_ENDPOINT = '/products';
export class ProductService extends RestServiceConnection {
    
    createProduct = async (values: NewProduct) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/create',
            data: values
        }, true);
        return this;
    }

    editProduct = async (values: UpdateProduct) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getProducts = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/list',
            data: {...filters
            },
        }, true);
        return this;
    }

    getProductById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/get',
            data: {
                product: id
            }
        }, true);
        return this;
    }

    toggleProductStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/toggle',
            data: {
                product: id,
                active: status
            },
        }, true);
        return this;
    }

    addServices = async (idProduct: string, services: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/add-services',
            data: {
                product: idProduct,
                services: services
            }
        }, true);
        return this;
    }

    addService = async (productService: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/add-service',
            data: {
                ...productService
            }
        }, true);
        return this;
    }

    removeService = async (idProduct: string, idService: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/remove-service',
            data: {
                product: idProduct,
                service: idService
            }
        }, true);
        return this;
    }

    editService = async (productService: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/edit-service',
            data: {
                ...productService
            }
        }, true);
        return this;
    }

    deleteProduct = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/delete',
            data: {
                product: id
            }
        }, true);
        return this;
    }

    importProducts = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/import',
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    getProductAvailableServices = async (idProduct: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PRODUCT_ENDPOINT + '/available-services',
            data: {
                product: idProduct
            }
        }, true);
        return this;
    }
}