import { Fragment, useContext, useEffect, useState } from "react";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../../layout/SubHeader/SubHeader";
import Button, { ButtonGroup } from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import { TABS } from "../main-dashboard/common/helper";
import { OperationService } from "../../../services/operations/operationService";
import moment from "moment";
import { useUserProfile } from "../../../hooks/useListData";
import { useFormik } from "formik";
import AsyncImg from "../../../components/AsyncImg";
import OperationMenu from "./components/OperationMenu";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import RemainingTasks from "./components/RemainingTask";
import OperationAgentRankingByRanking from "./components/OperationAgentRankingByRanking";

export type customTabType = 'Hoy' | 'Esta semana' | 'Este mes' | 'Este año' | 'Personalizado';

export const CUSTOMTABS: { [key: string]: customTabType } = {
  TODAY : "Hoy",
  WEEKLY: "Esta semana",
  MONTHLY: 'Este mes',
  YEARLY: 'Este año',
  CUSTOM: 'Personalizado',
};

const OperationStatistic = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(CUSTOMTABS.MONTHLY);

  const [data, setData] = useState<any>([]);
  const { userCan } = useContext(PrivilegeContext);
  const [date, setDate] = useState<any>({ start: '', end: '' });  
  const user = useUserProfile();

  const getDateRange = () => {
    if (activeTab === TABS.YEARLY) {
      return { start: moment().set('month', 0).startOf('month').format('YYYY-MM-DD'), end: moment().set('month', 11).endOf('month').format('YYYY-MM-DD') };
    }
    if (activeTab === TABS.MONTHLY) {
      return { start: moment().startOf('month').format('YYYY-MM-DD'), end: moment().endOf('month').format('YYYY-MM-DD') };
    }
    if (activeTab === "Hoy") {
      return { start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') };
    }
    return { start: moment().startOf('week').format('YYYY-MM-DD'), end: moment().endOf('week').format('YYYY-MM-DD') };
  };

  const getStatistics = async () => {
    if (!user) return;
    let dateRange
    if (activeTab !== CUSTOMTABS.CUSTOM) {
      dateRange = getDateRange();
    } else {
      dateRange = { start: formik.values.start, end: formik.values.end };
    }
    setDate(dateRange);
    const operationService = new OperationService();
    const response = (await operationService.getStatistics(dateRange.start, dateRange.end, user?.id || "")).getResponseData();
    setData(response);
  }

  useEffect(() => {
    if (activeTab !== CUSTOMTABS.CUSTOM) {
      getStatistics();
    }
  }, [activeTab, user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start: '',
      end: ''
    },
    onSubmit: async (values) => {
      setDate(values);
      const operationService = new OperationService();
      const response = (await operationService.getStatistics(values.start, values.end, user?.id || "")).getResponseData();
      setData(response);
    },
  });

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(`/operations`, { replace: true })}
          ></Button>
        </SubHeaderLeft>
        <SubHeaderRight>
          <ButtonGroup>
            {Object.keys(CUSTOMTABS).map((key) => (
              <Button
                key={key}
                color={activeTab === CUSTOMTABS[key] ? "primary" : "light"}
                onClick={() => {
                  setActiveTab(CUSTOMTABS[key]);
                }}
              >
                {CUSTOMTABS[key]}
              </Button>
            ))}
          </ButtonGroup>
          {activeTab === CUSTOMTABS.CUSTOM && (
            <form className="d-flex align-items-center ms-4" onSubmit={formik.handleSubmit}>
              <input
                type="date"
                id="start"
                value={formik.values.start}
                onChange={formik.handleChange}
                max={formik.values.end}
                className="form-control"
                placeholder="Fecha inicio"
              />
              <span className="mx-2">-</span>
              <input
                type="date"
                id="end"
                value={formik.values.end}
                onChange={formik.handleChange}
                min={formik.values.start}
                className="form-control"
                placeholder="Fecha fin"
              />
              <Button color="primary" className="ms-2" type="submit">
                Buscar
              </Button>
            </form>
          )}
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <div className="row">
          <div className="col-6 mt-4">
            <div className='col-12 d-flex justify-content-center'>
              {user?.profileImg && (
                <AsyncImg isBackground height="350px" width="350px" styles="rounded p-2" id={user?.profileImg ? user?.profileImg?.id : null}/>
              )}
            </div>
            {userCan("show_agent_ranking", "dashboard") && user && (
              <div className={`col-12 mt-4`}>
                <RemainingTasks user={user} />
              </div>
            )}
          </div>
          <div className="col-6 mt-4">
            <OperationMenu date={date} totalOperations={data?.data?.total} />
          </div>
          {!userCan("show_agent_ranking", "dashboard") && (
            <div className="col-12 mt-4">
              <RemainingTasks user={user} />
            </div>
            )
          }
          {userCan("show_agent_ranking", "dashboard") ? (
            user?.rankings && user?.rankings.map((ranking: any) => (
              <div className={`col-6 mt-4 ${userCan("show_agent_ranking", "dashboard") ? '' : 'd-none'}`}>
                <OperationAgentRankingByRanking date={date} showCommission={true} rankingId={ranking.ranking.id} rankingName={ranking.ranking.name} rankingDescription={ranking.ranking.description} 
                users={ranking.ranking.users.map((user: any) => user.user)} />
              </div>
            ))
          ) : null}
        </div>
      </Page>
    </Fragment>
  );
};

export default OperationStatistic;
