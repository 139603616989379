import { FC, Fragment, useCallback, useContext, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import { CardBody, CardHeader, CardTitle } from "../../../../components/bootstrap/Card";
import {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../../layout/SubHeader/SubHeader";
import useFilters from "../../../../hooks/useFilters";
import useFetch from "../../../../hooks/useFetch";
import Spinner from "../../../../components/bootstrap/Spinner";
import { ServicesApiResponse } from "../../../../type/service-type";
import { ServiceService } from "../../../../services/services/servicesService";
import moment from "moment";
import ServicesFilters from "./services-options/ServicesFilters";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ServiceModal from "./ServiceModal";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../../components/table/CustomTable";
import StatusDropdown from "../../../../components/StatusDropdown";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";

interface ServicesListProps {
  businessId: string;
}
export interface IServicesFilters {
	active?: number;
	business?: string;
	cif?: string;
}

const ServiceList: FC<ServicesListProps> = ({ businessId }) => {
  const {userCan} = useContext(PrivilegeContext)
  const servicesFilters: IServicesFilters = {
    active: 1,
    business: businessId,
  };
  
  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(servicesFilters, [], 50);

  const [serviceId, setServiceId] = useState('');
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);

  const deleteService= async(id: string) => {
    let response = (await new ServiceService().deleteService(id)).getResponseData();
    if(response.success) {
      toast.success('Servicio eliminado con éxito');
    } else {
      toast.error('Error al eleminar el servicio');
    }
    resetFilters(50);
  }

  const fetchService = useCallback(async () => {
    const response = await new ServiceService().getServices({...filters, business: businessId});
    return response.getResponseData() as ServicesApiResponse;
  }, [filters, businessId, modal]);

  const [data, loading, error, refetch] = useFetch(fetchService);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleServiceStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new ServiceService().toggleServiceStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refetch();
  };

  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setServiceId(item.id);
    setModal(true);
  }

  return (
    <Fragment>
      <CardHeader>
      <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Servicios</CardTitle>
            {userCan('create','services')?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('delete','services')?'':'d-none'}`}
                  isLight
                  onClick={() => {
                    openModal('', false);
                  }}
                >

                  Añadir Servicio
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <ServicesFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
        </SubHeaderRight>
      </CardHeader>
      <CardBody>
        <Fragment>
          {
            data ? (
              <CustomTable
              data={data?.services ? data.services : null}
              pagination={true}
              paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: data as ServicesApiResponse ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
              }}
              className={"table table-hover"}
              columns={
                [
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {
                          setReadMode(true);
                          setServiceId(element.id); 
                          setModal(true);}}>
                          {element.name}
                        </div>
                      )
                    },
                  },
                  {
                    name: "Descripción",
                    keyValue: "description",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                  },
                  {
                    name: "Comercializadora",
                    keyValue: "business",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {                  
                      if(element.business) {
                        return (
                          <div key={element.business.id} className="d-flex justify-content-center">
                            {element.business.name}
                          </div>
                        )
                      }else return (<></>);
                    }
                  },
                  {
                    name: "Estado",
                    keyValue: "active",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          <StatusDropdown
                            disabled={changingStatus.includes(element.id)}
                            itemId={element.id}
                            status={element.active}
                            change={toggleServiceStatus}
                            key={element.id}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    name: "Fecha de creación",
                    keyValue: "createdAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className={'text-center'}>
                          <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                        </div>
                      )
                    },
                  },
                  {
                    name: "Última modificación",
                    keyValue: "updatedAt",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (item: any) => {
                      return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                    }
                  },
                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
                actions={
                  [
                    {
                      title: "Ver",
                      buttonType: 'icon',
                      iconColor: 'text-primary',
                      iconPath: '/media/icons/duotune/general/gen021.svg',
                      additionalClasses: `text-primary ${userCan('show','services')?'':'d-none'}`,
                      description: "Ver servicio",
                      callback: (item: any) => {
                        if(userCan('show','services')) openModal(item, true);
                      },
                    },
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-info',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${userCan('edit','services')?'':'d-none'}`,
                      description: "Editar servicio",
                      callback: (item: any) => {
                        if(userCan('edit','services')) openModal(item, false);
                      },
                    },
        
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${userCan('delete','services')?'':'d-none'}`,
                      description: "Eliminar servicio",
                      callback: (item: any) => {
                        if(userCan('delete','services')){
                          handleConfirmationAlert({
                            title: "Eliminar servicio",
                            text: "¿Está seguro que desea eliminar el servicio?",
                            icon: "warning",
                            onConfirm: () => {
                              deleteService(item.id);
                            }
                          })
                        }
                      },
                    },
                  ]
                  }
              />
            ) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
          }
        </Fragment>
      </CardBody>
      {modal && (<ServiceModal isOpen={modal} setIsOpen={setModal} serviceId={serviceId} readMode={readMode} businessId={businessId}/>)}
    </Fragment>
  );
};

export default ServiceList;
