import React, { ChangeEvent, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { CustomTable } from '../../../../components/table/CustomTable';
import { toast } from 'react-toastify';
import { CampaignService } from '../../../../services/campaigns/campaignService';
import moment from 'moment';
import { UserService } from '../../../../services/users/userService';
import StatusDropdown from '../../../../components/StatusDropdown';
import CampaignUserSelectorModal from '../../../../components/selectors/user_selector/CampaignUserSelectorModal';

interface UsersTableWizardProps {
    formik: any; // Añade el tipo correcto para formik
    reloadCampaign: Function;

}

const UsersTableWizard: React.FC<UsersTableWizardProps> = ({ formik, reloadCampaign }) => {

    const [modalUs, setModalUs] = useState(false);
    const [selectedRowsUsr, setSelectedRowsUsr] = useState<any[]>([]);
    const [selectAllUsr, setSelectAllUsr] = useState(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const handleActionSelected = async (array: any, selectArr: any[], setSelectArr: any) => {
        try {
            for (const rowId of selectArr) {
                const response = (await new CampaignService().removeUserFromCampaign(formik.values.id, rowId.id)).getResponseData();
            }
            toast.success('Usuario borrado con éxito');
            reloadCampaign();
            setSelectArr([]);
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleCheckboxChangeUsr = (
        id: string,
        event: ChangeEvent<HTMLInputElement>,
        element: any,
    ) => {
        const { checked } = event.target;
        let selectedItemsCopy = [...selectedRowsUsr];
        if (checked) {
            selectedItemsCopy.push(element);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item.id === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedRowsUsr(selectedItemsCopy);
    };

    const handleSelectAllUsr = (checked: boolean) => {
        setSelectAllUsr(!selectAllUsr);
        if (!selectAllUsr) {
            setSelectedRowsUsr(formik.values.users ? formik.values.users : []);
        } else {
            setSelectedRowsUsr([]);
        }
    };

    const toggleUserStatus = async (
        id: string,
        status: boolean,
        toggleStatus: Function
    ) => {
        try {
            setChangingStatus([...changingStatus, id]);
            let response = await (
                await new UserService().toggleUserStatus(id, status)
            ).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
        }
    };

    const _oncloseModalUs = async (userId: string) => {
        const response = (await new CampaignService().addUserToCampaign(formik.values.id, userId)).getResponseData()
        if (response.success == true) {
            toast.success(response.message);
            reloadCampaign();
        } else {
            response.data.errors.forEach((error: any) => {
                toast.error(error.message);
            });
            return;
        }
    }

    return (
        <>
            <Card>
                <CardHeader className='rounded-1 mb-0' borderSize={1}>
                    <CardLabel icon='Groups' iconColor='info'>
                        <CardTitle>Usuarios</CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button color="primary" icon="Add" onClick={() => setModalUs(true)}>Añadir Usuarios</Button>
                        <Button color="danger" icon="DeleteForever" onClick={() => handleActionSelected(formik.values.users, selectedRowsUsr, setSelectedRowsUsr)}>Borrar seleccionados</Button>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <CustomTable
                        data={formik.values.users ? formik.values.users : null}
                        className={"table table-hover"}
                        selectableItems={true}
                        onSelectAllItems={handleSelectAllUsr}
                        onChangeCheckedItems={handleCheckboxChangeUsr}
                        columns={[
                            {
                                name: "Nombre",
                                keyValue: "name",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Email",
                                keyValue: "email",
                                className: "text-center",
                                cellClassName: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {element.email}
                                        </div>
                                    );
                                }
                            },
                            {
                                name: "Teléfono",
                                keyValue: "telephone",
                                className: "text-center",
                                cellClassName: "text-center",
                            },
                            {
                                name: "Estado",
                                keyValue: "active",
                                className: "text-center",
                                render: (element: any) => {
                                    return (
                                        <div className={'text-center'}>
                                            <StatusDropdown
                                                disabled={changingStatus.includes(element.id)}
                                                itemId={element.id}
                                                status={element.active}
                                                change={toggleUserStatus}
                                                key={element.id}
                                            />
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Último acceso",
                                keyValue: "lastLogin",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className={'text-center'}>
                                            <span className={'text-muted'}>{element.lastLogin?.date && moment(element.lastLogin?.date).format('DD-MM-YYYY HH:mm')}</span>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            {modalUs && <CampaignUserSelectorModal isOpen={modalUs} setIsOpen={setModalUs} onClose={_oncloseModalUs} isRequired />}
        </>
    );
};

export default UsersTableWizard;
