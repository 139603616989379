import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import LeadSelector from '../../../../components/selectors/lead_selector/LeadSelector'
import { useBusinessList, useProductTypeList } from '../../../../hooks/useListData'

interface IProductFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any , hardReset?: boolean) => void
    filters: any,
    isShow?: boolean
}

const ProductsFilters: FC<IProductFiltersProps> = ({ updateFilters, filters, resetFilters, isShow }) => {

    const [filterMenu, setFilterMenu] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const businessList = useBusinessList();
    const productTypeList = useProductTypeList();

    const handleTextChange = (search: string) => {
        updateFilters({ search_text: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>

            <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''} />

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Productos </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado de producto'>
                                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })} value={filters.filter_filters?.active?.toString() || '1'}
                                    id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter2' label='Orientado a:'>
                                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ target: e.target.value })} value={filters.filter_filters?.target || 'all'}
                                    id='active-select' ariaLabel='Default select example' placeholder='...'
                                    list={[{ value: 'all', label: 'Todos', text: 'Todos' }, { value: 'individual', label: 'individual', text: 'Individual' }, { value: 'business', label: 'Empresa', text: 'Empresa' }]}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter3' label='Comercializadora'>
                                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ business: e.target.value })} value={filters.filter_filters.business} ariaLabel='Default select example' placeholder='Elegir Comercializadora...' list={businessList}/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter4' label='Nombre'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter5' label='Tipo de producto'>
                                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ productType: e.target.value })} value={filters.filter_filters.productType} ariaLabel='Default select example' placeholder='Elegir Tipo de producto...' list={productTypeList}/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                                <LeadSelector name='leads' isMulti={true} discardSelected={false} isRequired={false} onChange={(e: any) => updateFilters({ leads: e })} defaultSelected={filters.filter_filters.leads} />
                        </div>
                        <div className="col-12">
                            <FormGroup id='filter7' label='Potencia (kw)'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const updatedValue = e.target.value.replace(/,/g, '.');
                                    updateFilters({ power: updatedValue });
                                }} value={filters.filter_filters.power} id='power' placeholder='Potencia...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup id='filter8' label='Consumo (kw)'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const updatedValue = e.target.value.replace(/,/g, '.');
                                    updateFilters({ consumption: updatedValue });
                                }} value={filters.filter_filters.consumption} id='consumption' placeholder='Consumo...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <Button
                                className='mt-4'
                                color="storybook"
                                isLight
                                onClick={() => {
                                    if (isShow) {
                                        resetFilters(50)
                                    } else {
                                        resetFilters(50, true)
                                    }
                                    updateFilters({ leads : [] })
                                    setIsLoading(!isLoading)
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default ProductsFilters