import { FC, Fragment } from "react";
import { useFormik } from "formik";
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import Spinner from "../../components/bootstrap/Spinner";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import Select from "../../components/bootstrap/forms/Select";
import { toast } from "react-toastify";
import { useLoggedUserCompany } from "../../hooks/useListData";
import Tooltips from "../../components/bootstrap/Tooltips";
import Icon from "../../components/icon/Icon";
import { showErrors, verifyClass } from "../../utils/forms/VerifyForms";
import clsx from "clsx";
import Textarea from "../../components/bootstrap/forms/Textarea";
import { ValidateDNICIFField } from "../../utils/forms/ValidateDNICIFField";
import { ValidateIBANField } from "../../utils/forms/ValidateIBANField";
import moment from "moment";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    clientData?: any;
}

export interface IClientForm {
    client: string;
    name: string;
    birthDate?: string;
    favourite: boolean;
    nif: string;
    email?: string | null;
    phone1: string;
    phone2?: string | null;
    address: string;
    postalCode: string;
    locality: string;
    province: string;
    comments?: string;
    iban: string;
    type: string;
    company: string;
    company_representative?: string;
    company_representative_nif?: string;
}

// Add custom method for check if search is a valid DNI or a valid phone number
Yup.addMethod(Yup.string, 'isValidDNICIF', ValidateDNICIFField);
Yup.addMethod(Yup.string, 'isValidIBAN', ValidateIBANField);

const clientSchema = Yup.object().shape({
    name: Yup.string().required("El nombre es requerido"),
    nif: Yup.string()
        .required("El DNI/CIF es obligatorio")
        // @ts-ignore
        .isValidDNICIF("Introduce un DNI/CIF válido"),
    phone1: Yup.string(),
    phone2: Yup.string().nullable(),
    email: Yup.string().email("Introduce un email válido").nullable(),
    type: Yup.string().required("El tipo de cliente es obligatorio"),
    birthDate: Yup.string().test('birthDate', 'La fecha de nacimiento no puede ser mayor a la fecha actual', function (value: string | undefined) {
        return moment(value).isSameOrBefore(moment());
    }.bind(this)).nullable(),
    // @ts-ignore
    iban: Yup.string().isValidIBAN("El IBAN no es válido"),
    address: Yup.string().required("La dirección es obligatoria"),
    postalCode: Yup.string().required('El código postal del cliente es obligatorio').matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos'),
    company_representative: Yup
        .string()
        .nullable()
        .when(
            'type',
            {
                is: "EMPRESA",
                then: (schema) => Yup.string()
                    .min(3, "El campo debe contener más de 3 caracteres")
                    .required("El nombre del representante es obligatorio para los clientes de tipo empresa.")
            }
        ),
    company_representative_nif: Yup
        .string()
        .nullable()
        .when(
            'type',
            {
                is: "EMPRESA",
                then: (schema) => Yup.string()
                    .required("El DNI de representante es obligatorio para los clientes de tipo empresa.")
                    // @ts-ignore
                    .isValidDNICIF("Introduce un DNI/CIF válido")
            }
        ),
});

const ClientForm: FC<CreateFormProps> = ({ isLoading, submit, clientData }) => {
    const mode = clientData ? "Editar" : "Crear";

    const company = useLoggedUserCompany();

    const userInitialValues: IClientForm = {
        client: clientData?.id,
        name: clientData?.name,
        favourite: clientData?.favourite,
        birthDate: clientData?.birthDate?.date.slice(0, 10),
        nif: clientData?.nif,
        email: clientData?.email,
        phone1: clientData?.phone1,
        phone2: clientData?.phone2 || "+34",
        address: clientData?.address,
        postalCode: clientData?.postalCode,
        locality: clientData?.locality,
        province: clientData?.province,
        comments: clientData?.textComments,
        iban: clientData?.iban,
        type: clientData?.clientType,
        company: clientData?.company.id,
        company_representative: clientData?.representativeName,
        company_representative_nif: clientData?.representativeNIF,
    };

    const formik = useFormik({
        initialValues: userInitialValues,
        validationSchema: clientSchema,
        onSubmit: (values) => {
            if (values.phone2 == "" || values.phone2 == "+34") {
                values.phone2 = null;
            }
            if (values.email == "") {
                values.email = null;
            }
            if (values.phone1 == "" || values.phone1 == "+34" || values.phone1 == null) {
                toast.error("El teléfono es obligatorio");
            } else {
                values.company = company;
                submit(values);
            }
        },
    });

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Card stretch={true}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon="AccountBox" iconColor="info">
                            <CardTitle>{`${mode == "Crear" ? "Nuevo" : "Editar"
                                } Cliente`}</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <FormGroup label="Nombre:" className="col-md-6">
                                <Input
                                    id="name"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.name || ""}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass(formik, "name")}
                                />
                                {showErrors(formik, "name")}
                            </FormGroup>
                            <FormGroup requiredInputLabel label="NIF/CIF:" className="col-md-6">
                                <Input
                                    id="nif"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.nif}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass(formik, "nif")}
                                />
                                {showErrors(formik, "nif")}
                            </FormGroup>
                        </div>
                        <div className="row mt-3">
                            <FormGroup label="Email" className="col-md-6">
                                <Input
                                    id="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email || ""}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass(formik, "email")}
                                />
                                {showErrors(formik, "email")}
                            </FormGroup>
                            <FormGroup label="IBAN" className="col-md-6">
                                <Input
                                    id="iban"
                                    onChange={formik.handleChange}
                                    value={formik.values.iban}
                                    autoCorrect="new-iban"
                                    onBlur={formik.handleBlur}
                                    className={verifyClass(formik, "iban")}
                                />
                                {showErrors(formik, "iban")}
                            </FormGroup>
                        </div>
                        <div className="row mt-3">
                            <FormGroup label="Direccion" requiredInputLabel className="col-md-4">
                                <Input
                                    id="address"
                                    autoComplete="new-address"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.address || undefined}
                                />
                            </FormGroup>
                            <FormGroup
                                requiredInputLabel
                                label="Codigo Postal"
                                className="col-2"
                            >
                                <Input type="text" 
                                name="postalCode" 
                                autoComplete="new-postal-code"
                                placeholder="Codigo Postal"
                                required
                                className={verifyClass(formik, 'postalCode')} 
                                value={formik.values.postalCode} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} />
                                {showErrors(formik, 'postalCode')}
                            </FormGroup>
                            <FormGroup
                                requiredInputLabel
                                label="Localidad"
                                className="col-md-3"
                            >
                                <Input
                                    id="locality"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.locality}
                                />
                            </FormGroup>
                            <FormGroup
                                requiredInputLabel
                                label="Provincia"
                                className="col-md-3"
                            >
                                <Input
                                    id="province"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.province}
                                />
                            </FormGroup>

                        </div>
                        <div className="row mt-2">
                            <FormGroup
                                label="Tipo de Cliente:"
                                className="col-3"
                                requiredInputLabel
                            >
                                <Select
                                    id="type"
                                    ariaLabel="Tipo de Cliente"
                                    placeholder="Tipo de Cliente"
                                    list={[{ value: "PARTICULAR", label: 'Particular', text: 'Particular' }, { value: "AUTÓNOMO", label: 'Autónomo', text: 'Autónomo' }, { value: "EMPRESA", label: 'Empresa', text: 'Empresa' }]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.type || ""}
                                    className={verifyClass(formik, "type")}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Favorito"
                                className="col-1 text-center"
                            >
                                <div className="d-flex justify-content-center">
                                    <Tooltips title={formik.values.favourite ? "Quitar de favoritos" : "Añadir a favoritos"}>
                                        <Button className={`d-flex justify-content-center btn ${formik.values.favourite ? 'btn-outline-info' : 'btn-outline-light'}`} isLight onClick={() => formik.setFieldValue("favourite", !formik.values.favourite)}>
                                            {formik.values.favourite ? (
                                                <Icon icon="Star" color="info" size={"2x"} />
                                            ) : (
                                                <Icon icon="Star" color="gray" size={"2x"} />
                                            )}
                                        </Button>
                                    </Tooltips>
                                </div>
                            </FormGroup>
                            <div className="row mt-2 col-5">
                                <FormGroup
                                    requiredInputLabel
                                    label="Teléfono"
                                    className="col-6"
                                >
                                    <PhoneInput
                                        onlyCountries={["es"]}
                                        country={"es"}
                                        autoFormat={false}
                                        countryCodeEditable={false}
                                        onChange={(phone: string) => {
                                            formik.setFieldValue("phone1", "+" + phone);
                                        }}
                                        value={formik.values.phone1}
                                        inputClass={verifyClass(formik, "phone1")}
                                    />
                                    {showErrors(formik, "phone1")}
                                </FormGroup>
                                <FormGroup label="Teléfono 2" className="col-6">
                                    <PhoneInput
                                        onlyCountries={["es"]}
                                        country={"es"}
                                        autoFormat={false}
                                        countryCodeEditable={false}
                                        onChange={(phone: string) => {
                                            if (phone.length == 0) {
                                                formik.setFieldValue("phone2", null);
                                                return;
                                            }
                                            formik.setFieldValue("phone2", "+" + phone);
                                        }}
                                        value={formik.values.phone2}
                                        inputClass={verifyClass(formik, "phone2")}
                                    />
                                    {showErrors(formik, "phone2")}
                                </FormGroup>
                            </div>
                        <div className="col-3 mt-2">
                            <FormGroup
                                label="Fecha de nacimiento"
                                className="col-12"
                            >
                                <Input id='birthDate' type='date' onChange={formik.handleChange}
                                value={formik.values.birthDate || ''} className={verifyClass(formik, 'birthDate')} />
                                {showErrors(formik, 'birthDate')}
                            </FormGroup>
                        </div>
                        </div>

                        <div className={
                            clsx({
                                'd-none': formik.values.type != "EMPRESA"
                            })
                        }>
                            <hr />
                            <div className="row">
                                <FormGroup label="Nombre Representante" requiredInputLabel className="col-md-3">
                                    <Input
                                        id="company_representative"
                                        autoComplete="new-address"
                                        className={verifyClass(formik, "company_representative")}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company_representative || undefined}
                                    />
                                    {showErrors(formik, "company_representative")}
                                </FormGroup>
                                <FormGroup label="NIF Representante" className="col-md-3" requiredInputLabel>
                                    <Input
                                        id="company_representative_nif"
                                        className={verifyClass(formik, "company_representative_nif")}
                                        autoComplete="new-address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company_representative_nif || undefined}
                                    />
                                    {showErrors(formik, "company_representative_nif")}
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <FormGroup label="Comentarios generales" className="col-md-12">
                                <Textarea id="comments" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.comments} />
                            </FormGroup>
                        </div>
                    </CardBody>
                </Card>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <Button type="submit" size="lg" color="primary">
                            {isLoading ? <Spinner /> : `${mode} Cliente`}
                        </Button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default ClientForm;
