import { FiltersProvider } from "../../components/providers/FiltersProvider";
import RankingList from "./ranking-list/RankingList";

const RankingListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <RankingList />
        </FiltersProvider>
      </>
    );
  };

export default RankingListWrapper;