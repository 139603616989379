import { AxiosResponse } from "axios";
import { ExpenseType, NewExpenseType } from "../../type/expense-type";
import { RestServiceConnection } from "../restServiceConnection";

const EXPENSE_TYPE_ENDPOINT = '/expense-types';

export class ExpenseTypeService extends RestServiceConnection {
    
    createExpenseType = async (expense : NewExpenseType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSE_TYPE_ENDPOINT + '/create',
            data: expense,
        }, true) as AxiosResponse;;
        return this;
    }

    editExpenseType = async (expense : ExpenseType) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSE_TYPE_ENDPOINT + '/edit',
            data: expense,
        }, true) as AxiosResponse;;
        return this;
    }

    getExpenseTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSE_TYPE_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    editFixedExpenseType = async (expense: ExpenseType) => {
        expense.fixed = !expense.fixed;
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSE_TYPE_ENDPOINT + '/edit',
            data: expense,
        }, true) as AxiosResponse;;
        return this;
    }

    deleteExpenseType = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSE_TYPE_ENDPOINT + '/delete',
            data: {
                expenseType: id
            },
        }, true) as AxiosResponse;
        return this;
    }
}