import { ChangeEvent, FC, useState } from "react";
import Button from "../bootstrap/Button";
import Tooltips from "../bootstrap/Tooltips";
import { OperationService } from "../../services/operations/operationService";
import { usePrivilege } from "../priviledge/PriviledgeProvider";
import useHandleErrors from "../../hooks/useHandleErrors";
import Spinner from "../bootstrap/Spinner";
import { toast } from "react-toastify";
import { ClientService } from "../../services/clients/clientService";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from "../bootstrap/forms/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validateDNI } from "../../utils/dniValidator";

export interface SearchButtonProps {
  formik: any;
  setIsClientSelected: any;
  operationTypeList: any;
}

interface SearchClientData {
  searchText: string;
}

const schema = Yup.object().shape({
  searchText: Yup.string()
    .test('conditionalValidation', 'Introduce un formato de DNI válido', function (value) {
      if (value !== undefined && value.length > 0) {
        return Yup.string()
          .matches(/^\d{8}[a-zA-Z]$/, "Introduce un formato de DNI válido")
          .test("isValidDNI", "Introduce un DNI real", function (value) {
            if (value !== undefined && value.length > 0) {
              return validateDNI(value);
            }
            return true;
          })
          .isValidSync(value);
      }
      return true;
    }),
});

export const SearchClientButton: FC<SearchButtonProps> = ({ formik, setIsClientSelected, operationTypeList }) => {

  const defaultText = "Buscar Cliente";

  const [title, setTitle] = useState<string>(defaultText);
  const [loading, setLoading] = useState<boolean>(false);

  const { userCan } = usePrivilege();
  const { handleErrors } = useHandleErrors();

  // Formik Form

  const showErrors = (inputFieldID: keyof SearchClientData) => {
    const errors = formikForm.errors[inputFieldID];
    if (formikForm.touched[inputFieldID] && errors) {
      const errorMessages = Array.isArray(errors)
        ? errors.join(", ")
        : String(errors);
      return (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {errorMessages}
        </div>
      );
    }
    return <></>;
  };

  const verifyClass = (inputFieldID: keyof SearchClientData) => {
    if (formikForm.touched[inputFieldID]) {
      return formikForm.errors[inputFieldID] ? "is-invalid" : "";
    }
    return "";
  };

  const verifyButtonClass = (inputFieldID: keyof SearchClientData) => {
    if (formikForm.touched[inputFieldID]) {
      return formikForm.errors[inputFieldID] ? "align-items-center" : "align-items-end";
    }
    return "align-items-end";
  }

  const formikForm = useFormik({
    initialValues: {
      searchText: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      _getClient(values.searchText);
    },
  });

  // Get Client Data

  const _handleLoading = (loadingStatus: boolean) => {
    if (loadingStatus) {
      setTitle("Cargando...");
      setLoading(true);
    } else {
      setTitle(defaultText);
      setLoading(false);
    }
  };

  const _getClient = async (data: string) => {
    _handleLoading(true);

    if (data === "") {
      toast.warning("Debe introducir un dni para buscar");
      _handleLoading(false);
      return;
    }

    const response = await new ClientService().findClientData(data);
    const responseData = response.getResponseData();

    if (response.getResponse()?.status !== 200) {
      toast.error("Error al buscar cliente");
      _handleLoading(false);
      return;
    }
    
    if (!responseData.success) {
      handleErrors(responseData);
      _handleLoading(false);
      
      // operationType Go to Caption cause is new client
      let operationType = operationTypeList.find((item: any) => {
        return item.label === "Captación";
      });

      if(operationType){
        formik.setFieldValue("operation_type", operationType.value);
      }

      return;
    }

    if (responseData.data.length === 0) {
      toast.warning(responseData.message);
      _handleLoading(false);

      
      return;
    }

    toast.success(responseData.message);
    _handleLoading(false);
    setIsClientSelected(true);
    _fillClient(responseData.data);
  };

  // Fill Client Data

  const _fillClient = (data: any) => {
    formik.setFieldValue("client", data.id);
    formik.setFieldValue("name", data.name);
    formik.setFieldValue("nif", data.nif);
    formik.setFieldValue("email", data.email || null);
    formik.setFieldValue("address", data.address);
    formik.setFieldValue("phone1", data.phone1);
    formik.setFieldValue("phone2", data.phone2 || "+34");
    formik.setFieldValue("locality", data.locality);
    formik.setFieldValue("province", data.province);
    formik.setFieldValue("postalCode", data.postalCode);
    formik.setFieldValue("iban", data.iban);
    formik.setFieldValue("favourite", data.favorite);
    // Detect operation for on loaded client
    if (data.clientType === "EMPRESA" || data.clientType === "AUTONOMO") {
      formik.setFieldValue('operation_for', "EMPRESA")
    } else {
      formik.setFieldValue('operation_for', "PARTICULAR")
    }

    // operationType Go to Renewal cause is old client
    let operationType = operationTypeList.find((item: any) => {
      return item.label === "Renovación";
    });

    if(operationType){
      formik.setFieldValue("operation_type", operationType.value);
    }

    
  };

  // Clear Client Data

  const _clearClient = () => {
    if (formik.values.client) {
      setIsClientSelected(false);
      formik.setFieldValue("client", "");
      formik.setFieldValue("name", "");
      formik.setFieldValue("nif", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("phone1", "+34");
      formik.setFieldValue("phone2", "+34");
      formik.setFieldValue("locality", "");
      formik.setFieldValue("province", "");
      formik.setFieldValue("address", "");
      formik.setFieldValue("postalCode", null);
      formik.setFieldValue("iban", "");
      toast.success("Cliente limpiado");
    }
  };

  return (
      <div className="row mb-3">
        {userCan("find_client_data", "clients") ? (
          <>
            <div className="col-10">
              <FormGroup label="Buscar Cliente">
                <Input
                  id="searchText"
                  onChange={formikForm.handleChange}
                  placeholder="Introduzca DNI"
                  value={formikForm.values.searchText}
                  onBlur={formikForm.handleBlur}
                  className={verifyClass("searchText")}
                />
                {showErrors("searchText")}
              </FormGroup>
            </div>
            <div className={`col-1 d-flex justify-content-center ${verifyButtonClass("searchText")}`}>
              <Tooltips placement="top" title={title}>
                {loading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="submit"
                    color="info"
                    isLight
                    icon="Search"
                    onClick={formikForm.handleSubmit}
                  ></Button>
                )}
              </Tooltips>
            </div>
            <div className={`col-1 d-flex justify-content-center ${verifyButtonClass("searchText")}`}>
              <Tooltips placement="top" title={"Limpiar Cliente"}>
                <Button
                  type="button"
                  color="danger"
                  isLight
                  icon="Delete"
                  onClick={_clearClient}
                ></Button>
              </Tooltips>
            </div>
          </>
        ) : null}
      </div>
  );
};
