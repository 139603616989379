import { AxiosResponse } from "axios";
import { RestServiceConnection } from "../restServiceConnection";

const CALL_ENDPOINT = '/calls';

export class CallService extends RestServiceConnection {

    /**
     * 
     * @EN This function is responsible to launch a call
     * @ES Esta función se encarga de lanzar una llamada
     * 
     * @param phone
     * @returns Launched Call
     */
    
    launchCall = async (phone: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CALL_ENDPOINT + '/launch-call',
            data: {
                phone_number: phone
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible to get the current call
     * @ES Esta función se encarga de obtener la llamada actual
     * 
     * @returns Current Call
     */

    getCurrentCall = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CALL_ENDPOINT + '/get-current-call',
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible to add a call record from the current call
     * @ES Esta función se encarga de añadir un registro de llamada desde la llamada actual
     * 
     * @param operationId
     * @returns Added Call Record
     */

    addFromCurrentCallRecord = async (operationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url:  `${CALL_ENDPOINT}/records/add-from-current-call`,
            data: {
                operation: operationId
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible to get the call records
     * @ES Esta función se encarga de obtener los registros de llamada
     * 
     * @param filters
     * @returns List of Call Records
     */

    getCalls = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CALL_ENDPOINT + '/list-calls',
            data: filters, 
        }, true) as AxiosResponse;
        return this;
    }
}