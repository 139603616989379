import { FC, PropsWithChildren, useState } from "react"
import Button from "../bootstrap/Button"
import Tooltips from "../bootstrap/Tooltips"
import { usePrivilege } from "../priviledge/PriviledgeProvider"
import useHandleErrors from "../../hooks/useHandleErrors"
import Spinner from "../bootstrap/Spinner"
import { toast } from "react-toastify"
import { CallInfo } from "../../type/calls/callInfo-type"
import {CallService} from "../../services/calls/servicesService";

export interface LoadFromCallButtonProps {
    onReceiveData: (success: boolean, data: CallInfo|null) => void | Promise<void>
}

/**
 * Component for manage the load from call button. This component is used for load data from the current call.
 */
export const LoadFromCallButton: FC<PropsWithChildren<LoadFromCallButtonProps>> = ({children, onReceiveData}) => {

    const defaultText = 'Cargar desde la llamada actual'

    const [callData, setCallData] = useState<any>(null)
    const [title, setTitle] = useState<string>(defaultText)
    const [loading, setLoading] = useState<boolean>(false)

    const {userCan} = usePrivilege()
    const {handleErrors} = useHandleErrors();

    const _handleLoading = (loadingStatus: boolean) => {
        if (loadingStatus) {
            setTitle("Cargando...")
            setLoading(true)
        } else {
            setTitle(defaultText)
            setLoading(false)
        }
    }

    const _getCurrentCall = async () => {
        _handleLoading(true)
        
        const response = (await (new CallService()).getCurrentCall());
        const responseData = response.getResponseData();
        
        if(response.getResponse()?.status !== 200) {
            toast.error("Error al cargar la llamada actual");
            _handleLoading(false);
            return;
        }

        if (!responseData.success) {
            handleErrors(responseData);
            _handleLoading(false);
            return;
        }

        if(responseData.data.length === 0) {
            toast.warning(responseData.message);
            _handleLoading(false);
            return;
        }

        let callData = responseData.data as CallInfo;
        
        onReceiveData(true, callData);

        _handleLoading(false);
    }

    return (
        <section className="LoadFromCallButton">
            {
                userCan('get_current_call_data', 'operations') ? (
                    <Tooltips placement="top" title={title} >
                        {
                            loading ? (
                                <Spinner />
                            ) : (
                                <Button type="button" color="info" isLight icon="Call" onClick={_getCurrentCall}></Button>
                            )
                        }
                    </Tooltips>
                ) : null
            }
        </section>
        
    )
}