import { useNavigate } from "react-router-dom";
import { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { ProductService } from "../../../services/products/productService";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import { ProductsApiResponse } from "../../../type/product-type";
import ProductsFilters from "./product-options/ProductsFilters";
import Button from "../../../components/bootstrap/Button";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import ProductsTableComponent from "../../../components/table/Products/ProductTableComponent";

interface IProductListProps {
  businessId?: string;
}

const ProductsList: FC<IProductListProps> = ({ businessId }) => {

  let defaultColumns = ["name", "fee", "power", "consumption", "baseCommission", "priority", "points", "productType", "operationTypes", "leads", "status", "clientType", "createdAt"];

  const { userCan } = useContext(PrivilegeContext)
  const navigate = useNavigate();
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchProducts = useCallback(async () => {
    const productService = new ProductService();
    const response = await productService.getProducts(filters);
    return response.getResponseData() as ProductsApiResponse;
  }, [filters]);

  const [data, loading, error, refetch] = useFetch(fetchProducts);

  return (
    <Fragment>
      {businessId ? (
        <>
          <CardHeader>
            <SubHeaderLeft>
              <Fragment>
                <CardTitle>Listado de Productos</CardTitle>
                <SubheaderSeparator />
                {userCan('create', 'products') && (
                  <Button
                    color="light"
                    icon="Add"
                    className={`${userCan('create', 'products') ? '' : 'd-none'}`}
                    isLight
                    onClick={() => {
                      if (businessId) {
                        navigate(`products/create`);
                      } else {
                        navigate('/product-create');
                      }
                    }}
                  >
                    Añadir
                  </Button>
                )}
                {userCan('import', 'products') && businessId === undefined && (
                  <Button
                    color="light"
                    icon="CloudUpload"
                    isLight
                    onClick={() => {
                      navigate('/products/import');
                    }}
                  >
                    Importar
                  </Button>
                )}
              </Fragment>
            </SubHeaderLeft>
            <SubHeaderRight>
              <ProductsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} isShow={true}/>
            </SubHeaderRight>
          </CardHeader>
          <CardBody>
            <Fragment>
              {
                <ProductsTableComponent
                  filters={filters}
                  updateFilters={updateFilters}
                  updatePage={updatePage}
                  updatePageSize={updatePageSize}
                  updateFilterOrder={updateFilterOrder}
                  classNameProps="table table-hover fs-7"
                  data={data}
                  refetch={() => {
                    refetch();
                  }}
                  visibleActions={['show', 'edit', 'delete']}
                  visibleColums={visibleColumns}
                  defaultLimit={filters.limit || 50}
                  defaultOrder={filters.filter_order || undefined}
                  businessId={businessId}
                />
              }
            </Fragment>
          </CardBody>
        </>
      ) : (
        <>
          <SubHeader>
            <SubHeaderLeft>
              <Fragment>
                <CardTitle>Listado de Productos</CardTitle>
                <SubheaderSeparator />
                {userCan('create', 'products') && (
                  <Button
                    color="light"
                    icon="Add"
                    className={`${userCan('create', 'products') ? '' : 'd-none'}`}
                    isLight
                    onClick={() => {
                      if (businessId) {
                        navigate(`products/create`);
                      } else {
                        navigate('/product-create');
                      }
                    }}
                  >
                    Añadir
                  </Button>
                )}
                {userCan('import', 'products') && businessId === undefined && (
                  <Button
                    color="light"
                    icon="CloudUpload"
                    isLight
                    onClick={() => {
                      navigate('/products/import');
                    }}
                  >
                    Importar
                  </Button>
                )}
              </Fragment>
            </SubHeaderLeft>
            <SubHeaderRight>
              <ProductsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
            </SubHeaderRight>
          </SubHeader>
          <Page container="fluid">
            <Card stretch={false}>{
                <ProductsTableComponent
                  filters={filters}
                  updateFilters={updateFilters}
                  updatePage={updatePage}
                  updatePageSize={updatePageSize}
                  updateFilterOrder={updateFilterOrder}
                  classNameProps="table table-hover fs-7"
                  data={data}
                  refetch={() => {
                    refetch();
                  }}
                  visibleActions={['show', 'edit', 'delete']}
                  visibleColums={[...visibleColumns, "business"]}
                  defaultLimit={filters.limit || 50}
                  defaultOrder={filters.filter_order || undefined}
                />
            }</Card>
          </Page>
        </>
      )}
    </Fragment>
  );
};

export default ProductsList;
