import { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { RootState } from "../../../redux/store";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import ClientCallList from "./ClientCallList";

interface IClientCallWrapperProps {
    clientId: string;
}

const ClientCallWrapper :FC<IClientCallWrapperProps> = ({clientId}) => {

  let callFilters : any = {
    client: clientId,
  };

  const { userCan } = useContext(PrivilegeContext);

  const user = useSelector((state: RootState) => state.auth.user);
  
  if (userCan("show_own", "calls") && !userCan("show_all", "calls")) {
    callFilters = {
        ...callFilters,
        user : user?.id,
    };
  }
  
    return (
      <>
        <FiltersProvider defaultFilterFilters={callFilters}>
          <ClientCallList/>
        </FiltersProvider>
      </>
    );
};

export default ClientCallWrapper;