import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import { useSelector } from 'react-redux'
import { AuthState } from '../../../../redux/authSlice'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";

interface IPendingTaskFiltersFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const PendingTaskFilters: FC<IPendingTaskFiltersFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false);

  const rol = useSelector((state: { auth: AuthState }) => {
    const userRoles = state.auth.user?.roles;
    return userRoles && (userRoles.includes('Superadministrador') || userRoles.includes('Administrador')) ? 'admin' : userRoles?.[0];
  });

  const handleTextChange = (search: string) => {
    updateFilters({ search_text: search });
  };


  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>
      
      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Contratos </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            <div className='col-12'>
              <FormGroup id='filter1' label='Localidad'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ locality: e.target.value })}
                  value={filters.filter_filters.locality} id='locality' placeholder='Localidad...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter2' label='Provincia'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ province: e.target.value })}
                  value={filters.filter_filters.province} id='province' placeholder='Provincia...' autoComplete='off' />
              </FormGroup>
            </div> 
            <div className='col-12'>
              <FormGroup id='filter3' label='Clientes:'>
                <Select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ favourite: parseInt(e.target.value) })}
                  value={filters.filter_filters.favourite}
                  id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                  list={[{ value: 2, label: 'Todos los Clientes', text: 'Todos los Clientes' }, { value: 1, label: 'Clientes favoritos', text: 'Clientes favoritos' }, { value: 0, label: 'Clientes no favoritos', text: 'Clientes no favoritos' }]}
                />
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => {
                resetFilters(50,true)
              }}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    </Fragment>
  )
}

export default PendingTaskFilters