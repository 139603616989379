import { NewCLient, UpdateClient } from '../../type/client-type';
import { RestServiceConnection } from '../restServiceConnection';

// ENDPOINT

const CLIENTS_ENDPOINT = '/clients';

// SERVICE

export class ClientService extends RestServiceConnection {

    /**
     * 
     * @EN This function is responsible for creating a client
     * @ES Esta función se encarga de crear un cliente
     * 
     * @param data 
     * @returns Create a client
     */

    createClient = async (data : NewCLient) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/create',
            data: data,
            headers: {
                "Content-Type": "application/json"
            },
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for creating a client comment
     * @ES Esta función se encarga de crear un comentario de cliente
     * 
     * @param data
     * @returns Create a client comment
     */

    createClientComment = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/add-comment',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for creating a client relationship
     * @ES Esta función se encarga de crear una relación de cliente
     * 
     * @param data
     * @returns Create a client relationship
     */

    createClientRelation = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/add-related-client',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for updating a client
     * @ES Esta función se encarga de actualizar un cliente
     * 
     * @param data
     * @returns Update a client
     */

    updateClient = async (data: UpdateClient) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/edit',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for updating a client comment
     * @ES Esta función se encarga de actualizar un comentario de cliente
     * 
     * @param data
     * @returns Update a client comment
     */

    updateClientComment = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/edit-comment',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for updating a client relationship
     * @ES Esta función se encarga de actualizar una relación de cliente
     * 
     * @param data
     * @returns Update a client relationship
     */

    updateClientRelation = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/edit-related-client',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the clients
     * @ES Esta función se encarga de obtener los clientes
     * 
     * @param filters
     * @returns Fetch the clients
     */

    getClients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/list',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client comments
     * @ES Esta función se encarga de obtener los comentarios del cliente
     * 
     * @param filters
     * @returns Fetch the client comments
     */

    getComments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get-comments',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client relationships
     * @ES Esta función se encarga de obtener las relaciones del cliente
     * 
     * @param filters
     * @returns Fetch the client relationships
     */

    getRelatedClients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get-related-clients',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching a client by id
     * @ES Esta función se encarga de obtener un cliente por id
     * 
     * @param id
     * @returns Fetch a client by id
     */

    getClientById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get',
            data: {
                client: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching a client by dni or phone
     * @ES Esta función se encarga de obtener un cliente por dni o teléfono
     * 
     * @param data
     * @returns Fetch a client by dni or phone
     */

    getClientByDniOrPhone = async (data?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/search-client-data',
            data: data ? {search : data} : {},
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client by dni
     * @ES Esta función se encarga de obtener el cliente por dni
     * 
     * @param dni
     * @returns Fetch the client by dni
     */

    toggleClientStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/toggle',
            data: {
                client: id,
                active: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for deleting a client
     * @ES Esta función se encarga de eliminar un cliente
     * 
     * @param clientId
     * @returns Delete a client
     */

    deleteClient = async (clientId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/delete',
            data: {
                client: clientId
            }
        }, true);

        return this
    }

    /**
     * 
     * @EN This function is responsible for deleting a client comment
     * @ES Esta función se encarga de eliminar un comentario de cliente
     * 
     * @param commentId
     * @param clientId
     * @returns Delete a client comment
     */

    deleteComment = async (commentId: string, clientId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/remove-comment',
            data: {
                client: clientId,
                comment: commentId
            }
        }, true);

        return this
    }

    /**
     * 
     * @EN This function is responsible for deleting a client relationship
     * @ES Esta función se encarga de eliminar una relación de cliente
     * 
     * @param relationId
     * @param clientId
     * @returns Delete a client relationship
     */

    deleteRelatedClient = async (relationId: string, clientId: string, relatedClientId: string) => {
            
            this.response = await this.makeRequest({
                method: 'POST',
                url: CLIENTS_ENDPOINT + '/remove-related-client',
                data: {
                    client: clientId,
                    relation: relationId,
                    relatedClient: relatedClientId
                }
            }, true);
    
            return this
    }

    /**
     * 
     * @EN This function is responsible for finding a client by dni
     * @ES Esta función se encarga de buscar un cliente por dni
     * 
     * @param dni
     * @returns Find a client by dni
     */

    findClientData = async (dni: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/find-client-data',
            data: {
                nif: dni
            }
        }, true);

        return this
    }

    /**
     * 
     * @EN This function is responsible for checking if the client has changed
     * @ES Esta función se encarga de comprobar si el cliente ha cambiado
     * 
     * @param data
     * @returns Check if the client has changed
     */

    isChanged = async (data : NewCLient) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/isChanged',
            data: data,
        }, true);

        return this
    }

    /**
     * 
     * @EN This function is responsible for fetching the client's cups
     * @ES Esta función se encarga de obtener los cups del cliente
     * 
     * @param id
     * @returns Fetch the client's cups
     */

    toggleClientFavourite = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/toggle-favourite',
            data: {
                client: id,
                favourite: status
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client's cups
     * @ES Esta función se encarga de obtener los cups del cliente
     * 
     * @param id
     * @returns Fetch the client's cups
     */

    getClientCups = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get-client-cups',
            data: {
                client: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client's cups
     * @ES Esta función se encarga de obtener los cups del cliente
     * 
     * @param ids
     * @returns Fetch the client's cups
     */

    getClientCupsAll = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get-client-cups-all',
            data: {
                client: id
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    /**
     * 
     * @EN This function is responsible for fetching the client's cups
     * @ES Esta función se encarga de obtener los clientes por cups
     * 
     * @param cups
     * @returns Fetch the client's cups
     */

    getClientByCups = async (cups: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CLIENTS_ENDPOINT + '/get-client-by-cups',
            data: {
                cups: cups
            },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }
}