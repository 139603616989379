import { FC, Fragment, useEffect } from "react";
import { Product } from "../../type/product-type";
import { Users } from "../../type/user-type";
import { useFormik } from "formik";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import Spinner from "../../components/bootstrap/Spinner";
import * as Yup from "yup";
import Select from "../../components/bootstrap/forms/Select";
import { useSelector } from "react-redux";
import { AuthState } from "../../redux/authSlice";
import { useCompanyList } from "../../hooks/useListData";
import ProductsTableWizard from "./campaign-edit/componetsFormEdit/ProductTableWizard";
import UsersTableWizard from "./campaign-edit/componetsFormEdit/UserTableWizard";
import OfficesTableWizard from "./campaign-edit/componetsFormEdit/OfficeTableWizard";
import CampaignStatusTableWizard from "../../components/selectors/status_selector/CampaignStatusTableWizard";
import CampaignChangeStatusTableWizard from "../../components/selectors/status_selector/CampaignChangeStatusTableWizard";
import PostalCodeRestrictionsTableWizard from "./campaign-edit/componetsFormEdit/PostalCodeRestrictionsTableWizard";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    campaignData?: any;
    reloadCampaign: Function;
}

export interface ICampaignForm {
    id: string;
    name: string;
    description: string;
    clientType: string;
    company: string;
    products: Product[] | undefined;
    users: Users[] | undefined;
    offices: any[] | undefined;
    postalCodeRestrictions: any[] | undefined;
}

const campaignSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    description: Yup.string().required('La descripcion es requerida'),
    company: Yup.string().required('La compañia es requerida'),
});

let options: any = [
    { value: 'ALL', label: 'Todos' },
    { value: 'PARTICULAR', label: 'Particular' },
    { value: 'EMPRESA', label: 'Empresa' }
];

const CampaignEditForm: FC<CreateFormProps> = ({ isLoading, submit, campaignData, reloadCampaign }) => {

    const userInitialValues: ICampaignForm = {
        id: campaignData?.id,
        name: campaignData?.name,
        description: campaignData?.description,
        clientType: campaignData?.clientType,
        company: campaignData?.company.id,
        products: campaignData?.products,
        users: campaignData?.users,
        offices: campaignData?.offices,
        postalCodeRestrictions: campaignData?.postalCodeRestrictions
    }

    const rol = useSelector((state: { auth: AuthState }) => {
        const userRoles = state.auth.user?.roles;
        return userRoles && (userRoles.includes('Superadministrador') ? 'Superadministrador' : userRoles?.[0]);
    });

    const companyList = useCompanyList();

    const formik = useFormik({
        initialValues: userInitialValues,
        validationSchema: campaignSchema,
        onSubmit: (values) => {
            submit(values);
        },
    });

    const verifyClass = (inputFieldID: keyof ICampaignForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof ICampaignForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    useEffect(() => {
        formik.setFieldValue('products', campaignData?.products);
        formik.setFieldValue('users', campaignData?.users);
        formik.setFieldValue('offices', campaignData?.offices);
        formik.setFieldValue('postalCodeRestrictions', campaignData?.postalCodeRestrictions);
    }, [campaignData]);

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className='col-md-6'>
                        <Card>
                            <CardBody isScrollable={false}>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <CardHeader className='rounded-1 mb-0'>
                                            <CardLabel icon='TextSnippet' iconColor='info'>
                                                <CardTitle>Información de el Departamento</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <FormGroup requiredInputLabel label='Nombre y apellidos' className={rol === "Superadministrador" ? 'col-md-4' : 'col-md-6'}>
                                                    <Input id='name' required onChange={formik.handleChange} value={formik.values.name || ''}
                                                        onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                    {showErrors('name')}
                                                </FormGroup>
                                                <FormGroup requiredInputLabel label='Descripcion' className={rol === "Superadministrador" ? 'col-md-4' : 'col-md-6'}>
                                                    <Input id='description' required type='text' onChange={formik.handleChange}
                                                        value={formik.values.description || ''} onBlur={formik.handleBlur}
                                                        className={verifyClass('description')} />
                                                    {showErrors('description')}
                                                </FormGroup>
                                                <FormGroup requiredInputLabel label='Tipo de Cliente' className={rol === "Superadministrador" ? 'col-md-12 mt-2' : 'col-md-12 mt-2'}>
                                                    <Select
                                                        onChange={(e: any) => { formik.setFieldValue('clientType', e.target.value) } }
                                                        value={formik.values.clientType}
                                                        id='active-select' ariaLabel='Default select example' placeholder='Tipo de cliente...'
                                                        list={options}
                                                    />
                                                    {showErrors('clientType')}
                                                </FormGroup>
                                                <FormGroup requiredInputLabel label='Descripcion' className={rol === "Superadministrador" ? 'col-md-4' : 'd-none'}>
                                                    <Select id='SelectCompany' required={rol === "Superadministrador" ? true : false} ariaLabel='Select Company' placeholder='Elegir Compañia...' onChange={formik.handleChange} value={formik.values.company} list={companyList} className={verifyClass('company')} />
                                                    {showErrors('company')}
                                                </FormGroup>
                                            </div>
                                        </CardBody>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        <CampaignStatusTableWizard campaign={formik.values.id} />

                        <CampaignChangeStatusTableWizard campaign={formik.values.id} />

                        <UsersTableWizard formik={formik} reloadCampaign={reloadCampaign} />

                    </div>
                    
                    <div className='col-md-6'>
                        <ProductsTableWizard formik={formik} reloadCampaign={reloadCampaign} />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <OfficesTableWizard formik={formik} reloadCampaign={reloadCampaign} />
                        </div>
                        <div className="col-6">
                            <PostalCodeRestrictionsTableWizard formik={formik} reloadCampaign={reloadCampaign} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <Button type="submit" size='lg' color='primary'>
                            {isLoading ? <Spinner isSmall /> : `Editar Departamento`}
                        </Button>
                    </div>
                </div>
            </form >
        </Fragment >
    )
}

export default CampaignEditForm;