export const getAge = (date?: string): any => {
    if (!date) return null;
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    const day = today.getDate() - birthDate.getDate();
    
    if (month < 0 || (month === 0 && day < 0)) age--;
    return age;
};


export const formatDateFromSeconds = (seconds: number): string => {
    const segundos = (Math.round(seconds % 0x3C)).toString();
    const horas    = (Math.floor(seconds / 0xE10)).toString();
    const minutos  = (Math.floor(seconds / 0x3C ) % 0x3C).toString();

    let formatedTime = '';

    if (horas !== '0') {
        formatedTime += horas + ' H';

        if (minutos !== '0') {
            formatedTime += ' ' + minutos + ' min';

            if (segundos !== '0') {
                formatedTime += ' ' + segundos + ' seg';
            }
        } else if (segundos !== '0') {
            formatedTime += ' ' + segundos + ' seg';
        }
    } else if (minutos !== '0') {
        formatedTime += minutos + ' min';

        if (segundos !== '0') {
            formatedTime += ' ' + segundos + ' seg';
        }
    } else {
        formatedTime += segundos + ' seg';
    }

    return formatedTime;
}