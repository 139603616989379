import React, { ChangeEvent, useState } from "react";

type RadioButtonProps = {
  onOptionChange: (option: string) => void;
}

function RadioButton({ onOptionChange }: RadioButtonProps ) {
  const [selectedOption, setSelectedOption] = useState<string|null>(null);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onOptionChange(value); // Llamamos a la función prop pasando el valor seleccionado
  };

  return (
    <div className="btn-group" role="group">
      <input
        type="radio"
        name="targetProduct"
        id="allOption"
        value=""
        checked={selectedOption === "" || selectedOption === null}
        onChange={handleOptionChange}
        className="btn-check"
      />
      <label className="btn btn-outline-info" htmlFor="allOption">
        Todos
      </label>
      <input
        type="radio"
        name="targetProduct"
        id="businessOption"
        value="business"
        checked={selectedOption === "business"}
        onChange={handleOptionChange}
        className="btn-check"
      />
      <label className="btn btn-outline-info" htmlFor="businessOption">
        Empresa
      </label>
      <input
        type="radio"
        name="targetProduct"
        id="individualOption"
        value="individual"
        checked={selectedOption === "individual"}
        onChange={handleOptionChange}
        className="btn-check"
      />
      <label className="btn btn-outline-info" htmlFor="individualOption">
        Individual
      </label>
    </div>
  );
}

export default RadioButton;