import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
	CardTitle,
} from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import { BusinessService } from "../../../services/business/businessService";
import StatusDropdown from "../../../components/StatusDropdown";
import { BusinessesApiResponse } from "../../../type/business-type";
import AsyncImg from "../../../components/AsyncImg";
import BusinessFilters from "./business-options/BusinessFilters";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import moment from "moment";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";

const BusinessList = () => {
	const {userCan} = useContext(PrivilegeContext)
	const navigate = useNavigate();

	const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

	useEffect(() => {
		checkIfUrlHasFilters();
	},[])

	const fetchBusinesses = useCallback(async () => {
		const businessService = new BusinessService();
		const response = await businessService.getBusinesses(filters);
		return response.getResponseData() as BusinessesApiResponse;
	}, [filters]);

	const [data, loading, error, refetch] = useFetch(fetchBusinesses);
	const [changingStatus, setChangingStatus] = useState<string[]>([]);

	const deleteBusiness = async (id: string) => {
		let response = (await new BusinessService().deleteBusiness(id)).getResponseData();
		if (response.success) {
			toast.success('Comercializadora eliminada correctamente');
			updateFilters({ ...filters });
		}
	}

	const toggleBusinessStatus = async (
		id: string,
		status: boolean,
		toggleStatus: Function
	) => {
		try {
			setChangingStatus([...changingStatus, id]);
			let response = await (
				await new BusinessService().toggleBusinessStatus(id, status)
			).getResponseData();
			if (response.success) {
				setChangingStatus(changingStatus.filter((item) => item !== id));
				toggleStatus(status);
			} else {
				setChangingStatus(changingStatus.filter((item) => item !== id));
			}
		} catch (error: any) {
			setChangingStatus(changingStatus.filter((item) => item !== id));
		}
		refetch()
	};

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Fragment>
						<CardTitle>Listado de Comercializadoras</CardTitle>
						<SubheaderSeparator />
						<Button
							color="light"
							icon="Add"
							className={`${userCan('delete','businesses')?'':'d-none'}`}
							isLight
							onClick={() => {
								navigate("create");
							}}
						>

							Añadir Comercializadora
						</Button>
					</Fragment>
				</SubHeaderLeft>
				<SubHeaderRight>
					<BusinessFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={false}>
					{
						data ? (
							<CustomTable
								data={data?.businesses ? data.businesses : null}
								pagination={true}
								defaultLimit={filters.limit || 50}
                				defaultOrder={filters.filter_order || undefined}
								paginationData={{
									pageSize: filters.limit,
									currentPage: filters.page,
									pageCount: data as BusinessesApiResponse ? data.lastPage : 1,
									totalCount: data.totalRegisters,
									handlePagination: updatePage,
									handlePerPage: updatePageSize,
								}}
								className={"table table-hover"}
								columns={
									[
										{
											name: "",
											keyValue: "logo",
											render: (element: any) => {
												return (
													<div className="d-flex justify-content-center">
														<div className='user-avatar'>
															<AsyncImg id={element.logo ? element.logo.id : null} />
														</div>
													</div>
												);
											},
										},
										{
											name: "Nombre",
											keyValue: "name",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											render: (element: any) => {
												return (
													<div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => { navigate(`${element.id}/profile`) }}>
														{element.name ? element.name : '' }
													</div>
												)
											},
										},
										{
											name: "CIF",
											keyValue: "cif",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											cellClassName: "text-center",
										},
										{
											name: "Dirección",
											keyValue: "address",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											cellClassName: "text-center",
										},
										{
											name: "Dias de Reclamacón",
											keyValue: "clawbackDays",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											cellClassName: "text-center",
										},
										{
											name: "Dias de Renovación",
											keyValue: "renewalDays",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											cellClassName: "text-center",
											render: (element: any) => {
												return (
													<div className={'text-center'}>
														{element.renewalDays || '0'}
													</div>
												);
											}
										},
										{
											name: "Organización",
											keyValue: "company",
											className: userCan('list','businesses',true) ? "text-center" : "d-none",
											isVisible: userCan('list','businesses',true),
											render: (element: any) => {
												if (element.company) {										
													return (
														<div key={element.company.id} className="d-flex justify-content-center">
														{element.company.name ?? ''}
													</div>
												)
												}else return (<></>);
											}
										},
										{
											name: "Nº Productos",
											keyValue: "products",
											className: "text-center",
											render: (element: any) => {
												return (
													<div className={'text-center'}>
														{element.products ? element.products.length : '0'}
													</div>
												);
											},
										},
										{
											name: "Nº Tarifas",
											keyValue: "fees",
											className: "text-center",
											render: (element: any) => {
												return (
													<div className={'text-center'}>
														{element.fees ? element.fees.length : '0'}
													</div>
												);
											},
										},
										{
											name: "Nº Servicios",
											keyValue: "services",
											className: "text-center",
											render: (element: any) => {
												return (
													<div className={'text-center'}>
														{element.services ? element.services.length : '0'}
													</div>
												);
											},
										},
										{
											name: "Estado",
											keyValue: "active",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											render: (element: any) => {
												return (
													<div className={'text-center'}>
														<StatusDropdown
															disabled={changingStatus.includes(element.id)}
															itemId={element.id}
															key={element.id}
															status={element.active}
															change={toggleBusinessStatus}
														/>
													</div>
												);
											},
										},
										{
											name: "Fecha de creación",
											keyValue: "createdAt",
											sortable: true,
											sortColumn: updateFilterOrder,
											className: "text-center",
											render: (element: any) => {

												return (
													<div className={'text-center'}>
														<span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
													</div>
												)
											},
										},

										{ name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
									]
								}
								actions={
									[
										{
											title: "Ver",
											buttonType: 'icon',
											iconColor: 'text-primary',
											iconPath: '/media/icons/duotune/general/gen021.svg',
											additionalClasses: `text-primary ${userCan('show','businesses')?'':'d-none'}`,
											description: "Ver perfil de la Comercializadora",
											callback: (item: any) => {
												navigate(`${item.id}/profile`)
											},
										},
										{
											title: "Editar",
											buttonType: 'icon',
											iconColor: 'text-info',
											iconPath: '/media/icons/duotune/general/gen055.svg',
											additionalClasses: `text-primary ${userCan('edit','businesses')?'':'d-none'}`,
											description: "Editar perfil de la Comercializadora",
											callback: (item: any) => {
												navigate(`${item.id}/edit`);
											},
										},

										{
											title: "Eliminar",
											buttonType: 'icon',
											iconColor: 'text-danger',
											iconPath: '/media/icons/duotune/general/gen027.svg',
											additionalClasses: `text-danger ${userCan('delete','businesses')?'':'d-none'}`,
											description: "Eliminar Comercializadora",
											callback: (item: any) => {
												handleConfirmationAlert({
													title: "Eliminar Comercializadora",
													text: "¿Está seguro que desea eliminar la Comercializadora?",
													icon: "warning",
													onConfirm: () => {
														deleteBusiness(item.id);
													}
												})
											},
										},
									]
								}
						/>
						) : (
							<div className="text-center d-flex justify-content-center"><Spinner/></div>
						)
					}
				</Card>
			</Page>
		</Fragment>
	)
}

export default BusinessList;
