export const clientsMenu = {
	clients: {
		id: 'clients',
		text: 'Clientes',
		path: '/clients',
		icon: 'Contacts',
		subMenu: {
			clientList: {
				id: 'clients-list',
				text: 'Lista',
				path: '/clients',
				icon: 'List'
			},
			clientsCreate: {
				id: 'clients-create',
				text: 'Crear',
				path: '/clients/create',
				icon: 'Add',
			}
		}
	}
};

export const walletMenu = {
	wallet: {
		id: 'wallet',
		text: 'Cartera',
		path: '/wallet',
		icon: 'AccountBalanceWallet',
		permissions_required: {
			entity: 'wallet',
			actions : ['list_all']
		},
	},
	wallet_not_all_permission: {
		id: 'wallet-not-all-permission',
		text: 'Panel de trabajo',
		path: '/wallet',
		icon: 'AccountBalanceWallet',
		permissions_required: {
			entity: 'wallet',
			actions : ['list']
		},
	}
};

export const callMenu = {
	calls: {
		id: 'calls',
		text: 'Llamadas',
		path: '/calls',
		icon: 'Phone',
		permissions_required: {
			entity: 'calls',
			actions: ['show_all' , 'show_own']
		},
	}
};

export const rankingMenu = {
	ranking: {
		id: 'ranking',
		text: 'Ranking',
		path: '/ranking',
		icon: 'TrendingUp',
		permissions_required: {
			entity: 'ranking',
			action: 'admin_ranking'
		},
		subMenu: {
			rankingList: {
				id: 'ranking-list',
				text: 'Lista',
				path: '/ranking',
				icon: 'List',
				permissions_required: {
					entity: 'ranking',
					action: 'list'
				},
			},
		}

	}
}

export const operationsMenu = {

	operations: {
		id: 'operations',
		text: 'Contratos',
		path: 'operations',
		icon: 'Assignment',
		subMenu: {
			operationList: {
				id: 'operations',
				text: 'Lista',
				path: 'operations',
				icon: 'List'
			}
		}
	}
};


export const pendingTasksMenu = {
	pendingTasks: {
		id: 'pending-tasks',
		text: 'Tareas Pendientes',
		class: 'text-primary',
		path: '/pending-tasks',
		icon: 'PendingActions',
		subMenu: {
			pendingTasksList: {
				id: 'pending-tasks-list',
				text: 'Lista',
				path: '/pending-tasks',
				icon: 'List',
			},
		},
	}
};

export const workMenu = {
	work: {
		id: 'work',
		text: 'Trabajo',
		class: 'text-primary',
		path: '/work',
		icon: 'Receipt Long',
		subMenu: {
			newWork: {
				id: 'new-work',
				text: 'Nuevo trabajo',
				path: 'new-work',
				absolutePath: '/work/new-work',
				icon: 'Add',
			},
		},
	}
};

export const productMenu = {
	products: {
		id: 'products',
		text: 'Productos',
		path: 'product',
		icon: 'Inventory2',
		permissions_required: {
			entity: 'products',
			action: 'admin_products'
		},
		subMenu: {
			productList: {
				id: 'product-list',
				text: 'Lista',
				path: 'products',
				icon: 'List',
			},
			productCreate: {
				id: 'product-create',
				text: 'Crear',
				path: 'product-create',
				icon: 'Add',
			},
			productTypes: {
				id: 'product-types',
				text: 'Tipos',
				path: 'products-types',
				icon: 'Widgets',
			},
		},
	},

};

export const usersMenu = {
	users: {
		id: 'users',
		text: 'Usuarios',
		path: '/users',
		icon: 'Person',
		permissions_required: {
			entity: 'users',
			action: 'list'
		},
		subMenu: {
			userList: {
				id: 'user-list',
				text: 'Lista',
				path: '/users',
				icon: 'List',
			},
			userCreate: {
				id: 'user-create',
				text: 'Crear',
				path: '/users/create',
				icon: 'Add',
			},
			rolesList: {
				id: 'roles-list',
				text: 'Roles',
				path: '/roles',
				icon: 'AdminPanelSettings',
				permissions_required: {
					entity: 'roles',
					action: 'admin'
				},
			}
		},
	},
}



export const campaignsMenu = {
	campaigns: {
		id: 'campaigns',
		text: 'Departamentos',
		path: '/campaigns',
		icon: 'Campaign',
		permissions_required: {
			entity: 'campaigns',
			action: 'admin_campaigns'
		},
		subMenu: {
			campaignList: {
				id: 'campaign-list',
				text: 'Lista',
				path: '/campaigns',
				icon: 'List',
			},
		}
	}
}

export const expensesMenu = {
	expenses: {
		id: 'expenses',
		text: 'Gastos',
		path: 'expenses',
		icon: 'Receipt',
		permissions_required: {
			entity: 'expenses',
			action: 'admin_expenses'
		},
		subMenu: {
			expenseList: {
				id: 'expenses-list',
				text: 'Lista',
				path: 'expenses',
				icon: 'List',
			},
			expenseTypes: {
				id: 'expenses-types',
				text: 'Tipos de Gastos',
				path: 'expenses-types',
				icon: 'Widgets',
			},
		},
	}
};

export const companiesMenu = {
	companies: {
		id: 'companies',
		text: 'Comercializadora',
		path: '/companies',
		icon: 'Store',
		permissions_required: {
			entity: 'businesses',
			action: 'admin_businesses'
		},
		subMenu: {
			companyList: {
				id: 'company-list',
				text: 'Lista',
				path: '/companies',
				icon: 'List',
			},
			companyCreate: {
				id: 'company-create',
				text: 'Crear',
				path: '/companies/create',
				icon: 'Add',
			}
		},
	},

};

export const officesMenu = {
	offices : {
		id: 'offices',
		text: 'Oficinas',
		path: '/offices',
		icon: 'Business',
		permissions_required: {
			entity: 'offices',
			action: 'admin_offices'
		},
		subMenu: {
			officeList: {
				id: 'office-list',
				text: 'Lista',
				path: '/offices',
				icon: 'List'
			}
		}
	}
};

export const documentsMenu = {
	documents: {
		id: 'documents',
		text: 'Documentos',
		path: '/documents',
		icon: 'Description',
		permissions_required: {
			entity: 'documents',
			action: 'manage_type'
		},
		subMenu: {
			documentTypes: {
				id: 'document-types',
				text: 'Tipos',
				path: '/document-types',
				icon: 'Widgets',
			}
		}
	}
};

export const operationAdminMenu = {
	operations: {
		id: 'admin-operations',
		text: 'Contratos',
		path: 'admin-operations',
		icon: 'Assignment',
		permissions_required: {
			entity: 'operations',
			action: 'admin_operations'
		},
		subMenu: {
			operationTypes: {
				id: 'admin-operation-types',
				text: 'Tipos',
				path: 'admin-operation-types',
				icon: 'Widgets',
			},
			operationsStatus: {
				id: 'admin-operation-action-status',
				text: 'Estados',
				path: 'admin-operation-action-status',
				icon: 'BubbleChart',
			},
			leads: {
				id: 'admin-lead',
				text: 'Tipos de Trámites',
				path: 'admin-lead',
				icon: 'HistoryEdu',
				permissions_required: {
					entity: 'leads',
					action: 'admin_leads'
				},
			},
			originLead: {
				id: 'admin-origin-lead',
				text: 'Origenes Leads',
				path: 'admin-origin-lead',
				icon: 'PersonSearch',
				permissions_required: {
					entity: 'origins',
					action: 'admin_origin_lead'
				},
			},
			configWallet: {
				id: 'config-wallet',
				text: 'Cartera',
				path: '/config-wallet/status',
				pathOrigin : '/config-wallet',
				icon: 'Settings',
				permissions_required: {
					entity: 'config',
					action: 'config_wallet'
				},
				subMenu: null
			}
		}
	}
}

export const adminMenu = {
	title: {
		id: 'title-admin',
		text: 'Administración',
		icon: 'Extension',
	},
	...operationAdminMenu,
	...usersMenu,
	...rankingMenu,
	...callMenu,
	...documentsMenu,
	...companiesMenu,
	...campaignsMenu,
	...officesMenu,
	...productMenu,
	...expensesMenu,
}

export const superAdminMenu = {
	title: {
		id: 'title-super-admin',
		text: 'SuperAdmin',
		icon: 'Extension',
	},
	organizations: {
		id: 'organizations',
		text: 'Organizaciones',
		path: '/organizations',
		icon: 'CorporateFare',
		subMenu: null,
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/roles',
		icon: 'AdminPanelSettings',
		subMenu: null,
	}
};

export const dashboardPagesMenu = {
		dashboard: {
			id: 'dashboard',
			text: 'Dashboard',
			path: '/dashboard',
			icon: 'Dashboard',
			permissions_required: {
				entity: 'dashboard',
				action: 'show_dashboard'
			},
			subMenu: null,
			// TODO: Change permissions for dashboard permissions
		},
		operationStats: {
			id: 'operation-statistics',
			text: 'Estadísticas',
			path: '/agent-statistics',
			icon: 'BarChart',
			permissions_required: {
				entity: 'dashboard',
				action: 'show_agent_dashboard'
			},
		},
};

export const permissionsPage = {
	permissions: {
		id: 'permissions',
		text: 'Permissions',
		path: '/permissions',
		subMenu: null,
	},
};

export const modulesMenu = {
	title: {
		id: 'title-modules-menu',
		text: 'Módulos',
		icon: 'Extension',
	},
	...operationsMenu,
	...pendingTasksMenu,
	...clientsMenu,
	...walletMenu
}
