import { FiltersProvider } from "../../components/providers/FiltersProvider";
import ExpenseTypeList from "./expense-list/ExpenseTypeList";

const ExpenseTypeListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <ExpenseTypeList />
        </FiltersProvider>
      </>
    );
  };

export default ExpenseTypeListWrapper ;