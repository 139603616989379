import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { log } from 'console';
import { EditUserFieldsModel } from '../../type/user-type';

const USER_ENDPOINT = '/users';
export class UserService extends RestServiceConnection {

    /**
     * Posting to API Form Data type in order to send the user photo
     */
    createUser = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/create',
            data: values,
        }, true);
        return this;
    }

    editUser = async (values: EditUserFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getUsers = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getUserById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get',
            data: {
                user: id
            }
        }, true);
        return this;
    }

    getDocuments = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get-documents',
            data: {
                user: id
            }
        }, true);
        return this;
    }

    getDocumentsByDocumentType = async (id: string, documentType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get-documents-by-documentType',
            data: {
                user: id,
                documentType: documentType
            }
        }, true);
        return this;
    }

    toggleUserStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/toggle',
            data: {
                user: id,
                active: status
            }
        }, true);
        return this;
    }

    loginLikeUser = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/login-like-user',
            data: {
                user: userId,
            }
        }, true);
        return this;
    }

    addUserDocument = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-document',
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    addUserDocuments = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-documents',
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editUserPermissions = async (user: string, company: string | null, permissions: number[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit-permissions',
            data: {
                user: user,
                company: company,
                permissions: permissions
            }
        }, true);
        return this;
    }

    restoreUserPermissions = async (user: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/restore-permissions',
            data: {
                user: user,
            }
        }, true);
        return this;
    }

    updateImage = async (file: File, userId: string) => {
        let formData = new FormData();
        formData.append('image', file);
        formData.append('user', userId);

        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/update-image',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData
        }, true);

        return this
    }

    deleteUser = async (userId: string) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete',
            data: {
                user: userId
            }
        }, true);

        return this
    }

    me = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: USER_ENDPOINT + '/me',
        }, true);

        return this
    }

    getNumberOfContracts = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get-number-of-contracts',
            data: {
                user: userId
            }
        }, true);
        return this;
    }
}