import { FC, useEffect, useState } from "react";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { DashboardService } from "../../../../services/dashboard/DashboardService";
import AsyncImg from "../../../../components/AsyncImg";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, ...registerables);

interface IDashboardDataByCompany {
  date: any;
  agent?: string;
  office?: string;
  business?: string;
}

const DashboardDataByCompany: FC<IDashboardDataByCompany> = ({
  date,
  agent,
  office,
  business
}) => {
  const [data, setData] = useState<any>(null);

  const [companySelected , setCompanySelected] = useState<any>(null);

  const [doughnutData, setDoughnutData] = useState<any>({
    labels: ["No hay datos"],
    datasets: [
      {
        label: "Numero de operaciones",
        data: [0],
        backgroundColor: ["#B2BABB"],
        borderColor: ["#B2BABB"],
        borderWidth: 1,
      },
    ],
  });

  const fetchCompaniesOperationData = async () => {
    const dashboardService = new DashboardService();
    if (date.start === "" || date.end === "") return;
    const response = await (
      await dashboardService.getCompanyOperationsData({
        filter_filters: {
          dateStart: date.start,
          dateEnd: date.end,
          ...(agent ? { agent: agent } : {}),
          ...(office ? { office: office } : {}),
          ...(business ? { business: business } : {}),
        },
      })
    ).getResponseData();;
    if (response.success) {
      setData(
        response.data.map((operationData: any) => ({
          businessName:
            operationData.operationData.products[0].product.business.name,
          businessLogo:
            operationData.operationData.products[0].product.business.logo?.id,
          totalOperation: operationData.totalOperaciones,
          totalCommission: operationData.totalOperacionesComisionadas,
          totalPoints: operationData.totalOperacionesPuntuadas,
        }))
      );
    } else {
      setData(null);
    }
  };

  useEffect(() => {
    if (date.start !== "" && date.end !== "") {
      fetchCompaniesOperationData();
    }
  }, [date, agent, office, business]);

  useEffect(() => {
    if (data?.length > 0) {
      setDoughnutData({
        labels: data.map((company: any) => company.businessName),
        datasets: [
          {
            label: "Total de Operaciones Creadas",
            data: data.map((company: any) => company.totalOperation),
            backgroundColor: data.map((company: any) => "#C8E3F9"),
            borderColor: data.map((company: any) => "#C8E3F9"),
            borderWidth: 1,
          },
          {
            label: "Total de Operaciones Comisionadas",
            data: data.map((company: any) => company.totalCommission),
            backgroundColor: data.map((company: any) => company.totalCommission > 0 ? "#FFBAD0" : "#B2BABB"),
            borderColor: data.map((company: any) => company.totalCommission > 0 ? "#FFBAD0" : "#B2BABB"),
            borderWidth: 1,
          },
          {
            label: "Total de Operaciones Puntuadas",
            data: data.map((company: any) => company.totalPoints),
            backgroundColor: data.map((company: any) => company.totalPoints > 0 ? "#D7E1CE" : "#B2BABB"),
            borderColor: data.map((company: any) => company.totalPoints > 0 ? "#D7E1CE" : "#B2BABB"),
            borderWidth: 1,
          },
          ],
      });
    } else {
	  setDoughnutData({
		labels: ["No hay datos"],
		datasets: [
		  {
			label: "Numero de operaciones",
			data: [0],
			backgroundColor: ["#B2BABB"],
			borderColor: ["#B2BABB"],
			borderWidth: 1,
		  },
		],
	  });
	}
  }, [data]);

  const changeData = (company : any) => {
	setDoughnutData({
	  labels: [
		"Total de Operaciones Creadas",
		"Total de Operaciones Comisionadas",
		"Total de Operaciones Puntuadas",
	  ],
	  datasets: [
		{
		  label: "Total",
		  data: [
			company?.totalOperation,
			company?.totalCommission,
			company?.totalPoints,
		  ],
		  backgroundColor: ["#C8E3F9", "#FFBAD0", "#D7E1CE"],
		  borderColor: ["#C8E3F9", "#FFBAD0", "#D7E1CE"],
		  borderWidth: 1,
		},
	  ],
	});
  }

  const resetData = () => {
    if (data?.length > 0) {
      setDoughnutData({
        labels: data.map((company: any) => company.businessName),
        datasets: [
          {
            label: "Total de Operaciones Creadas",
            data: data.map((company: any) => company.totalOperation),
            backgroundColor: data.map((company: any) => "#C8E3F9"),
            borderColor: data.map((company: any) => "#C8E3F9"),
            borderWidth: 1,
          },
          {
            label: "Total de Operaciones Comisionadas",
            data: data.map((company: any) => company.totalCommission),
            backgroundColor: data.map((company: any) => company.totalCommission > 0 ? "#FFBAD0" : "#B2BABB"),
            borderColor: data.map((company: any) => company.totalCommission > 0 ? "#FFBAD0" : "#B2BABB"),
            borderWidth: 1,
          },
          {
            label: "Total de Operaciones Puntuadas",
            data: data.map((company: any) => company.totalPoints),
            backgroundColor: data.map((company: any) => company.totalPoints > 0 ? "#D7E1CE" : "#B2BABB"),
            borderColor: data.map((company: any) => company.totalPoints > 0 ? "#D7E1CE" : "#B2BABB"),
            borderWidth: 1,
          },
          ],
      });
    }
  }

  return (
    <Card stretch>
      <CardHeader className="pb-0">
        <CardLabel icon="ReceiptLong">
          <CardTitle tag="h4" className="h5">
            Historial de contratos
          </CardTitle>
        </CardLabel>
        <div className="float-end">
          <Button
            icon="Refresh"
            className="btn-outline-danger"            
            onClick={resetData}
          >
            Ver Todas las Empresas
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <div className="row">
          <div className={data?.length > 0 ? "col-xl-12 col-xxl-12 d-flex justify-content-center" : "col-12 d-flex justify-content-center align-items-center"}>
            <Bar
              data={doughnutData}
              options={{
              }}
            />
          </div>
          <div className="col-xl-12 col-xxl-12">
          <div className="row g-3">
              {data
                ? data.length == 1 ? null :
                data.map((company: any) => (
                    <div
                      key={company.businessName + companySelected}
                      className="col-3"
                    >
                      <Button
                        className={`w-100 py-4 ${companySelected == company.businessName ? "btn-outline-primary" : ""}`}
                        shadow="sm"
                        onClick={() => {
                          changeData(company)
                          setCompanySelected(company?.businessName)
                        }}
                      >
                        <div className="d-flex flex-row justify-content-around align-items-center">
                          <div className="user-avatar">
                            <AsyncImg id={company.businessLogo} />
                          </div>
                          <span className="fs-6">{company.businessName}</span>
                        </div>
                      </Button>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardDataByCompany;
