import { FC, Fragment } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import { User } from "../../../type/user-type";

type UserObjectivesProps = {
  user: User;
  points?: number;
};

const UserObjectives: FC<UserObjectivesProps> = ({ user, points }) => {

  const percentage = points && user.monthlyObjective ? ((points / user.monthlyObjective) * 100) > 100 ? 100 : ((points / user.monthlyObjective) * 100) : 0;

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardLabel>
            <CardTitle>{user.monthlyObjective ? user.monthlyObjective : "0"} puntos de Objetivo</CardTitle>
          </CardLabel>
          <CardLabel>
            <CardTitle className={'text-primary'}>{points ? points : 0} puntos conseguidos</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <div className='d-flex justify-content-between'>
            <p>Objetivo completado este mes</p>
            <p className='fw-bold'>{percentage.toFixed(2)}%</p>
          </div>
          <Progress value={percentage} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserObjectives;