import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { ExpenseService } from "../../../services/expenses/expenseService";
import { ExpenseApiResponse, ExpensesApiResponse } from "../../../type/expense-type";
import ExpenseFilters from "./expenses-options/ExpensesFilters";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import moment from "moment";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import ExpenseModal from "../expenses-create/ExpenseModal";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";


const ExpenseList = () => {
    const {userCan} = useContext(PrivilegeContext)

    const [expense, setExpense] = useState(null);
    const [modal, setModal] = useState(false);
    const [readMode, setReadMode] = useState(false);

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

    useEffect(() => {
        checkIfUrlHasFilters();
    },[]);

    const fetchExpenses = useCallback(async () => {
        const response = await new ExpenseService().getExpenses(filters);
        return response.getResponseData() as ExpensesApiResponse;
    }, [filters, modal]);

    const [data, loading, error, refetch] = useFetch(fetchExpenses);

    const deleteExpense = async (id: string) => {
        let response = (await new ExpenseService().deleteExpense(id)).getResponseData();
        if (response.success) {
            toast.success('Gasto eliminado correctamente');
            updateFilters({ ...filters });
        }else toast.error('Error al eliminar gasto')
        updateFilters({...filters})
    }

    const openModal = (item: any, readBool: boolean) => {
        setReadMode(readBool);
        setExpense(item);
        setModal(true);
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                <Fragment>
                        <CardTitle>Listado de Gastos</CardTitle>
                        <SubheaderSeparator />
                        <Button
                            color="light"
                            icon="Add"
                            className={`${userCan('create','expenses')?'':'d-none'}`}
                            isLight
                            onClick={() => {
                                openModal(null, false);
                            }}
                        >

                            Añadir Gasto
                        </Button>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ExpenseFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
                </SubHeaderRight>
            </SubHeader>
            <Card>
                <CardBody>
                <Fragment>
                {
                    data ? (
                        <CustomTable
                            data={data?.expenses ? data.expenses : null}
                            pagination={true}
                            defaultLimit={filters.limit || 50}
                            defaultOrder={filters.filter_order || undefined}
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: data as ExpenseApiResponse ? data.lastPage : 1,
                                totalCount: data.totalRegisters,
                                handlePagination: updatePage,
                                handlePerPage: updatePageSize,
                            }}
                            className={"table table-hover"}
                            columns={
                                [
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => {
                                                    openModal(element, true);
                                                }}>
                                                    {element.name}
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        name: "Usuario",
                                        keyValue: "user",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div key={element.user?.id} className="d-flex justify-content-center">
                                                    {element.user?.name}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: userCan('list','expenses',true) ? "text-center" : "d-none",
                                        isVisible: userCan('list','expenses',true),
                                        render: (element: any) => {
                                            return (
                                                <div key={element.company?.id} className="d-flex justify-content-center">
                                                    {element.company?.name}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        name: "Tipo de Gasto",
                                        keyValue: "expenseType",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div key={element.expenseType?.expenseName} className="d-flex justify-content-center">
                                                    {element.expenseType?.expenseName}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        name: "Importe",
                                        keyValue: "amount",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Fecha",
                                        keyValue: "date",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {                                    
                                            return (
                                                <div className={'text-center'}>
                                                    <span className={'text-muted'}>{moment(element.date.date).format('DD-MM-YYYY HH:mm:ss')}</span>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "createdAt",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className={'text-center'}>
                                                    <span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        name: "Última modificación",
                                        keyValue: "updatedAt",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (item: any) => {
                                            return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
                                          }
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                                ]}
                            actions={
                                [
                                    {
                                        title: "Ver",
                                        buttonType: 'icon',
                                        iconColor: 'text-primary',
                                        iconPath: '/media/icons/duotune/general/gen021.svg',
                                        additionalClasses: `text-primary ${userCan('show','expenses')?'':'d-none'}`,
                                        description: "Ver gasto",
                                        callback: (item: any) => {
                                            openModal(item, true);
                                        },
                                    },
                                    {
                                        title: "Editar",
                                        buttonType: 'icon',
                                        iconColor: 'text-info',
                                        iconPath: '/media/icons/duotune/general/gen055.svg',
                                        additionalClasses: `text-primary ${userCan('edit','expenses')?'':'d-none'}`,
                                        description: "Editar gasto",
                                        callback: (item: any) => {
                                            openModal(item, false);
                                        },
                                    },
        
                                    {
                                        title: "Eliminar",
                                        buttonType: 'icon',
                                        iconColor: 'text-danger',
                                        iconPath: '/media/icons/duotune/general/gen027.svg',
                                        additionalClasses: `text-danger ${userCan('delete','expenses')?'':'d-none'}`,
                                        description: "Eliminar gasto",
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar gasto",
                                                text: "¿Está seguro que desea eliminar el gasto?",
                                                icon: "warning",
                                                onConfirm: () => {
                                                    deleteExpense(item.id);
                                                }
                                            })
                                        },
                                    },
                                ]
                            }
                        />
                    ) : (
                        <div className="text-center d-flex justify-content-center"><Spinner /></div>
                    )
                }
                </Fragment>
                </CardBody>
            </Card>
            {modal && (<ExpenseModal isOpen={modal} setIsOpen={setModal} expense={expense} readMode={readMode} />)}
        </Fragment >
    );
}

    export default ExpenseList;
