import React, { ChangeEvent, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { CustomTable } from '../../../../components/table/CustomTable';
import { CheckCircleOutline, HighlightOff } from '../../../../components/icon/material-icons';
import AddWizardProduct from './AddWizardProducts';
import { toast } from 'react-toastify';
import { CampaignService } from '../../../../services/campaigns/campaignService';
import { Product } from '../../../../type/product-type';
import { useLoader } from '../../../../components/loader/LoaderProvider';

interface ProductsTableWizardtProps {
    formik: any; // Añade el tipo correcto para formik
    reloadCampaign: Function;
}

const ProductsTableWizard: React.FC<ProductsTableWizardtProps> = ({ formik, reloadCampaign }) => {

    const [modalPr, setModalPr] = useState(false);
    const [selectedRowsPrd, setSelectedRowsPrd] = useState<any[]>([]);
    const [selectAllPrd, setSelectAllPrd] = useState(false);

    const {showLoading, hideLoading} = useLoader();

    const handleActionSelected = async (array: any, selectArr: any[], setSelectArr: any) => {
        try {
            for (const rowId of selectArr) {
                const rowToAction = array.find((row: any) => row.id === rowId.id);
                if (rowToAction) {
                    const response = (await new CampaignService().removeProductFromCampaign(formik.values.id, rowToAction.product.id)).getResponseData();
                }
            }
            toast.success('Producto borrado con éxito');
            reloadCampaign();
            setSelectArr([]);
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleCheckboxChangePrd = (
        id: string,
        event: ChangeEvent<HTMLInputElement>,
        element: any,
    ) => {
        const { checked } = event.target;
        let selectedItemsCopy = [...selectedRowsPrd];
        if (checked) {
            selectedItemsCopy.push(element);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item.id === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedRowsPrd(selectedItemsCopy);
    };

    const handleSelectAllPrd = (checked: boolean) => {
        setSelectAllPrd(!selectAllPrd);
        if (!selectAllPrd) {
            setSelectedRowsPrd(formik.values.products ? formik.values.products : []);
        } else {
            setSelectedRowsPrd([]);
        }
    };

    const _oncloseModalPr = async (message: string, products: Product[]) => {
        try {
            showLoading("Añadiendo productos a el departamento", (<>Por favor, espere</>));
            for (let product of products) {
                const response = (await new CampaignService().addProductToCampaign(formik.values.id, product.id)).getResponseData()
            }
            toast.success(message);
            reloadCampaign();
        } catch (error: any) {
            console.log(error);
            toast.error(message);
        } finally {
            hideLoading();
        }
    }

    return (
        <>
            <Card stretch>
                <CardHeader className='rounded-1 mb-0' borderSize={1}>
                    <CardLabel icon='Inventory2' iconColor='info'>
                        <CardTitle>Productos</CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button color="primary" icon="Add" onClick={() => setModalPr(true)}>Añadir Productos</Button>
                        <Button color="danger" icon="DeleteForever" onClick={() => handleActionSelected(formik.values.products, selectedRowsPrd, setSelectedRowsPrd)}>Borrar seleccionados</Button>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <CustomTable
                        data={formik.values.products || null}
                        pagination={true}
                        className={"table table-hover fs-6"}
                        selectableItems={true}
                        onSelectAllItems={handleSelectAllPrd}
                        onChangeCheckedItems={handleCheckboxChangePrd}
                        columns={[
                            {
                                name: "Nombre",
                                keyValue: "name",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.product.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Tarifa",
                                keyValue: "fee",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center fw-semibold">
                                            {element.product.fee.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "C. Base",
                                keyValue: "baseCommission",
                                className: "text-center",
                                cellClassName: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {element.product.base_commission ?? 0} €
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Puntos",
                                keyValue: "points",
                                className: "text-center",
                                cellClassName: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {element.product.points ?? 0}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Negocio",
                                keyValue: "businessEnabled",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="fs-7 text-center">
                                            {element.product.businessEnabled ? (
                                                <CheckCircleOutline className="text-success" width="30px" height="30px" />
                                                ) : (
                                                    <HighlightOff className="text-danger" width="30px" height="30px" />
                                                    )}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Individual",
                                keyValue: "individualEnabled",
                                className: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="fs-7 text-center">
                                            {element.product.individualEnabled ? (
                                                <CheckCircleOutline className="text-success" width="30px" height="30px" />
                                                ) : (
                                                    <HighlightOff className="text-danger" width="30px" height="30px" />
                                                    )}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Descripción",
                                keyValue: "description",
                                className: "text-center",
                                cellClassName: "text-center",
                                render: (element) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {element.product.description ?? 'No hay descripción'}
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            {modalPr && <AddWizardProduct isOpen={modalPr} setIsOpen={setModalPr} onClose={_oncloseModalPr} mode={"create"} />}
        </>
    );
};

export default ProductsTableWizard;