import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import moment from "moment/moment";

interface IProductFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any,
}

const CallssFilter: FC<IProductFiltersProps> = ({updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false);
    
    const handleTextChange = (search: string) => {
        updateFilters({ search_text: search });
    };

    const handleDateRangeChange = (field: 'from' | 'to', value: string|null) => {
        const updatedCallDate = {
            ...filters.filter_filters.call_date,
            [field]: value,
        };
        updateFilters({
            call_date: updatedCallDate,
        });
    };

    const handleDurationRangeChange = (field: 'from' | 'to', value: string) => {
        const updatedCallDuration = {
            ...filters.filter_filters.call_duration,
            [field]: value,
        };
        updateFilters({
            call_duration: updatedCallDuration,
        });
    };
    const handleCreatedAtRangeChange = (field: 'from' | 'to', value: string|null) => {
        const updatedCreatedAt = {
            ...filters.filter_filters.created_at,
            [field]: value,
        };
        updateFilters({
            created_at: updatedCreatedAt,
        });
    };
    
    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>
            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='callsFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Llamadas </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Nombre'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter2' label='Estado:'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ status: e.target.value })} value={filters.filter_filters.status} id='status' placeholder='Estado...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter3' label='DNI:'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ nif: e.target.value })} value={filters.filter_filters.nif} id='nif' placeholder='DNI...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter4' label='Telefono:'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ phone: e.target.value })} value={filters.filter_filters.phone} id='phone' placeholder='Telefono...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='row g-4 mt-1'>
                            <div className='col-12 text-center fw-bold text-muted'>
                                Fecha de Llamada
                            </div>
                            <FormGroup label='Desde' className='col-md-6'>
                                <Input
                                    type='date'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if(!e.target.value) {
                                            handleDateRangeChange('from', null)
                                            return
                                        }
                                        handleDateRangeChange('from', moment(e.target.value).set({hour: 0, minute: 0, second: 0}).format('YYYY-MM-DD HH:mm:ss'))
                                    }}
                                    value={filters.filter_filters.call_date?.from ? moment(filters.filter_filters.call_date?.from).format('YYYY-MM-DD') : ''}
                                    ariaLabel='Default select example'
                                    placeholder='Desde...'
                                />
                            </FormGroup>

                            <FormGroup label='Hasta' className='col-md-6'>
                                <Input
                                    type='date'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if(!e.target.value) {
                                            handleDateRangeChange('to', null)
                                            return
                                        }
                                        handleDateRangeChange('to', moment(e.target.value).set({hour: 23, minute: 59, second: 59}).format('YYYY-MM-DD HH:mm:ss'))
                                    }}
                                    value={filters.filter_filters.call_date?.to ? moment(filters.filter_filters.call_date?.to).format('YYYY-MM-DD') : ''}
                                    ariaLabel='Default select example'
                                    placeholder='Hasta...'
                                />
                            </FormGroup>
                        </div>
                        <div className='row g-4 mt-1'>
                            <div className='col-12 text-center fw-bold text-muted'>
                                Duración de Llamada
                            </div>
                            <FormGroup label='Duración Mínima' className='col-md-6'>
                                <Input
                                    type='number'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDurationRangeChange('from', e.target.value)}
                                    value={filters.filter_filters.call_duration?.from || ''}
                                    ariaLabel='Default select example'
                                    placeholder='Duración Mínima...'
                                />
                            </FormGroup>

                            <FormGroup label='Duración Máxima' className='col-md-6'>
                                <Input
                                    type='number'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDurationRangeChange('to', e.target.value)}
                                    value={filters.filter_filters.call_duration?.to || ''}
                                    ariaLabel='Default select example'
                                    placeholder='Duración Máxima...'
                                />
                            </FormGroup>
                        </div>
                        <div className='row g-4 mt-1'>
                            <div className='col-12 text-center fw-bold text-muted'>
                                Fecha de Creación
                            </div>
                            <FormGroup label='Desde' className='col-md-6'>
                                <Input
                                    type='date'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if(!e.target.value) {
                                            handleCreatedAtRangeChange('from', null)
                                            return
                                        }
                                        handleCreatedAtRangeChange('from', moment(e.target.value).set({hour: 0, minute: 0, second: 0}).format('YYYY-MM-DD HH:mm:ss'))
                                    }}
                                    value={filters.filter_filters.created_at?.from ? moment(filters.filter_filters.created_at?.from).format('YYYY-MM-DD') : ''}
                                    ariaLabel='Default select example'
                                    placeholder='Desde...'
                                />
                            </FormGroup>

                            <FormGroup label='Hasta' className='col-md-6'>
                                <Input
                                    type='date'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if(!e.target.value) {
                                            handleCreatedAtRangeChange('to', null)
                                            return
                                        }
                                        handleCreatedAtRangeChange('to', moment(e.target.value).set({hour: 23, minute: 59, second: 59}).format('YYYY-MM-DD HH:mm:ss'))
                                    }}
                                    value={filters.filter_filters.created_at?.to ? moment(filters.filter_filters.created_at?.to).format('YYYY-MM-DD') : ''}
                                    ariaLabel='Default select example'
                                    placeholder='Hasta...'
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Button
                            className='mt-4'
                            color="storybook"
                            isLight
                            onClick={() => resetFilters(50, true)}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default CallssFilter