import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { OfficeService } from "../../../services/ofiice/officeService";
import { OfficesApiResponse } from "../../../type/office-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import RankingModal from "../ranking-create/RankingModal";
import OfficeFilter from "./RankingFilter";
import moment from "moment";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { RankingService } from "../../../services/ranking/rankingService";
import AsyncImg from "../../../components/AsyncImg";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { useNavigate } from "react-router-dom";

const RankingList = () => {

  const navigate = useNavigate();
  
  const {userCan} = useContext(PrivilegeContext)

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const [office, setOffice] = useState(null);
  const [modal, setModal] = useState(false);
  const [readMode, setReadMode] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);


  const fetchRankings = useCallback(async () => {
    const rankingService = new RankingService();
    const response = await rankingService.getRankings(filters);
    return response.getResponseData() as any;
  }, [filters, modal]);

  const [data, loading, error, refech] = useFetch(fetchRankings);


  const deleteRanking = async (id: string) => {
    let response = (await new RankingService().deleteRanking(id)).getResponseData();
    if (response.success) {
      toast.success('Ranking eliminado correctamente');
      refech();
    } else {
      toast.error('Error al eliminar el Ranking');
    }
  }


  const openModal = (item: any, readBool: boolean) => {
    setReadMode(readBool);
    setOffice(item);
    setModal(true);
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Rankings</CardTitle>
              {userCan('create','ranking')?
              <>
                <SubheaderSeparator />
                <Button
                  color="light"
                  icon="Add"
                  className={`${userCan('create','ranking')?'':'d-none'}`}
                  isLight
                  onClick={() => {
                    openModal(null, false);
                  }}
                >
                  Añadir Ranking
                </Button>
              </> : <></>}
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <OfficeFilter updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {
            data ? (
                <CustomTable
                  data={data?.ranking ? data.ranking : null}
                  pagination={true}
                  defaultLimit={filters.limit || 50}
                  defaultOrder={filters.filter_order || undefined}
                  paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: data as OfficesApiResponse ? data.lastPage : 1,
                    totalCount: data.totalRegisters,
                    handlePagination: updatePage,
                    handlePerPage: updatePageSize,
                  }}
                  className={"table table-hover"}
                  columns={
                    [
                      {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className={`d-flex justify-content-center ${userCan('edit','ranking') ? 'cursor-pointer text-primary fw-bold' : '' }`} onClick={() => {
                              if (userCan('edit','ranking')) {
                                openModal(element, false);
                              }
                            }}>
                              {element.name}
                            </div>
                          )
                        },
                      },
                      {
                        name: "Descripción",
                        keyValue: "description",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center",
                      },
                      {
                        name: "Ususarios",
                        keyValue: "users",
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="avatar-group d-flex justify-content-center" key={element.id + element.users.length + data.ranking.length}>
                              <div className="avatar-container d-flex flex-row gap-3">
                              {element.users.map((user: any , index: number) => {
                                if (index > 2) return null;
                                return (
                                  <Tooltips key={index} title={user?.user?.name}>
                                    <div className="user-avatar">
                                      <AsyncImg
                                      styles="user-avatar"
                                          id={
                                            user?.user?.profileImg
                                              ? user?.user?.profileImg.id
                                              : null
                                          }
                                      />
                                    </div>
                                  </Tooltips>
                                )
                                })}
                              </div>
                              {element.users.length <= 3 ? null : (
                                <div className="avatar-more" style={{width: '32px', height: '32px'}}>
                                  {"+ " + (element.users.length - 3)}
                                </div>
                              )}
                            </div>
                          );
                        }
                      },
                      {
                        name: "Organización",
                        keyValue: "company",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: userCan('list','offices',true) ? "text-center" : "d-none",
                        isVisible: userCan('list','offices',true),
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.company.name}
                            </div>
                          );
                        },
                      },
                      {
                        name: "Fecha de Creación",
                        keyValue: "createdAt",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return <div className="text-center">{moment(element.createdAt.date).format('DD/MM/YYYY HH:mm')}</div>;
                        },
                      },
                      { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]
                  }
                  actions={
                  [
                    /*{
                      title: "Ver",
                      buttonType: "icon",
                      iconColor: "text-primary",
                      iconPath: "/media/icons/duotune/general/gen021.svg",
                      additionalClasses: `text-primary ${userCan("show", "ranking") ? "" : "d-none"
                        }`,
                      description: "Ver el Cliente",
                      callback: (item: any) => {
                        if (userCan("show", "ranking")) navigate(`${item.id}/show`);
                      },
                    },*/
                    {
                      title: "Editar",
                      buttonType: 'icon',
                      iconColor: 'text-info',
                      iconPath: '/media/icons/duotune/general/gen055.svg',
                      additionalClasses: `text-primary ${userCan('edit','ranking')?'':'d-none'}`,
                      description: "Editar la oficina",
                      callback: (item: any) => {
                        if(userCan('edit','ranking')) openModal(item, false);
                      },
                    },     
                    {
                      title: "Eliminar",
                      buttonType: 'icon',
                      iconColor: 'text-danger',
                      iconPath: '/media/icons/duotune/general/gen027.svg',
                      additionalClasses: `text-danger ${userCan('delete','ranking')?'':'d-none'}`,
                      description: "Eliminar Oficina",
                      callback: (item: any) => {
                        if(userCan('delete','ranking')){
                          handleConfirmationAlert({
                            title: "Eliminar Ranking",
                            text: "¿Está seguro que desea eliminar el Ranking? Esta acción no se puede deshacer.",
                            icon: "warning",
                            onConfirm: () => {
                              deleteRanking(item.id);
                            }
                          })
                        }
                      },
                    },
                  ]
                  }
                  />
              ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
          }
        </Card>
      </Page>
      {modal && (<RankingModal isOpen={modal} setIsOpen={setModal} readMode={readMode} setReadMode={setReadMode} ranking={office}  />)}
    </Fragment>
  );
};

export default RankingList;