import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardLabel,
  CardTabItem,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import { Company } from "../../../type/company-type";
import useFetch from "../../../hooks/useFetch";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Spinner from "../../../components/bootstrap/Spinner";
import AsyncImg from "../../../components/AsyncImg";
import Icon from "../../../components/icon/Icon";

const OrganizationProfile: FC = () => {
  // NAVIGATION

  const navigate = useNavigate();

  // GET ID FROM URL

  const { id = "" } = useParams<{ id: string }>();

  // FETCH COMPANY DATA

  const fetchCompany = useCallback(async () => {
    const companyService = new CompanyService();
    const response = await companyService.getCompanyById(id as string);
    return response.getResponseData() as Company;
  }, [id]);

  const [data] = useFetch(fetchCompany);

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate("/organizations", { replace: true })}
          ></Button>
        </SubHeaderLeft>
      </SubHeader>
      {data ? (
        <Page container="fluid">
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <Card hasTab>
                <CardTabItem
                  id="profile"
                  title="Perfil de la Organización"
                  icon="Person"
                >
                  <CardBody>
                    <div className="row g-4">
                      <div className="col-md-4">
                        <div className='col-12 d-flex justify-content-center'>
                           <AsyncImg isBackground height="120px" width="120px" styles="rounded-circle" id={data.logo?.id}/>
                        </div>
                      </div>
                      <div className="col-md-8 row">
                        <div className="col-md-8 row">
                          <div className="col-md-6">
                            <FormGroup label="NOMBRE:">
                              <p className="ms-2"> {data.name || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup label="TELEFONO:">
                              <p className="ms-2"> {data.telephone || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup label="EMAIL:">
                              <p className="ms-2"> {data.email || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup label="CIF:">
                              <p className="ms-2"> {data.cif || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-12">
                            <FormGroup label="DIRECCION:">
                              <p className="ms-2"> {data.address || "-"}</p>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <FormGroup label="DESCRIPCION:">
                            <p className="ms-2"> {data.description || "-"}</p>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </CardTabItem>
              </Card>
            </div>
          </div>
        </Page>
      ) : (
        <div className="text-center text-primary d-flex justify-content-center">
          <Spinner />
        </div>
      )}
    </Fragment>
  );
};

export default OrganizationProfile;
