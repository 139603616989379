import { AxiosResponse } from "axios";
import { RestServiceConnection } from '../restServiceConnection';

// ENDPOINT

const WALLET_ENDPOINT = '/wallets';

export class WalletService extends RestServiceConnection {

    // FUNCTIONS

    /**
     * 
     * @ES Función para obtener las entradas de la cartera
     * @EN Function to get wallet entries
     * 
     * @param filters 
     * @returns A List of Wallet Entries
     */

    getEntries = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/list',
            data: filters, 
        }, true) as AxiosResponse;
        return this;
    }

     /**
     * 
     * @ES Función para obtener una entrada de la cartera
     * @EN Function to get a wallet entry
     * 
     * @param walletId 
     * @returns A List of Wallet Entries
     */

     getEntry = async (walletId : string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/get',
            data: {
                walletId: walletId
            },
        }, true) as AxiosResponse;
        return this;
    }

    /**
     * 
     * @ES Función para borra una entrada de la cartera
     * @EN Function to delete a wallet entry
     * 
     * @param id 
     * @returns Deleted Wallet Entry
     */
    
    deleteEntry = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/delete',
            data: {
                walletId: id
            },
        }, true);
        return this;
    }

    /**
     * 
     * @ES Función para cambiar el estado de una entrada de la cartera
     * @EN Function to change the status of a wallet entry
     * 
     * @param id
     * @param actionStatus
     * @param comment
     * @returns Updated Wallet Entry
     */

    changeActionStatus = async (id: string, actionStatus: string, comment: string|null) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/change-action-status',
            data: {
                walletEntry: id,
                action_status: actionStatus,
                comment: comment
            },
        }, true);
        return this;
    }

     /**
     * 
     * @ES Función para cambiar el estado de accion de una entrada de la cartera
     * @EN Function to change the action status of a wallet entry
     * 
     * @param id
     * @param actionStatus
     * @param comment
     * @returns Updated Wallet Entry
     */

     changeActionWalletStatus = async (id: string, actionStatus: string, comment: string|null) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/change-action-wallet-status',
            data: {
                walletEntry: id,
                action_status: actionStatus,
                comment: comment
            },
        }, true);
        return this;
    }

    /**
     * 
     * @ES Función para exportar las entradas de la cartera
     * @EN Function to export wallet entries
     * 
     * @param filters
     * @returns A Blob with the exported entries
     */

    exportEntries = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/export',
            data: filters,
            responseType: 'blob'
        }, true) as AxiosResponse;
        return this;
    }

    /**
     *  
     * @ES Función para importar las entradas de la cartera
     * @EN Function to import wallet entries
     * 
     * @param file
     * @returns A List of Wallet Entries
     */
    assingCampaign = async (wallets: any, campaignId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/assign-departments',
            data: {
                wallets: wallets,
                campaignId: campaignId
            }
        }, true);
        return this;
    }

    deleteEntries = async (wallets: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: WALLET_ENDPOINT + '/delete',
            data: {
                wallets: wallets
            }
        }, true);
        return this;
    }
}