import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { Expense, ExpenseType, ExpenseTypeApiResponse } from "../../../type/expense-type";
import { ExpenseService } from "../../../services/expenses/expenseService";
import { ExpenseTypeService } from "../../../services/expenses/expenseTypeService";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import Select from "../../../components/bootstrap/forms/Select";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";

export interface IExpenseForm {
    user?: string;
    company?: string;
    name?: string;
    amount?: number;
    date?: any;
    expenseType?: string;
}

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    expense?: Expense | null;
}

const ExpenseModal: FC<CreateModalProps> = ({ isOpen, setIsOpen, readMode, expense }) => {

    const [loadign, setLoading] = useState(false);

    const getFormatedDate = (fecha: any): string | null => {
        try {
            if (fecha?.date && !fecha?.date?.includes('T')) {
                const fechaObjeto = new Date(fecha.date);
    
                // Ajustar la zona horaria a local antes de formatear
                const fechaLocal = new Date(fechaObjeto.getTime() - fechaObjeto.getTimezoneOffset() * 60000);
    
                const fechaFormateada = fechaLocal.toISOString().slice(0, 10) + 'T' + fechaLocal.toISOString().slice(11, 19);
                return fechaFormateada;
            } else {
                return null;
            }
        } catch (error) {
            console.log('error');
            return null;
        }
    };
    
    const TypeInitialValues: IExpenseForm = {
        user: expense?.user?.id,
        company: '',
        name: expense?.name,
        amount: expense?.amount,
        expenseType: expense?.expenseType?.id,
        date: expense?.date ? moment(expense?.date.date).format("YYYY-MM-DD") : ''
    }
    

    const fetchExpensesTypes = useCallback(async () => {
        const response = await new ExpenseTypeService().getExpenseTypes({filter_filters:{fixed:3}});
        return response.getResponseData() as ExpenseTypeApiResponse;
    }, [isOpen]);

    const [expenseTypes, fetchingExpenseTypes, expenseTypeError] = useFetch(fetchExpensesTypes);

    const getExpenseTypeList = () => {

        if (expenseTypes as ExpenseType) {
            return expenseTypes.expenseTypes.map((item: { id: any; expenseName: any; }) => {
                return {
                    value: item.id,
                    label: item.expenseName
                }
            })
        }
        return [];
    }


    const createExpense = async (values: any) => {
        try {
            setLoading(true);
            values.date = moment(values.date as string).format('YYYY-MM-DD HH:mm:ss');
            let response = await (await (new ExpenseService()).createExpense(values)).getResponseData();
            if (response.success) {
                toast.success('Gasto creado exitosamente');
            } else {
                toast.error('Error al crear Gasto');
            }
        } catch (e) {
            toast.error('Error al crear Gasto');
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }

    }

    const updateExpense = async (values: any) => {
        try {
            setLoading(true);
            values.id = expense?.id ?? '';
            values.date = moment(values.date as string).format('YYYY-MM-DD HH:mm:ss');
            let response = await (await (new ExpenseService()).updateExpense(values)).getResponseData();
            if (response.success) {
                toast.success('Gasto editado exitosamente');
            } else {
                toast.error('Error al editar Gasto');
            }
        } catch (e) {
            toast.error('Error al crear Gasto');
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: TypeInitialValues,
        onSubmit: (values) => {
            if (expense === null) {
                createExpense(values);
            } else {
                updateExpense(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode ? (expense ? 'Editar' : 'Nuevo') : 'Ver'}  Gasto</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>

                        <FormGroup requiredInputLabel label='Tipo de gasto' className='col-md-6'>
                            <Select disabled={readMode} required id='expenseType' onChange={formik.handleChange} value={formik.values.expenseType || ''} ariaLabel='Default select example' placeholder='Elegir tipo...'
                                list={getExpenseTypeList()}
                            />
                        </FormGroup>

                        <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6' >
                            <Input disabled={readMode} value={formik.values.name} required onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                    <div className='row g-4 mt-1'>
                        <FormGroup id='date' label='Fecha' className='col-md-6' requiredInputLabel>
                            <Input disabled={readMode} id={'date'} value={formik.values.date || ''} onChange={formik.handleChange} type="date" required />
                        </FormGroup>

                        <FormGroup requiredInputLabel id='amount' label='Importe' className='col-md-6 '>
                            <Input disabled={readMode} min={0} step={0.01} value={formik.values.amount || ''} required onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("amount" , e.target.value)}} type="number"/>
                        </FormGroup>
                    </div>
                </ModalBody>
                {(!readMode) ?
                    <ModalFooter className='px-4 pb-4'>
                        <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                            {loadign  ? <Spinner className="text-center"/> : "Guardar Gasto"}
                        </Button>
                    </ModalFooter>
                    : <></>}
            </form>
        </Modal>
    )
}

export default ExpenseModal;