
import React, { FC, useCallback, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { SMSMatrixService } from '../../services/sms_matrix/SMSMatrixService';
import { SMSMatrixApiResponse } from '../../type/smsMatrix';
import { SMSMatrixTableComponent } from './SMSMatrixTableComponent';
import Spinner from '../bootstrap/Spinner';
import useFilters from '../../hooks/useFilters';
import { CardActions } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import Input from '../bootstrap/forms/Input';
import { toast } from 'react-toastify';
import { useLoader } from '../loader/LoaderProvider';
import { SMSMatrixImport } from './SMSMatrixImport';
import CustomSearchInput from '../bootstrap/forms/CustomSearchInput';

type SMSMatrixListProps = {
    businessId: string;
}

export const SMSMatrixList: FC<SMSMatrixListProps> = ({ businessId }) => {

    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    // find matrix by businessId
    const { filters, updateFilters, updatePageSize, updatePage, updateFilterOrder } = useFilters({ business: businessId });

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const smsMatrixService = new SMSMatrixService();
        const response = await smsMatrixService.list(filters);
        return response.getResponseData() as SMSMatrixApiResponse;
    }, [filters]));

    const _showUploadMatrixModal = () => {
        setShowUploadModal(true);
    }

    return (
        <>
            <CardActions className='text-end'>
                <Button color='primary' isLight icon='Upload' onClick={_showUploadMatrixModal}>Importar Matriz</Button>
            </CardActions>
            <CardActions className='text-start d-flex'>
                <CustomSearchInput placeholder='CNAE' defaultValue={filters.filter_filters?.cnae || ''} onSearch={(search: string) => {
                    updateFilters({ cnae: search })
                }} />
                <CustomSearchInput placeholder='Nombre' defaultValue={filters.filter_filters?.name || ''} onSearch={(search: string) => {
                    updateFilters({ name: search })
                }} />
                <CustomSearchInput placeholder='Tipo (LUZ/GAS)' defaultValue={filters.filter_filters?.type || ''} onSearch={(search: string) => {
                    updateFilters({ type: search })
                }} />
                <CustomSearchInput placeholder='Tarifa de acceso' defaultValue={filters.filter_filters?.fee || ''} onSearch={(search: string) => {
                    updateFilters({ fee: search })
                }} />
                <CustomSearchInput placeholder='Servicios' defaultValue={filters.filter_filters?.services || ''} onSearch={(search: string) => {
                    updateFilters({ services: search })
                }} />
                <CustomSearchInput placeholder='Potencia' defaultValue={filters.filter_filters?.power || ''} onSearch={(search: string) => {
                    updateFilters({ power: search })
                }} />

            </CardActions>
            {loading && <div className="text-center"><Spinner></Spinner></div>}
            {!loading && (
                <SMSMatrixTableComponent
                    data={data}
                    filters={filters}
                    visibleColums={['name', 'cnae', 'type', 'fee', 'services', 'individualEnabled', 'businessEnabled', 'powerFrom', 'powerTo', 'message']}
                    visibleActions={[]}
                    updateFilters={updateFilters}
                    updatePage={updatePage}
                    updatePageSize={updatePageSize}
                    updateFilterOrder={updateFilterOrder}
                    classNameProps=""
                    error={error}
                    refetch={refetch}
                />
            )}
            <Modal isOpen={showUploadModal} setIsOpen={setShowUploadModal} size={'lg'}>
                <ModalHeader setIsOpen={setShowUploadModal}>
                    <h5 className="modal-title">Importar Matriz</h5>
                </ModalHeader>
                <ModalBody>
                    <SMSMatrixImport businessId={businessId} onSubmit={() => { 
                        setShowUploadModal(false)
                        refetch() 
                    }} />
                </ModalBody>
            </Modal>

        </>
    );
}