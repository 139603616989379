import * as yup from 'yup';
import { Product, ProductApiResponse } from '../../../../type/product-type';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import useFilters from '../../../../hooks/useFilters';
import { useBusinessList } from '../../../../hooks/useListData';
import { ProductService } from '../../../../services/products/productService';
import useFetch from '../../../../hooks/useFetch';
import Spinner from '../../../../components/bootstrap/Spinner';
import { CustomTable } from '../../../../components/table/CustomTable';
import { CheckCircleOutline, HighlightOff } from '../../../../components/icon/material-icons';
import { useFormik } from 'formik';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import Select from '../../../../components/bootstrap/forms/Select';
import RadioButton from '../../../../components/RadioButtons';
import Input from '../../../../components/bootstrap/forms/Input';


export interface IProductsForm {
    productsSelected: Product[];
    businessId: string;
    businessEnabled: boolean;
    individualEnabled: boolean;
    power: number | string | null;
    consumptionfilt: number | string | null;
}

const ProductsInitialValues: IProductsForm = {
    productsSelected: [],
    businessId: "",
    businessEnabled: false,
    individualEnabled: true,
    power: null,
    consumptionfilt: null,
}

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    onClose?: (message: string, product?: any) => void;
}

export interface IOperationForm {
    businessId: string;
    individualEnabled: boolean;
    businessEnabled: boolean;
    power: number | null;
    consumptionfilt: number | null;
}

const validationSchema = yup.object({
    businessId: yup.string().nullable(),
    individualEnabled: yup.boolean().nullable(),
    businessEnabled: yup.boolean().nullable(),
    power: yup
        .number()
        .min(0, 'Debe ser un número positivo o cero')
        .max(1000, 'Debe ser menor o igual a 1000')
        .nullable(),
    consumptionfilt: yup
        .number()
        .min(0, 'Debe ser un número positivo o cero')
        .max(1000, 'Debe ser menor o igual a 1000')
        .nullable(),
});

export interface IProductsFilters {
}

const AddWizardProduct: FC<CreateModalProps> = ({ isOpen, setIsOpen, mode, onClose }) => {


    const ProductsFilters: IProductsFilters = {
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(ProductsFilters, [], 999999);

    const businessList = useBusinessList();
    const [selectedTarget, setSelectedTarget] = useState<string|null>(null);

    const fetchProducts = useCallback(async () => {
        const operationService = new ProductService();
        const response = await operationService.getProducts(filters);
        return response.getResponseData() as ProductApiResponse;
    }, [filters]);

    const [data, loading, error, refetch] = useFetch(fetchProducts);
    
    const _handleSelectAllPrd = (checked: boolean): void => {
        if (checked) {
            let selectedItemsCopy = [...data.products];
            formik.setFieldValue('productsSelected', selectedItemsCopy);
        } else {
            formik.setFieldValue('productsSelected', []);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: ProductsInitialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.productsSelected !== null && values.productsSelected.length > 0) {
                setIsOpen(false);
                onClose && onClose("Producto seleccionado correctamente", values.productsSelected);
            } else {
                onClose && onClose("Debe seleccionar un producto");
            }
        },
    });

    
    const handleCheckboxChange = (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
        product: Product
    ) => {
        const { checked } = event.target;
        if (checked) {
            const selectedItemCopy = { ...product };
            formik.setFieldValue('productsSelected', [...formik.values.productsSelected, selectedItemCopy]);
        } else {
            let index = formik.values.productsSelected.findIndex(
                (item: any) => item.id === id
            );
            formik.values.productsSelected.splice(index, 1);
            formik.setFieldValue('productsSelected', [...formik.values.productsSelected]);
        }
    };


    const verifyClass = (inputFieldID: keyof IOperationForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IOperationForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as string}</div> : <></>;
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Eleccion Producto'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_expense'>Nueva eleccion de Producto</ModalTitle>) : (<ModalTitle id='edit_expense'>Editar elccion de Producto</ModalTitle>)}
            </ModalHeader>
            <ModalBody className='px-4'>
                <Wizard
                    isHeader={true}
                    title="Products Selection"
                    color={'primary'}
                    onSubmit={formik.handleSubmit}
                    disabledNextButton={[{ id: 0, disabled: false }, { id: 2, disabled: Object.keys(formik.errors).length === 0 ? false : true }]}
                    onNext={(active: number) => {
                        if (active === 0) {
                            updateFilters({ 
                            business: formik.values.businessId, 
                            individual_enabled: selectedTarget === 'individual' ? 1 : null, 
                            business_enabled: selectedTarget === 'business' ? 1 : null ,
                            power: formik.values.power == "" ? null : formik.values.power, 
                            consumption: formik.values.consumptionfilt == "" ? null : formik.values.consumptionfilt,
                        });
                    }}}
                >
                    <WizardItem
                        id={'options_product'}
                        title={'Opciones del Producto'}
                    >
                        <div className="row">
                            <FormGroup requiredInputLabel id='business' label='Comercializadora del Producto:' className='col-md-6'>
                                <Select id='businessId' ariaLabel='Default select example' placeholder='Elegir Comercializadora...' onChange={(e: any) => formik.setFieldValue('businessId', e.target.value)} value={formik.values.businessId} list={businessList} onBlur={formik.handleBlur} className={verifyClass('businessId')} autoFocus={true} />
                                {showErrors('businessId')}
                            </FormGroup>
                            <FormGroup id='target' label='Destinatario del Producto:' className='col-md-6 text-center'>
                                <br />
                                <RadioButton onOptionChange={(value) => { setSelectedTarget(value) }}></RadioButton>
                            </FormGroup>
                        </div>
                        <div className="row mt-5">
                            <FormGroup id='power' label='Potencia (kw):' className='col-md-6'>
                                <Input id="power" min={0} step={0.01} name="power" type="number" value={formik.values.power || ""} onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("power" , e.target.value)}} onBlur={formik.handleBlur} className={verifyClass('power')} />
                                {showErrors('power')}
                            </FormGroup>
                            <FormGroup id='consumptionfilt' label='Consumo (kw)' className='col-md-6'>
                                <Input id="consumptionfilt" min={0} step={0.01} name="consumptionfilt" type="number" value={formik.values.consumptionfilt || ""} onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("consumptionfilt" , e.target.value)}} onBlur={formik.handleBlur} className={verifyClass('consumptionfilt')} />
                                {showErrors('consumptionfilt')}
                            </FormGroup>
                        </div>
                    </WizardItem>
                    <WizardItem
                        id={'choose_product'}
                        title={'Elije el producto'}
                    >
                        {
                            data ? (
                                <CustomTable
                                    data={data.products}
                                    className={"table table-hover p-3 fs-7 max-h-250"}
                                    selectableItems={true}
                                    onChangeCheckedItems={(id: string, event: ChangeEvent<HTMLInputElement>, item: any) => handleCheckboxChange(event, id, item)}
                                    onSelectAllItems={_handleSelectAllPrd}
                                    pagination={true}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: data as ProductApiResponse ? data.lastPage : 1,
                                        totalCount: data.totalRegisters,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    columns={[
                                        {
                                            name: "Nombre",
                                            keyValue: "name",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="fs-7">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Tarifa",
                                            keyValue: "fee",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.fee.name}</div>
                                            },
                                        },
                                        {
                                            name: "Prioridad",
                                            keyValue: "priority",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.priority}</div>
                                            },
                                        },
                                        {
                                            name: "Tipo de Producto",
                                            keyValue: "productType",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.productType.name} </div>
                                            }
                                        },
                                        {
                                            name: "Tarifa",
                                            keyValue: "fee",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.fee.name}</div>
                                            },
                                        },
                                        {
                                            name: "Habilitado Particular",
                                            keyValue: "individualEnabled",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.individualEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                                            }
                                        },
                                        {
                                            name: "Habliitado Empresa",
                                            keyValue: "businessEnabled",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.businessEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                                            }
                                        },
                                    ]}
                                />
                            ) : (<div className="text-center d-flex justify-content-center"><Spinner/></div>)
                        }
                    </WizardItem>
                </Wizard>
            </ModalBody>
        </Modal>
    )
}

export default AddWizardProduct;