import { FC, useEffect, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Dropzone from "react-dropzone";
import Icon from "../../../components/icon/Icon";
import Card, {
  CardBody,
  CardHeader,
} from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { UserService } from "../../../services/users/userService";
import { OperationService } from "../../../services/operations/operationService";

interface TypeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  id : string;
  idOperation?: string;
  documentType: number;
  refetch: Function;
}

const UploadDocument: FC<TypeModalProps> = ({
  isOpen,
  idOperation,
  setIsOpen,
  id,
  documentType,
  refetch
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const addDocument = async (values: any) => {
    let response;
    const formData = new FormData();
    values.document.forEach((file: File, index: number) => {
      formData.append(`documents[${index}]`, file);
    });
    formData.append("document_type", values.document_type);
    formData.append("user", id);
    if (idOperation) {
      formData.append("operation", idOperation);
      response = await (
        await new OperationService().addOperationDocuments(formData)
      ).getResponseData();
    }else {
      response = await (
        await new UserService().addUserDocuments(formData)
      ).getResponseData();
    }
    if (response) {
      toast.success("Documentos subidos con éxito");
      refetch();
      setIsOpen(false);
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const formik = useFormik({
    initialValues: {
      document: [] as File[],
      document_type: documentType,
    },
    onSubmit: (values) => {
      values.document = uploadedFiles;
      addDocument(values);
    },
  });

  return (
    <>
    <div className="d-flex justify-content-end mt-4">
      <Button
        color="danger"
        icon="Close"
        isLight
        onClick={() => {
          setIsOpen(false);
          setUploadedFiles([]);
        }}
      >
        Cerrar
      </Button>
    </div>
    <form onSubmit={formik.handleSubmit}>
      <div className="row g-4 m-2">
        <FormGroup className="col-md-12 d-flex justify-content-center flex-column align-items-center mb-3">
          <Dropzone
            onDrop={(acceptedFiles) => {
              if (uploadedFiles.length < 5 && acceptedFiles.length < 6) {
                setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
              } else {
                toast.warning("No se pueden subir más de 5 archivos a la vez");
              }
            }}
            multiple={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="dropzone rounded w-100 p-5"
                style={{
                  borderStyle: "dashed",
                  borderColor: "gray",
                }}
              >
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="text-center">
                    Arrastra y suelta archivos aquí o haz clic para seleccionar
                    archivos
                  </p>
                  <Icon icon="CloudUpload" size="5x" color="gray" />
                </div>
              </div>
            )}
          </Dropzone>
          {uploadedFiles.length > 0 ? (
            <>
              <label className="mt-3">Archivos subidos:</label>
              <div className="d-flex align-items-center flex-wrap justify-content-around gap-2 mt-3 w-100">
                {uploadedFiles.map((file, index) => (
                  <Card key={index}>
                    <CardHeader className="d-flex justify-content-end p-0" style={{minHeight: "0px"}}>
                      <Button
                        className="btn pb-0 pt-0 pr-1 pl-1"
                        icon="Clear"
                        iconColor={"danger"}
                        onClick={() => removeFile(index)}
                      ></Button>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <div className="d-flex align-items-center flex-column">
                        <Icon icon="Task" color="success" size="5x" />
                        <div
                          className="text-truncate pt-4"
                          style={{ maxWidth: "100px" }}
                        >
                          {file.name}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </FormGroup>
        <div className="d-flex justify-content-center">
        <Button type="submit" color="success" icon="UploadFile" isLight>
          Subir Documentos
        </Button>
        </div>
      </div>
    </form>
    </>
  );
};

export default UploadDocument;
