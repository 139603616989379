import { Status } from "../../type/actionStatus-type";
import Button from "../bootstrap/Button";


export default ({ status, date }: { status: Status, date?: string|undefined }) => {
    return (
        <Button isLink isDisable={true} className='text-nowrap fs-8' style={{backgroundColor: status.color ? status.color : 'whiteSmoke', color : "white"}}>
            {status.name}
            {date ? <div className='text-nowrap fs-8'>{date}</div> : null}
        </Button>
    )
}