import { ChangeEvent, FC, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { ExpenseType } from "../../../type/expense-type";
import { ExpenseTypeService } from "../../../services/expenses/expenseTypeService";
import Checks from "../../../components/bootstrap/forms/Checks";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";

export interface IExpenseTypeForm {
    user?: string;
    company?: string;
    expenseName?: string;
    amount?: number;
    fixed?: boolean;
}

interface TypeModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    expenseType?: ExpenseType | null;
}

const ExpenseTypeModal: FC<TypeModalProps> = ({ isOpen, setIsOpen, readMode, expenseType }) => {

    const [loadign, setLoading] = useState(false);

    const TypeInitialValues: IExpenseTypeForm = {
        user: expenseType?.user?.id,
        company: expenseType?.company?.id || '',
        expenseName: expenseType?.expenseName,
        amount: expenseType?.amount,
        fixed: expenseType?.fixed || false
    }    


    const createExpenseType = async (values: any) => {
        try {
            setLoading(true);
            let response = await (await (new ExpenseTypeService()).createExpenseType(values)).getResponseData();
            if (response.success) {
                toast.success('Tipo de gasto creado exitosamente');
            } else {
                toast.error('Error al crear Tipo de Gasto');
            }
        } catch (e) {
            toast.error('Error al crear Tipo de Gasto');
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }

    }

    const editExpenseType = async (values: any) => {
        try {
            setLoading(true);
            values.id = expenseType?.id;
            let response = await (await (new ExpenseTypeService()).editExpenseType(values)).getResponseData();
            if (response.success) {
                toast.success('Tipo de gasto editado con éxito');
            } else {
                toast.error('Error al editar Tipo de Gasto');
            }
        } catch (e) {
            toast.error('Error al editar Tipo de Gasto');      
            console.log('error', e);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: TypeInitialValues,
        onSubmit: (values) => {
            if (expenseType === null) {
                createExpenseType(values);
            } else {
                editExpenseType(values);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Rol'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode?(expenseType ? 'Editar':'Nuevo'):'Ver'}  Tipo de Gasto</ModalTitle>
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='expenseName' label='Nombre' className='col-md-6'>
                            <Input disabled={readMode} value={formik.values.expenseName || ''} required onChange={formik.handleChange} />
                        </FormGroup>

                        <FormGroup requiredInputLabel id='amount' label='Importe' className='col-md-6 '>
                            <Input disabled={readMode} min={0} step={0.01} value={formik.values.amount || ''} required onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("amount" , e.target.value)}} type="number"/>
                        </FormGroup>
                    </div>   
                    <div className='row g-4 mt-1'>
                        <FormGroup id='fixed' label='Fijo' className='px-3'>
                            <Checks disabled={readMode} tmuted={true} label="Los gastos fijos se agregarán automáticamente el último día del mes" id="fixed" type="checkbox" checked={formik.values.fixed || false} onChange={formik.handleChange}/>
                        </FormGroup>
                    </div>
                </ModalBody>
                {(!readMode)?
                    <ModalFooter className='px-4 pb-4'>
                        <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                            {loadign  ? <Spinner className="text-center"/> : "Guardar Tipo de Gasto"}
                        </Button>
                    </ModalFooter>
                : <></>}
            </form>
        </Modal>
    )
}

export default ExpenseTypeModal;