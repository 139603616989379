import { FC, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { ActionStatus, NewActionStatus } from "../../../type/actionStatus-type";
import { StatusService } from "../../../services/status/statusService";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import {CirclePicker, CompactPicker, SliderPicker} from 'react-color';
import StatusTypeSelector from "../../../components/selectors/status_selector/StatusTypeSelector";
import Spinner from "../../../components/bootstrap/Spinner";
import { colors } from "react-select/dist/declarations/src/theme";
import Label from "../../../components/bootstrap/forms/Label";
import { set } from "date-fns";

export interface IActionForm {
    name: string;
    color: string;
    entity: string;
    description: string | null | undefined;
}

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    actionStatus?: ActionStatus;
    onClose?: (message?: string) => void;
}

const defaultColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', 
    '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b']

const ActionCreateModal: FC<CreateModalProps> = ({ isOpen, setIsOpen, mode, actionStatus, onClose }) => {

    const [loadign, setLoading] = useState(false);

    const [ generatedColors, setGeneratedColors ] = useState<string[]>(defaultColors);

    const [ reloadColors, setReloadColors ] = useState("");

    const createActionStatus = async (values: NewActionStatus) => {
        try {
            setLoading(true);
            let response = await (await (new StatusService()).createActionStatus(values)).getResponseData();
            if (response) {
                setLoading(false);
                setIsOpen(false);
                onClose && onClose("Estado de Acción creado correctamente");
            }
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }

    }

    const updateActionStatus = async (values: any) => {
        try {
            setLoading(true);
            values.action_status = actionStatus?.id ?? '';
            values.company = actionStatus?.company.id ?? '';
            let response = await (await (new StatusService()).editActionStatus(values)).getResponseData();
            if (response) {
                setLoading(false);
                setIsOpen(false);
                onClose && onClose("Cambios guardados correctamente");
            }
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
            mode === 'create'
                ?
                {
                    name: '',
                    color: '',
                    description: '',
                    entity: ''
                }
                :
                {
                    name: actionStatus?.name ?? '',
                    description: actionStatus?.description ?? '',
                    color: actionStatus?.color ?? '',
                }
        ,
        onSubmit: (values) => {
            if (mode === 'create') {
                createActionStatus(values as unknown as NewActionStatus);
            } else {
                updateActionStatus(values);
            }
        },
    });

    const regenerateColors = () => {
        const newColors: Set<string> = new Set();
        while (newColors.size < 18) {
            let n = (Math.random() * 0xfffff * 1000000).toString(16);
            newColors.add('#' + n.slice(0, 6));
        }
        const shuffledColors = Array.from(newColors).sort(() => Math.random() - 0.5);
        setGeneratedColors(shuffledColors);
        setReloadColors(Math.random().toString());
    }

    useEffect(() => {
        if (mode === 'edit' && isOpen) {
            let defaultColorsCopy = [...defaultColors];
            defaultColorsCopy[defaultColorsCopy.length - 1] = actionStatus?.color ?? '#607d8b';
            setGeneratedColors(defaultColorsCopy);
        } else  {
            setGeneratedColors(defaultColors);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Estado de Accion'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_status'>Nuevo Estado de Accion</ModalTitle>) : (<ModalTitle id='edit_status'>Editar Estado de Accion</ModalTitle>)}
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-12' >
                            <Input value={formik.values.name} required onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                    { mode === 'create' ?
                    <div className='row g-4 mt-1'>
                        <StatusTypeSelector name={'entity'} isRequired defaultSelected={formik.values.entity} isMulti={false} onChange={(value) => {
                            formik.setFieldValue('entity', value);
                        }}/>
                    </div> : null }
                    <div className='row g-4 mt-1'>
                        <FormGroup id='description' label='Descripcion' className='col-md-12' >
                            <Textarea value={formik.values.description == null ? '' : formik.values.description} onChange={formik.handleChange} />
                        </FormGroup>
                    </div>
                    <div className='row g-4 mt-1'>
                        <FormGroup id='date' className='col-md-12' requiredInputLabel>
                            <Label className="mb-4 d-flex justify-content-between">
                                <span>Seleccione un color <span className="text-danger"> * </span></span>
                                <Button color="primary" onClick={regenerateColors} icon="Refresh">
                                    Regenerar Colores
                                </Button>
                            </Label>
                            <div className="">
                                    <CirclePicker color={formik.values.color} onChangeComplete={(color: any) => {
                                        formik.setFieldValue('color', color.hex)
                                    }} colors={generatedColors} key={reloadColors}/>
                            </div>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                        {loadign  ? <Spinner className="text-center"/> : mode === 'create' ? 'Guardar Estado de Acción' : 'Editar Estado de Acción'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default ActionCreateModal;