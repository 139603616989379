import { FC, useContext, useState } from "react";
import { CustomTable } from "../CustomTable";
import { ProductsApiResponse } from "../../../type/product-type";
import { PrivilegeContext } from "../../priviledge/PriviledgeProvider";
import { useNavigate } from "react-router-dom";
import Badge from "../../bootstrap/Badge";
import StatusDropdown from "../../StatusDropdown";
import { CheckCircleOutline, HighlightOff } from "../../icon/material-icons";
import moment from "moment";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Spinner from "../../bootstrap/Spinner";
import { ProductService } from "../../../services/products/productService";
import { toast } from "react-toastify";

interface ProductsTableComponentProps {
    data : any;
    businessId?: string;
    visibleColums: string[];
    visibleActions: string[];
    updateFilters: Function;
    filters: any;
    updatePage: Function;
    updatePageSize: Function;
    defaultLimit?: number,
    defaultOrder?: any,
    updateFilterOrder: any;
    classNameProps: string;
    refetch: Function;
}

const ProductsTableComponent:FC<ProductsTableComponentProps> = ({
    data,
    updateFilters,
    filters,
    updatePage,
    updatePageSize,
    defaultLimit,
    defaultOrder,
    updateFilterOrder,
    classNameProps,
    businessId,
    visibleColums,
    visibleActions,
    refetch
}) => {

  const navigate = useNavigate();

  const { userCan } = useContext(PrivilegeContext);

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleProductStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new ProductService().toggleProductStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
    refetch();
  };

  const deleteProduct = async (id: string) => {
    let response = (await new ProductService().deleteProduct(id)).getResponseData();
    if (response.success) {
      toast.success('Producto eliminado correctamente');
      updateFilters({ ...filters });
    }
  }

    return (
        <>
            {
                data ? (
                    <CustomTable
                      data={data?.products ? data.products : null}
                      pagination={true}
                      className={classNameProps}
                      defaultLimit={defaultLimit}
                      defaultOrder={defaultOrder}
                      paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data as ProductsApiResponse ? data.lastPage : 1,
                        totalCount: data.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                      }}
                      columns={
                        [
                          {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("name") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("name"),
                            render: (element: any) => {
                              return (
                                <div className="d-flex flex-column justify-content-center text-center cursor-pointer text-primary fw-bold" onClick={() => {
                                  if (userCan('show', 'products')) {
                                    if (businessId) {
                                      navigate(`products/${element.id}/show`)
                                    } else {
                                      navigate(`${element.id}/show`);
                                    }
                                  }
                                }}>
                                  {element.name}
                                  {element.services.length > 0 &&
                                   element.services
                                   .sort((a: any, b: any) => a.service.name.localeCompare(b.service.name))
                                   .map((service: any) => (
                                    <div className="m-auto">
                                      <Badge
                                        key={element.id + "-" + service.id}
                                        color="primary"
                                        rounded={"pill"}
                                        isLight
                                      >
                                        {service.service.name}
                                      </Badge>
                                    </div>
                                  ))}
                                </div>
                              )
                            },
                          },
                          {
                            name: "Tarifa",
                            keyValue: "fee",
                            className: `${visibleColums.includes("fee") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("fee"),
                            cellClassName: "text-center",
                            render(element: any): JSX.Element {
                              return (
                                <div className="d-flex justify-content-center">
                                  {element.fee.name}
                                </div>
                              )
                            }
                          },
                          {
                            name: "Comercializadora",
                            keyValue: "business",
                            className: `${visibleColums.includes("business") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("business"),
                            cellClassName: "text-center",
                            render(element: any): JSX.Element {
                              return (
                                <div className="d-flex justify-content-center">
                                  {element.business.name}
                                </div>
                              )
                            }
                          },
                          {
                            name: "Potencia",
                            keyValue: "powerFrom",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("power") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("power"),
                            cellClassName: "text-center",
                            render(element: any): JSX.Element {
                              return (
                                <div key={element.productType.id} className="d-flex justify-content-center">
                                  {element.powerFrom ?? '-'} - {element.powerTo ?? '-'}
                                </div>
                              )
                            }
                          },
                          {
                            name: "Consumo",
                            keyValue: "consumptionFrom",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("consumption") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("consumption"),
                            cellClassName: "text-center",
                            render(element: any): JSX.Element {
                              return (
                                <div key={element.productType.id} className="d-flex justify-content-center">
                                  {element.consumptionFrom ?? '-'} - {element.consumptionTo ?? '-'}
                                </div>
                              )
                            }
                          },
                          {
                            name: "Comisión Base",
                            keyValue: "baseCommission",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("baseCommission") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("baseCommission"),
                            cellClassName: "text-center",
                          },
                          {
                            name: "Prioridad",
                            keyValue: "priority",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("priority") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("priority"),
                            cellClassName: "text-center",
                          },
                          {
                            name: "Puntos",
                            keyValue: "points",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("points") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("points"),
                            cellClassName: "text-center",
                          },
                          {
                            name: "Tipo",
                            keyValue: "product_type",
                            className: `${visibleColums.includes("productType") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("productType"),
                            render: (element: any) => {
                              if (element.productType) {
                                return (
                                  <div key={element.productType.id} className="d-flex justify-content-center">
                                    {element.productType.name ?? ''}
                                  </div>
                                )
                              } else return (<></>);
                            }
                          },
                          {
                            name: "Tipo Contratos",
                            keyValue: "operation_types",
                            className: `${visibleColums.includes("operationTypes") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("operationTypes"),
                            render: (element: any) => {
                              
                              if (element.operationTypes) {
                                return (
                                  <div key={element.productType.id} className="d-flex flex-column justify-content-center">
                                    {
                                      element.operationTypes.map((operationType: any) => {
                                        return (
                                          <Badge color="info" isLight key={element.id + '-' +operationType.id} className="mt-2">{operationType.operationType.name}</Badge>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              } else return (<>-</>);
                            }
                          },
                          {
                            name: "Tipo de trámite",
                            keyValue: "leads",
                            className: `${visibleColums.includes("leads") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("leads"),
                            render: (element: any) => {
                              
                              if (element.leads) {
                                return (
                                  <div key={element.productType.id} className="d-flex flex-column justify-content-center">
                                    {
                                      element.leads.map((lead: any) => {
                                        return (
                                          <Badge color="primary" isLight key={element.id + '-' +lead.id} className="mt-2">{lead.lead.name}</Badge>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              } else return (<>-</>);
                            }
                          },
                          {
                            name: "Estado",
                            keyValue: "active",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("status") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("status"),
                            render: (element: any) => {
                              return (
                                <div className={'text-center fs-7'}>
                                  <StatusDropdown
                                    disabled={changingStatus.includes(element.id)}
                                    itemId={element.id}
                                    key={element.id}
                                    status={element.active}
                                    change={toggleProductStatus}
                                  />
                                </div>
                              );
                            },
                          },
                          {
                            name: "Negocio",
                            keyValue: "businessEnabled",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("clientType") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("clientType"),
                            render: (element: any) => {
                              return (
                                <div className="fs-7 text-center">{element.businessEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                              );
                            },
                          },
                          {
                            name: "Individual",
                            keyValue: "individualEnabled",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("clientType") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("clientType"),
                            render: (element: any) => {
                              return (
                                <div className="fs-7 text-center">{element.individualEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                              );
                            },
                          },
                          {
                            name: "Creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: `${visibleColums.includes("createdAt") ? "text-center" : "d-none"}`,
                            isVisible: visibleColums.includes("createdAt"),
                            render: (element: any) => {
                              return (
                                <div className={'text-center'}>
                                  <span className={'text-muted'}>{moment(element.createdAt.date).format('DD/MM/YYYY H:mm')}</span>
                                </div>
                              )
                            },
                          },
                          { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                        ]
                      }
                      actions={
                        [
                          {
                            title: "Ver",
                            buttonType: 'icon',
                            iconColor: 'text-primary',
                            iconPath: '/media/icons/duotune/general/gen021.svg',
                            additionalClasses: `text-primary ${userCan('show', 'products') ? '' : 'd-none'} ${visibleActions.includes("show") ? '' : 'd-none'}`,
                            description: "Ver producto",
                            callback: (item: any) => {
                              if (userCan('show', 'products')) {
                                if (businessId) {
                                  navigate(`products/${item.id}/show`)
                                } else {
                                  navigate(`${item.id}/show`);
                                }
                              }
                            },
                          },
                          {
                            title: "Editar",
                            buttonType: 'icon',
                            iconColor: 'text-info',
                            iconPath: '/media/icons/duotune/general/gen055.svg',
                            additionalClasses: `text-primary ${userCan('edit', 'products') ? '' : 'd-none'} ${visibleActions.includes("edit") ? '' : 'd-none'}`,
                            description: "Editar el producto",
                            callback: (item: any) => {
                              if (userCan('edit', 'products')) {
                                if (businessId) {
                                  navigate(`products/${item.id}/edit`)
                                } else {
                                  navigate(`${item.id}/edit`)
                                }
                              };
                            },
                          },
            
                          {
                            title: "Eliminar",
                            buttonType: 'icon',
                            iconColor: 'text-danger',
                            iconPath: '/media/icons/duotune/general/gen027.svg',
                            additionalClasses: `text-danger ${userCan('delete', 'products') ? '' : 'd-none'} ${visibleActions.includes("delete") ? '' : 'd-none'}`,
                            description: "Eliminar Producto",
                            callback: (item: any) => {
                              if (userCan('delete', 'products')) {
                                handleConfirmationAlert({
                                  title: "Eliminar Producto",
                                  text: "¿Está seguro que desea eliminar el producto?",
                                  icon: "warning",
                                  onConfirm: () => {
                                    deleteProduct(item.id);
                                  }
                                })
                              }
                            },
                          },
                        ]
                      }
                    />
                  ) : (<div className="text-center d-flex justify-content-center"><Spinner /></div>)
            }
        </>
    );
};

export default ProductsTableComponent;