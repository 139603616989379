import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { CampaignService } from "../../../services/campaigns/campaignService";
import useFilters from "../../../hooks/useFilters";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import CampaignStatusTable from "../../table/CampaignStatusTable/CampaignStatusTable";
import Button from "../../bootstrap/Button";
import CampaignStatusSelectorModal from "./CampaignStatusSelectorModal";
import { ActionStatus } from "../../../type/actionStatus-type";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { CampaignStatus } from "../../../type/campaign-type";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";

type CampaignChangeStatusTableWizardProps = {
    campaign: string;

};

const CampaignChangeStatusTableWizard: React.FC<CampaignChangeStatusTableWizardProps> = ({ campaign }) => {

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ campaign }, [], 99999);
    const [addModal, setAddModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState<CampaignStatus[]>([]);
    const { handleErrors } = useHandleErrors();

    const [data, loading, errors, refetch] = useFetch(
        useCallback(async () => {
            const campaignService = new CampaignService();
            const response = await campaignService.listCampaignChangeStatuses(filters);
            return response.getResponseData();
        }, [])
    )
    
    // When close modal we need to update the table
    const _handleOnAddChnageStatuses = async (value: []) => {
        setAddModal(false);

        if (value.length > 0) {
            let statuses: string[] = value.map((status: ActionStatus) => status.id)
            for (let status of statuses) {
                let campaignService = new CampaignService()
                let request = await campaignService.addChangeStatusToCampaign(campaign, status)
                let response = await request.getResponseData()
                if (response.success) {
                    toast.success('Estados añadidos correctamente')
                } else {
                    handleErrors(response)
                }
            }
        }
        refetch()
    }

    const _handleOnDelete = async (value: CampaignStatus[]) => {
        if (value.length > 0) {
            let statuses: string[] = value.map((status: CampaignStatus) => status.id)
            for (let status of statuses) {
                let campaignService = new CampaignService()
                let request = await campaignService.removeChangeStatusFromCampaign(status)
                let response = await request.getResponseData()
                if (response.success) {
                    toast.success('Estados borrados correctamente')
                } else {
                    handleErrors(response)
                }
            }
        }
        refetch()
    }

    const _handleDeleteSelected = async () => {
        if (selectedItems.length === 0) {
            toast.error('Debe seleccionar al menos un estado')
            return
        }
        handleConfirmationAlert({
            title: 'Eliminar estados',
            icon: 'warning',
            text: '¿Está seguro que desea eliminar los estados seleccionados de este departamento?',
            onConfirm: async () => {
                await _handleOnDelete(selectedItems)
            }
        })
    }

    return (
        <>
            <Card>
                <CardBody isScrollable={false}>
                    <div className="row">
                        <div className='col-md-12'>
                            <CardHeader className='rounded-1 mb-0' >
                                <CardLabel icon='Label' iconColor='info'>
                                    <CardTitle >Estados a los que puede cambiar</CardTitle>
                                </CardLabel>
                                <CardActions>
                                    <Button color="primary" icon="Add" isLight onClick={() => setAddModal(true)}></Button>
                                    <Button color="danger" icon="DeleteForever" isLight onClick={_handleDeleteSelected}></Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CampaignStatusTable data={data} selectableItems onDelete={_handleOnDelete} onChangeCheckedItems={setSelectedItems}></CampaignStatusTable>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {addModal && <CampaignStatusSelectorModal campaignId={campaign} isOpen={addModal} setIsOpen={setAddModal} onClose={_handleOnAddChnageStatuses} />}

        </>
    );
};

export default CampaignChangeStatusTableWizard;