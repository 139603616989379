import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import BusinessForm from "../BusinessForm";
import { BusinessService } from "../../../services/business/businessService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateBusiness: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleCreation = async (values: any) => {
    setLoading(true);

    const formData = new FormData();
    
    for(let value in values) {
      if(values[value]){
        formData.append(value, values[value]);
      }else{
        formData.append(value, '');
      }
    }

    try {
      setLoading(true);
      let response = await ( await new BusinessService().createBusiness(formData,formData.get('logo')!==null )).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate(-1);
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
        setError(response.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    } catch (error: any) {
      toast.error('Error al crear Comercializadora');      
    } finally {
      setLoading(false);
    } 

  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          ></Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardHeader borderSize={1}>
            <CardLabel icon="AddBusiness" iconColor="info">
              <CardTitle>Nueva Comercializadora</CardTitle>
            </CardLabel>
          </CardHeader>
          <BusinessForm isLoading={loading} submit={handleCreation} />
        </Card>
      </Page>
    </Fragment>
  );
};

export default CreateBusiness;
