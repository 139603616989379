import moment from "moment";
import Button from "../../../components/bootstrap/Button";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { DocumentService } from "../../../services/documents/documentService";
import { toast } from "react-toastify";
import { ChangeEvent, FC, useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import UploadDocument from "../../users/user-profile/UploadDocument";
import { OperationService } from "../../../services/operations/operationService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

interface TabDocumentOperationItemProps {
  id: string;
  documentType: any;
}

const TabDocumentOperationItem: FC<TabDocumentOperationItemProps> = ({ id, documentType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { userCan } = usePrivilege()

  const loggedUserID =  useSelector((state: RootState) => state.auth.user?.id);

  const getDocuments = useCallback(async () => {
    const operationService = new OperationService();
    const response = await (
      await operationService.getDocumentsByDocumentType(id, documentType.id)
    ).getResponseData();
    return response;
  }, [id, documentType]);

  const [data, loading, error, refetch] = useFetch(getDocuments);

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    document: any
  ) => {
    const { checked } = event.target;
    let selectedItemsCopy = [...selectedRows];
    if (checked) {
      selectedItemsCopy.push(document.id);
    } else {
      selectedItemsCopy = selectedItemsCopy.filter((item) => item !== document.id);
    }
    setSelectedRows(selectedItemsCopy);
  };

  const _handleSelectAllPrd = (checked: boolean): void => {
    if (checked) {
      const selectedItemsCopy = data.map((item: any) => item.id);
      setSelectedRows(selectedItemsCopy);
    } else {
      setSelectedRows([]);
    }
  };

  const downloadDocument = async (name: string, id: string) => {
    try {
      let response = (
        await new DocumentService().renderDocument(id)
      ).getResponse();
      if (response && response.status === 200 && response.data) {
        let blob = new Blob([response.data], { type: response.data.type });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDocument = async (documentId: string) => {
    handleConfirmationAlert({
      title: "¿Estás seguro de eliminar este documento?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      onConfirm: async () => {
        let response = (
          await new DocumentService().deleteDocument(documentId)
        ).getResponse();
        if (response && response.status === 200 && response.data.success) {
          refetch();
          toast.success("Documento eliminado con éxito");
        } else {
          toast.error("Ha ocurrido un error al eliminar el documento");
        }
      },
    });
  };

  const deleteDocuments = async () => {
    handleConfirmationAlert({
      title: "¿Estás seguro de eliminar estos documentos?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      onConfirm: async () => {
        let response = (
          await new DocumentService().deleteDocuments(selectedRows)
        ).getResponse();
        if (response && response.status === 200 && response.data.success) {
          refetch();
          toast.success("Documentos eliminados con éxito");
          setSelectedRows([]);
        } else {
          toast.error("Ha ocurrido un error al eliminar los documentos");
        }
      },
    });
  };

  return (
    <>
      {selectedRows.length > 0 && userCan('delete_documents', 'operations') && (
        <div className="d-flex justify-content-end mt-4">
          <Button color="danger" icon="Delete" isLight onClick={deleteDocuments}>
            Eliminar Seleccionados
          </Button>
        </div>
      )}
      {
        data ? (
          <CustomTable
            data={data}
            selectableItems={true}
            onChangeCheckedItems={(
              id: string,
              event: ChangeEvent<HTMLInputElement>,
              item: any
            ) => handleCheckboxChange(event, id, item)}
            onSelectAllItems={_handleSelectAllPrd}
            className={"table table-hover max-h-250"}
            columns={[
              {
                name: "Nombre",
                keyValue: "originalName",
                render: (element: any) => {
                  return <div>{element.originalName}</div>;
                },
              },
              {
                name: "Usuario",
                keyValue: "user",
                render: (element: any) => {
                  return <div>{element.userName}</div>;
                },
              },
              {
                name: "Fecha de Creación",
                keyValue: "createdAt",
                className: "text-center",
                cellClassName: "text-center",
                render: (element: any) => {
                  return (
                    <div className="d-flex justify-content-center text-center">
                      {moment(element?.createdAt?.date).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </div>
                  );
                },
              },
              {
                name: "Acciones",
                className: "min-w-100px text-end",
                isActionCell: true,
              },
            ]}
            actions={[
              {
                title: "Descargar",
                buttonType: "icon",
                iconColor: "text-blue-600",
                iconPath: "/media/icons/duotune/arrows/arr091.svg",
                additionalClasses: "text-success",
                description: "Descargar Documento",
                callback: (item: any) => {
                  downloadDocument(item.originalName, item.id);
                },
              },
              {
                title: "Eliminar",
                buttonType: "icon",
                iconColor: "text-danger",
                iconPath: "/media/icons/duotune/general/gen027.svg",
                additionalClasses: "text-danger",
                description: "Eliminar Documento",
                hide: (element: any) => !userCan('delete_documents', 'operations'),
                callback: (item: any) => {
                  deleteDocument(item.id);
                },
              },
            ]}
          />
        ) : (
          <div className="text-center d-flex justify-content-center">
            <Spinner></Spinner>
          </div>
        )
      }
      {isOpen ? (
        <UploadDocument
          isOpen={isOpen}
          idOperation={id}
          setIsOpen={setIsOpen}
          id={loggedUserID || ""}
          documentType={documentType.id}
          refetch={refetch}
        />
      ) : userCan('add_documents', 'operations') && (
        <div className="d-flex justify-content-center mt-4">
          {" "}
          <Tooltips title={"Subir documento"}>
            <Button
              color="success"
              isLight
              icon="UploadFile"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Subir documentos
            </Button>
          </Tooltips>
        </div>
      )}
    </>
  );
};

export default TabDocumentOperationItem;
