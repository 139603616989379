import { RestServiceConnection } from "../restServiceConnection";

const CNAE_ENDPOINT = '/cnae';

export class CNAEService extends RestServiceConnection {

    public async getGroups() {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/groups',
        }, true);
        return this;
    }

    public async getSubGroups(group: string) {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/subgroups/'+group
        }, true);
        return this;
    }

    public async getCategories(group: string) {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/categories/'+group
        }, true);
        return this;
    }

    public async getActivities(group: string) {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/activities/'+group
        }, true);
        return this;
    }

    public async searchCNAEs(search: string) {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/search/'+search
        }, true);
        return this;
    }
}