import {FC, Fragment, useCallback, useState} from "react";
import Icon from "../../../../components/icon/Icon";
import AddConfigurationModal from "./AddConfigurationModal";
import useFetch from "../../../../hooks/useFetch";
import {ConfiguraciónService} from "../../../../services/configuration/configurationService";
import Button from "../../../../components/bootstrap/Button";
import {handleConfirmationAlert} from "../../../../utils/ConfirmationAlert";
import {toast} from "react-toastify";
import Spinner from "../../../../components/bootstrap/Spinner";
import Badge from "../../../../components/bootstrap/Badge";

interface IConfigurationsListProps {
    walletStatuses: any;
    walletActionStatuses: any;
    operationStatuses: any;
    campaigns: any;
}


const ConfigurationsList: FC<IConfigurationsListProps> = ({
                                                              walletStatuses,
                                                              walletActionStatuses,
                                                              operationStatuses,
                                                              campaigns
                                                          }) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const deleteConfiguration = async (id: string) => {
        const configurationService = new ConfiguraciónService();
        const response = await (await configurationService.deleteConfigurationWallet(id)).getResponseData();
        if (response.success) {
            toast.success('Configuración de Cartera eliminada correctamente');
            refetch();
        } else {
            response.data.errors.forEach((error: any) => {
                toast.error(error.message);
            });
        }
    }

    const fetchConfigutations = useCallback(async () => {
        const configurationService = new ConfiguraciónService();
        const response = await (await configurationService.getWalletConfigurations()).getResponseData();
        return response;
    }, []);

    const [data, loading, error, refetch] = useFetch(fetchConfigutations);

    return (
        <>
            <div className="mt-5 d-flex justify-content-center flex-column">
                {data ?
                    data.length > 0 ?
                        data.map((item: any, index: number) => (
                            <div key={index}
                                 className="d-flex justify-content-around align-items-center mb-3 element-configuration"
                                 style={{
                                     minHeight: '100px',
                                     backgroundColor: item?.actionStatusTrigger?.active ? '' : '#f8f9fa'
                                 }}>
                                <div>
                                    <p className="fw-bold">Estado de la Operación</p>
                                    <Button isLink className='text-wrap fs-8 p-2 statusButtons me-2'
                                            style={{backgroundColor: item?.actionStatusTrigger?.color}}>
                                        <Fragment><span className='text-white fw-bold fs-8 '
                                                        style={{color: '#3e3e3e'}}> {item?.actionStatusTrigger?.name}  </span></Fragment>
                                    </Button>
                                    {!item?.actionStatusTrigger?.active ? 
                                    <Badge color={'danger'}>{'Inactivo'}</Badge>
                                    : ''
                                    }   
                                </div>


                                <div className="d-flex justify-content-around align-items-center flex-column">
                                    <div className="fw-bold text-primary">
                                        Crea/Actualiza la cartera
                                    </div>
                                    {item?.actionStatusResult !== null ? <Icon
                                        icon={item?.actionWalletStatusResult !== null ? 'TurnRight' : "ArrowForward"}
                                        className={`ms-2 me-2 text-primary ${item?.actionWalletStatusResult !== null ? 'mb-4 mt-2' : ''}`}
                                        size={'2x'}/> : ''}
                                    {item?.actionWalletStatusResult !== null && item?.actionStatusResult !== null ?
                                        <Icon icon="SubdirectoryArrowRight" className="ms-2 me-2 text-primary mt-3"
                                              size={'2x'}/> : ''}
                                    {item?.actionStatusResult == null && item?.actionWalletStatusResult !== null ?
                                        <Icon icon="ArrowForward" className="ms-2 me-2 text-primary" size={'2x'}/> : ''}
                                </div>
                                <div className="fw-bold d-flex justify-content-center align-items-around flex-column">
                                    {item?.actionStatusResult !== null ? <div
                                        className={` ${item?.actionWalletStatusResult !== null ? 'mb-5 mt-3 text-center' : ''}`}>Estado
                                        de la Cartera</div> : ''}
                                    {item?.actionWalletStatusResult !== null && item?.actionStatusResult !== null ?
                                        <div className="text-center">Estado de Accion de<br/> la Cartera</div> : ''}
                                    {item?.actionStatusResult == null && item?.actionWalletStatusResult !== null ?
                                        <div className="text-center">Estado de Accion de<br/> la Cartera</div> : ''}
                                </div>
                                <div className="d-flex justify-content-center align-items-around flex-column">
                                    {item?.actionStatusResult !== null ? <Button isLink
                                                                                 className={`text-wrap fs-8 p-2 statusButtons ${item?.actionWalletStatusResult !== null ? 'mb-4 mt-3' : ''}`}
                                                                                 style={{backgroundColor: item?.actionStatusResult?.color}}>
                                        <Fragment><span className='text-white fw-bold fs-8 '
                                                        style={{color: '#3e3e3e'}}> {item?.actionStatusResult?.name}  </span></Fragment>
                                    </Button> : ''}
                                    {item?.actionWalletStatusResult !== null && item?.actionStatusResult !== null ?
                                        <Button isLink className='text-wrap fs-8 p-2 statusButtons mt-3'
                                                style={{backgroundColor: item?.actionWalletStatusResult?.color}}>
                                            <Fragment><span className='text-white fw-bold fs-8 '
                                                            style={{color: '#3e3e3e'}}> {item?.actionWalletStatusResult?.name}  </span></Fragment>
                                        </Button> : ''}
                                    {item?.actionStatusResult == null && item?.actionWalletStatusResult !== null ?
                                        <Button isLink className={`text-wrap fs-8 p-2 statusButtons`}
                                                style={{backgroundColor: item?.actionWalletStatusResult?.color}}>
                                            <Fragment><span className='text-white fw-bold fs-8 '
                                                            style={{color: '#3e3e3e'}}> {item?.actionWalletStatusResult?.name}  </span></Fragment>
                                        </Button> : ''}
                                </div>
                                <div className="d-flex justify-content-center align-items-around flex-column w-10">
                                    {!item?.campaign && (
                                        <div className="fw-bold text-primary">
                                            Sin Departamento
                                        </div>
                                    )}
                                    {item?.campaign && (
                                        <div className="fw-bold text-primary">
                                            {item?.campaign.name}
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="add-button border-danger maxWidth-rounded-config-button "
                                         onClick={() => {
                                             handleConfirmationAlert({
                                                 title: "Eliminar Configuración",
                                                 text: "¿Está seguro que desea eliminar esta configuración?",
                                                 icon: "warning",
                                                 onConfirm: () => {
                                                     deleteConfiguration(item.id);
                                                 },
                                             });
                                         }}>
                                        <Icon icon="Delete" className="m-2 text-danger" size={'2x'}/>
                                    </div>
                                </div>
                            </div>
                        )) : <div className="d-flex justify-content-center fw-bold fs-6 text-dark mb-5">
                            No hay configuraciones de cartera
                        </div>
                    : <Spinner color={'primary'} className="m-auto mb-5"/>}
                <div className="add-button border-primary maxWidth-rounded-config-button m-auto" onClick={handleOpen}>
                    <Icon icon="Add" className="m-2 text-primary" size={'2x'}/>
                </div>
            </div>
            <AddConfigurationModal isOpen={open} setIsOpen={setOpen} refetch={refetch}
                                   walletActionStatuses={walletActionStatuses} walletStatuses={walletStatuses}
                                   campaigns={campaigns}
                                   operationStatuses={operationStatuses}/>
        </>
    );
}

export default ConfigurationsList;