import React, {useEffect} from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import { useFormik } from 'formik';
import Spinner from "../../components/bootstrap/Spinner";

interface LoginFormProps {
    isLoading: boolean;
    submit: Function;
    errorsBool: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({isLoading = false, submit, errorsBool}) => {

    const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'El correo electrónico es requerido';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'La contraseña es requerida';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
            submit(values.loginUsername, values.loginPassword);
		},
	});

    useEffect(() => {

    }, [isLoading]);

    return(
        <form onSubmit={formik.handleSubmit} className='row g-4'>
        <div className='col-12'>
            <FormGroup id='loginUsername' isFloating label='Correo electrónico'>
                <Input
                    autoComplete='username'
                    value={formik.values.loginUsername}
                    isTouched={formik.touched.loginUsername}
                    invalidFeedback={
                        formik.errors.loginUsername
                    }
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => {
                        formik.setErrors({});
                    }}
                />
            </FormGroup>
            <FormGroup id='loginPassword' isFloating label='Contraseña' className='mt-3'>
                <Input
                    type='password'
                    autoComplete='current-password'
                    value={formik.values.loginPassword}
                    isTouched={formik.touched.loginPassword}
                    invalidFeedback={
                        formik.errors.loginPassword
                    }
                    validFeedback='Looks good!'
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </FormGroup>
        </div>
        <div className='col-12'>
            <Button color='warning' className='w-100 py-3' type='submit'  isDisable={isLoading}>
                {isLoading ? <Spinner/> : 'Iniciar Sesión'}
            </Button>
        </div>
    </form>
    ) 
}