import { FC, Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import { ProductApiResponse } from "../../../type/product-type";
import { ProductService } from "../../../services/products/productService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import ProductForm from "../ProductForm";
import useFetch from "../../../hooks/useFetch";

const EditProduct: FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const { idcm, id = '' } = useParams<{ idcm: string, id: string }>();

    const fetchProduct = useCallback(async () => {
        const response = await new ProductService().getProductById(id);
        return response.getResponseData() as ProductApiResponse;
    }, [id]);

    const [product, error] = useFetch(fetchProduct);

    const handleEdit = async (values: any) => {
        setLoading(true);

        normalizeData(values);
        try {
            setLoading(true)
            let response = await (await (new ProductService()).editProduct(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                if (idcm) {
                    navigate(-1)
                } else {
                    navigate(-1)
                }
            } else {
                toast.error(response.message);
            }
        } catch (error: any) {
            toast.error('Error al editar Producto');
        } finally {
            setLoading(false);
        }

    }

    const normalizeData = (data: any) => {
        if (data.points === '') {
            data.points = null;
        }
        if (data.base_commission === '') {
            data.base_commission = null;
        }
        if (data.powerFrom === '') {
            data.powerFrom = null;
        }
        if (data.powerTo === '') {
            data.powerTo = null;
        }
        if (data.consumptionFrom === '') {
            data.consumptionFrom = null;
        }
        if (data.consumptionTo === '') {
            data.consumptionTo = null;
        }
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => {
                        if (idcm) {
                            navigate(-1)
                        } else {
                            navigate(-1)
                        }
                    }}>
                    </Button>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                {product ? <ProductForm submit={handleEdit} productData={product} businessId={idcm}></ProductForm> : <></>}
            </Page>
        </Fragment>

    )
}

export default EditProduct;