import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { Fragment, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import Icon from "../../../components/icon/Icon";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/bootstrap/forms/Input";
import { toast } from "react-toastify";
import { ProductService } from "../../../services/products/productService";
import { useLoader } from "../../../components/loader/LoaderProvider";

export const ProductImport = () => {
    const navigate = useNavigate()
    const [importFile, setImportFile] = useState<any>(null)
    const {showLoading, hideLoading} = useLoader()


    const _handleFileUpload = (e: React.ChangeEvent<any>) => {
        const file: File|null = e.target.files[0]
        setImportFile(file)
    }

    const _handleSubmit = async () => {
        if(!importFile) {
            toast.error('Debe seleccionar un archivo para importar')
            return
        }
        const formData = new FormData()
        formData.append('file', importFile)
        showLoading('Importando productos', (<p>Por favor espera...</p>))
        const response = (await (new ProductService()).importProducts(formData)).getResponseData()
        hideLoading()
        if(response.success) {
            toast.success('Productos importados correctamente')
            navigate('/products', {replace: true})
        } else {
            toast.error('Error al importar productos')
        }
        
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <Button color="primary" isLink className="d-inline-block" onClick={() => { navigate('/products') }} icon="ArrowBack" />
                        <SubheaderSeparator />
                        <CardTitle>Importar productos</CardTitle>
                    </Fragment>
                </SubHeaderLeft>
            </SubHeader>

            <Page container="lg">
                <Alert
                    icon='Info'
                    isLight
                    color='info'
                    borderWidth={0}
                    className='shadow-3d-info'
                    isDismissible>
                    <AlertHeading tag='h2' className='h4'>
                        Importante!
                    </AlertHeading>
                    <section className="import-information d-flex flex-column justify-center">
                        <div className="import-information-text">
                            <p className="text-justify">
                                Importar archivo de productos para generar las combinaciones necesarias en el sistema.
                                Hay que tener en cuenta que si alguno de los componentes no existe en el sistema se creará dándole prioridad a la información contenida en el excel.
                                Es muy importante que los archivos tengan siempre el mismo formato por lo que se adjunta un archivo de plantilla con algunos ejemplos de importación incluidos a continuación.
                            </p>
                        </div>
                        <div className="import-link text-center">
                            <a color="primary" className="d-inline-block btn btn-sm btn-primary " href="/excel/templates/productsImport.xls" download>Descargar plantilla</a>
                        </div>
                    </section>
                </Alert>
                <Card stretch={false}>
                    <CardHeader>
                        <CardTitle><Icon icon="CloudUpload" size={'2x'} color="primary" /> &nbsp; Importar productos</CardTitle>
                    </CardHeader>
                    <CardBody className="justify-content-center">
                        <div className='d-flex w-50 m-auto'>
                            <Input type='file' onChange={(e: React.ChangeEvent<any>) => _handleFileUpload(e)}
                                autoComplete='photo' />
                        </div>
                    </CardBody>
                    <CardFooter className="justify-content-end" >
                        <Button color="primary" icon="Save" size="lg" onClick={_handleSubmit}>Importar</Button>
                    </CardFooter>
                </Card>
            </Page>
        </Fragment>
    )
}