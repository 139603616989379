import { FiltersProvider } from "../../components/providers/FiltersProvider";
import CampaignsList from "./campaign-list/CampaignList";

const CampaginListWrapper = () => {
  
    return (
      <>
        <FiltersProvider>
          <CampaignsList />
        </FiltersProvider>
      </>
    );
  };

export default CampaginListWrapper;