import { FC, Fragment, useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
    CardBody,
    CardFooter,
    CardLabel,
    CardTabItem,
    CardTitle,
} from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Page from "../../../layout/Page/Page";
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
} from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { OperationApiResponse } from "../../../type/operation-type";
import Badge from "../../../components/bootstrap/Badge";
import { ClientService } from "../../../services/clients/clientService";
import OperationTableComponent from "../../../components/table/Operation/OperationTableComponent";
import useFilters from "../../../hooks/useFilters";
import { OperationService } from "../../../services/operations/operationService";
import moment from "moment";
import { toast } from "react-toastify";
import ClientsCommentHistory from "../components/ClientsCommentHistory";
import { ClickToCall } from "../../../components/calls/ClickToCall";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ActiveTab, setActiveTab } from "../../../redux/activeTabSlice";
import ClientCupsCard from "../components/ClientCupsCard";
import CreateOperationModal from "../../../components/operation/CreateOperationModal";
import EditOperationModal from "../../../components/operation/EditOperationModal";
import { getAge } from "../../../utils/date/DateUtils";
import ClientsRelatedCard from "../components/ClientsRelatedCard";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import ClientCallWrapper from "../components/ClientCallWrapper";

const ACTIVE_TAB_SECTION = 'client_view';


const ClientView: FC = () => {
    const { id = "", tabURL = "" } = useParams<{ id: string, tabURL: string }>();
    const activeTab = useSelector((state: RootState) => state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION));

    const dispatch = useDispatch();

    const { userCan } = useContext(PrivilegeContext);

    const {
        filters,
        updateFilters,
        updateFilterOrder,
        updatePage,
        updatePageSize,
    } = useFilters({ client: id }, [], 50);

    const [dataCli, setDataCli] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [updateComments, setUpdateComments] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("");
    const [showCreateOperationModal, setShowCreateOperationModal] = useState<boolean>(false);
    const [showEditOperationModal, setShowEditOperationModal] = useState<boolean>(false);
    const [operationSelected, setOperationSelected] = useState<string|null>();

    const _onClose = (message?: string) => {
        if (message) {
            toast.success(message);
        }
        setIsOpen(false);
        setMode("");
        setUpdateComments(!updateComments);
    };

    const fetchClient = useCallback(async () => {
        const clientService = new ClientService();
        const response = await clientService.getClientById(id as string);
        setDataCli(response.getResponseData());
        return response.getResponseData() as OperationApiResponse;
    }, [id, updateComments]);

    const [data, loading, error, refetchClient] = useFetch(fetchClient);

    const _handleOnOperationCreate = (operation: any) => {
        toast.success("Contrato creado correctamente");
        refetchClient();
        setShowCreateOperationModal(false);
        setTimeout(() => {
            navigate(`/operations/${operation.id}/show`);
        }, 1000);
    }

    const _handleOnOperationEdit = (operation: any) => {
        toast.success("Contrato editado correctamente");
        refetchClient();
    }

    const _handleOnEditModalVisilble = (visibility: boolean) => {
        if (visibility === false) {
            setOperationSelected(null);
        }
        setShowEditOperationModal(visibility);
    }


    const fetchOperations = useCallback(async () => {
        const operationService = new OperationService();
        const response = await operationService.getOperations(filters);
        return response.getResponseData() as OperationApiResponse;
    }, [filters, data]);



    const [Operationdata, Operationloading, Operationerror] =
        useFetch(fetchOperations);

    const navigate = useNavigate();

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <Button
                            color="primary"
                            isLink
                            icon="ArrowBack"
                            onClick={() => navigate(-1)}
                        ></Button>
                        <CardLabel icon="Search" iconColor="info">
                            <CardTitle>Ver Cliente: {dataCli?.data?.name}</CardTitle>
                        </CardLabel>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="primary"
                        isLight
                        icon="Edit"
                        onClick={() => navigate(`/clients/edit/${data.id}`)}
                    >
                        Editar Cliente
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">{
                data ? (
                    <div className="row">
                <Card className="col-md-12">
                    <CardBody>
                        <div>
                            <div className="row">
                                {/* DATA COLUMN */}
                                <div className="col-md-9">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <FormGroup label="NOMBRE:">
                                                    <p> {data.name || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-2">
                                                <FormGroup label="NIF:">
                                                    <p> {data.nif || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-2">
                                                <FormGroup label="Edad:">
                                                {data.birthDate?.date ? (
                                                    <>
                                                        <p> 
                                                            {getAge(data.birthDate?.date.slice(0,10)) + " años " }
                                                            {"(" + moment(data.birthDate?.date).format("DD-MM-YYYY") + ")"}
                                                        </p>
                                                    </>
                                                ) : ( <p> - </p>)}
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-2">
                                                <FormGroup label="TELÉFONOS:">
                                                    <p>  {data.phone1 ? (<ClickToCall telephone={data.phone1} />) : ''}</p>
                                                    <p>  {data.phone2 ? (<ClickToCall telephone={data.phone2} />) : ''}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-2">
                                                <FormGroup label="EMAIL:">
                                                    <p> {data.email || "-"}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-2">
                                                <FormGroup label="IBAN:">
                                                    <p> {data.iban || "-"}</p>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-3">
                                                <FormGroup label="DIRECCIÓN:">
                                                    <p> {data.address || "-"}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="CÓDIGO POSTAL:">
                                                    <p> {data.postalCode || "-"}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="LOCALIDAD:">
                                                    <p> {data.locality || "-"}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="PROVINCIA:">
                                                    <p> {data.province || "-"}</p>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {data.clientType !== "PARTICULAR" && (
                                            <>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <FormGroup label="REPRESENTANTE:">
                                                            <p> {data.representativeName || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <FormGroup label="DNI REPRESENTANTE:">
                                                            <p> {data.representativeNIF || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Comments Column */}
                                <div className="col-md-3">
                                    <FormGroup label="COMENTARIOS GENERALES:">
                                        <p> {data.textComments || "-"}</p>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </CardBody>

                    <CardFooter className="align-items-start justify-content-start">
                        <FormGroup label="TIPO DE CLIENTE:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                <Badge color="info" className="h3">{data.clientType || "-"}</Badge>
                            </div>
                        </FormGroup>
                        <FormGroup label="ALTA:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                {data.createdAt ? moment(data.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>
                        <FormGroup label="ACTUALIZADO:" className="col-md-2">
                            <div className="d-inline-block ps-2">
                                {data.updatedAt ? moment(data.updatedAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                            </div>
                        </FormGroup>
                    </CardFooter>
                </Card>

                <Card
                    // @ts-ignore
                    defaultActiveTab={tabURL ? parseInt(tabURL) : activeTab?.activeTab || 0}
                    onActiveTab={(tab: number) => {
                        dispatch(setActiveTab({ section: ACTIVE_TAB_SECTION, activeTab: tab }));
                    }}
                    hasTab
                >
                    <CardTabItem

                        id="supplies"
                        title="Suministros"
                        icon="House"

                    >
                        <ClientCupsCard idMain= {data.id} />
                    </CardTabItem>
                    { userCan("list_related_clients", "clients") && (
                        <CardTabItem

                            id="relatedClients"
                            title="Clientes Relacionados"
                            icon="Group"
                        >
                            <ClientsRelatedCard id={data.id} />
                        </CardTabItem>
                    )}
                    <CardTabItem
                        id="operations"
                        title="Contratos"
                        icon="Inventory2"
                    >
                        {
                            Operationdata ? (
                                <>
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <Button
                                                icon="add"
                                                color="primary"
                                                size={'sm'}
                                                isLight
                                                onClick={() => {
                                                    setShowCreateOperationModal(true);
                                                }}
                                            >
                                                Nuevo Contrato
                                            </Button>
                                        </div>
                                    </div>
                                    <OperationTableComponent
                                        data={Operationdata}
                                        error={Operationerror}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        updateFilterOrder={updateFilterOrder}
                                        updatePage={updatePage}
                                        updatePageSize={updatePageSize}
                                        classNameProps="table table-hover p-3 fs-7"
                                        visibleActions={["show", "editTab", "delete"]}
                                        onClickEdit={(operation: any) => {
                                            setOperationSelected(operation.id);
                                            setShowEditOperationModal(true);
                                        }}
                                        visibleColums={["company", "agent", "operationType", "actionStatus", "status", "operationIdentifier", "cups", "clientName", "fav", "clientDni", "createdAt", "product"]}
                                    />
                                </>
                            ) : (
                                <div className="text-center d-flex justify-content-center">
                                    <Spinner/>
                                </div>
                            )
                        }
                    </CardTabItem>

                    {/* Comments */}
                    <CardTabItem
                        id="comments"
                        title="Comentarios"
                        icon="Chat"
                    >
                        <ClientsCommentHistory id={data.id} />
                    </CardTabItem>

                    {/* Calls */}
                    <CardTabItem
                        id="calls"
                        title="Llamadas"
                        icon="Phone"
                    >
                        <ClientCallWrapper clientId={data.id} />
                    </CardTabItem>
                </Card>
            </div>
            ) : (
            <div className="text-center d-flex justify-content-center">
                <Spinner />
            </div>)
            }
        </Page>
            <CreateOperationModal visible={showCreateOperationModal} setVisible={setShowCreateOperationModal} clientId={id} onOperationCreate={_handleOnOperationCreate}></CreateOperationModal>
            
            <EditOperationModal visible={showEditOperationModal} setVisible={_handleOnEditModalVisilble} operationId={operationSelected || ""} onOperationEdit={_handleOnOperationEdit}/>
            
        </Fragment>
    );
};

export default ClientView;
