import { Fragment } from "react";
import { Outlet } from "react-router-dom";

const ClientsWrapper = () => {

    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    );

}

export default ClientsWrapper;