import { valida_cups } from "../cupsValidator";


export const ValidateCUPS = function (errorMessage: string) {
    // @ts-ignore
    return this.test(`test-telephone-field`, errorMessage, function (value: any) {
        // @ts-ignore
        const { path, createError } = this;
        
        if(!value) {
            return true;
        }

        if (valida_cups(value)) {
            return true;
        }

        return (
            createError({ path, message: errorMessage })
        );

    });
}
