import moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import useDarkMode from "../../../hooks/useDarkMode";
import { UserService } from "../../../services/users/userService";

interface BusinessInfoCardProps {
    createdAt: any;
    userId: string;
}

const BusinessInfoCard: FC<BusinessInfoCardProps> = ({createdAt, userId}) => {

    const [numberOfContracts, setNumberOfContracts] = useState<number>(0);

    useEffect(() => {
        getNumberOfContracts();
    }, [userId]);

    const { darkModeStatus } = useDarkMode();

    const getNumberOfContracts = async () => {
        const userService = new UserService();
        const response = await (await (await userService.getNumberOfContracts(userId)).getResponseData());
        if (response.success) {
            setNumberOfContracts(response.data);
        } else {
            setNumberOfContracts(0);
        }
    }

    const getFormattedDuration = (date: any) => {
        const now = moment(); // Obtiene la fecha actual
        const createdDate = moment(date); // Convierte la fecha de creación a un objeto moment
        const duration = moment.duration(now.diff(createdDate)); // Calcula la duración desde la creación hasta ahora

        // Obtiene la cantidad de años, meses y días
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();

        // Construye el texto condicionalmente
        let formattedDuration = '';
        
        if (years > 0) {
            formattedDuration += `${years} ${years === 1 ? 'Año' : 'Años'}`;
        }
        
        if (months > 0) {
            if (years > 0) {
                formattedDuration += ' y ';
            }
            formattedDuration += `${months} ${months === 1 ? 'Mes' : 'Meses'}`;
        } else if (years === 0) {
            // Muestra días solo si no hay meses ni años
            formattedDuration = `${days} ${days === 1 ? 'día' : 'días'}`;
        }

        return formattedDuration || '0 días'; // Si no hay años, meses o días, mostrar "0 días"
    };

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <CardLabel icon='Assignment'>
                        <CardTitle>Contrato</CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody>
                    <div className='row g-4 align-items-center'>
                        <div className='col-xl-12'>
                            <div
                                className={`d-flex align-items-center bg-l${darkModeStatus ? 'o25' : '10'
                                    }-warning rounded-2 p-3 flex-row`}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='DoneAll' size='3x' color='warning' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-3 mb-0 text-center'>{numberOfContracts}</div>
                                </div>
                                <div className='text-muted mt-n2 truncate-line-1'>
                                    Contratos
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-12'>
                            <div
                                className={`d-flex align-items-center bg-l${darkModeStatus ? 'o25' : '10'
                                    }-success rounded-2 p-3 flex-row`}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='Timer' size='3x' color='success' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-3 mb-0 text-center'>
                                        {createdAt ? getFormattedDuration(createdAt.date) : 'N/A'}
                                    </div>
                                </div>
                                    <div className='text-muted mt-n2'>Antigüedad</div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default BusinessInfoCard;