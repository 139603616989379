import { FC, useCallback, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardTabItem,
} from "../../../components/bootstrap/Card";
import Tooltips from "../../../components/bootstrap/Tooltips";
import ClientsCommentHistory from "../../clients/components/ClientsCommentHistory";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import OperationTableComponent from "../../../components/table/Operation/OperationTableComponent";
import useFilters from "../../../hooks/useFilters";
import { OperationService } from "../../../services/operations/operationService";
import { OperationApiResponse } from "../../../type/operation-type";
import ClientCommentModal from "../../clients/components/ClientEditCommentModal";

interface ClientAditionalDataProps {
  clientId: string;
}

const ClientAditionalData: FC<ClientAditionalDataProps> = ({ clientId }) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modeCli, setMode] = useState<string>("");

  useEffect(() => {
    updateFilters({ client: clientId });
  }, [clientId]);

  const {
    filters,
    updateFilters,
    updateFilterOrder,
    updatePage,
    updatePageSize,
  } = useFilters({ client: clientId }, [], 50);

  const openModal = () => {
    setIsOpen(true);
    setMode("create");
  };

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    setIsOpen(false);
    setMode("");
  };

  const fetchOperations = useCallback(async () => {
    const operationService = new OperationService();
    const response = await operationService.getOperations(filters);
    return response.getResponseData() as OperationApiResponse;
  }, [filters]);

  const [Operationdata, Operationloading, Operationerror] =
    useFetch(fetchOperations);

  return (
    <>
      <h3 className="mt-4 text-center fw-bold text-muted mb-2">Datos adicionales del cliente</h3>
      <Card hasTab shadow={'none'} >
        <CardTabItem id="comments" title="Comentarios" icon="Comment">
          <CardBody>
            <ClientsCommentHistory
              id={clientId}
              minheight={"max-h-600"}
            />
            <Tooltips title="Añadir Comentario">
              <div className="d-flex justify-content-center">
                <Button
                  color="info"
                  isLight
                  className="mt-2 text-center"
                  isLink
                  icon="AddComment"
                  onClick={() => openModal()}
                >
                  Añadir Comentario
                </Button>
              </div>
            </Tooltips>
          </CardBody>
        </CardTabItem>
        <CardTabItem id="operations" title="Contratos" icon="Inventory2">
          <CardBody>
            <OperationTableComponent
              data={Operationdata}
              error={Operationerror}
              filters={filters}
              updateFilters={updateFilters}
              updateFilterOrder={updateFilterOrder}
              updatePage={updatePage}
              updatePageSize={updatePageSize}
              classNameProps="table table-hover p-3 fs-7 max-h-500"
              visibleActions={["show", "editTab", "delete"]}
              visibleColums={[
                "company",
                "agent",
                "operationType",
                "lead",
                "office",
                "actionStatus",
                "status",
                "operationIdentifier",
                "cups",
                "clientName",
                "clientDni",
                "locality",
                "province",
                "clientPhone",
                "createdAt",
                "updatedAt",
              ]}
            />
          </CardBody>
        </CardTabItem>
      </Card>
      <ClientCommentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        client={clientId}
        onClose={_onClose}
        mode={modeCli}
      />
    </>
  );
};

export default ClientAditionalData;
