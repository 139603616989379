import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import{ CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import OperationForm from "../OperationForm";
import { OperationService } from "../../../services/operations/operationService";
import Spinner from "../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { ClientService } from "../../../services/clients/clientService";
import Swal from "sweetalert2";

const OperationCreate: FC = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [createRedirect , setCreateRedirect] = useState<boolean>();
  const [operationtoEdit, setOperationtoEdit] = useState<any>(null);
  const { id = '', tab = '' } = useParams<{ id: string , tab? : string }>();
  const { handleErrors } = useHandleErrors();

  const handleCreation = async (values: any) => {
    setLoading(true);

    let response;

    try {
      setLoading(true);
      values.comments = null;
      let isClientChanged = await (
        await new ClientService().isChanged(values)
      ).getResponseData();

      if (isClientChanged.data == true) {
        Swal.fire({
          title: "¿Estas seguro?",
          text: "Ya existe un cliente con los mismos datos, ¿Desea sobreescribir el cliente ya existente?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, Crear",
        }).then(async (result) => {
          if (result.isConfirmed) {
            values.update_client_data = true;
            response = await (
              await new OperationService().createOperation(values)
            ).getResponseData();
          } else {
            values.update_client_data = false;
            response = await (
              await new OperationService().createOperation(values)
            ).getResponseData();
          }
          if (response.success) {
            toast.success("Contrato Creado correctamente");
            navigate(`/operations/edit/${response.data.id}`);
          } else {
            handleErrors(response);
          }
        });
      } else {
        response = await (
          await new OperationService().createOperation(values)
        ).getResponseData();

        if (response.success) {
          toast.success("Contrato Creado correctamente");
          setCreateRedirect(true);
          navigate(`/operations/edit/${response.data.id}`);
        } else {
          handleErrors(response);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getOperationtoEdit = async () => {
    try {
      let response = await (
        await new OperationService().getOperationById(id ? id : "")
      ).getResponseData();
      if (response.success) {
        setOperationtoEdit(response.data);
      } else {
        setError(response.message);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleEdit = async (values: any, maintainSamePage?: boolean) => {
    setLoading(true);
    values.operation = id;

    let response;

    try {
      setLoading(true);
      values.comments = null;

      let isClientChanged = await (
        await new ClientService().isChanged(values)
      ).getResponseData();

      if (isClientChanged.data.hasChanged == true) {
        Swal.fire({
          title: "¿Estas seguro?",
          html: `Ya existe un cliente con los mismos datos. ¿Deseas sobreescribir el cliente ya existente?<br><br><strong>Campos con cambios:</strong><br>${isClientChanged.data.changedFields.map((change : any) => {
            return `${change.fieldName}: ${change.oldValue} → ${change.newValue}`;
          }).join("<br>")}`,
          showCancelButton: true,
          confirmButtonColor: "#5D8540",
          cancelButtonColor: "#e5133d",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, Actualizar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            values.update_client_data = true;
            response = await (
              await new OperationService().editOperation(values)
            ).getResponseData();
          } else {
            values.update_client_data = false;
            response = await (
              await new OperationService().editOperation(values)
            ).getResponseData();
          }
          if (response.success) {
            toast.success("Contrato editado correctamente");
            if (!maintainSamePage) {
              if (tab != "") {
                navigate(-1);
              } else {
                  navigate("/operations", { replace: true });
              }
            }
          } else {
            handleErrors(response);
          }
        });
      } else {
        response = await (
          await new OperationService().editOperation(values)
        ).getResponseData();

        if (response.success) {
          toast.success("Contrato editado correctamente");
          console.log(maintainSamePage);
          if (!maintainSamePage) {
            if (tab != "") {
              navigate(-1);
            } else {
                navigate("/operations", { replace: true });
            }
          }
        } else {
          setError(response.message);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getOperationtoEdit();
    }
  }, [id]);

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => {
              if (createRedirect) {
                navigate("/operations", { replace: true });
              } else {
                  navigate(-1)
              }
          }}
          ></Button>
          <CardLabel icon="Inventory2" iconColor="info">
            <CardTitle>{id ? "Editar Contrato" : "Nuevo Contrato"}</CardTitle>
          </CardLabel>
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        {id ? (
          operationtoEdit != undefined ? (
            <OperationForm
              submit={handleEdit}
              isLoading={loading}
              OperationData={operationtoEdit}
              idOpe={id ?? ""}
            />
          ) : (
            <Spinner />
          )
        ) : (
          <OperationForm
            submit={handleCreation}
            isLoading={loading}
            idOpe={id ?? ""}
          />
        )}
      </Page>
    </Fragment>
  );
};

export default OperationCreate;
