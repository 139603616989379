import { FC } from "react";
import { SMSMatrixList } from "../../../../components/sms_matrix/SMSMatrixList";

interface ServiceWrapperProps {
    businessId: string;
}

const SMSMatrixWrapper: FC<ServiceWrapperProps> = ({ businessId }) => {

    return (
        <>
            <SMSMatrixList businessId={businessId} />
        </>
    );

}

export default SMSMatrixWrapper;