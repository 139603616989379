import { FC, useCallback, useState } from "react"
import { OperationService } from "../../../../services/operations/operationService"
import useFetch from "../../../../hooks/useFetch"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card"
import { CustomTable } from "../../../../components/table/CustomTable"
import Tooltips from "../../../../components/bootstrap/Tooltips"
import Icon from "../../../../components/icon/Icon"
import Button from "../../../../components/bootstrap/Button"
import Spinner from "../../../../components/bootstrap/Spinner"
import moment from "moment"
import { toast } from "react-toastify"
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert"
import { DocumentService } from "../../../../services/documents/documentService"
import useHandleErrors from "../../../../hooks/useHandleErrors"
import OperationRecordModal from "./OperationRecordModal"
import { set } from "js-cookie"
import AudioRecorder from "../../../../components/recorder/AudioRecorder"
import { CallService } from "../../../../services/calls/servicesService"

type OperationRecordsComponentProps = {
    operationId: string
    noCard?: boolean
    maxheight?: string
}


const OperationRecordsComponent: FC<OperationRecordsComponentProps> = ({ operationId , noCard , maxheight }) => {

    const defaultAddText = 'Agregar grabacion desde la lamada realizada recientemente.'
    const [addText, setAddText] = useState<string>(defaultAddText)
    const [addLoading, setAddLoading] = useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = useState<string | null>(null)

    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [searchingCallRecord, setSearchingCallRecord] = useState<boolean>(false)

    const { handleErrors } = useHandleErrors();

    const fetchRecords = useCallback(async () => {
        const response = await (await (new OperationService()).getOperationRecords(operationId)).getResponseData()
        return response;
    }, [])

    const [recordsData, loading, error, refetch] = useFetch(fetchRecords)

    const _getCurrentCallRecord = async () => {

        setAddLoading(true)
        setAddText("Cargando grabación de la llamada actual")

        try {
            const response = await (await (new CallService()).addFromCurrentCallRecord(operationId)).getResponseData()
            
            if (response.success) {
                setAddText("Grabacion agregada")
                refetch()
                toast.success("Grabacion agregada correctamente");
            } else {
                handleErrors(response);
            }
    
            setAddLoading(false)
            setAddText(defaultAddText)
        } catch (error) {
            setAddLoading(false)
            setAddText(defaultAddText)
        }
        

    }

    const _deleteRecord = async (recordId: string) => {

        const response = await (await (new OperationService()).deleteOperationRecord(recordId)).getResponseData()
        if (response.success) {
            toast.success("Grabacion eliminada correctamente")
            refetch()
        } else {
            handleErrors(response);
        }
    }

    const _duration =(duration: number) =>  {
        let milliseconds = Math.floor((duration % 1000) / 100),
          seconds: any = Math.floor((duration / 1000) % 60),
          minutes: any = Math.floor((duration / (1000 * 60)) % 60),
          hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
      
        return hours + ":" + minutes + ":" + seconds ;
      }

    const _downloadRecordFile = async (recordId: string) => {
        setSearchingCallRecord(true);

        const response = await (await (new OperationService()).downloadOperationRecord(recordId)).getResponseData()
      
        if(!response) {
            toast.warning("No se ha encontrado una grabación con el identificador de llamada actual");
            setSearchingCallRecord(false);
            return;
        }

        if (response.success) {
            toast.success("Grabacion descargada correctamente")
            refetch()
        } else {
            handleErrors(response);
        }
    }

    const _donwloadFile = async (documentId: string) => {
        const response = (await (new DocumentService()).downloadDocument(documentId)).getResponse();

        if (response?.status !== 200) {
            toast.error("Error al descargar el archivo");
            return;
        }

        const href = window.URL.createObjectURL(response?.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', documentId + '.mp3');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    const _handleUploadCallrecord = async (recordId: string) => {
        setShowCreateModal(true);
    }

    const _handleEdit = async (recordId: string) => {
        setSelectedRecord(recordId);
        setShowEditModal(true);

    }

    const _handleCreate = async (values: any) => {

        const response = await (await (new OperationService()).addOperationRecord(operationId, values)).getResponseData()
        if (response.success) {
            toast.success("Grabacion agregada correctamente")
        } else {
            handleErrors(response);
        }

        setShowCreateModal(false);
        refetch()
    }

    const _handleUpdate = async (values: any) => {

        if (selectedRecord === null) return;

        const response = await (await (new OperationService()).updateOperationRecord(selectedRecord, values)).getResponseData()
        if (response.success) {
            toast.success("Grabacion actualizada correctamente")
        } else {
            handleErrors(response);
        }

        setSelectedRecord(null);
        setShowEditModal(false);
        refetch()
    }

    return (
        <>
            {searchingCallRecord ? <Spinner/> : null}
            <Card className={`${noCard ? "shadow-none" : ""}`}>
                <CardHeader>
                    <CardLabel icon='Circle' iconColor='info'>
                        <CardTitle> Grabaciones de llamada</CardTitle>
                    </CardLabel>
                    <CardActions>
                        {/** <AudioRecorder /> */}
                        <Tooltips title={addText}>
                            {
                                !addLoading ?
                                    <Button icon='Call' color='primary' isLight onClick={_getCurrentCallRecord} />
                                    : <Spinner />
                            }
                        </Tooltips>

                        <Tooltips title={'Subir archivo de grabación manualmente'}>
                            {
                                !addLoading ?
                                    <Button icon='Upload' color='info' isLight onClick={_handleUploadCallrecord} />
                                    : <Spinner />
                            }
                        </Tooltips>
                    </CardActions>
                </CardHeader>
                <CardBody className={maxheight ? maxheight : ""}>
                    <CustomTable
                        className={"table table-hover"}
                        data={recordsData?.records || []}
                        columns={[
                            {
                                name: "Identificador",
                                keyValue: "callIdentifier",
                                className: "text-center",
                                render: (element: any) => {
                                    return (
                                        <div className="d-flex fs-7 justify-content-center">
                                            {element.callIdentifier || "-"}
                                        </div>
                                    )
                                },
                            },
                            {
                                name: "Nombre",
                                keyValue: "name",
                                className: "text-center",
                                render: (element: any) => {
                                    return (
                                        <div className="d-flex fs-7 justify-content-center text-center">
                                            {element.name}
                                        </div>
                                    )
                                },
                            },
                            {
                                name: "Duración",
                                keyValue: "name",
                                className: "text-center",
                                render: (element: any) => {
                                    return (
                                        <div className="d-flex fs-7 justify-content-center text-center">
                                            {element.duration ? _duration(element.duration) : '-'}
                                        </div>
                                    )
                                },
                            },
                            {
                                name: "Estado",
                                keyValue: "status",
                                className: "text-center",
                                render: (element: any) => {
                                    return (

                                        element.status === 2 ?
                                            <div className="d-flex justify-content-center fw-semibold">
                                                <Tooltips title="Descargado y vinculado al contrato">
                                                    <Icon icon='Check' color='success' size={'lg'} />
                                                </Tooltips>
                                            </div>
                                            : <div className="d-flex justify-content-center fw-semibold">
                                                <Tooltips title="Se ha detectado la llamada pero aún no se ha obtenido el archivo. puedes obtenerlo después de que finalice la llamada.">
                                                    <Icon icon='Close' color='danger' size={'lg'} />
                                                </Tooltips>
                                            </div>
                                    )
                                },
                            },
                            {
                                name: "Fecha de grabación",
                                keyValue: "recordDate",
                                className: "text-center",
                                render: (element: any) => {
                                    return <div className="fs-7 text-center">{element.recordDate ? moment(element.recordDate.date).format('DD/MM/YYYY HH:mm') : ''}</div>;
                                },
                            },
                            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                        ]}
                        actions={[
                            {
                                title: "Descargar",
                                buttonType: "icon",
                                iconPath: "/media/icons/duotune/general/gen021.svg",
                                iconColor: "text-blue-600",
                                additionalClasses: 'text-primary',
                                description: "Buscar y descargar la grabación de esta llamada",
                                callback: (item: any) => {
                                    _downloadRecordFile(item.id);
                                },
                                hide: (item: any) => {
                                    // Hide action if record is already downloaded
                                    return item.status === 2 || searchingCallRecord
                                }
                            },
                            {
                                title: "Descargar archivo",
                                buttonType: "icon",
                                iconPath: "/media/icons/duotune/arrows/arr091.svg",
                                additionalClasses: 'text-primary',
                                iconColor: "text-success",
                                description: "Descargar archivo de audio",
                                callback: (item: any) => {
                                    console.log(item)
                                    if (!item.document) return;
                                    _donwloadFile(item.document.id);
                                },
                                hide: (item: any) => {
                                    // Hide action if record is already downloaded
                                    return item.status === 1
                                }
                            },
                            {
                                title: "Editar",
                                buttonType: "icon",
                                iconColor: "text-info",
                                iconPath: "/media/icons/duotune/general/gen055.svg",
                                additionalClasses: 'text-primary',
                                description: "Editar la grabación",
                                callback: (item: any) => {
                                    _handleEdit(item.id);
                                },
                            },
                            {
                                title: "Eliminar",
                                buttonType: "icon",
                                iconColor: "text-danger",
                                iconPath: "/media/icons/duotune/general/gen027.svg",
                                additionalClasses: 'text-danger',
                                description: "Eliminar grabación de llamada",
                                callback: (item: any) => {
                                    handleConfirmationAlert({
                                        title: "Eliminar Grabación",
                                        text: "¿Está seguro que desea eliminar la grabación?",
                                        icon: "warning",
                                        onConfirm: () => {
                                            _deleteRecord(item.id);
                                        }
                                    });
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            {showCreateModal && <OperationRecordModal mode="edit" recordId={selectedRecord} opened={showCreateModal} onUpdateOpen={setShowCreateModal} onSubmit={_handleCreate} />}
            {selectedRecord && <OperationRecordModal mode="edit" recordId={selectedRecord} opened={showEditModal} onUpdateOpen={setShowEditModal} onSubmit={_handleUpdate} />}
        </>

    )
}

export default OperationRecordsComponent