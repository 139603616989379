import { Fragment } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import ProductTypeList from "./product-list/ProductTypeList"

const ProductTypeListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <ProductTypeList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default ProductTypeListWrapper