import Card, { CardBody } from "../../../components/bootstrap/Card";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Icon from "../../../components/icon/Icon";
import { useEffect, useState } from "react";

const WalletConfigPage = () => {

  const [path, setPath] = useState("status");

  return (
    <div className="container-fluid d-flex p-4">
    {/* Navigation column */}
        <div className="d-flex flex-column gap-7 gap-lg-10 w-150 mb-7 me-lg-10">
            <Card className="mt-5 border border-2 border-primary">
                <CardBody>
                    <label className="fs-5 text-center w-100 fw-semibold text-dark">
                    <Icon icon="Settings" className="fs-3 text-dark me-2" />
                        Configuración
                    </label>
                    <hr />
                    <div className="d-flex flex-column gap-3 mt-4">
                        <div className="d-flex flex-column gap-5">
                            <div className="d-flex flex-stack d-flex align-items-center justify-content-between">
                                <NavLink to={`/config-wallet/status`} className="fs-6 text-gray-800 text-hover-primary text-active-primary text-decoration-none fw-bold d-flex align-items-center justify-content-between w-100"
                                onClick={() => setPath('status') }>
                                    <Icon icon="BubbleChart" className="fs-3 text-primary me-2" />
                                    Estados
                                    { path === 'status' ? <Icon icon="Lens" className="fs-5 text-success ms-3" key={path}/> :
                                    <Icon icon="ArrowForward" className="fs-5 text-dark ms-3"/> }
                                </NavLink>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-5">
                            <div className="d-flex flex-stack d-flex align-items-center">
                                <NavLink to={`/config-wallet/aditional-config`} className="fs-6 text-gray-800 text-hover-primary text-active-primary text-decoration-none fw-bold d-flex align-items-center justify-content-between w-100"
                                onClick={() => setPath('aditional-config') }>
                                    <Icon icon="AccessTime" className="fs-3 text-primary me-2" />
                                    Tiempo
                                    { path === 'aditional-config' ? <Icon icon="Lens" className="fs-5 text-success ms-3" key={path}/> :
                                    <Icon icon="ArrowForward" className="fs-5 text-dark ms-3"/> }
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        {/* Content column */}
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10 w-webkit-100">
            <Outlet />
        </div>
    </div>
  );
};

export default WalletConfigPage;