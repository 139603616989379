import { FC } from "react";
import ServiceList from "./ServiceList";

interface ServiceWrapperProps {
    businessId: string;
}

const ServiceWrapper: FC<ServiceWrapperProps> = ({businessId}) => {

    return <ServiceList businessId={businessId} />;

}

export default ServiceWrapper;