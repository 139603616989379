import { AxiosResponse } from "axios";
import { NewOffice, Office } from "../../type/office-type";
import { RestServiceConnection } from '../restServiceConnection';

const OFFICES_ENDPOINT = '/offices';

export class OfficeService extends RestServiceConnection {
    
        createOffice = async (office: NewOffice) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/create',
                data: office
            }, true);
            return this;
        }
    
        editOffice = async (office: Office) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/edit',
                data: office
            }, true);
            return this;
        }
    
        getOffices = async (filters?: any) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/list',
                data: filters,
                }, true) as AxiosResponse;
            return this;
        }
    
        getOfficeById = async (id: string) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/get',
                data: {
                    office: id
                },
            }, true);
            return this;
        }
    
        deleteOffice = async (id: string) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/delete',
                data: {
                    office: id
                },
            }, true);
            return this;
        }

        toggleStatus = async (id: string, status: boolean) => {
            this.response = await this.makeRequest({
                method: 'POST',
                url: OFFICES_ENDPOINT + '/toggle',
                data: {
                    office: id,
                    active: status
                }, 
            }, true) as AxiosResponse;
            return this;
        }
    }
