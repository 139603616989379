import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { Product, ProductApiResponse } from "../../../type/product-type";
import { BusinessService } from "../../../services/business/businessService";
import { CustomTable } from "../../../components/table/CustomTable";
import { ProductService } from "../../../services/products/productService";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { CheckCircleOutline, HighlightOff, Warning } from "../../../components/icon/material-icons";
import Wizard, { WizardItem } from "../../../components/Wizard";
import Select from "../../../components/bootstrap/forms/Select";
import RadioButton from "../../../components/RadioButtons";
import useFilters from "../../../hooks/useFilters";
import Card, { CardBody, CardTabItem } from "../../../components/bootstrap/Card";
import * as yup from 'yup';
import Input from "../../../components/bootstrap/forms/Input";
import { valida_cups } from "../../../utils/cupsValidator";
import Button from "../../../components/bootstrap/Button";
import Icon from "../../../components/icon/Icon";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { Filters } from "../../../type/apiResponse-type";
import ProductTypeSelector from "../../../components/selectors/ProductTypeSelector";
import { SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import ServiceTableWizard from "../components/ServiceTableWizard";
import { warn } from "console";

export interface IProductsForm {
    productSelected: Product | null;
    servicesSelected: any[];
    businessId: string;
    businessEnabled: boolean;
    individualEnabled: boolean;
    address: string;
    postalCode: string;
    town: string;
    province: string;
    cups?: string | null;
    cupsGas?: string | null;
    power: number | string | null;
    consumptionfilt: number | string | null;
    productType: string | null;
    consumption: number | string | null;
    powerP1: number | string | null;
    powerP2: number | string | null;
    powerP3: number | string | null;
    powerP4: number | string | null;
    powerP5: number | string | null;
    powerP6: number | string | null;

}

const ProductsInitialValues: IProductsForm = {
    productSelected: null,
    servicesSelected: [],
    businessId: "",
    businessEnabled: false,
    individualEnabled: true,
    address: "",
    postalCode: "",
    town: "",
    province: "",
    cups: null,
    cupsGas: null,
    power: null,
    consumptionfilt: null,
    productType: "",
    consumption: null,
    powerP1: null,
    powerP2: null,
    powerP3: null,
    powerP4: null,
    powerP5: null,
    powerP6: null,
}

const ProductsValidationSchema = yup.object().shape({
    businessId: yup.string().required("Debe seleccionar una comercializadora"),
    address: yup.string().required("Debe introducir una dirección"),
    postalCode: yup.string().required("Debe introducir un código postal"),
    town: yup.string().required("Debe introducir una población"),
    province: yup.string().required("Debe introducir una provincia"),
    productType: yup.string().required("Debe introducir un tipo de producto"),
    consumption: yup.number().nullable().typeError("El consumo solo debe contener números"),
    powerP1: yup.number().nullable().typeError("La potencia solo debe contener números"),
    powerP2: yup.number().nullable().typeError("La potencia solo debe contener números"),
    powerP3: yup.number().nullable().typeError("La potencia solo debe contener números"),
    powerP4: yup.number().nullable().typeError("La potencia solo debe contener números"),
    powerP5: yup.number().nullable().typeError("La potencia solo debe contener números"),
    powerP6: yup.number().nullable().typeError("La potencia solo debe contener números"),
    cups: yup.string().test({
        name: 'cups',
        test: function (value) {
            const cupsGas = this.parent.cupsGas;
            const isCupsGasEmpty = !cupsGas || cupsGas.trim() === '';
            const isCupsEmpty = !value || value.trim() === '';
            const isValidCups = valida_cups(value ?? '');

            if (!isCupsEmpty && isValidCups) {
                if (isCupsGasEmpty || (valida_cups(cupsGas ?? '') && !isCupsGasEmpty)) {
                    return true;
                }
            }

            if (!isCupsGasEmpty && !valida_cups(cupsGas ?? '')) {
                return true;
            }

            if (isCupsEmpty && !isCupsGasEmpty) {
                return true;
            }

            return false;
        },
        message: 'Debe introducir un CUPS válido o dejarlo en blanco',
    }).nullable(),

    cupsGas: yup.string().test({
        name: 'cupsGas',
        test: function (value) {
            const cups = this.parent.cups;
            const isCupsEmpty = !cups || cups.trim() === '';
            const isCupsGasEmpty = !value || value.trim() === '';
            const isValidCupsGas = valida_cups(value ?? '');

            if (!isCupsGasEmpty && isValidCupsGas) {
                if (isCupsEmpty || (valida_cups(cups ?? '') && !isCupsEmpty)) {
                    return true;
                }
            }

            if (!isCupsEmpty && !valida_cups(cups ?? '')) {
                return true;
            }

            if (isCupsGasEmpty && !isCupsEmpty) {
                return true;
            }

            return false;
        },
        message: 'Debe introducir un CUPS GAS válido o dejarlo en blanco',
    }).nullable(),
    power: yup
        .number()
        .min(0, 'Debe ser un número positivo o cero')
        .max(1000, 'Debe ser menor o igual a 1000')
        .nullable(),
    consumptionfilt: yup
        .number()
        .min(0, 'Debe ser un número positivo o cero')
        .nullable(),
});

interface CreateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    operationData?: any;
    mode: string;
    onClose?: (message?: string, product?: any) => void;
}

export interface IOperationForm {
    businessId: string;
    individualEnabled: boolean;
    businessEnabled: boolean;
    address: string;
    postalCode: string;
    town: string;
    province: string;
    cups?: string;
    cupsGas?: string;
    power: number | null;
    consumptionfilt: string | null;
    consumption: number | string | null;
    productType: string | null;
    powerP1: number | string | null;
    powerP2: number | string | null;
    powerP3: number | string | null;
    powerP4: number | string | null;
    powerP5: number | string | null;
    powerP6: number | string | null;
}

export interface IProductsFilters {
}

const WizardProduct: FC<CreateModalProps> = ({ isOpen, setIsOpen, mode, onClose, operationData }) => {

    const [businessList, setBusinessList] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedTarget, setSelectedTarget] = useState("");
    const [selectedServices, setSelectedServices] = useState<any>([]);
    const [services, setServices] = useState<any>([]);

    let defaultFilters: any = {};

    if (operationData.lead !== undefined && operationData.lead !== null) {
        defaultFilters.lead = operationData.lead.id;
    }


    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, [], 50);

    const getBusinessList = async () => {
        filters.limit = 1000;
        const response = await (await (new BusinessService()).getBusinesses(filters)).getResponseData();
        if (response.success) {
            let businessList = response.data.businesses.map((business: any) => {
                return {
                    value: business.id,
                    label: business.name,
                    text: business.name
                }
            })
            setBusinessList(businessList);
        } else {
            return [];
        }
    }

    const fetchProducts = useCallback(async () => {
        const operationService = new ProductService();
        const response = await operationService.getProducts(filters);
        return response.getResponseData() as ProductApiResponse;
    }, [filters]);

    const [data, loading, error, refetch] = useFetch(fetchProducts);

    useEffect(() => {
        getBusinessList();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: ProductsInitialValues,
        validationSchema: ProductsValidationSchema,
        onSubmit: (values) => {
            let selectedServices
            if (formik.values.servicesSelected.length > 0) {
                selectedServices = formik.values.servicesSelected.map((service: any) => {
                    return {
                        id: service.service.id,
                        commission: service.commission,
                        points: service.points,
                        warning: service.warning
                    }
                });
            } else {
                selectedServices = null;
            } 


            if (values.productSelected !== null) {
                const selectedProduct = {
                    product: formik.values.productSelected,
                    address: formik.values.address,
                    town: formik.values.town,
                    province: formik.values.province,
                    postalcode: formik.values.postalCode,
                    cups: formik.values.cups,
                    cups_gas: formik.values.cupsGas,
                    businessId: formik.values.businessId,
                    power: formik.values.power,
                    consumption: formik.values.consumption == "" ? null : formik.values.consumption,
                    powerP1: formik.values.powerP1 == "" ? null : formik.values.powerP1,
                    powerP2: formik.values.powerP2 == "" ? null : formik.values.powerP2,
                    powerP3: formik.values.powerP3 == "" ? null : formik.values.powerP3,
                    powerP4: formik.values.powerP4 == "" ? null : formik.values.powerP4,
                    powerP5: formik.values.powerP5 == "" ? null : formik.values.powerP5,
                    powerP6: formik.values.powerP6 == "" ? null : formik.values.powerP6,
                    services: selectedServices
                };
                setIsOpen(false);
                onClose && onClose("Producto seleccionado correctamente", selectedProduct);
            } else {
                onClose && onClose("Debe seleccionar un producto");
            }
        },
    });

    const getResume = () => {
        if (selectedItem === null) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="fs-7">No hay ningún producto seleccionado</div>
                </div>
            );
        }

        return (
            <Card hasTab>
                <CardTabItem id='product' title={"Producto"} icon='Source'>
                    <CardBody>
                        <div className="row">
                            <FormGroup
                                id="name"
                                label="Nombre del Producto:"
                                className="col-md-6"
                            >
                                <span id='name' className='form-control'>
                                    {selectedItem.name}
                                </span>
                            </FormGroup>
                            <FormGroup
                                id="priority"
                                label="Prioridad del Producto:"
                                className="col-md-6"
                            >
                                <span id='priority' className='form-control'>
                                    {selectedItem.priority}
                                </span>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup
                                id="productType"
                                label="Tipo de Producto:"
                                className="col-md-6"
                            >
                                <span id='productType' className='form-control'>
                                    {selectedItem.productType.name}
                                </span>
                            </FormGroup>
                            <FormGroup
                                id="fee"
                                label="Tarifa del Producto:"
                                className="col-md-6"
                            >
                                <span id='fee' className='form-control'>
                                    {selectedItem.fee.name}
                                </span>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup
                                id="individualEnabled"
                                label="Habilitado Particular:"
                                className="col-md-6"
                            >
                                <span id='indivdialEnabled' className='form-control'>
                                    {selectedItem.individualEnabled ? "Si" : "No"}
                                </span>

                            </FormGroup>
                            <FormGroup
                                id="businessEnabled"
                                label="Habilitado Empresa:"
                                className="col-md-6"
                            >
                                <span id='businessEnabled' className='form-control'>
                                    {selectedItem.businessEnabled ? "Si" : "No"}
                                </span>
                            </FormGroup>
                        </div>
                    </CardBody>
                </CardTabItem>
                <CardTabItem id='adicionalData' title={"Datos Adicionales"} icon='Source'>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-6">
                                <FormGroup id='address' label='Dirección:'>
                                    <span id='address' className='form-control'>
                                        {formik.values.address}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='town' label='Ciudad:'>
                                    <span id='town' className='form-control'>
                                        {formik.values.town}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='province' label='Provincia:'>
                                    <span id='province' className='form-control'>
                                        {formik.values.province}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='postalCode' label='Código Postal:'>
                                    <span id='postalCode' className='form-control'>
                                        {formik.values.postalCode}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='cups' label='Cups:'>
                                    <span id='town' className='form-control'>
                                        {formik.values.cups ?? "No definido"}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='cupsGas' label='Cups Gas:'>
                                    <span id='cupsGas' className='form-control'>
                                        {formik.values.cupsGas ?? "No definido"}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup className="pt-2" id='info-consumption' label='Consumo:'>
                                    <span id='info-labelconsumption' className='form-control'>
                                        {formik.values.consumption ?? "No definido"}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='info-power1' label='Potencia P1:'>
                                    <span id='info-labelpower1' className='form-control'>
                                        {formik.values.powerP1 ?? "No definido"}
                                    </span>
                                </FormGroup>
                                <FormGroup className="pt-2" id='info-power2' label='Potencia P2:'>
                                    <span id='info-labelpower2' className='form-control'>
                                        {formik.values.powerP2 ?? "No definido"}
                                    </span>
                                </FormGroup>
                                <FormGroup className="pt-2" id='info-power3' label='Potencia P3:'>
                                    <span id='info-labelpower3' className='form-control'>
                                        {formik.values.powerP3 ?? "No definido"}
                                    </span>
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="pt-2" id='info-power4' label='Potencia P4:'>
                                    <span id='info-labelpower4' className='form-control'>
                                        {formik.values.powerP4 ?? "No definido"}
                                    </span>
                                </FormGroup>
                                <FormGroup className="pt-2" id='info-power5' label='Potencia P5:'>
                                    <span id='info-labelpower5' className='form-control'>
                                        {formik.values.powerP5 ?? "No definido"}
                                    </span>
                                </FormGroup>
                                <FormGroup className="pt-2" id='info-power6' label='Potencia P6:'>
                                    <span id='info-labelpower6' className='form-control'>
                                        {formik.values.powerP6 ?? "No definido"}
                                    </span>
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </CardTabItem>
                        <CardTabItem id={"services"} title={"Servicios"} icon='Source'>
                            {formik.values.servicesSelected.length > 0 ? formik.values.servicesSelected.map((service: any) => {
                                return (
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <FormGroup id='name' label='Nombre:'>
                                                    <span id='name' className='form-control'>
                                                    {service.service.name}
                                                    </span>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup id='commission' label='Comisión:'>
                                                    <span id='commission' className='form-control'>
                                                        {service.commission}
                                                    </span>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup id='points' label='Puntos:'>
                                                    <span id='points' className='form-control'>
                                                        {service.points}
                                                    </span>
                                                </FormGroup>
                                            </div>
                                        </div>
                            </CardBody>
                                );
                            }) : <div className="d-flex justify-content-center">
                                <div className="fs-7">No hay ningún servicio seleccionado</div>
                                </div>}          
                        </CardTabItem>
            </Card>
        );
    };
    const handleCheckboxChange = (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
        product: Product
    ) => {
        const { checked } = event.target;
        if (checked) {
            const selectedItemCopy = { ...product };
            setServices(product.services);
            formik.setFieldValue('productSelected', selectedItemCopy);
            setSelectedItem(selectedItemCopy);
        } else {
            formik.setFieldValue('productSelected', null);
            setServices([]);
            setSelectedItem(null);
        }
    };


    const verifyClass = (inputFieldID: keyof IOperationForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IOperationForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as string}</div> : <></>;
    }

    const _loadFromOperation = () => {
        if (operationData !== undefined && operationData !== null) {

            formik.setFieldValue('address', operationData.clientAddress);
            formik.setFieldValue('postalCode', operationData.clientPostalCode);
            formik.setFieldValue('town', operationData.locality);
            formik.setFieldValue('province', operationData.province);
        }

    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Eleccion Producto' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_expense'>Nueva eleccion de Producto</ModalTitle>) : (<ModalTitle id='edit_expense'>Editar elccion de Producto</ModalTitle>)}
            </ModalHeader>
            <ModalBody className='px-4'>
                <Wizard
                    isHeader={true}
                    title="Products Selection"
                    color={'primary'}
                    onSubmit={formik.handleSubmit}
                    disabledNextButton={[{ id: 0, disabled: formik.values.businessId === "" ? true : false }, { id: 3, disabled: Object.keys(formik.errors).length === 0 ? false : true }]}
                    onNext={(active: number) => {
                        if (active === 0) {
                            updateFilters({
                                active: true,
                                business: formik.values.businessId, target: selectedTarget, 
                                power: formik.values.power == "" ? null : formik.values.power, 
                                consumption: formik.values.consumptionfilt == "" ? null : formik.values.consumptionfilt,
                                productType: formik.values.productType
                            })
                        }
                    }}
                >
                    <WizardItem
                        id={'options_product'}
                        title={'Opciones del Producto'}
                    >
                        <div className="row">
                            <FormGroup requiredInputLabel id='business' label='Comercializadora del Producto:' className='col-md-6'>
                                <Select required id='businessId' ariaLabel='Default select example' placeholder='Elegir Comercializadora...' onChange={(e: any) => formik.setFieldValue('businessId', e.target.value)} value={formik.values.businessId} list={businessList} onBlur={formik.handleBlur} className={verifyClass('businessId')} autoFocus={true} />
                                {showErrors('businessId')}
                            </FormGroup>
                            <FormGroup id='target' label='Destinatario del Producto:' className='col-md-6 text-center'>
                                <br /><RadioButton onOptionChange={(value) => { setSelectedTarget(value) }}></RadioButton>
                            </FormGroup>
                            <div className="col-md-6 mt-2">
                                <ProductTypeSelector name="productType" defaultSelected={formik.values.productType} isRequired onChange={(value: string | string[]) => {
                                    formik.setFieldValue('productType', value);
                                }} ></ProductTypeSelector>
                            </div>

                        </div>
                        <div className="row mt-5">
                            <FormGroup id='power' label='Potencia (kw):' className='col-md-6'>
                                <Input id="power" min={0} step={0.01} name="power" type="number" value={formik.values.power || ''} onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("power" , e.target.value)}} onBlur={formik.handleBlur} className={verifyClass('power')} />
                                {showErrors('power')}
                            </FormGroup>
                            <FormGroup id='consumptionfilt' label='Consumo (kw)' className='col-md-6'>
                                <Input id="consumptionfilt" min={0} step={0.01} name="consumptionfilt" type="number" value={formik.values.consumptionfilt || ''} onChange={(e:ChangeEvent<HTMLInputElement>) => {formik.setFieldValue("consumptionfilt" , e.target.value)}} onBlur={formik.handleBlur} className={verifyClass('consumptionfilt')} />
                                {showErrors('consumptionfilt')}
                            </FormGroup>
                        </div>
                    </WizardItem>
                    <WizardItem
                        id={'choose_product'}
                        title={'Elije el producto'}
                    >
                        {
                            data ? (
                                <CustomTable
                                    data={data.products}
                                    className={"table table-hover p-3 fs-7"}
                                    pagination={true}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: data as ProductApiResponse ? data.lastPage : 1,
                                        totalCount: data.totalRegisters,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    columns={[
                                        {
                                            name: "",
                                            keyValue: "selected",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <div key={item.id} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`checkbox-${item.id}`}
                                                                checked={selectedItem ? selectedItem.id === item.id : false}
                                                                onChange={(event) => handleCheckboxChange(event, item.id, item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div >
                                            },
                                        },
                                        {
                                            name: "Nombre",
                                            keyValue: "name",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="fs-7">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Prioridad",
                                            keyValue: "priority",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.priority}</div>
                                            },
                                        },
                                        {
                                            name: "Tipo de Producto",
                                            keyValue: "productType",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.productType.name} </div>
                                            }
                                        },
                                        {
                                            name: "Tarifa",
                                            keyValue: "fee",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.fee.name}</div>
                                            },
                                        },
                                        {
                                            name: "Puntos base",
                                            keyValue: "points",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.points ? item.points : 0}</div>
                                            }
                                        },
                                        {
                                            name: "Habilitado Particular",
                                            keyValue: "individualEnabled",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.individualEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                                            }
                                        },
                                        {
                                            name: "Habliitado Empresa",
                                            keyValue: "businessEnabled",
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-7 text-center">{item.businessEnabled ? <CheckCircleOutline className="text-success" width="30px" height="30px" /> : <HighlightOff className="text-danger" width="30px" height="30px" />}</div>
                                            }
                                        },
                                    ]}
                                />
                            ) : (
                                <div className="text-center"><Spinner></Spinner></div>
                            )
                        }
                    </WizardItem>
                    <WizardItem
                        id={'select_services'}
                        title="Selección de servicios">
                            <ServiceTableWizard services={services} formik={formik}/>
                    </WizardItem>
                    <WizardItem
                        id={'complete_product'}
                        title={'Datos Adicionales'}

                    >
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Tooltips title="Cargar datos del cliente">
                                    <Button color="info" isLight size={'sm'} onClick={_loadFromOperation}><Icon icon="Refresh" size={'lg'}></Icon></Button>
                                </Tooltips>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-primary">Dirección del suministro</h3>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='address' label='Dirección:'>
                                    <Input id='address' required onChange={formik.handleChange} value={formik.values.address} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                    {showErrors('address')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='town' label='Ciudad:'>
                                    <Input id='town' required onChange={formik.handleChange} value={formik.values.town} onBlur={formik.handleBlur} className={verifyClass('town')} />
                                    {showErrors('town')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='province' label='Provincia:'>
                                    <Input id='province' required onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                    {showErrors('province')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='postalCode' label='Código Postal:'>
                                    <Input id='postalCode' required onChange={formik.handleChange} value={formik.values.postalCode} onBlur={formik.handleBlur} className={verifyClass('postalCode')} />
                                    {showErrors('postalCode')}
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12">
                                <h3 className="text-primary">Datos del producto contratado</h3>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='cups' label='Cups:'>
                                    <Input id='cups' onChange={formik.handleChange} value={formik.values.cups || ''} onBlur={formik.handleBlur} className={verifyClass('cups')} />
                                    {showErrors('cups')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup id='cupsGas' label='Cups Gas:'>
                                    <Input id='cupsGas' onChange={formik.handleChange} value={formik.values.cupsGas || ''} onBlur={formik.handleBlur} className={verifyClass('cupsGas')} />
                                    {showErrors('cupsGas')}
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup className="mt-2" id='consumption' label='Consumo (en Kw):'>
                                    <Input id='consumption' onChange={formik.handleChange} value={formik.values.consumption || ''} onBlur={formik.handleBlur} className={verifyClass('consumption')} />
                                    {showErrors('consumption')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 1)'>
                                    <Input id='powerP1' onChange={formik.handleChange} value={formik.values.powerP1 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP1')} />
                                    {showErrors('powerP1')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 2)'>
                                    <Input id='powerP2' onChange={formik.handleChange} value={formik.values.powerP2 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP2')} />
                                    {showErrors('powerP2')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 3)'>
                                    <Input id='powerP3' onChange={formik.handleChange} value={formik.values.powerP3 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP3')} />
                                    {showErrors('powerP3')}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 4)'>
                                    <Input id='powerP4' onChange={formik.handleChange} value={formik.values.powerP4 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP4')} />
                                    {showErrors('powerP4')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 5)'>
                                    <Input id='powerP5' onChange={formik.handleChange} value={formik.values.powerP5 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP5')} />
                                    {showErrors('powerP5')}
                                </FormGroup>
                                <FormGroup className="mt-2" id='powerP1' label='Potencia (Periodo 6)'>
                                    <Input id='powerP6' onChange={formik.handleChange} value={formik.values.powerP6 || ''} onBlur={formik.handleBlur} className={verifyClass('powerP6')} />
                                    {showErrors('powerP6')}
                                </FormGroup>
                            </div>


                        </div>
                    </WizardItem>
                    <WizardItem
                        id={'resume_product'}
                        title={'Resumen producto'}
                    >
                        {getResume()}

                    </WizardItem>
                </Wizard>
            </ModalBody>
        </Modal>
    )
}

export default WizardProduct;