import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardFooter,
  CardLabel,
  CardTabItem,
  CardTitle,
} from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Page from "../../../layout/Page/Page";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { OperationService } from "../../../services/operations/operationService";
import { OperationApiResponse } from "../../../type/operation-type";
import moment from "moment";
import Timeline, { TimelineItem } from "../../../components/extras/Timeline";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ActiveTab, setActiveTab } from "../../../redux/activeTabSlice";
import OperationProductsDataTab from "../components/OperationsProductDataTab";
import OperationCommentCard from "../components/operation-comments/OperationCommentCard";
import OperationRecordsComponent from "../components/operation-records/OperationRecordsComponent";
import OperationSMSPanel from "../components/operation-sms/OperationSMSPanel";
import OperationDocumentsComponent from "../components/OperationDocumentsComponent";
import EditOperationModal from "../../../components/operation/EditOperationModal";
import { useLoader } from "../../../components/loader/LoaderProvider";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ACTIVE_TAB_SECTION = 'operation_view';

const OperationView: FC = () => {

  const { id = "" } = useParams<{ id: string }>();
  const [products, setProducts] = useState<any[]>([]);
  const [showEditOperationModal, setShowCreateOperationModal] = useState<boolean>(false);
  const [operationSelected, setOperationSelected] = useState<string | null>(null);

  const fetchOperation = useCallback(async () => {
    const operationService = new OperationService();
    const response = await operationService.getOperationById(id as string);
    setProducts(response.getResponseData().data.products);
    return response.getResponseData() as OperationApiResponse;
  }, [id]);

  const activeTab = useSelector((state: RootState) => state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION));

  const dispatch = useDispatch();

  const [data, loading, error, refetch] = useFetch(fetchOperation);

  const navigate = useNavigate();

  const { userCan } = usePrivilege();
  const { showLoading, hideLoading } = useLoader();
  const { handleErrors } = useHandleErrors();

  const _handleOnEditModalVisilble = (visible: boolean) => {
    if (!visible) setOperationSelected(null);
    setShowCreateOperationModal(visible);
  }

  const _handleExportContract = () => async () => {

    showLoading("Exportando contrato", <>Espere mientras se genera el contrato</>);

    try {
      const response = await (await (new OperationService()).exportContract(id));
      const responseData = response.getResponseData();
      // generate blob and download
      const blob = new Blob([response?.getResponse()?.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${id}_contrato_endesa.xlsx`;
      link.click();
      if (responseData?.success) {

      } else {
        handleErrors(response);
      }

    } catch (error: any) {
      toast.error("Error al exportar el contrato" + error.message);
    } finally {
      hideLoading();
    }
  }

  const _renderExportToExcelButton = () => {
    if (data) {
      if (data.products.length > 0) {
        let product = data.products[0];
        if (product.product?.business?.name === "ENDESA") {
          return (
            <Button color="danger" isLight className="me-3" onClick={_handleExportContract()}>Exportar contrato ENDESA</Button>
          )
        }
      }
    }

    return (<></>)
  }

  const _handleOnOperationEdit = (operation: any) => {
    toast.success("Contrato editado correctamente");
    refetch();
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <Button
              color="primary"
              isLink
              icon="ArrowBack"
              onClick={() => {
                navigate(-1);
              }}
            ></Button>
            <CardLabel icon="Search" iconColor="info">
              <CardTitle>Ver Contrato</CardTitle>
            </CardLabel>
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Fragment>
            {_renderExportToExcelButton()}
            <Button
              color="primary"
              icon="Edit"
              isLight
              onClick={() => {
                setOperationSelected(id);
                _handleOnEditModalVisilble(true);
              }}
            >
              Editar Contrato
            </Button>
          </Fragment>
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        {
          data ? (
            <div>
            <div className="row">
              <Card className="col-md-12" hasTab tabButtonColor="light">
                <CardTabItem
                  id="operation"
                  title="Datos del Contrato"
                  icon="Source"
                >
                  <CardBody>
                    <div className="row">
                        {/* DATA COLUMN */}
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-3">
                            <FormGroup label="IDENTIFICADOR DEL CONTRATO:">
                              <p> {data.operationIdentifier || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-2">
                            <FormGroup label="CUPS:">
                              <p> {data.cups || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-3">
                            <FormGroup label='TIPO DE TRÁMITE:'>
                              <p> {data.lead?.name || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-2">
                            <FormGroup label='ORIGEN LEAD:'>
                              <p> {data.origin?.name || "-"}</p>
                            </FormGroup>
                          </div>
                          <div className="col-md-2">
                            <FormGroup label="CNAE:">
                              <p> {data.cnae || "-"} </p>
                            </FormGroup>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-md-3">
                              <FormGroup label="TIPO DE CONTRATO:">
                                <p> {data.operationType?.name || "-"} </p>
                              </FormGroup>
                            </div>
                            <div className="col-md-3">
                              <FormGroup label="CONTRATO DESTINADO A:">
                                <p> {data.operationFor || "-"} </p>
                              </FormGroup>
                            </div>
                            {
                              userCan('assign_to_agents', 'operations') || userCan('assign_to_user', 'operations') ?
                                <>
                                  <FormGroup label='CREADA POR:' className={`${userCan('assign_to_user', 'operations') ? userCan('assign_to_agents', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                    <p>{data.user?.name}</p>
                                  </FormGroup>
                                  <FormGroup label='AGENTE:' className={`${userCan('assign_to_agents', 'operations') ? userCan('assign_to_user', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                    <p>{data.agent?.name}</p>
                                  </FormGroup>
                                </>
                                : null
                            }
                        </div>
                      </div>
                      <div className="col-md-3">
                        <FormGroup label="COMENTARIO GENERAL:">
                          <p> {data.operationComment || "-"}</p>
                        </FormGroup>
                      </div>
                      </div>
                  </CardBody>
                  <CardFooter className="align-items-start justify-content-start">
                    <FormGroup label="CREADO:" className="col-md-2">
                      <div className="d-inline-block ps-2">
                        {data.createdAt ? moment(data.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                      </div>
                    </FormGroup>
                    <FormGroup label="ACTUALIZADO:" className="col-md-2">
                      <div className="d-inline-block ps-2">
                        {data.updatedAt ? moment(data.updatedAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                      </div>
                    </FormGroup>
                    <FormGroup label="ACTIVACIÓN:" className="ms-4 col-md-2">
                      <div className="d-inline-block ps-2">
                        {data.activationDate ? moment(data.activationDate.date).format("DD-MM-YYYY HH:mm") : "-"}
                      </div>
                    </FormGroup>
                  </CardFooter>
                </CardTabItem>
                <CardTabItem
                  id="client"
                  title="Datos del Cliente"
                  icon="Person"
                >
                  <CardBody>
                    <div className="row">
                      <div className="col-md-3">
                        <FormGroup label="NOMBRE:">
                          <Link to={`/clients/${data.client.id}/show`} color="primary">
                            <p> {data.clientName || ""}</p>
                          </Link>
                        </FormGroup>
                      </div>
                      <div className="col-md-2">
                        <FormGroup label="DNI:">
                          <p> {data.clientDni || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-2">
                        <FormGroup label="TELÉFONO:">
                          <p> {data.clientPhone || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-2">
                        <FormGroup label="EMAIL:">
                          <p> {data.clientEmail || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-3">
                        <FormGroup label="IBAN:">
                          <p> {data.iban || ""}</p>
                        </FormGroup>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <FormGroup label="LOCALIDAD:">
                          <p> {data.locality || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-3">
                        <FormGroup label="PROVINCIA:">
                          <p> {data.province || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-3">
                        <FormGroup label="DIRECCIÓN:">
                          <p> {data.clientAddress || ""}</p>
                        </FormGroup>
                      </div>
                      <div className="col-md-3">
                        <FormGroup label="CÓDIGO POSTAL:">
                          <p> {data.clientPostalCode || ""}</p>
                        </FormGroup>
                      </div>
                    </div>
                  </CardBody>
                </CardTabItem>
              </Card>
            </div>
            <Card
              // @ts-ignore
              defaultActiveTab={activeTab?.activeTab || 0}
              onActiveTab={(tab: number) => {
                dispatch(setActiveTab({ section: ACTIVE_TAB_SECTION, activeTab: tab }));
              }}
              tabButtonColor="light"
              hasTab
            >
              <CardTabItem
                id="operation"
                title="Timeline estados"
                icon="Timeline"
              >
                <CardBody className="max-h-250 overflow-auto">
                  {data.actionStatuses.length > 0 ? (
                    <Timeline noTruncate={true} className=" overflow-auto">
                      {data.actionStatuses.map(
                        (actionStatus: any, index: number) => (
                          <TimelineItem
                            noTruncate={true}
                            key={actionStatus.id}
                            color={actionStatus.actionStatus.color}
                            label={moment(
                              actionStatus.createdAt.date
                            ).format("YYYY/MM/DD HH:mm")}
                          >
                            <div>
                            Cambio a estado{" "}
                            <strong>
                              {actionStatus.actionStatus.name ||
                                "En curso"}
                            </strong>
                            </div>
                            <div>
                            {" "}
                            <p className="text-muted m-0" style={{maxWidth: '2000px', width: '100%', fontSize: '0.875em'}}>
                              Agente :{" "}{actionStatus.user?.name ||"-"}
                            </p>
                            <p className="text-muted m-0" style={{maxWidth: '2000px', width: '100%', fontSize: '0.875em'}}>
                              Tipo de Estado :{" "}{actionStatus.actionStatus.statusEntity.label ||"-"}
                            </p>
                            <p className="text-muted m-0" style={{maxWidth: '2000px', width: '100%', fontSize: '0.875em'}}>
                              Comentario :{" "}{actionStatus.comment ||"-"}
                            </p>
                            </div>
                          </TimelineItem>
                        )
                      )}
                    </Timeline>
                  ) : (
                    <div className="text-center">
                      No ha cambiado el estado todavia
                    </div>
                  )}
                </CardBody>
              </CardTabItem>
              <CardTabItem
                id="products"
                title="Productos"
                icon="Inventory2"
              >
                <OperationProductsDataTab products={data.products} maxheight="max-h-250" />
              </CardTabItem>
              <CardTabItem
                id="Comentarios"
                title="Comentarios"
                icon="Comment"
              >
                <OperationCommentCard id={data?.id} maxheight="max-h-250" />
              </CardTabItem>
              <CardTabItem
                id="records"
                title="Registros"
                icon="History"
              >
                <OperationRecordsComponent operationId={data?.id as string} noCard={true} maxheight="max-h-250" />
              </CardTabItem>
              <CardTabItem
                id="sms"
                title="SMS"
                icon="Sms"
              >
                <OperationSMSPanel operation={data} noCard={true} maxheight="max-h-250" />
              </CardTabItem>
              <CardTabItem
                id="documents"
                title="Documentos"
                icon="Description"
                hidden={userCan('show_documents', 'operations') ? false : true}
              >
                <OperationDocumentsComponent operationId={data?.id as string} />
              </CardTabItem>
  
            </Card>
          </div>
        ) : (
          <div className="text-center d-flex justify-content-center">
            <Spinner />
          </div>
        )}
        <EditOperationModal visible={showEditOperationModal} setVisible={_handleOnEditModalVisilble} operationId={operationSelected || ""} onOperationEdit={_handleOnOperationEdit} />
      </Page>
    </Fragment>
  );
};

export default OperationView;