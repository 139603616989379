import { FC } from "react";
import ProductsList from "../../../products/product-list/ProductList";
import { FiltersProvider } from "../../../../components/providers/FiltersProvider";
interface ProductWrapperProps {
    businessId: string;
}

const ProductWrapper: FC<ProductWrapperProps> = ({businessId}) => {

    return (
    <>
        <FiltersProvider defaultFilterFilters={{business: businessId}}>
            <ProductsList businessId={businessId}/>;
        </FiltersProvider>
    </>
    )
}

export default ProductWrapper;