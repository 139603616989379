import { useFormik } from "formik";
import { FC, Fragment, useState } from "react";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import Textarea from "../../components/bootstrap/forms/Textarea";
import AsyncImg from "../../components/AsyncImg";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { validateOnlyCIF } from "../../utils/forms/ValidateDNICIFField";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    companyData?: any;
}

export interface ICompanyForm {
    cif?: string;
    name?: string;
    address?: string;
    email?: string;
    description?: string;
    telephone?: string;
    leaddesk_username?: string;
    leaddesk_password?: string;
    tumensajero_username?: string;
    tumensajero_password?: string;
    ocm_server_url?: string;
    ocm_username?: string;
    ocm_password?: string;
    ocm_api_token?: string;
    ocm_launch_call_skill?: string;
    ocm_db_username?: string;
    ocm_db_password?: string;
    ocm_db_name?: string;

}

yup.addMethod(yup.string, 'isValidCIF', validateOnlyCIF);

const CompanySchema = yup.object({
    //@ts-ignore
    cif: yup.string().isValidCIF('El CIF no es válido').required('Campo Obligatorio'),
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    email: yup.string().email('Correo Invalido').required('Campo Obligatorio'),
    address: yup.string(),
    telephone: yup.string(),
});

const CompanyForm: FC<CreateFormProps> = ({ isLoading, submit, companyData }) => {

    const mode = companyData ? 'Editar' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<string | null>(companyData?.logo?.id || null);
    const [uploadImage, setuploadImage] = useState<any>(null);

    const companyInitialValues: ICompanyForm = {
        cif: companyData?.cif,
        name: companyData?.name,
        address: companyData?.address,
        email: companyData?.email,
        description: companyData?.description,
        telephone: companyData?.telephone,
        leaddesk_username: companyData?.leaddeskUsername,
        leaddesk_password: companyData?.leaddeskPassword,
        tumensajero_username: companyData?.tumensajeroUsername,
        tumensajero_password: companyData?.tumensajeroPassword,
        ocm_server_url: companyData?.OCMServerURL,
        ocm_username: companyData?.OCMUsername,
        ocm_password: companyData?.OCMPassword,
        ocm_api_token: companyData?.OCMAPIToken,
        ocm_launch_call_skill: companyData?.OCMLaunchCallSkill,
        ocm_db_username: companyData?.OCMDBUsername,
        ocm_db_password: companyData?.OCMDBPassword,
        ocm_db_name: companyData?.OCMDBName
    }

    const formik = useFormik({
        initialValues: companyInitialValues,
        validationSchema: CompanySchema,
        onSubmit: values => {
            submit(values)
        },
    });

    const handleImageUpload = (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setuploadImage(reader.result);
        };

        reader.readAsDataURL(file);
        formik.setFieldValue('logo', event.currentTarget.files[0])
    };

    const clearImage = () => {
        setuploadImage(null);
        formik.setFieldValue('logo', null);
    }

    const verifyClass = (inputFieldID: keyof ICompanyForm) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: keyof ICompanyForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
                <CardBody isScrollable={false}>
                    <div className="row">
                        <div className='col-md-6'>
                            <CardBody>
                                <div className="row align-items-center">
                                    <div className='col-lg-2'>
                                        {(uploadImage) ?
                                            <img width={70} height={70} src={uploadImage} alt="uploaded image" /> :
                                            (
                                                selectedImage ?
                                                    <AsyncImg height={"70px"} width={"70px"} id={selectedImage} />
                                                    : <PlaceholderImage width={70} height={70} className='mx-auto d-block img-fluid mb-3 rounded' />
                                            )}
                                    </div>
                                    <div className='col-lg-10'>
                                        <div className='row g-4'>
                                            <div className='col-8'>
                                                <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' />
                                            </div>
                                            <div className='col-4'>
                                                <Button color='dark' isLight icon='Delete' onClick={clearImage} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row">
                            <div className='col-md-12'>
                                <CardHeader className='rounded-1 mb-0'>
                                    <CardLabel icon='Assignment' iconColor='info'>
                                        <CardTitle>Datos básicos</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <FormGroup label='Nombre de Organización' className='col-md-6'>
                                            <Input id='name' onChange={formik.handleChange} value={formik.values.name || ''} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                            {showErrors('name')}
                                        </FormGroup>
                                        <FormGroup requiredInputLabel label='Email' className='col-md-6'>
                                            <span className={'text-muted ms-2'}>Se generará un usuario con este correo como adminsitrador de la compañía</span>
                                            <Input id='email' required type='email' onChange={formik.handleChange} value={formik.values.email || ''} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                            {showErrors('email')}
                                        </FormGroup>
                                    </div>
                                    <div className="row mt-2">
                                        <FormGroup label='CIF' className='col-md-6'>
                                            <Input id='cif' onChange={formik.handleChange} value={formik.values.cif || ''} onBlur={formik.handleBlur} className={verifyClass('cif')} />
                                            {showErrors('cif')}
                                        </FormGroup>
                                        <FormGroup label='Dirección' className='col-md-6'>
                                            <Input id='address' onChange={formik.handleChange} value={formik.values.address || ''} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                            {showErrors('address')}
                                        </FormGroup>
                                    </div>
                                    <div className="row mt-2">
                                        <FormGroup label='Teléfono' className='col-md-6'>
                                            <PhoneInput
                                                country={'es'}
                                                autoFormat={false}
                                                countryCodeEditable={false}
                                                onChange={(phone: string) => {
                                                    if (phone.length == 0) {
                                                        formik.setFieldValue('telephone', null);
                                                        return;
                                                    }
                                                    formik.setFieldValue('telephone', "+" + phone);
                                                }}
                                                value={formik.values.telephone} inputClass={verifyClass('telephone')} />
                                            {showErrors('telephone')}
                                        </FormGroup>
                                        <FormGroup label='Descripción' className='col-md-6'>
                                            <Textarea id='description' onChange={formik.handleChange} value={formik.values.description || ''} onBlur={formik.handleBlur} className={verifyClass('description')} />
                                            {showErrors('description')}
                                        </FormGroup>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row">
                            <div className='col-md-12'>
                                <CardBody>
                                    <div className="row">
                                        <div className={'row col-md-12'}>
                                            <CardTitle>Integraciones</CardTitle>
                                        </div>
                                        <div className='row col-md-3'>
                                            <CardBody>
                                                <div className={'row col-md-12'}>
                                                    <CardLabel icon='SupportAgent' iconColor='info'>
                                                        <CardTitle>LeadDesk</CardTitle>
                                                    </CardLabel>
                                                </div>

                                                <div className='row'>
                                                    <FormGroup label='Nombre de Usuario' className='col-md-12'>
                                                        <Input id='leaddesk_username'
                                                            name={'leaddesk_username'}
                                                            autoComplete="new-password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.leaddesk_username || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('leaddesk_username')}
                                                        />
                                                        {showErrors('leaddesk_username')}
                                                    </FormGroup>
                                                    <FormGroup label='Password' className='col-md-12'>
                                                        <Input id='leaddesk_password' name={'leaddesk_password'}
                                                            autoComplete="new-password"
                                                            type='password'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.leaddesk_password || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('leaddesk_password')}
                                                        />
                                                        {showErrors('leaddesk_password')}
                                                    </FormGroup>
                                                </div>
                                            </CardBody>
                                        </div>
                                        <div className='row col-md-3'>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className={'row col-md-12'}>
                                                        <CardLabel icon='Message' iconColor='info'>
                                                            <CardTitle>TuMensajero</CardTitle>
                                                        </CardLabel>
                                                    </div>
                                                    <FormGroup label='Nombre de Usuario' className='col-md-12'>
                                                        <Input
                                                            id='tumensajero_username'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.tumensajero_username || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('tumensajero_username')}
                                                        />
                                                        {showErrors('tumensajero_username')}
                                                    </FormGroup>
                                                    <FormGroup label='Password' className='col-md-12'>
                                                        <Input
                                                            id='tumensajero_password'
                                                            autoComplete="new-paswword"
                                                            type='password'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.tumensajero_password || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('tumensajero_password')}
                                                        />
                                                        {showErrors('tumensajero_password')}
                                                    </FormGroup>
                                                </div>
                                            </CardBody>
                                        </div>
                                        <div className='row col-md-6'>
                                            <CardHeader className='rounded-1 mb-0'>
                                                <CardLabel icon='SupportAgent' iconColor='info'>
                                                    <CardTitle>OCM</CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody className="py-0">
                                                <div className='row'>
                                                    <FormGroup label='Dirección del servidor (IP o Dominio)' className='col-md-6 mt-2'>
                                                        <Input id='ocm_server_url'
                                                            name={'ocm_server_url'}
                                                            autoComplete="new-password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.ocm_server_url || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('ocm_server_url')}
                                                        />
                                                        {showErrors('ocm_server_url')}
                                                    </FormGroup>
                                                    <FormGroup label='API Token' className='col-md-6 mt-2'>
                                                        <Input id='ocm_api_token'
                                                            name={'ocm_api_token'}
                                                            autoComplete="new-password"
                                                            type='password'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.ocm_api_token || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('ocm_api_token')}
                                                        />
                                                        {showErrors('ocm_api_token')}
                                                    </FormGroup>
                                                    <FormGroup label='Nombre de Usuario' className='col-md-6 mt-2'>
                                                        <Input id='ocm_username'
                                                            name={'ocm_username'}
                                                            autoComplete="new-password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.ocm_username || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('ocm_username')}
                                                        />
                                                        {showErrors('ocm_username')}
                                                    </FormGroup>
                                                    <FormGroup label='Password' className='col-md-6 mt-2'>
                                                        <Input id='ocm_password'
                                                            name={'ocm_password'}
                                                            autoComplete="new-password"
                                                            type='password'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.ocm_password || ''}
                                                            onBlur={formik.handleBlur}
                                                            className={verifyClass('ocm_password')}
                                                        />
                                                        {showErrors('ocm_password')}
                                                    </FormGroup>
                                                    <FormGroup label='Skill de lanzamiento de llamada' className='col-md-6 mt-2'>
                                                        <Input id='ocm_launch_call_skill'
                                                               name={'ocm_launch_call_skill'}
                                                               autoComplete="new-password"
                                                               type='text'
                                                               onChange={formik.handleChange}
                                                               value={formik.values.ocm_launch_call_skill || ''}
                                                               onBlur={formik.handleBlur}
                                                               className={verifyClass('ocm_launch_call_skill')}
                                                        />
                                                        {showErrors('ocm_launch_call_skill')}
                                                    </FormGroup>
                                                    <FormGroup label='Nombre Base de Datos' className='col-md-6 mt-2'>
                                                        <Input id='ocm_db_name'
                                                               name={'ocm_db_name'}
                                                               autoComplete="new-password"
                                                               type='text'
                                                               onChange={formik.handleChange}
                                                               value={formik.values.ocm_db_name || ''}
                                                               onBlur={formik.handleBlur}
                                                               className={verifyClass('ocm_db_name')}
                                                        />
                                                        {showErrors('ocm_db_name')}
                                                    </FormGroup>
                                                    <FormGroup label='Usuario BBDD' className='col-md-6 mt-2'>
                                                        <Input id='ocm_db_username'
                                                               name={'ocm_db_username'}
                                                               autoComplete="new-password"
                                                               type='text'
                                                               onChange={formik.handleChange}
                                                               value={formik.values.ocm_db_username || ''}
                                                               onBlur={formik.handleBlur}
                                                               className={verifyClass('ocm_db_username')}
                                                        />
                                                        {showErrors('ocm_db_username')}
                                                    </FormGroup>
                                                    <FormGroup label='Contraseña BBDD' className='col-md-6 mt-2'>
                                                        <Input id='ocm_db_password'
                                                               name={'ocm_db_password'}
                                                               autoComplete="new-password"
                                                               type='password'
                                                               onChange={formik.handleChange}
                                                               value={formik.values.ocm_db_password || ''}
                                                               onBlur={formik.handleBlur}
                                                               className={verifyClass('ocm_db_password')}
                                                        />
                                                        {showErrors('ocm_db_password')}
                                                    </FormGroup>
                                                </div>
                                            </CardBody>
                                        </div>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <CardFooterRight>
                        <Button type="submit" size='lg' color='primary' icon='Save'>
                            {isLoading ? <Spinner /> : `${mode} Organización`}
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default CompanyForm;