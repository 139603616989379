import {  Fragment, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody,CardTitle, } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { RoleService } from "../../../services/auth/roleService";
import { RolesApiResponse } from "../../../type/role-type";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import RoleCreateModal from "../role-create/RoleCreateModal";
import { CustomTable } from "../../../components/table/CustomTable";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import RoleFilters from "./role-options/RoleFilters";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import StatusDropdown from "../../../components/StatusDropdown";
import useHandleErrors from "../../../hooks/useHandleErrors";


const RoleList = () => {
  const {userCan} = useContext(PrivilegeContext)
  const navigate = useNavigate();
  const {handleErrors} = useHandleErrors();

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

  useEffect(() => {
    checkIfUrlHasFilters();
  }, []);

  const fetchRoles = useCallback(async () => {
    const roleService = new RoleService();
    const response = await roleService.getRoles(filters) ;
    return response.getResponseData() as RolesApiResponse;
  }, [filters]);

  const [data, loadingRole, errorRole, refetch] = useFetch(fetchRoles);
  const [roleCreationModal, setRoleCreationModal] = useState(false);
  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const toggleRoleStatus = async (
    id: string,
    status: boolean,
    toggleStatus: Function
  ) => {
    try {
      setChangingStatus([...changingStatus, id]);
      let response = await (
        await new RoleService().toggleRoleStatus(id, status)
      ).getResponseData();
      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
    }
  };

  const handleDelete = async (id: string) => {
    try {
      /** API delete call */
      const response = await (await (new RoleService()).deleteRole(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Rol eliminado correctamente");
        }, 500);
      } else {

      }
    } catch (error: any) {
      console.log(error);
    }
  }

  const handleDuplicate = async (id: string) => {

    const response = await (await (new RoleService()).duplicateRole(id)).getResponseData();
    if (response.success) {
      refetch();
      setTimeout(() => {
        toast.success("Rol duplicado correctamente");
      }, 500);
      return;
    }
    handleErrors(response);
  }

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Roles</CardTitle>
            <SubheaderSeparator />
            {userCan('create','roles') ?
            <Button
              color="light"
              icon="Add"
              isLight
              onClick={() => {
                setRoleCreationModal(true);
              }}
            >

              Añadir Rol
            </Button> : <></> }
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Fragment>
            <RoleFilters
              updateFilters={updateFilters}
              filters={filters}
              resetFilters={resetFilters}
            />
          </Fragment>
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody>
            <Fragment>
            {
              data ? (
                <CustomTable
                  data={data ? data.roles : null}
                  pagination={true}
                  defaultLimit={filters.limit || 50}
                  defaultOrder={filters.filter_order || undefined}
                  paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: (data as RolesApiResponse) ? data.lastPage : 1,
                    totalCount: data.totalRegisters,
                    handlePagination: updatePage,
                    handlePerPage: updatePageSize,
                  }}
                  className={"table table-hover"}
                  columns={
                    [
                      {
                        name: "Nombre",
                        keyValue: "name",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => { navigate(`${element.id}/edit`) }}>
                              {element.name}
                            </div>
                          )
                        },
                      },
                      {
                        name: "Organización",
                        keyValue: "company",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: userCan("list", "users", true)
                        ? "text-center"
                        : "d-none",
                        isVisible: userCan("list", "users", true),
                        render: (element: any) => {
                          if (element.company) {
                            return (
                            <div className="d-flex justify-content-center">
                              {element?.company?.name || "-"}
                            </div>
                            )
                          } else return(<></>);
                        }
                      },
                      {
                        name: "Descripción",
                        keyValue: "description",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        cellClassName: "text-center"
                      },
                      {
                        name: "Número de permisos",
                        keyValue: "permissions",
                        className: "text-center",
                        cellClassName: "text-center",
                        render: (element: any) => {
                          return (
                            <div className="d-flex justify-content-center">
                              {element.permissions?.length || 0}
                            </div>
                          );
                        }
                      },
                      {
                        name: "Estado",
                        keyValue: "active",
                        sortable: true,
                        sortColumn: updateFilterOrder,
                        className: "text-center",
                        render: (element: any) => {
                          return (
                            <div className={"text-center"}>
                              <StatusDropdown
                                disabled={changingStatus.includes(element.id)}
                                itemId={element.id}
                                status={element.active}
                                change={toggleRoleStatus}
                                key={element.id}
                              />
                            </div>
                          );
                        },
                      },

                      { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]
                  }
                  actions={
                    [
                      {
                        title: "Ver",
                        buttonType: 'icon',
                        iconColor: 'text-primary',
                        iconPath: '/media/icons/duotune/general/gen021.svg',
                        additionalClasses: `text-primary ${userCan('show','roles') ? '' : 'd-none'}`,
                        description: "Ver rol",
                        callback: (item: any) => {
                          if(userCan('show','roles')) navigate(`${item.id}/edit`);
                        },
                      },
                      {
                        title: "Editar",
                        buttonType: 'icon',
                        iconColor: 'text-info',
                        iconPath: '/media/icons/duotune/general/gen055.svg',
                        additionalClasses: `text-primary ${userCan('edit','roles') ? '' : 'd-none'}`,
                        description: "Editar rol",
                        callback: (item: any) => {
                          if(userCan('edit','roles')) navigate(`${item.id}/edit`);
                        },
                      },
                      {
                        title: "Duplicar",
                        buttonType: 'icon',
                        iconColor: 'text-success',
                        iconPath: '/media/icons/duotune/general/gen054.svg',
                        additionalClasses: `text-primary ${userCan('edit','roles') ? '' : 'd-none'}`,
                        description: "Duplicar rol",
                        callback: (item: any) => {
                          if(userCan('edit','roles')) {
                            handleConfirmationAlert({
                              title: "Duplicar rol",
                              text: "¿Está seguro que desea duplicar el rol?",
                              icon: "warning",
                              onConfirm: () => {
                                handleDuplicate(item.id);
                              }
                            })

                          }
                        },
                      },

                      {
                        title: "Eliminar",
                        buttonType: 'icon',
                        iconColor: 'text-danger',
                        iconPath: '/media/icons/duotune/general/gen027.svg',
                        additionalClasses: `text-danger ${userCan('delete','roles') ? '' : 'd-none'}`,
                        description: "Eliminar rol",
                        callback: (item: any) => {
                          if(userCan('delete','roles')){
                            handleConfirmationAlert({
                              title: "Eliminar rol",
                              text: "¿Está seguro que desea eliminar el rol?",
                              icon: "warning",
                              onConfirm: () => {
                                handleDelete(item.id);
                              }
                            })
                          }
                        },
                      },
                    ]
                  }
                />
              ) : (
                <div className="text-center d-flex justify-content-center text-primary"><Spinner></Spinner></div>
              )
            }
            </Fragment>
          </CardBody>
        </Card>
        {
          roleCreationModal && (
            <RoleCreateModal
              isOpen={roleCreationModal}
              setIsOpen={setRoleCreationModal}
              handleSuccessCreation={() => {
                toast.success("Rol creado correctamente");
                setRoleCreationModal(false);
                refetch()
              }}
            />)
        }
      </Page>
    </Fragment>
  );
};
export default RoleList;