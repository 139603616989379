import { FC, useContext } from "react";
import { OperationService } from "../../../services/operations/operationService";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import AsyncImg from "../../AsyncImg";
import StatusOperationDropdown from "../../StatusOperationDropdown";
import Spinner from "../../bootstrap/Spinner";
import { CustomTable } from "../CustomTable";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PrivilegeContext } from "../../priviledge/PriviledgeProvider";
import { OperationApiResponse } from "../../../type/operation-type";
import moment from "moment";
import { ClickToCall } from "../../calls/ClickToCall";
import Button from "../../bootstrap/Button";
import Icon from "../../icon/Icon";
import Tooltips from "../../bootstrap/Tooltips";
import { ActionStatus } from "../../../type/actionStatus-type";
import Badge from "../../bootstrap/Badge";
import { priceFormatEuro } from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import { ClientService } from "../../../services/clients/clientService";
import { truncateString } from "../../../utils/strings/truncateText";

interface OperationTableProps {
  visibleColums: string[];
  visibleActions: string[];
  updateFilters: Function;
  filters: any;
  updatePage: Function;
  updatePageSize: Function;
  defaultLimit?: number,
  defaultOrder?: any,
  updateFilterOrder: any;
  classNameProps: string;
  actionStatuses?: ActionStatus[];
  commissionStatuses?: ActionStatus[];
  contractStatuses?: ActionStatus[];
  data: any;
  error: any;
  refetch?: Function;
  onClickEdit?: (operation: any) => void;
}

const OperationTableComponent: FC<OperationTableProps> = ({
  visibleColums,
  visibleActions,
  updateFilters,
  filters,
  updatePage,
  updatePageSize,
  updateFilterOrder,
  classNameProps,
  defaultLimit,
  defaultOrder,
  actionStatuses,
  commissionStatuses,
  contractStatuses,
  data,
  error,
  refetch,
  onClickEdit
}) => {
  const navigate = useNavigate();

  const { userCan } = useContext(PrivilegeContext);

  const deleteOperation = async (id: string) => {
    const response = await (
      await new OperationService().deleteOperation(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Contrato eliminado correctamente");
      refetch && refetch();
    } else {
      toast.error("Error al eliminar contrato");
    }
  };

  const addToFavourite = async (id: string, status: boolean) => {
    const response = await (
      await new ClientService().toggleClientFavourite(id, !status)
    ).getResponseData();
    if (response.success) {
      toast.success("Cliente añadido a favoritos correctamente");
      refetch && refetch();
    } else {
      toast.error("Error al añadir a favoritos");
    }
  }

  const makeOperationDual = async (id: string) => {
    const response = await (
      await new OperationService().makeOperationDual(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Contrato hecho dual correctamente");
      updateFilters({ ...filters });
    } else {
      toast.error("Error al hacer dual contrato");
    }
  };

  const reApplyCommissionAndPoints = async (id: string) => {
    const response = await (
      await new OperationService().reApplyCommissionAndPoints(id)
    ).getResponseData();
    if (response.success) {
      toast.success("Comisión y puntos reaplicados correctamente");
      refetch && refetch();
    } else {
      toast.error("Error al reaplicar comisión y puntos");
    }
  }

  return <>
  {
    data ? (
      <CustomTable
        data={data.operations}
        className={classNameProps}
        defaultLimit={defaultLimit}
        defaultOrder={defaultOrder}
        pagination={true}
        paginationData={{
          pageSize: filters.limit,
          currentPage: filters.page,
          pageCount: (data as OperationApiResponse) ? data.lastPage : 1,
          totalCount: data.totalRegisters,
          handlePagination: updatePage,
          handlePerPage: updatePageSize,
        }}
        columns={[
          {
            name: "",
            keyValue: "agent",
            sortable: true,
            sortColumn: updateFilterOrder,
            className: `${visibleColums.includes("agent") ? "text-center" : "d-none"}`,
            isVisible: visibleColums.includes("agent"),
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center align-items-center flex-column" title={element.agent_name}>
                  <div className="user-avatar me-0">
                    <AsyncImg id={ element.agent_profile_img ? element.agent_profile_img : null } />
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {truncateString(element.agent_name, 8)}
                  </div>
                </div>
              );
            },
          },
          {
            name: "Estado",
            keyValue: "currentStatus",
            cellClassName: "p-0",
            className: `${visibleColums.includes("status") ? "text-center p-0" : "d-none"
              }`,
            isVisible: visibleColums.includes("status"),
            render: (item: any) => {
              if(item.current_action_status_id == null) return <></>;
              return (
                <div className="d-flex justify-content-center p-0">
                  <StatusOperationDropdown
                    onChange={() => {
                      refetch && refetch();
                    }}
                    key={item.id + "-status"}
                    itemId={item.id}
                    statuses={actionStatuses}
                    statusColor={item.current_action_status_color}
                    statusId={item.current_action_status_id}
                    statusName={item.current_action_status_name}
                    additionalInfo={
                      item.current_action_status_date
                        ? moment(item.current_action_status_date).format(
                          "DD/MM/yyyy HH:mm"
                        )
                        : undefined
                    }
                    entity={"operation"}
                  />
                </div>
              );
            },
          },
          {
            name: "E.Contrato",
            keyValue: "currentContractStatus",
            cellClassName: "p-0",
            className: `${visibleColums.includes("contract_status")
              ? "text-center p-0"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("contract_status"),
            render: (item: any) => {
              if(item.current_contract_status_id == null) return <></>;
              return (
                <div className="d-flex justify-content-center p-0">
                  {
                    contractStatuses && (
                      <StatusOperationDropdown
                        onChange={() => {
                          refetch && refetch();
                        }}
                        key={item.id + "-status-contract"}
                        itemId={item.id}
                        disabled={!userCan("change_contract_status", "operations")}
                        statuses={contractStatuses}
                        statusColor={item.current_contract_status_color}
                        statusId={item.current_contract_status_id}
                        statusName={item.current_contract_status_name}
                        additionalInfo={
                          item.current_contract_status_date
                            ? moment(item.current_contract_status_date).format(
                              "DD/MM/yyyy HH:mm"
                            )
                            : undefined
                        }
                        entity={"contract"}
                      />
                    )
                  }
                </div>
              );
            },
          },
          {
            name: "E.Comisión",
            keyValue: "currentCommissionStatus",
            cellClassName: "p-0",
            className: `${visibleColums.includes("commission_status")
              ? "text-center p-0"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("commission_status"),
            render: (item: any) => {
              if(item.current_commission_status_id == null) return <></>;
              return (
                <div className="d-flex justify-content-center p-0">
                  {
                    commissionStatuses && (
                      <StatusOperationDropdown
                        onChange={() => {
                          refetch && refetch();
                        }}
                        key={item.id + "-status-commission"}
                        itemId={item.id}
                        disabled={!userCan("change_commission_status", "operations")}
                        statusColor={item.current_commission_status_color}
                        statusId={item.current_commission_status_id}
                        statusName={item.current_commission_status_name}
                        statuses={commissionStatuses}
                        additionalInfo={
                          item.current_commission_status_date
                            ? moment(
                              item.current_commission_status_date
                            ).format("DD/MM/yyyy HH:mm")
                            : undefined
                        }
                        entity={"commission"}
                      />
                    )
                  }

                </div>
              );
            },
          },
          {
            name: "Fav. Cli",
            keyValue: "favorite",
            className: `${visibleColums.includes("fav")
              ? "text-center p-0"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("fav"),
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <div className="d-flex justify-content-center" onClick={() => { addToFavourite(item.client_id, item.client_favourite) }}>
                  <Icon icon="Star" color={item.client_favourite ? "warning" : "light"} size="2x" />
                </div>
              );
            },
          },
          {
            name: "Producto",
            keyValue: "product",
            className: `${visibleColums.includes("product") ? "text-center p-0" : "d-none"
              }`,
            isVisible: visibleColums.includes("product"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <div className="d-flex flex-column justify-content-center text-center p-0">
                  <div className="fw-bold">
                    {item.business_name ? item.business_name : ""}
                  </div>
                  {item.product_name ? item.product_name : "-"}
                  {item.services &&
                    item.services
                    .split(",")
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((service: any) => (
                      <div>
                        <Badge
                          key={item.id + "-" + service}
                          color="primary"
                          rounded={"pill"}
                          isLight
                        >
                          {service}
                        </Badge>
                      </div>
                    ))}
                </div>
              );
            },
          },
          {
            name: "Tipo",
            keyValue: "operationType",
            className: `${visibleColums.includes("operationType") ? "text-center p-0" : "d-none"
              }`,
            isVisible: visibleColums.includes("operationType"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return (
                <div className="text-center">{item.operation_type_name || ""}</div>
                );
              },
            },
          {
            name: "Creacion",
            keyValue: "created_at",
            className: `${visibleColums.includes("createdAt") ? "text-center p-0" : "d-none"
              }`,
            isVisible: visibleColums.includes("createdAt"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.created_at
                    ? moment(item.created_at).format("DD/MM/YYYY H:mm")
                    : "Sin fecha"}
                </div>
              );
            },
          },
          {
            name: "Cliente",
            keyValue: "clientName",
            className: `${visibleColums.includes("clientName")
            ? "text-center p-0"
            : "d-none"
          }`,
          isVisible: visibleColums.includes("clientName"),
          sortable: true,
          sortColumn: updateFilterOrder,
          cellClassName: "text-center",
          render: (item: any) => {
            return (
              <div className="text-center">
                  <Link to={`/clients/${item.client_id}/show`} color="primary">
                    {item.client_name ? item.client_name : "-"}
                  </Link>
                  <div>
                  {userCan("view_differents_phone_iban", "operations") && item.has_duplicate_iban ? (
                    <Tooltips title="El cliente tiene un IBAN de otro cliente registrado">
                      <span>
                        <Icon icon={'CreditCard'} color={"warning"} size={'lg'}/>
                      </span>
                    </Tooltips>
                  ) : ""}
                  {userCan("view_differents_phone_iban", "operations") &&  item.has_duplicate_phone ? (
                    <Tooltips title="El cliente tiene un Teléfono de otro cliente registrado">
                      <span>
                        <Icon icon={'Phone'} color={"warning"} size={'lg'}/>
                      </span>
                    </Tooltips>
                  ) : ""}
                  </div>
                </div>
              );
            },
          },
          {
            name: "DNI",
            keyValue: "clientDni",
            className: `${visibleColums.includes("clientDni")
              ? "text-center p-0"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("clientDni"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
            render: (item: any) => {
              return <div className="text-center">{item.client_dni || ""}</div>;
            }
          },
          {
            name: "CUPS/ID",
            keyValue: "cups",
            className: `${visibleColums.includes("cups") ? "text-center p-0" : "d-none"
              }`,
            isVisible: visibleColums.includes("cups"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.cups ? item.cups : "Sin código"}
                </div>
              );
            },
          },
          {
            name: "Tip. Trámite",
            keyValue: "lead",
            className: `${visibleColums.includes("lead") ? "text-center" : "d-none"
              }`,
            isVisible: visibleColums.includes("lead"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return <div>{item.lead_name || ""}</div>;
            },
          },
          {
            name: "Oficina",
            keyValue: "office",
            className: `${visibleColums.includes("office") ? "text-center" : "d-none"
              }`,
            isVisible: visibleColums.includes("office"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return <div>{item.office_name || ""}</div>;
            },
          },
          {
            name: "Organización",
            keyValue: "company",
            sortable: true,
            sortColumn: updateFilterOrder,
            className:
              userCan("list", "operations", true) &&
                visibleColums.includes("company")
                ? "text-center"
                : "d-none",
            isVisible:
              userCan("list", "operations", true) &&
              visibleColums.includes("company"),
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.company_name}
                </div>
              );
            },
          },
          {
            name: "Ult. Actualizacion",
            keyValue: "updatedAt",
            className: `${visibleColums.includes("updatedAt") ? "" : "d-none"
              }`,
            isVisible: visibleColums.includes("updatedAt"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.updated_at
                    ? moment(item.updated_at).format("DD/MM/YYYY")
                    : "Sin actualizar"}
                </div>
              );
            },
          },
          {
            name: "Fecha Activación",
            keyValue: "activationDate",
            className: `${visibleColums.includes("activationDate") ? "" : "d-none"
              }`,
            isVisible: visibleColums.includes("activationDate"),
            sortable: true,
            sortColumn: updateFilterOrder,
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.activationDate
                    ? moment(item.activationDate).format("DD/MM/YYYY")
                    : "Sin activar"}
                </div>
              );
            },
          },
          {
            name: "Localidad",
            keyValue: "locality",
            className: `${visibleColums.includes("locality")
              ? "text-center"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("locality"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
          },
          {
            name: "Provincia",
            keyValue: "province",
            className: `${visibleColums.includes("province")
              ? "text-center"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("province"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
          },
          {
            name: "Teléfono",
            keyValue: "clientPhone",
            className: `${visibleColums.includes("clientPhone")
              ? "text-center"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("clientPhone"),
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.client_phone1 ? (
                    <ClickToCall telephone={item.client_phone1} />
                  ) : (
                    "-"
                  )}
                  {item.client_phone2 ? (
                    <ClickToCall telephone={item.client_phone2} />
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          {
            name: "Comision",
            keyValue: "commission",
            className: `${visibleColums.includes("commission")
              ? "text-center p-0 min-w-custom"
              : "d-none"
              }`,
            isVisible: visibleColums.includes("commission"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <>
                  <div className="text-center fw-bold text-primary">
                    <div>
                    {item.commission ? (
                      <>
                        {priceFormatEuro(item.commission)}
                      </>
                    ) : "0 €"}
                    {item.contributor_commission && userCan("show_contributors_commissions", "operations") ? (
                      <>
                        {" / " + priceFormatEuro(item.contributor_commission)}
                      </>
                    ) : ""}
                    {(item.current_action_status_apply_commission !== 0 || item.current_commission_status_apply_commission !== 0 || item.current_contract_status_apply_commission !== 0)
                      && item.product_commission !== null && item.product_commission !== item.commission ? (
                        <Tooltips title="La commission del contrato esta desactualizada con la commission del producto">
                          <span>
                            <Icon icon={'Warning'} color={"warning"} size={'lg'}/>
                          </span>
                        </Tooltips>
                    ) : ""}
                    </div>
                  </div>
                  <div className="text-center">
                      {item.commission_date ? moment(item.commission_date).format("DD/MM/YYYY H:mm") : ""}
                  </div>
                </>
              );
            },
          },
          {
            name: "Puntos",
            keyValue: "points",
            className: `${visibleColums.includes("points") ? "text-center p-0 min-w-custom" : "d-none"
              }`,
            isVisible: visibleColums.includes("points"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.points ? (
                    <>
                      <span>{item.points}</span>
                      {(item.current_action_status_apply_points !== 0 || item.current_commission_status_apply_points !== 0 || item.current_contract_status_apply_points !== 0)
                        && item.product_points !== null && item.product_points !== item.points ? (
                        <Tooltips title="La puntos del contrato estan desactualizados con los puntos del producto">
                          <span>
                            <Icon icon={'Warning'} color={"warning"} size={'lg'}/>
                          </span>
                        </Tooltips>
                      ) : ""}
                      <div>{item.points_date ? moment(item.points_date).format("DD/MM/YYYY H:mm") : ""}</div>
                    </>
                  ) : "0"}
                </div>
              );
            },
          },
          {
            name: "Número de Contratos",
            keyValue: "operationCount",
            className: `${visibleColums.includes("operationCount") ? "text-center" : "d-none"
              }`,
            isVisible: visibleColums.includes("operationCount"),
            sortable: true,
            sortColumn: updateFilterOrder,
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <div className="text-center">
                  {item.operation_count ? (
                    <>
                      <span>{item.operation_count}</span>
                    </>
                  ) : "0"}
                </div>
              );
            },
          },
          {
            name: "Dual",
            keyValue: "dual",
            className: `${visibleColums.includes("dual") ? "text-center" : "d-none"
              }`,
            isVisible: visibleColums.includes("dual"),
            cellClassName: "text-center",
            render: (item: any) => {
              return (
                <Tooltips
                  title={`${item.dualOperation
                    ? "Ver Contrato Dual"
                    : "Contrato No Dual"
                    }`}
                >
                  <div className="text-center">
                    {item.dualOperation ? (
                      <Button
                        className="btn btns-sm btn-success fs-8"
                        onClick={() =>
                          navigate(
                            `/operations/${item.dualOperation.id}/show`
                          )
                        }
                      >
                        <Icon icon="ArrowForward" color="white" size="sm" />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </Tooltips>
              );
            },
          },
          {
            name: "Acciones",
            className: "min-w-100px text-end",
            isActionCell: true,
          },
        ]}
        actions={[
          {
            title: "Ver",
            buttonType: "link",
            route: (item: any) => `/operations/${item.id}/show`,
            iconPath: "/media/icons/duotune/general/gen021.svg",
            iconColor: "text-primary",
            additionalClasses: `${visibleActions.includes("show") ? "text-primary" : "d-none"
              }`,
            description: "Ver Contrato",
            callback: (item: any) => {
              navigate(`/operations/${item.id}/show`);
            },
          },
          {
            title: "Editar",
            buttonType: onClickEdit ? "icon" : "link",
            route: (item: any) => `/operations/edit/${item.id}`,
            iconColor: "text-info",
            iconPath: "/media/icons/duotune/general/gen055.svg",
            additionalClasses: `${visibleActions.includes("edit") ? "text-primary" : "d-none" }`,
            description: "Editar Contrato",
            callback: (item: any) => {
              if(onClickEdit !== undefined) {
                onClickEdit(item);
                return;
              }
              navigate(`/operations/edit/${item.id}`);
            },
          },
          {
            title: "Editar",
            buttonType: "icon",
            iconColor: "text-info",
            iconPath: "/media/icons/duotune/general/gen055.svg",
            additionalClasses: `${visibleActions.includes("editTab") ? "text-primary" : "d-none"
              }`,
            description: "Editar Contrato",
            callback: (item: any) => {
              if(onClickEdit) {
                onClickEdit(item);
                return;
              }
            },
          },
          {
            title: "Eliminar",
            buttonType: "icon",
            iconColor: "text-danger",
            iconPath: "/media/icons/duotune/general/gen027.svg",
            additionalClasses: `${visibleActions.includes("delete") ? "text-danger" : "d-none"
              }`,
            description: "Eliminar Contrato",
            callback: (item: any) => {
              handleConfirmationAlert({
                title: "Eliminar Contrato",
                text: "¿Está seguro que desea eliminar el contrato?",
                icon: "warning",
                onConfirm: () => {
                  deleteOperation(item.id);
                },
              });
            },
          },
          // {
          //   title: "Hacer Dual",
          //   buttonType: "icon",
          //   iconPath: "/media/icons/duotune/arrows/arr033.svg",
          //   iconColor: "text-success",
          //   additionalClasses: `${visibleActions.includes("delete") ? "text-danger" : "d-none"
          //     }
          //   `,
          //   description: "Hacer Dual",
          //   callback: (item: any) => {
          //     if (item.dualOperation) {
          //       toast.error(
          //         "Este contrato ya tiene un contrato dual asociada"
          //       );
          //       return;
          //     }
          //     handleConfirmationAlert({
          //       title: "Hacer Dual",
          //       text: "¿Está seguro que desea hacer dual el contrato?",
          //       icon: "warning",
          //       onConfirm: () => {
          //         makeOperationDual(item.id);
          //       },
          //     });
          //   },
          // },
          {
            title: "Ver Cliente",
            buttonType: "icon",
            iconPath: "/media/icons/duotune/general/gen021.svg",
            additionalClasses: `${visibleActions.includes("viewClient") ? "text-primary" : "d-none"
              }`,
            description: "Ver Cliente",
            callback: (item: any) => {
              navigate(`/clients/${item.client.id}/show/1`);
            },
          },
          {
            title: "Reaplicar comisión y puntos",
            buttonType: "icon",
            iconColor: "text-blue-600",
            iconPath: "/media/icons/duotune/arrows/arr029.svg",
            additionalClasses: `${visibleActions.includes("reapply") && userCan("reapply_points_and_commission" , "operations") ? "text-primary" : "d-none"}`,
            description: "Reaplicar comisión y puntos",
            callback: (item: any) => {
              handleConfirmationAlert({
                title: "Reaplicar comisión y puntos",
                text: "¿Está seguro que desea reaplicar la comisión y puntos?",
                icon: "warning",
                onConfirm: () => {
                  reApplyCommissionAndPoints(item.id);
                },
              });
            },
          },
        ]}
      />
    ) : (
      <div className="text-center d-flex justify-content-center">
        <Spinner />
      </div>
    )
  }</>;
};

export default OperationTableComponent;
