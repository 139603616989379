import { FC, useEffect, useState } from "react";
import { useUserProfile } from "../../../../hooks/useListData";
import Card, {CardBody, CardLabel, CardTitle} from "../../../../components/bootstrap/Card";
import UserObjectives from "../../../users/user-profile/UserObjectives";
import { OperationService } from "../../../../services/operations/operationService";
import DashboardOperationPoints from "../../main-dashboard/common/DashboardOperationPoints";

interface IOperationMenuProps {
    date: any;
    totalOperations?: number;
}

const OperationMenu: FC<IOperationMenuProps> = ({date, totalOperations}) => {

  const user = useUserProfile();
  const [points, setPoints] = useState<number>(0);

  const getPoints = async () => {
    if (date.start === "" || date.end === "" || !totalOperations) return;
    const operationService = new OperationService();
    const response = (await operationService.getAgentPointsBetweenDates(date.start, date.end, user?.id || "")).getResponseData();
    setPoints(response.data.totalPoints);
  }

  useEffect(() => {
    if (date.start !== "" || date.end !== "") {
      getPoints();
    }
  }, [date]);

  return (
    <>
      {user ? (
        <>
        <DashboardOperationPoints date={date} filterByUser isAgentDashboard />
        <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-6">
                <CardLabel icon='MilitaryTech' iconColor='info'>
                    <CardTitle>Bonus:</CardTitle>
                </CardLabel>
                <div className="row mt-3">
                    <div className="col-6">
                        <p className="text-black">Bonus Disponibles</p>
                    </div>
                    <div className="col-6 text-end">
                        <p className="fw-bold h3">{user?.availableBonuses || 0}</p>
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <CardLabel icon='Assignment' iconColor='secondary' className="mt-2">
                    <CardTitle>Contratos:</CardTitle>
                </CardLabel>
                <div className="row mt-3">
                    <div className="col-6">
                        <p className="text-black">Total de Contratos</p>
                    </div>
                    <div className="col-6 text-end">
                        <p className="fw-bold h3">{totalOperations}</p>
                    </div>
                </div>
                </div>
                </div>
            </CardBody>
        </Card>
        <UserObjectives user={user} points={points}/>
        </>
      ) : null}
    </>
  );
};

export default OperationMenu;
