import {
    FC,
    useEffect,
    useState,
} from "react";
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from "../../../../components/bootstrap/Card";
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "../../../../components/bootstrap/Dropdown";
import Button from "../../../../components/bootstrap/Button";
import classNames from "classnames";
import useDarkMode from "../../../../hooks/useDarkMode";
import {
    CUSTOMTABS,
    customTabType,
} from "../../agent-dashboard/OperationStatistic";
import ToTalMonthExpenses from "../../../../components/TotalMonthExpense";
import {DashboardService} from "../../../../services/dashboard/DashboardService";
import moment from "moment";
import PercentComparison from "../../../../components/extras/PercentComparison";
import {priceFormatEuro} from "../../../../helpers/helpers";
import OperacionPie from "../../agent-dashboard/components/OperationPie";
import compare from "../../../../components/icon/material-icons/Compare";

interface ICommonDashboardIncomeProps {
    activeTab: customTabType;
    date: any;
    showCommission?: boolean;
    agent?: string;
    office?: string;
    business?: string;
}

const CommonDashboardIncome: FC<ICommonDashboardIncomeProps> = ({
                                                                    activeTab,
                                                                    date,
                                                                    showCommission,
                                                                    agent,
                                                                    office,
                                                                    business
                                                                }) => {
    const {darkModeStatus} = useDarkMode();

    const [data, setData] = useState<any>(null);

    const [comparisonTotal, setComparisonTotal] = useState<any>(0);

    const [dateDiff, setDateDiff] = useState<number>(0);

    function compareLabel(amount = 0, name = false) {
        moment.locale("es");
        if (activeTab === CUSTOMTABS.YEARLY) {
            return name ? "year" : moment().subtract(amount, "years").format("YYYY");
        }
        if (activeTab === CUSTOMTABS.MONTHLY) {
            return name
                ? "month"
                : moment()
                    .subtract(amount, "months")
                    .format("MMMM")
                    .charAt(0)
                    .toUpperCase() +
                moment().subtract(amount, "months").format("MMMM").slice(1);
        }
        if (activeTab === CUSTOMTABS.WEEKLY) {
            return name
                ? "week"
                : moment().subtract(amount, "weeks").startOf("week").format("DD") +
                " - " +
                moment().subtract(amount, "weeks").endOf("week").format("DD");
        }
        if (activeTab === CUSTOMTABS.TODAY) {
            return name
                ? "today"
                : moment().subtract(amount, "days").format("DD");
        }
        return "Personalizado";
    }

    const getType = () => {
        if (activeTab === CUSTOMTABS.YEARLY) {
            return "year";
        }
        if (activeTab === CUSTOMTABS.MONTHLY) {
            return "month";
        }
        if (activeTab === CUSTOMTABS.WEEKLY) {
            return "week";
        }
        if (activeTab === CUSTOMTABS.TODAY) {
            return "today";
        }
        return "custom";
    };

    const getCommissionedContracts = async () => {
        const dashboardService = new DashboardService();
        if (date.start === "" || date.end === "") return;
        const response = await (
            await dashboardService.getTotalCommissionedOperationsAndPercentage({
                filter_filters: {
                    dateFrom: date.start,
                    dateTo: date.end,
                    periodType: getType(),
                    dateDifference: dateDiff,
                    ...(agent ? {agent: agent} : {}),
                    ...(office ? {office: office} : {}),
                    ...(business ? {business: business} : {}),
                },

            })
        ).getResponseData();
        if (response.success) {
            setData(response.data);
            setComparisonTotal(response.data.actualTotal)
        }
    };

    useEffect(() => {
        if (date.start !== "" && date.end !== "") {
            getCommissionedContracts();
            setDateDiff(0);
        }
    }, [date, agent, office, business]);

    const getCommissionContractsComparisson = async (dateFrom: string, dateTo: string) => {
        const dashboardService = new DashboardService();
        const response = await (
            await dashboardService.getTotalCommissionedOperationsAndPercentage({
                filter_filters: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    periodType: getType(),
                    dateDifference: dateDiff,
                    ...(agent ? {agent: agent} : {}),
                    ...(office ? {office: office} : {}),
                    ...(business ? {business: business} : {}),
                },

            })
        ).getResponseData();
        if (response.success) {
          setComparisonTotal(response.data.actualTotal !== null ? response.data.actualTotal : 0);
        }
    }

    useEffect(() => {
        if (date.start !== "" && date.end !== "") {
            const timeLabel: any = compareLabel(dateDiff, true);
            let startDateModified = moment(date.start).subtract(dateDiff, timeLabel).set({hours: 0, minutes: 0, seconds: 0});
            let endDateModified = moment(date.end).subtract(dateDiff, timeLabel).set({hours: 23, minutes: 59, seconds: 59});
            getCommissionContractsComparisson(startDateModified.format('YYYY-MM-DD HH:mm:ss'), endDateModified.format('YYYY-MM-DD HH:mm:ss'));
        }
    }, [dateDiff]);

    return (
        <Card stretch>
            <CardHeader>
                <CardLabel icon="PointOfSale" iconColor="success">
                    <CardTitle tag="h4" className="h5">
                        Ingresos
                    </CardTitle>
                    <CardSubTitle>{activeTab}</CardSubTitle>
                </CardLabel>
                {activeTab == CUSTOMTABS.CUSTOM ? (
                    <></>
                ) : (
                    <CardActions>
                        <Dropdown>
                            <DropdownToggle>
                                <Button color="info" icon="Compare" isLight>
                                    Comparado con <strong>{compareLabel(dateDiff)}</strong>
                                </Button>
                            </DropdownToggle>
                            <DropdownMenu isAlignmentEnd size="sm">
                                {
                                    [0, 1, 2].filter((item) => item !== dateDiff).map((item) => (
                                        <DropdownItem key={item}>
                                            <Button
                                                onClick={() => {
                                                    setDateDiff(item);
                                                }}
                                            >
                                                {compareLabel(item)}
                                            </Button>
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </CardActions>
                )}
            </CardHeader>
            <CardBody>
                <div className="row g-4">
                    <div className="col-md-6">
                        <Card
                            className={classNames(
                                "transition-base rounded-2 mb-0 text-dark",
                                {
                                    "bg-l25-warning": !darkModeStatus,
                                    "bg-lo50-warning": darkModeStatus,
                                }
                            )}
                            stretch
                            shadow="sm"
                        >
                            <CardHeader className="bg-transparent">
                                <CardLabel>
                                    <CardTitle tag="h4" className="h5">
                                        Total
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <OperacionPie
                                    activeTab={activeTab}
                                    date={date}
                                    dateDiff={dateDiff}
                                    showCommission={showCommission}
                                />
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card
                            className={classNames(
                                "transition-base rounded-2 mb-4 text-dark",
                                {
                                    "bg-l25-secondary": !darkModeStatus,
                                    "bg-lo50-secondary": darkModeStatus,
                                }
                            )}
                            shadow="sm"
                        >
                            <CardHeader className="bg-transparent">
                                <CardLabel>
                                    <CardTitle tag="h4" className="h5">
                                        Contratos Comisionados
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-center pb-3">
                                    {showCommission ? (
                                        <div className="fw-bold fs-3 mb-0">
                                            {priceFormatEuro(data?.actualTotal || 0)}
                                            {activeTab == CUSTOMTABS.CUSTOM ? (
                                                <></>
                                            ) : (
                                                <PercentComparison
                                                    valueOne={data?.actualTotal}
                                                    valueTwo={comparisonTotal}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="fw-bold fs-3 mb-0">
                                            XXX,XX €
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                        <ToTalMonthExpenses activeTab={activeTab} date={date} name="Total del Gastos"/>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default CommonDashboardIncome;
