import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './redux/store';
import { Provider } from 'react-redux';
import { LoaderProvider } from './components/loader/LoaderProvider';
import { registerServiceWorker } from './sw';




const client = new QueryClient();

const children = (
	<ThemeContextProvider>
		<Provider store={store}>
			<LoaderProvider>
				<QueryClientProvider client={client}>
					<Router>
						{/* <React.StrictMode> */}
							<App />
						{/* </React.StrictMode> */}
					</Router>
				</QueryClientProvider>
			</LoaderProvider>
		</Provider>
	</ThemeContextProvider>
);


// Init aplication when service worker is ready
registerServiceWorker().then(() => {

	const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
	createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	//reportWebVitals();
});

