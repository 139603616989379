import React, {FC, Fragment, useEffect, useState} from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, {OffCanvasBody, OffCanvasHeader, OffCanvasTitle} from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import Spinner from '../../../../components/bootstrap/Spinner'
import StatusSelector from '../../../../components/selectors/status_selector/StatusSelector'
import {ActionStatus} from '../../../../type/actionStatus-type'
import {usePrivilege} from '../../../../components/priviledge/PriviledgeProvider'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import {
    useStatusWalletList,
    useActionStatusWalletList,
    useBusinessList,
    useLeadList,
    useOfficeList,
    useOperationAgentsList,
    useOperationTypeList,
    useOriginList,
    useActionStatusOperationList
} from '../../../../hooks/useListData'
import CustomSelect from '../../../../components/selectors/general/CustomSelect'
import {MultiValue, SingleValue} from 'react-select'
import Tooltips from '../../../../components/bootstrap/Tooltips'
import CreateOptionPostalCodeExcluded from '../../../../components/bootstrap/forms/CreateOptionPostalCodeExcluded'
import CreateOptionPostalCodeIncluded from '../../../../components/bootstrap/forms/CreateOptionPostalCodeIncludes'
import {CampaignService} from "../../../../services/campaigns/campaignService";
import moment from "moment";

interface IWalletFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any
}

const WalletFilters: FC<IWalletFiltersProps> = ({updateFilters, filters, resetFilters}) => {

    const [filterMenu, setFilterMenu] = useState(false);

    const [campaigns, setCampaigns] = useState<any>([]);
    const [campaignsSelected, setCampaignsSelected] = useState<any>([]);

    const {userCan} = usePrivilege();

    const statusWalletList = useStatusWalletList();

    const actionStatusWalletList = useActionStatusWalletList();

    const operationStatusList = useActionStatusOperationList(true);

    const businessList = useBusinessList({filter_filters: {active: 1}, all: true}, true);

    const operationsTypeList = useOperationTypeList({filter_filters: {active: 1}, all: true}, true);

    //const originList = useOriginList({filter_filters : {active : 1}, all: true}, true);

    //const leadList = useLeadList({filter_filters: { active : 1 } , all: true}, true);

    const handleTextChange = (search: string) => {
        updateFilters({search_text: search});
    };

    const onChangeAgents = (newValue: MultiValue<unknown>) => {
        let agents = newValue.map((value: any) => value.value);
        updateFilters({agents: agents});
    }

    const onChangeOperationStatus = (newValue: MultiValue<unknown>) => {
        let status = newValue.map((value: any) => value.value);
        updateFilters({statusesList: status});
    }

    let clientTypeOptions = [
        {value: 'all', label: 'Todos los Clientes'},
        {value: 'particular', label: 'Particular'},
        {value: 'empresa', label: 'Empresa'}
    ];

    const getDepartments = async () => {
        const response = await (await (new CampaignService()).getCampaignsSelect()).getResponseData();
        if (response.success) {
            setCampaigns(response.data.campaigns.map((campaign: any) => {
                return {
                    value: campaign.id,
                    label: campaign.name,
                }
            }));
        } else {
            setCampaigns([]);
        }
    }

    useEffect(() => {
        getDepartments();
        return () => {
            setCampaigns([]);
        }
    }, []);

    useEffect(() => {
        console.log('filter', filters)
    }, [filters])

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary'/>
            </label>
            <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange}
                               defaultValue={filters.filter_filters.search_text || ''}/>

            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='current_wallet_status'>
                                <StatusSelector isMulti entity='wallet' name="Estados de Cartera Actuales"
                                                defaultSelected={filters.filter_filters.current_wallet_status ? filters.filter_filters.current_wallet_status : []}
                                                onChange={(value: ActionStatus[]) => {
                                                    updateFilters({current_wallet_status: value})
                                                }} data={statusWalletList}></StatusSelector>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='current_action_status_wallet'>
                                <StatusSelector isMulti entity='action_wallet' name="Estados de Acción Actuales"
                                                defaultSelected={filters.filter_filters.current_action_status_wallet ? filters.filter_filters.current_action_status_wallet : []}
                                                onChange={(value: ActionStatus[]) => {
                                                    updateFilters({current_action_status_wallet: value})
                                                }} data={actionStatusWalletList}></StatusSelector>
                            </FormGroup>
                        </div>
                        {userCan('list', 'campaigns') && (
                            <div className='col-12'>
                                {/** Show filter by campaign when the user has list_all permission */}
                                <>
                                    {campaigns && campaigns.length > 0 && (
                                        <FormGroup id='campaign' label={'Departamento asignado'}>
                                            <CustomSelect
                                                onChange={(selected: MultiValue<unknown>) => {
                                                    setCampaignsSelected(selected)
                                                    updateFilters({campaigns: selected.map((value: any) => value.value)})
                                                }}
                                                defaultValue={filters.filter_filters.campaigns ? campaigns.filter((campaign: any) => filters.filter_filters.campaigns.includes(campaign.value)) : []}
                                                placeholder='Departamentos...' options={campaigns} is_multi={true}/>
                                        </FormGroup>
                                    )}
                                </>
                            </div>
                        )}
                        <hr className='col-12'/>

                        <div className='col-12 mt-0'>
                            <FormGroup id='filter16' label='Filtrado Estado y Fechas' className='text-center'
                                       iconLabel={
                                           <Tooltips
                                               title='Para filtrar por fechas, seleccione uno o varios estados y el rango de fechas'>
                                               <Icon icon='Info' color='info' size='lg' className='ms-2'/>
                                           </Tooltips>
                                       }>
                                <CustomSelect options={operationStatusList} is_multi={true}
                                              defaultValue={filters.filter_filters.statusesList ? operationStatusList.filter((status: any) => filters.filter_filters.statusesList.includes(status.value)) : []}
                                              placeholder='Ha estado en...' onChange={onChangeOperationStatus}
                                              formatOptionLabel={(option: any) => <div className="d-flex justify-content-between align-items-center">
                                                {option.label}
                                                <div>
                                                    <svg viewBox="0 0 24 24" fill={option.color} className="svg-icon--material svg-icon fs-5 me-2" data-name="Material--Lens">
                                                        <path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8z" opacity="0.3"></path>
                                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                                    </svg>
                                                    {option.active ? 
                                                    <svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon svg-icon-3x text-primary fs-5" data-name="Material--Done">
                                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
                                                    </svg>
                                                    : 
                                                    <svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon svg-icon-3x text-danger fs-5" data-name="Material--Clear">
                                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                                                    </svg>
                                                    }
                                                </div>
                                              </div>}/>
                                <div className='row g-4 mt-1'>
                                    <FormGroup label='Fecha Inicio' className='col-md-6'>
                                        <Input type='date'
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                   if (!e.target.value) {
                                                       updateFilters({fromStatusDate: null})
                                                       return
                                                   }
                                                   updateFilters({
                                                       fromStatusDate: moment(e.target.value).set({
                                                           hour: 0,
                                                           minute: 0,
                                                           second: 0
                                                       }).format('YYYY-MM-DD HH:mm:ss')
                                                   })
                                               }}
                                               value={filters.filter_filters.fromStatusDate ? moment(filters.filter_filters.fromStatusDate).format('YYYY-MM-DD') : ''}
                                               ariaLabel='Default select example'
                                               placeholder='Fecha Inicial...'/>
                                    </FormGroup>

                                    <FormGroup label='Fecha Final' className='col-md-6'>
                                        <Input type='date'
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                   if (!e.target.value) {
                                                       updateFilters({toStatusDate: null})
                                                       return
                                                   }
                                                   updateFilters({
                                                       toStatusDate: moment(e.target.value).set({
                                                           hour: 23,
                                                           minute: 59,
                                                           second: 59
                                                       }).format('YYYY-MM-DD HH:mm:ss')
                                                   })
                                               }}
                                               value={filters.filter_filters.toStatusDate ? moment(filters.filter_filters.toStatusDate).format('YYYY-MM-DD') : ''}
                                               ariaLabel='Default select example'
                                               placeholder='Fecha Final...'/>
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </div>
                        <hr className='col-12'/>

                        <div className='col-12 mt-0'>
                            <FormGroup id='filter16' label='Filtrado por Fechas' className='text-center' iconLabel={
                                <Tooltips
                                    title='Para filtrar por fechas, seleccione el tipo de fecha y el rango de fechas'>
                                    <Icon icon='Info' color='info' size='lg' className='ms-2'/>
                                </Tooltips>
                            }>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateFilters({dateType: e.target.value})
                                    }}
                                    value={filters.filter_filters.dateType || ''}
                                    id='show-select' ariaLabel='Default select example' placeholder='Tipo de Fecha...'
                                    list={[{
                                        value: 'createdAt',
                                        label: 'Fecha de Creacion',
                                        text: 'Fecha de Creacion'
                                    }, {
                                        value: 'clawbackDate',
                                        label: 'Fecha de Clawback',
                                        text: 'Fecha de Clawback'
                                    },
                                        {
                                            value: 'renewalDate',
                                            label: 'Fecha de Renovación',
                                            text: 'Fecha de Renovación'
                                        },
                                        {
                                            value: 'activationDate',
                                            label: 'Fecha de Activación',
                                            text: 'Fecha de Activación'
                                        }
                                    ]
                                    }
                                />
                                <div className='row g-4 mt-1'>
                                    <FormGroup label='Fecha Inicio' className='col-md-6'>
                                        <Input type='date'
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                   if (!e.target.value) {
                                                       updateFilters({from: null})
                                                       return
                                                   }
                                                   updateFilters({
                                                       from: moment(e.target.value).set({
                                                           hour: 0,
                                                           minute: 0,
                                                           second: 0
                                                       }).format('YYYY-MM-DD HH:mm:ss')
                                                   })
                                               }}
                                               value={filters.filter_filters.from ? moment(filters.filter_filters.from).format('YYYY-MM-DD') : ''}
                                               ariaLabel='Default select example'
                                               placeholder='Fecha Inicial...'/>
                                    </FormGroup>

                                    <FormGroup label='Fecha Final' className='col-md-6'>
                                        <Input type='date'
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                   if (!e.target.value) {
                                                       updateFilters({to: null})
                                                       return
                                                   }
                                                   updateFilters({
                                                       to: moment(e.target.value).set({
                                                           hour: 23,
                                                           minute: 59,
                                                           second: 59
                                                       }).format('YYYY-MM-DD HH:mm:ss')
                                                   })
                                               }}
                                               value={filters.filter_filters.to ? moment(filters.filter_filters.to).format('YYYY-MM-DD') : ''}
                                               ariaLabel='Default select example'
                                               placeholder='Fecha Final...'/>
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Nombre'>
                                <Input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({name: e.target.value})}
                                    value={filters.filter_filters.name} id='name' placeholder='Nombre...'
                                    autoComplete='off'/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter2' label='Localidad'>
                                <Input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({locality: e.target.value})}
                                    value={filters.filter_filters.locality} id='locality' placeholder='Localidad...'
                                    autoComplete='off'/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter3' label='Provincia'>
                                <Input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({province: e.target.value})}
                                    value={filters.filter_filters.province} id='province' placeholder='Provincia...'
                                    autoComplete='off'/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter4' label='Tipo de Contrato'
                                       key={filters.filter_filters.operation_type}>
                                {(operationsTypeList) ? (
                                    <Select
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({operation_type: e.target.value})}
                                        value={filters.filter_filters.operation_type} ariaLabel='Default select example'
                                        placeholder='Tipo de Contrato...' list={operationsTypeList}
                                    />
                                ) : (<Spinner isSmall/>)}
                            </FormGroup>
                        </div>
                        {/*
                            <div className='col-12'>
                              <FormGroup id='filter7' label='Tipo de Trámite' key={filters.filter_filters.lead}>
                                {(leadList) ? (
                                    <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({lead: e.target.value})}
                                            value={filters.filter_filters.lead} ariaLabel='Default select example'
                                            placeholder='Tipo de Trámite...' list={leadList}
                                    />
                                ) : (<Spinner isSmall/>)}
                              </FormGroup>
                            </div>
                            */}
                        {/*
                            <div className='col-12'>
                              <FormGroup id='filter8' label='Origen Lead' key={filters.filter_filters.origin}>
                                {(leadList) ? (
                                    <Select
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({origin: e.target.value})}
                                        value={filters.filter_filters.origin} ariaLabel='Default select example'
                                        placeholder='Origen Lead...' list={originList}
                                    />
                                ) : (<Spinner isSmall/>)}
                              </FormGroup>
                            </div>
                        */}

                        <div className='col-12'>
                            <FormGroup id='filter11' label='Clientes' key={filters.filter_filters.favourite}>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({favourite: parseInt(e.target.value)})}
                                    value={filters.filter_filters.favourite}
                                    id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                                    list={[{value: 2, label: 'Todos los Clientes', text: 'Todos los Clientes'}, {
                                        value: 1,
                                        label: 'Clientes favoritos',
                                        text: 'Clientes favoritos'
                                    }, {value: 0, label: 'Clientes no favoritos', text: 'Clientes no favoritos'}]}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter12' label='CUPS'>
                                <Input
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({cups: e.target.value})}
                                    value={filters.filter_filters.cups} id='cups' placeholder='CUPS...'
                                    autoComplete='off'/>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='filter13' label='Comercializadora' key={filters.filter_filters.business}>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({business: e.target.value})}
                                    value={filters.filter_filters.business} ariaLabel='Default select example'
                                    placeholder='Elegir Comercializadora...' list={businessList}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='filter14' label='Tipo de Cliente'
                                       key={filters.filter_filters.clientTypeList}>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({clientTypeList: e.target.value})}
                                    value={filters.filter_filters.clientTypeList} ariaLabel='Default select example'
                                    placeholder='Elegir Tipo de Cliente...' list={clientTypeOptions}
                                />
                            </FormGroup>
                        </div>


                        <div className='col-12'>
                            <FormGroup id='filter15' label='Codigos Postales Permitidos'
                                       key={filters.filter_filters.postalCodesIncludedList}>
                                <CreateOptionPostalCodeIncluded className="mt-3 modify-colors-border mb-2"
                                                                defaultValue={filters.filter_filters.postalCodesIncludedList}
                                                                filters={filters} updateFilters={updateFilters}/>
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='filter15' label='Codigos Postales No Permitidos'
                                       key={filters.filter_filters.postalCodesExcludedList}>
                                <CreateOptionPostalCodeExcluded className="mt-3 modify-colors-border mb-2"
                                                                defaultValue={filters.filter_filters.postalCodesExcludedList}
                                                                filters={filters} updateFilters={updateFilters}/>
                            </FormGroup>
                        </div>

                    </div>
                    <div className='col-12'>
                        <Button
                            className='mt-4'
                            color="storybook"
                            isLight
                            onClick={() => {
                                resetFilters(50, true)
                            }}
                        >
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default WalletFilters