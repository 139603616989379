import { AxiosResponse } from "axios";
import { Lead, NewLead } from "../../type/lead-type";
import { RestServiceConnection } from '../restServiceConnection';
import { Filters } from "../../type/apiResponse-type";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

const LEAD_ENDPOINT = '/leads';

export class LeadService extends RestServiceConnection {

    createLead = async (lead: NewLead) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: LEAD_ENDPOINT + '/create',
            data: lead
        }, true);
        return this;
    }

    editLead = async (lead: Lead) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: LEAD_ENDPOINT + '/edit',
            data: lead
        }, true);
        return this;
    }

    getLeads = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: LEAD_ENDPOINT + '/list',
            data: filters, 
        }, true) as AxiosResponse;
        return this;
    }

    toggleStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: LEAD_ENDPOINT + '/toggle',
            data: {
                lead: id,
                active: status
            }, 
        }, true) as AxiosResponse;
        return this;
    }
    
    deleteLead = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: LEAD_ENDPOINT + '/delete',
            data: {
                lead: id
            },
        }, true);
        return this;
    }
}