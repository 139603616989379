import { FC } from "react";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Icon from "../../../components/icon/Icon";
import {
  CheckCircleOutline,
  HighlightOff,
} from "../../../components/icon/material-icons";
import { CustomTable } from "../../../components/table/CustomTable";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import Badge from "../../../components/bootstrap/Badge";
import { Link } from "react-router-dom";

interface IOperationProductsDataTabProps {
  products: any;
  maxheight?: string;
}

const OperationProductsDataTab: FC<IOperationProductsDataTabProps> = ({
  products,
  maxheight
}) => {
  const { userCan } = usePrivilege();

  return (
    <>
      <CustomTable
        data={products ? products : null}
        pagination={true}
        className={`table table-hover ${maxheight ? maxheight : ""}`}
        columns={[
          {
            name: "Nombre",
            keyValue: "name",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center fw-semibold">
                  <Link to={`/products/${element.product.id}/show`} color="primary">
                    {element.product.description && (
                      <Tooltips title={element.product.description}>
                        <Icon
                          icon="Info"
                          color="info"
                          size={"lg"}
                          className="me-2"
                        />
                      </Tooltips>
                    )}
                    {element.product.name}
                  </Link>
                </div>
              );
            },
          },
          {
            name: "Tipo",
            keyValue: "name",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center fw-semibold">
                  {element.product.productType.name}
                </div>
              );
            },
          },
          {
            name: "Tarifa",
            keyValue: "fee",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center fw-semibold">
                  {element.product.fee.name}
                </div>
              );
            },
          },
          {
            name: "Cups",
            keyValue: "cups",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.cups ?? "No tiene"}
                </div>
              );
            },
          },
          {
            name : "Comercializadora",
            keyValue: "business",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.product.business.name}
                </div>
              );
            },
          },
          {
            name: "Servicios",
            keyValue: "services",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {
                    element.services.map((service: any) => (
                      <div>
                        <Badge
                          key={element.id + "-" + service.id}
                          color="primary"
                          rounded={"pill"}
                          isLight
                        >
                          {service.service.name}
                        </Badge>
                      </div>
                   ))
                }
                </div>
              );
            },
          },
          {
            name: "Puntos",
            keyValue: "points",
            className: "text-center",
            cellClassName: "text-center",
            isVisible: userCan("show_points", "operations"),
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.points ?? 0}
                </div>
              );
            },
          },
          {
            name: "Comisión Total",
            keyValue: "commission",
            className: "text-center",
            cellClassName: "text-center",
            isVisible: userCan("show_commissions", "operations"),
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.commission ? element.commission : 0 } €
                </div>
              );
            },
          },
          {
            name: "Consumo anual:",
            keyValue: "consumption",
            className: "text-center",
            cellClassName: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.consumption ?? 0} kW
                </div>
              );
            },
          },
          {
            name: "Potencia (Periodos):",
            keyValue: "powers",
            className: "text-center",
            cellClassName: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  <ul className="list-unstyled">
                    {element.powerP1 != null && (
                      <li>P1: {element.powerP1} kW</li>
                    )}
                    {element.powerP2 != null && (
                      <li>P2: {element.powerP2} kW</li>
                    )}
                    {element.powerP3 != null && (
                      <li>P3: {element.powerP3} kW</li>
                    )}
                    {element.powerP4 != null && (
                      <li>P4: {element.powerP4} kW</li>
                    )}
                    {element.powerP5 != null && (
                      <li>P5: {element.powerP5} kW</li>
                    )}
                    {element.powerP6 != null && (
                      <li>P6: {element.powerP6} kW</li>
                    )}
                  </ul>
                </div>
              );
            },
          },
          {
            name: "Negocio",
            keyValue: "businessEnabled",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="fs-7 text-center">
                  {element.product.businessEnabled ? (
                    <CheckCircleOutline
                      className="text-success"
                      width="30px"
                      height="30px"
                    />
                  ) : (
                    <HighlightOff
                      className="text-danger"
                      width="30px"
                      height="30px"
                    />
                  )}
                </div>
              );
            },
          },
          {
            name: "Individual",
            keyValue: "individualEnabled",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="fs-7 text-center">
                  {element.product.individualEnabled ? (
                    <CheckCircleOutline
                      className="text-success"
                      width="30px"
                      height="30px"
                    />
                  ) : (
                    <HighlightOff
                      className="text-danger"
                      width="30px"
                      height="30px"
                    />
                  )}
                </div>
              );
            },
          },
          {
            name: "Dirección",
            keyValue: "address",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.address}
                </div>
              );
            },
          },
          {
            name: "Ciudad",
            keyValue: "town",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.town}
                </div>
              );
            },
          },
          {
            name: "Provincia",
            keyValue: "province",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.province}
                </div>
              );
            },
          },
          {
            name: "Código Postal",
            keyValue: "postalcode",
            className: "text-center",
            render: (element: any) => {
              return (
                <div className="d-flex justify-content-center">
                  {element.postalcode}
                </div>
              );
            },
          }
        ]}
      />
    </>
  );
};

export default OperationProductsDataTab;
