import { Fragment, useContext } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import WalletList from "./wallet-list/WalletList";

const WalletListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <WalletList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default WalletListWrapper