import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import { CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import Spinner from "../../../components/bootstrap/Spinner";
import { toast } from "react-toastify";
import CampaignEditForm from "../CampaignEditForm";
import { CampaignService } from "../../../services/campaigns/campaignService";

const CampaignEdit: FC = (props) => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [campaigntoEdit , setCampaigntoEdit] = useState<any>(null);
	const { id } = useParams();

	const getCampaigntoEdit = async () => {
		try {
			let response = await (await (new CampaignService).getCampaignById(id ? id : '')).getResponseData()
			if (response.success) {
				setCampaigntoEdit(response.data);
			} else {
				setError(response.message);
			}
		} catch (error: any) {
			console.log(error)
		}
	}

	const reloadCampaigntoEdit = async () => {
		try {
			let response = await (await (new CampaignService).getCampaignById(id ? id : '')).getResponseData()
			if (response.success) {
				setCampaigntoEdit(response.data);
			} else {
				setError(response.message);
			}
		} catch (error: any) {
			console.log(error)
		}
	}

	const handleEdit = async (values: any) => {
		setLoading(true);
		values.campaign = id;
		try {
			setLoading(true)
			let response = await (await (new CampaignService).updateCampaign(values)).getResponseData()
			if (response.success) {
				navigate(-1);
			} else {
				setError(response.message);
			}
		} catch (error: any) {
			console.log(error)
		} finally {
			setLoading(false);
		}

	}
	useEffect(() => {
		if (id) {
			getCampaigntoEdit();
		}
	}, [id])

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
					</Button>
					<CardLabel icon="Campaign" iconColor="info">
						<CardTitle>{'Editar Departamento'}</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
					{id && campaigntoEdit != undefined ? <CampaignEditForm submit={handleEdit} isLoading={loading} campaignData={campaigntoEdit} reloadCampaign={reloadCampaigntoEdit}/> : <Spinner/> }
			</Page>
		</Fragment>
	)
}

export default CampaignEdit;
