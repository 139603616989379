import {ChangeEvent, Fragment, useCallback, useContext, useEffect, useState} from "react";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, {SubHeaderLeft, SubHeaderRight, SubheaderSeparator} from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import Card, {CardTitle} from "../../../components/bootstrap/Card";
import {CustomTable} from "../../../components/table/CustomTable";
import {PrivilegeContext} from "../../../components/priviledge/PriviledgeProvider";
import {LeadsApiResponse} from "../../../type/lead-type";
import moment from "moment";
import {useFiltersPR} from "../../../components/providers/FiltersProvider";
import {WalletService} from "../../../services/wallet/walletService";
import Badge from "../../../components/bootstrap/Badge";
import StatusWalletDropdown from "../../../components/StatusWalletDropdown";
import {StatusService} from "../../../services/status/statusService";
import {ActionStatus} from "../../../type/actionStatus-type";
import WalletFilters from "./wallet-options/WalletFilters";
import Button from "../../../components/bootstrap/Button";
import {toast} from "react-toastify";
import {useLoader} from "../../../components/loader/LoaderProvider";
import {Link, useNavigate} from "react-router-dom";
import {ClickToCall} from "../../../components/calls/ClickToCall";
import WalletAssignDepartmentsModal from "../wallet-assign/walletAssignDepartmentsModal";
import useHandleErrors from "../../../hooks/useHandleErrors";
import Tooltips from "../../../components/bootstrap/Tooltips";
import {handleConfirmationAlert} from "../../../utils/ConfirmationAlert";

const WalletList = () => {

    // NAVIGATION

    const navigate = useNavigate();

    // USER CAN

    const {userCan} = useContext(PrivilegeContext)

    //FILTERS

    const {
        filters,
        updateFilters,
        updateFilterOrder,
        updatePage,
        updatePageSize,
        resetFilters,
        checkIfUrlHasFilters
    } = useFiltersPR();

    //STATES

    const [actionStatuses, setActionStatuses] = useState<any[]>([]);

    const [actionStatusesWallet, setActionStatusesWallet] = useState<any[]>([]);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const [isOpened, setIsOpened] = useState(false);

    // UTILS
    const {handleErrors} = useHandleErrors()

    // LOADER

    const {showLoading, hideLoading} = useLoader();

    // FETCH WALLET ENTRIES

    /**
     *
     * @EN This function is responsible for fetching the wallet entries
     * @ES Esta función se encarga de obtener las entradas de billetera
     *
     * @returns Fetch the wallet entries
     */

    const fetchWalletEntries = useCallback(async () => {
        const productService = new WalletService();
        const response = await productService.getEntries(filters);

        try {
            handleErrors(response.getResponseData());
        } catch (error: any) {
            console.error(error.message)
        }

        return response.getResponseData() as LeadsApiResponse;

    }, [filters]);

    const [data, loading, error, refetch] = useFetch(fetchWalletEntries);

    // FUNCTIONS

    /**
     *
     * @EN Function to get the list of action statuses
     * @ES Función para obtener la lista de estados de acción
     *
     * @returns List of Action Statuses
     */

    const getActionStatusList = async (): Promise<ActionStatus[] | undefined> => {
        const response = await (await (new StatusService()).getChangeActionStatusesWallet({
            filter_filters: {
                entity: 'wallet',
                active: 1
            }, all: true
        })).getResponseData();
        if (response.success) {
            setActionStatuses(response.data.actionStatuses);
        } else {
            return [];
        }
    }

    /**
     *
     * @EN Function to handle the checkbox
     * @ES Función para manejar los checkbox
     *
     * @param checked
     */
    const _handleChangeCheckedItems = (id: string, event: React.ChangeEvent<HTMLInputElement>, element: any) => {
        const {checked} = event.target;
        let selectedItemsCopy = [...selectedItems];
        if (checked) {
            selectedItemsCopy.push(id);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedItems(selectedItemsCopy);
    }

    /**
     *
     * @EN Function to handle the select all checkbox
     * @ES Función para manejar el checkbox de seleccionar todo
     *
     * @param checked
     */
    const _handleSelectAllItems = (checked: boolean) => {
        if (checked) {
            setSelectedItems(data.entries.map((item: any) => item.id));
        } else {
            setSelectedItems([]);
        }
    }

    /**
     *
     * @EN Function to get the list of action statuses
     * @ES Función para obtener la lista de estados de acción
     *
     * @returns List of Action Statuses
     */

    const getActionStatusWalletList = async (): Promise<ActionStatus[] | undefined> => {
        const response = await (await (new StatusService()).getChangeActionStatusesWallet({
            filter_filters: {
                entity: 'action_wallet',
                active: 1
            }, all: true
        })).getResponseData();
        if (response.success) {
            setActionStatusesWallet(response.data.actionStatuses);
        } else {
            return [];
        }
    }

    /**
     *
     * @ES Función para exportar la cartera
     * @EN Function to export the wallet
     *
     * @returns Export Wallet
     */

    const _handleExportWallet = () => async () => {
        showLoading("Exportando Cartera", <>Espere mientras se genera el archivo de exportación</>);

        try {
            const response = await (await (new WalletService()).exportEntries(filters));
            // generate blob and download
            const blob = new Blob([response?.getResponse()?.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Cartera.xlsx`;
            link.click();
            toast.success("Cartera exportada correctamente");
        } catch (error: any) {
            toast.error("Error al exportar la cartera" + error.message);
        } finally {
            hideLoading();
        }
    }

    const _handleDeleteWallets = async () => {
      await handleConfirmationAlert({
        title: '¡CUIDADO! ELIMINAR de la cartera no se puede revertir',
        text: '¿Está seguro que desea eliminar los elementos seleccionados de la cartera?',
        icon: 'warning',
        onConfirm: async () => {
          showLoading("Eliminando de la cartera", <>Espere mientras se eliminan los elementos seleccionados de la cartera</>);
          try {
            const response = await (await (new WalletService()).deleteEntries(selectedItems)).getResponseData();
            if (response.success) {
              toast.success("Elementos eliminados correctamente");
              refetch();
            } else {
              toast.error("Error al eliminar los elementos de la cartera");
            }
          } catch (error: any) {
            toast.error("Error al eliminar los elementos de la cartera" + error.message);
          } finally {
            hideLoading();
          }
        }
      });
    }

    // USE EFFECTS

    useEffect(() => {
        getActionStatusList();
        getActionStatusWalletList();
        checkIfUrlHasFilters();
    }, []);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Cartera</CardTitle>
                        <SubheaderSeparator/>
                        {/* Add refresh button */}
                        <Tooltips title={'Recargar resultados'}>
                            <Button color="light" size={'lg'} isLight icon="Refresh"
                                    onClick={() => refetch()}></Button>
                        </Tooltips>
                        {userCan("export", "wallet") && data ?
                            <Tooltips title={'Exportar'}>
                                <Button color="light" size={'lg'} isLight icon="FileDownload"
                                        onClick={_handleExportWallet()}></Button>
                            </Tooltips>
                            : <></>
                        }
                        {data && selectedItems.length > 0 && userCan("assign-departments", "wallet") ?
                            <Tooltips title={'Asignar a Departamento'}>
                                <Button color="light" size={'lg'} isLight icon="PersonAdd"
                                        onClick={() => setIsOpened(true)}></Button>
                            </Tooltips>
                            : <></>
                        }
                        {data && selectedItems.length > 0 && userCan("delete_wallet", "wallet") ?
                          <Tooltips title={'ELIMINAR registros seleccionados de la cartera'}>
                            <Button color="light" size={'lg'} isLight icon="Delete"
                                    onClick={() => _handleDeleteWallets()}></Button>
                          </Tooltips>
                          : <></>
                      }
                        <SubheaderSeparator/>
                        <div className="text-primary fw-bold">
                            Nº Resultados: {data ? data.totalRegisters : 0}
                        </div>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <WalletFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters}/>
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {
                        data ? (
                            <CustomTable
                                data={data.entries}
                                pagination={true}
                                defaultLimit={filters.limit || 50}
                                selectableItems
                                onSelectAllItems={_handleSelectAllItems}
                                onChangeCheckedItems={(id: string, event: ChangeEvent<HTMLInputElement>, item: any) => _handleChangeCheckedItems(id, event, item)}
                                defaultOrder={filters.filter_order || undefined}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: data as LeadsApiResponse ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                className={"table table-hover p-3 fs-8 min-h-500"}
                                columns={
                                    [
                                        {
                                            name: "Estado",
                                            keyValue: "active",
                                            className: "text-center",
                                            isVisible: userCan("show_wallet_status", "wallet"),
                                            render: (element: any) => {
                                                if (element.currentWalletStatus == null) return <></>;
                                                return (
                                                    <div className="d-flex justify-content-center p-0">
                                                        <StatusWalletDropdown
                                                            onChange={() => {
                                                                refetch && refetch();
                                                            }}
                                                            key={element.id + "-status"}
                                                            itemId={element.id}
                                                            statuses={actionStatuses}
                                                            status={
                                                                element.currentWalletStatus != null
                                                                    ? element.currentWalletStatus
                                                                    : null
                                                            }
                                                            disabled={!userCan("change-status", "wallet")}
                                                            additionalInfo={
                                                                element.statusDate
                                                                    ? moment(element.statusDate.date).format(
                                                                        "DD/MM/yyyy HH:mm"
                                                                    )
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Ultimo Estado Contrato",
                                            keyValue: "active",
                                            className: "text-center",
                                            isVisible: userCan("show_wallet_status", "wallet"),
                                            render: (element: any) => {

                                                if (element.currentWalletStatus == null) return <></>;
                                                if (element.lastOperationId == null) return (
                                                    <div className={'fw-bold text-danger'}>Operación Eliminada</div>);

                                                return (
                                                    <div className="d-flex justify-content-center p-0">
                                                        <StatusWalletDropdown
                                                            onChange={() => {
                                                                refetch && refetch();
                                                            }}
                                                            key={element.id + "-status"}
                                                            itemId={element.id}
                                                            statuses={[]}
                                                            status={
                                                                element.lastOperationId.currentActionStatus != null
                                                                    ? element.lastOperationId.currentActionStatus
                                                                    : null
                                                            }
                                                            disabled={true}
                                                            additionalInfo={
                                                                element.lastOperationId.currentActionStatusDate
                                                                    ? moment(element.lastOperationId.currentActionStatusDate.date).format(
                                                                        "DD/MM/yyyy HH:mm"
                                                                    )
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Estado de Accion",
                                            keyValue: "active",
                                            className: "text-center",
                                            isVisible: userCan("show_action_wallet_status", "wallet"),
                                            render: (element: any) => {
                                                if (element.currentActionStatusWallet == null) return <></>;
                                                return (
                                                    <div className="d-flex justify-content-center p-0">
                                                        <StatusWalletDropdown
                                                            onChange={() => {
                                                                refetch && refetch();
                                                            }}
                                                            key={element.id + "-status"}
                                                            itemId={element.id}
                                                            statuses={actionStatusesWallet}
                                                            status={
                                                                element.currentActionStatusWallet != null
                                                                    ? element.currentActionStatusWallet
                                                                    : null
                                                            }
                                                            disabled={!userCan("change_action_wallet_status", "wallet")}
                                                            additionalInfo={
                                                                element.actionStatusDate
                                                                    ? moment(element.actionStatusDate.date).format(
                                                                        "DD/MM/yyyy HH:mm"
                                                                    )
                                                                    : undefined
                                                            }
                                                            actionStatus
                                                        />
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Agente",
                                            keyValue: "agentName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="text-center">
                                                        {element.agentName}
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            name: "Dep.Asignado",
                                            keyValue: "campaignName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="text-center">
                                                        {element.campaign ? element.campaign.name : "NO ASIGNADO"}
                                                        {element.assignedAt && (
                                                            <div>{moment(element.assignedAt.date).format("DD/MM/YYYY HH:mm")}</div>)}
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            name: "Tipo de Operación",
                                            keyValue: "operationTypeName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.operationTypeName}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Producto",
                                            keyValue: "productName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div
                                                        className="d-flex flex-column justify-content-center text-center p-0">
                                                        <div className="fw-bold">
                                                            {element.businessName}
                                                        </div>
                                                        {element.productName || "-"}
                                                        {element.services !== null && element.services.split(",").map((service: any) => (
                                                            <div>
                                                                <Badge
                                                                    key={element.id + "-" + service}
                                                                    color="primary"
                                                                    rounded={"pill"}
                                                                    isLight
                                                                >
                                                                    {service}
                                                                </Badge>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Cliente",
                                            keyValue: "clientName",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center flex-column fw-bold">
                                                        {element.clientId !== null ?
                                                            <Link to={`/clients/${element.clientId}/show`}
                                                                  color="primary">
                                                                {element.clientName}
                                                            </Link>
                                                            :
                                                            element.clientName
                                                        }
                                                        {element.clientType !== 'PARTICULAR' && (
                                                            <div>
                                                                <Badge color="info" rounded={"pill"} isLight>
                                                                    {element.clientType}
                                                                </Badge>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <Badge color="dark" rounded={"pill"} isLight>
                                                                CP : {element.supplyPostalCode}
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Telefono",
                                            keyValue: "clientPhone",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.clientPhone ? (
                                                            <ClickToCall telephone={element.clientPhone}/>) : "-"}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Telefono 2",
                                            keyValue: "clientPhone2",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.clientPhone2 ? (
                                                            <ClickToCall telephone={element.clientPhone2}/>) : "-"}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "DNI",
                                            keyValue: "clientDni",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.clientDni}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Cups",
                                            keyValue: "cups",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            cellClassName: "text-center",
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.cups}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            name: "Creación",
                                            keyValue: "createdAt",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div
                                                    className="fs-8 text-center">{moment(item.createdAt?.date).format("DD/MM/YYYY H:mm")}</div>;
                                            }
                                        },
                                        {
                                            name: "Activación",
                                            keyValue: "activationDate",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (item: any) => {
                                                return item.activationDate ? <div
                                                        className="fs-8 text-center">{moment(item.activationDate?.date).format("DD/MM/YYYY H:mm")}</div> :
                                                    <div className="fs-8 text-center">-</div>;
                                            }
                                        },
                                        {
                                            name: "Clawback",
                                            keyValue: "clawbackDate",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-8 text-center">
                                                    {moment(item.clawbackDate?.date).format("DD/MM/YYYY H:mm")}
                                                    {moment(item.clawbackDate?.date).isBefore(moment()) ?
                                                        <Badge color="warning" className="fs-8" rounded={"pill"}
                                                               isLight>{
                                                            moment(item.clawbackDate?.date).fromNow().charAt(0).toUpperCase() + moment(item.clawbackDate?.date).fromNow().slice(1)
                                                        }</Badge> :
                                                        <Badge color="success" rounded={"pill"} className="fs-8"
                                                               isLight>{
                                                            moment(item.clawbackDate?.date).fromNow().charAt(0).toUpperCase() + moment(item.clawbackDate?.date).fromNow().slice(1)
                                                        }</Badge>
                                                    }
                                                </div>;
                                            }
                                        },
                                        {
                                            name: "Renovación",
                                            keyValue: "renewalDate",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: "text-center",
                                            render: (item: any) => {
                                                return <div className="fs-8 text-center">
                                                    {moment(item.renewalDate?.date).format("DD/MM/YYYY H:mm")}
                                                    {moment(item.renewalDate?.date).isBefore(moment()) ?
                                                        <Badge color="warning" className="fs-8" rounded={"pill"}
                                                               isLight>{
                                                            moment(item.renewalDate?.date).fromNow().charAt(0).toUpperCase() + moment(item.renewalDate?.date).fromNow().slice(1)
                                                        }</Badge> :
                                                        <Badge color="success" rounded={"pill"} className="fs-8"
                                                               isLight>{
                                                            moment(item.renewalDate?.date).fromNow().charAt(0).toUpperCase() + moment(item.renewalDate?.date).fromNow().slice(1)
                                                        }</Badge>
                                                    }
                                                </div>;
                                            }
                                        },
                                        {
                                            name: "Organización",
                                            keyValue: "company",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            className: userCan('list', 'companies', true) ? "text-center" : "d-none",
                                            isVisible: userCan('list', 'companies', true),
                                            render: (element: any) => {
                                                return (
                                                    <div className="d-flex justify-content-center">
                                                        {element.company.name}
                                                    </div>
                                                );
                                            },
                                        },
                                        {
                                            name: "Acciones",
                                            className: "min-w-100px text-end",
                                            isActionCell: true,
                                        },
                                    ]
                                }
                                actions={[
                                    {
                                        title: "Ver",
                                        buttonType: "icon",
                                        iconColor: "text-primary",
                                        iconPath: "/media/icons/duotune/general/gen021.svg",
                                        additionalClasses: `text-primary ${userCan("show", "wallet") ? "" : "d-none"
                                        }`,
                                        description: "Ver la ficha de Cartera",
                                        callback: (item: any) => {
                                            if (userCan("show", "wallet")) navigate(`${item.id}/show`);
                                        },
                                    },
                                ]}
                            />
                        ) : (
                            <div className="text-center d-flex justify-content-center"><Spinner/></div>
                        )
                    }
                </Card>
            </Page>
            <WalletAssignDepartmentsModal isOpen={isOpened} setIsOpen={setIsOpened} selectedItems={selectedItems}
                                          refecthData={refetch}/>
        </Fragment>
    );
};

export default WalletList;
