import { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import { useSelector } from 'react-redux'
import { AuthState } from '../../../../redux/authSlice'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../components/bootstrap/forms/Select'
import { useCompanyList } from '../../../../hooks/useListData'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'

interface IClientFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const ClientFilters: FC<IClientFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false)
  
  const rol = useSelector((state: { auth: AuthState }) => {
    const userRoles = state.auth.user?.roles;
    return userRoles && (userRoles.includes('Superadministrador')) ? 'Superadministrador' : userRoles?.[0];
  });

  const companyList = useCompanyList();

  const handleTextChange = (search: string) => {
    updateFilters({ search_text: search });
};

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>

      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Clientes </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            <div className='col-12'>
              <FormGroup id='filter1' label='Nombre:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })}
                  value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter2' label='Nif:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ nif: e.target.value })}
                  value={filters.filter_filters.nif} id='nif' placeholder='Nif...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter3' label='Email'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ email: e.target.value })}
                  value={filters.filter_filters.email} id='email' placeholder='Email...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter4' label='Telefono:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ phones: e.target.value })}
                  value={filters.filter_filters.phones} id='phones' placeholder='Telefono...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter5' label='Dirección:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ address: e.target.value })}
                  value={filters.filter_filters.address} id='address' placeholder='Direccion...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter6' label='Localidad:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ locality: e.target.value })}
                  value={filters.filter_filters.locality} id='locality' placeholder='Localidad...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter7' label='Provincia:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ province: e.target.value })}
                  value={filters.filter_filters.province} id='province' placeholder='Provincia...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter8' label='Código Postal:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ postalCode: e.target.value })}
                  value={filters.filter_filters.postalCode} id='postalCode' placeholder='Código Postal...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter9' label='Iban:'>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ iban: e.target.value })}
                  value={filters.filter_filters.iban} id='iban' placeholder='Iban...' autoComplete='off' />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter10' label='Estado de cuenta'>
                <Select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                  value={filters.filter_filters.active?.toString() || 3}
                  id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                  list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup id='filter11' label='Mostrar Clientes:'>
                <Select
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ favourite: parseInt(e.target.value) })}
                  value={filters.filter_filters.favourite}
                  id='show-select' ariaLabel='Default select example' placeholder='Mostrar...'
                  list={[{ value: 2, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Favoritos', text: 'Favoritos' }, { value: 0, label: 'No Favoritos', text: 'No Favoritos' }]}
                />
              </FormGroup>
            </div>
            <div className={rol === 'Superadministrador' ? 'col-12' : 'd-none'}>
              <FormGroup id='filter5' label='Compañia'>
                <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })} ariaLabel='Default select example'
                  value={filters.filter_filters.company} id='company' placeholder='Compañia...' list={companyList}/>
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => resetFilters(50, true)}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    
    </Fragment>
  )
}

export default ClientFilters