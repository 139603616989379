import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Button from "../../components/bootstrap/Button";
import Checks, { ChecksGroup } from "../../components/bootstrap/forms/Checks";
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import useFetch from "../../hooks/useFetch";
import { Business, BusinessApiResponse } from "../../type/business-type";
import { Fees, FeesApiResponse } from "../../type/fee-type";
import { FeeService } from "../../services/fees/feeService";
import { BusinessService } from "../../services/business/businessService";
import { useLeadList, useOperationTypeList, useProductTypeList } from "../../hooks/useListData";
import ServicesProductCard from "./product-create/products-components/ServiceProductCard";
import ReactSelect, { MultiValue, Options } from "react-select";
import { ProductCommissionsCard } from "./product-create/products-components/ProductCommissionsCard";
import { ProductHasCommission } from "../../type/product-type";
import InputGroup, { InputGroupText } from "../../components/bootstrap/forms/InputGroup";
import { ReactSelectStyles } from "../../utils/styles";
interface ProductFormProps {
    submit: Function;
    productData?: any;
    businessId?: string;
}

export interface IProductForm {
    product?: string;
    name?: string;
    description?: string;
    fee?: any;
    product_type?: any;
    operation_types?: any[];
    priority?: number;
    individual_enabled?: boolean;
    business_enabled?: boolean;
    points?: number | string;
    base_commission?: number | string;
    business?: any;
    powerFrom?: number | string;
    powerTo?: number | string;
    consumptionFrom?: number | string;
    consumptionTo?: number | string;
    contributor_percentage?: number | string;
    leads?: any[];
    services?: any;
    commissions?: [];
}

const validationShema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    description: yup.string().nullable(),
    fee: yup.string().required('La tarifa es requerida'),
    product_type: yup.string().required('El tipo de producto es requerido'),
    priority: yup.number().required('La prioridad es requerida'),
    individual_enabled: yup.boolean(),
    business_enabled: yup.boolean(),
    points: yup.number().nullable(),
    base_commission: yup.number().nullable(),
    business: yup.string().required('La comercializadora es requerida'),
    powerFrom: yup.number().nullable().test('is-less-than-powerTo', 'La potencia inicial debe ser menor que la potencia final', function (value) {
        const powerTo = this.parent.powerTo ?? null;
        return value === null || powerTo === null || (value !== undefined && value < powerTo);
    }),
    powerTo: yup.number().nullable().test('is-greater-than-powerFrom', 'La potencia final debe ser mayor que la potencia inicial', function (value) {
        const powerFrom = this.parent.powerFrom ?? null;
        return value === null || powerFrom === null || (value !== undefined && value > powerFrom);
    }),
    consumptionFrom: yup.number().nullable().test('is-less-than-consumptionTo', 'El consumo inicial debe ser menor que el consumo final', function (value) {
        const consumptionTo = this.parent.consumptionTo ?? null;
        return value === null || consumptionTo === null || (value !== undefined && value < consumptionTo);
    }),
    consumptionTo: yup.number().nullable().test('is-greater-than-consumptionFrom', 'El consumo final debe ser mayor que el consumo inicial', function (value) {
        const consumptionFrom = this.parent.consumptionFrom ?? null;
        return value === null || consumptionFrom === null || (value !== undefined && value > consumptionFrom);
    }),
    contributor_percentage: yup.number().nullable().test('is-more-than-zero', 'El porcentaje del colaborador debe ser mayor que 0', function (value) {
        return value === null || value === undefined || value > 0;
    }).test('is-less-than-100', 'El porcentaje del colaborador debe ser menor que 100', function (value) {
        return value === null || value === undefined || value < 100;
    }),
    //services: yup.array().required('Debe seleccionar al menos un servicio')
});


const ProductForm: FC<ProductFormProps> = ({ submit, productData, businessId }) => {

    const [selectedServices, setSelectedServices] = useState(productData?.services);

    const [productType , setProductType] = useState(productData?.productType?.name);

    const productInitialValues: IProductForm = {
        product: productData?.id,
        name: productData?.name,
        description: productData?.description,
        fee: productData?.fee?.id,
        product_type: productData?.productType?.id,
        operation_types: productData?.operationTypes?.map((type: any) => {
            return { value: type.operationType.id, label: type.operationType.name }
        }) || [],
        leads: productData?.leads?.map((lead: any) => {
            return { value: lead.lead.id, label: lead.lead.name }
        }) || [],
        priority: productData?.priority,
        individual_enabled: productData?.individualEnabled || productData?.individualEnabled == false ? productData?.individualEnabled : true ,
        business_enabled: productData?.businessEnabled || false,
        points: productData?.points,
        base_commission: productData?.baseCommission,
        business: productData?.business?.id || businessId,
        services: selectedServices,
        commissions: productData?.commissions ? productData.commisssion : [], 
        powerFrom: productData?.powerFrom !== null ? productData?.powerFrom : null,
        powerTo: productData?.powerTo !== null ? productData?.powerTo : null,
        consumptionFrom: productData?.consumptionFrom !== null ? productData?.consumptionFrom : null,
        consumptionTo: productData?.consumptionTo !== null ? productData?.consumptionTo : null,
        contributor_percentage: productData?.contributorPercentage !== null ? productData?.contributorPercentage : null
    }

    const formik = useFormik({
        initialValues: productInitialValues,
        validationSchema: validationShema,
        onSubmit: receivedValues => {
            const values = { ...receivedValues };
            if (values.services == undefined) {
                values.services = []
            }
            if (values.operation_types == undefined) {
                values.operation_types = []
            } else {
                values.operation_types = values.operation_types.map((operationType: any) => {
                    return operationType.value
                })
            }
            if (values.leads == undefined) {
                values.leads = []
            } else {
                values.leads = values.leads.map((lead: any) => {
                    return lead.value
                })
            }
            submit(values);
        },
    });

    useEffect(() => {
        formik.values.services = selectedServices;
    }, [selectedServices]);

    const leadList = useLeadList();

    const opeTypesList = useOperationTypeList();

    const productTypeList = useProductTypeList();

    const fetchBusiness = useCallback(async () => {
        const businessService = new BusinessService();
        const response = await businessService.getBusinesses({});
        return response.getResponseData() as BusinessApiResponse;
    }, [formik.values.business, productData]);

    const [business, loading, error] = useFetch(fetchBusiness);

    const fetchFees = useCallback(async () => {
        if (formik.values.business != null && formik.values.business != '') {
            const response = await new FeeService().getFees({ filter_filters: { business: formik.values.business } });
            return response.getResponseData() as FeesApiResponse;
        }
    }, [formik.values.business, productData]);

    const [fees, fetchingFees, feeError] = useFetch(fetchFees);


    const getBusinessList = () => {
        if (business as Business) {
            return business.businesses.map((business: { id: any; name: any; }) => {
                return {
                    value: business.id,
                    label: business.name
                }
            })
        }
        return [];
    }

    const getFeeList = () => {
        if (fees as Fees) {
            return fees.fees.map((fee: { id: any; name: any; }) => {
                return {
                    value: fee.id,
                    label: fee.name
                }
            })
        }
        return [];
    }

    const verifyClass = (inputFieldID: keyof IProductForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IProductForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID] as string}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>

                <div className='row g-4'>
                    <div className="col-md-12">
                        <Card stretch={true}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='Inventory' iconColor='info'>
                                    <CardTitle>{productData ? 'Editar' : 'Crear'} Producto</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-4'>
                                    <FormGroup requiredInputLabel label='Nombre' className='col-md-6'>
                                        <Input id="name" value={formik.values.name || ''} required onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                    <FormGroup label='Breve descripción' className='col-md-6'>
                                        <Input id='description' value={formik.values.description || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('description')} />
                                        {showErrors('description')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Comercializadora' className='col-md-6'>
                                        <Select required id='business' disabled={businessId ? true : false} onChange={formik.handleChange} value={formik.values.business || ''} ariaLabel='Default select example' placeholder='Elegir comercializadora...'
                                            list={getBusinessList()} onBlur={formik.handleBlur} className={verifyClass('business')}
                                        />
                                        {showErrors('business')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Tipo de producto' className='col-md-6'>
                                        <Select required id='product_type' value={formik.values.product_type || ''} ariaLabel='Default select example' placeholder='Elegir tipo...'
                                            list={productTypeList} onBlur={formik.handleBlur} className={verifyClass('product_type')}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setProductType(((productTypeList.find((type: any) => type.value == e.target.value)) as any)?.label);
                                                formik.setFieldValue("product_type", e.target.value);
                                            }}
                                        />
                                        {showErrors('product_type')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Tarifa' className='col-md-6'>
                                        <Select required onChange={formik.handleChange} value={formik.values.fee || ''} id='fee' ariaLabel='Default select example' placeholder='Elegir tarifa...'
                                            list={getFeeList()} onBlur={formik.handleBlur} className={verifyClass('fee')}
                                        />
                                        {showErrors('fee')}
                                    </FormGroup>
                                    <FormGroup requiredInputLabel id='priority' label='Prioridad' className='col-md-6'>
                                        <Input id='priority' required type="number" value={formik.values.priority} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("priority", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('priority')} />
                                        {showErrors('priority')}
                                    </FormGroup>
                                    <FormGroup id='points' label='Puntos' className='col-md-4'>
                                        <Input id="points" type="number" min={0} step={0.01} value={formik.values.points} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("points", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('points')} />
                                        {showErrors('points')}
                                    </FormGroup>
                                    <FormGroup id='base_commission' label='Comisión' className='col-md-2'>
                                        <InputGroup>
                                        <Input id="base_commission" min={0} step={0.01} type="number" value={formik.values.base_commission} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("base_commission", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('base_commission')} />
                                        <InputGroupText>
                                            €
                                        </InputGroupText>
                                        {showErrors('base_commission')}
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup id='contributor_percentage' label='Porcentaje Colaborador' className='col-md-2'>
                                        <InputGroup>
                                        <Input id="contributor_percentage" min={0} step={0.01} type="number" value={formik.values.contributor_percentage} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("contributor_percentage", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('contributor_percentage')} />
                                        <InputGroupText>
                                            %
                                        </InputGroupText>
                                        {showErrors('contributor_percentage')}
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup label='Tipo de Contrato' className='col-md-4'>
                                        <ReactSelect isMulti id='operation_types' onChange={(options: MultiValue<any>) => {
                                            formik.setFieldValue("operation_types", options)
                                        }} value={formik.values.operation_types || []} placeholder='Elegir Tipos...'
                                            styles={ReactSelectStyles}
                                            options={opeTypesList} onBlur={formik.handleBlur} className={verifyClass('operation_types')}
                                        />
                                        {showErrors('operation_types')}
                                    </FormGroup>
                                    <FormGroup id='powerFrom' label='Potencia Desde (kw)' className='col-md-2'>
                                        <Input id="powerFrom" min={0} step={0.01} type="number" value={formik.values.powerFrom} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("powerFrom", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('powerFrom')} />
                                        {showErrors('powerFrom')}
                                    </FormGroup>
                                    <FormGroup id='powerTo' label='Potencia Hasta (kw)' className='col-md-2'>
                                        <Input id="powerTo" min={0} step={0.01} type="number" value={formik.values.powerTo} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("powerTo", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('powerTo')} />
                                        {showErrors('powerTo')}
                                    </FormGroup>
                                    <FormGroup label='Protección' className='col-md-2'>
                                        <ChecksGroup >
                                            <Checks id="individual_enabled" type="checkbox" label='Habilitado particular' checked={formik.values.individual_enabled} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('individual_enabled')} />
                                            {showErrors('individual_enabled')}
                                            <Checks id="business_enabled" type="checkbox" label='Habilitado empresas' checked={formik.values.business_enabled} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('business_enabled')} />
                                            {showErrors('business_enabled')}
                                        </ChecksGroup>
                                    </FormGroup>
                                    <FormGroup label='Tipo de Trámite' className='col-md-2'>
                                        <ReactSelect isMulti id='leads' onChange={(options: MultiValue<any>) => {
                                            formik.setFieldValue("leads", options)
                                        }} value={formik.values.leads || []}
                                            styles={ReactSelectStyles}
                                            placeholder='Elegir Tipos...'
                                            options={leadList} onBlur={formik.handleBlur} className={verifyClass('leads')}
                                        />
                                    </FormGroup>
                                    <FormGroup id='consumptionFrom' label='Consumo Desde (kw)' className='col-md-2'>
                                        <Input id="consumptionFrom" min={0} step={0.01} type="number" value={formik.values.consumptionFrom} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("consumptionFrom", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('consumptionFrom')} />
                                        {showErrors('consumptionFrom')}
                                    </FormGroup>
                                    <FormGroup id='consumptionTo' label='Consumo Hasta (kw)' className='col-md-2'>
                                        <Input id="consumptionTo" min={0} step={0.01} type="number" value={formik.values.consumptionTo} onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.setFieldValue("consumptionTo", e.target.value) }} onBlur={formik.handleBlur} className={verifyClass('consumptionTo')} />
                                        {showErrors('consumptionTo')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ServicesProductCard business={formik.values.business} selectedServices={selectedServices} setSelectedServices={setSelectedServices} idProduct={productData?.id} />
                    </div>
                    <div className="col-md-6">
                        <ProductCommissionsCard product={formik.values.product} type={productType} onChange={(commissions: ProductHasCommission[]) => {
                            formik.setFieldValue("commissions", commissions)
                         }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <Button type="submit" size='lg' color='primary' icon="save">
                            Guardar Producto
                        </Button>
                    </div>
                </div>





            </form>
        </Fragment >
    )
}

export default ProductForm;