import { Fragment } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import BusinessList from "./business-list/BusinessList"

const BusinessListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <BusinessList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default BusinessListWrapper