import { FC, useCallback, useState } from "react";
import Alert from "../../../../components/bootstrap/Alert";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import { OperationService } from "../../../../services/operations/operationService";
import useFetch from "../../../../hooks/useFetch";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { toast } from "react-toastify";
import { set } from "js-cookie";
import { CustomTable } from "../../../../components/table/CustomTable";
import moment from "moment";
import Badge from "../../../../components/bootstrap/Badge";
import AsyncImg from "../../../../components/AsyncImg";
import OperationSMSModal from "./OperationSMSModal";
import { Operation, OperationRequestSMS } from "../../../../type/operation-type";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";

type OperationSMSPanelProps = {
    operation: Operation
    noCard?: boolean
    maxheight?: string
}

const DEFAULT_CHECK_CERTIFICATE_TRIES = 5;

const OperationSMSPanel: FC<OperationSMSPanelProps> = ({ operation , noCard, maxheight }) => {

    const defaultAddText = 'Regenerar el SMS de este contrato'
    const [addText, setAddText] = useState<string>(defaultAddText)
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [requestSelected, setRequestSelected] = useState<OperationRequestSMS | null>(null);

    const [generateLoading, setGenerateLoading] = useState<boolean>(false);

    const { handleErrors } = useHandleErrors();


    const [requests, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (await (new OperationService()).listSMSRequests({
            filter_filters: {
                operation: operation.id
            }
        })).getResponseData()
        return response;
    }, []))

    const _generateSms = async () => {
        if(generateLoading) {
            toast.info('El sistema está ocupado. Espere a que termine la operación anterior');
            return;
        } 

        handleConfirmationAlert({
            title: "Generar Nuevo SMS",
            text: "¿Está seguro que desea generar un nuevo SMS? Si el producto se encuentra en una matriz de SMS se intentará buscar un SMS que se adapte al contrato.",
            icon: "warning",
            onConfirm: async () => {
                setGenerateLoading(true);

                const response = await (await (new OperationService()).generateSMS({ operation: operation.id })).getResponseData();

                setGenerateLoading(false);
                if (response.success) {
                    toast.success('SMS generado correctamente');
                    refetch();
                } else {
                    handleErrors(response);
                }
            }
        });


    }

    const _launchMessage = (smsRequestId: string, message: string) => {

        if(generateLoading) {
            toast.info('El sistema está ocupado. Espere a que termine la operación anterior');
            return;
        } 

        if (message.trim().length == 0) {
            toast.error('El mensaje no puede estar vacío');
            return;
        }

        handleConfirmationAlert({
            title: "Enviar SMS",
            text: "¿Está seguro que deseas Enviar este sms?",
            html: (<p>{message}</p>),
            icon: "warning",
            onConfirm: async () => {
                setGenerateLoading(true);
                const response = await (await (new OperationService()).sendSMS(smsRequestId)).getResponseData();
                if (response.success) {
                    toast.success('SMS enviado correctamente');
                    setGenerateLoading(false);
                    refetch();
                } else {
                    handleErrors(response);
                    setGenerateLoading(false);
                }
            }
        });

    }

    const _editMessage = (smsRequest: OperationRequestSMS) => {
        if(generateLoading) {
            toast.info('El sistema está ocupado. Espere a que termine la operación anterior');
            return;
        }

        setRequestSelected(smsRequest);
        setOpenEditModal(true);
    }

    const _deleteMessage = async (smsRequestId: string) => {
        if(generateLoading) {
            toast.info('El sistema está ocupado. Espere a que termine la operación anterior');
            return;
        }
        handleConfirmationAlert({
            title: "Eliminar SMS",
            text: "¿Está seguro que desea eliminar la solicitud de SMS?",
            icon: "warning",
            onConfirm: async () => {
                const response = await (await (new OperationService()).deleteSMSRequest(smsRequestId)).getResponseData();
                if (response.success) {
                    toast.success('SMS eliminado correctamente');
                    refetch();
                    setGenerateLoading(false);
                } else {
                    handleErrors(response);
                    setGenerateLoading(false);
                }
            }
        });


    }

    const _onCloseModal = () => {
        setOpenEditModal(false);
        setRequestSelected(null);
        refetch();
    }

    const _getOperationTelephones = () => {
        let telephones = [];

        if (operation.clientPhone) {
            telephones.push({ value: operation.clientPhone, label: operation.clientPhone });
        }

        if (operation.clientPhone2) {
            telephones.push({ value: operation.clientPhone2, label: operation.clientPhone2 });
        }

        return telephones;
    }

    const _downloadCertificate = async (item: OperationRequestSMS) => {

        const download = await (await (new OperationService()).downloadSMSRequestDocument(item.id ? item.id : ''));
        const response = download.getResponse();
        const responseData = download.getResponseData();

        const link = document.createElement('a');

        // Create a blob link to download
        link.href = URL.createObjectURL(new Blob([responseData]));
        link.setAttribute('download', `${item.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }



    const _checkCertificate = async (item: OperationRequestSMS, checkCertificateTries: number, ignoreLoading?: boolean) => {
        
        // Check if is already loading and is not a retry.
        if(generateLoading && !ignoreLoading) {
            toast.warning('El sistema está ocupado. Espere a que termine la operación anterior');
            return;
        }

        if(checkCertificateTries <= 0) {
            toast.warning('No se ha encontrado el certificado del mensaje');
            setGenerateLoading(false);
            return;
        }
        
        setGenerateLoading(true);
       
        const response = await (await (new OperationService()).checkCertificateSMSRequest(item.id)).getResponseData();

        if (response.success) {

            toast.success('Se ha encontrado el certificado');
            setGenerateLoading(false);
            refetch();

        } else {
            
            if(response.message === 'No hay información del sms.') {
                toast.info('Buscando certificado...Quedan ' + checkCertificateTries + ' intentos ');
                // Retry in 5 seconds
                setTimeout(() => {
                    _checkCertificate(item, checkCertificateTries - 1, true);
                }, 10000);
                return;
            } else {
                setGenerateLoading(false);
                handleErrors(response);
            }
        }
    }

    return (
        <>
            <Card className={`${noCard ? "shadow-none" : ""}`}>
                <CardHeader>

                    <CardLabel icon='Message' iconColor='info'>
                        <CardTitle>Envío de SMS {requests && requests.sms_requests ? `(${requests.sms_requests.length})` : ""}</CardTitle>
                    </CardLabel>
                    
                    <CardActions>
                        <Tooltips title={addText}>
                            {
                                !generateLoading ?
                                    <Button icon='Message' color='primary' isLight onClick={_generateSms} />
                                    : <Spinner />
                            }
                        </Tooltips>
                        <Tooltips title={"Refrescar lista"}>
                            {
                                !generateLoading ?
                                    <Button icon='Autorenew' color='info' isLight onClick={async () => {
                                        setGenerateLoading(true);
                                        await refetch();
                                        setTimeout(() => {
                                            setGenerateLoading(false);
                                        }, 500);

                                    }} />
                                    : <Spinner />
                            }
                        </Tooltips>

                    </CardActions>
                </CardHeader>
                <CardBody className={maxheight ?  maxheight : ""}>
                    {
                        requests && requests.sms_requests.length === 0 && (
                            <Alert variant="info" icon="info" color="info">
                                No hay ningún SMS generado para este contrato.
                            </Alert>
                        )
                    }
                    <CustomTable
                        className={`table table-hover fs-7 max-h-250`}
                        actions={[
                            {
                                title: 'Enviar',
                                buttonType: "icon",
                                iconColor: 'text-success',
                                iconPath: "/media/icons/duotune/general/gen016.svg",
                                description: 'Enviar SMS',
                                callback: async (element: any) => {
                                    _launchMessage(element.id, element.message);
                                },
                                hide: (element: any) => {
                                    return element.sendDate !== null;
                                }
                            },
                            {
                                title: 'Editar',
                                buttonType: "icon",
                                iconColor: 'text-info',
                                iconPath: "/media/icons/duotune/general/gen055.svg",
                                description: 'Editar SMS',
                                callback: _editMessage,
                                hide: (element: any) => {
                                    return element.sendDate !== null;
                                }
                            },
                            {
                                title: 'Eliminar',
                                buttonType: "icon",
                                iconColor: 'text-danger',
                                iconPath: "/media/icons/duotune/general/gen027.svg",
                                description: 'Eliminar SMS',
                                callback: async (element: any) => {
                                    _deleteMessage(element.id);
                                },
                                hide: (element: any) => {
                                    return element.sendDate !== null;
                                }
                            },
                            {
                                title: 'Descargar',
                                buttonType: "icon",
                                iconColor: 'text-blue-600',
                                iconPath: "/media/icons/duotune/arrows/arr091.svg",
                                description: 'Descargar Certificado',
                                callback: async (element: any) => {
                                    _downloadCertificate(element);
                                },
                                hide: (element: any) => {
                                    return !element.responseDate;
                                }
                            },
                            {
                                title: 'Comprobar certificado',
                                buttonType: "icon",
                                iconColor: 'text-orange-500',
                                iconPath: "/media/icons/duotune/arrows/arr084.svg",
                                description: 'Comprobar certificado',
                                callback: async (element: any) => {
                                    _checkCertificate(element, DEFAULT_CHECK_CERTIFICATE_TRIES);
                                },
                                hide: (element: any) => {
                                    // Hide if message is not sent
                                    if(element.sendDate === null) return true;
                                    
                                    // Hide if message has already a certificate
                                    if(element.codeDocument !== undefined && element.codeDocument !== null) {
                                        return true;
                                    }

                                    return false;

                                }
                            },



                        ]}
                        columns={[
                            {
                                name: 'Usuario',
                                keyValue: 'user',
                                render: (element: any) => {
                                    return (
                                        <Tooltips title={element.user.name}>
                                            <div className="d-flex justify-content-center">
                                                <div className='user-avatar'>
                                                    <AsyncImg id={element.user.profileImg ? element.user.profileImg.id : null} />
                                                </div>
                                            </div>
                                        </Tooltips>
                                    )
                                }
                            },
                            {
                                name: 'Texto',
                                keyValue: 'message',
                                render: (element: any) => {
                                    return (
                                        <div className="text-center">
                                            <p>{element.message.substring(0, 100) + '...'}</p>
                                        </div>
                                    )
                                }

                            },
                            {
                                name: 'Teléfono',
                                keyValue: 'telephone',
                                render: (element: any) => {
                                    return (
                                        <div className="text-center">
                                            {element.telephone}
                                        </div>
                                    )
                                }

                            },
                            {
                                name: 'Creación',
                                keyValue: 'createdAt',
                                render: (element: any) => {
                                    return (
                                        <div className="text-center">
                                            {moment(element.createdAt.date).format('DD/MM/YYYY HH:mm:ss')}
                                        </div>
                                    )
                                }
                            },
                            {
                                name: 'Envío',
                                keyValue: 'sendDate',
                                render: (element: any) => {
                                    return (
                                        <div className="text-center d-flex flex-column">
                                            {!element.sendDate && (<Badge rounded="pill" shadow={'sm'} color="warning" className="h6">Esperando envío</Badge>)}
                                            {element.sendDate && (
                                                <>
                                                    <Badge rounded="pill" shadow={'sm'} color="success" className="h6">Enviado</Badge>
                                                    {moment(element.sendDate.date).format('DD/MM/YYYY HH:mm:ss')}
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                            },
                            {
                                name: 'Respuesta',
                                keyValue: 'responseDate',
                                render: (element: any) => {
                                    return (
                                        <div className="text-center d-flex flex-column justify-content-center align-items-center">
                                            {!element.sendDate && !element.responseDate && (<>-</>)}
                                            {element.sendDate && !element.responseDate && (
                                                <>
                                                    <Badge rounded="pill" shadow={'sm'} color="warning" className="h6">Esperando</Badge>
                                                </>
                                            )}
                                            {element.responseDate && (
                                                <>
                                                    <Badge rounded="pill" shadow={'sm'} color="success" className="h6">Respondido</Badge>
                                                    {moment(element.responseDate.date).format('DD/MM/YYYY HH:mm:ss')}
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                            },
                            {
                                name: 'Acciones',
                                isActionCell: true,
                                className: 'text-center'
                            }
                        ]}
                        data={requests?.sms_requests || null}
                    />
                </CardBody>
            </Card>
            {openEditModal && requestSelected && (<OperationSMSModal isOpen={openEditModal} setIsOpen={setOpenEditModal} onClose={_onCloseModal} mode={"edit"} requestSMS={requestSelected} availableTelephones={_getOperationTelephones()} />)}
        </>
    )
}

export default OperationSMSPanel;