import { FC, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { ClientService } from "../../../services/clients/clientService";
import * as yup from 'yup';
import { showErrors } from "../../../utils/forms/VerifyForms";
import CustomSelect from "../../../components/selectors/general/CustomSelect";
import { SingleValue } from "react-select";
import { useClientList } from "../../../hooks/useListData";
import { Client } from "../../../type/client-type";
import Icon from "../../../components/icon/Icon";
import Tooltips from "../../../components/bootstrap/Tooltips";

interface ClientRelatedModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    relation?: any;
    client?: string;
    onClose?: (message?: string) => void;
}

const clientRelatedSchema = yup.object().shape({
    client: yup.string().required('El cliente es obligatorio'),
    relatedClient: yup.string().required('El cliente relacionado es obligatorio').min(1, 'El cliente relacionado es obligatorio'),
    comment: yup.string().nullable(),
});

const ClientRelatedModal: FC<ClientRelatedModalProps> = ({ isOpen, setIsOpen, mode, relation, client, onClose }) => {

    // STATES

    const [clientList , setClientList] = useState<any>();
    const clientDefaultList = useClientList({ filter_order : [{field: "created_at", order: 'DESC'}] }, true);

    // OPTIONS 

    let relationTypeOptions = [
        { value: 'Recomienda a', label: 'Recomienda a' },
        { value: 'Viene Recomendado/a por', label: 'Viene Recomendado/a por' }
    ];

    // SUBMIT FUNCTIONS

    /**
     * 
     * @EN This function is responsible for creating the relationship between the clients
     * @ES Esta función se encarga de crear la relación entre los clientes
     * 
     * @param values
     * @returns Create the relationship
     */

    const createRelation = async (values: any) => {
        try {
            let response = await (await (new ClientService()).createClientRelation(values)).getResponseData();
            if (response.success) {
                setIsOpen(false);
                onClose && onClose("Relación creada correctamente");
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (e) {
            console.log('error', e);
        }

    }

    /**
     * 
     * @EN This function is responsible for updating the relationship between the clients
     * @ES Esta función se encarga de actualizar la relación entre los clientes
     * 
     * @param values
     * @returns Update the relationship
     */

    const updateRelation = async (values: any) => {
        try {
            let response = await (await (new ClientService()).updateClientRelation(values)).getResponseData();
            if (response.success) {
                setIsOpen(false);
                onClose && onClose("Relación actualizada correctamente");
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    // CHANGE FUNCTIONS

    const onChangeRelatedClient = (newValue: SingleValue<any>) => {
        let client = newValue.value
        formik.setFieldValue('relatedClient', client);
    };

    const onChangeRelationType = (newValue: SingleValue<any>) => {
        let relationType = newValue.value
        formik.setFieldValue('relationType', relationType);
    }   

    const onInputChange = async (inputValue: string) => {
        if (inputValue.length < 3) {
            return inputValue;
        }

        const response = await (await (new ClientService()).getClients({ filter_filters: { search_text: inputValue } })).getResponseData();

        if (response.success) {
            let clientsList = response.data.clients.map((client: Client) => {
                return {
                    value: client.id ,
                    label: `${client.name} - ${client.nif}`, 
                    text: client.name
                }
            })
            setClientList(clientsList);
        }

        return inputValue;
    }

    // FORMIK

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
            mode === 'create' ?
                {
                    client: client,
                    relatedClient: '',
                    comment: '',
                    relationType: ''
                }
                :
                {
                    relationId: relation?.id,
                    client: client,
                    relatedClient: relation?.relatedClient?.id,
                    lastRelatedClient: relation?.relatedClient?.id,
                    relationType: relation?.relationType,
                    comment: relation?.comment,
                }
        ,
        validationSchema: clientRelatedSchema,
        onSubmit: (values) => {
            if (mode === 'create') {
                createRelation(values as any);
            } else {
                updateRelation(values as any);
            }
        },
    });

    // USE EFFECTS

    useEffect(() => {
        if (clientDefaultList.length > 0) {
            setClientList(clientDefaultList);
        }
    }, [clientDefaultList]);

    // RETURN

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Relación'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_comment'>Nueva Relación</ModalTitle>) : (<ModalTitle id='edit_comment'>Editar Relación</ModalTitle>)}
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup id='comment' label='Cliente Relacionado' className='col-md-12 row'>
                            <div className="col-11">
                            <CustomSelect options={clientList} is_multi={false} key={formik.values.relationId}
                            defaultValue={formik.values.relatedClient && mode == 'edit' ? {value: relation?.relatedClient?.id, label: `${relation?.relatedClient?.name} - ${relation?.relatedClient?.nif}`} : null}
                            placeholder='Seleccionar o Buscar Cliente Relacionado ...'
                            onChangeSingle={onChangeRelatedClient} onInputChange={onInputChange}/>
                            {showErrors(formik, 'relatedClient')}
                            </div>
                            <div className="col-1">
                                <Tooltips title='EL selector esta limitado a 25 resultados por motivos de rendimiento si no encuentra el cliente que busca por favor escriba el nombre completo'>
                                    <span>
                                        <Icon icon={'info'} 
                                        color={"warning"} size={'3x'}/>
                                    </span>
                                </Tooltips>
                            </div>
                        </FormGroup>
                        <FormGroup id='relationType' label='Tipo de Relacion' className='col-md-12 mt-3'>
                            <CustomSelect options={relationTypeOptions} is_multi={false}
                            defaultValue={formik.values.relationType && mode == 'edit' ? {value: relation?.relationType, label: relation?.relationType} : null}
                            placeholder='Tipo de Relación ...' onChangeSingle={onChangeRelationType}/>
                            {showErrors(formik, 'relatedClient')}
                        </FormGroup>
                        <FormGroup id='comment' label='Comentario Sobre la Relación' className='col-md-12'>
                            <Textarea value={formik.values.comment == null ? '' : formik.values.comment} onChange={formik.handleChange} rows={10}/>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon="Save" color='primary' type="submit">
                        {mode === 'create' ? 'Guardar Relación' : 'Editar Relación'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default ClientRelatedModal;