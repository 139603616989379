/**
 *
 * @EN This function is responsible for fetching the list of origins
 * @ES Este hook se encarga de obtener la lista de entidades para pintar los selectores en la configuración de la wallet
 *
 * @param filters Filter to apply to the list
 * @param noId Return the name instead of the id
 * @returns Fetch the list of origins
 */
import {useEffect, useState} from "react";
import {StatusService} from "../services/status/statusService";
import {CampaignService} from "../services/campaigns/campaignService";
import useHandleErrors from "./useHandleErrors";


export const useWalletConfigEntities = (filters?: any , noId ?: boolean) => {

    const [ walletStatuses, setWalletStatuses ] = useState<any[]>([]);
    const [ walletActionStatuses, setWalletActionStatuses ] = useState<any[]>([]);
    const [ campaigns, setCampaigns ] = useState<any[]>([]);
    const [ operationStatuses, setOperationStatuses ] = useState<any[]>([]);

    const {handleErrors} = useHandleErrors();

    // OPTIONS FOR SELECT
    useEffect(() => {
        Promise.all([
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "wallet", active : 1 }}),
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "action_wallet", active : 1 }}),
            (new CampaignService()).getCampaigns({filter_filters: { active: 1 }, limit: 1000}),
            (new StatusService()).getActionStatusesSelect({filter_filters: { entity: "all_operations", active : 3 }})
        ]).then((responses) => {

            // OPTIONS FOR WALLET STATUSES
            handleErrors(responses[0]);
            const walletStatuses = responses[0].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name }))
            walletStatuses.unshift({ value: '', label: 'Elegir Estado...' });
            setWalletStatuses(walletStatuses);

            // OPTIONS FOR WALLET ACTION STATUSES
            handleErrors(responses[1]);
            const walletActionStatuses = responses[1].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name }))
            walletActionStatuses.unshift({ value: '', label: 'Elegir Estado...' });
            setWalletActionStatuses(walletActionStatuses);

            // OPTIONS FOR ACTIVE CAMPAIGNS
            handleErrors(responses[2]);
            const campaigns = responses[2].getResponseData()?.data.campaigns.map((campaign: any) => ({ value: campaign.id, label: campaign.name }))
            campaigns.unshift({ value: '', label: 'Elegir Departamento...' });
            setCampaigns(campaigns);

            // OPTIONS FOR OPERATION STATUSES
            handleErrors(responses[3]);
            const operationStatuses = responses[3].getResponseData()?.data.map((status: any) => ({ value: status.id, label: status.name , color : status.color , active : status.active }))
            setOperationStatuses(operationStatuses);
        });
    }, []);

    return { walletStatuses, walletActionStatuses, campaigns, operationStatuses }
}

