import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {RootState} from "../../../redux/store";
import {ActiveTab, setActiveTab} from "../../../redux/activeTabSlice";
import {useDispatch, useSelector} from "react-redux";
import {DocumentTypeService} from "../../../services/documents/documentTypeService";
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTabItem,
    CardTitle,
} from "../../../components/bootstrap/Card";
import TabDocumentOperationItem from "./TabDocumentOperationItem";
import {OperationService} from "../../../services/operations/operationService";

const ACTIVE_TAB_SECTION = "operation_document_view";

interface OperationDocumentsComponentProps {
    operationId: string;
}

const OperationDocumentsComponent: FC<OperationDocumentsComponentProps> = ({
                                                                               operationId,
                                                                           }) => {
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [countDocuments, setCountDocuments] = useState<any[]>([]);

    const activeTab = useSelector((state: RootState) =>
        state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION)
    );

    const dispatch = useDispatch();

    const getDocumentTypes = async () => {
        let response = await (
            await new DocumentTypeService().getDocumentTypes({
                filter_filters: {entityType: "Contrato", active: 1},
                all: true,
            })
        ).getResponseData();
        if (response.data) {
            console.log(response.data);
            let responseCount = await (
                await new OperationService().getDocumentsByDocumentTypes(operationId, response.data.documentTypes.map((documentType: any) => documentType.id))
            ).getResponseData();
            setCountDocuments(responseCount.data);
            setDocumentTypes(response.data.documentTypes);
        }
    };

    useEffect(() => {
        getDocumentTypes();
    }, []);

    return (
        <Fragment>
            <Card
                hasTab
                // @ts-ignore
                defaultActiveTab={activeTab?.activeTab || 0}
                onActiveTab={(tab: number) => {
                    dispatch(
                        setActiveTab({section: ACTIVE_TAB_SECTION, activeTab: tab})
                    );
                }}
            >
                {/* If has no document types: */}
                {(!documentTypes || documentTypes.length === 0) && (
                    <CardTabItem id="no_documents" title="No hay documentos">
                        <div className="d-flex justify-content-center align-items-center">
                            <span className=" fs-7 fw-bold">No hay ningun tipo de documento orientado a contrato</span>
                        </div>
                    </CardTabItem>
                )}

                {/* If has document types: */}
                { documentTypes && documentTypes.length !== 0 && documentTypes.map((documentType: any, index: number) => {

                    // Count documents by document type
                    let count = 0;
                    let countDocumentType = countDocuments.find((countDocument: any) => countDocument.documentTypeId === documentType.id);
                    if (countDocumentType) {
                        count = countDocumentType.documentCount;
                    }

                    return (
                        <CardTabItem key={"document-types-tab-" + index} id={documentType.name} title={documentType.name + ` (${count})`}>
                            <TabDocumentOperationItem
                                id={operationId}
                                documentType={documentType}
                                key={index}
                            />
                        </CardTabItem>
                    )
                })}

            </Card>
        </Fragment>
    );
};

export default OperationDocumentsComponent;
