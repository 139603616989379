import { FC } from "react";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { Client } from "../../../type/client-type";
import { ClientService } from "../../../services/clients/clientService";
import { useUserProfile } from "../../../hooks/useListData";

interface ClientCommentModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    mode: string;
    comment?: any;
    client?: string;
    onClose?: (message?: string) => void;
}

const ClientCommentModal: FC<ClientCommentModalProps> = ({ isOpen, setIsOpen, mode, comment, client, onClose }) => {

    const user = useUserProfile();

    const createComment = async (values: any) => {
        try {
            let response = await (await (new ClientService()).createClientComment(values)).getResponseData();
            if (response.success) {
                setIsOpen(false);
                onClose && onClose("Comentario añadido correctamente");
            } else {
                toast.error('Error al añadir el comentario');
            }
        } catch (e) {
            console.log('error', e);
        }

    }

    const updateCommet = async (values: any) => {
        try {
            let response = await (await (new ClientService()).updateClientComment(values)).getResponseData();
            if (response.success) {
                setIsOpen(false);
                onClose && onClose("Comentario actualizado correctamente");
            } else {
                toast.error('Error al actualizar el comentario');
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
            mode === 'create' ?
                {
                    comment: '',
                    user : user?.id,
                    client: client,
                }
                :
                {
                    comment: comment?.comment || '',
                    comment_id: comment?.id,
                    user : user?.id,
                    client: client,

                }
        ,
        onSubmit: (values) => {
            if (mode === 'create') {
                createComment(values as any);
            } else {
                updateCommet(values as any);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' titleId='Nuevo Comentario'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                {mode === 'create' ? (<ModalTitle id='new_comment'>Nuevo Comentario</ModalTitle>) : (<ModalTitle id='edit_comment'>Editar Comentario</ModalTitle>)}
            </ModalHeader>
            <form onSubmit={formik.handleSubmit}>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup id='comment' label='Comentario' className='col-md-12'>
                            <Textarea value={formik.values.comment == null ? '' : formik.values.comment} onChange={formik.handleChange} rows={10}/>
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button icon="Save" color='primary' type="submit">
                        {mode === 'create' ? 'Guardar Comentario' : 'Editar Comentario'}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default ClientCommentModal;