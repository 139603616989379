import { FiltersProvider } from "../../components/providers/FiltersProvider";
import ActionStatusList from "./actionStatus-list/ActionStatusList";

const ActionStatusListWrapper = () => {
  
    return (
      <>
        <FiltersProvider defaultFilterFilters={{entity: 'operation' , active: 3}}>
          <ActionStatusList />
        </FiltersProvider>
      </>
    );
  };

export default ActionStatusListWrapper;