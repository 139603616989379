import { ClientSupply } from "../../type/client-type"
import Badge from "../bootstrap/Badge"
import Card, { CardBody } from "../bootstrap/Card"

type ClientSupplyCardProps = {
    supply: ClientSupply,
    onClick?: (supply: ClientSupply) => void
}

const ClientSupplyCard: React.FC<ClientSupplyCardProps> = ({ supply, onClick }) => {
    return (
        <Card shadow={'none'} className="cursor-pointer" borderColor={'primary'} isCompact borderSize={1} onClick={() => {
            if (onClick) {
                onClick(supply);
            }
        }}>
            <CardBody className="text-start row">
                <div className="col-md-8 text-start">
                    <h5 className="fw-bold">{supply?.products[0]?.address} ({supply?.products[0]?.cups})</h5>
                    <p>{supply?.products[0]?.postalcode}, {supply?.products[0]?.town}, {supply?.products[0]?.province}</p>
                   
                    <p>
                        <Badge isLight>
                        {
                         // @ts-ignore
                        supply?.products[0]?.product?.business?.name
                        }
                        </Badge>
                    </p>
                </div>
                <div className="col-md-4 text-end">
                    {/** @ts-ignore */}
                    <p><Badge isLight>{supply.products[0].product?.productType?.name}</Badge></p>
                    <p>Consumo (Kw): {supply.products[0].consumption || "-"}</p>
                </div>
                <div className="col-md-12 text-end">
                    <p>
                        Potencia (Kw):  {supply.products[0].powerP1 && ('P1: ' + supply.products[0].powerP1 + ' - ')}
                        {supply.products[0].powerP2 && ('P2: ' + supply.products[0].powerP2 + ' - ')}
                        {supply.products[0].powerP3 && ('P3: ' + supply.products[0].powerP3 + ' - ')}
                        {supply.products[0].powerP4 && ('P4: ' + supply.products[0].powerP4 + ' - ')}
                        {supply.products[0].powerP5 && ('P5: ' + supply.products[0].powerP5 + ' - ')}
                        {supply.products[0].powerP6 && ('P6: ' + supply.products[0].powerP6 + ' - ')}
                    </p>
                </div>
            </CardBody>
        </Card>
    )
}


export default ClientSupplyCard