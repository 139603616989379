import { FC, useEffect, useState } from "react";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import { OperationService } from "../../../../services/operations/operationService";
import AsyncImg from "../../../../components/AsyncImg";
import { Link } from "react-router-dom";
import { priceFormatEuro } from "../../../../helpers/helpers";
import Icon from "../../../../components/icon/Icon";
import { usePrivilege } from "../../../../components/priviledge/PriviledgeProvider";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import { truncateString } from "../../../../utils/strings/truncateText";

interface IOperationAgentRankingProps {
  date: any;
  showCommission?: boolean;
  agent?: string;
  office?: string;
  business?: string;
}

const OperationAgentRanking: FC<IOperationAgentRankingProps> = ({ date , showCommission , agent , office , business }) => {
  const [data, setData] = useState<any>();
  const { userCan } = usePrivilege();
  const [loading, setLoading] = useState<boolean>(false);

  const getRanking = async () => {
    const operationService = new OperationService();
    if (date.start === "" || date.end === "") return;
    const response = (
      await operationService.getRanking(date.start, date.end , agent , office , business)
    ).getResponseData();
    if (response.success) {
      setData(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (date != null) {
      getRanking();
    }
  }, [date, office, agent, business]);

  return (
    <Card>
      <CardHeader className="mb-1">
        <CardLabel icon="ContactSupport" iconColor="secondary">
          <CardTitle tag="h4" className="h5">
            Ranking de Agentes
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className="overflow-auto" style={{ maxHeight: "650px" }}>
        {data != null && data.errors == null ? (
          data.map((item: any, key: number) => {
            return (
              <div className="col-12 mb-4" key={key}>
                <div className="row">
                  <div className="col-md-4 col-12 row d-flex">
                    <div className=" col-md-3 d-flex align-items-center">
                      <span className={`fs-4 fw-bold mb-0 text-center ${key + 1 == 1 ? "text-warning" : key + 1 == 2 ? "text-muted" : key + 1 == 3 ? "text-danger" : ""}`}>
                        {key + 1}
                      </span>
                    </div>
                    <div className="col-md-3 flex-shrink-0 d-flex justify-content-center">
                      <div
                        className="user-avatar"
                        title={item.agentName}
                        id={item.agentId}
                      >
                        <AsyncImg id={item.agentImage || null} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <Tooltips title={item.agentName}>
                          <Link
                            to={`/users/${item.agentId}/profile`}
                            className={"fw-bold fs-6 mb-0"}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {truncateString(item.agentName, 8)}
                          </Link>
                        </Tooltips>
                      </div>
                    </div>
                  </div>
                    <div className=" col-sm-8 row col-12 d-flex justify-content-around align-items-center">
                      {userCan("show_commissions", "operations") && (
                        <span className="fs-6 fw-bold mb-0 text-end col-4">
                          { showCommission ? priceFormatEuro(item.grossCommission || 0) : 'XXX,XX €'}
                        </span>
                      )}

                      <span className="fs-6 fw-bold mb-0 text-end col-4">
                        {item.netPoints + " ptos"}
                      </span>

                      <span className="fs-6 fw-bold mb-0 text-end col-4">
                        {item.grossPoints + " ptos"}
                      </span>
                    </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center">No hay datos</div>
        )}
      </CardBody>
    </Card>
  );
};

export default OperationAgentRanking;
