import { useFormik } from "formik";
import { FC, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Checks, { ChecksGroup } from "../../../components/bootstrap/forms/Checks";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { NewOrigin, Origin } from "../../../type/origin-type";
import { OriginService } from "../../../services/origin/originService";


interface ProductModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    readMode: boolean;
    setReadMode(...args: unknown[]): unknown;
    origin: Origin | any;
}

const OriginModal: FC<ProductModalProps> = ({ isOpen, setIsOpen, readMode, setReadMode, origin }) => {
    const [modal, setModal] = useState(false);
    const [loadign, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: origin?.name,
            description: origin?.description || null,
        },
        onSubmit: (values) => {
            if (origin === null) {
                createLead(values as NewOrigin);
            } else {
                updateLead(values as Origin)
            }
        },
    });


    const createLead = async (values: NewOrigin) => {
        try {
            setLoading(true);
            let response = await (await (new OriginService()).createOrigin(values)).getResponseData();
            if (response.success) {
                toast.success('Origen Lead creado con éxito');
            } else {
                toast.error('Error al crear el origen lead');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al crear el origen lead');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    const updateLead = async (values: any) => {
        try {
            setLoading(true);
            values.origin = origin.id;
            let response = await (await (new OriginService()).editOrigin(values)).getResponseData();
            if (response.success) {
                toast.success('Origen Lead editado con éxito');
            } else {
                toast.error('Error al editar el origen lead');
            }
        } catch (e) {
            setLoading(false);
            toast.error('Error al editar el origen lead');
            console.log('error', e);
        } finally {
            setLoading(false);
            setIsOpen(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop={true}  size={!modal? 'xl':'sm'} titleId='Nuevo Origen Lead'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='new_role'>{!readMode ? (origin ? 'Editar' : 'Nuevo') : 'Ver'} Origen Lead</ModalTitle>
            </ModalHeader>

            {true && !modal ? (
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <FormGroup requiredInputLabel id='name' label='Nombre' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.name || ''} autoComplete="off" required onChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup id='description' label='Breve descripción' className='col-md-6'>
                                <Input disabled={readMode} value={formik.values.description || ''} autoComplete="off" onChange={formik.handleChange} />
                            </FormGroup>
                        </div>
                    </ModalBody>
                    
                    {(!readMode) ?
                        <ModalFooter className='px-4 pb-4'>
                            <Button icon={loadign ? "" : "Save"} color='primary' type="submit" isDisable={loadign}>
                                {loadign  ? <Spinner className="text-center"/> : "Guardar"}
                            </Button>
                        </ModalFooter>
                        : <></>}
                </form>

            ) : (<div className="d-flex justify-content-center p-5">
                <Spinner />
            </div>
            )}
        </Modal>
    )
}

export default OriginModal;