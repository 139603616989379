import { AxiosResponse } from "axios";
import { Expense, NewExpense } from "../../type/expense-type";
import { RestServiceConnection } from "../restServiceConnection";


const EXPENSES_ENDPOINT = '/expenses';

export class ExpenseService extends RestServiceConnection {

    createExpense = async (expense : NewExpense ) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSES_ENDPOINT + '/create',
            data: expense,
        }, true);
        return this;
    }

    updateExpense = async (expense: Expense) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSES_ENDPOINT + '/edit',
            data: expense,
        }, true);
        return this;
    }

    getExpenses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            data: {...filters},
            url: EXPENSES_ENDPOINT + '/list',
        }, true) as AxiosResponse;
        return this;
    }

    getExpense = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSES_ENDPOINT + '/get/' + id,
        }, true);
        return this;
    }

    deleteExpense = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSES_ENDPOINT + '/delete',
            data: {
                expense: id
            },
        }, true);
        return this;
    }

    getSumBetweenDates = async (startDate: string, endDate: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: EXPENSES_ENDPOINT + '/sum-between-dates',
            data: {
                from: startDate,
                to: endDate,
            }
        }, true);
        return this;
    }
}
