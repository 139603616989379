import { AxiosResponse } from "axios";
import { RestServiceConnection } from '../restServiceConnection';
import { NewOrigin, Origin } from "../../type/origin-type";

const ORIGIN_ENDPOINT = '/origins';

export class OriginService extends RestServiceConnection {

    createOrigin = async (origin: NewOrigin) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ORIGIN_ENDPOINT + '/create',
            data: origin
        }, true);
        return this;
    }

    editOrigin = async (origin: Origin) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ORIGIN_ENDPOINT + '/edit',
            data: origin
        }, true);
        return this;
    }

    getOrigins = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ORIGIN_ENDPOINT + '/list',
            data: filters, 
        }, true) as AxiosResponse;
        return this;
    }

    toggleStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ORIGIN_ENDPOINT + '/toggle',
            data: {
                origin: id,
                active: status
            }, 
        }, true) as AxiosResponse;
        return this;
    }
    
    deleteOrigin = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ORIGIN_ENDPOINT + '/delete',
            data: {
                origin: id
            },
        }, true);
        return this;
    }
}