import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import {percent, priceFormatEuro} from '../../helpers/helpers';

interface IPercentComparisonProps {
	valueOne: number;
	valueTwo: number;
}
const PercentComparison: FC<IPercentComparisonProps> = ({ valueOne, valueTwo }) => {

	const [value , setValue] = useState(percent(valueOne , valueTwo));

	useEffect(() => {
		setValue(percent(valueOne , valueTwo));
	}, [valueOne, valueTwo]);

	function getIcon() {
		if (value > 0) {
			return { icon: 'TrendingUp', color: 'success' };
		}
		if (value < 0) {
			return { icon: 'TrendingDown', color: 'danger' };
		}
		return { icon: 'TrendingFlat', color: 'info' };
	}
	return (
		<>
			<span className={`text-${getIcon().color} fs-5 fw-bold ms-3 d-inline`}>
				{`${value}%`}
					<Icon icon={getIcon().icon}/>
			</span>
			<span className={'fs-6 fw-bold ms-3 d-inline text-muted'}>
				{priceFormatEuro(valueTwo)}
			</span>

		</>

	);
};

export default PercentComparison;
