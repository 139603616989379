import { useEffect, useState } from "react";
import { CampaignStatus } from "../../../type/campaign-type";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import Icon from "../../icon/Icon";
import { usePrivilege } from "../../priviledge/PriviledgeProvider";
import StatusLabel from "../../status_label/StatusLabel";
import { CustomTable } from "../CustomTable";

type CustomTableProps<T> = {
    data: T[];
    selectableItems?: boolean;
    onChangeCheckedItems?: (items: T[]) => void;
    onDelete: (items: T[]) => void;

}

const CampaignStatusTable: React.FC<CustomTableProps<CampaignStatus>> = ({ data, selectableItems, onChangeCheckedItems, onDelete }) => {

    const { userCan } = usePrivilege();
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    // Handle event when the click the selectAll checkbox
    const _handleSelectAllItems = (checked: boolean) => {
        if(checked) {
            setSelectedItems(data);
        } else {
            setSelectedItems([]);
        }
    }

    // Handle event when the selected checkbox change
    const _handleChangeCheckedItems = (id: string, event: React.ChangeEvent<HTMLInputElement>, element: any) => {
        const { checked } = event.target;
        let selectedItemsCopy = [...selectedItems];
        if (checked) {
            selectedItemsCopy.push(element);
        } else {
            let index = selectedItemsCopy.findIndex((item: any) => item.id === id);
            selectedItemsCopy.splice(index, 1);
        }
        setSelectedItems(selectedItemsCopy);
    }

    // Listen for changes in selectedItems and call onChangeCheckedItems prop
    useEffect(() => {
        if(onChangeCheckedItems) {
            onChangeCheckedItems(selectedItems);
        }
    }, [selectedItems])


    return (
        <>
            <CustomTable
                data={data}
                className={"table table-hover max-h-250"}
                selectableItems={selectableItems}
                onSelectAllItems={_handleSelectAllItems}
                onChangeCheckedItems={_handleChangeCheckedItems}
                actions={[
                    {
                        title: "Eliminar",
                        buttonType: "icon",
                        iconPath: "/media/icons/duotune/general/gen027.svg",
                        iconColor: "text-danger",
                        additionalClasses: `text-primary ${userCan('edit', 'campaigns') ? '' : 'd-none'}`,
                        description: "Eliminar estado",
                        callback: (item: any) => {
                            handleConfirmationAlert({
                                title: "Eliminar estado",
                                text: "¿Está seguro que desea eliminar el estado?",
                                icon: "warning",
                                onConfirm: () => {
                                    onDelete([item])
                                }
                            })
                        },
                    }
                ]}
                columns={[
                    {
                        name: "Nombre",
                        keyValue: "name",
                        className: "text-center",
                        render: (element: CampaignStatus) => {
                            return (
                                <div className="d-flex justify-content-center fw-semibold">
                                    {element.status && <StatusLabel status={element.status} />}
                                </div>
                            );
                        },
                    },
                    {
                        name: "Inicial",
                        keyValue: "defaultStatus",
                        className: "text-center",
                        render: (item: any) => {
                            return (
                                <div className="d-flex justify-content-center" >
                                    {
                                        item.status.defaultStatus ?
                                            <Icon icon={'star'} color={"primary"} size={'2x'} />
                                            : <Icon icon={'starOutline'} color={"light"} size={'2x'} />
                                    }
                                </div>
                            );
                        },
                    },
                    {
                        name: "Tipo",
                        keyValue: "name",
                        className: "text-center",
                        render: (item: any) => {
                            return (
                              <div className="d-flex justify-content-center" >
                                  {
                                      item.status?.statusEntity?.label
                                  }
                              </div>
                            );
                        },
                    },
                    {
                        name: "Descripción",
                        keyValue: "description",
                        className: "text-center",
                        render: (element: CampaignStatus) => {
                            return (
                                <div className="d-flex justify-content-center fw-semibold">
                                    {element.status?.description}
                                </div>
                            );
                        },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                ]}
            />
        </>
    )
}

CampaignStatusTable.defaultProps = {
    selectableItems: false,

}

export default CampaignStatusTable;