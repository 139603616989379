import { useEffect, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import { ProductHasCommission } from "../../../../type/product-type";
import { ProductCommissionModal } from "./ProductCommissionModal";
import { ProductService } from "../../../../services/products/productService";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { CustomTable } from "../../../../components/table/CustomTable";
import { usePrivilege } from "../../../../components/priviledge/PriviledgeProvider";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import Icon from "../../../../components/icon/Icon";


type ProductCommissionsCardProps = {
    product?: string
    type?: string
    onChange?: (commissions: ProductHasCommission[]) => void
}


export const ProductCommissionsCard: React.FC<ProductCommissionsCardProps> = ({ product, onChange, type }) => {

    const [commissions, setCommissions] = useState<ProductHasCommission[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const { handleErrors } = useHandleErrors();
    const {userCan} = usePrivilege();

    const productService = new ProductService();

    const _handleAddCommission = (commission: ProductHasCommission) => {
        setCommissions([...commissions, commission]);
        setModal(false);
    }

    const _handleDelete = async (item: ProductHasCommission) => {
        if (product) {
            _deleteCommission(item);
        } else {
            const commis = commissions?.filter((commission: any) => commission !== item);
            setCommissions(commis);
        }
    }

    const _deleteCommission = async (item: any) => {
        setCommissions(commissions.filter((commission: any) => commission !== item));
    }

    const _getCommissions = async () => {
        if (product) {
            let response = await productService.getProductById(product);
            let productData = response.getResponseData();

            if (productData.success) {
                setCommissions(productData.data.commissions);
            }

            handleErrors(response)

        }

    }  

    // Retrieve product commissions
    useEffect(() => {
        _getCommissions();
    }, [])

    // Trigger onChange event
    useEffect(() => {
        if(onChange !== undefined) {
            onChange(commissions);
        }
    }, [commissions])


    return (
        <Card>
            <CardHeader borderSize={1}>
                <CardLabel icon="Monetization On" iconColor="info">
                    <CardTitle>
                        Opciones de comisión
                        <Tooltips title="Las opciones de comisión sobreescribirán la comisión base de un contrato al aplicarse en el caso de que se cumplan las características de potencia indicadas.">
                            <Icon icon="Info" color="primary" className="ms-2"></Icon>
                        </Tooltips>
                    </CardTitle>
                </CardLabel>
                <CardActions>
                    <Button
                        className="mx-2"
                        color="primary"
                        icon="Add"
                        isLight
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Añadir Comisión
                    </Button>
                </CardActions>
            </CardHeader>
            <CardBody>
                <CustomTable
                    key={type}
                    data={commissions ?? []}
                    pagination={true}
                    className={'table table-hover'}
                    columns={[
                        {
                            name: 'Potencia desde',
                            keyValue: 'powerFrom',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.powerFrom} kW
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Potencia hasta',
                            keyValue: 'powerTo',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.powerTo} kW
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Consumo desde',
                            keyValue: 'consumptionFrom',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.consumptionFrom || '-' } kWh
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Consumo hasta',
                            keyValue: 'consumptionTo',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.consumptionTo || '-'} kWh
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Comisión €',
                            keyValue: 'commission',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.commission} €
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Puntos',
                            keyValue: 'points',
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-primary fw-bold">
                                        {element.points}
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'Descripción',
                            keyValue: 'description',
                            className: 'text-center',
                            cellClassName: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-muted fw-bold">
                                        {element.description}
                                    </div>
                                );
                            },
                        },
                        { name: 'Acciones', className: `min-w-100px text-end`, isActionCell: true, },
                    ]}
                    actions={[
                        {
                            title: 'Eliminar',
                            buttonType: 'icon',
                            iconColor: 'text-danger',
                            iconPath: '/media/icons/duotune/general/gen027.svg',
                            additionalClasses: `text-danger`,
                            description: 'Eliminar Servicio',
                            callback: (item: any) => {
                                if (userCan('delete', 'products')) {
                                    handleConfirmationAlert({
                                        title: 'Eliminar Comisión',
                                        text: '¿Está seguro que desea eliminar la comisión?',
                                        icon: 'warning',
                                        onConfirm: () => {
                                            _handleDelete(item);
                                        },
                                    });
                                }
                            },
                        },
                    ]}
                />
            </CardBody>
            <ProductCommissionModal isOpen={modal} setIsOpen={setModal} product={product} type={type} onAdd={_handleAddCommission} />
        </Card>
    )
}