import { FC, Fragment } from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import AsyncImg from "../../../components/AsyncImg";
interface PersonalInfoCardProps {
    email: string;
    phone: string | null;
    address: string | null;
    profileImg: string | null;
}

const PersonalInfoCard: FC<PersonalInfoCardProps> = ({email, phone, address, profileImg}) => {

    return (
        <Fragment> 
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 py-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <AsyncImg isBackground height="120px" width="120px" styles="rounded-circle" id={profileImg}/>
                        </div>
                        <div className='col-12'>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Mail' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3 with-90'>
                                            <div className='fw-bold fs-5 mb-0 text-truncate'>
                                                {email ? email : 'No proporcionado'}
                                            </div>
                                            <div className='text-muted'>
                                                Correo electrónico
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Phone' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3 with-90'>
                                            <div className='fw-bold mb-0 text-truncate'>
                                                {phone ? phone : 'No proporcionado'}
                                            </div>
                                            <div className='text-muted'>
                                                Teléfono
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Home' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3 with-90'>
                                            <div className='fw-bold mb-0 text-truncate'>
                                                {address ? address : 'No proporcionada'}
                                            </div>
                                            <div className='text-muted'>
                                                Dirección
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default PersonalInfoCard;