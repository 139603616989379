import { FC } from "react";
import FeeList from "./FeeList";

interface FeeWrapperProps {
    businessId: string;
}

const FeeWrapper: FC<FeeWrapperProps> = ({businessId}) => {

    return <FeeList businessId={businessId} />;

}

export default FeeWrapper;