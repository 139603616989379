import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../../layout/SubHeader/SubHeader";
import BusinessInfoCard from "./BusinessInfoCard";
import PersonalInfoCard from "./PersonalInfoCard";
import UserDocuments from "./UserDocuments";
import UserObjectives from "./UserObjectives";
import useFetch from "../../../hooks/useFetch";
import { User } from "../../../type/user-type";
import { UserService } from "../../../services/users/userService";
import { getUserRolesByObject } from "../../../helpers/helpers";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import moment from "moment";
import { OperationService } from "../../../services/operations/operationService";
import UserCampaign from "./UserCampaign";

const UserProfile: FC = () => {

  // GET ID FROM URL

  const { id = "" } = useParams<{ id: string }>();
  const [userId, setUserId] = useState<string | null>(id);

  // USER CAN

  const { userCan } = useContext(PrivilegeContext);

  // NAVIGATE

  const navigate = useNavigate();

  // STATES

  const [points, setPoints] = useState<number>(0);

  //FUNCTIONS

  /**
   * @ES OBTENER PUNTOS DE USUARIO ENTRE FECHAS
   * @EN GET POINTS OF USER BETWEEN DATES
   */

  const getPoints = async () => {
    const operationService = new OperationService();
    const response = (await operationService.getAgentPointsBetweenDates(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')
      , id || "")).getResponseData();
    setPoints(response.data.totalPoints);
  }

  /**
   * @ES OBTENER USUARIO
   * @EN GET USER
   * 
   * @returns DATA FROM USER
   */

  const fetchUser = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    return response.getResponseData() as User;
  }, [userId]);

  const [data, loading, error] = useFetch(fetchUser);

  useEffect(() => {
    getPoints();
  }, [id]);

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="primary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button
            color="primary"
            icon="Edit"
            onClick={() => navigate(`/users/${id}/edit`, { replace: true })}
          >
            Editar
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="xxl">
        <Fragment>{
          data ? (
            <Fragment>
          <div className="pt-3 pb-5 d-flex align-items-center">
            <span className="display-6 fw-bold me-3">{data.name}</span>
            {getUserRolesByObject(data)?.map((role: string) => (
              <span
                key={"profile-role" + role}
                className="border border-success border-2 text-success fw-bold px-3 py-2 rounded"
              >
                {role}
              </span>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-4">
              <PersonalInfoCard
                profileImg={data.profileImg?.id}
                email={data.email}
                phone={data.telephone}
                address={data.address}
              />
              <BusinessInfoCard createdAt={data.createdAt} userId={id}/>
            </div>
            <div className="col-lg-8">
              <UserCampaign user={data} />
              <UserObjectives user={data} points={points}/>
              {userCan("show_documents", "users") ? (
                  <UserDocuments id={id} />
              ) : null}
            </div>
          </div>
        </Fragment>
          ) : (<div className="text-center text-primary d-flex justify-content-center"><Spinner /></div>)
        }</Fragment>
      </Page>
    </Fragment>
  );
};

export default UserProfile;
