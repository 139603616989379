import React, { useCallback, useContext, useState } from 'react';
import { handleConfirmationAlert } from '../../../../utils/ConfirmationAlert';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { CustomTable } from '../../../../components/table/CustomTable';
import moment from 'moment';
import { ProductService } from '../../../../services/products/productService';
import { Product } from '../../../../type/product-type';
import useFetch from '../../../../hooks/useFetch';
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider';
import { toast } from 'react-toastify';
import ProductServiceModal from './ProductServiceModal';
import { BusinessService } from '../../../../services/business/businessService';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import Icon from '../../../../components/icon/Icon';

interface IProps {
    business: any;
    idProduct?: string;
    selectedServices: any;
    setSelectedServices: any;
    show?: boolean;
}

const ServicesProductCard: React.FC<IProps> = ({ selectedServices, setSelectedServices, business, idProduct, show }) => {

    const { userCan } = useContext(PrivilegeContext);

    const [modal, setModal] = useState(false);

    const [serviceEdit, setServiceEdit] = useState<any>(null);

    const fetchProduct = useCallback(async () => {
        const productService = new ProductService();
        const response = await productService.getProductById(idProduct || '');
        return response.getResponseData() as Product;
    }, [idProduct]);

    const [product, loading, error, refech] = useFetch(fetchProduct);

    const fetchBusiness = useCallback(async () => {
        const businessService = new BusinessService();
        const response = await businessService.getBusinessById (business);
        return response != undefined ?  response.getResponseData() as Product : null;
    }, [business]);

    const [businesses, fetchingBusiness, businessError] = useFetch(fetchBusiness);

    const setNewServices = (value: any) => {
        if (selectedServices != undefined) {
            let serviceExists = false;

            for (let i = 0; i < selectedServices.length; i++) {
                if (selectedServices[i].service?.id === value.service?.id) {
                    selectedServices[i] = value;
                    serviceExists = true;
                    break;
                }
            }
            if (!serviceExists) {
                if (Array.isArray(value)) { // Verifica si `value` es una matriz
                  setSelectedServices([...selectedServices, ...value]);
                } else { // Si no es una matriz, conviértelo en una matriz antes de agregarlo
                  setSelectedServices([...selectedServices, value]);
                }
              }
        } else {
            setSelectedServices([value]);
        }
    };

    const deleteProductService = async (service: any) => {
        try {
            if (selectedServices && product && product.id) {
                const updatedServices = selectedServices.filter(
                    (item: any) => item.service.id !== service.service.id
                );
                const response = await new ProductService().removeService(
                    product.id,
                    service.service.id
                );
                response.getResponseData().success
                    ? toast.success("Servicio eliminado correctamente")
                    : toast.error("Error al eliminar servicio");
                setSelectedServices(updatedServices);
            }
        } catch (error) {
            console.log(error);
            toast.error("Error al eliminar servicio");
        }
    };

    const shouldDisableButton = () => {
        if (business === undefined) {
            return true;
        }

        if (businesses === null || businesses.services === undefined) {
            return true;
        }

        if (businesses.services.length === 0) {
            return true;
        }

        return false;
    };

    const getButtonTitle = () => {
        if (business === undefined) {
            return 'Debe seleccionar una comercializadora';
        }

        if (businesses === null || businesses.services === undefined) {
            return 'Cargando Servicios';
        }

        if (businesses.services.length === 0) {
            return 'Esta comercializadora no tiene ningún servicio';
        }

        return 'Añadir Servicio';
    };

    return (
        <>
            <Card>
                <CardHeader borderSize={1}>
                    <CardLabel icon="SupportAgent" iconColor="info">
                        <CardTitle>Servicios</CardTitle>
                    </CardLabel>
                    {show ?
                        null
                        : <div title={getButtonTitle()}>
                            <Button
                                className="mx-2"
                                color="primary"
                                icon="Add"
                                isLight
                                isDisable={shouldDisableButton()}
                                onClick={() => {
                                    setServiceEdit(null);
                                    setModal(true);
                                }}
                            >
                                Añadir Servicio
                            </Button>
                        </div>}
                </CardHeader>
                <CardBody>
                    <CustomTable
                        data={selectedServices ?? []}
                        pagination={true}
                        className={'table table-hover'}
                        columns={[
                            {
                                name: 'Nombre',
                                keyValue: 'name',
                                className: 'text-center',
                                render: (element: any) => {
                                    return (
                                        <div className="d-flex justify-content-center text-primary fw-bold">
                                            {
                                                element.warning &&
                                                <Tooltips title={element.warning}>
                                                    <Icon icon="Info" color="info" size={'lg'} className="me-2" />
                                                </Tooltips>
                                            }
                                            {element.service.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: 'Descripción',
                                keyValue: 'description',
                                className: 'text-center',
                                cellClassName: 'text-center',
                                render: (element: any) => {
                                    return (
                                        <div className="d-flex justify-content-center text-muted fw-bold">
                                            {element.service.description}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: 'Commisión',
                                keyValue: 'commission',
                                className: 'text-center',
                                cellClassName: 'text-center',
                            },
                            {
                                name: 'Puntos',
                                keyValue: 'points',
                                className: 'text-center',
                                cellClassName: 'text-center',
                            },
                            {
                                name: 'Advertencia',
                                keyValue: 'warning',
                                className: 'text-center',
                                cellClassName: 'text-center',
                            },
                            {
                                name: 'Fecha de creación',
                                keyValue: 'createdAt',
                                sortable: true,
                                className: 'text-center',
                                render: (item: any) => {
                                    return (
                                        <div className={'text-center'}>
                                            <span className={'text-muted'}>
                                                {item.createdAt ? moment(item.createdAt.date).format('DD-MM-YYYY HH:mm') : 'Sin Fecha'}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: 'Última modificación',
                                keyValue: 'updatedAt',
                                sortable: true,
                                className: 'text-center',
                                render: (item: any) => {
                                    return (
                                        <div className={'text-center'}>
                                            <span className={'text-muted'}>
                                                {item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY HH:mm') : 'Sin actualizar'}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            { name: 'Acciones', className: `min-w-100px text-end ${show ? 'd-none' : ''}`, isActionCell: true,},
                        ]}
                        actions={[
                            {
                                title: 'Editar',
                                buttonType: 'icon',
                                iconColor: 'text-info',
                                iconPath: '/media/icons/duotune/general/gen055.svg',
                                additionalClasses: `text-primary ${userCan('edit', 'products') ? '' : 'd-none'} ${show ? 'd-none' : ''}`,
                                description: 'Editar el producto',
                                callback: (item: any) => {
                                    if (userCan('edit', 'products')) {
                                        setServiceEdit(item);
                                        setModal(true);
                                    }
                                },
                            },
                            {
                                title: 'Eliminar',
                                buttonType: 'icon',
                                iconColor: 'text-danger',
                                iconPath: '/media/icons/duotune/general/gen027.svg',
                                additionalClasses: `text-danger ${userCan('delete', 'products') ? '' : 'd-none'} ${show ? 'd-none' : ''}`,
                                description: 'Eliminar Servicio',
                                callback: (item: any) => {
                                    if (userCan('delete', 'products')) {
                                        handleConfirmationAlert({
                                            title: 'Eliminar Servicio',
                                            text: '¿Está seguro que desea eliminar el Servicio?',
                                            icon: 'warning',
                                            onConfirm: () => {
                                                if (idProduct) {
                                                    deleteProductService(item);
                                                } else {
                                                    const services = selectedServices?.filter((service: any) => service.service.id !== item.service.id);
                                                    setSelectedServices(services);
                                                }
                                            },
                                        });
                                    }
                                },
                            },
                        ]}
                    />
                </CardBody>
            </Card>
            <ProductServiceModal isOpen={modal} setIsOpen={setModal} productObj={product} newService={setNewServices} businessId={business} serviceEdit={serviceEdit}/>
        </>
    );
};

export default ServicesProductCard;