import { FC, Fragment, useState } from 'react'
import Icon from '../../../components/icon/Icon'
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput'
import Button from '../../../components/bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Select from '../../../components/bootstrap/forms/Select'
import Input from '../../../components/bootstrap/forms/Input'

interface IOfficeiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any,
}

const OfficeFilter: FC<IOfficeiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false)

    const handleTextChange = (search: string) => {
        updateFilters({ search_text: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={"Buscar"} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}/>
        
            <Button color='primary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Rankings  </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Nombre'>
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ name: e.target.value })} value={filters.filter_filters.name} id='name' placeholder='Nombre...' autoComplete='off' />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <Button
                                className='mt-4'
                                color="storybook"
                                isLight
                                onClick={() => resetFilters(50, true)}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                 </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default OfficeFilter