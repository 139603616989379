import { FC, SyntheticEvent, useCallback, useEffect, useState } from "react"
import { OperationService } from "../../../../services/operations/operationService"
import useFetch from "../../../../hooks/useFetch"
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal"
import FormGroup from "../../../../components/bootstrap/forms/FormGroup"
import { useFormik } from "formik"
import Button from "../../../../components/bootstrap/Button"
import Input from "../../../../components/bootstrap/forms/Input"
import { OperationRecordApiResponse } from "../../../../type/operation-type"
import * as Yup from 'yup'

type OperationRecordModalProps = {
    mode: 'create' | 'edit',
    opened: boolean
    recordId: string | null,
    onUpdateOpen(status: boolean): void;
    onSubmit?: (values: any) => void;
}

const defaultValues = {
    name: undefined,
    identifier: undefined,
}

const formSchema = Yup.object().shape({
    name: Yup.string()
        .required("El nombre es obligatorio")
        .min(5, "El nombre debe tener al menos 5 caracteres"),
    callIdentifier: Yup.string().nullable(),
    document: Yup.mixed().test('type', "El archivo debe ser formato mp3 o wav", (value: any) => {
        if (value) {
            return value.type === 'audio/mpeg' || value.type === 'audio/mp3' || value.type === 'audio/wav';
        }
        return false;
    }),
});

const OperationRecordModal: FC<OperationRecordModalProps> = ({ mode, recordId, opened, onUpdateOpen, onSubmit }) => {

    const [initialValues, setInitialValues] = useState<any>(defaultValues);

    const fetchRecord = useCallback(async () => {

        if (recordId === null) return null;

        try {
            let response = await (await (new OperationService()).getOperationRecordById(recordId)).getResponseData()
            if (response.success) {
                return response as OperationRecordApiResponse;
            } else {
                console.log(response.message);
            }
        } catch (error: any) {
            console.log(error);
        }
    }, [recordId, opened]);

    const [data, loading, error, refetch] = useFetch(fetchRecord);


    useEffect(() => {
        if (data) {
            setInitialValues(data);
        }
    }, [data]);


    const _getTitle = () => {
        switch (mode) {
            case 'create':
                return 'Añadir grabación de llamada';
            case 'edit':
                return 'Editar grabación de llamada';
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: formSchema,
        initialValues: initialValues,
        onSubmit: (values) => {
            if (onSubmit) onSubmit(values);
        },
    });


    const verifyClass = (inputFieldID: any) => {
        if (formik.touched[inputFieldID]) {
            return (formik.errors[inputFieldID]) ? 'is-invalid' : '';
        }
        return '';
    }

    const showErrors = (inputFieldID: any) => {
        const errors = formik.errors[inputFieldID];
        if (formik.touched[inputFieldID] && errors) {
            const errorMessages = Array.isArray(errors)
                ? errors.join(', ')
                : String(errors);
            return <div className="invalid-feedback" style={{ display: "block" }}>{errorMessages}</div>;
        }
        return <></>;
    };



    return (
        <>
            {
                <Modal isOpen={opened} setIsOpen={onUpdateOpen} isStaticBackdrop={true} size={'lg'} titleId="modal_title">
                    <ModalHeader setIsOpen={onUpdateOpen} className='p-4'>
                        <ModalTitle id={'modal_title'}>{_getTitle()}</ModalTitle>
                    </ModalHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalBody className='px-4'>
                            <div className='row g-4'>
                                <div className='col-md-6'>
                                    <FormGroup requiredInputLabel id='name' label='Nombre de la grabación'>
                                        <Input id="name" value={formik.values.name} autoComplete="off" required className={verifyClass('name')} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {showErrors('name')}
                                    </FormGroup>

                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label='Identificador de llamada'>
                                        <Input id='callIdentifier' value={formik.values.callIdentifier} autoComplete="off" className={verifyClass('callIdentifier')} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {showErrors('callIdentifier')}
                                    </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                    <FormGroup label='Identificador de llamada'>
                                        <Input type="file" autoComplete="off" className={verifyClass('document')} onChange={(e: any) => {
                                            formik.setFieldValue('document', e.currentTarget.files[0]);
                                        }} />
                                        {showErrors('document')}
                                    </FormGroup>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter className='px-4 pb-4'>
                            <Button color='primary' type="submit">
                                Guardar
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            }

        </>
    )
}


export default OperationRecordModal;