import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { CustomTable } from "../../../components/table/CustomTable";
import { Service } from "../../../type/service-type";
import { ProductService } from "../../../services/products/productService";
import { toast } from "react-toastify";
import Icon from "../../../components/icon/Icon";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

interface OperationTypeModalProps {
  isOpen: boolean;
  setIsOpen(...args: unknown[]): unknown;
  productSelected: any;
  onClose?: (updateProducts: any, message?: string) => void;
}

const OperationServiceEditModal: FC<OperationTypeModalProps> = ({
  isOpen,
  setIsOpen,
  productSelected,
  onClose,
}) => {

  const [selectedItems, setSelectedItems] = useState<Service[]>(
    productSelected.services.map((service: any) => ({
      ...service.service,
      commission: service.commission,
      points: service.points,
      warning: service.warning
    }))
  );

  const {userCan} = usePrivilege();

  useEffect(() => {
    setSelectedItems(
      productSelected.services.map((service: any) => ({
        ...service.service,
        commission: service.commission,
        points: service.points,
        warning: service.warning
      }))
    );
  }, [productSelected]);

  const fetchProducts = useCallback(async () => {
    const productService = new ProductService();
    const response = await productService.getProductById(
      productSelected.product.id
    );
    return response.getResponseData();
  }, []);

  const [products, loading, error, refetch] = useFetch(fetchProducts);



  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    service: any,
    commission: number,
    points: number,
    warning?: string
  ) => {
    const { checked } = event.target;
    let newSelectedItems;

    service.commission = commission;
    service.points = points;
    service.warning = warning;

    if (checked) {
      if (service.warning != null && service.warning != undefined && service.warning != "") {
        toast.warning(service.warning);
      }
      newSelectedItems = [...selectedItems, service];
    } else {
      newSelectedItems = selectedItems.filter((item) => item.id !== id);
    }

    formik.setFieldValue("newServices", newSelectedItems);
    setSelectedItems(newSelectedItems);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newServices: [],
    },
    onSubmit: (values) => {
      const newServices = {
        id: productSelected.id,
        services:
          values.newServices.length > 0
            ? values.newServices.map((service: any) => ({
                id: service.id,
                commission: service.commission,
                points: service.points,
                warning: service.warning
              }))
            : null,
      };

      onClose && onClose(newServices, "Servicios actualizados correctamente");
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="lg"
      titleId="Servicios Seleccionados"
      data-backdrop="static"
    >
      <ModalHeader setIsOpen={setIsOpen} className="p-4">
        <ModalTitle id="edit_operationType">
          Editar Servicios Seleccionados
        </ModalTitle>
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody className="">
          {
            products ? (
            <CustomTable
            data={products.services}
            className={"table table-hover"}
            columns={[
              {
                name: "",
                keyValue: "selected",
                className: "text-center",
                render: (item: any) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <div key={item.id} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`checkbox-${item.id}`}
                            checked={selectedItems.some(
                              (selectedItem) =>
                                selectedItem.id === item.service.id
                            )}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event,
                                item.service.id,
                                item.service,
                                item.commission,
                                item.points,
                                item.warning
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              {
                name: "Nombre",
                keyValue: "name",
                className: "text-center",
                render: (item: any) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        {
                          item.warning &&
                          <Tooltips title={item.warning}>
                            <Icon icon="Info" color="info" size={'lg'} className="me-2" />
                          </Tooltips>
                        }
                        {item.service.name}
                      </div>
                    </div>
                  );
                },
              },
              {
                name: "Comision",
                keyValue: "commission",
                className: "text-center",
                isVisible: userCan('show_commissions', 'operations'),
                render: (item: any) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>{item.commission}</div>
                    </div>
                  );
                },
              },
              {
                name: "Puntos",
                keyValue: "points",
                className: "text-center",
                isVisible: userCan('show_points', 'operations'),
                render: (item: any) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>{item.points}</div>
                    </div>
                  );
                },
              },
              {
                name: "Advertencia",
                keyValue: "warning",
                className: "text-center",
                render: (item: any) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>{item.warning}</div>
                    </div>
                  );
                }
              }
            ]}
          />
        ) : (<div className="text-center text-primary d-flex justify-content-center"><Spinner /></div>)
          }
          <Button
            icon="Save"
            color="primary"
            type="submit"
            className="float-end mb-3"
          >
            {"Editar Servicios"}
          </Button>
        </ModalBody>
      </form>
    </Modal>
  );
};

export default OperationServiceEditModal;
