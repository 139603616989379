import { FC, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomTable } from "../../../../components/table/CustomTable";
import Spinner from "../../../../components/bootstrap/Spinner";
import useFilters from "../../../../hooks/useFilters";
import AsyncImg from "../../../../components/AsyncImg";
import { handleConfirmationAlert } from "../../../../utils/ConfirmationAlert";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../../hooks/useFetch";
import Tooltips from "../../../../components/bootstrap/Tooltips";
import moment from "moment";
import { OperationService } from "../../../../services/operations/operationService";
import OperationCommentModal from "./OperationEditCommentModal";

interface OperationsCommentHistory {
  id: string;
  maxheight?: string;
  updateComments: boolean;
}

const OperationsCommentHistory: FC<OperationsCommentHistory> = ({ id, maxheight, updateComments }) => {
  const { userCan } = useContext(PrivilegeContext);

  const commentFilters = {
    operation: id,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [comment, setComment] = useState<any>(null);

  const _onClose = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    setIsOpen(false);
    setComment(null);
    updateFilters({ operation: id });
  };

  useEffect(() => {
    updateFilters({ operation: id });
  }, [id]);

  const {
    filters,
    updateFilters,
    resetFilters,
    updateFilterOrder,
    updatePage,
    updatePageSize,
  } = useFilters(commentFilters, [], 50);

  const fetchCommments = useCallback(async () => {
    const response = await (
      await new OperationService().getComments(filters)
    ).getResponseData();
    return response;
  }, [filters, updateComments]);

  const [data, loading, error, refech] = useFetch(fetchCommments);

  const deleteComment = async (idcomment: string) => {
    let response = (
      await new OperationService().deleteComment(idcomment, id)
    ).getResponseData();
    if (response.success) {
      toast.success("Comentario eliminado correctamente");
      refech();
    } else {
      toast.error("Error al eliminar el comentario");
    }
  };

  const formatElapsedTime = (created_at: string) => {
      const createdAt = new Date(created_at);
      const now = new Date();
      const timeDifference = now.getTime() - createdAt.getTime();
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);
    
      const formatUnit = (value: number, unit: string): string => {
        if (value === 1) {
          return `${value} ${unit}`;
        } else if (value > 1) {
          return `${value} ${unit}s`;
        }
        return "";
      };
    
      if (years > 0) {
        return formatUnit(years, "año");
      } else if (months > 0) {
        return formatUnit(months, "mes");
      } else if (days > 0) {
        return formatUnit(days, "día");
      } else if (hours > 0) {
        return formatUnit(hours, "hora");
      } else if (minutes > 0) {
        return formatUnit(minutes, "minuto");
      } else if (seconds > 0) {
        return formatUnit(seconds, "segundo");
      } else {
        return "Ahora";
      }
  };

  return (<>
   {data ? (
      <>
        <CustomTable
          data={data.comments || null}
          className={`table table-hover fs-7 ${maxheight ? maxheight : "max-h-250" }`}
          columns={[
            {
              name: "Usuario",
              keyValue: "name",
              className: "text-center",
              render: (element: any) => {
                return (
                  <Tooltips title={element.user.name}>
                    <div className="d-flex justify-content-center">
                      <div className="user-avatar">
                        <AsyncImg
                          id={
                            element.user.profileImg
                              ? element.user.profileImg.id
                              : null
                          }
                        />
                      </div>
                    </div>
                  </Tooltips>
                );
              },
            },
            {
              name: "Comentario",
              keyValue: "comment",
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    {element.comment}
                  </div>
                );
              },
            },
            {
              name: "Ult. Actualización",
              keyValue: "created_at",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    {element.updatedAt != null
                      ? formatElapsedTime(element.updatedAt.date)
                      : formatElapsedTime(element.createdAt.date)}
                  </div>
                );
              },
            },
            {
              name: "Fecha de Creación",
              keyValue: "created_at",
              sortable: true,
              sortColumn: updateFilterOrder,
              className: "text-center",
              cellClassName: "text-center",
              render: (element: any) => {
                return (
                  <>
                    <div className="d-flex justify-content-center">
                      {moment(element.createdAt.date).format("DD/MM/YYYY - HH:mm")}
                    </div>
                  </>
                );
              },
            },
            {
              name: "Acciones",
              className: "min-w-100px text-end",
              isActionCell: true,
            },
          ]}
          actions={[
            {
              title: "Editar",
              buttonType: "icon",
              iconColor: "text-info",
              iconPath: "/media/icons/duotune/general/gen055.svg",
              additionalClasses: `text-primary ${
                userCan("edit_comments", "operations") ? "" : "d-none"
              }`,
              description: "Editar el Comentario",
              callback: (item: any) => {
                if (userCan("edit_comments", "operations")) {
                  setComment(item);
                  setMode("edit");
                  setIsOpen(true);
                }
              },
            },
            {
              title: "Eliminar",
              buttonType: "icon",
              iconColor: "text-danger",
              iconPath: "/media/icons/duotune/general/gen027.svg",
              additionalClasses: `text-danger ${
                userCan("remove_comments", "operations") ? "" : "d-none"
              }`,
              description: "Eliminar el Comentario",
              callback: (item: any) => {
                if (userCan("remove_comments", "operations")) {
                  handleConfirmationAlert({
                    title: "Eliminar Comentario",
                    text: "¿Está seguro que desea eliminar el comentario?",
                    icon: "warning",
                    onConfirm: () => {
                      deleteComment(item.id);
                    },
                  });
                }
              },
            },
          ]}
    />
    <OperationCommentModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      operation={id}
      comment={comment}
      onClose={_onClose}
      mode={mode}
    />
  </>
) : (
  <div className="text-center text-primary d-flex justify-content-center">
    <Spinner />
  </div>
  )}</>);
};

export default OperationsCommentHistory;
