import React, { useCallback, useEffect, useState } from 'react'
import Select, { ActionMeta } from 'react-select';
import useFetch from '../../../hooks/useFetch';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { ReactSelectStyles } from '../../../utils/styles';
import { LeadService } from '../../../services/lead/leadService';
import { Lead } from '../../../type/lead-type';

type LeadSelectorProps = {
    defaultSelected?: string | null;
    name: string;
    onChange: (value: string[]|string) => void;
    isMulti?: boolean;
    discardSelected?: boolean;
    isRequired?: boolean;
}

const LeadSelector: React.FC<LeadSelectorProps> = ({ defaultSelected, isMulti, discardSelected, onChange, isRequired , name}) => {

    const [selectedOption, setSelectedOption] = useState<string[]|string|null>(null);

    // listen for changes in selectedOptions and store in selectedOptions state
    useEffect(() => {
        if (defaultSelected) {
            setSelectedOption(discardSelected ? null : defaultSelected);
        }
    }, [defaultSelected])

    const [data] = useFetch(
        useCallback(async () => {
            const service = new LeadService();
            const response = await service.getLeads({active : true});
            return response.getResponseData();
        }, [])
    )

    const _getOptions = (): any[] => {
        let options: any = [];
        if (data) {
            data.leads.forEach((item: Lead) => {
                // if discardSelected is true, discard selected options for not appear in the list
                if(discardSelected && defaultSelected && defaultSelected.length > 0) {
                    let found = defaultSelected == item.id;
                    if(found) {
                        return;
                    }
                }
                options.push({ value: item.id, label: item.name });
            })
        }
        return options;
    }



    const _getDefaultSelectedValues = (): any => {
        let option = null;
        if (selectedOption && data) {
            let optionData = data.leads.filter((item: Lead) => selectedOption.includes(item.id));
            option = optionData.map((item: Lead) => ({ value: item.id, label: item.name }));
        }
        return option;
    }

    const _handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        if (Array.isArray(newValue)) {
            let selected = newValue.map((option: any) => option.value);
            setSelectedOption(selected);
            onChange([...selected]);
        } else {
            setSelectedOption(newValue.value);
            onChange(newValue.value);
        }
    }


    // useEffect when selectedOptions changes and call onChange function


    return (
        <FormGroup label='Tipo de Trámite' requiredInputLabel={isRequired} color={'primary'}>
            <Select styles={ReactSelectStyles} placeholder="Elige un tipo"
                    name={name} isMulti={isMulti} options={_getOptions()} value={_getDefaultSelectedValues()} onChange={_handleChange} />
        </FormGroup>
    )
}

LeadSelector.defaultProps = {
    isMulti: false,
    discardSelected: false,
    isRequired: false
}

export default LeadSelector;