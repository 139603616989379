import { RestServiceConnection } from '../restServiceConnection';

const DASHBOARD_ENDPOINT = '/dashboard';

export class DashboardService extends RestServiceConnection {

    getRecentAcitivities = async (filters? : any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/get-recent-activities',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getTotalCommissionedOperationsAndPercentage = async (data : any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/get-income-commissioned-operations-between-dates',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getCompanyOperationsData =  async (filters? : any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/get-company-operations-data',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getOperationPointsBetweenDates =  async (data? : any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/get-operations-points-between-dates',
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }
}