import { Fragment } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import OriginList from "./origin-list/OriginList"

const OriginListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <OriginList/>
            </FiltersProvider>
        </Fragment>
    )
}

export default OriginListWrapper