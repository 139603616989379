import { Fragment } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import ProductsList from "./product-list/ProductList"

const ProductListWrapper = () => {

    return (
        <Fragment>
           <FiltersProvider>
             <ProductsList/>
           </FiltersProvider>
        </Fragment>
    )
}

export default ProductListWrapper