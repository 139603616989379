import { NewCampaign } from "../../type/campaign-type";
import { RestServiceConnection } from "../restServiceConnection";

const CAMPAIGN_ENDPOINT = '/campaigns';

export class CampaignService extends RestServiceConnection {

    public async getCampaigns(filters?: any) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/list',
            data: filters ? { ...filters } : {},
        }, true);
        return this;
    }

    public async getCampaignsSelect(filters?: any) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/list-for-select',
            data: filters ? { ...filters } : {},
        }, true);
        return this;
    }

    public async getCampaignById(id: string) {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CAMPAIGN_ENDPOINT + `/get/${id}`
        }, true);
        return this;
    }

    public async createCampaign(values: NewCampaign) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/create',
            data: values,
        }, true);
        return this;
    }

    public async updateCampaign(campaign: any) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/edit',
            data: campaign,
        }, true);
        return this;
    }

    public async deleteCampaign(id: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/delete',
            data: {
                campaign: id
            }
        }, true);
        return this;
    }

    public async addProductToCampaign(idCampaign: string, idProduct: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/add-product',
            data: {
                campaign: idCampaign,
                product: idProduct
            }
        }, true);
        return this;
    }

    public async removeProductFromCampaign(idCampaign: string, idProduct: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/delete-product',
            data: {
                campaign: idCampaign,
                product: idProduct
            }
        }, true);
        return this;
    }

    public async addUserToCampaign(idCampaign: string, idUser: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/add-user',
            data: {
                campaign: idCampaign,
                user: idUser
            }
        }, true);
        return this;
    }

    
    public async removeUserFromCampaign(idCampaign: string, idUser: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/delete-user',
            data: {
                campaign: idCampaign,
                user: idUser
            }
        }, true);
        return this;
    }

    public async addOfficeToCampaign(idCampaign: string, idOffice: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/add-office',
            data: {
                campaign: idCampaign,
                office: idOffice
            }
        }, true);
        return this;
    }

    public async removeOfficeFromCampaign(idCampaign: string, idOffice: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/remove-office',
            data: {
                campaign: idCampaign,
                campaignHasOffice: idOffice
            }
        }, true);
        return this;
    }

    public async addPostalCodeRestrictionToCampaign(idCampaign: string, postalCode: string , restrictionType: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/add-postal-code',
            data: {
                campaign: idCampaign,
                postalCode: postalCode,
                restrictionType: restrictionType
            }
        }, true);
        return this;
    }

    public async removePostalCodeRestrictionFromCampaign(idCampaign: string, idPostalCodeRestriction: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + '/remove-postal-code',
            data: {
                campaign: idCampaign,
                campaignHasPostalCodeRestriction: idPostalCodeRestriction
            }
        }, true);
        return this;
    }

    public async listCampaignStatuses(filters?: any) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/list-status`,
            params: filters
        }, true);
        return this;

    }

    public async listCampaignChangeStatuses(filters?: any) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/list-change-status`,
            params: filters
        }, true);
        return this;

    }

    public async addStatusToCampaign(campaignId: string, statusId: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/add-status`,
            data: {
                campaign: campaignId,
                status: statusId
            }
        }, true);
        return this;

    }

    public async addChangeStatusToCampaign(campaignId: string, statusId: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/add-change-status`,
            data: {
                campaign: campaignId,
                status: statusId
            }
        }, true);
        return this;

    }

    public async removeStatusFromCampaign(statusId: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/delete-status`,
            data: {
                status: statusId
            }
        }, true);
        return this;
    }

    public async removeChangeStatusFromCampaign(statusId: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CAMPAIGN_ENDPOINT + `/delete-change-status`,
            data: {
                status: statusId
            }
        }, true);
        return this;
    }
}